import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CheckoutReview from 'components/Checkout/CheckoutReview';
import { calculateCheckoutTotal } from 'components/Checkout/helpers';
import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
} from 'redux/selectors';
import { buildReviewData } from 'components/Philanthropy/MyGiving/Pledges/Payments/PledgePaymentReview/helpers';
import HiddenFieldsForm from 'components/Checkout/CheckoutReview/HiddenFieldsForm';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import usePageTitle from 'utils/hooks/usePageTitle';

/**
 * Responsible for pledge payment review page.
 */
const PledgePaymentReview = () => {
  const fnNavigate = useNavigate();
  const [oHiddenFieldsFormData, setHiddenFieldsFormData] = useState(null);
  const [oDadTransactionData, setDadTransactionData] = useState(null);
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const oName = useSelector(currentUserFirstLastNameSelector);
  const oHiddenFieldFormRef = useRef();
  const oPledgePaymentData = useSelector((state) => state.PledgePayment);
  const oCheckoutData = useSelector((state) => state.Checkout);
  const dispatch = useDispatch();

  usePageTitle('Commitment Payment Review');

  useEffect(() => {
    if (sLookupId && oPledgePaymentData.selectPaymentFormValues) {
      const oParams = {
        oFormValues: oPledgePaymentData.selectPaymentFormValues,
        aPayablePledges: oPledgePaymentData.payablePledges,
        oProfileData: {
          LOOKUPID: sLookupId,
          FIRSTNAME: oName.sFirstName,
          LASTNAME: oName.sLastName,
          EMAILADDRESS: oCheckoutData.email,
        },
      };
      const oReviewData = buildReviewData(oParams);
      const fUpdatedCheckoutTotal = calculateCheckoutTotal(
        oReviewData.aSummaryItems
      );
      dispatch(
        CheckoutActions.update({
          checkoutSummaryItems: oReviewData.aSummaryItems,
          checkoutTotal: fUpdatedCheckoutTotal,
        })
      );
      setHiddenFieldsFormData(oReviewData.oHiddenFieldsData);
      setDadTransactionData(oReviewData.oTransactionData);
    }
  }, [
    oPledgePaymentData,
    sLookupId,
    oName.sFirstName,
    oName.sLastName,
    oCheckoutData.email,
    dispatch,
  ]);

  const bShouldRedirect =
    oPledgePaymentData.payablePledges.length === 0 ||
    !oPledgePaymentData.selectPaymentFormValues;
  if (bShouldRedirect) {
    fnNavigate('/philanthropy');
  }

  return (
    <CheckoutReview
      sTitle='Commitment Payment'
      sFirstStepLabel='Select Payment'
      oHiddenFieldsMarkup={
        <HiddenFieldsForm
          oHiddenFieldsFormData={oHiddenFieldsFormData}
          oHiddenFieldFormRef={oHiddenFieldFormRef}
        />
      }
      oHiddenFieldFormRef={oHiddenFieldFormRef}
      oDadTransactionData={oDadTransactionData}
    />
  );
};

export default PledgePaymentReview;
