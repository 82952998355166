import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { sortByKey } from 'utils/common';
import AddressViewMode from 'components/MyProfile/elements/Personal/Address/viewMode';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import AddressEditMode from 'components/MyProfile/elements/Personal/Address/editMode';
import { profileExternalViewAddressActions } from 'components/ProfileExternalView/redux/actions';

const addressSelector = (state) =>
  (state.ProfileExternalView || {}).ADDRESS || [];

const sortAddressByPrimarySelector = createSelector(addressSelector, (items) =>
  sortByKey(items, 'PRIMARY', false)
);

export const AddressElement = () => {
  const aAddresses = useSelector(sortAddressByPrimarySelector);
  const [activeFormSection] = useActiveFormSection();
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, setIsEditMode] = useState(false);

  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      setIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      setIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    <ProfileSubsectionLayout
      sTitle='Address'
      left={
        aAddresses.length > 1 && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <AddressEditMode
            aAddresses={aAddresses}
            bIsExpanded={bIsExpanded}
            oActions={profileExternalViewAddressActions}
          />
        ) : (
          <AddressViewMode
            aAddresses={aAddresses}
            bIsExpanded={bIsExpanded}
            bIsExternalView
          />
        )
      }
    />
  );
};

export default AddressElement;
