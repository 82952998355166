import React from 'react';
import PropTypes from 'prop-types';
import DateAndTime from 'date-and-time';

import { fnFormatEventDate } from './helpers';

/**
 * Displays an event's date (or date range) and year.
 */
const EventDateBlock = ({ oEvent }) => {
  const { START_DATE_TIME, END_DATE_TIME } = oEvent;
  const oStartDate = DateAndTime.parse(START_DATE_TIME, 'YYYY-MM-DDTHH:mm:ss');
  const sYear = DateAndTime.format(oStartDate, 'YYYY');
  return (
    <p className='eventDateBlock t-label'>
      <span className='eventDateBlock__monthDay'>
        {fnFormatEventDate(START_DATE_TIME, END_DATE_TIME)}
      </span>{' '}
      <span className='eventDateBlock__year'>{sYear}</span>
    </p>
  );
};

EventDateBlock.propTypes = {
  oEvent: PropTypes.shape({
    START_DATE_TIME: PropTypes.string,
    END_DATE_TIME: PropTypes.string,
  }).isRequired,
};

export default EventDateBlock;
