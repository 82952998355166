import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import { fnChildrenPropType } from 'utils/customPropTypes';

export const aHighlightFlavors = ['info', 'warning'];

const Highlight = ({ sFlavor, children }) => (
  <div className={`highlight highlight--${sFlavor}`}>
    <FontAwesomeIcon
      icon={oIconLibrary.fontAwesome.info}
      className='highlight__icon'
    />
    <p className='highlight__message t-paragraph--small'>{children}</p>
  </div>
);

Highlight.propTypes = {
  sFlavor: PropTypes.oneOf(aHighlightFlavors),
  children: fnChildrenPropType.isRequired,
};

Highlight.defaultProps = {
  sFlavor: 'info',
};

export default Highlight;
