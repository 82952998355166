import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DegreesViewMode from './viewMode';
import DegreesEditMode from './editMode';
import { degreesByYearSelector } from './helpers';

const Degrees = () => {
  const aDegrees = useSelector(degreesByYearSelector);
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  useEffect(() => {
    if (activeFormSection === 'education') {
      fnSetIsEditMode(true);
    } else {
      fnSetIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    aDegrees.length > 0 &&
    (bIsEditMode ? (
      <DegreesEditMode aDegrees={aDegrees} />
    ) : (
      <DegreesViewMode aDegrees={aDegrees} />
    ))
  );
};

export default Degrees;
