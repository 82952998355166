import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncCreateable from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useField } from 'formik';

import useFloatingSelectLabel from '../useFloatingSelectLabel';
import {
  fnBuildNoResultsMessage,
  fnBuildLoadingMessage,
  fnGetOptionLabel,
  fnGetOptionValue,
  fnGetNewOptionData,
  fnLoadOptions,
} from './helpers';

const sClassNamePrefix = 'reactSelect';

const SelectEmployerField = ({
  className,
  oFieldRef,
  sInputId,
  bIsUserAllowedToAddOptions,
  sFieldName,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [field, meta, helpers] = useField(sFieldName);
  const {
    sLabelClass,
    sErrorClass,
    fnHandleFocus,
    fnHandleBlur,
    oCustomStyles,
  } = useFloatingSelectLabel(sFieldName, field.value?.NAME);

  // Synchronize selectedOption with the Formik field value
  // when the form is cleared
  useEffect(() => {
    if (
      selectedOption?.NAME !== field.value?.NAME &&
      field.value?.NAME !== ''
    ) {
      setSelectedOption(field.value);
    }
  }, [field.value, selectedOption]);

  const fnHandleChange = (oSelectedOption) => {
    setSelectedOption(oSelectedOption);
    helpers.setValue(oSelectedOption);
  };

  const ReactSelectComponent = bIsUserAllowedToAddOptions
    ? AsyncCreateable
    : AsyncSelect;

  return (
    <div className={`boxField boxField--select ${sErrorClass} ${className}`}>
      <ReactSelectComponent
        inputId={sInputId}
        name={sFieldName}
        value={selectedOption}
        className={`reactSelect__container ${className}`}
        classNamePrefix={sClassNamePrefix}
        getOptionLabel={fnGetOptionLabel}
        getOptionValue={fnGetOptionValue}
        placeholder='Choose an employer...'
        onChange={fnHandleChange}
        isClearable
        cacheOptions
        defaultOptions={[]}
        loadOptions={fnLoadOptions}
        noOptionsMessage={fnBuildNoResultsMessage}
        loadingMessage={fnBuildLoadingMessage}
        ref={oFieldRef}
        styles={oCustomStyles}
        onFocus={fnHandleFocus}
        onBlur={fnHandleBlur}
        getNewOptionData={fnGetNewOptionData}
      />
      <label htmlFor={sInputId} className={`select__label ${sLabelClass}`}>
        Employer
      </label>
      {meta.touched && meta.error && (
        <p className='field__errorMessage'>{meta.error}</p>
      )}
    </div>
  );
};

SelectEmployerField.propTypes = {
  className: PropTypes.string,
  oFieldRef: PropTypes.object,
  sInputId: PropTypes.string.isRequired,
  bIsUserAllowedToAddOptions: PropTypes.bool,
  sFieldName: PropTypes.string,
};

SelectEmployerField.defaultProps = {
  className: '',
  oFieldRef: null,
  bIsUserAllowedToAddOptions: false,
  sFieldName: 'EMPLOYER',
};

export default SelectEmployerField;
