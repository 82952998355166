import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import { fnIsEventAtCapacity } from './helpers';

const oIconsByFlavor = {
  cancelled: oIconLibrary.fontAwesome.exclamationTriangle,
  registered: oIconLibrary.fontAwesome.check,
  nearCapacity: oIconLibrary.fontAwesome.exclamationCircle,
  atCapacity: oIconLibrary.fontAwesome.exclamationCircle,
  waitlisted: oIconLibrary.fontAwesome.check,
};

const EventAlert = ({ oEvent }) => {
  const { IS_CANCELLED } = oEvent;
  const { IS_REGISTERED, TOTAL_CAPACITY, TOTAL_REGISTRATIONS } =
    oEvent.REGISTRATION || {}; // Main events don't have a REGISTRATION object
  const bIsWaitlistEvent = [true, 1].includes(
    oEvent?.REGISTRATION?.ALLOW_WAITLIST
  );

  let sFlavor = ''; // one of: cancelled, registered, capacity,
  let sFlavorId = '';

  const bIsCancelled = [true, 1].includes(IS_CANCELLED);
  const bIsRegistered = [true, 1].includes(IS_REGISTERED);
  const iSpotsRemaining = TOTAL_CAPACITY
    ? TOTAL_CAPACITY - TOTAL_REGISTRATIONS
    : 0;
  // Capacity for events without a limit is 0, so `iSpotsRemaining` could be negative
  const bIsEventNearCapacity = iSpotsRemaining > 0 && iSpotsRemaining <= 5;
  const bIsEventAtCapacity = fnIsEventAtCapacity(oEvent);
  // If the event is at capacity, check if the user is waitlisted already
  const bIsWaitlisted =
    [true, 1].includes(oEvent?.REGISTRATION?.IS_WAITLISTED) &&
    bIsEventAtCapacity;

  switch (true) {
    case bIsCancelled:
      sFlavor = 'cancelled';
      sFlavorId = 'cancelled';
      break;
    case bIsRegistered:
      sFlavor = 'registered';
      sFlavorId = 'registered';
      break;
    case bIsWaitlisted:
      sFlavor = 'waitlisted';
      sFlavorId = 'waitlisted';
      break;
    case bIsEventNearCapacity:
      sFlavor = 'nearCapacity';
      sFlavorId = 'near-capacity';
      break;
    case bIsEventAtCapacity:
      sFlavor = 'atCapacity';
      sFlavorId = 'at-capacity';
      break;
    default:
      break;
  }

  const sTextByFlavor = {
    cancelled: 'Cancelled',
    registered: 'Registered',
    nearCapacity: `${iSpotsRemaining} spot(s) remaining`,
    atCapacity: 'At capacity',
    waitlisted: 'On waitlist',
  };

  return sFlavor ? (
    <div className='eventAlert__wrapper'>
      <p
        className={`eventAlert eventAlert--${sFlavor} t-paragraph--small`}
        data-cy={`event-alert-${sFlavorId}`}
      >
        <FontAwesomeIcon icon={oIconsByFlavor[sFlavor]} />
        {sTextByFlavor[sFlavor]}
      </p>
      {sFlavor === 'atCapacity' && bIsWaitlistEvent && (
        <p
          className='eventAlert__waitlistMsg t-paragraph--small'
          data-cy='event-alert-waitlist-available'
        >
          Waitlist available
        </p>
      )}
    </div>
  ) : null;
};

EventAlert.propTypes = {
  oEvent: PropTypes.shape({
    IS_CANCELLED: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    // eslint-disable-next-line react/forbid-prop-types
    LOOKUPID: PropTypes.string,
    MY_PARTY: PropTypes.object,
    REGISTRATION: PropTypes.shape({
      TOTAL_CAPACITY: PropTypes.number,
      TOTAL_REGISTRATIONS: PropTypes.number,
      IS_REGISTERED: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      ALLOW_WAITLIST: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      IS_WAITLISTED: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    }),
  }).isRequired,
};

export default EventAlert;
