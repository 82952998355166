import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import * as CheckoutActions from 'components/Checkout/redux/actions';
import Button from 'components/Buttons/Button';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import useTrapFocusInsideModal from 'utils/hooks/useTrapFocusInsideModal';

const RemoveEventModal = ({ sEventTitle, sEventLookupId, fnCloseModal }) => {
  const oModalRef = useRef(null);
  const fnDispatch = useDispatch();

  useTrapFocusInsideModal(oModalRef);
  useCloseOnOutsideClick(oModalRef, fnCloseModal);

  return (
    <div className='reviewRegistration__removeEventOverlay'>
      <div className='reviewRegistration__removeEventModal' ref={oModalRef}>
        <IconOnlyButton
          sIcon='close'
          fnHandleClick={fnCloseModal}
          className='reviewRegistration__removeEventModalCloseButton'
          sAriaLabel='Close'
        />
        <p className='reviewRegistration__removeEventModalText t-paragraph--medium'>
          Are you sure you want to remove {sEventTitle}?
        </p>
        <div className='reviewRegistration__removeEventModalButtons'>
          <Button sFlavor='secondary' fnHandleClick={fnCloseModal}>
            Cancel
          </Button>
          <Button
            fnHandleClick={() => {
              fnDispatch(CheckoutActions.cart.removeItem(sEventLookupId));
            }}
          >
            Remove event
          </Button>
        </div>
      </div>
    </div>
  );
};

RemoveEventModal.propTypes = {
  sEventTitle: PropTypes.string.isRequired,
  sEventLookupId: PropTypes.string.isRequired,
  fnCloseModal: PropTypes.func.isRequired,
};

export default RemoveEventModal;
