import React, { useEffect, useState, useCallback } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import qs from 'qs';

import AdvancedSearchFields from 'components/Search/elements/AdvancedSearch/AdvancedSearchFields';
import LoadingZone from 'components/LoadingZone';
import oWnApi from 'utils/WnApi';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import Button from 'components/Buttons/Button';
import { SubmitButton } from 'components/Form/Buttons';
import { useNavigate } from 'react-router-dom';
import Results from './Results';
import advSearchResultsDataNormalizer from '../../apiDataNormalizer';
import { oEmptyAdvancedSearch } from '../../helpers';
import AdvancedSearchResultsSkeleton from './AdvancedSearchResultsSkeleton';
import { fnFormatAdvSearchIntoQueryParams } from './helpers';

/**
 * A form that allows the user to perform and refine a
 * detailed constituent search
 */
const AdvancedSearch = ({
  bForceSubmit,
  initialSearchCriteria,
  bIsAdvancedSearchVisible,
  fnSetIsAdvancedSearchVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [advSearchResults, setAdvSearchResults] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
  const fnNavigate = useNavigate();

  const fnHandleSearchSubmit = async (oFormData) => {
    // Remove trailing spaces on search terms
    const oSanitizedSearchCriteria = { ...oFormData };
    // eslint-disable-next-line
    for (const prop in oSanitizedSearchCriteria) {
      if (typeof oSanitizedSearchCriteria[prop] === 'string') {
        oSanitizedSearchCriteria[prop] = oSanitizedSearchCriteria[prop].trim();
      }
    }

    // Update the URL with the advanced search criteria
    const sQueryParams = fnFormatAdvSearchIntoQueryParams(
      oSanitizedSearchCriteria
    );
    fnNavigate(`/main/search?${sQueryParams}`);

    setSearchCriteria(oSanitizedSearchCriteria);
    setLoading(true);
    try {
      const sSearchQueryParams = qs.stringify(oSanitizedSearchCriteria);
      const sRequestPath = `constituents?${sSearchQueryParams}`;
      const oResponse = await oWnApi.get(sRequestPath);
      if (oResponse.status === 200) {
        const searchResults = await advSearchResultsDataNormalizer.validate(
          oResponse.data
        );
        setAdvSearchResults(searchResults);
        setShowResults(true);
      }
    } catch (err) {
      console.error('[E]: Error Performing Search - ', err);
    }
    setLoading(false);
  };

  const shouldShowAdvancedFields = useCallback(() => {
    const hasEducProgram = !!(searchCriteria.educationalprogram || {}).ID;
    const hasEducDept = !!(searchCriteria.educationaldepartment || {}).ID;
    const hasEducInvolvement = !!(searchCriteria.educationalinvolvement || {})
      .ID;
    const hasAffinityGroup = !!(searchCriteria.affinitygroup || {}).ID;
    return (
      hasEducProgram || hasEducDept || hasEducInvolvement || hasAffinityGroup
    );
  }, [searchCriteria]);

  const [more, setMore] = useState(() => shouldShowAdvancedFields());

  // Show advanced fields if user has entered data into them
  useEffect(() => {
    const hasAdvOptions = shouldShowAdvancedFields();
    setMore(hasAdvOptions);
  }, [searchCriteria, shouldShowAdvancedFields]);

  useEffect(() => {
    if (bForceSubmit) {
      fnHandleSearchSubmit(initialSearchCriteria);
    }
  }, [bForceSubmit, initialSearchCriteria]);

  return (
    <>
      {bIsAdvancedSearchVisible && (
        <h2 className='advancedSearch__header t-paragraph--large'>
          Advanced Directory Search
        </h2>
      )}
      <IconOnlyButton
        className='advancedSearch__toggle'
        fnHandleClick={() => {
          fnSetIsAdvancedSearchVisible(!bIsAdvancedSearchVisible);
        }}
        sIcon={bIsAdvancedSearchVisible ? 'collapse' : 'expand'}
        sAriaLabel='Toggle advanced search'
        sFlavor='tertiary'
      />
      {bIsAdvancedSearchVisible && (
        <div className='advancedSearch__mainContainer'>
          <LoadingZone
            isLoading={loading}
            skeleton={<AdvancedSearchResultsSkeleton />}
            message='Loading advanced search results'
          >
            <>
              {showResults && (
                <Results
                  initialSearchResults={advSearchResults}
                  setShowResults={setShowResults}
                />
              )}
              {!showResults && !loading && (
                <Formik
                  onSubmit={fnHandleSearchSubmit}
                  initialValues={searchCriteria}
                  enableReinitialize
                >
                  {(formikProps) => {
                    const sRegionMismatchError = `The selected region, ${formikProps?.values?.state?.DESCRIPTION} is not in
                            the selected country, ${formikProps?.values?.country?.DESCRIPTION}. Please check your selection.`;
                    const bShowRegionMismatchError =
                      formikProps.errors?.country ===
                      `${formikProps?.values?.state?.DESCRIPTION} is not in ${formikProps?.values?.country?.DESCRIPTION}`;
                    return (
                      <Form autoComplete='off'>
                        <AdvancedSearchFields bForceExpandMore={more} />
                        {bShowRegionMismatchError && (
                          <p className='form__overallError'>
                            {sRegionMismatchError}
                          </p>
                        )}
                        <div className='advancedSearch__formButtonWrapper'>
                          <SubmitButton>Search</SubmitButton>
                          <Button
                            sFlavor='secondary'
                            fnHandleClick={() => {
                              // Explicitly set the initial form values to empty instead of the original searchCriteria
                              formikProps.resetForm({
                                values: oEmptyAdvancedSearch,
                              });
                              // Clear the advanced search terms from the URL
                              fnNavigate('/main');
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </>
          </LoadingZone>
        </div>
      )}
    </>
  );
};

export default AdvancedSearch;

AdvancedSearch.defaultProps = {
  bForceSubmit: false,
  initialSearchCriteria: oEmptyAdvancedSearch,
};

AdvancedSearch.propTypes = {
  bForceSubmit: PropTypes.bool,
  initialSearchCriteria: PropTypes.shape({
    educationalprogram: PropTypes.shape({
      ID: PropTypes.string,
      NAME: PropTypes.string,
    }),
    educationalinvolvement: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
    }),
    educationaldepartment: PropTypes.shape({
      ID: PropTypes.string,
      NAME: PropTypes.string,
    }),
    affinitygroup: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
    }),
  }),
  bIsAdvancedSearchVisible: PropTypes.bool.isRequired,
  fnSetIsAdvancedSearchVisible: PropTypes.func.isRequired,
};
