import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { isFunction } from '../../../utils/common';
import {
  fnChildrenPropType,
  yupSchemaPropType,
} from '../../../utils/customPropTypes';

const ConditionalForm = ({
  isForm,
  initialValues,
  children,
  handleSubmit,
  renderChildren,
  validationSchema,
}) =>
  isForm ? (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {() => (
        <Form className='optionsModal__form'>
          {isFunction(renderChildren) ? renderChildren() : children}
        </Form>
      )}
    </Formik>
  ) : (
    children
  );

ConditionalForm.defaultProps = {
  handleSubmit: null,
  renderChildren: null,
  validationSchema: null,
};

ConditionalForm.propTypes = {
  isForm: PropTypes.bool.isRequired,
  initialValues: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  handleSubmit: PropTypes.func,
  children: fnChildrenPropType.isRequired,
  renderChildren: PropTypes.func,
  validationSchema: yupSchemaPropType,
};

export default ConditionalForm;
