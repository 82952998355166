import { createActions } from 'redux-actions';

export const { get, set, create, signUp, remove, update, addNote } =
  createActions(
    {
      GET: (p) => p,
      SET: (p) => p,
      CREATE: (action) => action,
      SIGN_UP: (action) => action,
      DELETE: (action) => action,
      UPDATE: (state) => state,
      ADD_NOTE: (action) => action,
    },
    {
      prefix: 'Profile',
    }
  );
