import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { triggerGoogleTagManagerEvent } from 'utils/common';
import { SubmitButton } from 'components/Form/Buttons';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import EstateActions from '../redux/actions';
import useActions from '../../../../utils/hooks/useActions';
import { Radio } from '../../../Form/Fields/Radio';
import estateFormSchema from './schema';

const EstateForm = ({ legacySocietyStatus }) => {
  const [successMsg, setSuccessMsg] = useState(null);
  const legacySocietyCodes = useCodeTablesSection('LEGACYSOCIETYCODE');
  const actions = useActions(EstateActions);

  const updateSuccessMsg = (status) => {
    switch (status) {
      case 'Yes':
        setSuccessMsg(
          <>
            <p className='estate__successMsg'>
              We thank you for your thoughtful planning and generous
              contribution to the future of Wake Forest.
            </p>
            <p className='estate__successMsg'>
              A staff member will contact you within a few business days to
              document your commitment.
            </p>
          </>
        );
        break;
      case 'Interested':
        setSuccessMsg(
          <p className='estate__successMsg'>
            Thank you for your interest in including Wake Forest in your estate
            planning process. A staff member will be in contact within a few
            business days to discuss estate planning strategies and options with
            you.
          </p>
        );
        break;
      case 'No':
        setSuccessMsg(
          <p className='estate__successMsg'>
            Thank you for letting us know. We hope you&apos;ll reconsider.
          </p>
        );
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (values, formikBag) => {
    triggerGoogleTagManagerEvent('Estate', 'submitted update');
    // Find matching DAD ID for chosen value
    const option = legacySocietyCodes.find(
      (item) => item.DESCRIPTION === values.estate
    );

    try {
      // Send value to DAD
      const submitPromise = new Promise((resolve, reject) => {
        actions.update({
          data: {
            ID: option.ID || '',
            DESCRIPTION: values.estate,
          },
          resolve,
          reject,
        });
      });
      const result = await submitPromise;
      if (result === 'Success') {
        updateSuccessMsg(values.estate);
      } else {
        throw new Error('There was an error saving form data');
      }
    } catch (error) {
      console.error('[E]: failed handleSubmit', error);
      formikBag.setFieldError(
        'estate',
        'Something went wrong. Please try again or contact us for help.'
      );
    }
  };

  const initialValues = {
    estate: legacySocietyStatus || '',
  };

  return (
    successMsg || (
      <Formik
        initialValues={initialValues}
        validationSchema={estateFormSchema}
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className='estate__form'>
            <fieldset>
              <legend>Estate Preferences</legend>
              <Radio
                sName='estate'
                sId='estate-yes'
                mValue='Yes'
                sLabel='I have included Wake Forest in my Estate.'
                silenceErrors
              />
              <Radio
                sName='estate'
                sId='estate-interested'
                mValue='Interested'
                sLabel='I am interested to know more.'
                bSilenceErrors
              />
              <Radio
                sName='estate'
                sId='estate-no'
                mValue='No'
                sLabel='I am not interested right now.'
                bSilenceErrors
              />
              {errors.estate && touched.estate && (
                <p className='field__errorMessage'>{errors.estate}</p>
              )}
            </fieldset>
            <SubmitButton />
          </Form>
        )}
      </Formik>
    )
  );
};

EstateForm.propTypes = {
  legacySocietyStatus: PropTypes.string,
};

EstateForm.defaultProps = {
  legacySocietyStatus: '',
};

export default EstateForm;
