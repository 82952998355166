import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import oWnApi from 'utils/WnApi';
import useIsOnScreen from 'utils/hooks/useIsOnScreen';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { useLocation } from 'react-router-dom';

/**
 * Displays a constituent's profile photo if they have one;
 * otherwise, it shows their initials.
 */
const ConstituentAvatar = ({
  lookupId,
  className,
  sFirstName,
  sLastName,
  bHasPhoto,
  sSize,
  sUploadedImage,
}) => {
  const { pathname } = useLocation();
  const [sImageSource, fnSetImageSource] = useState('');
  const [sInitials, fnSetInitials] = useState('');
  const oContainerRef = useRef();
  const bIsOnScreen = useIsOnScreen(oContainerRef);
  const bIsAuthenticated = useIsUserAuthenticated();
  const bIsProfilePage = pathname === '/main' || pathname.includes('profile');

  useEffect(() => {
    const sFirstInitial = sFirstName.substr(0, 1) || '';
    const sLastInitial = sLastName.substr(0, 1) || '';
    fnSetInitials(`${sFirstInitial}${sLastInitial}`);
  }, [sFirstName, sLastName]);

  // When a virtualized list renders this component (ex: My Classmates, advanced search results),
  // the lookup ID changes as the user scrolls through the list. But, the component *stays mounted*.
  // This hook clears the previous photo in order to stay in sync with the new lookup ID.
  useEffect(() => {
    fnSetImageSource('');
  }, [lookupId]);

  // Display the newly uploaded profile photo
  useEffect(() => {
    if (sUploadedImage) {
      fnSetImageSource(sUploadedImage);
    }
  }, [sUploadedImage]);

  useEffect(() => {
    const sUrl = `constituents/${lookupId}/photo`;
    let oAbortController = null;

    const fnFetchData = async () => {
      oAbortController = new AbortController();
      const oResponse = await oWnApi.get(sUrl, true, oAbortController.signal);
      if (oResponse.status === 200) {
        const img = `data:image/png;base64,${oResponse.data.DATA.PICTURE}`;
        fnSetImageSource(img);
        oAbortController = null;
      }
    };

    // Only fetch the photo when:
    // 1. The user hasn't just uploaded a new photo and
    // 2. the constituent has one in DAD.
    // This helps prevent Blackbaud CRM from locking up by
    // reducing how many API calls WN makes to the photo endpoint.
    if (
      bIsAuthenticated &&
      !sImageSource &&
      bHasPhoto &&
      (bIsProfilePage || bIsOnScreen)
    ) {
      fnFetchData();
    }

    return () => {
      if (oAbortController) {
        oAbortController.abort();
        console.log(`GET:${sUrl} request canceled`);
      }
    };
  }, [lookupId, bHasPhoto, sImageSource, bIsOnScreen, bIsAuthenticated]);

  return bIsAuthenticated ? (
    <div
      className={`constituentAvatar--${sSize} ${className}`}
      ref={oContainerRef}
    >
      {sImageSource ? (
        <img
          className='constituentAvatar__image'
          src={sImageSource}
          alt={`${sFirstName} ${sLastName}`}
        />
      ) : (
        sInitials
      )}
    </div>
  ) : null;
};

export const aSizes = ['extraSmall', 'small', 'large', 'extraLarge'];

ConstituentAvatar.propTypes = {
  lookupId: PropTypes.string.isRequired,
  className: PropTypes.string,
  sFirstName: PropTypes.string.isRequired,
  sLastName: PropTypes.string.isRequired,
  bHasPhoto: PropTypes.bool.isRequired,
  sSize: PropTypes.oneOf(aSizes),
  sUploadedImage: PropTypes.string,
};

ConstituentAvatar.defaultProps = {
  className: '',
  sSize: aSizes[0],
  sUploadedImage: '',
};

export default ConstituentAvatar;
