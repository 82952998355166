import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import Modal from 'components/Layout/Modal';
import oIconLibrary from 'utils/iconLibrary';
import { aSocialIdps } from 'components/Authentication/AuthLayout/helpers';
import fnSignIn from './fnSignIn';

const SocialSignInModal = ({ fnSetShowSignInModal }) => {
  const oLocation = useLocation();
  const [oSearchParams, fnSetSearchParams] = useSearchParams();

  useEffect(() => {
    if (oLocation.pathname.includes('events')) {
      fnSetSearchParams({ ...oSearchParams, redirect: oLocation.pathname });
    }
  }, [oLocation.pathname, oSearchParams, fnSetSearchParams]);

  return (
    <Modal
      sFlavor='sign-in'
      fnSetShowModal={fnSetShowSignInModal}
      sModalTitle='Access Wake Network'
      sModalDetail='Choose your sign in method:'
    >
      <div className='landingPage__signInWithIdpButtonsContainer'>
        {aSocialIdps.map((oIdp) => (
          <Button
            sIcon={oIdp.icon}
            sFlavor='tertiary'
            fnHandleClick={() => fnSignIn(oLocation, oIdp.id)}
            className='landingPage__signInWithIdpButton'
            key={oIdp.id}
          >
            <img src={oIconLibrary.wfu[oIdp.icon]} alt={`${oIdp.name} logo`} />
            Sign in with {oIdp.name}
          </Button>
        ))}
      </div>
    </Modal>
  );
};

SocialSignInModal.propTypes = {
  fnSetShowSignInModal: PropTypes.func.isRequired,
};
export default SocialSignInModal;
