import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import PageLayout from 'components/Layout/PageLayout';
import usePageTitle from 'utils/hooks/usePageTitle';
import * as scholarshipsActions from './redux/actions';
import ScholarshipBiography from './ScholarshipBiography';
import LoadingZone from '../LoadingZone';
import ScholarshipList from './ScholarshipList';
import ScholarshipsSkeleton from './ScholarshipsSkeleton';

const Scholarships = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const scholarshipsData = useSelector(
    (state) => state.Scholarships?.RECIPIENTDATA
  );

  usePageTitle('Scholarships');

  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      dispatch(
        scholarshipsActions.get({
          resolve,
          reject,
        })
      );
    });
    promise.then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  return (
    <PageLayout sTitle='Scholarships'>
      <LoadingZone
        isLoading={isLoading}
        skeleton={<ScholarshipsSkeleton />}
        message='Loading your scholarships'
      >
        <ScholarshipBiography biographyData={scholarshipsData?.BIOGRAPHY} />
        <ScholarshipList aScholarships={scholarshipsData?.SCHOLARSHIPS} />
      </LoadingZone>
      {/* This tells <BrowserRouter /> where to render nested routes - here, the public profile modal */}
      <Outlet />
    </PageLayout>
  );
};

export default Scholarships;
