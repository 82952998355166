import DateAndTime from 'date-and-time';

// Formats to: Wednesday, July 27, 2022 at 10:07 AM
const sDateTimeFormat = 'dddd, MMMM D, YYYY at h:mm A';

// Formats to: Wednesday, July 27, 2022
const sDateOnlyFormat = 'dddd, MMMM D, YYYY';

/**
 * Formats a date string into a human-readable date.
 *
 * @param {string} sDate - Date string with or without time
 * @returns {string} - Formatted date.
 */
const fnFormatDate = (sDate = '') => {
  let sFormatted = '';
  if (sDate) {
    const oDate = new Date(sDate);
    const bDateIncludesTime = sDate.includes('T');
    const sFormat = bDateIncludesTime ? sDateTimeFormat : sDateOnlyFormat;
    sFormatted = DateAndTime.format(oDate, sFormat);
  }
  return sFormatted;
};

export default fnFormatDate;
