import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import usePageTitle from 'utils/hooks/usePageTitle';
import ContinueButton from 'components/Authentication/AuthLayout/ContinueButton';
import AuthLayout from '../../AuthLayout';
import feliciaHelpLogo from '../../images/felicia-help.svg';
import ParentSignUpHelp from './ParentSignUpHelp';
import SignUpHelpForm from './SignUpHelpForm';

const sSignUpHelpFormId = 'sign-up-help-form';

/**
 * Help form for unauthenticated users.
 */
const SignUpHelp = () => {
  const oParams = useParams();
  const sMatchType = oParams.type;
  const bIsParent = sMatchType === 'parent';
  const [bIsSuccessState, fnSetIsSuccessState] = useState(false);
  const [bIsSubmitting, fnSetIsSubmitting] = useState(false);

  usePageTitle('Help');

  return bIsParent ? (
    <ParentSignUpHelp />
  ) : (
    <AuthLayout
      continueButton={
        bIsSuccessState ? null : (
          <ContinueButton
            sType='submit'
            sFormId={sSignUpHelpFormId}
            sContinueButtonText='Send Request'
            bIsLoading={bIsSubmitting}
          />
        )
      }
      left={
        <div className='help__formContainer'>
          <h1 className='t-header2'>We&apos;re here to help</h1>
          {bIsSuccessState ? (
            <>
              <h2 className='t-header3'>Request successfully sent</h2>
              <p className='authHelpForm__successText'>
                Someone from Wake Forest will be in contact with you soon.
              </p>
            </>
          ) : (
            <>
              <p className='help__instructions'>
                Tell us more about the problem. We&apos;ll look into it and
                reach out via email soon.
              </p>
              <SignUpHelpForm
                sFormId={sSignUpHelpFormId}
                fnSetIsSubmitting={fnSetIsSubmitting}
                fnSetIsSuccessState={fnSetIsSuccessState}
              />
            </>
          )}
        </div>
      }
      right={
        <img
          src={feliciaHelpLogo}
          className='help__feliciaHelpLogo'
          alt='Wake Network help logo'
        />
      }
    />
  );
};

export default SignUpHelp;
