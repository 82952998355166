import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import oIconLibrary from 'utils/iconLibrary';
import { oCurrencyFormatter } from 'utils/common';
import { fnRemoveGuestFromEventInCart } from '../redux/guestHelpers';
import useCurrentEventCartItem from '../useCurrentEventCartItem';
import { fnCalculateMaxNumberOfRegistrantsForEvent } from '../helpers';

const RegistrantListTray = () => {
  const { eventSlug } = useParams();
  const oEventCartItem = useCurrentEventCartItem();

  if (!oEventCartItem) {
    return null;
  }

  const { aGuests = [] } = oEventCartItem.oDetails ?? {};
  const bIsWaitlistEvent = [true, 1].includes(
    oEventCartItem.oDetails.oEvent.REGISTRATION?.ALLOW_WAITLIST
  );

  const {
    TOTAL_CAPACITY = 0,
    TOTAL_REGISTRATIONS = 0,
    MAX_GUESTS = 0,
    HAS_COST,
    REGISTRATION_OPTIONS = [],
  } = oEventCartItem.oDetails.oEvent.REGISTRATION ?? {};
  const iMaxRegistrants = fnCalculateMaxNumberOfRegistrantsForEvent(
    bIsWaitlistEvent ? 0 : TOTAL_CAPACITY,
    TOTAL_REGISTRATIONS,
    MAX_GUESTS
  );
  const bIsCostTbd =
    aGuests.every((oGuest) => !oGuest.REG_OPTION) &&
    HAS_COST === 1 &&
    REGISTRATION_OPTIONS.length > 1;

  return eventSlug ? (
    <div className='registrantListTray' data-cy='registrant-list-tray'>
      <div>
        <h2 className='registrantListTray__heading t-paragraph--medium'>
          Registrant List (Max: {iMaxRegistrants})
        </h2>
        {aGuests.length === 0 ? (
          <p className='registrantListTray__noRegistrantsMsg t-paragraph--small'>
            Please add at least one registrant.
          </p>
        ) : (
          <ul className='registrantListTray__registrants'>
            {aGuests.map((oGuest) => (
              <li
                className='registrantListTray__registrant'
                key={oGuest.LOOKUPID}
                data-cy={`registrant-${oGuest.LOOKUPID}-list-item`}
              >
                <span>
                  <FontAwesomeIcon icon={oIconLibrary.fontAwesome.user} />
                  &nbsp;&nbsp;<span>{oGuest.NAME}</span>
                </span>
                <IconOnlyButton
                  sIcon='closeCircle'
                  sFlavor='tertiary'
                  sAriaLabel='Remove'
                  fnHandleClick={() => {
                    fnRemoveGuestFromEventInCart(
                      oGuest.LOOKUPID,
                      oEventCartItem.oDetails.oEvent.LOOKUPID
                    );
                  }}
                  sCypressId='remove-guest-button'
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <h2 className='registrantListTray__heading t-paragraph--medium'>
        Event Cost:{' '}
        {bIsCostTbd ? 'TBD' : oCurrencyFormatter.format(oEventCartItem.nPrice)}
      </h2>
    </div>
  ) : null;
};

export default RegistrantListTray;
