import React, { useRef, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutTracker from 'components/Checkout/CheckoutTracker';
import PageLayout from 'components/Layout/PageLayout';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import Button from 'components/Buttons/Button';
import usePageTitle from 'utils/hooks/usePageTitle';
import * as PledgePaymentActions from '../redux/actions';
import SelectPaymentForm from './SelectPaymentForm';
import { calculatePendingBalance } from './helpers/calculateSelectPaymentTotals';
import PledgeList from '../../PledgeList';

/**
 * Responsible for pledge payment checkout page.
 */
const PledgePaymentCheckout = () => {
  const dispatch = useDispatch();
  const fnNavigate = useNavigate();
  const oLocation = useLocation();
  const submitSelectFormikFormRef = useRef();
  // If the user got here from a later checkout step, aIncomingPayablePledges won't be defined.
  const aIncomingPayablePledges = useMemo(
    () => (oLocation.state || {}).aPayablePledges || [],
    [oLocation]
  );

  const aSavedPayablePledges = useSelector(
    (state) => state.PledgePayment.payablePledges
  );
  const oPendingAmount = calculatePendingBalance(aSavedPayablePledges);

  usePageTitle('Commitment Payment Checkout');

  // Save payable pledges to Redux store
  useEffect(() => {
    if (aIncomingPayablePledges.length > 0) {
      dispatch(
        PledgePaymentActions.update({
          payablePledges: aIncomingPayablePledges,
        })
      );
    }
  }, [aIncomingPayablePledges, dispatch]);

  const submitHandler = (oFormValues) => {
    dispatch(
      PledgePaymentActions.update({
        selectPaymentFormValues: oFormValues,
      })
    );
    fnNavigate('/philanthropy/commitment-payment/review');
  };

  const handleCancelClick = () => {
    dispatch(PledgePaymentActions.reset({}));
    dispatch(CheckoutActions.reset({}));

    fnNavigate('/philanthropy');
  };

  const bShouldRedirect =
    oLocation?.state?.from !== '/philanthropy' &&
    aSavedPayablePledges.length === 0;
  if (bShouldRedirect) {
    fnNavigate('/philanthropy');
  }

  return (
    <>
      <CheckoutTracker sFirstStepLabel='Select Payment' sCurrentStep='select' />
      <PageLayout sTitle='Commitment Payment'>
        <div className='pledgePaymentSelect__section'>
          <h2 className='pledgePaymentSelect__sectionTitle'>
            Current Installments Due
          </h2>
          <div className='pledgePaymentSelect__pledgeWrapper'>
            <p className='pledgePaymentSelect__note'>
              * Commitments with ACH, payroll deduction and recurring are not
              displayed.
            </p>
            {aSavedPayablePledges.length > 0 && (
              <PledgeList
                aPledges={aSavedPayablePledges}
                className='pledgePaymentSelect__pledges'
              />
            )}
          </div>
        </div>
        <div className='pledgePaymentSelect__section'>
          <h2 className='pledgePaymentSelect__sectionTitle'>
            Select a Payment
          </h2>
          <div className='checkout__sectionBox'>
            {oPendingAmount.value > 0 && (
              <p className='checkout__pendingMessage'>
                <strong>Note:</strong> You have a pending transaction of{' '}
                {oPendingAmount.format()}. Please use &lsquo;Other&rsquo; for
                any additional payments.
              </p>
            )}
            <SelectPaymentForm
              submitFormikFormRef={submitSelectFormikFormRef}
              submitHandler={submitHandler}
            />
          </div>
        </div>
        <div className='checkout__cancelContinue'>
          <Button
            fnHandleClick={() => {
              submitSelectFormikFormRef.current();
            }}
            className='checkout__continue'
          >
            Continue
          </Button>
          <Button
            sFlavor='secondary'
            fnHandleClick={handleCancelClick}
            className='checkout__cancel'
          >
            Cancel
          </Button>
        </div>
      </PageLayout>
    </>
  );
};

export default PledgePaymentCheckout;
