import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

export const DateField = ({
  sName,
  sLabel,
  sId,
  bSilenceErrors,
  sMin,
  sMax,
}) => {
  const [field, meta] = useField(sName);
  const bShowErrors = !bSilenceErrors && meta.error;
  const sClassName = bShowErrors ? 'boxField boxField--error' : 'boxField';

  return (
    <div className={sClassName}>
      <input
        {...field}
        id={sId}
        type='date'
        min={sMin}
        max={sMax}
        onFocus={(event) => {
          // Allows date to be cleared on iOS
          // eslint-disable-next-line no-param-reassign
          event.nativeEvent.target.defaultValue = '';
        }}
      />
      <label htmlFor={sId}>{sLabel}</label>
      {!bSilenceErrors && meta.error && (
        <p className='field__errorMessage'>{meta.error}</p>
      )}
    </div>
  );
};

DateField.defaultProps = {
  bSilenceErrors: false,
  sMin: '',
  sMax: '',
};

DateField.propTypes = {
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  sId: PropTypes.string.isRequired,
  bSilenceErrors: PropTypes.bool,
  sMin: PropTypes.string,
  sMax: PropTypes.string,
};

export default DateField;
