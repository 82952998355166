import { createActions } from 'redux-actions';

export default createActions(
  {
    UPDATE: (payload) => payload,
  },
  {
    prefix: 'Profile/BIOGRAPHICAL/Birthdate',
  }
);
