import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import { fnElementRefPropType } from 'utils/customPropTypes';

export const CancelButton = ({ fnHandleClick, bIsDisabled, oRef }) => (
  <Button
    fnHandleClick={fnHandleClick}
    className='cancelButton'
    sFlavor='secondary'
    bIsDisabled={bIsDisabled}
    oRef={oRef}
  >
    Cancel
  </Button>
);

CancelButton.propTypes = {
  fnHandleClick: PropTypes.func.isRequired,
  bIsDisabled: PropTypes.bool,
  oRef: fnElementRefPropType,
};

CancelButton.defaultProps = {
  bIsDisabled: false,
  oRef: null,
};

export default CancelButton;
