import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import { useActiveFormSection } from '../../../../../utils/hooks/useActiveFormSection';
import NameWithQuickFindEditMode from './editMode';
import NameWithQuickFindViewMode from './viewMode';
import * as actions from './redux/actions';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';

const generateSelector =
  (recordPath) =>
  ({ Profile }) =>
    R.path(recordPath, Profile) || [];

const nameDataSelector = (recordPath) =>
  createSelector(generateSelector(recordPath), (data = []) => {
    // Add ID property to each record and set it to the relationship ID
    const transformedData = data.map((item) => {
      const copyItem = R.clone(item);
      copyItem.ID = copyItem.RELATIONSHIP.ID;
      return copyItem;
    });
    return transformedData;
  });

const NameWithQuickFind = ({
  recordPath,
  sTitle,
  bAllowMultiple,
  name,
  bIsPrivacyChangeable,
  label,
  codeTablesRelationshipType,
}) => {
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  const nameData = useSelector(nameDataSelector(recordPath));
  const bShowSection = nameData.length > 0 || bIsEditMode;
  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsEditMode(true);
    } else {
      fnSetIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    bShowSection && (
      <ProfileSubsectionLayout
        sTitle={sTitle}
        right={
          bIsEditMode ? (
            <NameWithQuickFindEditMode
              data={nameData}
              sTitle={sTitle}
              bAllowMultiple={bAllowMultiple}
              actions={actions[name]}
              name={name}
              bIsPrivacyChangeable={bIsPrivacyChangeable}
              label={label}
              codeTablesRelationshipType={codeTablesRelationshipType}
            />
          ) : (
            <NameWithQuickFindViewMode data={nameData} name={name} />
          )
        }
      />
    )
  );
};

NameWithQuickFind.defaultProps = {
  recordPath: ['BIOGRAPHICAL'],
  sTitle: 'Name',
  bAllowMultiple: false,
  bIsPrivacyChangeable: false,
  label: '',
  codeTablesRelationshipType: '',
};

NameWithQuickFind.propTypes = {
  recordPath: PropTypes.arrayOf(PropTypes.string),
  sTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  bAllowMultiple: PropTypes.bool,
  bIsPrivacyChangeable: PropTypes.bool,
  label: PropTypes.string,
  codeTablesRelationshipType: PropTypes.string,
};

export default NameWithQuickFind;
