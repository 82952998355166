import DateAndTime from 'date-and-time';

/**
 * Formats a sub event's date and time.
 *
 * @example
 * fnFormatSubEventDateAndTime('2023-01-27T10:00:00', '2023-01-27T11:30:00')
 * // 'Jan 27 at 10:00 AM - 11:30 AM'
 *
 * @param {string} sStartDate - START_DATE_TIME from DAD
 * @param {string} sEndDate - END_DATE_TIME from DAD
 * @param {string} sTimezone - TIME_ZONE from DAD
 * @returns {string}
 */
export const fnFormatSubEventDateAndTime = (
  sStartDate,
  sEndDate,
  sTimezone = ''
) => {
  if (!sStartDate && !sEndDate) {
    return '';
  }
  const oStartDate = DateAndTime.parse(sStartDate, 'YYYY-MM-DDTHH:mm:ss');
  const oEndDate = DateAndTime.parse(sEndDate, 'YYYY-MM-DDTHH:mm:ss');

  const sStartTime = DateAndTime.format(oStartDate, 'HH:mm');
  const sEndTime = DateAndTime.format(oEndDate, 'HH:mm');
  const bTimeIsTbd = sStartTime === '00:00' && sEndTime === '00:01';

  const bIsSingleDayEvent = DateAndTime.isSameDay(oStartDate, oEndDate);

  const sStartFormat = 'MMM DD at h:mm A'; // Jan 27 at 10:00 AM
  const sEndFormat = bIsSingleDayEvent ? 'h:mm A' : 'MMM DD at h:mm A';

  let sFormatted = bTimeIsTbd
    ? 'To Be Announced'
    : `${DateAndTime.format(oStartDate, sStartFormat)} - ${DateAndTime.format(
        oEndDate,
        sEndFormat
      )}`;

  if (sTimezone && !bTimeIsTbd) {
    sFormatted += ` ${sTimezone}`;
  }

  return sFormatted;
};

// Determine if the authenticated user is allowed to see the event.
export const fnShowEventInviteOnly = (oEvent, bIsAuthenticated) => {
  if (!oEvent || !bIsAuthenticated) {
    return false;
  }

  if (oEvent.SUB_EVENTS?.length > 0) {
    // multi-level event
    return oEvent.SUB_EVENTS.every(
      (oSubEvent) =>
        bIsAuthenticated &&
        oSubEvent.REGISTRATION.IS_REGISTERED === 0 &&
        oSubEvent.REGISTRATION.OPEN_INVITATION === 0 &&
        oSubEvent.INVITATION.IS_INVITED === 0 &&
        oSubEvent.INVITATION.IS_INCLUDED === 0
    );
  }
  // single event
  return (
    bIsAuthenticated &&
    oEvent.REGISTRATION.IS_REGISTERED === 0 &&
    oEvent.REGISTRATION.OPEN_INVITATION === 0 &&
    oEvent.INVITATION.IS_INVITED === 0 &&
    oEvent.INVITATION.IS_INCLUDED === 0
  );
};

export default {};
