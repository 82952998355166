import React from 'react';

import IconOnlyButton from '../Buttons/IconOnlyButton';
import Button from '../Buttons/Button';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';

const fnPlaceholder = () => {};

const Buttons = () => (
  <>
    <h3>Regular Buttons</h3>
    <div className='styleGuide__regularButtons'>
      <p>Default</p>
      <Button fnHandleClick={fnPlaceholder}>Primary</Button>
      <Button sFlavor='secondary' fnHandleClick={fnPlaceholder}>
        Secondary
      </Button>
      <Button sFlavor='tertiary' fnHandleClick={fnPlaceholder}>
        Tertiary
      </Button>
      <p>Disabled</p>
      <Button fnHandleClick={fnPlaceholder} bIsDisabled>
        Primary
      </Button>
      <Button sFlavor='secondary' fnHandleClick={fnPlaceholder} bIsDisabled>
        Secondary
      </Button>
      <Button sFlavor='tertiary' fnHandleClick={fnPlaceholder} bIsDisabled>
        Tertiary
      </Button>
      <p>Loading</p>
      <Button fnHandleClick={fnPlaceholder} bIsLoading>
        Primary
      </Button>
      <Button sFlavor='secondary' fnHandleClick={fnPlaceholder} bIsLoading>
        Secondary
      </Button>
      <Button sFlavor='tertiary' fnHandleClick={fnPlaceholder} bIsLoading>
        Tertiary
      </Button>
    </div>

    <h3>Buttons with an Icon</h3>
    <div className='styleGuide__buttons'>
      <ButtonWithIcon fnHandleClick={fnPlaceholder} sIcon='plus'>
        Add Data
      </ButtonWithIcon>
      <ButtonWithIcon fnHandleClick={fnPlaceholder} sIcon='edit'>
        Edit Data
      </ButtonWithIcon>
      <ButtonWithIcon
        fnHandleClick={fnPlaceholder}
        sIcon='expand'
        sIconPosition='right'
      >
        More
      </ButtonWithIcon>
      <ButtonWithIcon fnHandleClick={fnPlaceholder} sIcon='edit' bIsLoading>
        Edit Data
      </ButtonWithIcon>
    </div>

    <h3>Icon-only Buttons</h3>
    <div className='styleGuide__iconOnlyButtons'>
      <p>Primary</p>
      <IconOnlyButton
        sIcon='edit'
        fnHandleClick={fnPlaceholder}
        sAriaLabel='Edit'
      />
      <IconOnlyButton
        sIcon='close'
        fnHandleClick={fnPlaceholder}
        sAriaLabel='Close'
      />
      <p>Secondary</p>
      <IconOnlyButton
        sIcon='plus'
        fnHandleClick={fnPlaceholder}
        sAriaLabel='Add'
        sFlavor='secondary'
      />
      <IconOnlyButton
        sIcon='minus'
        fnHandleClick={fnPlaceholder}
        sAriaLabel='Remove'
        sFlavor='secondary'
      />
      <p>Tertiary</p>
      <IconOnlyButton
        sIcon='ellipsis'
        fnHandleClick={fnPlaceholder}
        sAriaLabel='Options'
        sFlavor='tertiary'
      />
      <IconOnlyButton
        sIcon='info'
        fnHandleClick={fnPlaceholder}
        sAriaLabel='Info'
        sFlavor='tertiary'
      />
    </div>
  </>
);

export default Buttons;
