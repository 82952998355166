import React from 'react';
import PropTypes from 'prop-types';

import { oCurrencyFormatter } from 'utils/common';
import { Select } from 'components/Form/Fields';
import { fnUpdateGuestRegistrationOption } from '../redux/guestHelpers';

const RegistrationOptionField = ({
  sFieldName,
  aFieldOptions,
  sGuestLookupId,
  sEventLookupId,
}) => (
  <Select
    name={sFieldName}
    id={`${sEventLookupId}-${sGuestLookupId}-registration-option`}
    label='Option'
    options={aFieldOptions}
    getOptionLabel={(oOption) => {
      if (oOption.COST) {
        return `${oOption.NAME} (${oCurrencyFormatter.format(oOption.COST)})`;
      }
      return oOption.NAME;
    }}
    getOptionValue={(oOption) => oOption.NAME}
    onChange={(oOption) => {
      fnUpdateGuestRegistrationOption(sGuestLookupId, sEventLookupId, oOption);
    }}
    sCypressId={`event-${sEventLookupId}-registrant-registration-option-select`}
  />
);

RegistrationOptionField.propTypes = {
  sFieldName: PropTypes.string.isRequired,
  aFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      NAME: PropTypes.string.isRequired,
      COST: PropTypes.number,
    })
  ).isRequired,
  sGuestLookupId: PropTypes.string.isRequired,
  sEventLookupId: PropTypes.string.isRequired,
};

export default RegistrationOptionField;
