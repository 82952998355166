import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * Responsible for step indicator on checkout tracker.
 *
 * @param {object} props - The props passed to this component.
 * @param {int} props.iStepNumber - The step number for the tracker.
 * @param {string} props.sStepLabel - The step label for the tracker.
 * @param {string} props.sLink - URL to link to.
 * @param {int} props.iCurrentStepNumber - Number for the current step.
 */
const CheckoutTrackerStep = ({
  iStepNumber,
  sStepLabel,
  sLink,
  iCurrentStepNumber,
}) => {
  const fnNavigate = useNavigate();
  const bIsActive = iStepNumber <= iCurrentStepNumber;
  const iBackSteps = iCurrentStepNumber - iStepNumber;

  const sTrackerMarkup = (
    <>
      <span
        className={`checkoutTrackerStep__label ${
          bIsActive ? 'checkoutTrackerStep__label--active' : ''
        }`}
      >
        {sStepLabel}
      </span>
      <span
        className={`checkoutTrackerStep__number ${
          bIsActive ? 'checkoutTrackerStep__number--active' : ''
        }`}
      >
        {iStepNumber}
      </span>
    </>
  );

  return (
    <li
      className={`checkoutTrackerStep ${
        bIsActive ? 'checkoutTrackerStep--active' : ''
      }`}
    >
      {sLink ? (
        <button
          type='button'
          onClick={() => fnNavigate(`-${iBackSteps}`)}
          className='checkoutTrackerStep__link checkoutTrackerStep__link--clickable'
        >
          {sTrackerMarkup}
        </button>
      ) : (
        <div className='checkoutTrackerStep__link'>{sTrackerMarkup}</div>
      )}
    </li>
  );
};

CheckoutTrackerStep.defaultProps = {
  sLink: '',
};

CheckoutTrackerStep.propTypes = {
  iStepNumber: PropTypes.number.isRequired,
  iCurrentStepNumber: PropTypes.number.isRequired,
  sStepLabel: PropTypes.string.isRequired,
  sLink: PropTypes.string,
};

export default CheckoutTrackerStep;
