import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import { ResultsList } from './ResultsList';
import SortingModal from './SortingModal';

const AdvancedSearchResults = ({ setShowResults, initialSearchResults }) => {
  const [searchResults, setSearchResults] = useState(initialSearchResults);
  const fnHandleClose = () => {
    setShowResults(false);
  };

  return (
    <>
      <div className='advancedSearch__resultsWrapper'>
        <IconOnlyButton
          sIcon='arrowLeft'
          className='advancedSearch__backBtn'
          sFlavor='tertiary'
          fnHandleClick={fnHandleClose}
          sAriaLabel='Back'
        />
        <h3 className='advancedSearch__resultsHeader t-paragraph--medium'>
          Search Results
        </h3>
        <SortingModal
          dataToSort={searchResults}
          setSortedData={setSearchResults}
        />
      </div>
      <div className='advancedSearch__resultsList'>
        <ResultsList results={searchResults} />
      </div>
    </>
  );
};

export default AdvancedSearchResults;

AdvancedSearchResults.propTypes = {
  setShowResults: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialSearchResults: PropTypes.array.isRequired,
};
