import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActiveFormSection } from '../../../../../utils/hooks/useActiveFormSection';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import ReligionViewMode from './viewMode';
import ReligionEditMode from './editMode';

const Religion = () => {
  const oReligionData = useSelector(
    (state) => state.Profile.BIOGRAPHICAL.RELIGION
  );
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  const bShowReligionSection = oReligionData || bIsEditMode;
  useEffect(() => {
    fnSetIsEditMode(activeFormSection === 'personal');
  }, [activeFormSection]);

  return (
    bShowReligionSection && (
      <ProfileSubsectionLayout
        sTitle='Religion'
        right={
          bIsEditMode ? (
            <ReligionEditMode oReligionData={oReligionData} />
          ) : (
            <ReligionViewMode oReligionData={oReligionData} />
          )
        }
      />
    )
  );
};

export default Religion;
