import { createActions } from 'redux-actions';

export const { get, set, addNote, update, add } = createActions(
  {
    GET: (action) => action,
    SET: (action) => action,
    ADD: (action) => action,
    ADD_NOTE: (action) => action,
    UPDATE: (action) => action,
  },
  { prefix: 'Scholarships' }
);
