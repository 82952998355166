import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { Select } from 'components/Form/Fields';
import MyNetworkSearch from './MyNetworkSearch';
import {
  oInitialValues,
  fnSelectFilterableRelationshipTypes,
  fnDebouncedFilterAndSearch,
} from './helpers';
import { fnSelectMyNetworkConnections } from '../helpers';

const MyNetworkListControls = ({ fnSetFilteredConnections }) => {
  const aMyNetworkConnections = useSelector(fnSelectMyNetworkConnections);
  const aRelationshipTypeFilters = useSelector(
    fnSelectFilterableRelationshipTypes
  );
  const [oControls, fnSetControls] = useState({ search: '', filter: null });

  const fnUpdateSearch = async (sQuery) => {
    fnSetControls((oPreviousValue) => ({ ...oPreviousValue, search: sQuery }));
  };

  const fnUpdateFilter = (oNewFilter) => {
    fnSetControls((oPreviousValue) => ({
      ...oPreviousValue,
      filter: oNewFilter,
    }));
  };

  useEffect(() => {
    const fnReset = () => fnSetFilteredConnections(aMyNetworkConnections);

    if (R.equals(oControls, oInitialValues)) {
      fnReset();
    } else {
      fnDebouncedFilterAndSearch(oControls, aMyNetworkConnections, (aResults) =>
        fnSetFilteredConnections(aResults)
      );
    }
  }, [oControls, aMyNetworkConnections, fnSetFilteredConnections]);

  return (
    <Formik initialValues={oInitialValues}>
      <Form className='myNetwork__searchFilter'>
        <MyNetworkSearch fnOnChange={fnUpdateSearch} />
        <Select
          label='Filter'
          name='filter'
          id='filter-my-network'
          placeholder='Choose'
          options={aRelationshipTypeFilters}
          className='myNetwork__relationshipFilter'
          onChange={fnUpdateFilter}
        />
      </Form>
    </Formik>
  );
};

MyNetworkListControls.propTypes = {
  fnSetFilteredConnections: PropTypes.func.isRequired,
};

export default MyNetworkListControls;
