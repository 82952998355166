import React from 'react';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';

const SelectedEventsList = () => {
  const oFormikProps = useFormikContext();
  const bHasSelectedEvents = oFormikProps.values?.subEvents?.length > 0;
  return bHasSelectedEvents ? (
    <div className='multiLevelEventDetails__eventsSelected'>
      <div>
        <h3 className='multiLevelEventDetails__eventsSelectedTitle t-paragraph--medium'>
          Events Selected
        </h3>
        <ul className='multiLevelEventDetails__eventsSelectedList'>
          {(oFormikProps.values?.subEvents || []).map((sSubEvent) => {
            const oSubEvent = JSON.parse(sSubEvent);
            return (
              <li
                key={oSubEvent.LOOKUPID}
                className='multiLevelEventDetails__eventsSelectedItem t-paragraph--small'
              >
                <FontAwesomeIcon icon={oIconLibrary.fontAwesome.calendarStar} />
                {oSubEvent.TITLE}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default SelectedEventsList;
