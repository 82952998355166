import { createActions } from 'redux-actions';

export const { dismissEmailActivationModal } = createActions(
  {
    DISMISS_EMAIL_ACTIVATION_MODAL: (action) => action,
  },
  {
    prefix: 'Meta',
  }
);

export default { dismissEmailActivationModal };
