/**
 * Determines if an event-specific checkpoint step should be included in the event registration flow.
 *
 * @description Returns true if the following conditions are met:
 * 1. There is more than one event in the cart
 * 2. The current event allows guests or has multiple registration options
 * 3. The current event is not the last event in the cart
 * 4. At least one of the remaining events in the cart allows guests or has multiple registration options
 *
 * @param {array} aEventsInCart - List of all events in the cart
 * @param {*} iCurrentIndex - Index of the current event in the cart being processed
 * @returns {boolean}
 */
const fnIncludeEventCheckpointStep = (aEventsInCart, iCurrentIndex) => {
  const { REGISTRATION } = aEventsInCart[iCurrentIndex].oDetails.oEvent;
  const bIsWaitlistEvent =
    aEventsInCart[iCurrentIndex]?.sType === 'event-waitlist';
  const bAllowsGuests = REGISTRATION.MAX_GUESTS > 0;
  const bHasMultipleRegOptions = REGISTRATION.REGISTRATION_OPTIONS?.length > 1;
  const bIsLastSubEvent = iCurrentIndex === aEventsInCart.length - 1;
  const bRemainingEventAllowsGuestsOrHasMultipleRegOptions = aEventsInCart.some(
    (oNextEventInCart, iRemainingIndex) => {
      if (iRemainingIndex <= iCurrentIndex) {
        return false;
      }
      const { REGISTRATION: oNextEventRegistration } =
        oNextEventInCart.oDetails.oEvent;
      const bNextEventIsWaitlistEvent =
        oNextEventInCart?.sType === 'event-waitlist';
      const bNextEventHasMultipleRegOptions =
        oNextEventRegistration.REGISTRATION_OPTIONS?.length > 1;
      const bNextEventAllowsGuests = oNextEventRegistration.MAX_GUESTS > 0;

      return (
        bNextEventAllowsGuests ||
        (bNextEventHasMultipleRegOptions && !bNextEventIsWaitlistEvent)
      );
    }
  );

  const bIncludeEventCheckpointStep =
    aEventsInCart.length > 1 &&
    (bAllowsGuests || (bHasMultipleRegOptions && !bIsWaitlistEvent)) &&
    !bIsLastSubEvent &&
    bRemainingEventAllowsGuestsOrHasMultipleRegOptions;

  return bIncludeEventCheckpointStep;
};

/**
 * Builds a list of all the applicable step paths for each event in the cart.
 *
 * @param {array} aEventsInCart - List of all events in the cart
 * @returns {array}
 */
const fnBuildEventSpecificNavSteps = (aEventsInCart) => {
  const aEventSpecificSteps = aEventsInCart.reduce(
    (aEventSteps, oEventInCart, iIndex) => {
      const { SLUG, REGISTRATION } = oEventInCart.oDetails.oEvent;
      const bIsWaitlistEvent = oEventInCart?.sType === 'event-waitlist';

      // @todo: Remove this once all events have slugs. This will save us time tracking down bugs due to missing SLUG values.
      if (!SLUG) {
        console.error(
          `Missing SLUG for event in cart. Event: ${oEventInCart.oDetails.oEvent.TITLE} - ${oEventInCart.oDetails.oEvent.LOOKUPID}`
        );
      }

      if (bIsWaitlistEvent) {
        aEventSteps.push(`/events/${SLUG}/register/waitlist`);
      }

      const bAllowsGuests = REGISTRATION.MAX_GUESTS > 0;
      if (bAllowsGuests) {
        aEventSteps.push(`/events/${SLUG}/register/guests`);
      }

      if (!bIsWaitlistEvent) {
        const bHasMultipleRegOptions =
          REGISTRATION.REGISTRATION_OPTIONS?.length > 1;
        if (bHasMultipleRegOptions) {
          aEventSteps.push(`/events/${SLUG}/register/reg-options`);
        }
      }

      if (fnIncludeEventCheckpointStep(aEventsInCart, iIndex)) {
        aEventSteps.push(`/events/${SLUG}/register/checkpoint`);
      }

      return aEventSteps;
    },
    []
  );
  return aEventSpecificSteps;
};

/**
 * Builds a list of all the applicable common event registration navigation step paths
 *
 * @param {array} aEventsInCart - List of all events in the cart
 * @returns {array}
 */
const fnBuildCommonEventRegNavSteps = (aEventsInCart) => {
  const aCommonEventRegNavSteps = [
    '/events/register/checkpoint',
    '/events/register/additional-info',
    '/events/register/contact',
    '/events/register/review',
  ];
  const bHasCost = aEventsInCart.some((oEventInCart) => {
    if (oEventInCart.oDetails.aGuests?.length > 0) {
      return oEventInCart.oDetails.aGuests.some(
        (oGuest) => oGuest.REG_OPTION?.COST > 0
      );
    }
    return oEventInCart.oDetails.oEvent.REGISTRATION.HAS_COST === 1;
  });
  if (bHasCost) {
    aCommonEventRegNavSteps.push('/events/register/payment');
  }

  aCommonEventRegNavSteps.push('/events/register/confirmation');

  return aCommonEventRegNavSteps;
};

/**
 * Builds an array of all the event registration navigation step paths
 *
 * @param {array} aEventsInCart - List of all the events in the cart; Results from `selectEventsInCart`
 * @returns {array} - An array of all the event registration navigation step paths
 */
export const fnBuildEventRegNavSteps = (aEventsInCart) => {
  if (aEventsInCart.length === 0) {
    return [];
  }

  const aEventSpecificSteps = fnBuildEventSpecificNavSteps(aEventsInCart);
  const aCommonEventRegNavSteps = fnBuildCommonEventRegNavSteps(aEventsInCart);

  const aEventRegNavSteps = [
    ...aEventSpecificSteps,
    ...aCommonEventRegNavSteps,
  ];

  return aEventRegNavSteps;
};

/**
 * Returns the path for the next step in the event registration flow
 *
 * @param {array} aAllEventRegistrationSteps - Results from `selectEventRegNavSteps`
 * @param {string} sCurrentPath - window.location.pathname.
 * @returns {string}
 *
 * @note Passing in `window.location.pathname` prevents the selector from being memoized
 * and potentially returning stale data. It also makes this function easier to test.
 */
export const fnGetNextEventRegStep = (
  aAllEventRegistrationSteps,
  sCurrentPath
) => {
  const iCurrentEventStepIndex =
    aAllEventRegistrationSteps.indexOf(sCurrentPath);

  // Check if the user is on the last step
  if (iCurrentEventStepIndex === aAllEventRegistrationSteps.length - 1) {
    return '';
  }

  const sNextUrl = aAllEventRegistrationSteps[iCurrentEventStepIndex + 1];
  return sNextUrl;
};
