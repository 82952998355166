import React from 'react';
import PropTypes from 'prop-types';

import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { OptionsModal } from '../../../../Form/OptionsModal';
import { Select } from '../../../../Form/Fields';
import { SelectPrivacy } from '../../../../Form/OptionsModal/DefaultFields/SelectPrivacy';

const NameWithQuickFindOptions = ({
  data,
  actions,
  forceExpanded,
  closeOnOutsideClick,
  bIsPrivacyChangeable,
  codeTablesRelationshipType,
  formikProps,
}) => {
  const relationshipOptions = useCodeTablesSection(
    'RELATIONSHIP',
    (aRelationshipCodes) => {
      const aFiltered = aRelationshipCodes.filter(
        (oRelationship) =>
          oRelationship.DETAILS.CATEGORY === codeTablesRelationshipType &&
          oRelationship.DESCRIPTION !== 'Spouse'
      );
      return aFiltered;
    }
  );

  return (
    <OptionsModal
      actions={actions}
      submitButtonHidden
      data={data}
      forceExpanded={forceExpanded}
      closeOnOutsideClick={closeOnOutsideClick}
      formikProps={formikProps}
      name={codeTablesRelationshipType}
    >
      {formikProps && (
        <div className='optionsModal__section'>
          <Select
            className='settingsDropdown'
            name='RELATIONSHIP'
            label='Relationship'
            options={relationshipOptions}
          />
        </div>
      )}
      <SelectPrivacy data={data} isPrivacyChangeable={bIsPrivacyChangeable} />
    </OptionsModal>
  );
};

NameWithQuickFindOptions.propTypes = {
  data: PropTypes.shape({
    CONSTITUENT: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        NAME: PropTypes.string,
        LOOKUPID: PropTypes.string,
      }),
    ]),
    RELATIONSHIP: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
    ]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object,
  forceExpanded: PropTypes.bool,
  bIsPrivacyChangeable: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formikProps: PropTypes.object,
  codeTablesRelationshipType: PropTypes.string.isRequired,
};

NameWithQuickFindOptions.defaultProps = {
  data: {},
  actions: {},
  forceExpanded: false,
  bIsPrivacyChangeable: true,
  closeOnOutsideClick: true,
  formikProps: null,
};

export default NameWithQuickFindOptions;
