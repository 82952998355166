import * as R from 'ramda';
import { Auth } from 'aws-amplify';

/**
 * Traverses object's structure to sanitize all primitive values.
 *
 * @param {object} oOriginalData - Object to traverse.
 */
export const fnSanitizeByDataType = (oOriginalData) => {
  const oClonedData = R.clone(oOriginalData);
  Object.entries(oClonedData).forEach(([sKey, mValue]) => {
    let sSanitizedValue = mValue ?? '';
    switch (typeof mValue) {
      case 'boolean':
        sSanitizedValue = mValue ? '1' : '0';
        break;
      case 'object':
        // `null` is an object too o.O
        if (!R.isNil(mValue)) {
          sSanitizedValue = fnSanitizeByDataType(mValue);
        }
        break;
      default:
        break;
    }
    oClonedData[sKey] = sSanitizedValue;
  });
  return oClonedData;
};

/**
 * Sanitizes data into DAD-approved formats.
 *
 * @param {object} oIncomingData - Form data to sanitize.
 * @returns {Promise}
 */
export const fnSanitizeDataForDad = async (oIncomingData) => {
  const oPromise = new Promise((resolve, reject) => {
    try {
      const oSanitizedData = fnSanitizeByDataType(oIncomingData);
      resolve(oSanitizedData);
    } catch (oError) {
      reject(oError);
    }
  });
  return oPromise;
};

export const fnHandleAxiosError = (oError) => {
  if (oError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return oError.response;
  }
  if (oError.request) {
    // The request was made but no response was received
    // `oError.request` is an instance of XMLHttpRequest in the browser
    return oError.request;
  }
  // Something happened in setting up the request that triggered an error
  return oError.message;
};

/**
 * Builds axios config.
 *
 * @param {boolean} bIsAuthenticatedRequest - If request requires authentication or not.
 * @param {string} oAbortSignal - AbortSignal property from an AbortController object.
 * @returns {object}
 */
export const buildAxiosConfig = async (
  bIsAuthenticatedRequest = true,
  oAbortSignal = null
) => {
  const oAxiosConfig = {
    signal: oAbortSignal,
  };
  if (bIsAuthenticatedRequest) {
    const sCognitoIdToken = await Auth.currentSession().then(
      (response) => response.idToken.jwtToken
    );
    oAxiosConfig.headers = {
      'Content-Type': 'application/json',
      Authorization: sCognitoIdToken,
    };
  } else {
    oAxiosConfig.withCredentials = false;
  }
  return oAxiosConfig;
};
