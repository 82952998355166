import React from 'react';

import { SkeletonCircle, SkeletonText } from 'components/LoadingZone/Skeleton';

const ClassmatesSkeleton = () => {
  return (
    <div className='classmatesSkeleton'>
      <div className='classmateSkeleton'>
        <SkeletonCircle size='large' />
        <div className='classmateSkeleton__details'>
          <SkeletonText size='medium' width='70%' />
          <SkeletonText size='medium' width='40%' />
          <SkeletonText size='medium' width='40%' />
        </div>
      </div>
      <div className='classmateSkeleton'>
        <SkeletonCircle size='large' />
        <div className='classmateSkeleton__details'>
          <SkeletonText size='medium' width='70%' />
          <SkeletonText size='medium' width='40%' />
          <SkeletonText size='medium' width='40%' />
        </div>
      </div>
      <div className='classmateSkeleton'>
        <SkeletonCircle size='large' />
        <div className='classmateSkeleton__details'>
          <SkeletonText size='medium' width='70%' />
          <SkeletonText size='medium' width='40%' />
          <SkeletonText size='medium' width='40%' />
        </div>
      </div>
    </div>
  );
};

export default ClassmatesSkeleton;
