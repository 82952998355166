import { createSelector } from 'reselect';

/**
 * Selects all items in the cart.
 *
 * @param {object} state - Redux store state
 * @returns {array} - List of cart items
 */
export const selectCart = (state) => state.Checkout.aCart;

/**
 * Selects all events and waitlisted events in the cart.
 */
export const selectAllEventsInCart = createSelector([selectCart], (aCart) =>
  aCart.filter(
    (oCartItem) =>
      oCartItem.sType === 'event' || oCartItem.sType === 'event-waitlist'
  )
);

/**
 * Returns an array of all the events in the cart
 */
export const selectEventsInCart = createSelector([selectCart], (aCart) =>
  aCart.filter((oCartItem) => oCartItem.sType === 'event')
);

/**
 * Selects all waitlisted events in the cart.
 */
export const selectWaitlistEventsInCart = createSelector(
  [selectCart],
  (aCart) => aCart.filter((oCartItem) => oCartItem.sType === 'event-waitlist')
);

export const selectEventInCartByLookupId = (sEventLookupId) =>
  createSelector([selectAllEventsInCart], (aEventsInCart) =>
    aEventsInCart.find((oEvent) => oEvent.sId === sEventLookupId)
  );

export const selectEventInCartBySlug = (sEventSlug) =>
  createSelector([selectAllEventsInCart], (aEventsInCart) =>
    aEventsInCart.find((oEvent) => oEvent.oDetails.oEvent.SLUG === sEventSlug)
  );
