import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as R from 'ramda';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import { fnFocusNextField, fnGetErrors } from './helpers';

/**
 * Allows user to enter the last four digits of their SSN.
 */
const SsnLastFourField = ({ matchType }) => {
  const oFormikContext = useFormikContext();
  const [sFieldType, fnSetFieldType] = useState('password');

  const fnHandleChange = (oEvent) => {
    const sFieldName = oEvent.target.name;
    const sFieldValue = oEvent.target.value;

    const bIsSingleDigitNumber = /\d/.test(sFieldValue);
    const bIsValidValue = sFieldValue === '' || bIsSingleDigitNumber;
    if (!bIsValidValue) {
      return;
    }

    oFormikContext.setFieldValue(sFieldName, sFieldValue);
    fnFocusNextField(sFieldName, sFieldValue);
  };

  const fnBuildSsnFieldsMarkup = () => {
    const ssnFieldsMarkup = [];
    for (let i = 1; i <= 4; i++) {
      const sWrapperClassName = R.isEmpty(oFormikContext.errors)
        ? 'boxField'
        : 'boxField boxField--error';
      ssnFieldsMarkup.push(
        <div key={i} className={sWrapperClassName}>
          <label htmlFor={`ssnField-${i}`} className='hide_accessibly'>
            SSN Input {i}
          </label>
          <input
            type={sFieldType}
            autoComplete='off'
            onChange={fnHandleChange}
            id={`ssnField-${i}`}
            name={`ssn${i}`}
            className={`ssnField__input ${
              sFieldType === 'password' ? 'ssnField__input--password' : ''
            }`}
            maxLength='1'
            value={oFormikContext.values[`ssn${i}`]}
          />
        </div>
      );
    }
    return ssnFieldsMarkup;
  };

  const fnBuildErrorMarkup = () => {
    const aErrorsSet = fnGetErrors(oFormikContext);
    const aErrorMarkup = Array.from(aErrorsSet, (sError, iIndex) => (
      <div className='field__errorMessage' key={`ssn${iIndex}`}>
        {sError}
      </div>
    ));
    return aErrorMarkup;
  };

  const fnToggleSsnVisibility = () =>
    fnSetFieldType(sFieldType === 'password' ? 'text' : 'password');

  return (
    <>
      <h2>
        Welcome back! To keep your info safe, we just need you to verify your
        identity.
      </h2>
      <fieldset className='ssnLastFour'>
        <legend className='ssnLastFour__legend'>
          The easiest way is to enter the last four of your
          {matchType === 'parent' ? " student's" : ''} SSN below:
        </legend>
        <div className='ssnLastFour__fieldContainer'>
          {fnBuildSsnFieldsMarkup()}
          <IconOnlyButton
            sIcon={sFieldType === 'password' ? 'eye' : 'eyeSlash'}
            fnHandleClick={fnToggleSsnVisibility}
            sFlavor='tertiary'
            sAriaLabel='Toggle SSN visibility'
            className='ssnLastFour__toggleVisibility'
          />
        </div>
        {fnBuildErrorMarkup()}
      </fieldset>
    </>
  );
};

SsnLastFourField.propTypes = {
  matchType: PropTypes.string.isRequired,
};

export default SsnLastFourField;
