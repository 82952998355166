import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';

/**
 * Displays list of skills.
 */
const SkillsViewMode = ({ aSkills }) => (
  <ul className='skills'>
    {aSkills.map((oSkill, iIndex) => {
      const bIsPrivate = oSkill.PRIVACY === '2';
      let sSeparator = '';
      // When there's more than 1 skill, add a comma and a non-breaking space between them
      if (aSkills.length > 1 && iIndex !== aSkills.length - 1) {
        sSeparator = ',\u00A0';
      }
      return (
        <li
          className='record record--skill t-paragraph--medium'
          key={oSkill.ID}
        >
          {oSkill.NAME}
          {!bIsPrivate ? (
            sSeparator
          ) : (
            <span>
              ( <RecordPrivacyIcon ariaId={oSkill.ID} /> ){sSeparator}
            </span>
          )}
        </li>
      );
    })}
  </ul>
);

SkillsViewMode.defaultProps = {
  aSkills: [],
};

SkillsViewMode.propTypes = {
  aSkills: PropTypes.array,
};

export default SkillsViewMode;
