import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { CancelButton, DeleteButton } from 'components/Form/Buttons';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import actions from '../../../redux/actions';

/**
 * Displays a warning message before allowing the user to delete an employer.
 */
const ConfirmDeleteEmployer = ({ sEmployerRelationshipId, fnHandleCancel }) => {
  const [bIsDeleting, fnSetIsDeleting] = useState(false);
  const fnDispatch = useDispatch();

  const fnDeleteEmployer = async () => {
    fnSetIsDeleting(true);
    await new Promise((resolve, reject) => {
      fnDispatch(
        actions.delete({
          data: { ID: sEmployerRelationshipId },
          resolve,
          reject,
        })
      );
    });
    fnSetIsDeleting(false);
  };

  return (
    <>
      <fieldset className='optionsModal__section'>
        <legend className='optionsModal__sectionTitle'>Alert</legend>
        <p className='optionsModal__text'>
          Are you sure you want to delete this employer?
        </p>
        <p className='optionsModal__text'>
          This will delete all job titles associated with this employer.
        </p>
      </fieldset>
      <div className='optionsModal__section'>
        <DeleteButton
          sLabel='Yes, Delete Employer'
          fnHandleClick={fnDeleteEmployer}
          sFlavor='primary'
          bIsLoading={bIsDeleting}
        />
      </div>
      <CancelButton fnHandleClick={fnHandleCancel} bIsDisabled={bIsDeleting} />
    </>
  );
};

ConfirmDeleteEmployer.propTypes = {
  sEmployerRelationshipId: PropTypes.string.isRequired,
  fnHandleCancel: PropTypes.func.isRequired,
};

export default ConfirmDeleteEmployer;
