import { put, takeEvery, select } from 'redux-saga/effects';

import oWnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';
import * as claimedClassmatesActions from './ClaimedClassmates/actions';
import * as myClassmatesActions from './AllClassmates/actions';
import {
  classmateDataNormalizer,
  claimedClassmateDataNormalizer,
} from './apiDataNormalizers';

function* getClaimedClassmates({ payload }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestUrl = `constituents/${sLookupId}/claimed_classmates`;
  const oResponse = yield oWnApi.get(sRequestUrl);
  if (oResponse.status === 200) {
    const oClaimedClassmatesData = oResponse.data.ITEMS.DATA || {};
    let aClaimedClassmates = [];
    if (oClaimedClassmatesData.NAME !== 'No results') {
      aClaimedClassmates = yield claimedClassmateDataNormalizer.validate(
        oClaimedClassmatesData
      );
    }
    yield put(
      claimedClassmatesActions.set({ claimedClassmates: aClaimedClassmates })
    );
    if ((payload || {}).resolve) {
      payload.resolve('Success');
    }
  } else if ((payload || {}).reject) {
    payload.reject('Error');
  }
}

function* getAllClassmates({ payload: { classYear } }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  let sRequestUrl = `constituents/${sLookupId}/classmates`;
  if (classYear) {
    sRequestUrl = `${sRequestUrl}?classyear=${classYear}`;
  }
  const oResponse = yield oWnApi.get(sRequestUrl);
  if (oResponse.status === 200) {
    const oOptions = oResponse.data?.REUNION_YEARS;
    const allClassmates = yield classmateDataNormalizer.validate(
      oResponse.data.CLASSMATES
    );
    const oResult = {
      data: allClassmates,
      options: oOptions,
    };
    yield put(myClassmatesActions.set(oResult));
  }
}

function* createNote({
  payload: { classmateLookupId, noteData, resolve, reject },
}) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestUrl = `constituents/${sLookupId}/add_note`;
  const oRequestPayload = {
    TYPE: 'Note',
    data: {
      prospectLookupId: classmateLookupId,
      TITLE: 'Volunteer Note',
      ...noteData,
    },
  };
  const oResponse = yield oWnApi.post(sRequestUrl, oRequestPayload);
  if (oResponse.status === 200) {
    resolve('success');
  } else {
    reject('Error');
  }
}

function* deleteClaimedClassmate({
  payload: { classmateLookupId, classYear, resolve, reject },
}) {
  const oRequestPayload = {
    TYPE: 'Unclaim',
    ID: classmateLookupId,
  };
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestUrl = `constituents/${sLookupId}/claimed_classmates`;
  const oResponse = yield oWnApi.delete(sRequestUrl, oRequestPayload);
  if (oResponse.status === 200) {
    const oClaimedClassmatesData = oResponse.data.ITEMS.DATA || {};
    let aClaimedClassmates = [];
    if (oClaimedClassmatesData.NAME !== 'No results') {
      aClaimedClassmates = yield claimedClassmateDataNormalizer.validate(
        oClaimedClassmatesData
      );
    }
    yield put(
      claimedClassmatesActions.set({ claimedClassmates: aClaimedClassmates })
    );
    yield put(myClassmatesActions.get({ classYear }));
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* addClaimedClassmate({ payload: { data, resolve, reject } }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestUrl = `constituents/${sLookupId}/claimed_classmates`;
  const oRequestPayload = {
    TYPE: 'Claim',
    data: {
      prospect: data.classmateLookupId,
      classYear: data.classYear,
    },
  };
  const oResponse = yield oWnApi.post(sRequestUrl, oRequestPayload);
  if (oResponse.status === 200) {
    resolve(oResponse.data);
  } else {
    reject('Error');
  }
}

export default function* watchVolunteers() {
  yield takeEvery('Volunteers/ClaimedClassmates/GET', getClaimedClassmates);
  yield takeEvery('Volunteers/AllClassmates/GET', getAllClassmates);
  yield takeEvery(
    (action) => /^Volunteers\/([a-zA-Z_]+\/)+ADD_NOTE/.test(action.type),
    createNote
  );
  yield takeEvery(
    (action) => /^Volunteers\/([a-zA-Z_]+\/)+REMOVE/.test(action.type),
    deleteClaimedClassmate
  );
  yield takeEvery(
    (action) => /^Volunteers\/([a-zA-Z_]+\/)+ADD$/.test(action.type),
    addClaimedClassmate
  );
}
