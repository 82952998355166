import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import ButtonWithPopupMenu from 'components/MyProfile/elements/layout/ButtonWithPopupMenu';
import { SelectPrivacy } from 'components/Form/OptionsModal/DefaultFields';
import { DeleteButton } from 'components/Form/Buttons';
import ConfirmDeleteEmployer from './ConfirmDeleteEmployer';

/**
 * Allows the user to change an employer's privacy or delete the employer.
 */
const EmployerOptions = () => {
  const [bShowAreYouSure, fnSetShowAreYouSure] = useState(false);
  const oFormikContext = useFormikContext();
  const sEmployerRelationshipId =
    oFormikContext.values.EMPLOYER_RELATIONSHIP_ID;

  const fnOpenAreYouSure = () => {
    fnSetShowAreYouSure(true);
  };

  const fnCloseAreYouSure = () => {
    fnSetShowAreYouSure(false);
  };

  return (
    <ButtonWithPopupMenu className='employerOptions'>
      {bShowAreYouSure ? (
        <ConfirmDeleteEmployer
          sEmployerRelationshipId={sEmployerRelationshipId}
          fnHandleCancel={fnCloseAreYouSure}
        />
      ) : (
        <>
          <SelectPrivacy
            data={{
              ID: sEmployerRelationshipId,
              PRIVACY: oFormikContext.initialValues.PRIVACY,
            }}
          />
          <DeleteButton
            sLabel='Delete Employer'
            fnHandleClick={fnOpenAreYouSure}
          />
        </>
      )}
    </ButtonWithPopupMenu>
  );
};

export default EmployerOptions;
