import { object, string } from 'yup';

const contactUsSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  comments: string().required('Please enter some details'),
});

export default contactUsSchema;
