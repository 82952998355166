import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { triggerGoogleTagManagerEvent } from 'utils/common';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import oWnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import Button from 'components/Buttons/Button';
import oIconLibrary from 'utils/iconLibrary';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import AddNote from '../OptionsModal/DefaultFields/AddNote';

const HelpRequest = ({
  className,
  sNoteType,
  sNoteTitle,
  sGtmCategory,
  sGtmAction,
}) => {
  const [isHelpPopupVisible, setIsHelpPopupVisible] = useState(false);
  const [isAddNoteVisible, setIsAddNoteVisible] = useState(true);
  const [addNoteStatus, setAddNoteStatus] = useState('');
  const aCodes = useCodeTablesSection('CONSTITUENTNOTETYPE');
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const oPopupRef = useRef(null);

  const handleSubmitDetails = async ({ data, resolve, reject }) => {
    triggerGoogleTagManagerEvent(sGtmCategory, sGtmAction);
    const oNoteTypeCode = aCodes.find((item) => item.DESCRIPTION === sNoteType);
    const sRequestUrl = `constituents/${sLookupId}/add_note`;
    const oResponse = await oWnApi.post(sRequestUrl, {
      TYPE: 'Note',
      data: {
        TITLE: sNoteTitle,
        TEXTNOTE: data.TEXTNOTE,
        NOTETYPECODEID: oNoteTypeCode.ID,
      },
    });
    if (oResponse.status === 200) {
      resolve('Success');
    } else {
      reject('Error');
    }
  };

  useCloseOnOutsideClick(oPopupRef, setIsHelpPopupVisible);

  return (
    <div className={`helpRequest ${className}`}>
      <IconOnlyButton
        sIcon='help'
        fnHandleClick={() => setIsHelpPopupVisible(!isHelpPopupVisible)}
        sAriaLabel='Help'
        sFlavor='tertiary'
      />

      {isHelpPopupVisible && (
        <div className='helpRequest__popup' ref={oPopupRef}>
          {addNoteStatus === '' && (
            <p className='helpRequest__title'>Have a question?</p>
          )}

          {addNoteStatus !== '' && (
            <div className='helpRequest__statusIconWrapper'>
              <FontAwesomeIcon
                className={
                  addNoteStatus === 'success'
                    ? 'helpRequest__successIcon'
                    : 'helpRequest__errorIcon'
                }
                icon={
                  oIconLibrary.fontAwesome[
                    addNoteStatus === 'success' ? 'check' : 'close'
                  ]
                }
              />
            </div>
          )}

          <AddNote
            visible={isAddNoteVisible}
            setVisible={setIsAddNoteVisible}
            onSubmit={handleSubmitDetails}
            status={addNoteStatus}
            setFormStatus={setAddNoteStatus}
            successMsg='Your request has been sent. We will be in touch with you shortly!'
            classname='addNoteCallout'
          />

          {addNoteStatus !== '' && (
            <Button
              fnHandleClick={() => setIsHelpPopupVisible(false)}
              className='helpRequest__doneBtn'
            >
              Done
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

HelpRequest.defaultProps = {
  className: '',
  sNoteType: '',
  sNoteTitle: 'Help Request',
  sGtmCategory: 'Help',
  sGtmAction: 'submitted help request',
};

HelpRequest.propTypes = {
  className: PropTypes.string,
  sNoteType: PropTypes.string,
  sNoteTitle: PropTypes.string,
  sGtmCategory: PropTypes.string,
  sGtmAction: PropTypes.string,
};

export default HelpRequest;
