import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import SkillsEditMode from './editMode';
import SkillsViewMode from './viewMode';

/**
 * Displays skills in view or edit mode based on active form section.
 */
const Skills = () => {
  const aSkills = useSelector((state) => state.Profile.JOB_SPECIALTY);
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  const bShowSection = aSkills.length > 0 || bIsEditMode;
  useEffect(() => {
    if (activeFormSection === 'career') {
      fnSetIsEditMode(true);
    } else {
      fnSetIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    bShowSection && (
      <ProfileSubsectionLayout
        sTitle='Job Skills'
        right={
          bIsEditMode ? (
            <SkillsEditMode aSkills={aSkills} />
          ) : (
            <SkillsViewMode aSkills={aSkills} />
          )
        }
      />
    )
  );
};

export default Skills;
