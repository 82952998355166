import DateAndTime from 'date-and-time';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { date } from 'yup';

import { oMinimumDateSchema } from 'utils/schemas/fields';

/**
 * Determines if the constituent has any visible positions for an employer.
 *
 * @example A public profile with a public employer but a private position results in a false'
 * @param {object} oEmployer - Employer data.
 * @returns {bool}
 */
export const fnEmployerHasPositions = (oEmployer) => {
  const bHasPosition = (oEmployer.POSITIONS || []).length > 0;
  return bHasPosition;
};

/**
 * Determines if the constituent has any employers with multiple
 * postions.
 *
 * @param {array} aEmployment - All employment data.
 * @returns {bool}
 */
export const fnHasEmployerWithMultiplePositions = (aEmployment) => {
  const bHasEmployerWithMultiplePositions = aEmployment.some(
    (oEmployer) => oEmployer.POSITIONS.length > 1
  );
  return bHasEmployerWithMultiplePositions;
};

/**
 * For a date range with both start and end dates,
 * determines if the start date is before the end date.
 *
 * @param {DateAndTime} oStartDate - Start date
 * @param {DateAndTime} oEndDate - End date
 * @returns {bool}
 */
const fnValidateDateRange = (oStartDate, oEndDate) => {
  let bIsValid = true;
  if (oStartDate && oEndDate && oStartDate > oEndDate) {
    bIsValid = false;
  }
  return bIsValid;
};

/**
 * Formats an employment end date based on available data.
 *
 * @param {DateAndTime} oEndDate - End date
 * @param {*} sStatus - Job status. One of 'Active', 'Former', or 'Retired'
 * @returns {string}
 */
const fnGetEndDateText = (oEndDate, sStatus) => {
  let sEndDateText = '';
  switch (true) {
    case !!oEndDate:
      sEndDateText = DateAndTime.format(oEndDate, 'MMM YYYY');
      break;
    case sStatus === 'Active' || sStatus === 'Part Time':
      sEndDateText = 'Present';
      break;
    default:
      sEndDateText = 'Unknown';
      break;
  }
  return sEndDateText;
};

/**
 * Formats employment date range.
 *
 * @param {string} sStartDate - Start date in YYYY-MM-DD format
 * @param {string} sEndDate - End date in YYYY-MM-DD format
 * @param {string} sStatus - Job status. Either 'Active' or 'Former'
 * @returns {string}
 */
export const fnFormatEmploymentDateRange = (sStartDate, sEndDate, sStatus) => {
  const oStartDate = sStartDate
    ? DateAndTime.parse(sStartDate, 'YYYY-MM-DD')
    : null;
  const oEndDate = sEndDate ? DateAndTime.parse(sEndDate, 'YYYY-MM-DD') : null;

  const bIsValidDateRange = fnValidateDateRange(oStartDate, oEndDate);
  if (!bIsValidDateRange) {
    throw new Error(
      `Unable to format date range. Start date ${sStartDate} is after end date ${sEndDate}.`
    );
  }

  const sStartDateText = oStartDate
    ? DateAndTime.format(oStartDate, 'MMM YYYY')
    : 'Unknown';
  const sEndDateText = fnGetEndDateText(oEndDate, sStatus);

  return `${sStartDateText} – ${sEndDateText}`;
};

/**
 * Filters employment data by job status.
 *
 * @param {string|array} mStatus - Job status. One or more of: 'Active', 'Former', or 'Retired'
 * @param {array} aAllEmployment - List of employers
 * @returns {array}
 */
export const fnGetEmploymentByStatus = (mStatus, aAllEmployment) => {
  const aStatuses = Array.isArray(mStatus) ? mStatus : [mStatus];
  const aMatchingEmployment = aAllEmployment.reduce((aResult, oEmployer) => {
    // Some constituents have employer data only without any positions in DAD
    const bNoPositionsData = oEmployer.POSITIONS.length === 0;
    if (bNoPositionsData) {
      const bMatchesEmployerDates =
        (mStatus === 'Active' && oEmployer.END_DATE === '') ||
        (mStatus === 'Former' && oEmployer.END_DATE !== '');
      if (bMatchesEmployerDates) {
        return [...aResult, oEmployer];
      }
    }
    const oClonedEmployer = R.clone(oEmployer);

    const aMatchingPositions = oClonedEmployer.POSITIONS.filter((oPosition) =>
      aStatuses.includes(oPosition.STATUS)
    );
    if (aMatchingPositions.length > 0) {
      return [
        ...aResult,
        {
          ...oClonedEmployer,
          POSITIONS: aMatchingPositions,
        },
      ];
    }
    return aResult;
  }, []);
  return aMatchingEmployment;
};

export const allEmploymentSelector = (state) => state.Profile.EMPLOYMENT || [];

export const currentEmploymentSelector = createSelector(
  allEmploymentSelector,
  (aEmployment) => fnGetEmploymentByStatus('Active', aEmployment)
);

export const retiredEmploymentSelector = createSelector(
  allEmploymentSelector,
  (aEmployment) => fnGetEmploymentByStatus('Retired', aEmployment)
);

export const currentAndRetiredEmploymentSelector = createSelector(
  allEmploymentSelector,
  (aEmployment) => fnGetEmploymentByStatus(['Active', 'Retired'], aEmployment)
);

/**
 * Determines if the constituent has any former jobs,
 * EXCLUDING retired jobs.
 *
 * @param {array} aEmployment - All employment data.
 * @returns {bool}
 */
export const fnHasFormerEmployment = (aEmployment) => {
  const bHasFormerEmployment = aEmployment.some((oEmployer) => {
    let bHasFormer = false;
    // Some constituents have employer data only without any positions in DAD
    if (oEmployer.POSITIONS.length === 0) {
      bHasFormer = oEmployer.END_DATE !== '';
    } else {
      bHasFormer = oEmployer.POSITIONS.some(
        (oPosition) => oPosition.STATUS === 'Former'
      );
    }

    return bHasFormer;
  });
  return bHasFormerEmployment;
};

export const oStartDateFieldSchema = date().when('END_DATE', ([END_DATE]) => {
  if (END_DATE) {
    return oMinimumDateSchema.max(
      END_DATE,
      'Please add a start date before the end date.'
    );
  }
  return oMinimumDateSchema.required(
    'Whoops! You forgot to enter a start date.'
  );
});

export const oEndDateFieldSchema = date().when('IS_RETIRED', ([IS_RETIRED]) => {
  if (IS_RETIRED) {
    return oMinimumDateSchema.required('Please add an end date.');
  }
  return oMinimumDateSchema;
});

export default {};
