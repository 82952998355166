import React, { useState } from 'react';

import ContinueButton from 'components/Authentication/AuthLayout/ContinueButton';
import AuthLayout from '../../AuthLayout';
import SignUpHelpForm from './SignUpHelpForm';

const sSignUpHelpFormId = 'parent-sign-up-help-form';

const ParentSignUpHelp = () => {
  const [bIsSubmitting, fnSetIsSubmitting] = useState(false);
  const [bIsSuccessState, fnSetIsSuccessState] = useState(false);

  return (
    <AuthLayout
      continueButton={
        bIsSuccessState ? null : (
          <ContinueButton
            sContinueButtonText='Send Request'
            sFormId={sSignUpHelpFormId}
            sType='submit'
            bIsLoading={bIsSubmitting}
          />
        )
      }
      left={
        <div className='help__formContainer'>
          <h1 className='t-header2'>We&apos;re here to help</h1>
          <div className='help__instructions'>
            <p>
              But first, are you a new parent who can&apos;t find their name
              during search?
            </p>
            <p>
              If so, it&apos;s likely because families do not show up in Wake
              Network until their student&apos;s admissions deposit has been
              received and processed; depending on how you paid your deposit, it
              could take a week or two to be received and processed.
            </p>
            <p>
              Please try again in a week or two and if your name still
              isn&apos;t there, let us know and we&apos;ll take care of it!
            </p>
          </div>
        </div>
      }
      right={
        <div className='help__formContainer'>
          {bIsSuccessState ? (
            <>
              <h2 className='t-header3'>Request successfully sent</h2>
              <p>Someone from Wake Forest will be in contact with you soon.</p>
            </>
          ) : (
            <>
              <p className='help__instructions'>
                Tell us more about the problem. We&apos;ll look into it and
                reach out via email soon.
              </p>
              <SignUpHelpForm
                sFormId={sSignUpHelpFormId}
                fnSetIsSubmitting={fnSetIsSubmitting}
                fnSetIsSuccessState={fnSetIsSuccessState}
              />
            </>
          )}
        </div>
      }
    />
  );
};

export default ParentSignUpHelp;
