import React from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import ProfileRecordLayout from 'components/MyProfile/elements/layout/ProfileRecordLayout';
import { OptionsModal } from 'components/Form';
import { SelectPrivacy } from 'components/Form/OptionsModal/DefaultFields';
import { spouseActions } from 'components/MyProfile/elements/Personal/Name/redux/actions/spouseActions';

const EditSpouseRecord = ({ data }) => {
  const fnNavigate = useNavigate();
  const fnOpenSpouseProfile = () => {
    fnNavigate(`/main/profile/${data.LOOKUPID}`);
  };
  return (
    <ProfileRecordLayout
      left={
        <div>
          <div className='record t-paragraph--medium'>{data.NAME}</div>
          <ButtonWithIcon
            className='record__editSpouseButton'
            fnHandleClick={fnOpenSpouseProfile}
            sIcon='edit'
          >
            Edit Spouse
          </ButtonWithIcon>
        </div>
      }
      right={
        <OptionsModal data={data} actions={spouseActions}>
          <SelectPrivacy />
        </OptionsModal>
      }
    />
  );
};

export default EditSpouseRecord;
