/* eslint-disable max-len */
import React, { useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import MyClassmatesContext from '../MyClassmatesContext';
import * as myClassmatesActions from '../../../redux/AllClassmates/actions';
import * as claimedClassmatesActions from '../../../redux/ClaimedClassmates/actions';

const MyClassmatesClaimButton = ({ classmateId, claimInfo }) => {
  const dispatch = useDispatch();
  const { classYear } = useContext(MyClassmatesContext);
  const [bEllipsisMenuVisible, fnSetEllipsisMenuVisible] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const allClassmates = useSelector((state) => state.AllClassmates.data);
  const ellipsisRef = useRef();

  const handleClaim = async () => {
    try {
      setIsClaiming(true);
      const result = await new Promise((resolve, reject) => {
        dispatch(
          myClassmatesActions.add({
            data: {
              classmateLookupId: classmateId,
              classYear,
            },
            resolve,
            reject,
          })
        );
      });
      setIsClaiming(false);

      // Handle already claimed classmate
      // @TODO: Tell the user classmate is already claimed
      const isAlreadyClaimed =
        (result.DATA || {}).CLAIMSTATUS === 'ALREADY CLAIMED';
      if (isAlreadyClaimed) {
        throw new Error('Classmate already claimed');
      }

      // Update claimed classmate list
      dispatch(claimedClassmatesActions.get());

      // Show success message for 1 second
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);

        // Update my classmates data in Redux
        const newlyClaimedClassmate = result.CLASSMATES[0];
        const updatedAllClassmates = allClassmates.map((classmate) =>
          classmate.LOOKUPID === newlyClaimedClassmate.LOOKUPID
            ? {
                ...classmate,
                REUNIONPROSPECTTEAM: newlyClaimedClassmate.REUNIONPROSPECTTEAM,
              }
            : classmate
        );
        dispatch(
          myClassmatesActions.set({
            data: updatedAllClassmates,
          })
        );
      }, [1000]);
    } catch (err) {
      console.error('Error claiming classmate', err);
    }
  };

  let claimBtn = '';

  if (isSuccess) {
    claimBtn = (
      <div className='myClassmates__claimStatus myClassmates__claimStatus--success'>
        Claimed by you
      </div>
    );
  } else {
    // Determine if this classmate has been claimed or is a volunteer
    switch (claimInfo.STATUS) {
      case 'Claimed':
        claimBtn = (
          <div className='myClassmates__claimStatus'>
            Claimed by {claimInfo.CLAIMEDBY.NAME}
          </div>
        );
        break;
      case 'Deceased':
        claimBtn = <div className='myClassmates__claimStatus'>Deceased</div>;
        break;
      case 'Unclaimed':
        claimBtn = (
          <Button
            className={`myClassmates__claimButton ${
              isClaiming ? 'myClassmates__claimButton--claiming' : ''
            }`}
            fnHandleClick={handleClaim}
            bIsLoading={isClaiming}
          >
            Claim
          </Button>
        );
        break;
      case 'Volunteer':
        claimBtn = <div className='myClassmates__claimStatus'>Volunteer</div>;
        break;
      default:
        break;
    }
  }

  const isMobileSize = window.innerWidth <= 640;

  const fnToggleEllipsisMenu = () => {
    fnSetEllipsisMenuVisible(!bEllipsisMenuVisible);
  };

  useCloseOnOutsideClick(
    ellipsisRef,
    fnToggleEllipsisMenu,
    bEllipsisMenuVisible
  );

  return isMobileSize ? (
    <div className='myClassmates__ellipsisContainer'>
      <IconOnlyButton
        sIcon='ellipsis'
        className='button ellipsisButton'
        fnHandleClick={fnToggleEllipsisMenu}
        sAriaLabel='Toggle options'
      />
      {bEllipsisMenuVisible && (
        <div className='myClassmates__ellipsisMenu' ref={ellipsisRef}>
          {claimBtn}
        </div>
      )}
    </div>
  ) : (
    claimBtn
  );
};

export default MyClassmatesClaimButton;

MyClassmatesClaimButton.defaultProps = {
  claimInfo: {},
  classmateId: '',
  deceasedDate: '',
};

MyClassmatesClaimButton.propTypes = {
  claimInfo: PropTypes.object,
  classmateId: PropTypes.string,
  deceasedDate: PropTypes.string,
};
