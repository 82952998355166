import { createSelector } from 'reselect';
import * as R from 'ramda';

import { selectAllEventsInCart, selectEventsInCart } from '../redux/selectors';

/**
 * Builds a unique list of all named guests from all events in the cart.
 *
 * @param {array} aEventsInCart - The events in the cart
 * @returns {array} - The named guests for all events in the cart
 */
export const fnGetAllNamedEventGuests = (aEventsInCart) => {
  const aNamedGuests = [];
  aEventsInCart.forEach((oEventCartItem) => {
    oEventCartItem.oDetails.aGuests.forEach((oGuest) => {
      const bHasName = !oGuest.NAME.includes('Guest');
      if (bHasName) {
        aNamedGuests.push(oGuest);
      }
    });
  });
  const aUniqueNamedGuests = R.uniqBy(R.prop('LOOKUPID'), aNamedGuests);
  return aUniqueNamedGuests;
};

export const selectAllNamedEventGuests = createSelector(
  [selectAllEventsInCart],
  fnGetAllNamedEventGuests
);

/**
 * Returns the dietary restriction codes with the 'Other' code last.
 *
 * @param {array} aCodes - Dietary restriction codes
 * @returns {array}
 */
export const fnWeightOtherDietaryRestrictionCodeLast = (aCodes) => {
  if (aCodes.length === 0) {
    return [];
  }
  const oOtherCode = aCodes.find((oCode) => oCode.DESCRIPTION === 'Other');
  const aCodesWithoutOther = aCodes.filter(
    (oCode) => oCode.DESCRIPTION !== 'Other'
  );
  return [...aCodesWithoutOther, oOtherCode];
};
