import React from 'react';
import { useLocation } from 'react-router-dom';

const aPathSteps = ['sign-up', 'match', 'validation', 'finalize-account'];

const AuthLayoutProgressBar = () => {
  const oLocation = useLocation();

  // Get the current step from the path
  const iCurrentStep = aPathSteps.findLastIndex((sStep) =>
    oLocation.pathname.includes(sStep)
  );

  const aProgressBarParts = [];
  for (let i = 0; i < 4; i++) {
    let sClass = 'authLayout__progressBarStep';
    if (iCurrentStep === i) {
      sClass += '--current';
    }
    if (iCurrentStep > i) {
      sClass += '--visited';
    }
    aProgressBarParts.push(<div className={sClass} key={aPathSteps[i]} />);
  }

  return (
    <>
      <div className='authLayout__progressBar'>{aProgressBarParts}</div>
      <p className='authLayout__progressLabel t-paragraph--small'>
        {iCurrentStep + 1} of 4
      </p>
    </>
  );
};

export default AuthLayoutProgressBar;
