import React from 'react';
import PropTypes from 'prop-types';

import LongTextWithToggle from 'components/Layout/LongTextWithToggle';
import useWindowSize from 'utils/hooks/useWindowSize';

const EventDescription = ({ sDescription, bIncludeHeader, className }) => {
  const bDescriptionHasHtml = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>/g.test(
    sDescription
  );
  const oWindowSize = useWindowSize();

  let descriptionMarkup = <p className={className}>{sDescription}</p>;
  if (bDescriptionHasHtml) {
    descriptionMarkup = (
      <div
        className={className}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sDescription }}
      />
    );
  } else if (oWindowSize.width < 640) {
    descriptionMarkup = (
      <LongTextWithToggle sText={sDescription} sClassName={className} />
    );
  }
  return (
    <>
      {bIncludeHeader && (
        <h3 className='eventDetails__descriptionHeader t-paragraph--bold'>
          About
        </h3>
      )}
      {descriptionMarkup}
    </>
  );
};

export default EventDescription;

EventDescription.propTypes = {
  sDescription: PropTypes.string,
  bIncludeHeader: PropTypes.bool,
  className: PropTypes.string,
};

EventDescription.defaultProps = {
  sDescription: '',
  bIncludeHeader: true,
  className: 'eventDetails__description',
};
