/**
 * Formats designations data as expected for Lambda.
 *
 * @param {array} aDesignations
 *
 * @returns {array}
 */
export const formatDesignations = (aDesignations) =>
  aDesignations.map((oDesignation) => ({
    CODE: oDesignation.CODE,
    AMOUNT: oDesignation.AMOUNT,
  }));

/**
 * Formats the transaction data so its ready to send to API endpoint.
 *
 * @param {object} oTransactionData
 *
 * @example of oTransactionData structure
 *  {
 *    amount: 1200,
 *    designations: [
 *      {
 *        CODE: 'UFU01',
 *        NAME: 'The Wake Forest Fund',
 *        AMOUNT: 500,
 *      },
 *      {
 *        CODE: 'SBU01',
 *        NAME: 'The Wake Forest Fund - School of Business',
 *        AMOUNT: 400,
 *      },
 *      {
 *        CODE: 'LSU01',
 *        NAME: 'The Wake Forest Fund - School of Law',
 *        AMOUNT: 300,
 *      }
 *    ]
 *  }
 *
 *  @example of return JSON object
 *  {
 *    "TOTAL_TRANS_AMOUNT": 1200,
 *    "DESIGNATIONS": [
 *      {
 *        "CODE: "UFU01",
 *        "AMOUNT: "500.0000",
 *      },
 *      {
 *        "CODE: "SBU01",
 *        "AMOUNT: "400.0000",
 *      },
 *      {
 *        "CODE: "LSU01",
 *        "AMOUNT: "300.0000",
 *      }
 *    ]
 *  }
 *
 * @return {object}
 */
export const formatTransactionData = (oTransactionData) => {
  const oFormattedData = {
    TOTAL_TRANS_AMOUNT: oTransactionData.amount,
    DESIGNATIONS: formatDesignations(oTransactionData.designations),
  };
  // @todo need to convert the object to JSON.
  return oFormattedData;
};
