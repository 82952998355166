import axios from 'axios';
import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
} from 'redux/selectors';
import store from '../../redux/store';

import {
  fnSanitizeDataForDad,
  fnHandleAxiosError,
  buildAxiosConfig,
} from './helpers';

/**
 * Wake Network API.
 *
 * @author Sharon Hartsell <hartses@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
class WnApi {
  constructor() {
    this.sStage = process.env.REACT_APP_STAGE;
    this.sBaseUrl = this.getApiUrlByStage();
    this.sCurrentUserLookupId = '';
    this.sCurrentUserName = '';
  }

  /**
   * Gets API URL for the current stage (dev, uat, or prod).
   *
   * @returns {string}
   */
  getApiUrlByStage() {
    let sUrl = '';
    switch (this.sStage) {
      case 'prod':
        sUrl = 'https://api.wakenetwork.wfu.edu';
        break;
      case 'uat':
      case 'dev':
      default:
        sUrl = `https://api.wakenetwork.${this.sStage}.wfu.edu`;
        break;
    }
    return sUrl;
  }

  /**
   * Gets current user's lookup ID and name to use in CloudWatch logs.
   *
   * @returns {void}
   */
  getUserInfoForLogging() {
    const bHasUserInfo = this.sCurrentUserLookupId && this.sCurrentUserName;
    if (!store || bHasUserInfo) {
      return;
    }
    const oReduxState = store.getState();
    const sLookupId = currentUserLookupIdSelector(oReduxState);
    if (sLookupId) {
      this.sCurrentUserLookupId = sLookupId;
    }
    const oName = currentUserFirstLastNameSelector(oReduxState);
    if (oName.sFirstName) {
      this.sCurrentUserName = `${oName.sFirstName} ${oName.sLastName}`;
    }
  }

  /**
   * Makes an HTTP GET request to the Wake Network API.
   *
   * @param {string} sPath - API resource path.
   * @param {boolean} bIsAuthenticatedRequest - If request requires authentication or not.
   * @param {string} oAbortSignal - AbortSignal property from an AbortController object.
   * @returns {object}
   */
  async get(sPath, bIsAuthenticatedRequest = true, oAbortSignal = null) {
    this.getUserInfoForLogging();
    const sRequestUrl = `${this.sBaseUrl}/${sPath}`;
    const oAxiosConfig = await buildAxiosConfig(
      bIsAuthenticatedRequest,
      oAbortSignal
    );
    const oResponse = await axios
      .get(sRequestUrl, oAxiosConfig)
      .catch(fnHandleAxiosError);
    return oResponse;
  }

  /**
   * Makes an HTTP POST request to the Wake Network API.
   *
   * @param {string} sPath - API resource path.
   * @param {object} oData - Data to send to API.
   * @param {boolean} bIsAuthenticatedRequest - If request requires authentication or not.
   * @returns {object}
   */
  async post(sPath, oData, bIsAuthenticatedRequest = true) {
    const sRequestUrl = `${this.sBaseUrl}/${sPath}`;
    const oSanitizedData = await fnSanitizeDataForDad(oData);
    const oAxiosConfig = await buildAxiosConfig(bIsAuthenticatedRequest);
    const oResponse = await axios
      .post(sRequestUrl, oSanitizedData, oAxiosConfig)
      .catch(fnHandleAxiosError);
    return oResponse;
  }

  /**
   * Makes an HTTP PATCH request to the Wake Network API.
   *
   * @param {string} sPath - API resource path.
   * @param {object} oData - Data to send to API.
   * @param {boolean} bIsAuthenticatedRequest - If request requires authentication or not.
   * @returns {object}
   */
  async patch(sPath, oData, bIsAuthenticatedRequest = true) {
    const sRequestUrl = `${this.sBaseUrl}/${sPath}`;
    const oSanitizedData = await fnSanitizeDataForDad(oData);
    const oAxiosConfig = await buildAxiosConfig(bIsAuthenticatedRequest);
    const oResponse = await axios
      .patch(sRequestUrl, oSanitizedData, oAxiosConfig)
      .catch(fnHandleAxiosError);
    return oResponse;
  }

  /**
   * Makes an HTTP DELETE request to the Wake Network API.
   *
   * @param {string} sPath - API resource path.
   * @param {object} oData - Data to send to API.
   * @param {boolean} bIsAuthenticatedRequest - If request requires authentication or not.
   * @returns {object}
   */
  async delete(sPath, oData, bIsAuthenticatedRequest = true) {
    const sRequestUrl = `${this.sBaseUrl}/${sPath}`;
    const oAxiosConfig = await buildAxiosConfig(bIsAuthenticatedRequest);
    oAxiosConfig.data = oData;
    const oResponse = await axios
      .delete(sRequestUrl, oAxiosConfig)
      .catch(fnHandleAxiosError);
    return oResponse;
  }
}

const oWnApi = new WnApi();

export default oWnApi;
