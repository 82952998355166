import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { sortByKey } from 'utils/common';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import ViewMoreButton from '../../../../Layout/ViewMoreButton';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import EmailEditMode from './editMode';
import EmailViewMode from './viewMode';

const fEmailSelector = (state) => (state.Profile || {}).EMAIL || [];

// Selector to filter out IDP private relay emails
const nonPrivateRelayEmailsSelector = createSelector(fEmailSelector, (items) =>
  items.filter((email) => !email.EMAILADDRESS.includes('privaterelay'))
);

const sortByPrimarySelector = createSelector(
  nonPrivateRelayEmailsSelector,
  (items) => sortByKey(items, 'PRIMARY', false)
);

export const EmailElement = () => {
  const aEmails = useSelector(sortByPrimarySelector);
  const [activeFormSection] = useActiveFormSection();
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, setIsEditMode] = useState(false);

  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      setIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      setIsEditMode(false);
    }
  }, [activeFormSection]);
  return (
    <ProfileSubsectionLayout
      sTitle='Email'
      left={
        aEmails.length > 1 && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <EmailEditMode aEmails={aEmails} bIsExpanded={bIsExpanded} />
        ) : (
          <EmailViewMode aEmails={aEmails} bIsExpanded={bIsExpanded} />
        )
      }
    />
  );
};

export default EmailElement;
