import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutReview from 'components/Checkout/CheckoutReview';
import usePageTitle from 'utils/hooks/usePageTitle';
import HiddenFieldsForm from 'components/Checkout/CheckoutReview/HiddenFieldsForm';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { selectCartGifts, selectCartTotal } from '../selectors';
import { useFormattedCartData } from './helpers';

const GivingReview = () => {
  const aCartGifts = useSelector(selectCartGifts);
  const nCartTotal = useSelector(selectCartTotal);
  const { oDadData, oHiddenFormData } = useFormattedCartData({
    aCartGifts,
    nCartTotal,
  });

  const oRef = useRef();
  const fnDispatch = useDispatch();

  usePageTitle('Giving Review');

  useEffect(() => {
    if (aCartGifts.length > 0 && nCartTotal > 0) {
      const aSummaryItems = aCartGifts.map((oGift) => ({
        description: oGift.oDetails.FUND.DESCRIPTION,
        amount: parseFloat(oGift.nPrice),
      }));

      // @todo: this is going to overwrite any existing pledge payment data in the Checkout store...hmm
      fnDispatch(
        CheckoutActions.update({
          checkoutTotal: nCartTotal,
          checkoutSummaryItems: aSummaryItems,
        })
      );
    }
  }, [aCartGifts, nCartTotal, fnDispatch]);

  return (
    <CheckoutReview
      sTitle='Giving'
      sFirstStepLabel='Choose amount'
      oDadTransactionData={oDadData}
      oHiddenFieldsMarkup={
        <HiddenFieldsForm
          oHiddenFieldsFormData={oHiddenFormData}
          oHiddenFieldFormRef={oRef}
        />
      }
      oHiddenFieldFormRef={oRef}
    />
  );
};

export default GivingReview;
