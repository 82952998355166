import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import { Radio } from 'components/Form/Fields';
import { fnElementRefPropType } from 'utils/customPropTypes';

/**
 * Responsible for building the contact (email, address) selection form.
 *
 * @author Elizabeth Hicks <hicksem@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const SelectContactForm = ({
  sSelectionLabel,
  oInitialValues,
  aContactData,
  submitFormikFormRef,
  handleSubmit,
  sFormId,
}) =>
  aContactData.length > 0 ? (
    <Formik
      onSubmit={handleSubmit}
      initialValues={oInitialValues}
      validateOnBlur
      enableReinitialize
    >
      {(formikProps) => {
        if (submitFormikFormRef) {
          submitFormikFormRef.current = formikProps.submitForm;
        }
        return (
          <Form className='selectContactForm' id={sFormId}>
            {aContactData.map((oContactData) => (
              <Radio
                key={oContactData.id}
                sId={oContactData.id}
                sLabel={oContactData.value}
                sName={sSelectionLabel}
                mValue={oContactData.value}
                sWrapperClassName='selectContactForm__radioWrapper'
              />
            ))}
          </Form>
        );
      }}
    </Formik>
  ) : null;

SelectContactForm.propTypes = {
  sSelectionLabel: PropTypes.string.isRequired,
  oInitialValues: PropTypes.shape({
    email: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  aContactData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  submitFormikFormRef: fnElementRefPropType,
  handleSubmit: PropTypes.func.isRequired,
  sFormId: PropTypes.string,
};

SelectContactForm.defaultProps = {
  submitFormikFormRef: null,
  sFormId: '',
};

export default SelectContactForm;
