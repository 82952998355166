import { createActions } from 'redux-actions';

export default createActions(
  {
    GET: (phone) => phone,
    SET: (phone) => phone,
    CREATE: (phone) => phone,
    DELETE: (phone) => phone,
    UPDATE: (payload) => payload,
    ADD_NOTE: (state) => state,
  },
  {
    prefix: 'Profile/PHONE',
  }
);
