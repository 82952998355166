import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';

const EventRegistrantName = ({ sName, sIconTheme }) => {

  return (
    <div
      className='eventRegistrantName'
      data-cy='event-registrant-name'
    >
      <FontAwesomeIcon
        icon={oIconLibrary.fontAwesome.user}
        className={`eventRegistrantName__icon--${sIconTheme}`}
      />
      <span className='t-paragraph'>{sName}</span>
    </div>
  );
};

EventRegistrantName.propTypes = {
  sName: PropTypes.string.isRequired,
  sIconTheme: PropTypes.oneOf(['light', 'dark']),
};

EventRegistrantName.defaultProps = {
  sIconTheme: 'light',
};

export default EventRegistrantName;
