import DateAndTime from 'date-and-time';

import { oCurrencyFormatter, sortByKey, sortByKeyAsNumber } from 'utils/common';

/**
 * Formats the fund financial data into a list of data points for the chart.
 *
 * @param {object} aData - All historical fund financial data.
 * @returns {array}
 */
export const fnFormatChartData = (aData) => {
  let aChartData = [];

  // Sort the data by fiscal year in ascending order
  const aChronologicalFinancialData = sortByKeyAsNumber(
    aData,
    'FISCALYEAR',
    true
  );

  aChronologicalFinancialData.forEach((oData, iIndex) => {
    const bHasQuarterlyData =
      oData.VALUE_OVER_TIME && oData.VALUE_OVER_TIME.length > 0;
    if (bHasQuarterlyData) {
      // Sort the quarterly data by start date in ascending order
      const aChronologicalQuarterlyData = sortByKey(
        oData.VALUE_OVER_TIME,
        'START_DATE',
        true
      );
      const aQuarterlyData = aChronologicalQuarterlyData.map(
        ({ BEGINNING_VALUE, START_DATE, DISTRIBUTIONS }) => {
          const oStartDate = DateAndTime.parse(
            START_DATE,
            'YYYY-MM-DDTHH:mm:ss'
          );
          const sFormattedStartDate = DateAndTime.format(
            oStartDate,
            'MMM YYYY'
          );
          return {
            name: sFormattedStartDate,
            fundValue: parseFloat(BEGINNING_VALUE),
            distributions: parseFloat(DISTRIBUTIONS),
          };
        }
      );
      aChartData = [...aChartData, ...aQuarterlyData];
    } else {
      // The fund only has annual start/end financial data
      aChartData.push({
        name: `Jul ${oData.FISCALYEAR - 1}`,
        fundValue: parseFloat(oData.FISCAL_YEAR_BEGINNING_VALUE),
      });
      // Include the ending value for the most recent year available
      if (iIndex === aChronologicalFinancialData.length - 1) {
        aChartData.push({
          name: `Jun ${oData.FISCALYEAR}`,
          fundValue: parseFloat(oData.FISCAL_YEAR_ENDING_VALUE),
        });
      }
    }
  });
  const aLastSixDataPoints = aChartData.slice(-6);
  return aLastSixDataPoints;
};

export default {};
