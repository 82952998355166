import React from 'react';
import PropTypes from 'prop-types';

import { fnElementRefPropType } from 'utils/customPropTypes';
import { InternationalPhoneField } from '../../../../Form/Fields';
import NewRecordForm from '../../../../Form/NewRecordForm';
import { OptionsModal } from '../../../../Form';
import actions from './redux/actions';
import { addPhoneNumberSchema } from './schemas';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const FormBody = ({ firstFieldRef, ...formikProps }) => (
  <ProfileRecordLayout
    left={
      <InternationalPhoneField
        groupLabel='Phone Number'
        countryFieldOptions={{
          id: 'country',
          label: 'Country',
          name: 'COUNTRY',
          fieldRef: firstFieldRef,
        }}
        phoneFieldOptions={{
          id: 'number',
          label: 'Phone Number',
          name: 'NUMBER',
          placeholder: '###-###-####',
        }}
      />
    }
    right={
      <OptionsModal
        data={formikProps.values}
        name='Phone'
        forceExpanded
        formikProps={formikProps}
      />
    }
  />
);

FormBody.propTypes = {
  firstFieldRef: fnElementRefPropType.isRequired,
};

export const NewPhoneNumberForm = ({ initialValues, oActions }) => (
  <NewRecordForm
    initialValues={initialValues}
    formId={`new_phone_number_form${initialValues.ID || ''}`}
    addItemLabel='Add Phone'
    actions={oActions || actions}
    validationSchema={addPhoneNumberSchema}
    FormBody={FormBody}
    codeTablesKey='PHONE'
  />
);

NewPhoneNumberForm.defaultProps = {
  initialValues: {
    NUMBER: '',
    TYPE: 'Mobile',
    PRIVACY: '0',
    PRIMARY: true,
    COUNTRY: {
      DESCRIPTION: 'United States',
      ID: '70FA9361-547E-4C98-A3CF-F655D36F8ECF',
      DETAILS: {
        ABBRV: 'US',
        NAME_ABBRV: 'US - United States',
      },
    },
  },
};

NewPhoneNumberForm.propTypes = {
  initialValues: PropTypes.shape({
    ID: PropTypes.string,
    NUMBER: PropTypes.string,
    TYPE: PropTypes.string,
    PRIVACY: PropTypes.string,
    PRIMARY: PropTypes.bool,
    COUNTRY: PropTypes.shape({
      DESCRIPTION: PropTypes.string,
      ID: PropTypes.string,
      DETAILS: PropTypes.shape({
        ABBRV: PropTypes.string,
        NAME_ABBRV: PropTypes.string,
      }),
    }),
  }),
};

export default NewPhoneNumberForm;
