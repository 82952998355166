import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';

import { fnChildrenPropType } from './customPropTypes';

const RequireAuth = ({ bIsAuthenticated, children }) => {
  const oLocation = useLocation();
  const oQueryParams = {
    redirect: `${oLocation.pathname}${oLocation.search}`,
  };
  return bIsAuthenticated ? (
    children
  ) : (
    <Navigate to={`/sign-in?${qs.stringify(oQueryParams)}`} />
  );
};

RequireAuth.propTypes = {
  bIsAuthenticated: PropTypes.bool.isRequired,
  children: fnChildrenPropType.isRequired,
};

export default RequireAuth;
