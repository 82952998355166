import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { fnGetOtherValidationOptionText } from './helpers';

const OtherValidationOptions = ({ sSelectedValidationType, fnHandleClick }) => {
  const oParams = useParams();
  const oLocation = useLocation();
  const sMatchType = oParams.type;
  const {
    HASLASTFOUR: bHasSsn = false,
    HASBANNERID: bHasWfuId = false,
    HASTEMPTOKEN: bHasTempToken = false,
    ISMAGICLINKELIGIBLE: bIsMagicLinkEligible = false,
  } = oLocation.state.oConstituent;

  const aValidationOptions = [
    {
      sOption: 'ssn',
      bInclude: sSelectedValidationType !== 'ssn' && bHasSsn === '1',
    },
    {
      sOption: 'bannerid',
      bInclude: sSelectedValidationType !== 'bannerid' && bHasWfuId === '1',
    },
    {
      sOption: 'deacontoken',
      bInclude:
        sSelectedValidationType !== 'deacontoken' && bHasTempToken === '1',
    },
    {
      sOption: 'magic-link',
      bInclude:
        sSelectedValidationType !== 'magic-link' &&
        bIsMagicLinkEligible === '1',
    },
  ];

  const aFilteredValidationOptions = aValidationOptions
    .filter((option) => option.bInclude)
    .map((option) => option.sOption);

  return aFilteredValidationOptions.length > 0 ? (
    <div className='validation__otherOptionsContainer'>
      <p>
        <strong>
          Or you could use one of the options below to verify your identity.
        </strong>
      </p>
      {aFilteredValidationOptions.map((sOption) => (
        <Button
          key={sOption}
          sFlavor='underline'
          sType='reset'
          fnHandleClick={() => fnHandleClick(sOption)}
          className='validation__typeButton'
        >
          {fnGetOtherValidationOptionText(sOption, sMatchType)}
        </Button>
      ))}
    </div>
  ) : null;
};

OtherValidationOptions.propTypes = {
  sSelectedValidationType: PropTypes.string.isRequired,
  fnHandleClick: PropTypes.func.isRequired,
};

export default OtherValidationOptions;
