import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';
import LinkWithSourcePath from 'utils/LinkWithSourcePath';

/**
 * Displays the event contact person's name and email address.
 */
const EventContact = ({ oContact }) => (
  <div className='eventDetails__contact'>
    <p className='t-paragraph--bold'>Contact</p>
    <p>
      <FontAwesomeIcon icon={oIconLibrary.fontAwesome.addressCard} />{' '}
      <LinkWithSourcePath
        to={`profile/${oContact.LOOKUPID}`}
        referringComponent='registeredEvents'
      >
        {oContact.NAME}
      </LinkWithSourcePath>
    </p>
    {oContact.EMAIL && (
      <p>
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome.envelope} />{' '}
        <a href={`mailto:${oContact.EMAIL}`}>{oContact.EMAIL}</a>
      </p>
    )}
  </div>
);

EventContact.propTypes = {
  oContact: PropTypes.shape({
    LOOKUPID: PropTypes.string,
    NAME: PropTypes.string,
    EMAIL: PropTypes.string,
  }).isRequired,
};

export default EventContact;
