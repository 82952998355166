import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '../../../../Form/Fields';
import NewRecordForm from '../../../../Form/NewRecordForm';
import actions from './redux/actions';
import fnGetFormattedBirthdate from './fnGetFormattedBirthdate';
import BirthdateViewMode from './viewMode';
import birthdateSchema from './schemas';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const FormBody = ({ firstFieldRef }) => (
  <ProfileRecordLayout
    left={
      <TextField
        sId='birthdate'
        sLabel='Birthday'
        sName='BIRTHDATE'
        sPlaceholder='MM/DD/YYYY'
        oFieldRef={firstFieldRef}
      />
    }
  />
);

const BirthdateEditMode = ({ sBirthdate }) => {
  const bHasBirthdate = sBirthdate !== '00000000';

  const initialValues = {
    BIRTHDATE: fnGetFormattedBirthdate(sBirthdate),
  };

  return (
    <>
      <ProfileRecordLayout
        left={
          bHasBirthdate && (
            <div className='birthdate__editInactiveWrapper'>
              <BirthdateViewMode sBirthdate={sBirthdate} />
            </div>
          )
        }
      />

      <NewRecordForm
        initialValues={initialValues}
        addItemLabel={bHasBirthdate ? 'Edit Birthdate' : 'Add Birthdate'}
        formId='birthdate-form'
        actions={actions}
        FormBody={FormBody}
        validationSchema={birthdateSchema}
        submitActionName='update'
      />
    </>
  );
};

BirthdateEditMode.propTypes = {
  sBirthdate: PropTypes.string.isRequired,
};

export default BirthdateEditMode;
