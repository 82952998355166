import LoadingZone from 'components/LoadingZone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useProfileData from 'utils/hooks/useProfileData';
import Accordion from '../Layout/Accordion';
import { fnSelectMyNetworkConnections } from './helpers';
import MyNetworkList from './MyNetworkList';
import MyNetworkListControls from './MyNetworkListControls';
import MyNetworkSkeleton from './MyNetworkSkeleton';

const MyNetwork = () => {
  const aMyNetworkConnections = useSelector(fnSelectMyNetworkConnections);
  const [aFilteredConnections, fnSetFilteredConnections] = useState(
    aMyNetworkConnections
  );
  const isProfileDataLoading = useProfileData();

  useEffect(() => {
    fnSetFilteredConnections(aMyNetworkConnections);
  }, [aMyNetworkConnections]);

  return (
    <Accordion
      mTitle={
        <>
          My Network{' '}
          {!isProfileDataLoading && (
            <span className='myNetwork__numConnections t-paragraph--medium'>
              {aMyNetworkConnections.length}
            </span>
          )}
        </>
      }
      sTitleIcon='user'
      sGtmCategory='My Network'
      bLazyRender
    >
      <LoadingZone
        isLoading={isProfileDataLoading}
        skeleton={<MyNetworkSkeleton />}
      >
        {aMyNetworkConnections.length > 0 ? (
          <>
            <MyNetworkListControls
              fnSetFilteredConnections={fnSetFilteredConnections}
            />
            <MyNetworkList aConnections={aFilteredConnections} />
          </>
        ) : (
          <p className='myNetwork__noConnections t-paragraph--small'>
            Search for fellow Wake Foresters above to add them to your network.
          </p>
        )}
      </LoadingZone>
    </Accordion>
  );
};

export default MyNetwork;
