import { SkeletonCircle, SkeletonText } from 'components/LoadingZone/Skeleton';
import React from 'react';

const CheckoutContactInfoSkeleton = () => (
  <>
    <SkeletonText
      className='checkoutContactInfoSkeleton__text'
      size='medium'
      width='50%'
    />
    <SkeletonText
      className='checkoutContactInfoSkeleton__text'
      size='medium'
      width='80%'
    />
    <SkeletonCircle
      className='checkoutContactInfoSkeleton__button'
      size='small'
    />
  </>
);

export default CheckoutContactInfoSkeleton;
