import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';
import { fnFormatDegreeText } from './helpers';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const DegreesViewMode = ({ aDegrees }) =>
  aDegrees.map((oDegree) => {
    const isPrivate = oDegree.PRIVACY === '1' || oDegree.PRIVACY === '2';
    return (
      <ProfileSubsectionLayout
        key={oDegree.ID}
        sTitle={oDegree.DEGREELEVEL}
        right={
          <ProfileRecordLayout
            left={
              <span className='record t-paragraph--medium'>
                {fnFormatDegreeText(oDegree)}
                {isPrivate && (
                  <RecordPrivacyIcon ariaId={`private-eye-${oDegree.ID}`} />
                )}
              </span>
            }
          />
        }
      />
    );
  });

DegreesViewMode.propTypes = {
  aDegrees: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      DEGREELEVEL: PropTypes.string,
    })
  ),
};

DegreesViewMode.defaultProps = {
  aDegrees: [],
};

export default DegreesViewMode;
