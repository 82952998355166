import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { object } from 'yup';
import AsyncSelect from 'react-select/async';
import { useField } from 'formik';

import useFloatingSelectLabel from 'components/Form/Fields/Select/useFloatingSelectLabel';
import { fnElementRefPropType } from 'utils/customPropTypes';
import NewRecordForm from '../../../../Form/NewRecordForm';
import useWindowSize from '../../../../../utils/hooks/useWindowSize';
import NameWithQuickFindOptions from './NameWithQuickFindOptions';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';
import {
  fnBuildNoResultsMessage,
  fnBuildLoadingMessage,
  fnGetOptionLabel,
  fnGetOptionValue,
  fnGetNewOptionData,
  fnLoadOptions,
} from './helpers';

const sClassNamePrefix = 'reactSelect';

const sFieldName = 'CONSTITUENT';

const FormBody = ({
  bIsPrivacyChangeable,
  name,
  actions,
  label,
  codeTablesRelationshipType,
  firstFieldRef,
  ...formikProps
}) => {
  const windowSize = useWindowSize();
  const [selectedOption, setSelectedOption] = useState(null);
  const [field, meta, helpers] = useField(sFieldName);
  const {
    sLabelClass,
    sErrorClass,
    fnHandleFocus,
    fnHandleBlur,
    oCustomStyles,
  } = useFloatingSelectLabel(sFieldName, field.value?.NAME);

  const fnHandleChange = (oSelectedOption) => {
    setSelectedOption(oSelectedOption);
    helpers.setValue(oSelectedOption);
  };

  const capitalizedName = name.charAt(0).toUpperCase() + name.substring(1);

  return (
    <ProfileRecordLayout
      left={
        <div className={`boxField boxField--select ${sErrorClass}`}>
          <AsyncSelect
            inputId={`constituent-select-${name}`}
            name={sFieldName}
            value={selectedOption}
            className='reactSelect__container'
            classNamePrefix={sClassNamePrefix}
            getOptionLabel={fnGetOptionLabel}
            getOptionValue={fnGetOptionValue}
            placeholder='Choose someone...'
            onChange={fnHandleChange}
            isClearable
            cacheOptions
            defaultOptions={[]}
            loadOptions={fnLoadOptions}
            noOptionsMessage={fnBuildNoResultsMessage}
            loadingMessage={fnBuildLoadingMessage}
            ref={firstFieldRef}
            styles={oCustomStyles}
            onFocus={fnHandleFocus}
            onBlur={fnHandleBlur}
            getNewOptionData={fnGetNewOptionData}
          />
          <label
            htmlFor={`constituent-select-${name}`}
            className={`select__label ${sLabelClass}`}
          >
            {label || capitalizedName}
          </label>
          {meta.error && <p className='field__errorMessage'>{meta.error}</p>}
        </div>
      }
      right={
        <NameWithQuickFindOptions
          data={formikProps.values}
          forceExpanded
          closeOnOutsideClick={windowSize.width < 1024}
          bIsPrivacyChangeable={bIsPrivacyChangeable}
          formikProps={formikProps}
          actions={actions}
          codeTablesRelationshipType={codeTablesRelationshipType}
        />
      }
    />
  );
};

FormBody.propTypes = {
  bIsPrivacyChangeable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    get: PropTypes.func,
    set: PropTypes.func,
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    addNote: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  codeTablesRelationshipType: PropTypes.string.isRequired,
  firstFieldRef: fnElementRefPropType,
};

FormBody.defaultProps = {
  bIsPrivacyChangeable: true,
  label: '',
  firstFieldRef: null,
};

export const NewNameWithQuickFindForm = ({
  activateFormLabel,
  actions,
  onShow,
  onHide,
  name,
  initialValues,
  bIsPrivacyChangeable,
  label,
  codeTablesRelationshipType,
}) => {
  const schema = object({
    CONSTITUENT: object().nullable().required('Please choose a person.'),
    RELATIONSHIP: object().nullable().required('Please choose a relationship.'),
  });

  return (
    <NewRecordForm
      initialValues={initialValues}
      formId={`new_${name}_form_${(initialValues || {}).LOOKUPID || ''}`}
      addItemLabel={activateFormLabel}
      submitActionName={(initialValues || {}).LOOKUPID ? 'update' : 'create'}
      validationSchema={schema}
      actions={actions}
      FormBody={FormBody}
      formBodyProps={{
        bIsPrivacyChangeable,
        name,
        actions,
        label,
        codeTablesRelationshipType,
      }}
      onShow={onShow}
      onHide={onHide}
    />
  );
};

NewNameWithQuickFindForm.defaultProps = {
  onShow: () => {},
  onHide: () => {},
  name: 'Name',
  activateFormLabel: 'Edit Name',
  initialValues: {
    LOOKUPID: null,
    NAME: null,
    RELATIONSHIP: null,
  },
  label: '',
  bIsPrivacyChangeable: true,
};

NewNameWithQuickFindForm.propTypes = {
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  name: PropTypes.string,
  activateFormLabel: PropTypes.string,
  initialValues: PropTypes.shape({
    RELATIONSHIP: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
    }),
    LOOKUPID: PropTypes.string,
    NAME: PropTypes.string,
  }),
  label: PropTypes.string,
  bIsPrivacyChangeable: PropTypes.bool,
  codeTablesRelationshipType: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    get: PropTypes.func,
    set: PropTypes.func,
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    addNote: PropTypes.func,
  }).isRequired,
};

export default NewNameWithQuickFindForm;
