import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import EmployerGroup from './EmployerGroup';
import AddNewEmploymentForm from './AddNewEmploymentForm';
import {
  fnHasFormerEmployment,
  allEmploymentSelector,
  currentEmploymentSelector,
  retiredEmploymentSelector,
  fnHasEmployerWithMultiplePositions,
  currentAndRetiredEmploymentSelector,
} from './helpers';
import ViewMoreButton from '../../../../Layout/ViewMoreButton';

/**
 * Displays employment section on a user's profile.
 */
const Employment = () => {
  const aEmploymentData = useSelector(allEmploymentSelector);
  const aCurrentEmployment = useSelector(currentEmploymentSelector);
  const aRetiredEmployment = useSelector(retiredEmploymentSelector);
  const [activeFormSection] = useActiveFormSection();
  const bIsEditingCareerSection = activeFormSection === 'career';
  const [bShowFormer, fnSetShowFormer] = useState(false);
  const bShowToggleFormerButton =
    !bIsEditingCareerSection && fnHasFormerEmployment(aEmploymentData);
  const bIsRetired = aRetiredEmployment.length > 0;
  const aAlwaysVisibleEmployment = useSelector(
    currentAndRetiredEmploymentSelector
  );
  const bHasEmployerWithMultiplePositions =
    fnHasEmployerWithMultiplePositions(aEmploymentData);

  const fnToggleFormer = () => {
    fnSetShowFormer(!bShowFormer);
  };

  return (
    <ProfileSubsectionLayout
      sTitle='Employment'
      left={
        bShowToggleFormerButton && (
          <ViewMoreButton
            fnOnClick={fnToggleFormer}
            bIsShowingMore={bShowFormer}
            sViewMoreLabel='Show Former'
            sViewLessLabel='Hide Former'
          />
        )
      }
      right={
        bIsEditingCareerSection ? (
          <>
            <AddNewEmploymentForm />
            {aEmploymentData.map((oEmployer) => (
              <EmployerGroup
                data={oEmployer}
                key={oEmployer.EMPLOYER_RELATIONSHIP_ID}
                bHasEmployerWithMultiplePositions={
                  bHasEmployerWithMultiplePositions
                }
              />
            ))}
          </>
        ) : (
          <>
            {!bIsRetired && aCurrentEmployment.length === 0 && (
              <p>No current known employment</p>
            )}

            {(bShowFormer ? aEmploymentData : aAlwaysVisibleEmployment).map(
              (oEmployer) => (
                <EmployerGroup
                  data={oEmployer}
                  key={oEmployer.EMPLOYER_RELATIONSHIP_ID}
                  bHasEmployerWithMultiplePositions={
                    bHasEmployerWithMultiplePositions
                  }
                />
              )
            )}
          </>
        )
      }
    />
  );
};

export default Employment;
