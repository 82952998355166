import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import EmailRecord from './EmailRecord';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

/**
 * Displays profile email section in view mode.
 */
const EmailViewMode = ({ aEmails, bIsExpanded }) => {
  const [oFirstEmail, ...aOtherEmails] = aEmails;
  return (
    <>
      {oFirstEmail && (
        <ProfileRecordLayout left={<EmailRecord oEmail={oFirstEmail} />} />
      )}
      {aOtherEmails.length > 0 && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          {aOtherEmails.map((oEmail) => (
            <ProfileRecordLayout
              key={oEmail.ID}
              left={<EmailRecord oEmail={oEmail} />}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};

EmailViewMode.defaultProps = {
  aEmails: [],
  bIsExpanded: false,
};

EmailViewMode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aEmails: PropTypes.arrayOf(PropTypes.object),
  bIsExpanded: PropTypes.bool,
};

export default EmailViewMode;
