import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';

/**
 * Displays an email record.
 */
const EmailRecord = ({ oEmail, bIsViewMode }) => {
  const bIsPrivate = oEmail.PRIVACY === '1' || oEmail.PRIVACY === '2';
  return (
    <>
      <span className='recordMiniLabel t-label'>
        {oEmail.TYPE.DESCRIPTION}
        {bIsViewMode && bIsPrivate && (
          <RecordPrivacyIcon ariaId={`private-eye-${oEmail.ID}`} />
        )}
      </span>
      <div className='record t-paragraph--medium'>{oEmail.EMAILADDRESS}</div>
    </>
  );
};

EmailRecord.propTypes = {
  oEmail: PropTypes.object.isRequired,
  bIsViewMode: PropTypes.bool,
};

EmailRecord.defaultProps = {
  bIsViewMode: true,
};

export default EmailRecord;
