import oDadDataTypeToApiResourceRef from './DadDataTypeToApiResourceReference.json';

/**
 * Retrieves appropriate API resource name based on the given DAD data type.
 *
 * @param {string} sDadDataType - DAD data type.
 * @returns {string}
 */
const fnGetApiResourceNameByDadDataType = (sDadDataType) => {
  let sResult = '';
  const oResult = oDadDataTypeToApiResourceRef.find(
    (oItem) => oItem.sDadDataType === sDadDataType
  );
  if (oResult) {
    sResult = oResult.sApiResourceName;
  }
  return sResult;
};

/**
 * Extracts relevant data from Redux action type.
 *
 * @example
 * fnParseActionType('Profile/PHONE/CREATE');
 * // expected output: Object {
 * //   sReduxStoreKey: 'Profile',
 * //   sReduxStoreSubKey: 'PHONE',
 * //   sDadDataType: 'PHONE',
 * //   sActionName: 'CREATE',
 * //   sApiResourceName: 'phones',
 * // }
 * @example
 * fnParseActionType('Profile/BIOGRAPHICAL/Birthdate/CREATE');
 * // expected output: Object {
 * //   sReduxStoreKey: 'Profile',
 * //   sReduxStoreSubKey: 'BIOGRAPHICAL',
 * //   sDadDataType: 'Birthdate',
 * //   sActionName: 'CREATE',
 * //   sApiResourceName: '',
 * // }
 *
 * @param {string} sActionType - Redux action type.
 * @returns {object}
 */
const fnParseActionType = (sActionType) => {
  const sActionTypeParts = sActionType.split('/');
  const [sReduxStoreKey, sReduxStoreSubKey] = sActionTypeParts.slice(0, 2);
  const [sDadDataType, sActionName] = sActionTypeParts.slice(-2);
  const sApiResourceName = fnGetApiResourceNameByDadDataType(sDadDataType);
  return {
    sReduxStoreKey,
    sReduxStoreSubKey,
    sDadDataType,
    sActionName,
    sApiResourceName,
  };
};

export default fnParseActionType;
