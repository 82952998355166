import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

export const TextArea = ({
  sId,
  sLabel,
  sName,
  className,
  sPlaceholder,
  sHelpText,
  sCypressId,
  fnHandleChange,
}) => {
  const [field, meta] = useField(sName);
  const bShowError = meta.touched && meta.error;
  return (
    <div
      className={`boxField ${bShowError ? 'boxField--error' : ''}`}
      data-cy={sCypressId}
    >
      <textarea
        {...field}
        id={sId}
        name={sName}
        className={`${className}`}
        maxLength={10000}
        placeholder={sPlaceholder}
        onChange={(event) => {
          field.onChange(event);
          if (fnHandleChange) {
            fnHandleChange(event.target.value);
          }
        }}
      />
      <label htmlFor={sId}>{sLabel}</label>
      {sHelpText && <p className='field__helpText'>{sHelpText}</p>}
      {bShowError && <p className='field__errorMessage'>{meta.error}</p>}
    </div>
  );
};

TextArea.defaultProps = {
  sPlaceholder: ' ',
  className: '',
  sHelpText: '',
  sCypressId: '',
  fnHandleChange: null,
};

TextArea.propTypes = {
  sId: PropTypes.string.isRequired,
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  sPlaceholder: PropTypes.string,
  sHelpText: PropTypes.string,
  sCypressId: PropTypes.string,
  fnHandleChange: PropTypes.func,
};

export default TextArea;
