import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ConstituentAvatar from 'components/ConstituentAvatar';
import Button from 'components/Buttons/Button';
import LinkWithSourcePath from 'utils/LinkWithSourcePath';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';

/**
 * Displays a list of the event's attendees.
 */
const EventAttendeesList = ({ aAttendees }) => {
  const [bIsCollapsed, fnSetIsCollapsed] = useState(aAttendees.length > 7);
  const bIsUserAuthenticated = useIsUserAuthenticated();

  // Unauth users don't get to see constituent avatars, so just show all attendee names.
  useEffect(() => {
    if (!bIsUserAuthenticated) {
      fnSetIsCollapsed(false);
    }
  }, [bIsUserAuthenticated]);

  return aAttendees.length > 0 ? (
    <div className='eventAttendeesList'>
      <div className='eventAttendeesList__titleWrapper'>
        <h3 className='eventAttendeesList__title t-paragraph--bold'>
          Attendees
        </h3>
        {!bIsCollapsed && aAttendees.length > 7 && bIsUserAuthenticated && (
          <Button
            sFlavor='underline'
            fnHandleClick={() => fnSetIsCollapsed(true)}
          >
            Collapse
          </Button>
        )}
      </div>
      <div className='eventAttendeesList__wrapper'>
        <ul
          className={`eventDetails___attendeesList eventDetails___attendeesList--${
            bIsCollapsed ? 'collapsed' : 'expanded'
          }`}
        >
          {bIsCollapsed
            ? aAttendees.slice(0, 7).map((oAttendee) => (
                <li key={oAttendee.LOOKUPID}>
                  <ConstituentAvatar
                    sSize='extraSmall'
                    lookupId={oAttendee.LOOKUPID}
                    sFirstName={oAttendee.FIRSTNAME}
                    sLastName={oAttendee.LASTNAME}
                    bHasPhoto={oAttendee.HAS_PHOTO === 1}
                    className='eventDetails___attendeeAvatar'
                  />
                </li>
              ))
            : aAttendees.map((oAttendee) => (
                <li
                  className='eventAttendeesList__avatarName'
                  key={oAttendee.LOOKUPID}
                >
                  {oAttendee.LOOKUPID ? (
                    <LinkWithSourcePath
                      to={`profile/${oAttendee.LOOKUPID}`}
                      className='eventAttendeesList__avatarName'
                      referringComponent='EventDetails'
                    >
                      <ConstituentAvatar
                        sSize='extraSmall'
                        lookupId={oAttendee.LOOKUPID}
                        sFirstName={oAttendee.FIRSTNAME}
                        sLastName={oAttendee.LASTNAME}
                        bHasPhoto={oAttendee.HAS_PHOTO === 1}
                      />
                      <p className='eventAttendeesList__attendeeName'>
                        {oAttendee.NAME}
                      </p>
                    </LinkWithSourcePath>
                  ) : (
                    <>
                      <ConstituentAvatar
                        sSize='extraSmall'
                        lookupId={oAttendee.LOOKUPID}
                        sFirstName={oAttendee.FIRSTNAME}
                        sLastName={oAttendee.LASTNAME}
                        bHasPhoto={oAttendee.HAS_PHOTO === 1}
                      />
                      <p>{oAttendee.NAME}</p>
                    </>
                  )}
                </li>
              ))}
        </ul>
        {bIsCollapsed && aAttendees.length > 7 && (
          <Button
            sFlavor='underline'
            fnHandleClick={() => fnSetIsCollapsed(false)}
          >
            + {aAttendees.length - 7}
          </Button>
        )}
      </div>
      {aAttendees.length === 25 && (
        <p className='eventAttendeesList__disclaimer t-paragraph--small'>
          * First 25 attendees listed
        </p>
      )}
    </div>
  ) : null;
};

EventAttendeesList.propTypes = {
  aAttendees: PropTypes.arrayOf(
    PropTypes.shape({
      LOOKUPID: PropTypes.string,
    })
  ),
};

EventAttendeesList.defaultProps = {
  aAttendees: [],
};

export default EventAttendeesList;
