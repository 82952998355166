import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';

const TotalPrivacyDisabledModal = ({ setShowModal }) => (
  <div className='totalPrivacyDisable__modal'>
    <p>
      Your public profile is now discoverable. Thank you for being part of the
      Wake Network!
    </p>
    <Button fnHandleClick={() => setShowModal(false)}>Got it</Button>
  </div>
);

TotalPrivacyDisabledModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
export default TotalPrivacyDisabledModal;
