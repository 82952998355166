import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';

const ButtonWithPopupMenu = ({ forceExpanded, children, className }) => {
  const [optionsModalVisible, setOptionsModalVisible] = useState(forceExpanded);
  const oWrapperRef = useRef();

  useEffect(() => {
    if (forceExpanded !== undefined) {
      setOptionsModalVisible(forceExpanded);
    }
  }, [forceExpanded]);

  useCloseOnOutsideClick(oWrapperRef, setOptionsModalVisible);

  return (
    <div className={`${className} optionsModal__wrapper`} ref={oWrapperRef}>
      <IconOnlyButton
        fnHandleClick={() => setOptionsModalVisible(!optionsModalVisible)}
        sIcon='ellipsis'
        sAriaLabel='Toggle options'
        sFlavor='secondary'
      />
      {optionsModalVisible && (
        <div className='optionsModal__reveal'>{children}</div>
      )}
    </div>
  );
};

ButtonWithPopupMenu.propTypes = {
  forceExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ButtonWithPopupMenu.defaultProps = {
  forceExpanded: false,
  className: '',
};

export default ButtonWithPopupMenu;
