import debounce from 'lodash.debounce';

import oWnApi from 'utils/WnApi';

/**
 * Formats the search query and filter for the `/quick_search` API endpoint.
 *
 * @param {string} sQuery - The search query.
 * @param {string} sFilter - The search filter.
 * @returns {string}
 */
const fnBuildQuickSearchQueryParams = (sQuery, sFilter = '') => {
  let sQueryParams = '';
  const sEncodedQuery = encodeURIComponent(sQuery.trim());
  sQueryParams = `q=${sEncodedQuery}`;
  if (sFilter) {
    const sEncodedFilter = encodeURIComponent(`type:'${sFilter}'`);
    sQueryParams = `${sQueryParams}&fq=${sEncodedFilter}`;
  }
  return sQueryParams;
};

/**
 * Calls the `/quick_search` API endpoint.
 *
 * @param {string} sQuery - The search query.
 * @param {array} aAbortControllers - An array of AbortControllers.
 * @param {function} fnHandleResults - A function to handle the results.
 * @returns {Promise<void>}
 */
const fnCallQuickSearch = async (
  sQuery,
  aAbortControllers,
  fnHandleResults
) => {
  // Cancel any previous search API calls
  aAbortControllers.forEach((oAbortController) => {
    oAbortController.abort();
  });
  // eslint-disable-next-line no-param-reassign
  aAbortControllers.length = 0;

  // Set up a new AbortController
  const oAbortController = new AbortController();
  aAbortControllers.push(oAbortController);

  // Call quick search API endpoint
  const sQueryParams = fnBuildQuickSearchQueryParams(sQuery, 'constituent');
  const oResults = await oWnApi.get(
    `quick_search?${sQueryParams}`,
    false,
    oAbortController.signal
  );

  if (oResults.status === 200) {
    fnHandleResults(oResults.data);
  } else {
    throw new Error(`Search for '${sQuery}' failed. ${oResults}`);
  }
};

// Debounced version of fnCallQuickSearch
const fnDebouncedCallQuickSearch = debounce(fnCallQuickSearch, 500);

export default fnDebouncedCallQuickSearch;
