import { useField } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { currentUserLookupIdSelector } from 'redux/selectors';
import { TextField, InternationalPhoneField } from '../../../Form/Fields';
import Tooltip from '../../../Layout/Tooltip';

const AlumniGoogleAccountFormBody = () => {
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const [oPasswordField, oPasswordFieldMeta] = useField('password');
  return (
    <>
      <div className='emailActivation__formUsernameWrapper'>
        <TextField
          sId='username'
          sName='username'
          sLabel='Username'
          sHelpText='Your alumni username will become the prefix to your new alumni Gmail account (i.e. jane.smith@alumni.wfu.edu).'
        />
        <p className='emailActivation__formUsernameDomain'>@alumni.wfu.edu</p>
      </div>
      <div
        className={
          oPasswordFieldMeta.error ? 'boxField boxField--error' : 'boxField'
        }
      >
        <input
          {...oPasswordField}
          type='password'
          id='password'
          placeholder=' ' // This empty space placeholder allows the CSS animation to work.
        />
        <label htmlFor='password'>Password</label>
        <p className='field__helpText'>
          Make sure your password is at least 5 characters long.
        </p>
        {oPasswordFieldMeta.error && (
          <p className='field__errorMessage'>{oPasswordFieldMeta.error}</p>
        )}
      </div>
      <TextField
        sId='recovery-email'
        sName='recoveryEmail'
        sLabel='Recovery Email'
        sHelpText='If you forget your password and/or username, it will be sent here.'
      />
      <InternationalPhoneField
        countryFieldOptions={{
          id: 'recovery-phone-country',
          label: 'Country',
          name: 'recoveryPhoneCountry',
        }}
        phoneFieldOptions={{
          id: 'recovery-phone-number',
          label: 'Phone Number',
          name: 'recoveryPhoneNumber',
        }}
        groupLabel={
          <>
            * Enter your <strong>recovery phone</strong>:
            <Tooltip
              sTriggerIcon='info'
              ariaId={`alum-email-form-recovery-phone-${sLookupId}`}
            >
              If you forget your password and/or username, it will be sent here.
            </Tooltip>
          </>
        }
      />
    </>
  );
};

export default AlumniGoogleAccountFormBody;
