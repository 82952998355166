import { object, string } from 'yup';

import { coerceToString } from 'utils/yupHelpers';

const microscoreDetailsDataNormalizer = object({
  MICROSCOREDETAILS: object({
    PROGRAMOFSTUDY: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    MAJORMINOR: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    GREEK: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    SAMEATHLETIC: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    EMPLOYMENT: object({
      DESCRIPTION: string().transform(coerceToString),
      SCORE_EMPLOY_PRORATED: string(),
    }),
    SECONDARYCONTACTS: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    NEIGHBOR: object({
      DESCRIPTION: string().transform(coerceToString).ensure(),
    }),
    EVENTATTENDANCE: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    PARENT: object({
      DESCRIPTION: string().transform(coerceToString),
    }),
    NOTES: string().transform(coerceToString),
  }),
});

/**
 * Transforms microscore details into a human-friendly, comma-separated list.
 *
 * @example
 * // returns 'Identical class year/program, Identical major or minor'
 * fnFormatMicroscoreDetails({
 *    MICROSCOREDETAILS: {
 *        PROGRAMOFSTUDY: {
 *            DESCRIPTION: 'Identical class year/program'
 *        },
 *        MAJORMINOR: {
 *            DESCRIPTION: 'Identical major or minor'
 *        },
 *      }
 *    }
 * });
 *
 * @param {object} oData - Microscore details API response data
 * @returns {string}
 */
export const fnFormatMicroscoreDetails = async (oData) => {
  const { MICROSCOREDETAILS } = await microscoreDetailsDataNormalizer.validate(
    oData
  );
  const aDetails = Object.keys(MICROSCOREDETAILS).reduce((aResult, sKey) => {
    const { DESCRIPTION } = MICROSCOREDETAILS[sKey];
    if (DESCRIPTION) {
      aResult.push(DESCRIPTION);
    }
    return aResult;
  }, []);
  const sDetails = aDetails.join(', ');
  return sDetails;
};

export default { fnFormatMicroscoreDetails };
