import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Accordion from 'components/Layout/Accordion';
import EventDateBlock from './EventDateBlock';

/**
 * Displays a multi level event and its sub events.
 */
const MultiLevelEventCard = ({ oEvent, SubEventComponent }) => (
  <li
    className='multiLevelEventCard'
    data-cy={`event-${oEvent.EVENT_LOOKUPID}-card`}
  >
    <div className='multiLevelEventCard__header'>
      <EventDateBlock oEvent={oEvent} />
      <Link
        className='multiLevelEventCard__title t-paragraph--bold'
        to={`/events/${oEvent.SLUG}`}
        data-cy='event-details-link'
      >
        {oEvent.TITLE}
      </Link>
      {/* @todo: remove this Homecoming 2024 lookup id check after HC moves to WN event registration */}
      {oEvent.EVENT_LOOKUPID === '8-10010554' ? (
        <a
          href='https://registration.secure.wfu.edu/homecoming/events'
          target='_blank'
          className='button--tertiary'
          rel='noreferrer'
          style={{ display: 'block', marginLeft: 'auto' }}
        >
          Register for Homecoming events
        </a>
      ) : (
        <Link
          to={`/events/${oEvent.SLUG}`}
          className='multiLevelEventCard__register button button--tertiary'
          data-cy='register-button'
        >
          Register
        </Link>
      )}
    </div>
    <div className='multiLevelEventCard__subEventsWrapper'>
      <ul className='multiLevelEventCard__subEvents'>
        {oEvent.SUB_EVENTS.map((oSubEvent) => {
          if (oSubEvent.SUB_EVENTS?.length > 0) {
            return (
              <Accordion
                mTitle={oSubEvent.TITLE}
                sTitleIcon='mapMarker'
                key={oSubEvent.EVENT_ID}
                sSize='small'
                className='multiLevelEventCard__subEventsGrouping'
              >
                <div>
                  {oSubEvent.SUB_EVENTS.map((oSubSubEvent) => (
                    <SubEventComponent
                      key={oSubSubEvent.EVENT_ID}
                      oEvent={oSubSubEvent}
                    />
                  ))}
                </div>
              </Accordion>
            );
          }
          return (
            <SubEventComponent key={oSubEvent.EVENT_ID} oEvent={oSubEvent} />
          );
        })}
      </ul>
    </div>
  </li>
);

MultiLevelEventCard.propTypes = {
  oEvent: PropTypes.shape({
    SLUG: PropTypes.string,
    EVENT_ID: PropTypes.string,
    EVENT_LOOKUPID: PropTypes.string,
    TITLE: PropTypes.string,
    START_DATE_TIME: PropTypes.string,
    END_DATE_TIME: PropTypes.string,
    LOCATION: PropTypes.shape({
      NAME: PropTypes.string,
      ROOM: PropTypes.string,
      ADDRESSBLOCK: PropTypes.string,
    }),
    CONTACT: PropTypes.shape({
      NAME: PropTypes.string,
      EMAIL: PropTypes.string,
      LOOKUPID: PropTypes.string,
    }),
    SUB_EVENTS: PropTypes.arrayOf(
      PropTypes.shape({
        EVENT_ID: PropTypes.string,
        TITLE: PropTypes.string,
        START_DATE_TIME: PropTypes.string,
        END_DATE_TIME: PropTypes.string,
        LOCATION: PropTypes.shape({
          NAME: PropTypes.string,
          ROOM: PropTypes.string,
          ADDRESSBLOCK: PropTypes.string,
        }),
        CONTACT: PropTypes.shape({
          NAME: PropTypes.string,
          EMAIL: PropTypes.string,
          LOOKUPID: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
  SubEventComponent: PropTypes.func.isRequired,
};

export default MultiLevelEventCard;
