import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useWindowSize from 'utils/hooks/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import oIconLibrary from 'utils/iconLibrary';
import { fnChildrenPropType } from 'utils/customPropTypes';

// ARIA implementation based on example found here: https://pauljadam.com/demos/tooltip.html
const Tooltip = ({
  children,
  sTriggerIcon,
  position,
  ariaLabel,
  ariaId,
  sWrapperClassName,
}) => {
  const oTooltipRef = useRef(null);
  const [oStyle, fSetStyle] = useState(null);
  const oWindowSize = useWindowSize();

  useEffect(() => {
    if (oTooltipRef.current && position === 'top') {
      const oTooltipBox = oTooltipRef.current.getBoundingClientRect();
      fSetStyle((oPreviousStyle) => ({
        ...oPreviousStyle,
        top: `-${oTooltipBox.height}px`,
      }));
    }
  }, [oTooltipRef, position]);

  // Prevent tooltips from falling off the side of the screen.
  useEffect(() => {
    if (oTooltipRef.current) {
      const oTooltipBox = oTooltipRef.current.getBoundingClientRect();
      const iRightXCoord = oTooltipBox.x + oTooltipBox.width;
      const iHalfTooltipWidth = oTooltipBox.width / 2;

      const iScreenWidth = window.screen.width;
      // This value must equal the SASS variable `$screen-padding` found in `_tooltip.scss`
      const iScreenPadding = 30;

      const bIsOffRightSideOfScreen = iRightXCoord > iScreenWidth;
      const bIsOffLeftSideOfScreen = oTooltipBox.x < 0;

      if (bIsOffRightSideOfScreen) {
        const iShiftValue = oTooltipBox.x + iHalfTooltipWidth - iScreenPadding;
        fSetStyle((oPreviousStyle) => ({
          ...oPreviousStyle,
          transform: `translateX(-${iShiftValue}px)`,
        }));
      } else if (bIsOffLeftSideOfScreen) {
        const iShiftValue =
          Math.abs(oTooltipBox.x) - iHalfTooltipWidth + iScreenPadding;
        fSetStyle((oPreviousStyle) => ({
          ...oPreviousStyle,
          transform: `translateX(${iShiftValue}px)`,
        }));
      } else {
        // The tooltip already fits on the screen, so clear any previous transformations.
        fSetStyle((oPreviousStyle) => {
          const oResetStyle = R.omit(['transform'], oPreviousStyle);
          return oResetStyle;
        });
      }
    }
  }, [oTooltipRef, position, oWindowSize.width]);

  return (
    <div className={`tooltip__wrapper ${sWrapperClassName}`}>
      <button
        type='button'
        className='tooltip__triggerButton button--iconOnly--tertiary'
        aria-label={ariaLabel}
        aria-describedby={`tooltip-${ariaId}`}
      >
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome[sTriggerIcon]} />
      </button>
      <div
        id={`tooltip-${ariaId}`} // for aria
        className={`tooltip tooltip--${position} t-paragraph--small`}
        role='tooltip' // for aria
        ref={oTooltipRef}
        style={oStyle}
      >
        {children}
      </div>
    </div>
  );
};

Tooltip.defaultProps = {
  sTriggerIcon: 'help',
  position: 'bottom',
  ariaLabel: 'Help',
  sWrapperClassName: '',
};

Tooltip.propTypes = {
  sTriggerIcon: PropTypes.oneOf(Object.keys(oIconLibrary.fontAwesome)),
  children: fnChildrenPropType.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  ariaLabel: PropTypes.string,
  ariaId: PropTypes.string.isRequired,
  sWrapperClassName: PropTypes.string,
};

export default Tooltip;
