import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import LoadingZone from 'components/LoadingZone';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import useProfileData from 'utils/hooks/useProfileData';
import {
  fnBuildInitialValues,
  oAdditionalInfoFormSchema,
  sAdditionalInfoFormId,
  fnSaveAdditionalInfoToCart,
} from './helpers';
import { selectAllNamedEventGuests } from './selectors';
import { selectNextEventRegStep } from '../EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import EventRegistrationContext from '../EventRegistrationContext';
import AdditionalInfoFormBody from './AdditionalInfoFormBody';

const AdditionalInfoForm = () => {
  const fnNavigate = useNavigate();
  const [bIsLoading, fnSetIsLoading] = useState(true);
  const aNamedGuests = useSelector(selectAllNamedEventGuests);
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(state, window.location.pathname)
  );
  const aDietaryRestrictionCodes = useCodeTablesSection('DIETARY_RESTRICTION');
  const { fnSetCurrentFormId } = useContext(EventRegistrationContext);
  const sPrimaryRegistrantLookupId = useSelector(
    (state) => state.Checkout.userLookupId
  );
  const bIsProfileDataLoading = useProfileData(sPrimaryRegistrantLookupId);

  const oMemoizedInitialValues = useMemo(
    () => fnBuildInitialValues(aNamedGuests),
    [aNamedGuests]
  );

  // Show the form after the dietary restriction codes and the user's data are available
  useEffect(() => {
    if (!bIsProfileDataLoading && aDietaryRestrictionCodes.length > 0) {
      fnSetCurrentFormId(sAdditionalInfoFormId);
      fnSetIsLoading(false);
    }

    return () => {
      fnSetCurrentFormId('');
    };
  }, [
    aDietaryRestrictionCodes.length,
    fnSetCurrentFormId,
    bIsProfileDataLoading,
  ]);

  return (
    <LoadingZone isLoading={bIsLoading}>
      <Formik
        initialValues={oMemoizedInitialValues}
        validationSchema={oAdditionalInfoFormSchema}
        onSubmit={(oFormValues) => {
          fnSaveAdditionalInfoToCart(oFormValues);
          fnSetCurrentFormId('');
          fnNavigate(sNextStepUrl);
        }}
      >
        <Form className='additionalInfoForm' id={sAdditionalInfoFormId}>
          <AdditionalInfoFormBody />
        </Form>
      </Formik>
    </LoadingZone>
  );
};

export default AdditionalInfoForm;
