import PropTypes from 'prop-types';

export const giftPropType = PropTypes.shape({
  DESIGNATIONS: PropTypes.arrayOf(
    PropTypes.shape({
      TITLE: PropTypes.string,
      AMOUNT: PropTypes.string,
    })
  ),
  GIFT_DATE: PropTypes.string,
  ID: PropTypes.string,
  LEGAL_DONOR: PropTypes.shape({
    LOOKUPID: PropTypes.string,
    NAME: PropTypes.string,
  }),
  TOTAL_AMOUNT: PropTypes.string,
  TYPE: PropTypes.string,
});

export default {};
