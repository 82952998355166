import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotInvitedEnvelope from 'images/not-invited-envelope.svg';
import Button from 'components/Buttons/Button';
import EventDetailsPageHeader from './EventDetailsPageHeader';

// Informs the user that the event is invite-only and provides contact information
const EventDetailsInviteOnly = ({ oEvent }) => {
  const fnNavigate = useNavigate();
  const { CONTACT } = oEvent;
  return (
    <>
      <div className='eventDetails__sectionWithLine'>
        <EventDetailsPageHeader oEvent={oEvent} />
      </div>
      <img
        src={NotInvitedEnvelope}
        className='eventDetails__inviteOnlyImage'
        alt='Closed gold envelope with a top hat sticker seal surrounded by sparkles'
      />
      <h2 className='notInvited__title t-paragraph--medium'>
        This is an invite-only event.
      </h2>
      <p className='notInvited__contact'>
        If there&apos;s been a mistake, please contact {CONTACT.NAME} at{' '}
        <a href={`mailto:${CONTACT.EMAIL}`}>{CONTACT.EMAIL}</a>.
      </p>
      <Button
        sFlavor='secondary'
        className='notInvited__backButton'
        fnHandleClick={() => {
          fnNavigate(-1);
        }}
      >
        Back
      </Button>
    </>
  );
};

EventDetailsInviteOnly.propTypes = {
  oEvent: PropTypes.shape({
    CONTACT: PropTypes.shape({
      NAME: PropTypes.string.isRequired,
      EMAIL: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EventDetailsInviteOnly;
