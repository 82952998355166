import { createActions } from 'redux-actions';

export const { update, reset } = createActions(
  {
    UPDATE: (action) => action,
    RESET: (action) => action,
  },
  {
    prefix: 'PledgePayment',
  }
);
