import * as R from 'ramda';
import { createSelector } from 'reselect';

const profileSelector = (state) => state.Profile;

/**
 * Builds a list of public employers, including only public jobs.
 *
 * @param {array} aAllEmployment - All the user's employment records from the Redux store.
 * @returns {array}
 */
export const fnGetPublicEmployment = (aAllEmployment) => {
  const aPublicEmployers = aAllEmployment.filter(
    (oEmployer) => oEmployer.PRIVACY === '0'
  );
  const aPublicEmployment = aPublicEmployers.map((oEmployer) => {
    const aPublicJobs = oEmployer.POSITIONS.filter(
      (oJob) => oJob.PRIVACY === '0'
    );
    return {
      ...oEmployer,
      POSITIONS: aPublicJobs,
    };
  });
  return aPublicEmployment;
};

/**
 * Returns original spouse data if the spouse is public
 * and returns null if the spouse is private.
 *
 * @param {object} oSpouse - Spouse data
 * @returns {object}
 */
const fnGetPublicSpouse = (oSpouse) => {
  let oPublicSpouse = null;
  const bIsPublic = oSpouse?.PRIVACY === '0';
  if (bIsPublic) {
    oPublicSpouse = oSpouse;
  }
  return oPublicSpouse;
};

/**
 * Returns public records only.
 *
 * @param {array} aRecords - List of records with privacy settings
 * @returns {array}
 */
const fnGetPublicRecords = (aRecords) =>
  aRecords.filter(
    (oRecord) => oRecord.PRIVACY === '0' || oRecord.PRIVACY === '1'
  );

const fnDerivePublicProfile = (oProfile) => {
  let oPublicProfile = null;

  if (oProfile) {
    const aProfileKeysToCopy = [
      'ADDRESS',
      'BIOGRAPHICAL',
      'DEGREE',
      'EMAIL',
      'EMPLOYMENT',
      'INVOLVEMENT',
      'JOB_SPECIALTY',
      'PHONE',
      'SPOUSE',
    ];
    oPublicProfile = R.pick(aProfileKeysToCopy, oProfile);

    Object.keys(oPublicProfile).forEach((sKey) => {
      const mValue = oPublicProfile[sKey];
      switch (sKey) {
        case 'EMPLOYMENT':
          oPublicProfile[sKey] = fnGetPublicEmployment(mValue);
          break;
        case 'BIOGRAPHICAL': // None of this data has privacy settings
          break;
        case 'SPOUSE':
          oPublicProfile[sKey] = fnGetPublicSpouse(mValue);
          break;
        default:
          oPublicProfile[sKey] = fnGetPublicRecords(mValue);
          break;
      }
    });
  }

  return oPublicProfile;
};

export const currentUserPublicProfileSelector = createSelector(
  profileSelector,
  fnDerivePublicProfile
);
