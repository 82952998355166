import * as Yup from 'yup';

import { fnUpdateGuestAdditionalInfo } from '../redux/guestHelpers';

/**
 * Builds the initial values for the form.
 *
 * @param {array} aNamedGuests - All named guests from all events in the cart
 * @returns {object} - The initial values for the form
 */
export const fnBuildInitialValues = (aNamedGuests) => {
  const aGuestsInitialValues = aNamedGuests.map((oGuest) => {
    const { NAME, LOOKUPID, ADDITIONAL_INFO } = oGuest;
    const {
      DIETARY_RESTRICTIONS,
      OTHER_DIETARY_RESTRICTION,
      ADDITIONAL_INFO_CONTENT,
    } = ADDITIONAL_INFO;

    return {
      sGuestLookupId: LOOKUPID,
      sGuestName: NAME,
      dietaryRestrictions: DIETARY_RESTRICTIONS,
      otherDietaryRestriction: OTHER_DIETARY_RESTRICTION,
      additionalInfo: ADDITIONAL_INFO_CONTENT,
    };
  });

  const oInitialValues = {
    additionalInfoByGuest: aGuestsInitialValues,
  };

  return oInitialValues;
};

// If the user chooses "Other" as a dietary restriction, they must provide more information
export const oAdditionalInfoFormSchema = Yup.object().shape({
  additionalInfoByGuest: Yup.array().of(
    Yup.object().shape({
      otherDietaryRestriction: Yup.string().when(
        'dietaryRestrictions',
        ([aDietaryRestrictions]) => {
          const bHasChosenOtherDietaryRestriction = aDietaryRestrictions.find(
            (oCode) => oCode.DESCRIPTION === 'Other'
          );
          return bHasChosenOtherDietaryRestriction
            ? Yup.string().required(
                'Please let us know about your other dietary restriction(s).'
              )
            : Yup.string();
        }
      ),
    })
  ),
});

// Allows clicking the "Next" button in the footer to submit the form
export const sAdditionalInfoFormId = 'event-registration-additional-info-form';

export const fnSaveAdditionalInfoToCart = (oFormValues) => {
  oFormValues.additionalInfoByGuest.forEach((oGuest) => {
    const { sGuestLookupId, dietaryRestrictions, additionalInfo } = oGuest;

    // Clear other dietary restriction data if the user didn't choose "Other"
    let sOtherDietaryRestriction = oGuest.otherDietaryRestriction;
    if (
      sOtherDietaryRestriction &&
      !dietaryRestrictions.find((oCode) => oCode.DESCRIPTION === 'Other')
    ) {
      sOtherDietaryRestriction = '';
    }

    fnUpdateGuestAdditionalInfo(
      sGuestLookupId,
      dietaryRestrictions,
      sOtherDietaryRestriction,
      additionalInfo
    );
  });
};
