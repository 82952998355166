import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import CheckoutTrackerStep from './CheckoutTrackerStep';
import {
  getSelectStepLink,
  getCurrentStepNumber,
  getCheckoutStepsMinusSelect,
} from '../helpers';

/**
 * Responsible for checkout tracker showing user where they are in the process.
 *
 * @param {object} props - The props passed to this component.
 * @param {string} props.sFirstStepLabel - The label for the select step in the checkout process.
 * @param {string} props.sCurrentStep - The current step in the checkout process for this page.
 *     Options are 'select', 'review', 'payment' and 'confirmation'.
 * @param {boolean} props.bHasPayment - Whether there is a payment step.
 *
 * @author Elizabeth Hicks <hicksem@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const CheckoutTracker = ({ sFirstStepLabel, sCurrentStep, bHasPayment }) => {
  const iCurrentStepNumber = getCurrentStepNumber(sCurrentStep, bHasPayment);
  const sSelectStepLink = getSelectStepLink(iCurrentStepNumber, useLocation());
  const aCheckoutStepsMinusSelect = getCheckoutStepsMinusSelect(bHasPayment);

  return (
    <ol className='checkoutTracker'>
      <CheckoutTrackerStep
        iStepNumber={1}
        sStepLabel={sFirstStepLabel}
        sLink={sSelectStepLink}
        iCurrentStepNumber={iCurrentStepNumber}
      />
      {aCheckoutStepsMinusSelect.map((oStep) => (
        <CheckoutTrackerStep
          key={oStep.number}
          iStepNumber={oStep.number}
          sStepLabel={oStep.label}
          iCurrentStepNumber={iCurrentStepNumber}
        />
      ))}
    </ol>
  );
};

CheckoutTracker.defaultProps = {
  sFirstStepLabel: 'Select',
  bHasPayment: true,
};

CheckoutTracker.propTypes = {
  sFirstStepLabel: PropTypes.string,
  sCurrentStep: PropTypes.string.isRequired,
  bHasPayment: PropTypes.bool,
};

export default CheckoutTracker;
