import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import Button from 'components/Buttons/Button';
import oIconLibrary from 'utils/iconLibrary';
import { fnGetIdpIconFromUsername } from './helpers';

const IdentityProviderAccountManagement = () => {
  const [sSignedInAccount, fnSetSignedInAccount] = useState('');
  const [sIdp, fnSetIdp] = useState('');

  useEffect(() => {
    const fnGetSignedInAccount = async () => {
      const oUser = await Auth.currentUserInfo();
      if (oUser) {
        fnSetSignedInAccount(oUser.attributes.email);
        fnSetIdp(fnGetIdpIconFromUsername(oUser.username));
      }
    };
    fnGetSignedInAccount();
  }, []);

  const fnSignOut = () => Auth.signOut({ global: true });
  return sSignedInAccount ? (
    <div className='authLayout__identityProviderAccountManagement'>
      <p className='authLayout__identityProviderAccount t-paragraph--small'>
        Logged in with <img src={oIconLibrary.wfu[sIdp]} alt={`${sIdp} logo`} />{' '}
        as <strong>{sSignedInAccount}</strong>
      </p>
      <Button
        sFlavor='underline'
        fnHandleClick={fnSignOut}
        className='authLayout__identityProviderAccountSignOut'
      >
        Sign Out
      </Button>
    </div>
  ) : null;
};

export default IdentityProviderAccountManagement;
