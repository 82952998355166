import { useState, useEffect } from 'react';

export default function useIsOnScreen(oElementRef) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let observer = null;
    if (oElementRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        { threshold: 0.1 } // At least 10% of the element is visible
      );

      observer.observe(oElementRef.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oElementRef.current]); // Use oElementRef.current instead of oElementRef. ESLint complains, but this works.

  return isIntersecting;
}
