import React from 'react';
import PropTypes from 'prop-types';

export const SkeletonText = ({ size, width, className }) => (
  <div
    className={`${className} skeletonText skeletonText--${size}`}
    style={{ width }}
  />
);

SkeletonText.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  width: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SkeletonText.defaultProps = {
  size: 'medium',
  className: '',
};

export default SkeletonText;
