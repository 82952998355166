import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import useWindowSize from 'utils/hooks/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import oIconLibrary from 'utils/iconLibrary';
import LoadingZone from 'components/LoadingZone';
import { SkeletonText } from 'components/LoadingZone/Skeleton';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';

/**
 * Displays a link to the parent event, if the current event is a sub-event.
 */
const EventDetailsPageBreadcrumbs = ({ oEvent }) => {
  const oWindowSize = useWindowSize();
  const bIsAuthenticated = useIsUserAuthenticated();

  return (
    <LoadingZone
      isLoading={!oEvent}
      skeleton={
        <div className='eventDetailsPage__breadcrumbsSkeleton'>
          <SkeletonText size='small' width='10%' />
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome.arrowRight} />
          <SkeletonText size='small' width='30%' />
        </div>
      }
    >
      <div className='eventDetailsPage__breadcrumbs t-paragraph--small'>
        <Link
          to={bIsAuthenticated ? '/main' : '/sign-in'}
          className='t-paragraph--small'
        >
          Home
        </Link>
        {oEvent?.MAIN_EVENT?.LOOKUPID && (
          <>
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome.arrowRight} />
            <Link
              to={`/events/${oEvent?.MAIN_EVENT?.SLUG}`}
              className='t-paragraph--small'
            >
              {oEvent?.MAIN_EVENT?.TITLE}
            </Link>
          </>
        )}
        {oWindowSize.sScreenSize !== 'small' && (
          <>
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome.arrowRight} />
            <span>{oEvent?.TITLE}</span>
          </>
        )}
      </div>
    </LoadingZone>
  );
};

EventDetailsPageBreadcrumbs.propTypes = {
  oEvent: PropTypes.shape({
    MAIN_EVENT: PropTypes.shape({
      LOOKUPID: PropTypes.string,
      SLUG: PropTypes.string,
      TITLE: PropTypes.string,
    }),
    TITLE: PropTypes.string,
  }),
};

EventDetailsPageBreadcrumbs.defaultProps = {
  oEvent: null,
};

export default EventDetailsPageBreadcrumbs;
