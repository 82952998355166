import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useWindowSize, { WindowSizeType } from 'utils/hooks/useWindowSize';
import Button from 'components/Buttons/Button';
import UpcomingDowntimeBanner from 'components/Downtime/UpcomingDowntimeBanner';
import SocialSignInModal from './SocialSignInModal';

type LandingPageIntroProps = {
  aMaintenanceAlerts: Array<any>;
};

const LandingPageIntro = ({
  aMaintenanceAlerts = [],
}: LandingPageIntroProps) => {
  const [bShowSignInModal, fnSetShowSignInModal] = useState(false);
  const oWindowSize: WindowSizeType = useWindowSize();

  return (
    <div className='landingPage__intro'>
      {aMaintenanceAlerts.length > 0 && (
        <UpcomingDowntimeBanner aMaintenanceAlerts={aMaintenanceAlerts} />
      )}
      <div className='landingPage__introText'>
        <h1 className='landingPage__introTitle'>
          Welcome to
          <br />
          Wake<span className='landingPage__introTitle--gold'>Network</span>
        </h1>
        <p className='landingPage__introSubtitle t-header4'>
          It&apos;s not who you know, it&apos;s what you know about who you
          know.
        </p>
      </div>
      <Button
        sFlavor='secondary'
        fnHandleClick={() => fnSetShowSignInModal(true)}
        className='landingPage__signInButton'
        data-cy='landingPage__signInButton'
      >
        Access your Network
      </Button>
      {oWindowSize.sScreenSize === 'small' && (
        <Link to='/sign-up/help/noverify' className='landingPage__contactLink'>
          Contact Us
        </Link>
      )}
      {bShowSignInModal && (
        <SocialSignInModal fnSetShowSignInModal={fnSetShowSignInModal} />
      )}
    </div>
  );
};

export default LandingPageIntro;
