import React from 'react';
import PropTypes from 'prop-types';

import ConstituentAvatar from 'components/ConstituentAvatar';
import LinkWithSourcePath from 'utils/LinkWithSourcePath';
import Accordion from '../../../Layout/Accordion';
import FoundationDonor from './FoundationDonor';

const FundDonors = ({ aDonors }) => (
  <Accordion
    mTitle='Fund Donors'
    sSize='small'
    sGtmCategory='Scholarship: Fund Donors'
    className='scholarships__donorAccordion'
  >
    <ul className='scholarships__donorList'>
      {aDonors.map((oDonor) => (
        <li className='scholarships__donor t-paragraph' key={oDonor.LOOKUPID}>
          {oDonor.IS_FOUNDATION ? (
            <FoundationDonor oDonor={oDonor} />
          ) : (
            <>
              <ConstituentAvatar
                lookupId={oDonor.LOOKUPID}
                sSize='small'
                sFirstName={oDonor.FIRSTNAME}
                sLastName={oDonor.LASTNAME}
                bHasPhoto={oDonor.HAS_PHOTO}
              />
              <LinkWithSourcePath
                to={`/scholarships/profile/${oDonor.LOOKUPID}`}
                referringComponent='Scholarships'
              >
                {oDonor.NAME}
              </LinkWithSourcePath>
            </>
          )}
        </li>
      ))}
    </ul>
  </Accordion>
);

FundDonors.propTypes = {
  aDonors: PropTypes.arrayOf(
    PropTypes.shape({
      NAME: PropTypes.string,
      LOOKUPID: PropTypes.string,
      FIRSTNAME: PropTypes.string,
      LASTNAME: PropTypes.string,
      HAS_PHOTO: PropTypes.bool,
    })
  ),
};

FundDonors.defaultProps = {
  aDonors: [],
};

export default FundDonors;
