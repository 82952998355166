import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Displays error message for an expired magic link.
 */
const ExpiredMagicLink = ({ sVerificationResult }) => {
  const fnGetErrorMessage = () => {
    switch (sVerificationResult) {
      case 'EXCEEDED':
        return (
          <>
            <p className='magicLink__textContent'>
              You&apos;ve run out of verification attempts.
            </p>
            <Link to='/sign-up/help/noverify' className='magicLink__button'>
              Contact Us
            </Link>
          </>
        );
      case 'FAIL':
      default:
        return (
          <>
            <p className='magicLink__textContent'>
              It looks like that magic link has expired. Click the button below
              to go back through and request another one.
            </p>
            <Link to='/sign-in' className='magicLink__button'>
              Return to Sign In
            </Link>
          </>
        );
    }
  };

  return (
    <>
      <h1 className='magicLink__greeting t-paragraph--medium'>Oops!</h1>
      {fnGetErrorMessage()}
    </>
  );
};

ExpiredMagicLink.propTypes = {
  sVerificationResult: PropTypes.string.isRequired,
};

export default ExpiredMagicLink;
