import { takeEvery, put, select } from 'redux-saga/effects';

import { currentUserLookupIdSelector } from 'redux/selectors';
import oWnApi from 'utils/WnApi';
import * as scholarshipsActions from './actions';
import scholarshipsSchema from '../schema';

function* getScholarshipsData({ payload: { resolve, reject } }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const oResponse = yield oWnApi.get(`constituents/${sLookupId}/scholarships`);
  if (oResponse.status === 200) {
    const scholarshipsData = yield scholarshipsSchema.validate(oResponse.data);
    yield put(scholarshipsActions.set(scholarshipsData));
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* updateScholarshipNote({ payload: { ID, data, resolve, reject } }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestUrl = `constituents/${sLookupId}/scholarships`;
  const oRequestPayload = {
    ID,
    TYPE: 'Note',
    data: {
      TEXTNOTE: data.TEXTNOTE,
    },
  };
  const oResponse = yield oWnApi.patch(sRequestUrl, oRequestPayload);
  if (oResponse.status === 200) {
    const scholarshipsData = yield scholarshipsSchema.validate(oResponse.data);
    yield put(scholarshipsActions.set(scholarshipsData));
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* createNote({
  payload: { TITLE, TEXTNOTE, NOTETYPECODEID, FUNDPURPOSEID, resolve, reject },
}) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestUrl = `constituents/${sLookupId}/scholarships`;
  const oRequestPayload = {
    TYPE: 'Note',
    data: {
      TITLE,
      TEXTNOTE,
      NOTETYPECODEID,
      FUNDPURPOSEID,
    },
  };
  const oResponse = yield oWnApi.post(sRequestUrl, oRequestPayload);
  if (oResponse.status === 200) {
    const oScholarshipsData = yield scholarshipsSchema.validate(oResponse.data);
    yield put(scholarshipsActions.set(oScholarshipsData));
    resolve('Success');
  } else {
    reject('Error');
  }
}

export default function* watchScholarships() {
  yield takeEvery('Scholarships/GET', getScholarshipsData);
  yield takeEvery('Scholarships/ADD_NOTE', createNote);
  yield takeEvery('Scholarships/UPDATE', updateScholarshipNote);
}
