import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import NotInvitedEnvelope from 'images/not-invited-envelope.svg';
import Button from 'components/Buttons/Button';
import usePageTitle from 'utils/hooks/usePageTitle';

const NotInvited = () => {
  const fnNavigate = useNavigate();
  const oLocation = useLocation();
  const { sEventTitle, oEventContact } = oLocation.state;
  usePageTitle('Invite-only Event');
  return (
    <div className='eventRegistrationContainer'>
      <img
        src={NotInvitedEnvelope}
        className='notInvited__image'
        alt='Closed gold envelope with a top hat sticker seal surrounded by sparkles'
      />
      <h1 className='notInvited__title t-paragraph--medium'>
        {sEventTitle} is an invite-only event.
      </h1>
      <p className='notInvited__contact'>
        If there&apos;s been a mistake, please contact {oEventContact.NAME} at{' '}
        <a href={`mailto:${oEventContact.EMAIL}`}>{oEventContact.EMAIL}</a>.
      </p>
      <Button
        sFlavor='secondary'
        className='notInvited__backButton'
        fnHandleClick={() => {
          fnNavigate(-1);
        }}
      >
        Back
      </Button>
    </div>
  );
};

export default NotInvited;
