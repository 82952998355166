import React from 'react';
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
} from 'react-virtualized';
import PropTypes from 'prop-types';

import TopHats from '../../TopHats';
import ConstituentAvatar from '../../../../ConstituentAvatar';
import LinkWithSourcePath from '../../../../../utils/LinkWithSourcePath';

export const ResultsList = ({ results }) => {
  const cellHeightCache = new CellMeasurerCache({
    defaultHeight: 125,
    fixedWidth: true,
  });

  const rowRenderer = ({ index, parent, key, style }) => {
    const {
      NAME,
      EMPLOYER,
      CITY,
      STATE,
      COUNTRY,
      LOOKUPID,
      SCORE_1TO5,
      FIRSTNAME,
      LASTNAME,
      HAS_PHOTO,
    } = results[index];

    // On mobile, line breaks are added and seperating characters
    // are removed via css
    const sCityStateCountry = [CITY, STATE, COUNTRY].filter(Boolean).join(', ');

    const sEmployer =
      EMPLOYER && EMPLOYER !== 'Unknown Employer' ? EMPLOYER : '';

    return (
      <CellMeasurer
        key={key}
        cache={cellHeightCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={{ ...style }}>
          <div className='searchItem'>
            <LinkWithSourcePath
              className='searchResults__modalTrigger'
              to={`/main/profile/${LOOKUPID}`}
              referringComponent='AdvancedSearch'
            >
              <ConstituentAvatar
                lookupId={LOOKUPID}
                sFirstName={FIRSTNAME}
                sLastName={LASTNAME}
                className='searchResults__avatar'
                bHasPhoto={HAS_PHOTO}
                sSize='large'
              />
            </LinkWithSourcePath>
            <div className='searchResults__userDetails'>
              <LinkWithSourcePath
                className='searchResults__userName searchResults__modalTrigger'
                to={`/main/profile/${LOOKUPID}`}
                referringComponent='AdvancedSearch'
              >
                {NAME}
              </LinkWithSourcePath>
              <div className='searchResults__userInfo'>
                {sCityStateCountry && (
                  <span className='cityStateEmployerPart'>
                    {sCityStateCountry}
                  </span>
                )}
                {sEmployer && (
                  <span className='cityStateEmployerPart'>{sEmployer}</span>
                )}
              </div>

              <div className='searchResults__topHats'>
                <TopHats score={SCORE_1TO5} lookupId={LOOKUPID} />
              </div>
            </div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  return results.length > 0 ? (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          rowCount={results.length}
          rowHeight={cellHeightCache.rowHeight}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  ) : (
    <div className='advancedSearch__noResults'>No results found.</div>
  );
};

ResultsList.defaultProps = {
  results: [],
};

ResultsList.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      NAME: PropTypes.string,
      EMPLOYER: PropTypes.string,
      CITY: PropTypes.string,
      STATE: PropTypes.string,
      COUNTRY: PropTypes.string,
      LOOKUPID: PropTypes.string,
      SCORE_1TO5: PropTypes.number,
      FIRSTNAME: PropTypes.string,
      LASTNAME: PropTypes.string,
      HAS_PHOTO: PropTypes.bool,
    })
  ),
};

export default ResultsList;
