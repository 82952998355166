import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { arrayCast, oCurrencyFormatter } from '../../../utils/common';
import Accordion from '../../Layout/Accordion';
import Gift from './Gift';
import HelpRequest from '../../Form/HelpRequest';
import { giftPropType } from './helpers';

const GivingHistory = ({ data }) => {
  // Show the first 5 years of giving history by default
  const [aGivingHistoryToShow, fnSetGivingHistoryToShow] = useState(
    data.slice(0, 5)
  );

  return (
    <>
      <div className='myGiving__dividerTitleBlock'>
        <span className='myGiving__dividerTitle'>
          Giving History by Fiscal Year
        </span>
        <HelpRequest
          className='myGiving__helpRequest'
          sNoteType='Wake Network: Gift Administration Assistance Requested'
          sNoteTitle='Giving Question'
          sGtmCategory='MyGiving'
          sGtmAction='submitted help request'
        />
      </div>
      <div className='myGiving__givingHistoryContent'>
        {aGivingHistoryToShow.map((year) => {
          const giftsArr = arrayCast(year.GIFTS);
          // All giving data before 1986 was rolled up into FY 1960
          const isRollupData = year.FISCAL_YEAR === '1960';
          return (
            <Accordion
              mTitle={isRollupData ? 'Pre-1986' : year.FISCAL_YEAR}
              sSize='medium'
              className='myGiving__yearSection'
              sGtmCategory='Giving History: Year'
              key={year.FISCAL_YEAR}
            >
              {giftsArr.map((gift) => (
                <Gift data={gift} forceExpanded key={gift.ID} />
              ))}
              <div className='myGiving__yearTotal'>
                <span>
                  Total for {isRollupData ? 'Pre-1986' : year.FISCAL_YEAR}
                </span>
                <span>{oCurrencyFormatter.format(year.TOTAL_AMOUNT)}</span>
              </div>
            </Accordion>
          );
        })}
        {aGivingHistoryToShow.length !== data.length && (
          <Button
            sFlavor='tertiary'
            fnHandleClick={() => fnSetGivingHistoryToShow(data)}
            className='myGiving__loadMoreButton'
            bIsCentered
          >
            Show all giving history
          </Button>
        )}
      </div>
    </>
  );
};

GivingHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      FISCAL_YEAR: PropTypes.string,
      GIFTS: PropTypes.arrayOf(giftPropType),
      IS_CURRENT_FISCAL_YEAR: PropTypes.bool,
      TOTAL_AMOUNT: PropTypes.string,
    })
  ).isRequired,
};

export default GivingHistory;
