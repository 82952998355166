import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import { useSelector } from 'react-redux';
import usePageTitle from 'utils/hooks/usePageTitle';
import useCurrentEventCartItem from './useCurrentEventCartItem';

const EventRegistrationCheckpoint = () => {
  const oCurrentEventCartItem = useCurrentEventCartItem();

  const sNextSubEventTitle = useSelector((state) => {
    const iCurrentEventIndex = state.Checkout.aCart.findIndex(
      (oItem) => oItem.sId === oCurrentEventCartItem?.sId
    );
    if (iCurrentEventIndex !== -1) {
      const oNextEvent = state.Checkout.aCart[iCurrentEventIndex + 1];
      return oNextEvent?.oDetails?.oEvent?.TITLE;
    }
    return '';
  });

  const bIsSubEventCheckpoint = !!sNextSubEventTitle;

  const fnGetSubEventCheckPointText = () => {
    const bAllowsGuests =
      oCurrentEventCartItem?.oDetails?.oEvent?.REGISTRATION?.MAX_GUESTS > 0;

    const bHasMultipleRegOptions =
      oCurrentEventCartItem?.oDetails?.oEvent?.REGISTRATION
        ?.REGISTRATION_OPTIONS?.length > 1;

    let sSubEventCheckpointOptionsText = '';
    switch (true) {
      case bAllowsGuests && bHasMultipleRegOptions:
        sSubEventCheckpointOptionsText =
          'Thanks for choosing guests and options for';
        break;
      case bAllowsGuests:
        sSubEventCheckpointOptionsText = "You've completed guest selection for";
        break;
      default: // bHasMultipleRegOptions:
        sSubEventCheckpointOptionsText = 'Thanks for choosing options for';
        break;
    }
    const sEventTitle = oCurrentEventCartItem?.oDetails?.oEvent?.TITLE;

    return `${sSubEventCheckpointOptionsText} ${sEventTitle}. Let's move on to ${sNextSubEventTitle}.`;
  };

  usePageTitle('Event Registration - Checkpoint');

  return (
    <div className='eventRegCheckpoint'>
      <h1 className='eventRegCheckpoint__title t-paragraph--medium'>
        {bIsSubEventCheckpoint ? (
          fnGetSubEventCheckPointText()
        ) : (
          <>
            Almost done! Let&apos;s review your registration and wrap this up.
          </>
        )}
      </h1>
      <FontAwesomeIcon
        icon={
          oIconLibrary.fontAwesome[
            bIsSubEventCheckpoint ? 'partyHorn' : 'rocket'
          ]
        }
        className='eventRegCheckpoint__icon'
      />
    </div>
  );
};

export default EventRegistrationCheckpoint;
