import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-spinners/FadeLoader';

/**
 * @description - Renders loading spinner until content is loaded.
 *
 * @param {bool} isLoading - If content is loading or not
 * @param {node} children - Content to render once loaded
 */
const LoadingZone = ({ isLoading, children, skeleton, message }) => {
  if (isLoading) {
    if (skeleton) {
      return (
        <>
          <p className='hide_accessibly'>{message}</p>
          {skeleton}
        </>
      );
    }
    return (
      <div className='loadingZone__spinnerWrapper'>
        <Loader loading={isLoading} color='gray' />
      </div>
    );
  }
  return children;
};

LoadingZone.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  skeleton: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  message: PropTypes.string,
};

LoadingZone.defaultProps = {
  children: null,
  skeleton: null,
  message: 'Loading',
};

export default LoadingZone;
