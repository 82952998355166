import React, { useEffect, useState } from 'react';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import Button from 'components/Buttons/Button';
import Modal from 'components/Layout/Modal';
import ConstituentSearch from '../ConstituentSearch';
import AddConstituentForm from '../AddConstituentForm';
import {
  eventCartItemPropType,
  fnCalculateMaxNumberOfRegistrantsForEvent,
} from '../helpers';
import { fnAddGuestToEventInCart } from '../redux/guestHelpers';

const AddGuestFromReviewStep = ({ oEventCartItem }) => {
  const [sGuestUIPhase, fnSetGuestUIPhase] = useState('');
  const [oNewGuestInitialValues, fnSetNewGuestInitialValues] = useState(null);
  const bIsWaitlistEvent = oEventCartItem.sType === 'event-waitlist';
  const [bShowDuplicateGuestModal, fnSetShowDuplicateGuestModal] =
    useState(false);
  const [sDuplicateGuestErrorMsg, fnSetDuplicateGuestErrorMsg] = useState('');
  const {
    TOTAL_CAPACITY = 0,
    TOTAL_REGISTRATIONS = 0,
    MAX_GUESTS = 0,
  } = oEventCartItem.oDetails.oEvent.REGISTRATION ?? {};
  const iMaxRegistrants = fnCalculateMaxNumberOfRegistrantsForEvent(
    bIsWaitlistEvent ? 0 : TOTAL_CAPACITY,
    TOTAL_REGISTRATIONS,
    MAX_GUESTS
  );
  const iNumGuests = oEventCartItem.oDetails.aGuests.length;

  // Auto-focus the search input when it's displayed
  useEffect(() => {
    if (sGuestUIPhase === 'search') {
      const oSearchInput = document.getElementById('constituent-search-input');
      if (oSearchInput) {
        oSearchInput.focus();
      }
    }
  }, [sGuestUIPhase]);

  if (
    (oEventCartItem.oDetails.oEvent && iNumGuests === iMaxRegistrants) ||
    (bIsWaitlistEvent && iNumGuests === MAX_GUESTS + 1)
  ) {
    return (
      <p className='t-paragraph--small'>
        You have reached the max number of guests for this event.
      </p>
    );
  }

  const fnHandleNewGuest = (sGuestName, sGuestLookupId, oNewGuestData) => {
    try {
      fnAddGuestToEventInCart(
        sGuestName,
        sGuestLookupId,
        oEventCartItem.oDetails.oEvent.LOOKUPID,
        oNewGuestData
      );
    } catch (oError) {
      console.error(oError);
      if (oError.message === 'DUPLICATE_GUEST') {
        const sEventTitle = oEventCartItem.oDetails.oEvent.TITLE;
        fnSetDuplicateGuestErrorMsg(
          `You already added ${sGuestName} as a guest for ${sEventTitle}.`
        );
        fnSetShowDuplicateGuestModal(true);
      }
    }
  };

  switch (sGuestUIPhase) {
    case 'search':
      return (
        <div>
          <ConstituentSearch
            fnSetGuestUIPhase={fnSetGuestUIPhase}
            fnSetNewGuestInitialValues={fnSetNewGuestInitialValues}
            fnHandleNewConstituent={fnHandleNewGuest}
          />
          <Button
            sFlavor='underline'
            bIsCentered
            fnHandleClick={() => fnSetGuestUIPhase('')}
            className='guestManagement__cancelButton'
          >
            Cancel
          </Button>
          {bShowDuplicateGuestModal && (
            <Modal
              fnSetShowModal={fnSetShowDuplicateGuestModal}
              sModalSubtitle={sDuplicateGuestErrorMsg}
              sModalDetail='Please select a different guest or close this modal to continue.'
            >
              <Button fnHandleClick={() => fnSetShowDuplicateGuestModal(false)}>
                Close
              </Button>
            </Modal>
          )}
        </div>
      );
    case 'create':
      return (
        <div>
          <Modal
            sModalSubtitle='Add a Guest Manually'
            fnSetShowModal={fnSetGuestUIPhase}
          >
            <AddConstituentForm
              oInitialValues={oNewGuestInitialValues}
              fnHandleSubmit={(oFormValues) => {
                const oNewConstituentData = {
                  FIRST_NAME: oFormValues.firstName,
                  LAST_NAME: oFormValues.lastName,
                  EMAIL_ADDRESS: oFormValues.email,
                };
                fnHandleNewGuest(
                  `${oFormValues.firstName} ${oFormValues.lastName}`,
                  '',
                  oNewConstituentData
                );
                fnSetGuestUIPhase('search');
              }}
              sSubmitButtonLabel='Add Guest'
            />
            <Button
              sFlavor='underline'
              bIsCentered
              fnHandleClick={() => fnSetGuestUIPhase('')}
              className='guestManagement__cancelButton'
            >
              Cancel
            </Button>
          </Modal>
          {bShowDuplicateGuestModal && (
            <Modal
              fnSetShowModal={fnSetShowDuplicateGuestModal}
              sModalSubtitle={sDuplicateGuestErrorMsg}
              sModalDetail='Please select a different guest or close this modal to continue.'
            >
              <Button fnHandleClick={() => fnSetShowDuplicateGuestModal(false)}>
                Close
              </Button>
            </Modal>
          )}
        </div>
      );
    default:
      return (
        <ButtonWithIcon
          sIcon='plus'
          fnHandleClick={() => {
            fnSetGuestUIPhase('search');
          }}
          className='reviewRegistration__addGuestButton'
        >
          Add Guest
        </ButtonWithIcon>
      );
  }
};

AddGuestFromReviewStep.propTypes = {
  oEventCartItem: eventCartItemPropType.isRequired,
};

export default AddGuestFromReviewStep;
