import React from 'react';

import Skills from './Skills';
import Employment from './Employment';
import ProfileSection from '../layout/ProfileSection';

export default () => (
  <ProfileSection sName='career' sTitle='Career' bIsEditable>
    <Employment />
    <Skills />
  </ProfileSection>
);
