import React from 'react';
import { useSelector } from 'react-redux';
import SkillsViewMode from 'components/MyProfile/elements/Career/Skills/viewMode';
import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';

/**
 * Displays list of job skills.
 */
const Skills = () => {
  const aSkills = useSelector(
    (state) => state.ProfileExternalView.JOB_SPECIALTY
  );

  return (
    aSkills.length > 0 && (
      <ProfileSubsectionLayout
        sTitle='Job Skills'
        right={<SkillsViewMode aSkills={aSkills} />}
      />
    )
  );
};

export default Skills;
