import React from 'react';
import { Outlet } from 'react-router-dom';

import usePageTitle from 'utils/hooks/usePageTitle';
import ComponentErrorBoundary from 'components/ErrorBoundaries/ComponentErrorBoundary';
import MyEvents from 'components/Events/MyEvents';
import UpcomingEvents from 'components/Events/UpcomingEvents';
import Search from '../Search';
import MyNetwork from '../MyNetwork';
import MyProfile from '../MyProfile';

/**
 * Authenticated User Home Page
 */
const Main = () => {
  usePageTitle('My Profile');

  return (
    <div className='mainContainer'>
      <Search />
      <MyNetwork />
      <UpcomingEvents />
      {/* <MyEvents /> */}
      <ComponentErrorBoundary>
        <MyProfile />
      </ComponentErrorBoundary>
      {/* This tells <BrowserRouter /> where to render nested routes - here, the public profile modal */}
      <Outlet />
    </div>
  );
};

export default Main;
