import { string, date } from 'yup';
import DateAndTime from 'date-and-time';

export const privacyFieldSchema = string()
  .oneOf(['0', '1', '2', '3'])
  .default('0')
  .required();

// Yup's date method automatically converts a two-digit year like 0005 into 1905.
// This custom test method asks the user to enter the full, four-digit year instead.
const oMinimumDate = DateAndTime.parse('1901-01-01', 'YYYY-MM-DD');
export const oMinimumDateSchema = date().test(
  'only-dates-after-1901-allowed',
  'Only dates after 1901 are allowed',
  (sValue, oContext) => {
    if (!sValue) {
      return true;
    }
    const oOriginalDate = DateAndTime.parse(
      oContext.originalValue,
      'YYYY-MM-DD'
    );
    if (oOriginalDate > oMinimumDate) {
      return true;
    }
    return oContext.createError({
      path: oContext.path,
      message: 'Please enter a 4-digit year.',
    });
  }
);
