import { oMinimumDateSchema } from 'utils/schemas/fields';
import * as yup from 'yup';

export const oInitialValues = {
  textField: '',
  textFieldDisabled: 'Hello world',
  textArea: '',
  dateField: '',
  dateRangeStart: '',
  dateRangeEnd: '',
  dateSelectMMDDMonth: '',
  dateSelectMMDDDay: '',
  START_MONTH: '',
  START_Day: '',
  END_MONTH: '',
  END_DAY: '',
  dollarAmount: 0,
  selectRegular: null,
  selectAsync: null,
  selectCreateable: null,
  selectAsyncCreateable: null,
  radio: 'selected',
  radioLarge: 'selected',
  radioButton: 'selected',
  toggleSwitchOn: true,
  toggleSwitchOff: false,
  checkboxDefault: false,
  checkboxSelected: true,
  checkboxDisabled: true,
};

export const oSchema = yup.object({
  textField: yup.string().required('Please enter some text.'),
  textFieldDisabled: yup.string(),
  textArea: yup.string().required('Please enter some text.'),
  dateField: oMinimumDateSchema.required('Choose a date'),
  dateRangeStart: oMinimumDateSchema.required('Choose a date'),
  dateRangeEnd: oMinimumDateSchema.required('Choose a date'),
  dateSelectMMDDMonth: yup.string().required('Please choose one').nullable(),
  dateSelectMMDDDay: yup.string().required('Please choose one').nullable(),
  START_MONTH: yup.string().required('Please choose one').nullable(),
  START_Day: yup.string().required('Please choose one').nullable(),
  END_MONTH: yup.string().required('Please choose one').nullable(),
  END_DAY: yup.string().required('Please choose one').nullable(),
  dollarAmount: yup
    .number()
    .min(1, 'Please enter an amount of at least $1.00')
    .required('Enter an amount'),
  selectRegular: yup.string().required('Choose a color'),
  selectAsync: yup.string().required('Choose a dalmation'),
  selectCreateable: yup.string().required('Choose a color'),
  selectAsyncCreateable: yup.string().required('Choose a puppy.'),
  radio: yup.string().required('Please make a selection.'),
  radioLarge: yup.string().required('Please make a selection.'),
  radioButton: yup.string().required('Please make a selection.'),
  toggleSwitchOn: yup.bool(),
  toggleSwitchOff: yup.bool(),
  checkboxDefault: yup.bool(),
  checkboxSelected: yup.bool(),
});
