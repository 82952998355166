import React from 'react';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import { fnBuildGoogleCalendarLink } from './helpers';

const AddToCalendar = ({ oEvent }) => {
  const sGoogleLink = fnBuildGoogleCalendarLink(oEvent);
  return (
    <a
      target='_blank'
      href={sGoogleLink}
      className='eventDetails___addToCal button button--withIcon'
      rel='noreferrer'
    >
      <span className='button--iconOnly--secondary'>
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome.addToCalendar} />
      </span>
      Add to Calendar
    </a>
  );
};

AddToCalendar.propTypes = {
  oEvent: PropTypes.shape({
    TITLE: PropTypes.string,
    START_DATE_TIME: PropTypes.string,
    END_DATE_TIME: PropTypes.string,
    DESCRIPTION: PropTypes.string,
    LOCATION: PropTypes.shape({
      NAME: PropTypes.string,
      ROOM: PropTypes.string,
      ADDRESSBLOCK: PropTypes.string,
      CITY: PropTypes.string,
      STATE: PropTypes.string,
      POSTCODE: PropTypes.string,
    }),
  }).isRequired,
};

export default AddToCalendar;
