import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActiveFormSection } from '../../../../../utils/hooks/useActiveFormSection';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import BirthdateViewMode from './viewMode';
import BirthdateEditMode from './editMode';

const Birthdate = () => {
  const sBirthdate = useSelector(
    (state) => state.Profile.BIOGRAPHICAL.BIRTHDATE
  );
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  const bShowBirthdateSection = sBirthdate !== '00000000' || bIsEditMode;
  useEffect(() => {
    fnSetIsEditMode(activeFormSection === 'personal');
  }, [activeFormSection]);

  return (
    bShowBirthdateSection && (
      <ProfileSubsectionLayout
        sTitle='Birthday'
        right={
          bIsEditMode ? (
            <BirthdateEditMode sBirthdate={sBirthdate} />
          ) : (
            <BirthdateViewMode sBirthdate={sBirthdate} />
          )
        }
      />
    )
  );
};

export default Birthdate;
