import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Shows failed identity verification error message.
 */
const ValidationFail = ({ sVerificationResult }) => {
  let sErrorMessage = '';
  switch (sVerificationResult) {
    case 'FAIL':
      sErrorMessage = "Oops! That doesn't match what we have on file.";
      break;
    case 'EXCEEDED':
      sErrorMessage = "Oops! You've run out of attempts.";
      break;
    default:
      break;
  }

  return (
    <div className='validationFail'>
      <p className='validationFail__message'>{sErrorMessage}</p>
      {sVerificationResult === 'EXCEEDED' && (
        <Link to='/sign-up/help/noverify' className='button--secondary'>
          Contact Us
        </Link>
      )}
    </div>
  );
};

ValidationFail.propTypes = {
  sVerificationResult: PropTypes.string.isRequired,
};

export default ValidationFail;
