import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useProfileData from 'utils/hooks/useProfileData';
import ClaimedClassmatesContext from './ClaimedClassmatesContext';
import Accordion from '../../../Layout/Accordion';
import LoadingZone from '../../../LoadingZone';
import ClaimedClassmatesIcon from '../../images/svg/ClaimedClassmatesIcon.svg';
import ClaimedClassmatesOptionMenu from './ClaimedClassmatesOptionMenu';
import * as volunteerActions from '../../redux/ClaimedClassmates/actions';
import ClaimedClassmatesList from './ClaimedClassmatesList';
import useIsMountedRef from '../../../../utils/hooks/useIsMountedRef';
import ClassmatesSkeleton from '../ClassmatesSkeleton';

/*
 * @description - generates the claimed classmates view
 *
 * @param {index, key, parent, style, classYear, isLoading }
 *
 * @author Tim Holder <holdertm@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const ClaimedClassmates = ({ classYear }) => {
  const [isLoading, setIsLoading] = useState(true);
  const claimedClassmates = useSelector(
    (state) => (state.Volunteers || {}).claimedClassmates || []
  );
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const bIsProfileDataLoading = useProfileData();

  // Initial fetch of claimed classmate data
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve, reject) => {
        dispatch(volunteerActions.get({ resolve, reject }));
      });
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    };
    if (!bIsProfileDataLoading) {
      fetchData();
    }
  }, [dispatch, isMountedRef, bIsProfileDataLoading]);

  // Hide loading spinner once we've got the data
  useEffect(() => {
    if (claimedClassmates.length > 0) {
      setIsLoading(false);
    }
  }, [claimedClassmates]);

  return (
    <Accordion
      mTitle='Claimed Classmates'
      sTitleIconType='wfu'
      sTitleIcon='claimedClassmate'
      sGtmCategory='Claimed Classmates'
    >
      <LoadingZone
        isLoading={isLoading}
        message='Loading my claimed classmates'
        skeleton={<ClassmatesSkeleton />}
      >
        <div className='myClaimedClassmates__content'>
          {claimedClassmates.length > 0 && <ClaimedClassmatesOptionMenu />}
          <ClaimedClassmatesContext.Provider
            value={{
              classYear,
              setIsLoading,
            }}
          >
            <ClaimedClassmatesList />
          </ClaimedClassmatesContext.Provider>
        </div>
      </LoadingZone>
    </Accordion>
  );
};

ClaimedClassmates.propTypes = {
  classYear: PropTypes.string,
};

ClaimedClassmates.defaultProps = {
  classYear: null,
};

export default ClaimedClassmates;
