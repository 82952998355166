import { useState, useEffect, useCallback } from 'react';

export type WindowSizeType = {
  width: number | null;
  height: number | null;
  sScreenSize: string;
};

// Source - https://usehooks.com/useWindowSize/
/**
 * Calculates the window's current size.
 *
 * @returns {object}
 */
const useWindowSize = () => {
  const isClient: boolean = typeof window === 'object';
  const getSize = useCallback(() => {
    // Matches the CSS breakpoint names in _mixins.scss
    let sScreenSize: string = '';
    switch (true) {
      case window.innerWidth < 640:
        sScreenSize = 'small';
        break;
      case window.innerWidth >= 640 && window.innerWidth < 1024:
        sScreenSize = 'medium';
        break;
      case window.innerWidth >= 1024 && window.innerWidth < 1366:
        sScreenSize = 'large';
        break;
      case window.innerWidth >= 1366:
        sScreenSize = 'extraLarge';
        break;
      default:
        sScreenSize = 'small';
    }
    return {
      width: isClient ? window.innerWidth : null,
      height: isClient ? window.innerHeight : null,
      sScreenSize,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, getSize]);

  return windowSize;
};

export default useWindowSize;
