import React from 'react';

import { SkeletonText } from '../../LoadingZone/Skeleton';

const ScholarshipsSkeleton = () => (
  <div className='scholarshipsSkeleton'>
    <SkeletonText size='medium' width='80%' />
    <SkeletonText size='small' width='30%' />
    <SkeletonText size='small' width='30%' />
    <SkeletonText size='small' width='20%' />
    <SkeletonText size='medium' width='80%' />
    <SkeletonText size='large' width='60%' />
    <SkeletonText size='large' width='60%' />
    <SkeletonText size='large' width='60%' />
  </div>
);

export default ScholarshipsSkeleton;
