import { handleActions } from 'redux-actions';
import * as actions from './actions';

const initialState = {
  SHOW_EMAIL_ACTIVATION_MODAL: true,
};

const options = { prefix: 'Meta' };

const reducer = handleActions(
  {
    [actions.dismissEmailActivationModal]: {
      next(state, { payload }) {
        // Handle a valid object
        return { ...payload, SHOW_EMAIL_ACTIVATION_MODAL: false };
      },
      throw(state) {
        // Handle errors
        return state;
      },
    },
  },
  initialState,
  options
);

export default reducer;
