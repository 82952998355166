import React from 'react';
import { Link } from 'react-router-dom';

import Deacon404 from './deacon404.png';

export default function NotFound() {
  const sUrl = window.location.href;
  return (
    <div className='pageErrorBoundary'>
      <img
        src={Deacon404}
        className='pageErrorBoundary__image'
        alt='Something is not working'
      />
      <h1 className='pageErrorBoundary__title'>Page Not Found</h1>
      <p className='pageErrorBoundary__details '>
        The Deacon must have misplaced something. The page you were looking for
        cannot be found.
      </p>
      <p>
        Return to the{' '}
        <Link to='/main' className=''>
          home page
        </Link>
        , or let us know about the problem at{' '}
        <a
          href={`mailto:wakenetwork.help@wfu.edu?subject=Broken link: ${sUrl}`}
        >
          wakenetwork.help@wfu.edu
        </a>
      </p>
    </div>
  );
}
