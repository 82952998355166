import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import { yupSchemaPropType } from 'utils/customPropTypes';
import { isFunction } from '../../../utils/common';
import useActions from '../../../utils/hooks/useActions';
import RecordPrivacyIcon from '../../MyProfile/elements/RecordPrivacyIcon';
import ConditionalForm from './ConditionalForm';
import { DeleteButton } from '../Buttons/DeleteButton';
import OptionsModalContactForm from './OptionsModalContactForm';
import OptionsModalDefaultFields from './OptionsModalDefaultFields';
import { SubmitButton } from '../Buttons';

export const OptionsModal = ({
  data,
  privacyLabels,
  children,
  render,
  forceExpanded,
  validationSchema,
  validateAt,
  actions,
  submitButtonHidden,
  formikProps,
  name,
  textWhenPrimary,
  closeOnOutsideClick,
}) => {
  const [optionsModalVisible, setOptionsModalVisible] = useState(forceExpanded);
  const [bIsSaving, fnSetIsSaving] = useState(false);
  const [bIsDeleting, fnSetIsDeleting] = useState(false);
  const isMounted = useIsMountedRef();
  const parentActions = useActions(actions);
  const node = useRef();

  useEffect(() => {
    if (forceExpanded !== undefined) {
      setOptionsModalVisible(forceExpanded);
    }
  }, [forceExpanded]);

  useCloseOnOutsideClick(node, setOptionsModalVisible, closeOnOutsideClick);

  const handleSubmit = async (formData, formActions) => {
    if (!bIsSaving) {
      fnSetIsSaving(true);
      try {
        const result = await new Promise((resolve, reject) => {
          parentActions.update({
            data: formData,
            validateAt,
            resolve,
            reject,
          });
        });
        if (isMounted.current) {
          formActions.setSubmitting(false);
          fnSetIsSaving(false);
          if (result === 'Success') {
            setOptionsModalVisible(false);
          }
        }
      } catch (error) {
        console.error(error);
        fnSetIsSaving(false);
      }
    }
  };

  const handleDelete = async () => {
    fnSetIsDeleting(true);
    try {
      await new Promise((resolve, reject) => {
        parentActions.delete({ data, resolve, reject });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const aPrivateRecordTypes = ['child', 'group'];

  const recordIsPrivate =
    aPrivateRecordTypes.includes(name) ||
    data.PRIVACY === '1' ||
    data.PRIVACY === '2';

  const bShowDeleteButton = !!(data || {}).ID && isFunction(actions.delete);
  const bShowSubmitButton = !(
    submitButtonHidden ||
    (!(data || {}).ID && !(data || {}).LOOKUPID) ||
    !!formikProps
  );
  const bIsForm = !formikProps;

  const renderChildren = () => {
    if (isFunction(children)) {
      return children({ setOptionsModalVisible });
    }
    return children;
  };

  return (
    <div className='optionsModal__wrapper' ref={node}>
      {recordIsPrivate && (
        <RecordPrivacyIcon ariaId={`private-eye-${data.ID}`} />
      )}
      <IconOnlyButton
        fnHandleClick={() => {
          setOptionsModalVisible(!optionsModalVisible);
        }}
        sIcon='ellipsis'
        sAriaLabel='Toggle options'
        sFlavor='secondary'
      />
      {optionsModalVisible && (
        <div className='optionsModal__reveal'>
          <ConditionalForm
            isForm={bIsForm}
            initialValues={data}
            handleSubmit={handleSubmit}
            renderChildren={render}
            validationSchema={validationSchema}
          >
            {children ? (
              renderChildren()
            ) : (
              <OptionsModalDefaultFields
                data={data}
                privacyLabels={privacyLabels}
                name={name}
                textWhenPrimary={textWhenPrimary}
              />
            )}

            {(bShowSubmitButton || bShowDeleteButton) && (
              <div className='optionsModal__section'>
                {bShowSubmitButton && (
                  <SubmitButton bIsDisabled={bIsDeleting} />
                )}
                {bShowDeleteButton && (
                  <DeleteButton
                    fnHandleClick={handleDelete}
                    bIsLoading={bIsDeleting}
                    bIsDisabled={bIsSaving}
                  />
                )}
              </div>
            )}
          </ConditionalForm>

          {bIsForm && (
            <div className='optionsModal__section'>
              <OptionsModalContactForm
                sRecordType={name}
                oRecordData={data}
                handleSubmit={parentActions.addNote}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

OptionsModal.defaultProps = {
  data: null,
  privacyLabels: ['Public', null, 'Private'],
  children: null,
  render: null,
  actions: {},
  validationSchema: undefined,
  submitButtonHidden: false,
  formikProps: null,
  validateAt: null,
  name: null,
  textWhenPrimary: null,
  closeOnOutsideClick: true,
  forceExpanded: false,
};

OptionsModal.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  privacyLabels: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  render: PropTypes.func,
  actions: PropTypes.shape({
    addNote: PropTypes.func,
    delete: PropTypes.func,
  }),
  validationSchema: yupSchemaPropType,
  submitButtonHidden: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formikProps: PropTypes.object,
  validateAt: PropTypes.string,
  name: PropTypes.string,
  textWhenPrimary: PropTypes.string,
  closeOnOutsideClick: PropTypes.bool,
  forceExpanded: PropTypes.bool,
};
export default OptionsModal;
