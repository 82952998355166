import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import buildSelectPaymentSchema from './schema';
import SelectPaymentFormBody from './SelectPaymentFormBody';

/**
 * Responsible for building the payment selection form.
 *
 * @author Elizabeth Hicks <hicksem@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const SelectPaymentForm = ({ submitFormikFormRef, submitHandler }) => {
  const oSavedFormValues = useSelector(
    (state) => state.PledgePayment.selectPaymentFormValues
  );
  const aPayablePledges = useSelector(
    (state) => state.PledgePayment.payablePledges
  );
  const oDefaultValues = {
    paymentType: '',
    otherAmount: '',
    note: '',
    amount: 0,
  };
  const oInitialValues = oSavedFormValues || oDefaultValues;
  const oSchema = buildSelectPaymentSchema(aPayablePledges);

  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={oInitialValues}
      validateOnBlur
      validationSchema={oSchema}
    >
      {(formikProps) => {
        submitFormikFormRef.current = formikProps.submitForm;
        return (
          <Form className='selectPaymentForm'>
            <SelectPaymentFormBody />
          </Form>
        );
      }}
    </Formik>
  );
};

SelectPaymentForm.propTypes = {
  submitFormikFormRef: PropTypes.shape({ current: PropTypes.func }).isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default SelectPaymentForm;
