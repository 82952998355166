import { put, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';

import oWnApi from 'utils/WnApi';
import * as codeTablesActions from './actions';

const fnSortByDescription = R.sortBy(R.prop('DESCRIPTION'));

function* getCodeTables(oAction) {
  const sCodeTablesType = oAction.payload;
  const sUrl = sCodeTablesType
    ? `code_tables?type=${sCodeTablesType}`
    : 'code_tables';
  // Unauthenticated event registrants can access dietary restrictions
  const bIsAuthenticatedRequest = sCodeTablesType !== 'DIETARY_RESTRICTION';
  const oResponse = yield oWnApi.get(sUrl, bIsAuthenticatedRequest);
  const aSortedItems = fnSortByDescription(oResponse.data.Items);
  yield put(codeTablesActions.update({ [sCodeTablesType]: aSortedItems }));
}

export default function* watchCodeTables() {
  yield takeEvery('CodeTables/GET', getCodeTables);
}
