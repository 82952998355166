import debounce from 'lodash.debounce';

import oWnApi from 'utils/WnApi';

let oPreviousAbortController = null;

const fnCallCloudSearch = async (sQuery, fnHandleResults) => {
  const oAbortController = new AbortController();
  if (oPreviousAbortController) {
    oPreviousAbortController.abort();
    console.log('Request cancelled: User performed a new quick search');
  }
  oPreviousAbortController = oAbortController;

  const sEncodedQuery = encodeURIComponent(sQuery);
  const oResults = await oWnApi.get(
    `quick_search?q=${sEncodedQuery}`,
    true,
    oAbortController.signal
  );

  fnHandleResults(oResults);
};

const fnDebouncedCallCloudSearch = debounce((sQuery, fnHandleResults) => {
  fnCallCloudSearch(sQuery, fnHandleResults);
}, 500);

export const fnResetAbortController = () => {
  oPreviousAbortController = null;
};

export default fnDebouncedCallCloudSearch;
