import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useCurrentEventCartItem = () => {
  const { eventSlug } = useParams();
  const oEventCartItem = useSelector((oState) =>
    oState.Checkout.aCart.find(
      (oItem) => oItem.oDetails.oEvent.SLUG === eventSlug
    )
  );
  return oEventCartItem;
};

export default useCurrentEventCartItem;
