import React from 'react';

import usePageTitle from 'utils/hooks/usePageTitle';
import PageLayout from '../Layout/PageLayout';
import Buttons from './Buttons';
import ColorList from './ColorList';
import Typography from './Typography';
import IconList from './IconList';
import Elevations from './Elevations';

/**
 * Displays colors, typography, icons, buttons, and common components used in Wake Network.
 */
const StyleGuide = () => {
  usePageTitle('Style Guide');
  return (
    <PageLayout sTitle='Style Guide'>
      <nav className='styleGuide__nav'>
        <a href='#colors' className='styleGuide__navLink'>
          Colors
        </a>
        <a href='#typography' className='styleGuide__navLink'>
          Typography
        </a>
        <a href='#icons' className='styleGuide__navLink'>
          Icons
        </a>
        <a href='#buttons' className='styleGuide__navLink'>
          Buttons
        </a>
        <a href='#elevations' className='styleGuide__navLink'>
          Elevations
        </a>
      </nav>

      <h2 id='colors'>Colors</h2>
      <h3>Base</h3>
      <ColorList sCategory='base' />
      <h3>Interactions</h3>
      <ColorList sCategory='interactions' />
      <h3>Neutrals</h3>
      <ColorList sCategory='neutrals' />

      <h2 id='typography'>Typography</h2>
      <Typography />

      <h2 id='icons'>Icons</h2>
      <h3>Custom</h3>
      <IconList sType='wfu' />
      <h3>Font Awesome</h3>
      <IconList sType='fontAwesome' />

      <h2 id='buttons'>Buttons</h2>
      <Buttons />

      <h2 id='elevations'>Elevations</h2>
      <Elevations />
    </PageLayout>
  );
};

export default StyleGuide;
