import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import { aAllIconNames, aIconTypes } from 'utils/iconLibrary';
import { fnChildrenPropType } from 'utils/customPropTypes';
import { triggerGoogleTagManagerEvent } from 'utils/common';
import AccordionTrigger from './AccordionTrigger';
import aSizes from './sizes';

/**
 * Displays an accordion.
 */
const Accordion = ({
  className,
  children,
  sSize,
  mTitle,
  sTitleIcon,
  sTitleIconType,
  bStartOpen,
  sGtmCategory,
  bLazyRender,
  sCypressId,
}) => {
  const [bIsOpen, fnSetIsOpen] = useState(bStartOpen);

  return (
    <Collapsible
      className={`accordion accordion--${sSize} ${className}`}
      openedClassName={`accordion accordion--${sSize} ${className}`}
      triggerTagName='button'
      triggerClassName={`accordion__header--${sSize}`}
      trigger={
        <AccordionTrigger
          sSize={sSize}
          sTitleIcon={sTitleIcon}
          sTitleIconType={sTitleIconType}
        >
          {mTitle}
        </AccordionTrigger>
      }
      triggerOpenedClassName={`accordion__header--${sSize}`}
      triggerWhenOpen={
        <AccordionTrigger
          sSize={sSize}
          sTitleIcon={sTitleIcon}
          sTitleIconType={sTitleIconType}
          bIsOpen
        >
          {mTitle}
        </AccordionTrigger>
      }
      triggerElementProps={{
        'data-cy': sCypressId,
      }}
      contentOuterClassName={`accordion__panel--${sSize}`}
      contentInnerClassName={`accordion__panelInner--${sSize}`}
      open={bStartOpen}
      onOpening={() => {
        fnSetIsOpen(true);
      }}
      onOpen={() => {
        if (sGtmCategory) {
          triggerGoogleTagManagerEvent(sGtmCategory, 'accordion opened');
        }
      }}
      onClose={() => {
        fnSetIsOpen(false);
        if (sGtmCategory) {
          triggerGoogleTagManagerEvent(sGtmCategory, 'accordion closed');
        }
      }}
      overflowWhenOpen='visible'
      lazyRender={bLazyRender}
    >
      {/* This prevents users from tabbing into the accordion content 
      when the accordion is closed. Collapsible.contentHiddenWhenClosed 
      should take care of this, but it broke in v2.8.2.
      See https://github.com/glennflanagan/react-collapsible/issues/194 */}
      <div style={{ visibility: bIsOpen ? 'visible' : 'hidden' }}>
        {children}
      </div>
    </Collapsible>
  );
};

Accordion.propTypes = {
  mTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  children: fnChildrenPropType.isRequired,
  sSize: PropTypes.oneOf(aSizes),
  sTitleIcon: (props, propName) => {
    let oError = null;
    // Only large expandable sections need a title icon.
    if (props.sSize === 'large') {
      if (!props[propName]) {
        oError = new Error(
          `${propName} is required for a large Expandable Section`
        );
      }
      if (!aAllIconNames.includes(props[propName])) {
        oError = new Error(
          `${propName}: ${props[propName]} not found in our icon library`
        );
      }
    }
    return oError;
  },
  sTitleIconType: (props, propName) => {
    let oError = null;
    // Only large expandable sections need a title icon type.
    if (props.sSize === 'large' && !aIconTypes.includes(props[propName])) {
      oError = new Error(
        `${propName}: ${props[propName]} is not a valid icon type in our icon library`
      );
    }
    return oError;
  },
  bStartOpen: PropTypes.bool,
  sGtmCategory: PropTypes.string,
  bLazyRender: PropTypes.bool,
  sCypressId: PropTypes.string,
};

Accordion.defaultProps = {
  className: '',
  sSize: 'large',
  sTitleIcon: '',
  sTitleIconType: 'fontAwesome',
  bStartOpen: false,
  sGtmCategory: '',
  bLazyRender: false,
  sCypressId: '',
};

export default Accordion;
