import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { yupSchemaPropType } from 'utils/customPropTypes';
import { Select } from '../Fields';
import useCloseOnOutsideClick from '../../../utils/hooks/useCloseOnOutsideClick';
import { SubmitButton } from '../Buttons';

const QuickFindModalForm = ({
  onReset,
  onSubmit,
  initialValues,
  formikFieldToUpdate,
  options,
  className,
  schema,
  searchKey,
  setIsFormVisible,
  placeholder,
  sLabel,
}) => {
  const modalRef = useRef();
  const selectFieldRef = useRef(null);

  useCloseOnOutsideClick(modalRef, setIsFormVisible);

  // Focus field when form opens
  useEffect(() => {
    if (selectFieldRef.current) {
      selectFieldRef.current.focus();
    }
  }, [selectFieldRef]);

  return (
    <div className={`quickFindModalForm__wrapper ${className}`} ref={modalRef}>
      <Formik
        onReset={onReset}
        onSubmit={onSubmit}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({ handleSubmit }) => (
          <Form
            autoComplete='off'
            onSubmit={handleSubmit}
            className='quickFindModalForm'
          >
            <div className='quickFindModalForm__selectWrapper'>
              <div className='quickFindModalForm__select'>
                <Select
                  name={formikFieldToUpdate}
                  label={sLabel}
                  options={options}
                  getOptionLabel={(option) => option[searchKey]}
                  placeholder={placeholder}
                  fieldRef={selectFieldRef}
                />
              </div>
              <SubmitButton>Add</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

QuickFindModalForm.propTypes = {
  onReset: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  formikFieldToUpdate: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  schema: yupSchemaPropType,
  searchKey: PropTypes.string,
  setIsFormVisible: PropTypes.func,
  placeholder: PropTypes.string,
  sLabel: PropTypes.string.isRequired,
};

QuickFindModalForm.defaultProps = {
  onReset: () => {},
  className: '',
  schema: null,
  searchKey: 'DESCRIPTION',
  setIsFormVisible: () => {},
  placeholder: '',
};

export default QuickFindModalForm;
