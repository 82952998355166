import React from 'react';
import PropTypes from 'prop-types';

import { SelectPrivacy } from '../../../../Form/OptionsModal/DefaultFields/SelectPrivacy';
import { OptionsModal } from '../../../../Form/OptionsModal';

import actions from './redux/actions';
import NewInvolvementForm from './NewInvolvementForm';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

/**
 * Allows user to edit their involvements.
 */
const InvolvementsEditMode = ({ aInvolvements }) => (
  <>
    {aInvolvements.map((oInvolvement) => (
      <ProfileRecordLayout
        key={oInvolvement.ID}
        left={
          <div className='record t-paragraph--medium'>{oInvolvement.NAME}</div>
        }
        right={
          <OptionsModal name='Education' data={oInvolvement} actions={actions}>
            <SelectPrivacy data={oInvolvement} />
          </OptionsModal>
        }
      />
    ))}
    <NewInvolvementForm />
  </>
);

InvolvementsEditMode.propTypes = {
  aInvolvements: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      NAME: PropTypes.string,
    })
  ),
};

InvolvementsEditMode.defaultProps = {
  aInvolvements: [],
};

export default InvolvementsEditMode;
