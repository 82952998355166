import React from 'react';
import PropTypes from 'prop-types';
import SVGSolidHeartIcon from '../../../images/Icons/SVGSolidHeartIcon';
import SVGShinyHeartIcon from '../../../images/Icons/SVGShinyHeartIcon';

const LoyalDeacHeart = ({ letter, isFilled }) => (
  <div className='loyalDeacHeart'>
    <div
      className={`loyalDeacHeart__letter ${
        isFilled ? 'loyalDeacHeart__letter--filled' : ''
      }`}
    >
      {letter}
    </div>
    {isFilled ? <SVGShinyHeartIcon /> : <SVGSolidHeartIcon />}
  </div>
);

LoyalDeacHeart.propTypes = {
  letter: PropTypes.string,
  isFilled: PropTypes.bool,
};

LoyalDeacHeart.defaultProps = {
  letter: '',
  isFilled: false,
};

export default LoyalDeacHeart;
