import React from 'react';
import PropTypes from 'prop-types';

import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { OptionsModal } from '../../../../Form/OptionsModal';
import { Select } from '../../../../Form/Fields';
import { SelectPrivacy } from '../../../../Form/OptionsModal/DefaultFields/SelectPrivacy';
import { nameOptionsSchema, nameOptionsSchemaWithPrivacy } from './schemas';

export const NameOptions = ({
  data,
  actions,
  forceExpanded,
  bIsPrivacyChangeable,
  formikProps,
  name,
}) => {
  const titleOptions = useCodeTablesSection('TITLE');
  const professionalSuffixOptions = useCodeTablesSection('PROFESSIONALSUFFIX');
  const personalSuffixOptions = useCodeTablesSection('PERSONALSUFFIX');

  return (
    <OptionsModal
      actions={actions}
      submitButtonHidden={!data.LOOKUPID}
      data={data}
      forceExpanded={forceExpanded}
      formikProps={formikProps}
      validationSchema={
        name === 'spouse' ? nameOptionsSchemaWithPrivacy : nameOptionsSchema
      }
      name={name}
    >
      <div className='optionsModal__section'>
        <Select
          className='settingsDropdown'
          name='TITLE'
          label='Title'
          options={titleOptions}
        />
        <Select
          className='settingsDropdown'
          name='PROFESSIONALSUFFIX'
          label='Professional Suffix'
          options={professionalSuffixOptions}
        />
        <Select
          className='settingsDropdown'
          name='PERSONALSUFFIX'
          label='Familial Suffix'
          options={personalSuffixOptions}
        />
      </div>
      <SelectPrivacy
        data={data}
        name={name}
        isPrivacyChangeable={bIsPrivacyChangeable}
      />
    </OptionsModal>
  );
};

NameOptions.defaultProps = {
  forceExpanded: false,
  bIsPrivacyChangeable: true,
  formikProps: null,
  actions: {},
  name: '',
};

NameOptions.propTypes = {
  data: PropTypes.shape({
    LOOKUPID: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object,
  forceExpanded: PropTypes.bool,
  bIsPrivacyChangeable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formikProps: PropTypes.object,
  name: PropTypes.string,
};

export default NameOptions;
