import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FieldErrorMessage = ({ sFieldName, sClassName }) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField(sFieldName);
  return meta.touched && meta.error ? (
    <p className={`field__errorMessage ${sClassName}`}>{meta.error}</p>
  ) : null;
};

FieldErrorMessage.propTypes = {
  sFieldName: PropTypes.string.isRequired,
  sClassName: PropTypes.string,
};

FieldErrorMessage.defaultProps = {
  sClassName: '',
};

export default FieldErrorMessage;
