import React from 'react';
import PropTypes from 'prop-types';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';

const SortButton = ({
  label,
  handleClick,
  sortKey,
  activeSortKey,
  activeSortOrder,
}) => {
  const isActive = sortKey === activeSortKey;
  let sIconName = 'sort';
  if (isActive) {
    if (activeSortOrder) {
      sIconName = 'sortUp';
    } else {
      sIconName = 'sortDown';
    }
  }

  return (
    <ButtonWithIcon
      sIcon={sIconName}
      fnHandleClick={() => handleClick(sortKey)}
    >
      {label}
    </ButtonWithIcon>
  );
};

SortButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  activeSortKey: PropTypes.string.isRequired,
  activeSortOrder: PropTypes.bool.isRequired,
};

export default SortButton;
