import React from 'react';
import { useSelector } from 'react-redux';

import ProfileSection from 'components/MyProfile/elements/layout/ProfileSection';
import DegreesViewMode from 'components/MyProfile/elements/Education/Degrees/viewMode';
import Involvements from './Involvements';

const Education = () => {
  const aDegrees = useSelector((state) => state.ProfileExternalView.DEGREE);
  const aInvolvements = useSelector(
    (state) => state.ProfileExternalView.INVOLVEMENT
  );

  const bHasPublicEducationData =
    (aDegrees.length > 0 &&
      aDegrees.some(
        (oDegree) => oDegree.PRIVACY === '0' || oDegree.PRIVACY === undefined
      )) ||
    (aInvolvements.length > 0 &&
      aInvolvements.some(
        (oInvolvement) =>
          oInvolvement.PRIVACY === '0' || oInvolvement.PRIVACY === undefined
      ));

  return (
    bHasPublicEducationData && (
      <ProfileSection sTitle='Education' sName='education'>
        {aDegrees.length > 0 && <DegreesViewMode aDegrees={aDegrees} />}
        {aInvolvements.length > 0 && (
          <Involvements aInvolvements={aInvolvements} />
        )}
      </ProfileSection>
    )
  );
};

export default Education;
