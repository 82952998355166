import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { fnElementRefPropType } from 'utils/customPropTypes';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import DateRangeMMDD from 'components/Form/Fields/DateRangeMMDD';
import NewRecordForm from 'components/Form/NewRecordForm';
import { TextField, Select } from 'components/Form/Fields';
import { OptionsModal } from 'components/Form';
import { fnGetRegionOptionsByCountry } from 'components/Search/elements/AdvancedSearch/helpers';
import { profileExternalViewAddressActions } from 'components/ProfileExternalView/redux/actions';
import useIsUserViewingSpouseProfile from 'utils/hooks/useIsUserViewingSpouseProfile';
import { addAddressSchema } from './schemas';
import profileActions from '../redux/actions';
import {
  SelectPrimary,
  ChooseType,
  SelectPrivacy,
} from '../../../../../Form/OptionsModal/DefaultFields';
import ProfileRecordLayout from '../../../layout/ProfileRecordLayout';

const NEW_ADDRESS_FORM_ID = 'new-address-form';

const FormBody = ({ firstFieldRef, iNumberOfAddresses, ...formikProps }) => {
  const [oCountry, fnSetCountry] = useState({ DESCRIPTION: 'United States' });
  const aAllRegionOptions = useCodeTablesSection('REGION', (aCodes) => {
    const aFilteredStateOptions = fnGetRegionOptionsByCountry(aCodes, oCountry);
    return aFilteredStateOptions;
  });
  const bIsCountryUs = oCountry?.DESCRIPTION === 'United States';
  const bCountryHasRegionsInDad = aAllRegionOptions.length > 0;

  const aCountryCodes = useCodeTablesSection('COUNTRY', (aCodes) => {
    if (aCodes.length > 0) {
      const oUsCountryCode = aCodes.find(
        (oCountryUs) => oCountryUs.DETAILS.ABBRV === 'US'
      );
      const aForeignCountryCodes = aCodes.filter(
        (oCode) => oCode.DETAILS.ABBRV !== 'US'
      );
      return [oUsCountryCode, ...aForeignCountryCodes];
    }
    return aCodes;
  });
  const isSeasonalResidence =
    formikProps.getFieldProps('TYPE').value === 'Seasonal Residence';
  return (
    <ProfileRecordLayout
      left={
        <>
          <Select
            label='Country'
            name='ADDRESSCOUNTRY'
            options={aCountryCodes}
            getOptionLabel={(option) => option.DETAILS?.NAME_ABBRV}
            getOptionValue={(option) => option.DETAILS?.NAME_ABBRV}
            onChange={fnSetCountry}
          />
          <TextField
            sId='street'
            sLabel={bIsCountryUs ? 'Street Address' : 'Mailing Address'}
            sName='ADDRESSBLOCK'
            oFieldRef={firstFieldRef}
          />
          <TextField sId='city' sLabel='City' sName='ADDRESSCITY' />
          {bCountryHasRegionsInDad && (
            <Select
              label={bIsCountryUs ? 'State' : 'Region'}
              options={aAllRegionOptions}
              name='ADDRESSSTATE'
              getOptionLabel={(option) => option.DETAILS?.NAME_ABBRV}
              getOptionValue={(option) => option.DETAILS?.NAME_ABBRV}
            />
          )}
          <TextField
            sId='zipcode'
            sLabel={bIsCountryUs ? 'ZIP Code' : 'Postal Code'}
            sName='ADDRESSZIP'
          />
          {isSeasonalResidence && <DateRangeMMDD />}
        </>
      }
      right={
        <OptionsModal
          data={formikProps.values}
          name='Address'
          forceExpanded
          formikProps={formikProps}
        >
          {/* The first address a user adds must be primary - DAD rule */}
          {iNumberOfAddresses > 0 && <SelectPrimary />}
          <ChooseType name='Address' codeTablesKey='ADDRESS' />
          <SelectPrivacy
            data={formikProps.values}
            privacyLabels={[
              'Public',
              'Show only city, state, and country',
              'Private',
            ]}
          />
        </OptionsModal>
      }
    />
  );
};

FormBody.propTypes = {
  firstFieldRef: fnElementRefPropType.isRequired,
  iNumberOfAddresses: PropTypes.number.isRequired,
};

export const NewAddressForm = ({ initialValues, handleSubmitSideEffects }) => {
  const aAddresses = useSelector((state) => state.Profile?.ADDRESS || []);
  const bIsUserViewingSpouseProfile = useIsUserViewingSpouseProfile();
  return (
    <NewRecordForm
      initialValues={initialValues}
      addItemLabel='Add Address'
      formId={NEW_ADDRESS_FORM_ID}
      validationSchema={addAddressSchema}
      validateOnBlur={false}
      validateOnChange={false}
      actions={
        bIsUserViewingSpouseProfile
          ? profileExternalViewAddressActions
          : profileActions
      }
      FormBody={FormBody}
      formBodyProps={{ iNumberOfAddresses: aAddresses.length }}
      codeTablesKey='ADDRESS'
      handleSubmitSideEffects={handleSubmitSideEffects}
    />
  );
};

NewAddressForm.defaultProps = {
  initialValues: {
    TYPE: 'Primary Residence',
    PRIVACY: '0',
    ADDRESSZIP: '',
    ADDRESSBLOCK: '',
    ADDRESSCITY: '',
    PRIMARY: true,
    START_MONTH: '',
    START_DAY: '',
    END_MONTH: '',
    END_DAY: '',
    ADDRESSCOUNTRY: {
      ID: '70FA9361-547E-4C98-A3CF-F655D36F8ECF',
      DETAILS: {
        NAME_ABBRV: 'US - United States',
        ABBRV: 'US',
      },
      DESCRIPTION: 'United States',
    },
    REGION: '',
  },
  handleSubmitSideEffects: () => {},
};

NewAddressForm.propTypes = {
  initialValues: PropTypes.shape({
    TYPE: PropTypes.string,
    PRIVACY: PropTypes.string,
    ADDRESSZIP: PropTypes.string,
    ADDRESSBLOCK: PropTypes.string,
    ADDRESSCITY: PropTypes.string,
    ADDRESSCOUNTRY: PropTypes.shape({
      ABBRV: PropTypes.string,
      NAME_ABBRV: PropTypes.string,
    }),
    PRIMARY: PropTypes.bool,
    START_MONTH: PropTypes.string,
    START_DAY: PropTypes.string,
    END_MONTH: PropTypes.string,
    END_DAY: PropTypes.string,
  }),
  handleSubmitSideEffects: PropTypes.func,
};

export default NewAddressForm;
