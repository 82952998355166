import React from 'react';

import BaseErrorBoundary from './BaseErrorBoundary';

/**
 * Error boundary that displays a section-sized error message
 * if any child component throws an error.
 *
 * See https://reactjs.org/docs/error-boundaries.html for details.
 *
 * @example
 * <ComponentErrorBoundary>
 *     <RiskyComponent /> // Might throw an error, but it shouldn't break the whole app
 * </ComponentErrorBoundary>
 */
class ComponentErrorBoundary extends BaseErrorBoundary {
  render() {
    const { bHasError } = this.state;
    let { sErrorMessage } = this.props;
    sErrorMessage =
      sErrorMessage || 'Oops! Something went wrong with this section.';

    if (bHasError) {
      return (
        <div className='componentErrorBoundary'>
          <p className='t-paragraph--medium'>{sErrorMessage}</p>
        </div>
      );
    }

    const { children } = this.props;

    return children;
  }
}

export default ComponentErrorBoundary;
