import React from 'react';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { OptionsModal } from '../../../../Form/OptionsModal';
import profileActions from './redux/actions';
import { NewEmailForm } from './NewEmailForm';
import { editEmailSchema } from './schemas';
import EmailRecord from './EmailRecord';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const fnGetActionsForEmail = (sEmail = '', oActionsProp = null) => {
  let oTheActions = oActionsProp || profileActions;
  // Don't allow users to delete their alumni Gmail address.
  if (sEmail.includes('@alumni.wfu.edu')) {
    oTheActions = R.omit(['delete'], oTheActions);
  }
  return oTheActions;
};

/**
 * Displays profile email section in edit mode.
 */
const EmailEditMode = ({ aEmails, bIsExpanded, oActions }) => {
  const [oFirstEmail, ...aOtherEmails] = aEmails;

  return (
    <>
      {oFirstEmail && (
        <ProfileRecordLayout
          left={<EmailRecord oEmail={oFirstEmail} bIsViewMode={false} />}
          right={
            <OptionsModal
              data={oFirstEmail}
              actions={fnGetActionsForEmail(
                oFirstEmail?.EMAILADDRESS,
                oActions
              )}
              name='Email'
              validationSchema={editEmailSchema}
            />
          }
        />
      )}
      {aOtherEmails.length > 0 && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          {aOtherEmails.map((oEmail) => (
            <ProfileRecordLayout
              key={oEmail.ID}
              left={<EmailRecord oEmail={oEmail} bIsViewMode={false} />}
              right={
                <OptionsModal
                  data={oEmail}
                  actions={fnGetActionsForEmail(oEmail?.EMAILADDRESS, oActions)}
                  name='Email'
                  validationSchema={editEmailSchema}
                />
              }
            />
          ))}
        </Collapsible>
      )}
      <NewEmailForm />
    </>
  );
};

EmailEditMode.defaultProps = {
  aEmails: [],
  bIsExpanded: false,
};

EmailEditMode.propTypes = {
  aEmails: PropTypes.arrayOf(
    PropTypes.shape({
      primary: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.string,
          EMAILADDRESS: PropTypes.string,
          TYPE: PropTypes.shape({
            DESCRIPTION: PropTypes.string,
            ID: PropTypes.string,
          }),
          PRIVACY: PropTypes.string,
          PRIMARY: PropTypes.bool,
        })
      ),
    })
  ),
  bIsExpanded: PropTypes.bool,
};

export default EmailEditMode;
