import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import {
  fnFormatQuickSearchLocationForAdvSearch,
  oEmptyAdvancedSearch,
} from 'components/Search/helpers';
import { triggerGoogleTagManagerEvent } from 'utils/common';
import LoadingZone from 'components/LoadingZone';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import fnDebouncedCallCloudSearch, {
  fnResetAbortController,
} from './fnDebouncedCallCloudSearch';

import QuickSearchSelectInput from './QuickSearchSelectInput';
import QuickSearchSelectResultsList from './QuickSearchResultsList';
import QuickSearchResultsListSkeleton from './QuickSearchResultsList/QuickSearchResultsListSkeleton';

/**
 * A basic auto-suggesting dropdown list component that allows the user to
 * perform fuzzy searches against a set of data
 */
export const QuickSearchSelect = ({ handleSubmitAdvancedSearch }) => {
  const [aSuggestions, fnSetSuggestions] = useState([]);
  const [sUserQuery, fnSetUserQuery] = useState('');
  const [bIsResultListVisible, fnSetIsResultListVisible] = useState(false);
  const aStateList = useCodeTablesSection('REGION');
  const aCountryList = useCodeTablesSection('COUNTRY');
  const [bIsSearching, fnSetIsSearching] = useState(false);
  const [bHasError, fnSetHasError] = useState(false);

  useEffect(() => {
    const sTrimmedQuery = sUserQuery.trim();

    const fnHandleUserQuery = () => {
      fnSetHasError(false);
      fnSetIsSearching(true);
      fnSetIsResultListVisible(true);
      fnDebouncedCallCloudSearch(sTrimmedQuery, (oResults) => {
        switch (oResults.status) {
          case 200:
            fnSetSuggestions(oResults.data.hits.hit);
            fnSetIsSearching(false);
            triggerGoogleTagManagerEvent('QuickSearch', 'searched');
            break;
          case 400:
            console.error(
              `Search for '${sTrimmedQuery}' failed. ${oResults.data}`
            );
            fnSetHasError(true);
            fnSetIsSearching(false);
            break;
          default:
            // Clear the search results if the CloudSearch API call was canceled
            fnSetSuggestions([]);
        }
      });
    };

    if (sTrimmedQuery.length >= 2) {
      // @TODO: Remove this check once all browsers support requestIdleCallback.
      // Looking at you, Safari. https://caniuse.com/requestidlecallback
      if (window.requestIdleCallback) {
        window.requestIdleCallback(fnHandleUserQuery);
      } else {
        fnHandleUserQuery();
      }
    }
    if (sTrimmedQuery.length === 0) {
      fnSetIsResultListVisible(false);
    }
  }, [sUserQuery]);

  const handleSelection = (oUserSelection) => {
    console.log('oUserSelection', oUserSelection);
    fnSetIsResultListVisible(false);
    if (oUserSelection) {
      switch (oUserSelection.TYPE) {
        case 'employer':
          handleSubmitAdvancedSearch({
            ...oEmptyAdvancedSearch,
            employer: oUserSelection,
          });
          triggerGoogleTagManagerEvent(
            'QuickSearch',
            'selected item',
            'type: Employer'
          );
          break;
        case 'location':
          handleSubmitAdvancedSearch({
            ...oEmptyAdvancedSearch,
            ...fnFormatQuickSearchLocationForAdvSearch(
              oUserSelection.NAME,
              aStateList,
              aCountryList
            ),
          });
          triggerGoogleTagManagerEvent(
            'QuickSearch',
            'selected item',
            'type: Location'
          );
          break;
        default:
          break;
      }
    }
  };

  const node = useRef(null);
  useCloseOnOutsideClick(node, fnSetIsResultListVisible, true);

  // Reset quick search when the results list closes
  useEffect(() => {
    if (!bIsResultListVisible) {
      fnSetUserQuery('');
      fnSetSuggestions([]);
    }
  }, [bIsResultListVisible]);

  // Reset the API cancel token when the component unmounts
  useEffect(
    () => () => {
      fnResetAbortController();
    },
    []
  );

  return (
    <div className='quickSearch__container' ref={node}>
      <QuickSearchSelectInput
        fnSetIsResultListVisible={fnSetIsResultListVisible}
        fnSetUserQuery={fnSetUserQuery}
        sUserQuery={sUserQuery}
      />
      {bIsResultListVisible && (
        <div className='quickSearchSelect__resultsList'>
          <LoadingZone
            isLoading={bIsSearching}
            skeleton={<QuickSearchResultsListSkeleton />}
          >
            {bHasError ? (
              <p className='quickSearchSelect__errorMsg'>
                Oops! Something went wrong. Please try your search again.
              </p>
            ) : (
              <QuickSearchSelectResultsList
                aResultsList={aSuggestions}
                setSelection={handleSelection}
                sUserQuery={sUserQuery}
              />
            )}
          </LoadingZone>
        </div>
      )}
    </div>
  );
};

export default QuickSearchSelect;

QuickSearchSelect.propTypes = {
  handleSubmitAdvancedSearch: PropTypes.func.isRequired,
};
