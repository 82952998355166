/**
 * Move focus to next field.
 *
 * @param {string} sFieldName - Field name.
 * @param {string} sFieldValue - Field value.
 */
const fnFocusNextField = (sFieldName = '', sFieldValue = '') => {
  const iCurrentFieldIndex = parseInt(sFieldName.slice(-1));
  let iFieldIndexToFocusOn = 0;
  if (sFieldValue === '') {
    // User hit backspace, go back a field
    iFieldIndexToFocusOn = iCurrentFieldIndex - 1;
  } else {
    // User entered a number, go forward a field
    iFieldIndexToFocusOn = iCurrentFieldIndex + 1;
  }

  if (iFieldIndexToFocusOn >= 1 && iFieldIndexToFocusOn <= 4) {
    document.getElementById(`ssnField-${iFieldIndexToFocusOn}`).focus();
  }
};

/**
 * Builds list of unique errors for SSN fields.
 *
 * @param {object} oFormikContext - Formik Context.
 * @returns {Set} - Unique error list.
 */
const fnGetErrors = (oFormikContext) => {
  const aErrors = new Set();
  for (let i = 1; i <= 4; i += 1) {
    const bIsTouched = !!oFormikContext.touched[`ssn${i}`];
    const bHasError = !!oFormikContext.errors[`ssn${i}`];
    if (bIsTouched && bHasError) {
      aErrors.add(oFormikContext.errors[`ssn${i}`]);
    }
  }
  return aErrors;
};

export { fnFocusNextField, fnGetErrors };
