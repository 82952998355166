import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { fnTruncateBetweenWords } from 'utils/common';

const iMaxCharacters = 200;

const FundOriginStory = ({ sStory }) => {
  const [bIsExpanded, fnSetIsExpanded] = useState(false);
  const bIsTruncated = sStory.length > iMaxCharacters;
  return (
    <>
      <h4 className='scholarships__subtitle t-label'>Fund Origin Story</h4>
      <div className='scholarships__fundOriginStoryWrapper'>
        <p
          className={
            bIsTruncated && !bIsExpanded
              ? 'scholarships__fundOrigin--truncated'
              : 'scholarships__fundOrigin'
          }
        >
          {bIsExpanded
            ? sStory
            : fnTruncateBetweenWords(sStory, iMaxCharacters)}
        </p>
        {bIsTruncated && (
          <Button
            sFlavor='underline'
            fnHandleClick={() => fnSetIsExpanded(!bIsExpanded)}
            className='scholarships__fundOriginViewMore'
          >
            View {bIsExpanded ? 'Less' : 'More'}
          </Button>
        )}
      </div>
    </>
  );
};

FundOriginStory.propTypes = {
  sStory: PropTypes.string.isRequired,
};

export default FundOriginStory;
