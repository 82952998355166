import React from 'react';
import PropTypes from 'prop-types';

import LinkWithSourcePath from 'utils/LinkWithSourcePath';
import { oCurrencyFormatter } from 'utils/common';

/**
 * Displays fund manager names.
 */
const FundOverview = ({ aContacts, sLatestValue, sTotalDistributions }) => {
  const fTotalDistributions = Math.abs(parseFloat(sTotalDistributions));
  return (
    <div className='philanthropyReport__fundOverview philanthropyReport__overviewContainer'>
      <h4 className='philanthropyReport__fundOverviewTitle t-label'>
        Fund Overview
      </h4>
      {sLatestValue && (
        <p className='fundOverview__stat'>
          Latest Value:{' '}
          <span className='fundOverview__statValue'>
            {oCurrencyFormatter.format(sLatestValue)}
          </span>
        </p>
      )}
      {sTotalDistributions && (
        <p className='fundOverview__stat'>
          Total Disbursements:{' '}
          <span className='fundOverview__statValue'>
            {oCurrencyFormatter.format(fTotalDistributions)}
          </span>
        </p>
      )}
      {aContacts.length > 0 && (
        <ul className='philanthropyReport__fundContactList'>
          {aContacts.map((oContact) => (
            <li
              className='philanthropyReport__fundContact t-paragraph--medium'
              key={oContact.LOOKUPID}
            >
              <span>Contact for Assistance:</span>
              <LinkWithSourcePath
                to={`/philanthropy/profile/${oContact.LOOKUPID}`}
                referringComponent='PhilanthropyReport'
                className='philanthropyReport__fundContactLink'
              >
                {oContact.NAME}
              </LinkWithSourcePath>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

FundOverview.propTypes = {
  aContacts: PropTypes.arrayOf(
    PropTypes.shape({
      LOOKUPID: PropTypes.string,
      NAME: PropTypes.string,
    })
  ),
  sLatestValue: PropTypes.string,
  sTotalDistributions: PropTypes.string,
};

FundOverview.defaultProps = {
  aContacts: [],
  sLatestValue: '',
  sTotalDistributions: '',
};

export default FundOverview;
