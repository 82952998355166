import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { fnElementRefPropType } from 'utils/customPropTypes';
import {
  retrieveFormPaymentUrl,
  retrieveCheckoutConfirmationUrl,
} from '../helpers';

/**
 * Responsible for building the hidden fields form that passes data to payment.
 *
 * @param {object} - params.oHiddenFieldsFormData
 *
 * @example for donations this is what the oHiddenFieldsFormData looks like.
 *  {
 *      lookup_id: '08-1324123',
 *      first_name: 'Elizabeth',
 *      last_name: 'Hicks',
 *      email: 'hicksem@wfu.edu',
 *      donation_amount: 1300
 *      items: {
 *        designation: [
 *          {
 *            id: 'UFU01',
 *            name: 'The Wake Forest Fund',
 *            quantity: 1000,
 *          },
 *          {
 *            id: 'SBU01',
 *            name: 'The Wake Forest Fund - School of Business',
 *            quantity: 400,
 *          }
 *        ]
 *      }
 *      other_amount_note: '',
 *    }
 *
 * @author Elizabeth Hicks <hicksem@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const HiddenFieldsForm = ({
  oHiddenFieldsFormData,
  oHiddenFieldFormRef,
  sPaymentFormUrl,
  sReturnPageUrl,
}) => {
  const { items, contact, ...oBasicHiddenFields } = oHiddenFieldsFormData || {};
  const { dadTransactionId } = useSelector((state) => state.Checkout);
  let aDesignationItems = [];
  if (items && items.designation) {
    aDesignationItems = items.designation;
  }

  const oLocation = useLocation();
  const sPaymentUrl = sPaymentFormUrl || retrieveFormPaymentUrl(oLocation);
  const sReturnUrl =
    sReturnPageUrl || retrieveCheckoutConfirmationUrl(oLocation);

  return !oHiddenFieldsFormData ? null : (
    <form
      action={sPaymentUrl}
      acceptCharset='UTF-8'
      method='post'
      ref={oHiddenFieldFormRef}
    >
      <input name='utf8' type='hidden' value='&#x2713;' />
      <input type='hidden' name='dad_transaction_id' value={dadTransactionId} />

      {Object.keys(oBasicHiddenFields).map((sHiddenFieldKey) => {
        const vHiddenFieldValue = oHiddenFieldsFormData[sHiddenFieldKey];
        return (
          <input
            key={sHiddenFieldKey}
            type='hidden'
            name={sHiddenFieldKey}
            value={vHiddenFieldValue}
          />
        );
      })}

      {contact && (
        <React.Fragment key='contact'>
          <input
            type='hidden'
            name='contact[name]'
            value={`${contact.first_name} ${contact.last_name}`}
          />
          <input
            type='hidden'
            name='contact[first_name]'
            value={contact.first_name}
          />
          <input
            type='hidden'
            name='contact[last_name]'
            value={contact.last_name}
          />
          <input
            type='hidden'
            name='contact[email_address]'
            value={contact.email_address}
          />
        </React.Fragment>
      )}

      {aDesignationItems.map((oDesignation, index) => (
        // @todo need to make sure aDesignationItems has items before building this part
        // this will build in flexibility for registration forms in the future that will
        // not necessarily have donating ability.
        <React.Fragment key={oDesignation.id}>
          <input
            type='hidden'
            name={`items[designation][${index}][quantity]`}
            value={oDesignation.quantity}
          />
          <input
            type='hidden'
            name={`items[designation][${index}][id]`}
            value={oDesignation.id}
          />
          <input
            type='hidden'
            name={`items[designation][${index}][name]`}
            value={oDesignation.name}
          />
        </React.Fragment>
      ))}

      <input
        type='hidden'
        name='redirect_url'
        id='redirect_url'
        value={sReturnUrl}
      />
    </form>
  );
};

HiddenFieldsForm.defaultProps = {
  oHiddenFieldsFormData: {},
  sPaymentFormUrl: '',
  sReturnPageUrl: '',
};

HiddenFieldsForm.propTypes = {
  oHiddenFieldsFormData: PropTypes.shape({
    lookup_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    donation_amount: PropTypes.number,
    items: PropTypes.shape({
      designation: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          quantity: PropTypes.number,
        })
      ),
    }),
  }),
  oHiddenFieldFormRef: fnElementRefPropType.isRequired,
  sPaymentFormUrl: PropTypes.string,
  sReturnPageUrl: PropTypes.string,
};

export default HiddenFieldsForm;
