import React from 'react';
import PropTypes from 'prop-types';

import { oCurrencyFormatter } from 'utils/common';

const GivingSummary = ({ data }) => (
  <>
    <div className='myGiving__dividerTitleBlock'>
      <span className='myGiving__dividerTitle'>Summary</span>
    </div>
    <div className='myGiving__summaryContent'>
      <div className='myGiving__summaryLabel'>Lifetime Giving</div>
      <div className='myGiving__summaryAmount'>
        {oCurrencyFormatter.format(data.LIFETIME)}
      </div>
      <div className='myGiving__thisFiscalYearWrapper'>
        <div className='myGiving__summaryLabel'>This Fiscal Year</div>
      </div>
      <div className='myGiving__summaryAmount'>
        {oCurrencyFormatter.format(data.THIS_FISCAL_YEAR)}
      </div>
    </div>
  </>
);

GivingSummary.propTypes = {
  data: PropTypes.shape({
    LIFETIME: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    THIS_FISCAL_YEAR: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

GivingSummary.defaultProps = {
  data: null,
};

export default GivingSummary;
