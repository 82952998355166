/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useProfileData from 'utils/hooks/useProfileData';
import MyClassmatesContext from './MyClassmatesContext';
import Accordion from '../../../Layout/Accordion';
import LoadingZone from '../../../LoadingZone';
import * as actions from '../../redux/AllClassmates/actions';
import MyClassmatesSearch from './MyClassmatesSearch';
import SortingModal from '../../../Search/elements/AdvancedSearch/Results/SortingModal';
import MyClassmatesList from './MyClassmatesList';
import MyClassmatesSearchEngine from './MyClassmatesSearch/helpers/MyClassmatesSearchEngine';
import ClassmatesSkeleton from '../ClassmatesSkeleton';

const searchEngine = new MyClassmatesSearchEngine();

/**
 * @description - generates the classmates view
 *
 * @author Tim Holder <holdertm@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */

export const MyClassmates = ({ setClassYear, classYear }) => {
  const allClassmates = useSelector((state) => state.AllClassmates.data);
  const activeClassmatesFilters = useSelector(
    (state) => state.AllClassmates.activeClassmatesFilters
  );
  const [isLoading, setIsLoading] = useState(true);
  const virtualizedListRef = useRef(null);
  const [classmatesToShow, setClassmatesToShow] = useState(allClassmates);
  const bIsProfileDataLoading = useProfileData();

  const dispatch = useDispatch();

  // Fetch classmates initially and when class year changes
  useEffect(() => {
    if (!bIsProfileDataLoading) {
      dispatch(actions.get({ classYear }));
    }
  }, [classYear, dispatch, bIsProfileDataLoading]);

  // Clear loading spinner once all classmates data is available
  useEffect(() => {
    if (allClassmates.length > 0) {
      if (activeClassmatesFilters) {
        const oSearchPromise = searchEngine.search(
          activeClassmatesFilters.advSearchCriteria,
          allClassmates
        );
        oSearchPromise.then((oFilteredClassmates) => {
          setClassmatesToShow(oFilteredClassmates);
        });
      } else {
        setClassmatesToShow(allClassmates);
      }
      setIsLoading(false);
    }
  }, [allClassmates, activeClassmatesFilters]);

  const handleClassYearChange = (value) => {
    if (value) {
      setIsLoading(true);
      setClassYear(value.ID);
    }
  };

  return (
    <Accordion
      mTitle='My Classmates'
      sTitleIcon='graduationCap'
      sGtmCategory='My Classmates'
    >
      <LoadingZone
        isLoading={isLoading}
        skeleton={<ClassmatesSkeleton />}
        message='Loading my classmates'
      >
        <div className='myClassmates__panelContent'>
          <div className='myClassmates__searchWrapper'>
            <MyClassmatesSearch
              handleClassYearChange={handleClassYearChange}
              classmatesToShow={classmatesToShow}
              setClassmatesToShow={setClassmatesToShow}
              virtualizedListRef={virtualizedListRef}
            />
            <SortingModal
              dataToSort={classmatesToShow}
              setSortedData={setClassmatesToShow}
              className='sortModalContainer--myClassmates'
            />
          </div>

          <MyClassmatesContext.Provider
            value={{
              classYear,
            }}
          >
            <MyClassmatesList
              classmates={classmatesToShow}
              virtualizedListRef={virtualizedListRef}
            />
          </MyClassmatesContext.Provider>
        </div>
      </LoadingZone>
    </Accordion>
  );
};

MyClassmates.propTypes = {
  setClassYear: PropTypes.func.isRequired,
  classYear: PropTypes.string,
};

MyClassmates.defaultProps = {
  classYear: '',
};

export default MyClassmates;
