import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

export const ToggleSwitch = ({
  sId,
  sName,
  sLabel,
  bSilenceErrors,
  bIsDisabled,
}) => {
  const [field, meta] = useField({ name: sName, type: 'checkbox' });
  const bShowErrors = meta.touched && meta.error && !bSilenceErrors;
  return (
    <div
      className={`toggleSwitch ${bIsDisabled ? 'toggleSwitch--disabled' : ''}`}
    >
      <input
        {...field}
        name={sName}
        type='checkbox'
        className='toggleSwitch__input hide_accessibly'
        id={sId}
        checked={field.value}
      />
      <label htmlFor={sId} className='toggleSwitch__label'>
        {sLabel}
      </label>
      {bShowErrors && <p className='field__errorMessage'>{meta.error}</p>}
    </div>
  );
};

ToggleSwitch.propTypes = {
  sId: PropTypes.string.isRequired,
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  bSilenceErrors: PropTypes.bool,
  bIsDisabled: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  bSilenceErrors: false,
  bIsDisabled: false,
};

export default ToggleSwitch;
