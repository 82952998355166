import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/Buttons/Button';
import { currentUserFirstLastNameSelector } from 'redux/selectors';
import useActions from 'utils/hooks/useActions';
import oIconLibrary from 'utils/iconLibrary';
import notificationsActions from '../redux/actions';

const UpdateEmploymentInfoNotification = ({ oData, fnSetShowModal }) => {
  const oName = useSelector(currentUserFirstLastNameSelector);
  const [bShowSuccess, fnSetShowSuccess] = useState(false);
  const [bIsLoading, fnSetIsLoading] = useState(false);
  const actions = useActions(notificationsActions);

  const fnVerifyEmploymentInfo = async () => {
    fnSetIsLoading(true);
    const oResult = await new Promise((resolve, reject) => {
      const { ID, TYPE } = oData;
      actions.update({
        data: {
          // This key is required in order to convert the array JSON to XML correctly.
          NOTIFICATIONS: [
            {
              NOTIFICATION: {
                ID,
                TYPE,
                DISMISSEDSTATUS: '1',
              },
            },
          ],
        },
        resolve,
        reject,
      });
    });
    if (oResult === 'Success') {
      fnSetIsLoading(false);
      fnSetShowSuccess(true);
    }
  };

  return bShowSuccess ? (
    <div className='notification__success'>
      <p>
        Great!
        <br />
        Thanks for confirming!
      </p>
      <FontAwesomeIcon
        icon={oIconLibrary.fontAwesome.sparkles}
        className='notificationsModal__successIcon'
      />
    </div>
  ) : (
    <div>
      <p className='t-paragraph--small'>Hey {oName.sFirstName}!</p>
      <p className='t-paragraph--small'>
        We noticed you haven&apos;t changed your career information in a while.
        Is this still accurate?
      </p>
      <p className='t-paragraph--small'>
        This information helps us personalize your network.
      </p>
      <Button
        className='notification__answerBtn'
        sFlavor='tertiary'
        bIsCentered
        fnHandleClick={() => fnSetShowModal(false)}
        bIsLoading={bIsLoading}
      >
        I&apos;d like to update
      </Button>
      <Button
        className='notification__answerBtn'
        sFlavor='underline'
        bIsCentered
        fnHandleClick={fnVerifyEmploymentInfo}
        bIsLoading={bIsLoading}
      >
        My information is up to date
      </Button>
    </div>
  );
};

UpdateEmploymentInfoNotification.propTypes = {
  oData: PropTypes.shape({
    ID: PropTypes.string,
    TYPE: PropTypes.string,
  }).isRequired,
  fnSetShowModal: PropTypes.func.isRequired,
};

export default UpdateEmploymentInfoNotification;
