import { createActions } from 'redux-actions';

export const totalPrivacyActions = createActions(
  {
    UPDATE: (action) => action,
  },
  {
    prefix: 'Profile/BIOGRAPHICAL/TOTAL_PRIVACY',
  }
);

export default totalPrivacyActions;
