import DateAndTime from 'date-and-time';
import TimeZonePlugin from 'date-and-time/plugin/timezone';

import { fnConvertTimeZoneAbbrToIana, oCurrencyFormatter } from 'utils/common';

DateAndTime.plugin(TimeZonePlugin);

/**
 * Formats an event's start and end dates.
 *
 * @param {string} sStartDate - Start date time from DAD
 * @param {string} sEndDate - End date time from DAD
 * @returns {string}
 */
export const fnFormatEventDate = (sStartDate, sEndDate) => {
  if (!sStartDate && !sEndDate) {
    return '';
  }

  let sFormatted = '';
  const oStartDate = DateAndTime.parse(sStartDate, 'YYYY-MM-DDTHH:mm:ss');
  const oEndDate = DateAndTime.parse(sEndDate, 'YYYY-MM-DDTHH:mm:ss');

  const sStartMonth = DateAndTime.format(oStartDate, 'MMM');
  const sStartDay = DateAndTime.format(oStartDate, 'DD');

  const sEndMonth = DateAndTime.format(oEndDate, 'MMM');
  const sEndDay = DateAndTime.format(oEndDate, 'DD');

  const bIsSingleDayEvent = DateAndTime.isSameDay(oStartDate, oEndDate);

  if (bIsSingleDayEvent) {
    sFormatted = `${sStartMonth} ${sStartDay}`; // Jan 01
  } else if (sStartMonth === sEndMonth) {
    sFormatted = `${sStartMonth} ${sStartDay} - ${sEndDay}`; // Jan 01 - 11
  } else {
    sFormatted = `${sStartMonth} ${sStartDay} - ${sEndMonth} ${sEndDay}`; // Jan 25 - Feb 02
  }
  return sFormatted;
};

/**
 * Formats an event's start and end times.
 *
 * @param {string} sStartDate - Start date time from DAD
 * @param {string} sEndDate - End date time from DAD
 * @param {string} sTimezone - Timezone abbreviation from DAD
 * @returns {string}
 */
export const fnFormatEventTime = (sStartDate, sEndDate, sTimezone = 'ET') => {
  if (!sStartDate && !sEndDate) {
    return '';
  }

  const oStartDate = DateAndTime.parse(sStartDate, 'YYYY-MM-DDTHH:mm:ss');
  const oEndDate = DateAndTime.parse(sEndDate, 'YYYY-MM-DDTHH:mm:ss');

  const sStartTime = DateAndTime.format(oStartDate, 'hh:mm');
  const sEndTime = DateAndTime.format(oEndDate, 'hh:mm');

  const bTimeIsTbd = sStartTime === '12:00' && sEndTime === '12:01';
  const bIsSingleDayEvent = DateAndTime.isSameDay(oStartDate, oEndDate);
  const sTimeFormat = bIsSingleDayEvent
    ? 'h:mm A' // 10:00 AM
    : 'MMM DD at h:mm A'; // Jan 27 at 10:00 AM
  const sFormatted = bTimeIsTbd
    ? 'To Be Announced'
    : `${DateAndTime.format(oStartDate, sTimeFormat)} - ${DateAndTime.format(
        oEndDate,
        sTimeFormat
      )} ${sTimezone}`;

  return sFormatted;
};

/**
 * Applies USD currency formatting and handles cost ranges.
 *
 * @param {object} oEvent - Event object from DAD
 * @returns {string}
 */
export const fnFormatEventCost = (oEvent) => {
  let sCost = '';

  const bHasCost = oEvent.REGISTRATION.HAS_COST === 1;

  if (bHasCost) {
    const { MIN, MAX } = oEvent.REGISTRATION.COST_RANGE;
    if (MIN === MAX) {
      sCost = oCurrencyFormatter.format(MIN);
    } else {
      sCost = `${oCurrencyFormatter.format(MIN)} - ${oCurrencyFormatter.format(
        MAX
      )}`;
    }
  }

  return sCost;
};

const fnIsEventRegistrationOpen = (
  sOpenDateTime,
  sCloseDateTime,
  sTimeZone
) => {
  if (!sOpenDateTime || !sCloseDateTime) {
    return false;
  }
  const oNow = new Date();
  const sEventIanaTimeZone = fnConvertTimeZoneAbbrToIana(sTimeZone);
  let oOpenDateTime;
  let oCloseDateTime;
  if (sEventIanaTimeZone) {
    oOpenDateTime = DateAndTime.parseTZ(
      sOpenDateTime,
      'YYYY-MM-DDTHH:mm:ss',
      sEventIanaTimeZone
    );
    oCloseDateTime = DateAndTime.parseTZ(
      sCloseDateTime,
      'YYYY-MM-DDTHH:mm:ss',
      sEventIanaTimeZone
    );
  } else {
    oOpenDateTime = DateAndTime.parse(sOpenDateTime, 'YYYY-MM-DDTHH:mm:ss');
    oCloseDateTime = DateAndTime.parse(sCloseDateTime, 'YYYY-MM-DDTHH:mm:ss');
  }
  return oOpenDateTime <= oNow && oCloseDateTime >= oNow;
};

export const fnIsEventAtCapacity = (oEvent) => {
  const { TOTAL_CAPACITY, TOTAL_REGISTRATIONS } = oEvent.REGISTRATION;
  const bHasSetCapacity = TOTAL_CAPACITY > 0;
  const iSpotsRemaining = TOTAL_CAPACITY - TOTAL_REGISTRATIONS;
  return bHasSetCapacity && iSpotsRemaining <= 0;
};

export const fnIsEventRegistrable = (oEvent) => {
  const { IS_CANCELLED, TIME_ZONE } = oEvent;
  const {
    IS_REGISTERED,
    TOTAL_CAPACITY,
    TOTAL_REGISTRATIONS,
    OPEN_DATETIME,
    CLOSE_DATETIME,
  } = oEvent.REGISTRATION;
  const bIsCancelled = [true, 1].includes(IS_CANCELLED);
  if (bIsCancelled) {
    return false;
  }

  const bIsRegistered = [true, 1].includes(IS_REGISTERED);
  if (bIsRegistered) {
    return false;
  }

  const bIsRegistrationOpen = fnIsEventRegistrationOpen(
    OPEN_DATETIME,
    CLOSE_DATETIME,
    TIME_ZONE
  );
  if (!bIsRegistrationOpen) {
    return false;
  }

  const bHasSetCapacity = TOTAL_CAPACITY > 0;
  const iSpotsRemaining = TOTAL_CAPACITY - TOTAL_REGISTRATIONS;
  if (bHasSetCapacity && iSpotsRemaining === 0) {
    return false;
  }

  return true;
};
