import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Buttons/Button';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { useNavigate } from 'react-router-dom';
import oWnApi from 'utils/WnApi';
import { lookupIdMatcher } from 'utils/matchers';
import usePageTitle from 'utils/hooks/usePageTitle';
import { selectAllEventsInCart } from '../redux/selectors';
import {
  fnAddGuestToEventInCart,
  fnRemoveAllGuestsFromEventInCart,
} from '../redux/guestHelpers';
import EventRegistrationUserSearch from './EventRegistrationUserSearch';
import CreateEventRegistrationUser from './CreateEventRegistrationUser';
import EventRegistrationContext from '../EventRegistrationContext';
import { selectNextEventRegStep } from '../EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import { fnGetFirstEventUserIsNotInvitedTo } from './helpers';

const sFormId = 'event-registration-user-identification-form';

// Identifies the user for unauthenticated event registration
const EventRegistrationUserIdentification = () => {
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();
  const {
    fnSetCurrentFormId,
    fnSetIsSubmittingCurrentForm,
    bIsSubmittingCurrentForm,
  } = useContext(EventRegistrationContext);
  const [sUIPhase, fnSetUIPhase] = useState('search');
  const [oNewUserInitialValues, fnSetNewUserInitialValues] = useState(null);
  const [oNewUser, fnSetNewUser] = useState(null);
  const aEventsInCart = useSelector(selectAllEventsInCart);
  const sNextStepUrl = useSelector(selectNextEventRegStep);
  usePageTitle('Event Registration - User Identification');

  const iNumGuestsForFirstEvent = aEventsInCart[0]?.oDetails?.aGuests?.length;

  useEffect(() => {
    fnSetCurrentFormId(sFormId);

    return () => {
      fnSetCurrentFormId('');
    };
  }, [fnSetCurrentFormId]);

  // Save the user's info to Checkout in Redux
  useEffect(() => {
    const fnUpdateCheckoutUserData = async () => {
      let sUserFirstName = oNewUser?.sFirstName ?? '';
      let sUserLastName = oNewUser?.sLastName ?? '';
      let sEncodedEmail = '';

      // Quick search only returns full names and lookup IDs currently,
      // so we need to make a separate API call to get the user's first and last name
      if (oNewUser.sLookupId && lookupIdMatcher.test(oNewUser.sLookupId)) {
        const oResponse = await oWnApi.get(
          `constituents/${oNewUser.sLookupId}`,
          false
        );
        if (oResponse.status === 200) {
          sUserFirstName = oResponse.data.DATA.BIOGRAPHICAL.FIRSTNAME;
          sUserLastName = oResponse.data.DATA.BIOGRAPHICAL.LASTNAME;
          sEncodedEmail = oResponse.data.DATA?.EMAIL?.[0]?.ENCODED_EMAIL;
        }
      }

      fnDispatch(
        CheckoutActions.update({
          fullName: oNewUser?.sName ?? '',
          firstName: sUserFirstName,
          lastName: sUserLastName,
          email: oNewUser?.sEmail ?? '',
          sEncodedEmail,
          userLookupId: oNewUser?.sLookupId ?? '', // Users who didn't find a match in quick search won't have a lookup ID
        })
      );
    };
    if (oNewUser) {
      fnUpdateCheckoutUserData();
    }
  }, [oNewUser, aEventsInCart, fnDispatch]);

  // Add the user to the event(s) in the cart
  const fnHandleSubmit = async (event) => {
    event.preventDefault();

    fnSetIsSubmittingCurrentForm(true);

    // If the user is not invited to any of the events in the cart, redirect to the invite-only page
    const oEventUserIsNotInvitedTo = await fnGetFirstEventUserIsNotInvitedTo(
      oNewUser.sLookupId,
      aEventsInCart
    );
    if (oEventUserIsNotInvitedTo) {
      fnSetIsSubmittingCurrentForm(false);
      const { TITLE, CONTACT } = oEventUserIsNotInvitedTo;
      fnNavigate('/events/register/invite-only', {
        state: { sEventTitle: TITLE, oEventContact: CONTACT },
      });
      return;
    }

    let sNewUserLookupId = oNewUser.sLookupId;
    const oNewConstituentData = oNewUser.sLookupId
      ? null
      : {
          FIRST_NAME: oNewUser.sFirstName,
          LAST_NAME: oNewUser.sLastName,
          EMAIL_ADDRESS: oNewUser.sEmail,
        };

    // Add the new user to the event(s) in the cart
    aEventsInCart.forEach((oEventCartItem) => {
      fnRemoveAllGuestsFromEventInCart(oEventCartItem.oDetails.oEvent.LOOKUPID);

      sNewUserLookupId = fnAddGuestToEventInCart(
        oNewUser.sName,
        sNewUserLookupId,
        oEventCartItem.oDetails.oEvent.LOOKUPID,
        oNewConstituentData
      );
    });
    fnSetNewUser((oPrevUser) => ({
      ...oPrevUser,
      sLookupId: sNewUserLookupId,
    }));
  };

  // Once the user is added to the event(s) in the cart, navigate to the next step
  useEffect(() => {
    if (iNumGuestsForFirstEvent > 0 && bIsSubmittingCurrentForm) {
      fnSetIsSubmittingCurrentForm(false);
      fnNavigate(sNextStepUrl);
    }
  }, [
    iNumGuestsForFirstEvent,
    sNextStepUrl,
    bIsSubmittingCurrentForm,
    fnSetIsSubmittingCurrentForm,
    fnNavigate,
  ]);

  switch (sUIPhase) {
    case 'search':
      return (
        <EventRegistrationUserSearch
          fnSetUIPhase={fnSetUIPhase}
          fnSetNewUser={fnSetNewUser}
          fnSetNewUserInitialValues={fnSetNewUserInitialValues}
        />
      );
    case 'create':
      return (
        <CreateEventRegistrationUser
          fnSetNewUser={fnSetNewUser}
          oNewUserInitialValues={oNewUserInitialValues}
          fnSetUIPhase={fnSetUIPhase}
        />
      );
    default:
      return (
        <div className='eventRegUserIdentification__identifiedWrapper'>
          <h1 className='t-paragraph--medium'>Hi {oNewUser.sName}!</h1>
          <h2 className='t-paragraph--medium'>
            Let&apos;s start the event registration process.
          </h2>

          <Button
            sFlavor='underline'
            fnHandleClick={() => {
              fnSetNewUser(null);
              fnSetUIPhase('search');
            }}
            className='eventRegUserIdentification__notYouButton'
          >
            Not {oNewUser.sName}?
          </Button>
          {/* This form allows adding the user to the event(s) in the cart when they click the "Next" button */}
          <form id={sFormId} onSubmit={fnHandleSubmit} />
        </div>
      );
  }
};

export default EventRegistrationUserIdentification;
