import * as R from 'ramda';
import { createSelector } from 'reselect';

import { groupByPrimary } from '../../../../utils/common';

const transformEmailDataForRender = (aRawEmailData) => {
  let aEmails = [];
  if (aRawEmailData.length > 0) {
    const oGroupedByPrimary = groupByPrimary(aRawEmailData);
    if (oGroupedByPrimary.primary) {
      const oPrimaryEmail = oGroupedByPrimary.primary[0];
      const oFormattedPrimaryEmail = {
        id: oPrimaryEmail.ID,
        value: oPrimaryEmail.EMAILADDRESS,
      };
      aEmails.push(oFormattedPrimaryEmail);
    }

    let aSecondaryEmails = [];
    if (oGroupedByPrimary.secondary) {
      aSecondaryEmails = oGroupedByPrimary.secondary.map((oEmail) => ({
        id: oEmail.ID,
        value: oEmail.EMAILADDRESS,
      }));
    }
    aEmails = [...aEmails, ...aSecondaryEmails];
    aEmails = R.uniqBy((oEmail) => oEmail.value, aEmails);
  }
  return aEmails;
};

const rawEmailSelector = (state) => (state.Profile || {}).EMAIL || [];

export const selectEmailOptionsForCheckout = createSelector(
  rawEmailSelector,
  transformEmailDataForRender
);

export default selectEmailOptionsForCheckout;
