import React from 'react';
import PropTypes from 'prop-types';

const SVGSolidHeartIcon = ({ fillColor, strokeColor }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='56'
    height='51'
    fill='none'
    viewBox='0 0 56 51'
  >
    <path
      fill={fillColor}
      d='M54.794 19.687c-1.462 8.262-7.153 14.89-12.896 20.696a56.661 56.661 0 01-2.956 2.793c-.34.298-.71.577-1.082.856-.5.375-1 .752-1.428 1.177l-.017.014c-3.018 2.464-6.02 4.383-8.728 5.633-2.71-1.25-5.709-3.17-8.726-5.633l-.017-.014c-.428-.425-.93-.802-1.429-1.177-.372-.279-.743-.558-1.083-.856a56.64 56.64 0 01-2.955-2.793C7.736 34.581 2.043 27.953.58 19.691.284 18.01.28 16.4.4 14.706.944 6.94 7.457.813 15.41.813c2.39-.002 4.745.562 6.87 1.645a15.006 15.006 0 015.355 4.584.948.948 0 01.052-.07.789.789 0 01.053.07 15.042 15.042 0 017.262-5.396 15.138 15.138 0 019.062-.271 15.057 15.057 0 017.575 4.953 14.906 14.906 0 013.337 8.378c.12 1.69.116 3.3-.182 4.981z'
    />
    <path
      stroke={strokeColor}
      d='M54.794 19.687c-1.462 8.262-7.153 14.89-12.896 20.696a56.661 56.661 0 01-2.956 2.793c-.34.298-.71.577-1.082.856-.5.375-1 .752-1.428 1.177l-.017.014c-3.018 2.464-6.02 4.383-8.728 5.633-2.71-1.25-5.709-3.17-8.726-5.633l-.017-.014c-.428-.425-.93-.802-1.429-1.177-.372-.279-.743-.558-1.083-.856a56.64 56.64 0 01-2.955-2.793C7.736 34.581 2.043 27.953.58 19.691.284 18.01.28 16.4.4 14.706.944 6.94 7.457.813 15.41.813c2.39-.002 4.745.562 6.87 1.645a15.006 15.006 0 015.355 4.584.948.948 0 01.052-.07.789.789 0 01.053.07 15.042 15.042 0 017.262-5.396 15.138 15.138 0 019.062-.271 15.057 15.057 0 017.575 4.953 14.906 14.906 0 013.337 8.378c.12 1.69.116 3.3-.182 4.981z'
    />
  </svg>
);

SVGSolidHeartIcon.defaultProps = {
  fillColor: '#EFEFEF',
  strokeColor: 'none',
};

SVGSolidHeartIcon.propTypes = {
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default SVGSolidHeartIcon;
