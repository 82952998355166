import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import oWnApi from 'utils/WnApi';
import Button from 'components/Buttons/Button';
import usePageTitle from 'utils/hooks/usePageTitle';
import LoadingZone from 'components/LoadingZone';

const MagicLink = ({ bIsMagicLinkSent, fnSetIsMagicLinkSent }) => {
  const oLocation = useLocation();
  const { oConstituent } = oLocation.state;
  const [sEmail, setEmail] = useState('');
  const [bIsSendingMagicLink, setIsSendingMagicLink] = useState(false);
  usePageTitle('Sign Up');

  useEffect(() => {
    const fnGetEmailAddress = async () => {
      const oCognitoUser = await Auth.currentAuthenticatedUser();
      setEmail(oCognitoUser.attributes.email);
    };

    fnGetEmailAddress();
  }, []);

  const fnSendMagicLink = async () => {
    setIsSendingMagicLink(true);
    const oResponse = await oWnApi.get(
      `users/${oConstituent.LOOKUPID}/magic_link?nickname=${oConstituent.NICKNAME}`
    );
    if (oResponse.status === 200) {
      setIsSendingMagicLink(false);
      fnSetIsMagicLinkSent(true);
    }
  };

  return bIsMagicLinkSent ? (
    <>
      <h2>Email Sent!</h2>
      <p>
        We just sent a magic link to <strong>{sEmail}</strong>.
      </p>
      <p>
        Please check your inbox and click the link within the email to log in to
        WakeNetwork.
      </p>
      <p>
        If you don&apos;t see the email in your inbox, please check your spam
        folder.
      </p>
    </>
  ) : (
    <>
      <h2>
        Welcome back! To keep your info safe, we just need you to verify your
        identity.
      </h2>
      <p>
        We can email you a magic link to <strong>{sEmail}</strong>, that will
        allow you to access Wake Network instantly.
      </p>
      <LoadingZone isLoading={bIsSendingMagicLink}>
        <Button
          fnHandleClick={fnSendMagicLink}
          className='magicLink__button'
          disabled={bIsSendingMagicLink}
        >
          Email Me a Magic Link
        </Button>
      </LoadingZone>
    </>
  );
};

MagicLink.propTypes = {
  bIsMagicLinkSent: PropTypes.bool,
  fnSetIsMagicLinkSent: PropTypes.func,
};

MagicLink.defaultProps = {
  bIsMagicLinkSent: false,
  fnSetIsMagicLinkSent: () => {},
};

export default MagicLink;
