import React from 'react';
import PropTypes from 'prop-types';

import oColors from '../../styles/utils/_colors.scss';

const aAllColorNames = Object.keys(oColors);

const ColorList = ({ sCategory }) => {
  const aColorNames = aAllColorNames.filter((sName) =>
    sName.includes(sCategory)
  );
  return (
    <ul className='colorList'>
      {aColorNames.map((sColorName) => {
        const sColorNameWithoutCategory = sColorName.split('-')[1];
        const sHexValue = oColors[sColorName];
        return (
          <li className='t-header5' key={sColorName}>
            <div
              className='colorList__swatch'
              style={{ background: sHexValue }}
            />
            {sColorNameWithoutCategory}
            <br />
            {sHexValue}
          </li>
        );
      })}
    </ul>
  );
};

ColorList.propTypes = {
  sCategory: PropTypes.oneOf(['base', 'interactions', 'neutrals']).isRequired,
};

export default ColorList;
