/**
 * Formats quick search location strings into an object for advanced search.
 *
 * @param {string} sLocation - Quick search location name
 * @param {array} aStateList - REGION code table
 * @param {array} aCountryList - COUNTRY code table
 * @returns {object}
 */
export const fnFormatQuickSearchLocationForAdvSearch = (
  sLocation = '',
  aStateList = [],
  aCountryList = []
) => {
  if (!sLocation) {
    return {};
  }

  const oResult = {};

  // Example: 'Winston-Salem, North Carolina (NC), United States (USA)'
  const [sCity, sStatePart, sCountryPart] = sLocation.split(', ');

  oResult.city = sCity;

  const sStateAbbrv = sStatePart.slice(-3, -1);
  const oState = aStateList.find(
    (item) => item.DETAILS.STATE_ABBRV === sStateAbbrv
  );
  if (oState) {
    oResult.state = { ID: oState.ID, DESCRIPTION: oState.DESCRIPTION };
  }

  const [sCountryName] = sCountryPart.split(' (');
  const oCountry = aCountryList.find(
    (item) => item.DESCRIPTION === sCountryName
  );
  if (oCountry) {
    oResult.country = { ID: oCountry.ID, DESCRIPTION: oCountry.DESCRIPTION };
  }

  return oResult;
};

export const oEmptyAdvancedSearch = {
  firstname: '',
  lastname: '',
  city: '',
  state: { ID: null, DESCRIPTION: '' },
  country: { ID: null, DESCRIPTION: '' },
  classof: '',
  employer: { NAME: '', LOOKUPID: '' },
  includeformeremployers: false,
  educationalprogram: { ID: null, DESCRIPTION: '' },
  educationalinvolvement: { ID: null, DESCRIPTION: '' },
  educationaldepartment: { ID: null, DESCRIPTION: '' },
  affinitygroup: { ID: null, DESCRIPTION: '' },
  industry: { ID: null, DESCRIPTION: '' },
};
