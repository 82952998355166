import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';

const ContinueButton = ({
  bContinueButtonDisabled,
  fnHandleContinue,
  sContinueButtonText,
  sType,
  sFormId,
  bIsLoading,
}) => (
  <Button
    className='authLayout__continueBtn'
    bIsDisabled={bContinueButtonDisabled}
    fnHandleClick={() => fnHandleContinue()}
    sType={sType}
    sFormId={sFormId}
    bIsLoading={bIsLoading}
  >
    {sContinueButtonText}
  </Button>
);

// Custom validator for fnHandleContinue
function fnHandleContinueValidator(props, propName, componentName) {
  if (props.sFormId === '' && props.sType === 'button') {
    const fn = props[propName];
    if (!fn || typeof fn !== 'function') {
      return new Error(
        `${propName} is required when sFormId is an empty string and sType is 'button' in ${componentName}.`
      );
    }
  }
}

ContinueButton.propTypes = {
  bContinueButtonDisabled: PropTypes.bool,
  fnHandleContinue: fnHandleContinueValidator,
  sContinueButtonText: PropTypes.string,
  sFormId: PropTypes.string,
  sType: PropTypes.string,
  bIsLoading: PropTypes.bool,
};

ContinueButton.defaultProps = {
  bContinueButtonDisabled: false,
  fnHandleContinue: () => {},
  sContinueButtonText: 'Continue',
  sFormId: '',
  sType: 'button',
  bIsLoading: false,
};

export default ContinueButton;
