import { createSelector } from 'reselect';

const selectCart = (state) => state.Checkout.aCart;

export const selectCartTotal = createSelector(selectCart, (aCart) =>
  aCart.reduce((nTotal, oItem) => {
    // eslint-disable-next-line no-param-reassign
    nTotal += oItem.nPrice;
    return nTotal;
  }, 0)
);

export const selectCartGifts = createSelector(selectCart, (aCart) =>
  aCart.filter((oItem) => oItem.sType === 'donation')
);

export default {};
