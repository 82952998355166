import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CheckoutConfirmation from 'components/Checkout/CheckoutConfirmation';
import CheckoutSummaryTable from 'components/Checkout/CheckoutSummaryTable';
import usePageTitle from 'utils/hooks/usePageTitle';
import { selectCartGifts } from './selectors';

/**
 * Responsible for the giving confirmation page.
 */
const GivingConfirmation = () => {
  const fnNavigate = useNavigate();
  const oLocation = useLocation();
  const aCartGifts = useSelector(selectCartGifts);

  const [aSummaryItems, fnSetSummaryItems] = useState([]);

  useEffect(() => {
    if (aCartGifts.length > 0) {
      const aFormattedGifts = aCartGifts.map((oGift) => ({
        description: oGift.sDisplayName,
        amount: oGift.nPrice,
      }));
      fnSetSummaryItems(aFormattedGifts);
    }
  }, [aCartGifts]);

  usePageTitle('Giving Confirmation');

  let bUserRefreshedPage = false;
  if (performance?.getEntriesByType('navigation')?.length > 0) {
    bUserRefreshedPage =
      performance.getEntriesByType('navigation')[0].type === 'reload';
  }
  const bShouldRedirect = !oLocation.search || bUserRefreshedPage;
  if (bShouldRedirect) {
    fnNavigate('/philanthropy');
  }

  return (
    <CheckoutConfirmation
      sTitle='Giving'
      sFirstStepLabel='Select Amount'
      oTableMarkup={<CheckoutSummaryTable aCheckoutItems={aSummaryItems} />}
      bHasPayment
    />
  );
};

export default GivingConfirmation;
