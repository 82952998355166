import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import oWnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';
import Button from 'components/Buttons/Button';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import LoadingZone from 'components/LoadingZone';
import AddNote from '../Form/OptionsModal/DefaultFields/AddNote';
import ThumbsUpThumbsDownFeedback from './ThumbsUpThumbsDownFeedback';

const Feedback = () => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [hasSubmittedGoodBadFeedback, setHasSubmittedGoodBadFeedback] =
    useState(false);
  const [isAddNoteActive, setIsAddNoteActive] = useState(false);
  const [addNoteStatus, setAddNoteStatus] = useState('');
  const feedbackRef = useRef();
  const aCodes = useCodeTablesSection('CONSTITUENTNOTETYPE');
  const sFeedbackNoteTypeCodeId = aCodes.find(
    (item) => item.DESCRIPTION === 'Wake Network: User Feedback'
  )?.ID;
  const sLookupId = useSelector(currentUserLookupIdSelector);

  const postFeedback = async (feedback) => {
    // Build the name of the current page
    // get the current path without the leading slash
    let page = window.location.pathname.substring(1);
    // change 'main' into 'profile' to make it more human-friendly
    page = page === 'main' ? 'profile' : page;
    // capitalize the page name
    page = page.charAt(0).toUpperCase() + page.substring(1);

    const sRequestUrl = `constituents/${sLookupId}/add_note`;
    const oResponse = await oWnApi.post(sRequestUrl, {
      TYPE: 'Note',
      data: {
        TITLE: `${page} Page Feedback`,
        TEXTNOTE: `${page} page: ${feedback}`,
        NOTETYPECODEID: sFeedbackNoteTypeCodeId,
      },
    });

    return oResponse;
  };

  const handleSubmitTextFeedback = async ({ data, resolve, reject }) => {
    try {
      const response = await postFeedback(data.TEXTNOTE);
      if (response.status === 200) {
        resolve('Success');
      } else {
        throw response.status;
      }
    } catch (err) {
      console.error('Error saving feedback data', err);
      reject(err);
    }
  };

  const handleReset = () => {
    // Close popup
    setIsFeedbackOpen(false);

    // Reset contents
    setIsAddNoteActive(false);
    setAddNoteStatus('');
    setHasSubmittedGoodBadFeedback(false);
  };

  // Reset the form after successfully submitting feedback
  useEffect(() => {
    if (addNoteStatus === 'success') {
      // Allow the confirmation message to show for 2 seconds
      setTimeout(() => {
        handleReset();
      }, 2000);
    }
  }, [addNoteStatus]);

  useCloseOnOutsideClick(feedbackRef, handleReset, isFeedbackOpen);

  // Scroll down to show whole form
  useLayoutEffect(() => {
    if (isFeedbackOpen) {
      feedbackRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  });

  return (
    <div className='feedback' ref={feedbackRef}>
      {isFeedbackOpen ? (
        <LoadingZone isLoading={!sFeedbackNoteTypeCodeId}>
          <div className='feedback__content'>
            {isAddNoteActive ? (
              <AddNote
                visible={isAddNoteActive}
                setVisible={setIsAddNoteActive}
                onSubmit={handleSubmitTextFeedback}
                status={addNoteStatus}
                setFormStatus={setAddNoteStatus}
                successMsg='Thanks for letting us know!'
              />
            ) : (
              <>
                <div className='feedback__header t-paragraph'>
                  How are we doing?
                </div>
                <ThumbsUpThumbsDownFeedback
                  hasSubmittedGoodBadFeedback={hasSubmittedGoodBadFeedback}
                  setHasSubmittedGoodBadFeedback={
                    setHasSubmittedGoodBadFeedback
                  }
                  postFeedback={postFeedback}
                />
                <Button
                  sFlavor='underline'
                  fnHandleClick={() => setIsAddNoteActive(true)}
                  className='feedback__addNoteTrigger'
                >
                  Have anything else to share?
                </Button>
              </>
            )}
          </div>
        </LoadingZone>
      ) : (
        <Button
          sFlavor='tertiary'
          fnHandleClick={() => setIsFeedbackOpen(true)}
        >
          Feedback
        </Button>
      )}
    </div>
  );
};

export default Feedback;
