import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * Custom hook that determines if the user is viewing their spouse's profile.
 *
 * @returns {boolean} - True if the user is viewing the spouse profile, false otherwise.
 */
const useIsUserViewingSpouseProfile = () => {
  const oRouteParams = useParams();
  const sSpouseLookupId = useSelector(
    (state) => state.Profile?.SPOUSE?.LOOKUPID || ''
  );

  const bIsUserViewingSpouseProfile = useMemo(
    () => oRouteParams.lookupId === sSpouseLookupId,
    [oRouteParams.lookupId, sSpouseLookupId]
  );

  return bIsUserViewingSpouseProfile;
};

export default useIsUserViewingSpouseProfile;
