import React from 'react';
import AddressFormatter from '@fragaria/address-formatter';
import DateAndTime from 'date-and-time';

/**
 * Converts MM/DD start and end dates to Jan – Jun format.
 *
 * @param {string} sStartDate - Start date in MM/DD format.
 * @param {string} sEndDate - End date in MM/DD format.
 *
 * @returns {string}
 */
export const fnBuildDateRangeText = (sStartDate, sEndDate) => {
  let sDateRange = '';
  const bHasStartDate = sStartDate !== '00/00';
  const bHasEndDate = sEndDate !== '00/00';
  if (bHasStartDate && bHasEndDate) {
    const oStart = DateAndTime.parse(sStartDate, 'MM/DD');
    const oEnd = DateAndTime.parse(sEndDate, 'MM/DD');
    sDateRange = `${DateAndTime.format(oStart, 'MMM')} - ${DateAndTime.format(
      oEnd,
      'MMM'
    )}`;
  } else if (bHasStartDate) {
    const oStart = DateAndTime.parse(sStartDate, 'MM/DD');
    sDateRange = `${DateAndTime.format(oStart, 'MMM')} - Unknown`;
  } else if (bHasEndDate) {
    const oEnd = DateAndTime.parse(sEndDate, 'MM/DD');
    sDateRange = `Unknown - ${DateAndTime.format(oEnd, 'MMM')}`;
  } else {
    sDateRange = 'Dates Unknown';
  }
  return sDateRange;
};

const oAddressFormatterOptions = { output: 'array', appendCountry: true };

/**
 * Builds international address markup based on privacy settings.
 *
 * @param {object} oAddress - Address data.
 * @param {boolean} bIsExternalView - If address is being shown on a public profile or not.
 *
 * @returns {jsx}
 */
export const fnFormatAddress = (oAddress, bIsExternalView) => {
  const {
    ADDRESSCITY,
    ADDRESSSTATE,
    ADDRESSBLOCK,
    ADDRESSZIP,
    ADDRESSCOUNTRY,
  } = oAddress;
  let aLines = [];
  const oFormatMe = {
    city: ADDRESSCITY,
    state: ADDRESSSTATE,
    countryCode: ADDRESSCOUNTRY,
  };
  const bShowCityStateOnly = oAddress.PRIVACY === '1';
  if (bIsExternalView && bShowCityStateOnly) {
    aLines = AddressFormatter.format(oFormatMe, oAddressFormatterOptions);
  } else {
    const sZip = ADDRESSCOUNTRY === 'US' ? ADDRESSZIP.slice(0, 5) : ADDRESSZIP;
    aLines = AddressFormatter.format(
      {
        ...oFormatMe,
        road: ADDRESSBLOCK,
        postcode: sZip,
      },
      oAddressFormatterOptions
    );
  }

  const aMarkup = aLines.map((sLine, nIndex) => {
    const bIsLastLine = nIndex === aLines.length - 1;
    if (bIsLastLine) {
      return sLine;
    }
    return (
      <React.Fragment key={sLine}>
        {sLine}
        <br />
      </React.Fragment>
    );
  });

  return aMarkup;
};
