import React from 'react';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import useWindowSize from 'utils/hooks/useWindowSize';

const QuickSearchSelectInput = ({
  fnSetIsResultListVisible,
  fnSetUserQuery,
  sUserQuery,
}) => {
  const { sScreenSize } = useWindowSize();
  const sPlaceholder =
    sScreenSize === 'small'
      ? 'Search Directory'
      : 'Search for people, employers, or locations';
  return (
    <div className='quickSearchSelectInput__wrapper'>
      <label htmlFor='search-directory' className='hide_accessibly'>
        Search for people, employers, or locations
      </label>
      <input
        id='search-directory'
        type='text'
        name='search-directory'
        onChange={(event) => fnSetUserQuery(event.target.value)}
        autoComplete='off'
        value={sUserQuery}
        placeholder={sPlaceholder}
        className='quickSearchSelect__input t-paragraph--large'
      />
      {sUserQuery.length > 0 && (
        <IconOnlyButton
          sIcon='close'
          sAriaLabel='Clear search'
          className='quickSearchSelect__clearBtn'
          fnHandleClick={() => fnSetIsResultListVisible(false)}
          sFlavor='tertiary'
        />
      )}
    </div>
  );
};

QuickSearchSelectInput.propTypes = {
  fnSetIsResultListVisible: PropTypes.func.isRequired,
  fnSetUserQuery: PropTypes.func.isRequired,
  sUserQuery: PropTypes.string.isRequired,
};

export default QuickSearchSelectInput;
