import React from 'react';
import PropTypes from 'prop-types';

import AddNewReligion from './AddNewReligion';
import actions from './redux/actions';
import { OptionsModal } from '../../../../Form/OptionsModal';
import { SelectPrivacy } from '../../../../Form/OptionsModal/DefaultFields';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const ReligionEditMode = ({ oReligionData }) => (
  <>
    {oReligionData && (
      <ProfileRecordLayout
        left={
          <div className='record t-paragraph--medium'>
            {oReligionData.DESCRIPTION}
          </div>
        }
        right={
          <OptionsModal
            name='Religion'
            data={oReligionData}
            actions={actions}
            submitButtonHidden
          >
            <SelectPrivacy data={oReligionData} isPrivacyChangeable={false} />
          </OptionsModal>
        }
      />
    )}
    <AddNewReligion data={oReligionData} />
  </>
);

ReligionEditMode.propTypes = {
  oReligionData: PropTypes.shape({
    ID: PropTypes.string,
    DESCRIPTION: PropTypes.string,
  }),
};

ReligionEditMode.defaultProps = {
  oReligionData: null,
};

export default ReligionEditMode;
