import React from 'react';
import { Formik, Form } from 'formik';

import {
  TextArea,
  TextField,
  DateField,
  DateRange,
  DollarAmountTextField,
  ToggleSwitch,
  CheckBox,
} from 'components/Form/Fields';
import DateSelectMMDD from 'components/Form/Fields/DateSelectMMDD';
import DateRangeMMDD from 'components/Form/Fields/DateRangeMMDD';
import { SubmitButton } from 'components/Form/Buttons';
import SelectFields from './SelectFields';
import Radios from './Radios';
import { oInitialValues, oSchema } from './helpers';

const FormFields = () => (
  <Formik
    initialValues={oInitialValues}
    validationSchema={oSchema}
    validateOnBlur={false}
  >
    <Form>
      <TextField
        sId='text-field'
        sName='textField'
        sLabel='TextField'
        sPlaceholder='Placeholder'
      />
      <TextField
        sId='text-field-disabled'
        sName='textFieldDisabled'
        sLabel='TextField - Disabled'
        sPlaceholder='Placeholder'
        bIsDisabled
      />
      <TextArea
        sId='text-area'
        sName='textArea'
        sLabel='TextArea'
        sPlaceholder='Placeholder'
      />
      <h3>Date Fields</h3>
      <DateField sId='date-field' sName='dateField' sLabel='Date Field' />
      <DateRange
        oStartDateProps={{
          sName: 'dateRangeStart',
          sLabel: 'Start Date',
          sId: 'start-date',
        }}
        oEndDateProps={{
          sName: 'dateRangeEnd',
          sLabel: 'End Date',
          sId: 'end-date',
        }}
      />
      <DateSelectMMDD
        groupLabel='DateSelectMMDD'
        monthFieldOptions={{
          id: 'start-month-select',
          label: 'Month',
          name: 'dateSelectMMDDMonth',
        }}
        dayFieldOptions={{
          id: 'start-day-select',
          label: 'Day',
          name: 'dateSelectMMDDDay',
        }}
      />
      <DateRangeMMDD sGroupLabel='DateRangeMMDD' />
      <DollarAmountTextField
        sId='dollar-amount'
        sName='dollarAmount'
        sLabel='DollarAmountTextField'
      />
      <SelectFields />
      <h3>Radio Buttons</h3>
      <Radios />
      <h3>Toggle Switch</h3>
      <ToggleSwitch sId='toggle-switch-on' sName='toggleSwitchOn' sLabel='On' />
      <ToggleSwitch
        sId='toggle-switch-off'
        sName='toggleSwitchOff'
        sLabel='Off'
      />
      <ToggleSwitch
        sId='toggle-switch-disabled'
        sName='toggleSwitchDisabled'
        sLabel='Disabled'
        bIsDisabled
      />
      <h3>Checkboxes</h3>
      <CheckBox sId='checkbox' sName='checkboxDefault' sLabel='Default' />
      <CheckBox
        sId='checkbox-selected'
        sName='checkboxSelected'
        sLabel='Selected'
      />
      <CheckBox
        sId='checkbox-disabled'
        sName='checkboxDisabled'
        sLabel='Disabled'
        bIsDisabled
      />
      <SubmitButton>Validate</SubmitButton>
    </Form>
  </Formik>
);

export default FormFields;
