import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import oIconLibrary from 'utils/iconLibrary';
import { exportCSVFile } from '../../../helpers';
import { fnBuildClaimedClassmatesCsv } from './helpers';

const ClaimedClassmatesOptionMenu = () => {
  const claimedClassmates = useSelector(
    (state) => state.Volunteers?.claimedClassmates || []
  );
  const [isOptionsMenuHidden, setIsOptionsMenuHidden] = useState(true);
  const [isCopiedToClipboardHidden, setIsCopiedToClipboardHidden] =
    useState(true);

  const sEmailAddresses = (claimedClassmates || [])
    .map((data) => data.EMAILADDRESS)
    .filter(Boolean)
    .join(',');

  const handleEmail = () => {
    setIsOptionsMenuHidden(true);
    setIsCopiedToClipboardHidden(false);
    setTimeout(() => {
      setIsCopiedToClipboardHidden(true);
    }, 1500);
    copy(sEmailAddresses);
  };

  const handleDownload = () => {
    setIsOptionsMenuHidden(true);
    const sFilename = 'MyClaimedClassmates';
    const oClaimedClassmatesToDownload =
      fnBuildClaimedClassmatesCsv(claimedClassmates);
    const sClaimedClassmatesToDownload = Papa.unparse(
      oClaimedClassmatesToDownload
    );
    exportCSVFile(sClaimedClassmatesToDownload, sFilename);
  };

  return (
    <div className='myClaimedClassmates__options'>
      <ButtonWithIcon
        sIcon={isOptionsMenuHidden ? 'expand' : 'collapse'}
        sIconPosition='right'
        className='myClaimedClassmates__optionsButtons'
        fnHandleClick={() => {
          setIsOptionsMenuHidden(!isOptionsMenuHidden);
        }}
      >
        Options
      </ButtonWithIcon>
      {!isOptionsMenuHidden && (
        <div className='myClaimedClassmates__optionsMenu'>
          <ButtonWithIcon
            sIcon='download'
            className='myClaimedClassmates__optionsButtons'
            fnHandleClick={handleDownload}
          >
            Download
          </ButtonWithIcon>
          <ButtonWithIcon
            sIcon='envelope'
            className='myClaimedClassmates__optionsButtons'
            fnHandleClick={handleEmail}
          >
            Copy Emails
          </ButtonWithIcon>
          <div
            className={`copiedToClipboard copiedToClipboard--below ${
              isCopiedToClipboardHidden ? '' : 'copiedToClipboard--show'
            }`}
          >
            <span className='copiedToClipboard__icon'>
              <FontAwesomeIcon icon={oIconLibrary.fontAwesome.check} />
            </span>
            <span className='copiedToClipboard__text'>
              Copied to clipboard!
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimedClassmatesOptionMenu;
