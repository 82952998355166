import { useEffect } from 'react';

/**
 * Formats the page title to include the site suffix and truncate
 * to the Google-recommended 60 characters max if necessary.
 *
 * @param {string} sPageTitle - Page title
 * @returns {string} - Formatted page title
 */
const fnFormatPageTitle = (sPageTitle) => {
  const sSuffix = ' - WakeNetwork';
  let sFormattedTitle = `${sPageTitle}${sSuffix}`;

  if (sFormattedTitle.length > 60) {
    // Subtract the length of the sSuffix and the ellipsis
    let iTruncateAt = 60 - sSuffix.length - 3;
    // Find the last space within the truncation limit
    iTruncateAt = sPageTitle.lastIndexOf(' ', iTruncateAt);
    // If a space was found, truncate at that space, otherwise truncate at the limit
    iTruncateAt = iTruncateAt > 0 ? iTruncateAt : 60 - sSuffix.length - 3;
    sFormattedTitle = `${sPageTitle.substring(0, iTruncateAt)}...${sSuffix}`;
  }

  return sFormattedTitle;
};

/**
 * Sets the page title.
 *
 * @param {string} sPageTitle - Page title
 */
const usePageTitle = (sPageTitle) => {
  useEffect(() => {
    if (sPageTitle) {
      document.title = fnFormatPageTitle(sPageTitle);
    }

    return () => {
      document.title = 'WakeNetwork';
    };
  }, [sPageTitle]);
};

export default usePageTitle;
