/**
 * Formats the transaction data so its ready to send to API endpoint.
 *
 * @param {object} aSummaryItems
 *
 * @example of aSummaryItems structure
 *  [
 *    {
 *      description: 'Pledge #11887783: The Wake Forest Fund'
 *      amount: 500,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 500,
 *    },
 *    {
 *      description: 'Pledge #11887783: The Wake Forest Fund - School of Business'
 *      amount: 400,
 *      hiddenCode: 'SBU01',
 *      hiddenName: 'The Wake Forest Fund - School of Business',
 *      hiddenAmount: 400,
 *    },
 *    {
 *      description: 'Pledge #11887783: The Wake Forest Fund - School of Law'
 *      amount: 300,
 *      hiddenCode: 'LSU01',
 *      hiddenName: 'The Wake Forest Fund - School of Law',
 *      hiddenAmount: 300,
 *    }
 *  ]
 *
 *  @example of return object
 *  [
 *    {
 *      description: 'Pledge #11887783: The Wake Forest Fund'
 *      amount: 500,
 *    },
 *    {
 *      description: 'Pledge #11887783: The Wake Forest Fund - School of Business'
 *      amount: 400,
 *    },
 *    {
 *      description: 'Pledge #11887783: The Wake Forest Fund - School of Law'
 *      amount: 300,
 *    }
 *  ]
 *
 * @return {object}
 */
const formatSummaryItems = (aSummaryItems) =>
  aSummaryItems.map((oSummaryItem) => ({
    description: oSummaryItem.description,
    amount: oSummaryItem.amount,
  }));

export default formatSummaryItems;
