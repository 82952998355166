import * as R from 'ramda';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';

/**
 * Selects notable affiliations (aka groups) data from Redux store.
 *
 * @param {object} oState - Redux state.
 * @returns {array} - Notable affiliations (aka groups).
 */
const fnRawAffiliationsSelector = (oState) =>
  oState.Profile.BIOGRAPHICAL.NOTABLEAFFILIATIONS;

/**
 * Separates groups into WFU-affiliated and non-WFU-affiliated lists.
 *
 * @param {array} - All groups.
 * @returns {object} - Separated groups. { wfu: [...], other: [...]}
 */
const fnSeparateWfuAndOtherGroups = R.groupBy((oGroup) =>
  oGroup.ISWFUAFFILIATION ? 'wfu' : 'other'
);

/**
 * Converts end date from human-readable format into a sortable value in milliseconds.
 *
 * @param {object} - Notable affiliation.
 * @returns {int} - Date in milliseconds.
 */
const fnGetSortableEndDateValue = R.compose((mEndDate) => {
  if (mEndDate) {
    return Date.parse(mEndDate);
  }
  // Undefined end dates mean user is currently an active member in this group.
  return Date.now();
}, R.prop('ENDDATE'));

/**
 * Sorts groups by end date first, then by organization name.
 *
 * @param {array} - Groups to sort.
 * @return {array} - Sorted groups.
 */
const fnSortByEndDateOrgName = R.sortWith([
  R.descend(fnGetSortableEndDateValue),
  R.ascend(R.prop('ORGANIZATIONNAME')),
]);

/**
 * Transforms group data for render.
 *
 * @param {array} aGroups - All groups from the Redux store.
 * @returns {object} - Separated, sorted group data.
 */
const fnTransformAffiliationsForRender = (aGroups) => {
  const oSeparatedGroups = fnSeparateWfuAndOtherGroups(aGroups);
  if (oSeparatedGroups.wfu) {
    oSeparatedGroups.wfu = fnSortByEndDateOrgName(oSeparatedGroups.wfu);
  }
  if (oSeparatedGroups.other) {
    oSeparatedGroups.other = fnSortByEndDateOrgName(oSeparatedGroups.other);
  }
  return oSeparatedGroups;
};

/**
 * Retrieves group data from Redux store and prepares it for render.
 */
export const fnAffiliationsSelector = createSelector(
  fnRawAffiliationsSelector,
  fnTransformAffiliationsForRender
);

export const fnHasFormerAffiliations = (aAffiliations) =>
  aAffiliations.some((oAffiliation) => !R.isEmpty(oAffiliation.ENDDATE));

export const fnGetCurrentAffiliations = (aAffiliations) =>
  aAffiliations.filter((oAffiliation) => R.isEmpty(oAffiliation.ENDDATE));

export const oGroupPropType = PropTypes.shape({
  ENDDATE: PropTypes.string,
  STARTDATE: PropTypes.string,
  ISWFUAFFILIATION: PropTypes.bool,
  ISLIFETIMEMEMBER: PropTypes.bool,
  ISFORMER: PropTypes.bool,
  ID: PropTypes.string,
  ORGANIZATIONNAME: PropTypes.string,
  POSITION: PropTypes.string,
  TYPE: PropTypes.string,
});
