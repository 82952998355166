import React from 'react';
import { useSelector } from 'react-redux';
import AlumniGoogleAccountActivated from './AlumniGoogleAccountActivated';
import AlumniGoogleAccountActivationForm from './AlumniGoogleAccountActivationForm';

const AlumniGoogleAccount = () => {
  const bHasAlumniGoogleAccount = useSelector(
    (state) => state.Profile.BIOGRAPHICAL.GOOGLEACCOUNTEXISTS
  );
  return (
    <>
      <h2 className='emailActivation__formTitle'>Alumni Gmail</h2>
      {bHasAlumniGoogleAccount ? (
        <AlumniGoogleAccountActivated />
      ) : (
        <AlumniGoogleAccountActivationForm />
      )}
    </>
  );
};

export default AlumniGoogleAccount;
