import React from 'react';

import smartphoneSvg from 'images/Icons/smartphone-white.svg';
import usersSvg from 'images/Icons/users-white.svg';
import thumbsUpSvg from 'images/Icons/thumbs-up-white.svg';

const LandingPageContent = () => (
  <div className='landingPage__content'>
    <div className='landingPage__contentItem'>
      <img
        src={smartphoneSvg}
        className='landingPage__contentIcon'
        alt='Smartphone icon'
      />
      <p className='landingPage__contentText t-paragraph--medium'>
        A mobile-friendly, digital platform designed by Deacs, for Deacs.
      </p>
    </div>
    <div className='landingPage__contentItem'>
      <img
        src={usersSvg}
        className='landingPage__contentIcon'
        alt='Users icon'
      />
      <p className='landingPage__contentText t-paragraph--medium'>
        Stay connected and build new relationships with fellow Wake Foresters
        across the globe.
      </p>
    </div>
    <div className='landingPage__contentItem'>
      <img
        src={thumbsUpSvg}
        className='landingPage__contentIcon'
        alt='Thumbs up icon'
      />
      <p className='landingPage__contentText t-paragraph--medium'>
        In less than a minute you could be browsing a vast database of Deacs.
        Jump in, and get connected!
      </p>
    </div>
  </div>
);

export default LandingPageContent;
