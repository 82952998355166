import { createSelector } from 'reselect';

import { selectAllEventsInCart } from 'components/EventRegistration/redux/selectors';
import { fnBuildEventRegNavSteps, fnGetNextEventRegStep } from './helpers';

/**
 * Generates an array of all the event registration navigation steps
 */
export const selectEventRegNavSteps = createSelector(
  selectAllEventsInCart,
  fnBuildEventRegNavSteps
);

/**
 * Returns the path for the next step in the event registration flow
 *
 * @param {string} sCurrentPath - window.location.pathname
 * @returns {string} - The path for the next step in the event registration flow
 *
 * @example
 * const fnNavigate = useNavigate();
 * const sNextStepUrl = useSelector((state) =>
 *  selectNextEventRegStep(state, window.location.pathname)
 * );
 * fnNavigate(sNextStepUrl);
 */
export const selectNextEventRegStep = createSelector(
  [selectEventRegNavSteps, (state, sCurrentPath) => sCurrentPath],
  fnGetNextEventRegStep
);
