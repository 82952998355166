import { object, string } from 'yup';

import { wfuGoogleUsernameValidator } from 'utils/yupHelpers';
import { fnIsPhoneNumberValidForRegion } from 'utils/common';

const emailActivationSchema = object({
  username: string()
    .required(
      "Please enter a username. You can use letters (a–z), numbers (0–9), and the following special characters: dashes (-), underscores (_), apostrophes ('), and periods (.). Please do not include spaces."
    )
    .test(
      'is-valid-wfu-google-username',
      "Please enter a username. You can use letters (a–z), numbers (0–9), and the following special characters: dashes (-), underscores (_), apostrophes ('), and periods (.). Please do not include spaces.",
      (username, oTestContext) => {
        if (!username) {
          return false;
        }
        const { bIsValid, sError } =
          wfuGoogleUsernameValidator.validate(username);
        if (!bIsValid) {
          return oTestContext.createError({ message: sError });
        }
        return bIsValid;
      }
    ),
  password: string()
    .min(5, 'Your password must be at least 5 characters long')
    .required('Please enter a password'),
  recoveryEmail: string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  recoveryPhoneCountry: object()
    .nullable()
    .required('Please choose a country.'),
  recoveryPhoneNumber: string().when(
    'recoveryPhoneCountry',
    ([recoveryPhoneCountry]) => {
      if ((recoveryPhoneCountry || {}).ABBRV) {
        return string()
          .required('Please enter a phone number')
          .test(
            'is-valid-phone-number',
            'Please enter a valid phone number for the selected region.',
            (sPhoneNumber) => {
              if (!sPhoneNumber) {
                return false;
              }
              const bIsPhoneNumberValidForRegion =
                fnIsPhoneNumberValidForRegion(
                  sPhoneNumber,
                  recoveryPhoneCountry.ABBRV
                );
              return bIsPhoneNumberValidForRegion;
            }
          );
      }
      return string().required('Please enter a phone number');
    }
  ),
});

export default emailActivationSchema;
