import qs from 'qs';

import oWnApi from 'utils/WnApi';
import { lookupIdMatcher } from 'utils/matchers';

export const fnGetEventStatus = async (
  sEventSlug,
  aGuests,
  bIsUserAuthenticated
) => {
  if (!sEventSlug) {
    return null;
  }
  const aGuestLookupIds = aGuests
    .filter((oGuest) => lookupIdMatcher.test(oGuest.LOOKUPID))
    .map((oGuest) => ({ LOOKUPID: oGuest.LOOKUPID }));
  const sQueryString = qs.stringify({
    registrantLookupIds: JSON.stringify(aGuestLookupIds),
  });
  const oResponse = await oWnApi.get(
    `events/${sEventSlug}/status?${sQueryString}`,
    bIsUserAuthenticated
  );
  if (oResponse.status !== 200) {
    console.error('Failed to retrieve event status', oResponse);
    return null;
  }
  return oResponse.data;
};

export default {};
