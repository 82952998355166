import React from 'react';
import PropTypes from 'prop-types';

import Highlight from 'components/Layout/Highlight';
import Accordion from '../../../Layout/Accordion';
import FundFinancesByQuarter from './FundFinancesByQuarter';
import AnnualFundFinances from './AnnualFundFinances';

const FundFinancialHistory = ({ aData, sFundType }) => (
  <Accordion
    mTitle='Financial Information'
    sSize='small'
    sGtmCategory='Philanthropy Report: Financial Information'
    className='fundFinancialHistory'
  >
    <div className='fundFinancialHistory__content'>
      {aData.map((oFiscalYear) => (
        <Accordion
          mTitle={`Fiscal Year ${oFiscalYear.FISCALYEAR}`}
          sSize='small'
          sGtmCategory='Philanthropy Report: Financial Information'
          key={oFiscalYear.FISCALYEAR}
        >
          <div className='fundFinancialHistory__fyContent'>
            {sFundType === 'Endowment' ? (
              <>
                <FundFinancesByQuarter
                  aFinances={oFiscalYear.VALUE_OVER_TIME}
                />
                <Highlight sFlavor='warning'>
                  Please note endowments are valued on a quarterly basis. Q1–Q3
                  of the financial data presented above represents the unaudited
                  ending market value of this endowed gift. Wake Forest
                  undergoes an external audit annually and as such the Q4 ending
                  market value is an audited amount.
                </Highlight>
              </>
            ) : (
              <AnnualFundFinances
                sBeginningValue={oFiscalYear.FISCAL_YEAR_BEGINNING_VALUE}
                sEndingValue={oFiscalYear.FISCAL_YEAR_ENDING_VALUE}
              />
            )}
          </div>
        </Accordion>
      ))}
    </div>
  </Accordion>
);

FundFinancialHistory.propTypes = {
  aData: PropTypes.arrayOf(
    PropTypes.shape({
      FISCALYEAR: PropTypes.string,
      VALUE_OVER_TIME: PropTypes.arrayOf(
        PropTypes.shape({
          INTERVAL: PropTypes.string,
          BEGINNING_VALUE: PropTypes.string,
          ENDING_VALUE: PropTypes.string,
        })
      ),
    })
  ),
  sFundType: PropTypes.string.isRequired,
};

FundFinancialHistory.defaultProps = {
  aData: [],
};

export default FundFinancialHistory;
