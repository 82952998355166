import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { TextField, DateRange, CheckBox } from 'components/Form/Fields';
import ButtonWithPopupMenu from 'components/MyProfile/elements/layout/ButtonWithPopupMenu';
import DeleteButton from 'components/Form/Buttons/DeleteButton';
import { useDispatch } from 'react-redux';
import ProfileRecordLayout from 'components/MyProfile/elements/layout/ProfileRecordLayout';
import actions from '../../redux/actions';
import SelectJobPrivacy from './SelectJobPrivacy';

const JobFieldSet = ({ iIndex }) => {
  // eslint-disable-next-line no-unused-vars
  const [oPositionsField, oPositionsMeta, oPositionsHelpers] =
    useField('POSITIONS');
  const fnDispatch = useDispatch();
  const oJobTitleFieldRef = useRef();

  // Auto-focus the first job's job title field on load
  useEffect(() => {
    if (iIndex === 0) {
      oJobTitleFieldRef.current.focus();
    }
  }, [iIndex, oJobTitleFieldRef]);

  const fnDeleteJob = async () => {
    await new Promise((resolve, reject) => {
      const sJobId = oPositionsField.value[iIndex].ID;
      if (sJobId) {
        // A job with an ID exists in DAD, so we need to delete it there.
        fnDispatch(
          actions.delete({
            data: { ID: sJobId },
            resolve,
            reject,
          })
        );
      } else {
        // A job without an ID only exists in Formik land, so remove it from the POSITIONS field.
        const [
          // eslint-disable-next-line no-unused-vars
          oPositionToDelete,
          ...aRemainingPositions
        ] = oPositionsField.value;
        oPositionsHelpers.setValue(aRemainingPositions);
      }
    });
  };

  return (
    <fieldset className='jobFieldSet'>
      <legend className='hide_accessibly'>
        {oPositionsMeta.initialValue[iIndex].JOBTITLE}
      </legend>
      <ProfileRecordLayout
        left={
          <div className='jobFieldSet__fields'>
            <TextField
              sId={`job-title-${iIndex}`}
              sName={`POSITIONS.${iIndex}.JOB_TITLE`}
              sLabel='Job Title'
              oFieldRef={oJobTitleFieldRef}
            />
            <DateRange
              oStartDateProps={{
                sName: `POSITIONS.${iIndex}.START_DATE`,
                sLabel: 'Start Date',
                sId: 'start-date',
              }}
              oEndDateProps={{
                sName: `POSITIONS.${iIndex}.END_DATE`,
                sLabel: 'End Date',
                sId: 'end-date',
              }}
            />
          </div>
        }
        right={
          <ButtonWithPopupMenu className='jobFieldSet__options'>
            <SelectJobPrivacy
              sFormikFieldName={`POSITIONS.${iIndex}.PRIVACY`}
              sRecordId={oPositionsField.value[iIndex].ID}
            />
            <div className='optionsModal__section'>
              <CheckBox
                sName={`POSITIONS.${iIndex}.IS_RETIRED`}
                sLabel="I'm Retired"
                sId='retired'
              />
            </div>
            <DeleteButton fnHandleClick={fnDeleteJob} sLabel='Delete Job' />
          </ButtonWithPopupMenu>
        }
      />
    </fieldset>
  );
};

JobFieldSet.propTypes = {
  iIndex: PropTypes.number.isRequired,
};

export default JobFieldSet;
