import React from 'react';

const TermsOfUse = () => (
  <div className='termsOfUse'>
    <p>
      We care about keeping your data safe. Wake Network is for official
      University and Alumni Association use and for individual communication of
      a personal nature among the alumni listed therein.
    </p>
    <p>
      Use of the information found in Wake Network for any other purpose,
      including but not limited to, reproducing and storing in a retrieval
      system by any means, electronic or mechanical, or the use of addresses
      (electronic or otherwise), telephone numbers, or other information
      contained therein for any private, commercial, or political mailing is
      strictly prohibited and constitutes misappropriation of proprietary
      information.
    </p>
    <p>
      Such unauthorized use may also violate the rights of privacy and/or
      publicity of individuals listed therein. Inappropriate use of the Wake
      Network may result in the complete revocation of your rights of access to
      Wake Network.
    </p>
  </div>
);

export default TermsOfUse;
