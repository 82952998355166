import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Main from 'components/Main';
import SignUp from 'components/Authentication/elements/SignUp';
import UserMatching from 'components/Authentication/elements/Matching';
import MatchValidation from 'components/Authentication/elements/Validation';
import SignUpHelp from 'components/Authentication/elements/Help';
import NotFound from 'components/NotFound';
import Settings from 'components/Settings';
import ContactUs from 'components/ContactUs';
import LandingPage from 'components/LandingPage';
import MagicLink from 'components/Authentication/elements/MagicLink';
import StyleGuide from 'components/StyleGuide';
import Volunteer from 'components/Volunteer';
import ComponentLibrary from 'components/ComponentLibrary';
import Downtime from 'components/Downtime';
import GivingCheckout from 'components/Philanthropy/MyGiving/Giving/GivingCheckout';
import GivingReview from 'components/Philanthropy/MyGiving/Giving/GivingReview';
import GivingConfirmation from 'components/Philanthropy/MyGiving/Giving/GivingConfirmation';
import EventDetailsPage from 'components/EventDetailsPage';
import GivingTrafficController from 'components/GivingTrafficController';
import EventRegistrationContainer from 'components/EventRegistration/EventRegistrationContainer';
import GuestManagement from 'components/EventRegistration/GuestManagement';
import RegistrationOptionsForm from 'components/EventRegistration/RegistrationOptionsForm';
import EventRegistrationCheckpoint from 'components/EventRegistration/EventRegistrationCheckpoint';
import ReviewRegistration from 'components/EventRegistration/ReviewRegistration';
import AdditionalInfo from 'components/EventRegistration/AdditionalInfo';
import EventRegistrationContactInfo from 'components/EventRegistration/EventRegistrationContactInfo';
import EventConfirmation from 'components/EventRegistration/EventConfirmation';
import EventRegistrationUserIdentification from 'components/EventRegistration/EventRegistrationUserIdentification';
import NotInvited from 'components/EventRegistration/NotInvited';
import EventWaitlist from 'components/EventRegistration/EventWaitlist';
import TermsOfUseAcceptancePage from '../components/Authentication/elements/TermsofUseAcceptancePage';
import Philanthropy from '../components/Philanthropy';
import Scholarships from '../components/Scholarships';
import ProfileExternalView from '../components/ProfileExternalView';
import PledgePaymentCheckout from '../components/Philanthropy/MyGiving/Pledges/Payments/PledgePaymentCheckout';
import PledgePaymentReview from '../components/Philanthropy/MyGiving/Pledges/Payments/PledgePaymentReview';
import PledgePaymentConfirmation from '../components/Philanthropy/MyGiving/Pledges/Payments/PledgePaymentConfirmation';
import RequireAuth from './RequireAuth';

const AppRoutes = ({ appProps }) => {
  const bIsAuthenticated = !!appProps.user;
  return (
    <Routes>
      <Route path='/' element={<Navigate to='main' />} />
      <Route path='/signin' element={<Navigate to='/sign-in' />} />
      <Route
        path='sign-in/:type?'
        element={
          <LandingPage aMaintenanceAlerts={appProps.aMaintenanceAlerts} />
        }
      />
      <Route
        path='giving-traffic-controller'
        element={<GivingTrafficController oUser={appProps.user} />}
      />
      <Route path='sign-up' element={<SignUp />} />
      <Route path='sign-up/match/:type' element={<UserMatching />} />
      <Route path='sign-up/magic-link/:type' element={<MagicLink />} />
      <Route path='sign-up/validation/:type' element={<MatchValidation />} />
      <Route
        path='sign-up/finalize-account'
        element={<TermsOfUseAcceptancePage />}
      />
      <Route path='sign-up/help' element={<SignUpHelp />} />
      <Route path='sign-up/help/:type' element={<SignUpHelp />} />
      <Route path='downtime' element={<Downtime />} />
      <Route
        path='main'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <Main />
          </RequireAuth>
        }
      >
        <Route
          path='profile/:lookupId'
          element={
            <RequireAuth bIsAuthenticated={bIsAuthenticated}>
              <ProfileExternalView />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path='main/search'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <Main />
          </RequireAuth>
        }
      />
      <Route
        path='events/:eventSlug'
        element={<EventDetailsPage bIsAuthenticated={bIsAuthenticated} />}
      >
        <Route path='profile/:lookupId' element={<ProfileExternalView />} />
      </Route>
      <Route
        path='events/:eventSlug/register'
        element={<EventRegistrationContainer />}
      >
        <Route path='waitlist' element={<EventWaitlist />} />
        <Route path='guests' element={<GuestManagement />} />
        <Route path='reg-options' element={<RegistrationOptionsForm />} />
        <Route path='checkpoint' element={<EventRegistrationCheckpoint />} />
      </Route>
      <Route path='events/register' element={<EventRegistrationContainer />}>
        <Route
          path='user-identification'
          element={<EventRegistrationUserIdentification />}
        />
        <Route path='checkpoint' element={<EventRegistrationCheckpoint />} />
        <Route path='additional-info' element={<AdditionalInfo />} />
        <Route path='contact' element={<EventRegistrationContactInfo />} />
        <Route path='review' element={<ReviewRegistration />} />
        <Route path='confirmation' element={<EventConfirmation />} />
      </Route>
      <Route path='events/register/invite-only' element={<NotInvited />} />
      <Route
        path='philanthropy'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <Philanthropy />
          </RequireAuth>
        }
      >
        <Route
          path='profile/:lookupId'
          element={
            <RequireAuth bIsAuthenticated={bIsAuthenticated}>
              <ProfileExternalView />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path='philanthropy/giving'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <GivingCheckout />
          </RequireAuth>
        }
      />
      <Route
        path='philanthropy/giving/review'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <GivingReview />
          </RequireAuth>
        }
      />
      <Route
        path='philanthropy/giving/confirmation'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <GivingConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path='philanthropy/commitment-payment'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <PledgePaymentCheckout />
          </RequireAuth>
        }
      />
      <Route
        path='philanthropy/commitment-payment/review'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <PledgePaymentReview />
          </RequireAuth>
        }
      />
      <Route
        path='philanthropy/commitment-payment/confirmation'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <PledgePaymentConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path='scholarships'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <Scholarships />
          </RequireAuth>
        }
      >
        <Route
          path='profile/:lookupId'
          element={
            <RequireAuth bIsAuthenticated={bIsAuthenticated}>
              <ProfileExternalView />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path='volunteers'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <Volunteer />
          </RequireAuth>
        }
      >
        <Route
          path='profile/:lookupId'
          element={
            <RequireAuth bIsAuthenticated={bIsAuthenticated}>
              <ProfileExternalView />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path='settings'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <Settings />
          </RequireAuth>
        }
      />
      <Route
        path='contact-us'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <ContactUs />
          </RequireAuth>
        }
      />
      <Route
        path='style-guide'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <StyleGuide />
          </RequireAuth>
        }
      />
      <Route
        path='component-library'
        element={
          <RequireAuth bIsAuthenticated={bIsAuthenticated}>
            <ComponentLibrary />
          </RequireAuth>
        }
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

AppRoutes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appProps: PropTypes.object.isRequired,
};

export default AppRoutes;
