import { SkeletonText } from 'components/LoadingZone/Skeleton';
import React from 'react';

const EventDetailsPageSkeleton = () => (
  <div className='eventDetailsPageSkeleton'>
    <div className='eventDateBlock' />
    <SkeletonText size='large' width='80%' />
    <SkeletonText size='medium' width='30%' />
    <SkeletonText size='medium' width='30%' />
    <hr />
    <SkeletonText size='small' width='60%' />
    <SkeletonText size='small' width='40%' />
    <SkeletonText size='small' width='60%' />
  </div>
);

export default EventDetailsPageSkeleton;
