import React from 'react';

import deaconIllustration from 'components/NotFound/deacon404.png';
import Button from 'components/Buttons/Button';
import BaseErrorBoundary from './BaseErrorBoundary';

/**
 *  Error boundary that displays a page-sized error message
 *  if any child component throws an error.
 *
 * See https://reactjs.org/docs/error-boundaries.html for details.
 *
 * @example
 * <PageErrorBoundary>
 *     <ImportantRoute /> // Errors here stop the app and show the error UI instead
 * </PageErrorBoundary>
 */
class PageErrorBoundary extends BaseErrorBoundary {
  handleButtonClick = () => {
    this.resetError();
    this.props.fnNavigate('/main');
  };

  render() {
    const { bHasError } = this.state;

    if (bHasError) {
      return (
        <div className='pageErrorBoundary'>
          <img
            src={deaconIllustration}
            className='pageErrorBoundary__image'
            alt='The Demon Deacon sitting at a computer keyboard while surrounded by browser windows. The text above his head reads, "Hmm, something is not working."'
          />
          <h1 className='pageErrorBoundary__title'>Something went wrong</h1>
          <p className='pageErrorBoundary__details t-paragraph--large'>
            Please refresh the page and try again, or click the button below to
            return to the home page.
          </p>
          <Button fnHandleClick={this.handleButtonClick}>
            Return to the home page
          </Button>
          <p className='pageErrorBoundary__details t-paragraph--small'>
            If this problem persists, please contact us at{' '}
            <a
              href='mailto:wakenetwork.help@wfu.edu'
              className='t-paragraph--small'
            >
              wakenetwork.help@wfu.edu
            </a>
            .
          </p>
        </div>
      );
    }

    const { children } = this.props;

    return children;
  }
}

export default PageErrorBoundary;
