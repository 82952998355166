import { createActions } from 'redux-actions';

export default createActions(
  {
    GET: (email) => email,
    SET: (email) => email,
    CREATE: (email) => email,
    DELETE: (email) => email,
    UPDATE: (email) => email,
    ADD_NOTE: (state) => state,
  },
  {
    prefix: 'Profile/EMAIL',
  }
);
