import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import GroupRecord from './GroupRecord';
import EditableGroupRecord from './EditableGroupRecord';
import ProfileSubsectionLayout from '../layout/ProfileSubsectionLayout';
import ProfileRecordLayout from '../layout/ProfileRecordLayout';
import { fnHasFormerAffiliations, fnGetCurrentAffiliations } from './helpers';

const WfuGroups = ({ aData }) => {
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditingWfuGroups, fnSetIsEditingWfuGroups] = useState(false);
  const [bShowFormer, fnSetShowFormer] = useState(false);

  useEffect(() => {
    fnSetIsEditingWfuGroups(activeFormSection === 'notableAffiliations');
  }, [activeFormSection]);

  const bShowToggleFormerButton =
    !bIsEditingWfuGroups && fnHasFormerAffiliations(aData);

  const fnToggleFormer = () => {
    fnSetShowFormer(!bShowFormer);
  };

  const aCurrentAffiliations = fnGetCurrentAffiliations(aData);

  return (
    <ProfileSubsectionLayout
      sTitle='Notable Wake Forest Affiliations'
      left={
        bShowToggleFormerButton && (
          <ViewMoreButton
            fnOnClick={fnToggleFormer}
            bIsShowingMore={bShowFormer}
            sViewMoreLabel='Show Former'
            sViewLessLabel='Hide Former'
          />
        )
      }
      right={
        bIsEditingWfuGroups ? (
          aData.map((oGroup) => {
            const sGroupId = oGroup.ID + oGroup.POSITION;
            return <EditableGroupRecord oData={oGroup} key={sGroupId} />;
          })
        ) : (
          <>
            {aCurrentAffiliations.length === 0 && (
              <p>No current known affiliations</p>
            )}
            {(bShowFormer ? aData : aCurrentAffiliations).map((oGroup) => (
              <ProfileRecordLayout
                key={oGroup.ID}
                left={<GroupRecord oData={oGroup} />}
              />
            ))}
          </>
        )
      }
    />
  );
};

WfuGroups.propTypes = {
  aData: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      POSITION: PropTypes.string,
    })
  ),
};

WfuGroups.defaultProps = {
  aData: [],
};

export default WfuGroups;
