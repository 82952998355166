import currency from 'currency.js';

import { fnGroupByBalance } from 'components/Philanthropy/MyGiving/Pledges/helpers';
import { calculateMonetarySumByKey } from 'utils/common';

/**
 * Determines the upcoming fiscal year end date.
 *
 * @returns {object}
 */
export const determineFiscalYearEndDate = () => {
  const oToday = new Date();
  let iFiscalYearEndingYear = oToday.getFullYear();
  // On July 1, the fiscal year's ending year is in the next calendar year
  if (oToday.getMonth() > 5) {
    iFiscalYearEndingYear += 1;
  }
  return new Date(`June 30, ${iFiscalYearEndingYear} 23:59:59`);
};

/**
 * Retrieves all unpaid installments for specified pledge.
 *
 * @param {object} oPledgeData - DAD data for a specific pledge.
 *
 * @returns {array}
 */
export const retrieveUnpaidPledgeInstallments = (oPledgeData) => {
  const oInstallmentsByBalance = fnGroupByBalance(oPledgeData.INSTALLMENTS);
  return oInstallmentsByBalance.Unpaid || [];
};

/**
 * Retrieves all unpaid installments for specified pledge that are due now.
 *
 * The DUE_DATE include the 'T00:00:00' part.
 *
 * @param {object} oPledgeData - DAD data for a specific pledge.
 * @param {object} oFiscalYearEndDate - Date object for current fiscal year end.
 *
 * @returns {array}
 */
export const retrieveDueUnpaidPledgeInstallments = (
  oPledgeData,
  oFiscalYearEndDate
) => {
  const aUnpaidPledgeInstallments =
    retrieveUnpaidPledgeInstallments(oPledgeData);
  return aUnpaidPledgeInstallments.filter((oUnpaidInstallment) => {
    const oInstallmentDueData = new Date(`${oUnpaidInstallment.DUE_DATE}`);
    return oInstallmentDueData <= oFiscalYearEndDate;
  });
};

/**
 * Calculates the current due for all payable pledges.
 *
 * @param {array} aPayablePledges - Data for all payable pledges.
 *
 * @returns {object}
 */
export const calculateCurrentDuePayablePledges = (aPayablePledges) => {
  const oFiscalYearEndDate = determineFiscalYearEndDate();
  return aPayablePledges.reduce((oAccumulator, oPledgeData) => {
    const aDueUnpaidPledgeInstallments = retrieveDueUnpaidPledgeInstallments(
      oPledgeData,
      oFiscalYearEndDate
    );
    const oPledgeCurrentDue = calculateMonetarySumByKey(
      aDueUnpaidPledgeInstallments,
      'BALANCE'
    );
    return oAccumulator.add(oPledgeCurrentDue);
  }, currency(0));
};

/**
 * Calculates the total balance for all payable pledges.
 *
 * @param {array} aPayablePledges - Data for all payable pledges.
 *
 * @returns {object}
 */
export const calculateTotalBalancePayablePledges = (aPayablePledges = []) => {
  const oTotal = aPayablePledges.reduce((oAccumulator, oPledgeData) => {
    const aUnpaidPledgeInstallments =
      retrieveUnpaidPledgeInstallments(oPledgeData);
    const oPledgeBalance = calculateMonetarySumByKey(
      aUnpaidPledgeInstallments,
      'BALANCE'
    );
    return oAccumulator.add(oPledgeBalance);
  }, currency(0));
  return oTotal;
};

/**
 * Calculates the total pending transaction amount for all payable pledges.
 *
 * @param {array} aPayablePledges - Data for all payable pledges.
 *
 * @returns {object}
 */
export const calculatePendingBalance = (aPayablePledges = []) => {
  const oPendingTotal = aPayablePledges.reduce((oTotal, oPledge) => {
    const oPending = currency(oPledge.PENDING);
    return oTotal.add(oPending);
  }, currency(0));
  return oPendingTotal;
};
