import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import { fnAffiliationsSelector } from './helpers';
import WfuGroups from './WfuGroups';
import OtherGroups from './OtherGroups';
import ProfileSection from '../layout/ProfileSection';

const Groups = () => {
  const oGroups = useSelector(fnAffiliationsSelector);
  const [activeFormSection] = useActiveFormSection();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(activeFormSection === 'notableAffiliations');
  }, [activeFormSection]);

  return (
    <ProfileSection
      className='recordLabel'
      sTitle='Affiliations'
      sName='notableAffiliations'
      bIsEditable
    >
      {oGroups.wfu && <WfuGroups aData={oGroups.wfu} />}
      {(isActive || oGroups.other) && (
        <OtherGroups aGroupData={oGroups.other} />
      )}
    </ProfileSection>
  );
};

export default Groups;
