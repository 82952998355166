import { object, array, string, boolean } from 'yup';

import { coerceToArray, coerceToString } from 'utils/yupHelpers';

const scholarshipsSchema = object({
  RECIPIENTDATA: object({
    BIOGRAPHY: object({
      NOTEID: string(),
      NOTE: string().transform(coerceToString),
      DATEADDED: string(),
    }).nullable(),
    SCHOLARSHIPS: array()
      .of(
        object({
          FUNDNAME: string(),
          FUNDPURPOSEID: string(),
          FUNDTHANKYOUNOTES: object({
            ISREVIEWED: boolean(),
            NOTE: string().transform(coerceToString),
          }),
          FUNDAWARDS: array()
            .of(
              object({
                FISCALYEAR: string(),
                AMOUNT: string(),
              })
            )
            .transform(coerceToArray)
            .ensure(),
          FUND_DONORS: array()
            .of(
              object({
                NAME: string(),
                FIRSTNAME: string().transform(coerceToString),
                LASTNAME: string().transform(coerceToString),
                LOOKUPID: string(),
                HAS_PHOTO: boolean(),
                IS_FOUNDATION: boolean(),
                FOUNDATION_URL: string().transform(coerceToString),
              })
            )
            .transform(coerceToArray)
            .ensure(),
          FUNDDETAILS: object({
            PURPOSE: string().transform(coerceToString),
            INHONORORMEMORYOF: string().transform(coerceToString),
          }).nullable(),
        })
      )
      .transform(coerceToArray)
      .ensure(),
  }),
});

export default scholarshipsSchema;
