import React from 'react';
import PropTypes from 'prop-types';

import { OptionsModal } from '../../../../Form/OptionsModal';
import { SelectPrivacy } from '../../../../Form/OptionsModal/DefaultFields/SelectPrivacy';
import actions from './redux/actions';
import { fnFormatDegreeText } from './helpers';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const DegreesEditMode = ({ aDegrees }) =>
  aDegrees.map((oDegree) => (
    <ProfileSubsectionLayout
      key={oDegree.ID}
      sTitle={oDegree.DEGREELEVEL}
      right={
        <ProfileRecordLayout
          left={
            <div className='record t-paragraph--medium'>
              {fnFormatDegreeText(oDegree)}
            </div>
          }
          right={
            <OptionsModal name='Education' data={oDegree} actions={actions}>
              <SelectPrivacy data={oDegree} />
            </OptionsModal>
          }
        />
      }
    />
  ));

DegreesEditMode.propTypes = {
  aDegrees: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      DEGREELEVEL: PropTypes.string,
    })
  ),
};

DegreesEditMode.defaultProps = {
  aDegrees: [],
};

export default DegreesEditMode;
