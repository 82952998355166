import * as R from 'ramda';

import { oEmptyAdvancedSearch } from 'components/Search/helpers';
import { fnFuzzySearch, fnFindItemByIdOrDescription } from 'utils/common';
import { fnCallCloudSearch } from 'components/Form/Fields/Select/SelectEmployerField/cloudSearchHelpers';

/**
 * Transforms human-friendly query params into a format
 * that the advanced search Formik form can process.
 */
export default class QueryParamsToAdvancedSearchTransformer {
  oQueryParams = null;
  oTransformed = R.clone(oEmptyAdvancedSearch);

  /**
   * @param {array} .aRegions - REGION code table values
   * @param {array} .aCountries - COUNTRY code table values
   * @param {array} .aEducPrograms - EDUCATIONALPROGRAM code table values
   * @param {array} .aInvolvements - INVOLVEMENT code table values
   * @param {array} .aEducDepts - EDUCATIONALDEPARTMENT code table values
   * @param {array} .aAffinityGroups - AFFINITY_GROUP code table values
   * @param {array} .aSkills - JOB_SPECIALTY code table values
   */
  constructor({
    aRegions = [],
    aCountries = [],
    aEducPrograms = [],
    aInvolvements = [],
    aEducDepts = [],
    aAffinityGroups = [],
    aSkills = [],
  }) {
    this.aRegions = aRegions;
    this.aCountries = aCountries;
    this.aEducPrograms = aEducPrograms;
    this.aInvolvements = aInvolvements;
    this.aEducDepts = aEducDepts;
    this.aAffinityGroups = aAffinityGroups;
    this.aSkills = aSkills;
  }

  async transform(oQueryParams) {
    if (R.isEmpty(oQueryParams)) {
      return this.oTransformed;
    }

    const {
      'first-name': sFirstName,
      'last-name': sLastName,
      city,
      state,
      country,
      'class-of': sClassOf,
      employer,
      'include-former-employers': sIncludeFormerEmployers,
    } = oQueryParams;

    // Transform the simple advanced search items
    this.oTransformed.firstname = sFirstName || '';
    this.oTransformed.lastname = sLastName || '';
    this.oTransformed.city = city || '';
    if (sClassOf) {
      this.oTransformed.classof = {
        DESCRIPTION: sClassOf,
      };
    }
    if (sIncludeFormerEmployers) {
      this.oTransformed.includeformeremployers =
        sIncludeFormerEmployers === 'true';
    }

    const aPromises = [];

    // Find a matching code table object for the remaining advanced search items
    if (state) {
      aPromises.push(this.transformState(state));
    }
    if (country) {
      aPromises.push(this.transformCountry(country));
    }
    if (employer) {
      aPromises.push(this.transformEmployer(employer));
    }
    const aSeekAndFind = this.buildSeekAndFindList(oQueryParams);
    aSeekAndFind.forEach(({ sOriginalValue, aSearchList, sTargetKey }) => {
      const oPromise = fnFindItemByIdOrDescription(
        sOriginalValue,
        aSearchList
      ).then((oSearchResult) => {
        if (oSearchResult) {
          this.oTransformed[sTargetKey] = oSearchResult;
        }
      });
      aPromises.push(oPromise);
    });

    await Promise.all(aPromises);
    return this.oTransformed;
  }

  async transformState(sState) {
    // Accept state abbreviations like NC
    if (sState.length === 2) {
      const aSearchResults = fnFuzzySearch(
        this.aRegions,
        'DETAILS.NAME_ABBRV',
        sState,
        1
      );
      if (aSearchResults.length > 0) {
        this.oTransformed.state = aSearchResults[0].obj;
      }
    } else {
      this.oTransformed.state = await fnFindItemByIdOrDescription(
        sState,
        this.aRegions
      );
    }
  }

  async transformCountry(sCountry) {
    // Accept country abbreviations like US
    if (sCountry.length === 2) {
      const aSearchResults = await fnFuzzySearch(
        this.aCountries,
        'DETAILS.NAME_ABBRV',
        sCountry,
        1
      );
      if (aSearchResults.length > 0) {
        this.oTransformed.country = aSearchResults[0].obj;
      }
    } else {
      this.oTransformed.country = await fnFindItemByIdOrDescription(
        sCountry,
        this.aCountries
      );
    }
  }

  async transformEmployer(sEmployer) {
    const aSearchResults = await new Promise((resolve) => {
      fnCallCloudSearch(sEmployer, resolve);
    });
    if (aSearchResults.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.oTransformed.employer = aSearchResults[0];
    }
  }

  buildSeekAndFindList(oQueryParams) {
    const {
      'academic-program': sAcademicProgram,
      'club-org': sClubOrg,
      'area-of-study': sAreaOfStudy,
      'affinity-group': sAffinityGroup,
      'job-skill': sJobSkill,
    } = oQueryParams;

    const aSeekAndFind = [];

    if (sAcademicProgram) {
      aSeekAndFind.push({
        sOriginalValue: sAcademicProgram,
        aSearchList: this.aEducPrograms,
        sTargetKey: 'educationalprogram',
      });
    }
    if (sClubOrg) {
      aSeekAndFind.push({
        sOriginalValue: sClubOrg,
        aSearchList: this.aInvolvements,
        sTargetKey: 'educationalinvolvement',
      });
    }
    if (sAreaOfStudy) {
      aSeekAndFind.push({
        sOriginalValue: sAreaOfStudy,
        aSearchList: this.aEducDepts,
        sTargetKey: 'educationaldepartment',
      });
    }
    if (sAffinityGroup) {
      aSeekAndFind.push({
        sOriginalValue: sAffinityGroup,
        aSearchList: this.aAffinityGroups,
        sTargetKey: 'affinitygroup',
      });
    }
    if (sJobSkill) {
      aSeekAndFind.push({
        sOriginalValue: sJobSkill,
        aSearchList: this.aSkills,
        sTargetKey: 'industry',
      });
    }
    return aSeekAndFind;
  }
}
