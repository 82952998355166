import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
} from 'redux/selectors';

export const useFormattedCartData = ({ aCartGifts, nCartTotal }) => {
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const oName = useSelector(currentUserFirstLastNameSelector);
  const oCheckoutData = useSelector((state) => state.Checkout);
  const [oDadData, fnSetDadData] = useState(null);
  const [oHiddenFormData, fnSetHiddenFormData] = useState(null);

  // Format the cart data for DAD
  useEffect(() => {
    if (aCartGifts && nCartTotal) {
      const aFormattedGifts = aCartGifts.map((oGift) => ({
        CODE: oGift.oDetails.FUND.DETAILS.CODE,
        AMOUNT: oGift.nPrice,
      }));
      const oDataForDad = {
        TOTAL_TRANS_AMOUNT: nCartTotal,
        DONATION: {
          AMOUNT: nCartTotal,
          DESIGNATIONS: aFormattedGifts,
        },
      };
      fnSetDadData(oDataForDad);
    }
  }, [nCartTotal, aCartGifts]);

  // Format the cart data for the hidden form that submits data to the payment step
  useEffect(() => {
    const aFormatted = aCartGifts.map((oGift) => ({
      id: oGift.oDetails.FUND.DETAILS.CODE,
      name: oGift.oDetails.FUND.DESCRIPTION,
      quantity: oGift.nPrice,
    }));
    const sStage = process.env.REACT_APP_STAGE;
    const sEmailAddress =
      sStage === 'prod' ? oCheckoutData.email : 'ait-developers@wfu.edu';
    const oHidden = {
      lookup_id: sLookupId,
      contact: {
        first_name: oName.sFirstName,
        last_name: oName.sLastName,
        email_address: sEmailAddress,
      },
      donation_amount: nCartTotal,
      items: {
        designation: aFormatted,
      },
    };
    fnSetHiddenFormData(oHidden);
  }, [aCartGifts, nCartTotal, oName, sLookupId, oCheckoutData]);

  return { oDadData, oHiddenFormData };
};

export default {};
