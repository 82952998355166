import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import ConstituentAvatar from '../../../../ConstituentAvatar';
import ClaimedClassmatesEllipsisMenu from './ClaimedClassmatesEllipsisMenu';
import Star from '../../../images/svg/Star.svg';
import EmailIcon from '../../../../../images/Icons/wn-email.svg';
import PhoneIcon from '../../../../../images/Icons/wn-phone.svg';
import ClubsOrgsIcon from '../../../../../images/Icons/wn-clubs-orgs.svg';
import HomecomingYesIcon from '../../../../../images/Icons/wn-homecoming-yes.svg';
import HomecomingNoIcon from '../../../../../images/Icons/wn-homecoming-no.svg';
import ReunionDonorYesIcon from '../../../../../images/Icons/wn-reunion-donor-yes.svg';
import ReunionDonorNoIcon from '../../../../../images/Icons/wn-reunion-donor-no.svg';
import { buildCityStateEmployerString } from '../../../../../utils/common';
import LinkWithSourcePath from '../../../../../utils/LinkWithSourcePath';

const ClaimedClassmateRow = ({ data }) => {
  const [isCopiedToClipboardVisible, setIsCopiedToClipboardVisible] =
    useState(false);

  const copyToClipboard = (claimedClassmateFieldValue, e) => {
    // preventDefault prevents the page scroll top from resetting to 0
    e.preventDefault();

    setIsCopiedToClipboardVisible(true);
    setTimeout(() => {
      setIsCopiedToClipboardVisible(false);
    }, 1500);
    copy(claimedClassmateFieldValue);
  };

  const strCityStateEmployer = buildCityStateEmployerString(data);

  return (
    <>
      <div className='myClaimedClassmates__avatar'>
        <LinkWithSourcePath
          className='myClaimedClassmates__name'
          to={`/volunteers/profile/${data.PROSPECTID}`}
          referringComponent='ClaimedClassmates'
        >
          <ConstituentAvatar
            lookupId={data.PROSPECTID}
            sFirstName={data.NICKNAME}
            sLastName={data.LASTNAME}
            bHasPhoto={data.HAS_PHOTO}
            sSize='large'
          />
        </LinkWithSourcePath>
      </div>

      <div className='myClaimedClassmates__detailsWrapper'>
        <div className='myClaimedClassmates__nameWrapper'>
          <LinkWithSourcePath
            className='myClaimedClassmates__name'
            to={`/volunteers/profile/${data.PROSPECTID}`}
            referringComponent='ClaimedClassmates'
          >
            {data.PROSPECTNAME}
          </LinkWithSourcePath>
          {/* @TODO Replace with with iconLibrary icon. */}
          <img className='iStar' src={Star} alt='' />
        </div>
        <div className='cityStateEmployer'>{strCityStateEmployer}</div>
        <div className='myClaimedClassmates__detailsContent'>
          {data.EMAILADDRESS ? (
            <>
              {/* @TODO Replace with with iconLibrary icon. */}
              <img src={EmailIcon} alt='Email' />
              <div className='myClaimedClassmates__detailsTextWrap'>
                <a
                  href='#top'
                  onClick={(e) => {
                    copyToClipboard(data.EMAILADDRESS, e);
                  }}
                  className='myClaimedClassmates__detailsText myClaimedClassmates__detailsText--email'
                >
                  {data.EMAILADDRESS}
                </a>
              </div>
            </>
          ) : null}

          {data.PHONENUMBER && (
            <>
              {/* @TODO Replace with with iconLibrary icon. */}
              <img src={PhoneIcon} alt='Phone Number' />
              <div className='myClaimedClassmates__detailsTextWrap'>
                <a
                  href='#top'
                  onClick={(e) => {
                    copyToClipboard(data.PHONENUMBER, e);
                  }}
                  className='myClaimedClassmates__detailsText'
                >
                  {data.PHONENUMBER}
                </a>
              </div>
            </>
          )}

          {data.INVOLVEMENTS && (
            <>
              {/* @TODO Replace with with iconLibrary icon. */}
              <img src={ClubsOrgsIcon} alt='Clubs and organizations' />
              <div className='myClaimedClassmates__detailsTextWrap'>
                <span className='myClaimedClassmates__detailsText'>
                  {data.INVOLVEMENTS}
                </span>
              </div>
            </>
          )}
          {/* @TODO Replace with with iconLibrary icon. */}
          <img
            src={
              data.HOMECOMINGATTENDANCESTATUS === 'Registered'
                ? HomecomingYesIcon
                : HomecomingNoIcon
            }
            alt={`Clipboard with ${
              data.HOMECOMINGATTENDANCESTATUS ? 'a check mark' : 'an x'
            }`}
          />
          <div className='myClaimedClassmates__detailsTextWrap'>
            <span className='myClaimedClassmates__detailsText'>
              {`Registered for Homecoming: ${
                data.HOMECOMINGATTENDANCESTATUS === 'Registered' ? 'Yes' : 'No'
              }`}
            </span>
          </div>
          {/* @TODO Replace with with iconLibrary icon. */}
          <img
            src={data.REUNIONDONOR ? ReunionDonorYesIcon : ReunionDonorNoIcon}
            alt={`Calendar with ${data.REUNIONDONOR ? 'a check mark' : 'an x'}`}
          />
          <div className='myClaimedClassmates__detailsTextWrap'>
            <span className='myClaimedClassmates__detailsText'>
              {`Reunion Donor: ${data.REUNIONDONOR ? 'Yes' : 'No'}`}
            </span>
          </div>

          <div
            className={`copiedToClipboard copiedToClipboard--centered ${
              isCopiedToClipboardVisible ? 'copiedToClipboard--show' : ''
            }`}
          >
            {/* @TODO Replace with with iconLibrary icon. */}
            <span className='copiedToClipboard__icon'>
              <FontAwesomeIcon icon={oIconLibrary.fontAwesome.check} />
            </span>
            <span className='copiedToClipboard__text'>
              Copied to clipboard!
            </span>
          </div>
        </div>
      </div>
      <ClaimedClassmatesEllipsisMenu classmateLookupId={data.PROSPECTID} />
    </>
  );
};

ClaimedClassmateRow.propTypes = {
  data: PropTypes.object,
};

ClaimedClassmateRow.defaultProps = {
  data: {},
};

export default ClaimedClassmateRow;
