import { put, takeEvery, select } from 'redux-saga/effects';

import { toPascalCase } from 'utils/common';
import oWnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';
import fnParseActionType from './fnParseActionType';
import userProfileNormalizer from './apiDataNormalizers';
import * as profileActions from './actions';

const aMyNetworkReduxSubKeys = ['SPOUSE', 'CHILD', 'PARENT', 'WFUFAMILY'];

/**
 * Handles Profile\/GET redux actions.
 *
 * @param {object} oAction - Incoming Redux action.
 * @param {object} oAction.payload - Redux action payload.
 * @param {object} oAction.payload.data - Profile data to create.
 * @param {function} oAction.payload.resolve - Promise resolve function to tell code that dispatched Redux action when it's done.
 * @param {function} oAction.payload.reject - Promise reject function to tell code that dispatched Redux action that something failed.
 */
function* getProfile({ payload: { data, resolve, reject } }) {
  const sRequestPath = `constituents/${data.lookupId}`;
  const oResponse = yield oWnApi.get(sRequestPath, data.bIsUserAuthenticated);
  if (oResponse.status === 200) {
    const oProfile = yield userProfileNormalizer.validate(oResponse.data.DATA);
    yield put(profileActions.set(oProfile));
    resolve('Success');
  } else {
    reject('Error');
  }
}

/**
 * Handles Profile\/*\/CREATE redux actions.
 *
 * @param {object} oAction - Incoming Redux action.
 * @param {object} oAction.payload - Redux action payload.
 * @param {object} oAction.payload.data - Profile data to create.
 * @param {function} oAction.payload.resolve - Promise resolve function to tell code that dispatched Redux action when it's done.
 * @param {function} oAction.payload.reject - Promise reject function to tell code that dispatched Redux action that something failed.
 * @param {string} oAction.type - Redux action type.
 */
function* createProfileData({ payload: { data, resolve, reject }, type }) {
  const { sReduxStoreSubKey, sDadDataType, sApiResourceName } =
    fnParseActionType(type);
  const { ID, ...oDataWithoutId } = data;
  const oRequestPayload = {
    ID: ID || '00000000-0000-0000-0000-000000000000',
    TYPE: sDadDataType,
    data: oDataWithoutId,
  };
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestPath = `constituents/${sLookupId}/${sApiResourceName}`;
  const oResponse = yield oWnApi.post(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    // New BIOGRAPHICAL data, like religion, is nested within the BIOGRAPHICAL block.
    // So, we need to validate the schema at BIOGRAPHICAL instead of at the specific endpoint, ex: RELIGION.
    const sKeyToValidateAt =
      sReduxStoreSubKey === 'BIOGRAPHICAL' ? sReduxStoreSubKey : sDadDataType;
    const oValidatedResponseData = yield userProfileNormalizer.validateAt(
      sKeyToValidateAt,
      oResponse.data.DATA
    );
    yield put(
      profileActions.update({ [sReduxStoreSubKey]: oValidatedResponseData })
    );

    if (aMyNetworkReduxSubKeys.includes(sReduxStoreSubKey)) {
      yield put(
        profileActions.get({
          data: {
            lookupId: sLookupId,
          },
          resolve: () => {},
          reject: () => {},
        })
      );
    }

    resolve('Success');
  } else {
    reject('Error');
  }
}

/**
 * Handles Profile\/*\/ADD_NOTE redux actions.
 *
 * @param {object} oAction - Incoming Redux action.
 * @param {object} oAction.payload - Redux action payload.
 * @param {object} oAction.payload.data - Note content.
 * @param {function} oAction.payload.resolve - Promise resolve function to tell code that dispatched Redux action when it's done.
 * @param {function} oAction.payload.reject - Promise reject function to tell code that dispatched Redux action that something failed.
 * @param {string} oAction.type - Redux action type.
 */
function* createNote({ payload: { data, resolve, reject }, type }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestPath = `constituents/${sLookupId}/add_note`;
  const { sDadDataType } = fnParseActionType(type);
  const sTitle = `${toPascalCase(sDadDataType)} Note`;
  const oRequestPayload = {
    TYPE: 'Note',
    data: {
      TITLE: sTitle,
      ...data,
    },
  };
  const oResponse = yield oWnApi.post(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    resolve('Success');
  } else {
    reject('Error');
  }
}

/**
 * Handles Profile\/*\/UPDATE redux actions.
 *
 * @param {object} oAction - Incoming Redux action.
 * @param {object} oAction.payload - Redux action payload.
 * @param {object} oAction.payload.data - Profile data to update.
 * @param {function} oAction.payload.resolve - Promise resolve function to tell code that dispatched Redux action when it's done.
 * @param {function} oAction.payload.reject - Promise reject function to tell code that dispatched Redux action that something failed.
 * @param {string} oAction.type - Redux action type.
 */
function* updateProfileData({ payload: { data, resolve, reject }, type }) {
  const { sReduxStoreSubKey, sDadDataType, sApiResourceName } =
    fnParseActionType(type);
  const { ID, ...oDataWithoutId } = data;
  const oRequestPayload = {
    ID,
    TYPE: sDadDataType,
    data: oDataWithoutId,
  };
  const sLookupId = yield select(currentUserLookupIdSelector);

  let sRequestPath = '';
  switch (sDadDataType) {
    case 'TOTAL_PRIVACY':
    case 'NOTIFICATIONS':
      sRequestPath = `users/${sLookupId}/${sApiResourceName}`;
      break;
    default:
      sRequestPath = `constituents/${sLookupId}/${sApiResourceName}`;
      break;
  }

  const oResponse = yield oWnApi.patch(sRequestPath, oRequestPayload);

  if (oResponse.status === 200) {
    const oValidatedResponseData = yield userProfileNormalizer.validateAt(
      sReduxStoreSubKey,
      oResponse.data.DATA
    );
    yield put(
      profileActions.update({ [sReduxStoreSubKey]: oValidatedResponseData })
    );

    if (aMyNetworkReduxSubKeys.includes(sReduxStoreSubKey)) {
      yield put(
        profileActions.get({
          data: {
            lookupId: sLookupId,
          },
          resolve: () => {},
          reject: () => {},
        })
      );
    }
    resolve('Success');
  } else {
    reject('Error');
  }
}

/**
 * Handles Profile\/*\/DELETE redux actions.
 *
 * @param {object} oAction - Incoming Redux action.
 * @param {object} oAction.payload - Redux action payload.
 * @param {object} oAction.payload.data - Profile data to delete.
 * @param {function} oAction.payload.resolve - Promise resolve function to tell code that dispatched Redux action when it's done.
 * @param {function} oAction.payload.reject - Promise reject function to tell code that dispatched Redux action that something failed.
 * @param {string} oAction.type - Redux action type.
 */
function* deleteProfileData({ payload: { data, resolve, reject }, type }) {
  const { sReduxStoreSubKey, sApiResourceName, sDadDataType } =
    fnParseActionType(type);
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestPath = `constituents/${sLookupId}/${sApiResourceName}`;
  const oRequestPayload = {
    ID: data.ID,
    TYPE: sDadDataType,
  };
  const oResponse = yield oWnApi.delete(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    const oValidatedResponseData = yield userProfileNormalizer.validateAt(
      sReduxStoreSubKey,
      oResponse.data.DATA
    );
    yield put(
      profileActions.update({ [sReduxStoreSubKey]: oValidatedResponseData })
    );

    if (aMyNetworkReduxSubKeys.includes(sReduxStoreSubKey)) {
      yield put(
        profileActions.get({
          data: {
            lookupId: sLookupId,
          },
          resolve: () => {},
          reject: () => {},
        })
      );
    }

    resolve('Success');
  } else {
    reject('Error');
  }
}

function* signUpForAlumniAcct({ payload: { data, resolve, reject } }) {
  const sLookupId = yield select(currentUserLookupIdSelector);
  const sRequestPath = `users/${sLookupId}/alumni_account`;
  try {
    const oResponse = yield oWnApi.post(sRequestPath, data);
    if (oResponse.status === 200) {
      resolve('Success');
    } else {
      throw new Error(oResponse.data.details);
    }
  } catch (oError) {
    reject(oError);
  }
}

export default function* watchProfile() {
  yield takeEvery('Profile/GET', getProfile);
  // Matches Profile/any/thing/CREATE
  yield takeEvery(
    (action) => /^Profile\/([a-zA-Z_]+\/)+CREATE/.test(action.type),
    createProfileData
  );
  // Matches Profile/anything/ADD_NOTE
  yield takeEvery(
    (action) => /^Profile\/([a-zA-Z_]+\/)+ADD_NOTE/.test(action.type),
    createNote
  );
  // Matches Profile/any/thing/UPDATE
  yield takeEvery(
    (action) => /^Profile\/([a-zA-Z_]+\/)+UPDATE/.test(action.type),
    updateProfileData
  );
  // Matches Profile/any/thing/DELETE
  yield takeEvery(
    (action) => /^Profile\/([a-zA-Z_]+\/)+DELETE/.test(action.type),
    deleteProfileData
  );
  yield takeEvery(
    (action) => /^Profile\/SIGN_UP/.test(action.type),
    signUpForAlumniAcct
  );
}
