import debounce from 'lodash.debounce';

import oWnApi from 'utils/WnApi';

let oPreviousAbortController = null;

export const fnFormatSearchResults = (aHits) =>
  aHits.map((oHit) => ({
    NAME: oHit.fields.name[0],
    LOOKUPID: oHit.id,
  }));

export const fnCallCloudSearch = async (sQuery, fnHandleResults) => {
  const oAbortController = new AbortController();
  if (oPreviousAbortController) {
    oPreviousAbortController.abort();
    console.log('Request cancelled: User performed a new quick search');
  }
  oPreviousAbortController = oAbortController;

  const sTrimmedQuery = sQuery.trim();
  const sEncodedQuery = encodeURIComponent(sTrimmedQuery);
  const sEncodedFilter = encodeURIComponent("type:'employer'");
  const oResults = await oWnApi.get(
    `quick_search?q=${sEncodedQuery}&fq=${sEncodedFilter}`,
    true,
    oAbortController.signal
  );
  const aFormattedResults = fnFormatSearchResults(oResults.data.hits.hit);
  fnHandleResults(aFormattedResults);
};

export const fnDebouncedCallCloudSearch = debounce(
  (sQuery, fnHandleResults) => {
    fnCallCloudSearch(sQuery, fnHandleResults);
  },
  500
);
