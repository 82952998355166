import React, { useContext, useEffect } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import oIconLibrary from 'utils/iconLibrary';
import usePageTitle from 'utils/hooks/usePageTitle';
import useCurrentEventCartItem from '../useCurrentEventCartItem';
import RegistrationOptionField from './RegistrationOptionField';
import EventRegistrationContext from '../EventRegistrationContext';
import { selectNextEventRegStep } from '../EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import {
  oRegistrationOptionsValidationSchema,
  sRegOptionsFormId,
} from './helpers';

const RegistrationOptionsForm = () => {
  const oEventCartItem = useCurrentEventCartItem();
  const iNumGuests = oEventCartItem.oDetails.aGuests.length;
  const { fnSetCurrentFormId } = useContext(EventRegistrationContext);
  const sNextEventRegStep = useSelector((state) =>
    selectNextEventRegStep(state, window.location.pathname)
  );
  const fnNavigate = useNavigate();

  useEffect(() => {
    fnSetCurrentFormId(sRegOptionsFormId);

    return () => {
      fnSetCurrentFormId('');
    };
  }, [fnSetCurrentFormId]);

  usePageTitle('Event Registration - Options');

  if (!oEventCartItem) {
    return null;
  }

  const sInstructions =
    iNumGuests > 1
      ? 'Please select an option for each guest.'
      : 'Please select an option.';

  return (
    <div>
      <h1 className='registrationOptionsForm__title t-paragraph--medium'>
        {sInstructions}
      </h1>
      <Formik
        initialValues={{ guests: oEventCartItem.oDetails.aGuests }}
        validationSchema={oRegistrationOptionsValidationSchema}
        enableReinitialize
        onSubmit={() => {
          fnSetCurrentFormId('');
          fnNavigate(sNextEventRegStep);
        }}
      >
        {(oFormikProps) => (
          <Form className='registrationOptionsForm' id={sRegOptionsFormId}>
            <FieldArray name='guests'>
              <div>
                {oFormikProps.values.guests.map((oGuest, iIndex) => (
                  <div
                    className='registrationOptionsForm__guest'
                    key={oGuest.LOOKUPID}
                    data-cy={`registrant-${oGuest.LOOKUPID}-reg-options`}
                  >
                    <h2 className='registrationOptionsForm__guestName t-paragraph--medium'>
                      <FontAwesomeIcon
                        icon={oIconLibrary.fontAwesome.user}
                        className='registrationOptionsForm__userIcon'
                      />
                      {oGuest.NAME}
                    </h2>
                    <RegistrationOptionField
                      sFieldName={`guests.${iIndex}.REG_OPTION`}
                      aFieldOptions={
                        oEventCartItem.oDetails.oEvent.REGISTRATION
                          .REGISTRATION_OPTIONS
                      }
                      sGuestLookupId={oGuest.LOOKUPID}
                      sEventLookupId={oEventCartItem.oDetails.oEvent.LOOKUPID}
                    />
                  </div>
                ))}
              </div>
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationOptionsForm;
