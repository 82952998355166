import { useEffect, useRef } from 'react';

/**
 * @description - returns true when the component
 * is mounted and false when unmounted.
 *
 * @author Tim Holder <holdertm@wfu.edu>
 * @copyright 2019 Wake Forest University, AIT
 */

const useIsMountedRef = () => {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
};

export default useIsMountedRef;
