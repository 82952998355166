import React from 'react';
import { useSelector } from 'react-redux';

const AlumniGoogleAccountActivated = () => {
  const { EMAILADDRESS } =
    useSelector((state) =>
      state.Profile.EMAIL.find((email) =>
        email.EMAILADDRESS.includes('alumni.wfu.edu')
      )
    ) || '';
  return (
    <p className='emailActivation__activatedEmailWrapper'>
      <span className='emailActivation__email'>{EMAILADDRESS}</span>
      <span className='emailActivation__activated'>&#10004; Activated</span>
    </p>
  );
};

export default AlumniGoogleAccountActivated;
