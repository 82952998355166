import React from 'react';
import PropTypes from 'prop-types';
import { oCurrencyFormatter } from '../../utils/common';

/**
 * Responsible for showing donation items for checkout.
 *
 * @example aCheckoutItems
 *  [
 *    {
 *      "description": "some name",
 *      "amount": 0
 *    }
 *  ]
 *
 * @author Elizabeth Hicks <hicksem@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */
const CheckoutSummaryTable = ({ aColumnHeaders, aCheckoutItems }) => (
  <table className='checkoutSummary'>
    <thead className='checkoutSummary__header'>
      <tr>
        {aColumnHeaders.map((sColumnHeader) => (
          <th className='checkoutSummary__columnHeader' key={sColumnHeader}>
            {sColumnHeader}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {aCheckoutItems.map((oCheckoutItem) => (
        <tr className='checkoutSummary__row' key={oCheckoutItem.description}>
          {aColumnHeaders.map((sColumnHeader) => {
            const sLowerColumnHeader = sColumnHeader.toLowerCase();
            let vValue = '';
            if (sLowerColumnHeader === 'amount') {
              vValue = oCurrencyFormatter.format(
                oCheckoutItem[sLowerColumnHeader]
              );
            } else {
              vValue = oCheckoutItem[sLowerColumnHeader];
            }
            return (
              <td
                className={`checkoutSummary__${sLowerColumnHeader}`}
                key={sColumnHeader}
              >
                {vValue}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

CheckoutSummaryTable.defaultProps = {
  aColumnHeaders: ['Description', 'Amount'],
};

CheckoutSummaryTable.propTypes = {
  aColumnHeaders: PropTypes.arrayOf(PropTypes.string),
  aCheckoutItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      amount: PropTypes.number,
    })
  ).isRequired,
};

export default CheckoutSummaryTable;
