import React from 'react';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';

import { OptionsModal } from '../../../../Form/OptionsModal';
import actions from './redux/actions';
import { NewAddressForm } from './NewAddressForm';
import { editAddressSchema } from './NewAddressForm/schemas';
import AddressRecord from './AddressRecord';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

/**
 * Displays profile address section in edit mode.
 */
const AddressEditMode = ({ aAddresses, bIsExpanded, oActions }) => {
  const [oFirstAddress, ...aOtherAddresses] = aAddresses;
  const oTheActions = oActions || actions;
  return (
    <>
      {oFirstAddress && (
        <ProfileRecordLayout
          left={<AddressRecord oAddress={oFirstAddress} bIsViewMode={false} />}
          right={
            <OptionsModal
              data={oFirstAddress}
              actions={oTheActions}
              textWhenPrimary='This is my preferred address contact'
              name='Address'
              privacyLabels={[
                'Public',
                'Show only city, state, and country',
                'Private',
              ]}
              validationSchema={editAddressSchema}
            />
          }
        />
      )}
      {aOtherAddresses.length > 0 && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          {aOtherAddresses.map((oAddress) => (
            <ProfileRecordLayout
              key={oAddress.ID}
              left={<AddressRecord oAddress={oAddress} bIsViewMode={false} />}
              right={
                <OptionsModal
                  data={oAddress}
                  actions={oTheActions}
                  textWhenPrimary='This is my preferred address contact'
                  name='Address'
                  privacyLabels={[
                    'Public',
                    'Show only city, state, and country',
                    'Private',
                  ]}
                  validationSchema={editAddressSchema}
                />
              }
            />
          ))}
        </Collapsible>
      )}
      <NewAddressForm />
    </>
  );
};

AddressEditMode.defaultProps = {
  aAddresses: [],
  bIsExpanded: false,
};

AddressEditMode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aAddresses: PropTypes.arrayOf(PropTypes.object),
  bIsExpanded: PropTypes.bool,
};

export default AddressEditMode;
