import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const oInitialState = {
  selectPaymentFormValues: null,
  payablePledges: [],
};

const reducer = handleActions(
  {
    [actions.update]: (state, action) => ({
      ...state,
      ...action.payload,
      _lastUpdated: Date.now(),
    }),
    [actions.reset]: () => oInitialState,
  },
  oInitialState,
  { prefix: 'PledgePayment' }
);

export default reducer;
