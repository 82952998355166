import PropTypes from 'prop-types';
import { MixedSchema, ObjectSchema } from 'yup';
import { Component } from 'react';

export const fnChildrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
]);

export const fnElementRefPropType = PropTypes.oneOfType([
  PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.instanceOf(Component),
    ]),
  }),
  PropTypes.func,
]);

export const locationPropType = PropTypes.shape({
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
});

export const yupSchemaPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(ObjectSchema),
  PropTypes.instanceOf(MixedSchema),
]);

export default {
  fnChildrenPropType,
};
