import * as R from 'ramda';
import { fnFuzzySearch } from 'utils/common';

export default class MyClassmatesSearchEngine {
  get searchFilter() {
    return this.filter;
  }

  set searchFilter(advSearchCriteria) {
    let searchFilter = {
      sFirstName: '',
      sLastName: '',
      sCity: '',
      fState: '',
      fEmployer: '',
      fDepartment: '',
      fProgram: '',
      fClubsOrganizations: '',
      fAffinity: '',
      fClaimable: '',
    };

    // Convert Formik adv search data into a filter we can do comparisons with more easily
    if (!R.isEmpty(advSearchCriteria)) {
      // Select type fields could be null if user cleared them by clicking the "X"
      const fState = advSearchCriteria.state?.DETAILS?.STATE_ABBRV || '';
      const fEmployer = advSearchCriteria.employer?.NAME || '';
      const fDepartment =
        advSearchCriteria.educationaldepartment?.DESCRIPTION || '';
      const fProgram = advSearchCriteria.educationalprogram?.DESCRIPTION || '';
      // The involvement typecode description must be dropped so only the name remains.
      // ex. "Awards and Honors - Dean's List" becomes "Dean's List"
      const fClubsOrganizations = (
        advSearchCriteria.educationalinvolvement?.DESCRIPTION || ''
      ).replace(/.* - /, '');
      const fAffinity = advSearchCriteria.affinitygroup?.DESCRIPTION || '';

      const sSanitizedCity = advSearchCriteria.city.replace(/-/g, ' '); // Replace hyphens with spaces

      searchFilter = {
        sFirstName: advSearchCriteria.firstname,
        sLastName: advSearchCriteria.lastname,
        sCity: sSanitizedCity,
        fState,
        fEmployer,
        fDepartment,
        fProgram,
        fClubsOrganizations,
        fAffinity,
        fClaimable: advSearchCriteria.claimableOnly ? 'Unclaimed' : '',
      };
    }

    this.filter = searchFilter;
  }

  async search(advSearchCriteria, allClassmates) {
    this.searchFilter = advSearchCriteria;

    const iMaxFuzzySearchResults = 150;
    let aSearchPool = allClassmates;
    // Fuzzy search first name
    if (this.searchFilter.sFirstName) {
      const aFuzzyResults = fnFuzzySearch(
        aSearchPool,
        ['FIRSTNAME', 'NICKNAME'],
        this.searchFilter.sFirstName,
        iMaxFuzzySearchResults
      );
      aSearchPool = aFuzzyResults.map((oResult) => oResult.obj);
    }

    // Fuzzy search last name
    if (this.searchFilter.sLastName) {
      const aFuzzyResults = fnFuzzySearch(
        aSearchPool,
        ['LASTNAME', 'MAIDENNAME'],
        this.searchFilter.sLastName,
        iMaxFuzzySearchResults
      );
      aSearchPool = aFuzzyResults.map((oResult) => oResult.obj);
    }

    // Fuzzy search city
    if (this.searchFilter.sCity) {
      const aFuzzyResults = fnFuzzySearch(
        aSearchPool,
        ['CITY'],
        this.searchFilter.sCity,
        iMaxFuzzySearchResults
      );
      aSearchPool = aFuzzyResults.map((oResult) => oResult.obj);
    }

    // Exact match search for everything else, since they're dropdowns
    aSearchPool = aSearchPool.filter(
      (item) =>
        this.matchesState(item) &&
        this.matchesEmployer(item) &&
        this.matchesDepartment(item) &&
        this.matchesInvolvements(item) &&
        this.matchesAffinityGroups(item) &&
        this.matchesEducationalProgram(item) &&
        this.matchesReunionProspectTeamStatus(item)
    );

    return aSearchPool;
  }

  matchesNickName(item) {
    let bHasNickName = false;
    if (
      (item.NICKNAME || {}) &&
      (item.NICKNAME || {})
        .toString()
        .toLowerCase()
        .includes(this.searchFilter.sFirstName)
    ) {
      bHasNickName = true;
    }
    return bHasNickName;
  }

  matchesFirstName(item) {
    let bHasFirstName = false;
    if (
      item.FIRSTNAME &&
      item.FIRSTNAME.toString()
        .toLowerCase()
        .includes(this.searchFilter.sFirstName)
    ) {
      bHasFirstName = true;
    }
    return bHasFirstName;
  }

  matchesLastName(item) {
    let bHasLastName = false;
    const bMatchesLastName =
      item.LASTNAME &&
      item.LASTNAME.toString()
        .toLowerCase()
        .includes(this.searchFilter.sLastName);
    const bMatchesMaidenName =
      item.MAIDENNAME &&
      item.MAIDENNAME.toString()
        .toLowerCase()
        .includes(this.searchFilter.sLastName);
    if (bMatchesLastName || bMatchesMaidenName) {
      bHasLastName = true;
    }
    return bHasLastName;
  }

  matchesState(item) {
    let bHasState = false;
    if (
      (item.STATE || {}) &&
      (item.STATE || {}).toString().includes(this.searchFilter.fState)
    ) {
      bHasState = true;
    }
    return bHasState;
  }

  matchesCity(item) {
    let bHasCity = false;
    if (
      (item.CITY || {}) &&
      (item.CITY || {})
        .toString()
        .toLowerCase()
        .includes(this.searchFilter.sCity)
    ) {
      bHasCity = true;
    }
    return bHasCity;
  }

  matchesEmployer(item) {
    let bHasEmployer = false;
    if (
      (item.EMPLOYER || {}) &&
      (item.EMPLOYER || {}).toString().includes(this.searchFilter.fEmployer)
    ) {
      bHasEmployer = true;
    }
    return bHasEmployer;
  }

  matchesDepartment(item) {
    let bHasDepartment = false;
    if (
      (item.DEPARTMENTS || [])
        .toString()
        .includes(this.searchFilter.fDepartment)
    ) {
      bHasDepartment = true;
    }
    return bHasDepartment;
  }

  matchesInvolvements(item) {
    let bHasInvolvements = false;
    if (
      (item.INVOLVEMENTS || [])
        .toString()
        .includes(this.searchFilter.fClubsOrganizations)
    ) {
      bHasInvolvements = true;
    }
    return bHasInvolvements;
  }

  matchesAffinityGroups(item) {
    let bHasAffinityGroups = false;
    if (
      (item.AFFINITYGROUPS || [])
        .toString()
        .includes(this.searchFilter.fAffinity)
    ) {
      bHasAffinityGroups = true;
    }
    return bHasAffinityGroups;
  }

  matchesEducationalProgram(item) {
    let bHasEducationalProgram = false;
    if (
      item.EDUCATIONALPROGRAM &&
      item.EDUCATIONALPROGRAM.toString().includes(this.searchFilter.fProgram)
    ) {
      bHasEducationalProgram = true;
    }
    return bHasEducationalProgram;
  }

  matchesReunionProspectTeamStatus(item) {
    let bHasReunionProspectTeamStatus = false;
    if (
      item.REUNIONPROSPECTTEAM.STATUS &&
      item.REUNIONPROSPECTTEAM.STATUS.includes(this.searchFilter.fClaimable)
    ) {
      bHasReunionProspectTeamStatus = true;
    }
    return bHasReunionProspectTeamStatus;
  }
}
