import React from 'react';
import debounce from 'lodash.debounce';

import { sEmptyLookupId } from 'utils/constants';
import oWnApi from 'utils/WnApi';

let oPreviousAbortController = null;

export const fnFormatSearchResults = (aHits) =>
  aHits.map((oHit) => ({
    NAME: oHit.fields.name[0],
    LOOKUPID: oHit.id,
  }));

const fnCallCloudSearch = async (sQuery, fnHandleResults) => {
  const oAbortController = new AbortController();
  if (oPreviousAbortController) {
    oPreviousAbortController.abort();
    console.log('Request cancelled: User performed a new quick search');
  }
  oPreviousAbortController = oAbortController;

  const sTrimmedQuery = sQuery.trim();
  const sEncodedQuery = encodeURIComponent(sTrimmedQuery);
  const sEncodedFilter = encodeURIComponent("type:'constituent'");
  const oResults = await oWnApi.get(
    `quick_search?q=${sEncodedQuery}&fq=${sEncodedFilter}`,
    oAbortController.signal
  );
  const aFormattedResults = fnFormatSearchResults(oResults.data.hits.hit);
  fnHandleResults(aFormattedResults);
};

const fnDebouncedCallCloudSearch = debounce((sQuery, fnHandleResults) => {
  fnCallCloudSearch(sQuery, fnHandleResults);
}, 500);

export const fnLoadOptions = (sUserInput) =>
  new Promise((resolve, reject) => {
    fnDebouncedCallCloudSearch(sUserInput, resolve);
  });

export const fnBuildNoResultsMessage = ({ inputValue }) => (
  <span className='t-paragraph--small'>
    {inputValue ? `No one found for '${inputValue}'` : 'Type to search'}
  </span>
);

export const fnBuildLoadingMessage = () => (
  <span className='t-paragraph--small'>Loading...</span>
);

export const fnGetOptionLabel = (oOption) => oOption.NAME;

export const fnGetOptionValue = (oOption) => oOption.NAME;

export const fnGetNewOptionData = (option) => ({
  LOOKUPID: sEmptyLookupId,
  NAME: option,
});
