import * as R from 'ramda';
import PropTypes from 'prop-types';

/**
 * Prepares pledge data for render by adding next due date and pledge number and sorting.
 *
 * @param {array} aPledgeData - Pledge data from CRM.
 * @returns {array}
 */
export const fnTransformPledgeDataForRender = (aPledgeData) => {
  const aPledgeDataToSort = aPledgeData.map((oPledge) => {
    const iPledgeNumber = parseInt(oPledge.LOOKUPID.split('-')[1]);
    const oNextDueInstallment = oPledge.INSTALLMENTS.find((oInstallment) => {
      const fBalance = parseFloat(oInstallment.BALANCE);
      return fBalance > 0;
    });
    const oNextDueDate = new Date(oNextDueInstallment.DUE_DATE);
    return {
      ...oPledge,
      pledgeNumber: iPledgeNumber,
      nextDueDate: oNextDueDate,
    };
  });
  const fnSortByPledgeDueDateThenNumber = R.sortWith([
    R.ascend(R.prop('nextDueDate')),
    R.ascend(R.prop('pledgeNumber')),
  ]);
  const aSortedPledgeData = fnSortByPledgeDueDateThenNumber(aPledgeDataToSort);
  return aSortedPledgeData;
};

/**
 * Groups pledge installments into 'Paid' or 'Unpaid' based on remaining balance.
 */
export const fnGroupByBalance = R.groupBy((oInstallment) => {
  const fBalance = parseFloat(oInstallment.BALANCE);
  if (fBalance === 0) {
    return 'Paid';
  }
  return 'Unpaid';
});

/**
 * Retrieves payable pledges.
 *
 * @param {array} aPledgeData - Data for all pledges that aren't fully paid.
 * @param {array} aSortedPledgeData - Pledge data sorted by due date.
 *
 * @returns {array}
 */
export const retrievePayablePledges = (aPledgeData, aSortedPledgeData) => {
  const aNonPayableTypes = [
    'Recurring Credit Card',
    'ACH Draft',
    'Payroll Deduction',
  ];
  return aSortedPledgeData.filter(
    (oPledge) => !aNonPayableTypes.includes(oPledge.PLEDGESUBTYPE)
  );
};

export const installmentPropType = PropTypes.shape({
  AMOUNT: PropTypes.string,
  APPLIED: PropTypes.string,
  BALANCE: PropTypes.string,
  DESIGNATIONS: PropTypes.arrayOf(
    PropTypes.shape({
      AMOUNT: PropTypes.string,
      CODE: PropTypes.string,
      NAME: PropTypes.string,
    })
  ),
  DUE_DATE: PropTypes.string,
  ID: PropTypes.string,
});

export const pledgePropType = PropTypes.shape({
  AMOUNT: PropTypes.string,
  BALANCE: PropTypes.string,
  ID: PropTypes.string,
  INSTALLMENTS: PropTypes.arrayOf(installmentPropType),
  LOOKUPID: PropTypes.string,
  NEXTINSTALLMENTDUEDATE: PropTypes.string,
  PENDING: PropTypes.string,
  PLEDGESUBTYPE: PropTypes.string,
  nextDueDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  pledgeNumber: PropTypes.number,
});
