import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Button from 'components/Buttons/Button';

export const SubmitButton = ({
  children,
  bIsCentered,
  bIsDisabled,
  className,
  sCypressId,
}) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Button
      sType='submit'
      bIsLoading={isSubmitting}
      bIsCentered={bIsCentered}
      bIsDisabled={bIsDisabled}
      className={className}
      sCypressId={sCypressId}
    >
      {children}
    </Button>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.string,
  bIsCentered: PropTypes.bool,
  bIsDisabled: PropTypes.bool,
  className: PropTypes.string,
  sCypressId: PropTypes.string,
};

SubmitButton.defaultProps = {
  children: 'Save',
  bIsCentered: false,
  bIsDisabled: false,
  className: 'submitButton',
  sCypressId: 'submitButton',
};

export default SubmitButton;
