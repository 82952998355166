import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import AddConstituentForm from '../AddConstituentForm';

const CreateAGuest = ({
  oNewGuestInitialValues,
  fnSetGuestUIPhase,
  fnHandleNewGuest,
}) => (
  <div>
    <h1 className='guestManagement__addFormTitle t-paragraph--medium'>
      Tell us more about your guest.
    </h1>
    <AddConstituentForm
      oInitialValues={oNewGuestInitialValues}
      fnSetGuestUIPhase={fnSetGuestUIPhase}
      fnHandleNewConstituent={fnHandleNewGuest}
      fnHandleSubmit={(oFormValues) => {
        const oNewConstituentData = {
          FIRST_NAME: oFormValues.firstName,
          LAST_NAME: oFormValues.lastName,
          EMAIL_ADDRESS: oFormValues.email,
        };
        fnHandleNewGuest(
          `${oFormValues.firstName} ${oFormValues.lastName}`,
          '',
          oNewConstituentData
        );
        fnSetGuestUIPhase('search');
      }}
      sSubmitButtonLabel='Add Guest'
    />
    <Button
      sFlavor='underline'
      bIsCentered
      fnHandleClick={() => fnSetGuestUIPhase('search')}
      className='guestManagement__cancelButton'
    >
      Cancel
    </Button>
  </div>
);

CreateAGuest.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  oNewGuestInitialValues: PropTypes.object.isRequired,
  fnSetGuestUIPhase: PropTypes.func.isRequired,
  fnHandleNewGuest: PropTypes.func.isRequired,
};

export default CreateAGuest;
