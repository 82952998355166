import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { currentUserLookupIdSelector } from 'redux/selectors';
import oWnApi from 'utils/WnApi';
import LoadingZone from 'components/LoadingZone';
import Button from 'components/Buttons/Button';
import Accordion from '../../Layout/Accordion';
import philanthropyReportDataNormalizer from './apiDataNormalizer';
import FundOverview from './FundOverview';
import FundFinancialHistory from './FundFinancialHistory';
import FundFinancialChart from './FundFinancialChart';
import { fnHasFinancialChartData } from './helpers';
import Recipient from './Recipient';

const PhilanthropyReport = () => {
  const [aFundData, fnSetFundData] = useState([]);
  const [isReportDataLoading, setIsReportDataLoading] = useState(true);
  const sLookupId = useSelector(currentUserLookupIdSelector);

  useEffect(() => {
    const fetchPhilanthropyData = async () => {
      try {
        const result = await oWnApi.get(
          `constituents/${sLookupId}/reports/philanthropy`
        );
        const validatedData = await philanthropyReportDataNormalizer.validate(
          result.data
        );
        fnSetFundData(validatedData.STEWARDSHIPDATA.SCHOLARSHIPS);
        setIsReportDataLoading(false);
      } catch (err) {
        console.error('Error fetching philanthropy report data', err);
      }
    };
    fetchPhilanthropyData();
  }, [sLookupId]);

  return (
    <Accordion
      mTitle='Philanthropy Report'
      sTitleIcon='bookOpen'
      sGtmCategory='Philanthropy Report'
    >
      <LoadingZone isLoading={isReportDataLoading}>
        <div className='philanthropyReport__content'>
          {aFundData.map((oFund) => {
            const {
              CONTACTS,
              FUNDNAME,
              DESIGNATIONLEVELID,
              RECIPIENTS,
              FINANCIAL_SUMMARY,
              FINANCIALS,
            } = oFund;
            const currentRecipients = RECIPIENTS.filter(
              (recipient) => recipient.STATUS === 'CURRENT'
            );
            const formerRecipients = RECIPIENTS.filter(
              (recipient) => recipient.STATUS === 'FORMER'
            );
            const bHasChartData = fnHasFinancialChartData(FINANCIALS);
            return (
              <Accordion
                mTitle={FUNDNAME}
                sSize='medium'
                className='philanthropyReport__scholarship'
                key={DESIGNATIONLEVELID}
                sGtmCategory='Philanthropy Report: Scholarship Fund'
                bStartOpen={aFundData.length === 1}
              >
                <p className='philanthropyReport__fundIntro'>
                  Below is a summary of your fund performance. Your gifts and
                  generosity make it possible for Wake Forest to continue a
                  proud tradition of empowering young men and women to lead
                  lives of purpose and fulfillment in the spirit of Pro
                  Humanitate. The disbursements support the purpose of the fund.
                  Thank you for your continued support of Wake Forest
                  University.
                </p>
                {/* @todo: connect this button to a download */}
                {/* <Button bIsCentered className='philanthropyReport__pdfButton'>
                  Download Latest Financial Report PDF
                </Button> */}
                <FundOverview
                  aContacts={CONTACTS}
                  sLatestValue={FINANCIALS?.[0]?.FISCAL_YEAR_ENDING_VALUE}
                  sTotalDistributions={FINANCIAL_SUMMARY.TOTAL_DISTRIBUTIONS}
                />
                {bHasChartData && (
                  <FundFinancialChart aFinancialData={FINANCIALS} />
                )}
                <div className='philanthropyReport__scholarshipContent'>
                  {currentRecipients.length > 0 && (
                    <Accordion
                      mTitle='Current Recipients'
                      sSize='small'
                      sGtmCategory='Philanthropy Report: Scholarship Recipients'
                    >
                      <div className='philanthropyReport__recipientsContent'>
                        {currentRecipients.map((oRecipient) => (
                          <Recipient
                            data={oRecipient}
                            key={oRecipient.LOOKUPID}
                          />
                        ))}
                      </div>
                    </Accordion>
                  )}
                  {formerRecipients.length > 0 && (
                    <Accordion
                      mTitle='Former Recipients'
                      sSize='small'
                      sGtmCategory='Philanthropy Report: Scholarship Recipients'
                    >
                      <div className='philanthropyReport__recipientsContent'>
                        {formerRecipients.map((oRecipient) => (
                          <Recipient
                            data={oRecipient}
                            key={oRecipient.LOOKUPID}
                          />
                        ))}
                      </div>
                    </Accordion>
                  )}
                </div>
                {FINANCIALS.length > 0 && (
                  <FundFinancialHistory
                    aData={FINANCIALS}
                    sFundType={oFund.FUND_TYPE.DESCRIPTION}
                  />
                )}
              </Accordion>
            );
          })}
        </div>
      </LoadingZone>
    </Accordion>
  );
};

export default PhilanthropyReport;
