import React from 'react';
import { useSelector } from 'react-redux';

import EventRegistrationDetailsCard from '../EventRegistrationDetailsCard';
import EventConfirmationCardContent from './EventConfirmationCardContent';
import {
  selectEventsInCart,
  selectWaitlistEventsInCart,
} from '../redux/selectors';

const EventsYouRegisteredFor = () => {
  const aEventsInCart = useSelector(selectEventsInCart);
  const aWaitlistEventsInCart = useSelector(selectWaitlistEventsInCart);
  return (
    <>
      {aEventsInCart.length > 0 && (
        <EventRegistrationDetailsCard
          sTitle='Events'
          sCypressId='confirmation-events'
        >
          <EventConfirmationCardContent aEvents={aEventsInCart} />
        </EventRegistrationDetailsCard>
      )}
      {aWaitlistEventsInCart.length > 0 && (
        <EventRegistrationDetailsCard
          sTitle='Waitlist Events'
          sCypressId='confirmation-waitlist-events'
        >
          <EventConfirmationCardContent aEvents={aWaitlistEventsInCart} />
        </EventRegistrationDetailsCard>
      )}
    </>
  );
};

export default EventsYouRegisteredFor;
