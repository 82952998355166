import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { Select } from 'components/Form/Fields';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import actions from '../redux/actions';
import {
  fnFilterProtectedRelationshipTypes,
  oMyNetworkContactPropType,
} from './helpers';

const RelationshipTypeSwitcher = ({ oConstituent }) => {
  const fnDispatch = useDispatch();

  const aRelationshipTypes = useCodeTablesSection('RELATIONSHIP', (aCodes) => {
    const aUnprotectedCodes = fnFilterProtectedRelationshipTypes(aCodes);
    const aSameCategoryCodes = aUnprotectedCodes.filter(
      (oCode) =>
        oCode.DETAILS.CATEGORY === oConstituent.RELATIONSHIP.TYPE.CATEGORY
    );
    return aSameCategoryCodes;
  });

  const fnHandleChangeRelationship = (oNewRelationship) => {
    fnDispatch(
      actions.update({
        data: {
          ID: oConstituent.RELATIONSHIP.ID,
          RELATIONSHIP: oNewRelationship,
        },
        resolve: () => {},
        reject: () => {},
      })
    );
  };

  return (
    <Formik
      initialValues={{
        relationship: oConstituent.RELATIONSHIP.TYPE,
      }}
    >
      <Form>
        <Select
          label='Relationship'
          id={`relationship-${oConstituent.LOOKUPID}`}
          name='relationship'
          options={aRelationshipTypes}
          className='myNetworkListItem__selectRelationship'
          onChange={fnHandleChangeRelationship}
          getOptionLabel={(oOption) => oOption.DESCRIPTION}
          bIsClearable={false}
        />
      </Form>
    </Formik>
  );
};

RelationshipTypeSwitcher.propTypes = {
  oConstituent: oMyNetworkContactPropType.isRequired,
};

export default RelationshipTypeSwitcher;
