import { useState, useEffect } from 'react';

let listeners = [];
let state = null;

const setState = (newState) => {
  state = newState;

  // Call setState() for each listener in the array
  listeners.forEach((listener) => {
    listener(state);
  });
};

export const useActiveFormSection = () => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);

    return () => {
      // Remove listener when component unmounts
      listeners = listeners.filter((listener) => listener !== newListener);
    };
  }, [newListener]);
  return [state, setState];
};

export default useActiveFormSection;
