import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { fnElementRefPropType } from 'utils/customPropTypes';

export const TextField = ({
  sId,
  sLabel,
  sName,
  bSilenceErrors,
  className,
  bIsLabelHidden,
  oFieldRef,
  bReadOnly,
  sPlaceholder,
  sHelpText,
  sCypressId,
  bIsDisabled,
}) => {
  const [field, meta] = useField(sName);
  const bShowErrors = meta.touched && meta.error && !bSilenceErrors;
  const sWrapperClassName = bShowErrors
    ? 'boxField boxField--error'
    : 'boxField';
  return (
    <div className={sWrapperClassName}>
      <input
        {...field}
        id={sId}
        type='text'
        name={sName}
        className={className}
        ref={oFieldRef}
        readOnly={bReadOnly}
        placeholder={sPlaceholder}
        data-cy={sCypressId}
        disabled={bIsDisabled}
      />
      <label htmlFor={sId} className={bIsLabelHidden ? 'hide_accessibly' : ''}>
        {sLabel}
      </label>
      {sHelpText && <p className='field__helpText'>{sHelpText}</p>}
      {bShowErrors && <p className='field__errorMessage'>{meta.error}</p>}
    </div>
  );
};

TextField.defaultProps = {
  bSilenceErrors: false,
  className: '',
  bIsLabelHidden: false,
  bReadOnly: false,
  oFieldRef: null,
  sPlaceholder: ' ', // The CSS requires a placeholder, even if it's just an empty space
  sHelpText: '',
  sCypressId: '',
  bIsDisabled: false,
};

TextField.propTypes = {
  sId: PropTypes.string.isRequired,
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  bSilenceErrors: PropTypes.bool,
  bIsLabelHidden: PropTypes.bool,
  className: PropTypes.string,
  bReadOnly: PropTypes.bool,
  oFieldRef: fnElementRefPropType,
  sPlaceholder: PropTypes.string,
  sHelpText: PropTypes.string,
  sCypressId: PropTypes.string,
  bIsDisabled: PropTypes.bool,
};

export default TextField;
