import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import oIconLibrary from 'utils/iconLibrary';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import useCurrentEventCartItem from '../useCurrentEventCartItem';
import { selectNextEventRegStep } from '../EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';

const GuestYesNo = ({ fnSetGuestUIPhase }) => {
  const fnNavigate = useNavigate();
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(state, window.location.pathname)
  );
  const oEventCartItem = useCurrentEventCartItem();
  const bIsWaitlistEvent = oEventCartItem?.sType === 'event-waitlist';
  const iNumGuests = oEventCartItem?.oDetails?.aGuests?.length ?? 0;

  return (
    <div className='guestManagement'>
      <FontAwesomeIcon
        icon={oIconLibrary.fontAwesome.userAdd}
        className='guestManagement__icon'
      />
      <h1 className='guestManagement__question t-paragraph--medium'>
        {bIsWaitlistEvent
          ? 'Would you like to add additional guests to the waitlist?'
          : `Would you like to bring someone to 
        ${oEventCartItem.oDetails.oEvent.TITLE}?`}
      </h1>
      <div className='eventReg__yesNoButtons'>
        <ButtonWithIcon
          sIcon='check'
          className='eventReg__yesNoButton'
          fnHandleClick={() => fnSetGuestUIPhase('search')}
          sCypressId='yes-guest-button'
        >
          Yes
        </ButtonWithIcon>
        <ButtonWithIcon
          sIcon='close'
          className='eventReg__yesNoButton'
          fnHandleClick={() => {
            fnNavigate(sNextStepUrl);
          }}
          bIsDisabled={iNumGuests === 0}
          sCypressId='no-guest-button'
        >
          No
        </ButtonWithIcon>
      </div>
    </div>
  );
};

GuestYesNo.propTypes = {
  fnSetGuestUIPhase: PropTypes.func.isRequired,
};

export default GuestYesNo;
