import React from 'react';
import DateAndTime from 'date-and-time';

import { oCurrencyFormatter } from 'utils/common';
import InstallmentDetailsTable from './InstallmentDetailsTable';
import { installmentPropType } from '../helpers';

/**
 * Displays one unpaid pledge installment.
 */
const Installment = ({ oData }) => {
  const oToday = new Date();
  const oDueDate = new Date(oData.DUE_DATE);
  const bIsPastDue = oDueDate < oToday;
  const sDueDate = DateAndTime.format(oDueDate, 'MM.DD.YY');

  return (
    <div className='installment'>
      <div
        className={`installment__header ${
          bIsPastDue ? 'installment__header--pastDue' : ''
        }`}
      >
        <div className='installment__headerDescription'>
          <span className='installment__date'>{sDueDate}</span>
          {oData.DESIGNATIONS.length === 1 && (
            <span className='installment__singleDesignation'>
              {oData.DESIGNATIONS[0].NAME}
            </span>
          )}
        </div>
        <div className='installment__headerAmount'>
          {bIsPastDue && (
            <span className='installment__pastDue'>
              {/* VS Code didn't like parentheses as text in JSX. */}
              &#40;Past Due&#41;
            </span>
          )}
          <span className='installment__total'>
            {oCurrencyFormatter.format(oData.AMOUNT)}
          </span>
        </div>
      </div>
      <InstallmentDetailsTable
        oInstallmentData={oData}
        bIsPastDue={bIsPastDue}
      />
    </div>
  );
};

Installment.propTypes = {
  oData: installmentPropType.isRequired,
};

export default Installment;
