import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import useActiveFormField from 'utils/hooks/useActiveFormField';
import PencilEditButton from 'components/Layout/PencilEditButton';
import { AddItemButton } from 'components/Form';
import RecordPrivacyIcon from 'components/MyProfile/elements/RecordPrivacyIcon';
import EditEmployerGroupForm from './EditEmployerGroupForm';
import JobRecord from './JobRecord';
import { fnEmployerHasPositions } from '../helpers';

const EmployerGroup = ({
  data,
  bIsPublicViewMode,
  bHasEmployerWithMultiplePositions,
}) => {
  const { EMPLOYER, POSITIONS } = data;
  const bHasVisiblePosition = fnEmployerHasPositions(data);
  const [activeFormSection] = useActiveFormSection();
  const bIsEditingCareerSection = activeFormSection === 'career';
  const sFormId = bIsPublicViewMode ? '' : `edit-employment-form-${EMPLOYER}`;
  const [activeFormField, setActiveFormField] = useActiveFormField(sFormId);
  const [bIsEditingGroup, fnSetIsEditingGroup] = useState(false);
  const [oInitialFormValues, fnSetInitialFormValues] = useState(data);
  const bIsRetired = bHasVisiblePosition
    ? POSITIONS.some((oPosition) => oPosition.IS_RETIRED)
    : false;
  const bIsPrivate = data.PRIVACY === '2';

  const fnCloseForm = () => {
    setActiveFormField(null);
  };
  const fnOpenForm = () => {
    fnSetInitialFormValues(data);
    setActiveFormField(sFormId);
  };

  useEffect(() => {
    if (activeFormField === sFormId && bIsEditingCareerSection) {
      fnSetIsEditingGroup(true);
    } else {
      fnSetIsEditingGroup(false);
    }
  }, [activeFormField, bIsEditingCareerSection, sFormId, fnSetIsEditingGroup]);

  const fnHandleAddPositionClick = () => {
    const oCopy = R.clone(data);
    oCopy.POSITIONS = [
      {
        ID: '',
        JOB_TITLE: '',
        PRIMARY: true,
        START_DATE: '',
        END_DATE: '',
        PRIVACY: '0',
      },
      ...oCopy.POSITIONS,
    ];
    fnSetInitialFormValues(oCopy);
    fnSetIsEditingGroup(true);
    setActiveFormField(sFormId);
  };

  // Update the initial form values after user deletes a job
  useEffect(() => {
    fnSetInitialFormValues(data);
  }, [data, fnSetInitialFormValues]);

  return (
    <div className='employerGroup'>
      {!bIsPublicViewMode && bIsEditingCareerSection && (
        <PencilEditButton
          fnHandleClick={bIsEditingGroup ? fnCloseForm : fnOpenForm}
          bIsEditing={bIsEditingGroup}
          sAriaLabel={`Edit employment with ${data.EMPLOYER}`}
          className='employerGroup__editButton'
          sSize='small'
        />
      )}

      {bIsEditingGroup && !bIsPublicViewMode ? (
        <EditEmployerGroupForm
          data={oInitialFormValues}
          fnCloseForm={fnCloseForm}
        />
      ) : (
        <div
          className={`employerGroup__innerWrapper ${
            bHasEmployerWithMultiplePositions
              ? 'employerGroup__innerWrapper--withDotLines'
              : ''
          }`}
        >
          <div className='employerGroup__employerWrapper'>
            <p className='record t-paragraph--medium'>
              {EMPLOYER}
              {bIsRetired && ' (Retired)'}
            </p>
            {bIsPrivate && (
              <RecordPrivacyIcon ariaId={data.EMPLOYER_RELATIONSHIP_ID} />
            )}
          </div>
          {bIsEditingCareerSection && !bIsPublicViewMode && (
            <AddItemButton
              sLabel='Add position'
              fnHandleClick={fnHandleAddPositionClick}
            />
          )}
          {bHasVisiblePosition && (
            <ol className='employerGroup__positions'>
              {POSITIONS.map((oPosition) => (
                <JobRecord
                  data={oPosition}
                  bEmployerIsPrivate={bIsPrivate}
                  key={oPosition.ID}
                />
              ))}
            </ol>
          )}
        </div>
      )}
    </div>
  );
};

EmployerGroup.propTypes = {
  data: PropTypes.shape({
    EMPLOYER: PropTypes.string,
    POSITIONS: PropTypes.array,
  }).isRequired,
  bIsPublicViewMode: PropTypes.bool,
  bHasEmployerWithMultiplePositions: PropTypes.bool,
};

EmployerGroup.defaultProps = {
  bIsPublicViewMode: false,
  bHasEmployerWithMultiplePositions: true,
};

export default EmployerGroup;
