import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

import FieldErrorMessage from 'components/Form/FieldErrorMessage';
import { useFormikContext } from 'formik';
import { fnElementRefPropType } from 'utils/customPropTypes';

const sWfuReCaptchaSiteKey = '6Lfm5QwqAAAAAHoWF3R3ZkqhB4t2yXKher0vK7gX';

/**
 * Displays a Google reCAPTCHA v2 checkbox and Formik field error message.
 * Renders `null` when running Cypress tests.
 */
export const GoogleReCaptcha = ({ oReCaptchaRef, className = '' }) => {
  const oFormikProps = useFormikContext();

  return window.Cypress ? null : (
    <div className={`googleReCaptcha ${className}`}>
      <ReCAPTCHA
        sitekey={sWfuReCaptchaSiteKey}
        onChange={(sToken) => {
          oFormikProps.setFieldValue('reCaptchaToken', sToken);
        }}
        className='googleReCaptcha__box'
        ref={oReCaptchaRef}
      />
      <FieldErrorMessage
        sFieldName='reCaptchaToken'
        sClassName='googleReCaptcha__error'
      />
    </div>
  );
};

GoogleReCaptcha.propTypes = {
  oReCaptchaRef: fnElementRefPropType.isRequired,
  className: PropTypes.string,
};

GoogleReCaptcha.defaultProps = {
  className: '',
};

export default GoogleReCaptcha;
