import React from 'react';

import LandingPageIntro from './LandingPageIntro';
import LandingPageContent from './LandingPageContent';
import LandingPageFooter from './LandingPageFooter';
import ScrollIndicator from './ScrollIndicator';

type LandingPageProps = {
  aMaintenanceAlerts: Array<any>;
};

/**
 * Displays landing page with log-in buttons.
 */
const LandingPage = ({ aMaintenanceAlerts = [] }: LandingPageProps) => (
  <div className='landingPage'>
    <main className='landingPage__main'>
      <LandingPageIntro aMaintenanceAlerts={aMaintenanceAlerts} />
      <LandingPageContent />
    </main>
    <LandingPageFooter />
    <ScrollIndicator />
  </div>
);

export default LandingPage;
