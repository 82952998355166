import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { fnTruncateBetweenWords } from 'utils/common';

const iMaxCharacters = 200;

/**
 * Displays text followed by an ellipsis and a "View More" toggle button
 * if the text is more than 200 characters long.
 */
const LongTextWithToggle = ({ sText, sClassName }) => {
  const [bIsExpanded, fnSetIsExpanded] = useState(false);
  const bIsTooLong = sText.length > iMaxCharacters;

  return (
    <div className={sClassName}>
      <p className={bIsExpanded ? '' : 'longTextWithToggle__text--truncated'}>
        {bIsExpanded || !bIsTooLong
          ? sText
          : `${fnTruncateBetweenWords(sText, iMaxCharacters)}...`}
      </p>
      {bIsTooLong && (
        <Button
          fnHandleClick={() => fnSetIsExpanded(!bIsExpanded)}
          sFlavor='underline'
        >
          View {bIsExpanded ? 'Less' : 'More'}
        </Button>
      )}
    </div>
  );
};

LongTextWithToggle.propTypes = {
  sText: PropTypes.string.isRequired,
  sClassName: PropTypes.string,
};

LongTextWithToggle.defaultProps = {
  sClassName: '',
};

export default LongTextWithToggle;
