import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import Button from 'components/Buttons/Button';
import NotificationsList from './NotificationsList';
import useActions from '../../../../utils/hooks/useActions';
import notificationsActions from '../redux/actions';

const NotificationsModal = ({ fnSetShowModal }) => {
  const aNotificationsData = useSelector(
    (state) => state.Profile?.NOTIFICATIONS || []
  );
  const actions = useActions(notificationsActions);

  const fnMarkAllAsRead = () => {
    // Build list of unread notifications
    const NOTIFICATIONS = aNotificationsData.reduce((result, item) => {
      if (!item.READSTATUS) {
        result.push({
          NOTIFICATION: { TYPE: item.TYPE, READSTATUS: '1' },
        });
      }
      return result;
    }, []);
    // Tell DAD all notifications are now read
    // eslint-disable-next-line no-unused-vars
    const promise = new Promise((resolve, reject) => {
      actions.update({
        data: {
          // This key is required in order to convert the array JSON to XML correctly.
          NOTIFICATIONS,
        },
        resolve,
        reject,
      });
    });
  };
  return (
    <div className='notificationsModal' role='dialog'>
      <div className='notificationsModal__titleWrapper'>
        <p className='notificationsModal__title t-paragraph--medium'>
          My Notifications
        </p>
      </div>
      {aNotificationsData.length > 0 ? (
        <>
          <NotificationsList fnSetShowModal={fnSetShowModal} />
          <Button
            sFlavor='underline'
            fnHandleClick={fnMarkAllAsRead}
            className='notificationsModal__markAllRead'
          >
            Mark all as read
          </Button>
        </>
      ) : (
        <div className='notificationsModal__noNotifications'>
          <p>You&apos;re all up to date!</p>
          <FontAwesomeIcon
            icon={oIconLibrary.fontAwesome.thumbsUp}
            className='notificationsModal__successIcon'
          />
        </div>
      )}
    </div>
  );
};

NotificationsModal.propTypes = {
  fnSetShowModal: PropTypes.func.isRequired,
};

export default NotificationsModal;
