import React, { useState } from 'react';
import { Hub } from 'aws-amplify';
import usePageTitle from 'utils/hooks/usePageTitle';

import AuthLayout from '../../AuthLayout';
import TermsOfUseAcceptanceForm from './TermsOfUseAcceptanceForm';
import tosLogo from '../../images/tos.svg';
import ContinueButton from 'components/Authentication/AuthLayout/ContinueButton';

const TermsOfUseAcceptancePage = () => {
  const [bIsContinueDisabled, fnSetIsContinueDisabled] = useState(true);
  usePageTitle('Sign Up');

  const fnHandleSubmit = () => {
    // Refresh current session since first-time users already logged in with Google
    Hub.dispatch('wfu', {
      event: 'wfuForceTokenRefresh',
      data: {},
      message: '',
    });
  };
  // fnHandleSubmitDisable handles disabling the continue button when the form is invalid or dirty,
  // only when changed from the initial values.
  const fnHandleSubmitDisable = (formikProps) => {
    fnSetIsContinueDisabled(!(formikProps?.isValid && formikProps?.dirty));
  };
  return (
    <AuthLayout
      continueButton={
        <ContinueButton
          fnHandleContinue={fnHandleSubmit}
          bContinueButtonDisabled={bIsContinueDisabled}
        />
      }
      left={
        <>
          <h2>It&apos;s you!</h2>
          <p className='termsOfUseAcceptancePg__subheading'>
            The last step is to agree to the terms of service and then
            you&apos;re done.
          </p>
          <TermsOfUseAcceptanceForm
            fnHandleSubmit={fnHandleSubmit}
            fnHandleSubmitDisable={fnHandleSubmitDisable}
          />
        </>
      }
      right={
        <img
          src={tosLogo}
          className='termsOfUseAcceptancePg__tosDocumentLogo'
          alt='a terms of service document'
        />
      }
    />
  );
};

export default TermsOfUseAcceptancePage;
