import React from 'react';
import DateAndTime from 'date-and-time';
import PropTypes from 'prop-types';

const RegistrationWindowClosed = ({ sCloseDateTime, sTimeZone }) => {
  const oCloseDateTime = DateAndTime.parse(
    sCloseDateTime,
    'YYYY-MM-DDTHH:mm:ss'
  );
  const sFormattedDate = DateAndTime.format(oCloseDateTime, 'MMMM D, YYYY');
  const sFormattedTime = DateAndTime.format(oCloseDateTime, 'h:mm A');

  return (
    <p className='t-paragraph--small'>
      Registration for this event closed on {sFormattedDate} at {sFormattedTime}{' '}
      {sTimeZone}.
    </p>
  );
};

RegistrationWindowClosed.propTypes = {
  sCloseDateTime: PropTypes.string.isRequired,
  sTimeZone: PropTypes.string.isRequired,
};

export default RegistrationWindowClosed;
