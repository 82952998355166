import React from 'react';

import ProfileSection from 'components/MyProfile/elements/layout/ProfileSection';
import Skills from './Skills';
import Employment from './Employment';

export default () => (
  <ProfileSection sName='career' sTitle='Career'>
    <Employment />
    <Skills />
  </ProfileSection>
);
