export const aSocialIdps = [
  { id: 'Google', icon: 'google', name: 'Google' },
  // { id: 'Facebook', icon: 'facebook', name: 'Facebook' }, // @TODO: Re-enable once business verification is complete.
  { id: 'LoginWithAmazon', icon: 'amazon', name: 'Amazon' },
  { id: 'SignInWithApple', icon: 'apple', name: 'Apple' },
];

export const fnGetIdpIconFromUsername = (sUsername) => {
  let sIdpIcon = '';
  switch (true) {
    case /Amazon/i.test(sUsername):
      sIdpIcon = 'amazon';
      break;
    case /Apple/i.test(sUsername):
      sIdpIcon = 'apple';
      break;
    case /Facebook/i.test(sUsername):
      sIdpIcon = 'facebook';
      break;
    case /Google/i.test(sUsername):
      sIdpIcon = 'google';
      break;
    default:
      break;
  }
  return sIdpIcon;
};
