import * as Yup from 'yup';

export const oRegistrationOptionsValidationSchema = Yup.object().shape({
  guests: Yup.array().of(
    Yup.object().shape({
      REG_OPTION: Yup.object().required('Please select an option.'),
    })
  ),
});

export const sRegOptionsFormId = 'event-registration-reg-options-form';
