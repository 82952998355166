import React from 'react';
import PropTypes from 'prop-types';

import { TextField, DateRange } from 'components/Form/Fields';
import NewRecordForm from 'components/Form/NewRecordForm';
import { OptionsModal } from 'components/Form';
import {
  ChooseType,
  SelectPrivacy,
} from 'components/Form/OptionsModal/DefaultFields';
import { fnElementRefPropType } from 'utils/customPropTypes';
import actions from '../redux/actions';
import { addEditGroupSchema } from './schemas';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const FormBody = ({ firstFieldRef, newPositionOnly, ...formikProps }) => (
  <ProfileRecordLayout
    left={
      <>
        <TextField
          sId='organization-name'
          sName='ORGANIZATIONNAME'
          sLabel='Board Name'
          oFieldRef={!newPositionOnly ? firstFieldRef : null}
          bReadOnly={newPositionOnly}
        />
        <TextField
          sId='position'
          sName='POSITION'
          sLabel='Position'
          oFieldRef={newPositionOnly ? firstFieldRef : null}
        />
        <DateRange
          oStartDateProps={{
            sName: 'STARTDATE',
            sLabel: 'Start Date',
            sId: 'start-date',
          }}
          oEndDateProps={{
            sName: 'ENDDATE',
            sLabel: 'End Date',
            sId: 'end-date',
          }}
        />
      </>
    }
    right={
      <OptionsModal
        actions={actions}
        data={formikProps.values}
        isForm={false}
        formikProps={formikProps}
        forceExpanded
        name='Board'
      >
        <ChooseType
          id={formikProps.values?.ID}
          name='notableAffiliations'
          codeTablesKey='NOTABLEAFFILIATIONS'
        />
        <SelectPrivacy data={formikProps.values} isPrivacyChangeable={false} />
      </OptionsModal>
    }
  />
);

FormBody.defaultProps = {
  firstFieldRef: null,
  newPositionOnly: false,
};

FormBody.propTypes = {
  newPositionOnly: PropTypes.bool,
  firstFieldRef: fnElementRefPropType,
};

const NewGroupForm = ({
  initialValues,
  newPositionOnly,
  setNewPositionRowId,
}) => {
  let sFormId = 'new-board-form';
  if (initialValues.ID) {
    sFormId = `new-board-form-${initialValues.ID}`;
  } else if (newPositionOnly) {
    sFormId = 'new-board-position-form';
  }

  return (
    <NewRecordForm
      initialValues={initialValues}
      addItemLabel={`${initialValues.ID ? 'Edit Group' : 'New Group'}`}
      formId={sFormId}
      validationSchema={addEditGroupSchema}
      validateOnBlur={false}
      validateOnChange={false}
      actions={actions}
      FormBody={FormBody}
      codeTablesKey='NOTABLEAFFILIATIONS'
      submitActionName={`${initialValues.ID ? 'update' : 'create'}`}
      startsActive={newPositionOnly}
      onHide={() => {
        setNewPositionRowId('');
      }}
    />
  );
};

NewGroupForm.defaultProps = {
  initialValues: {
    ID: '',
    ORGANIZATIONNAME: '',
    POSITION: '',
    TYPE: 'Advisory Board/Council',
    STARTDATE: '',
    ENDDATE: '',
  },
  newPositionOnly: false,
  setNewPositionRowId: () => {},
};

NewGroupForm.propTypes = {
  initialValues: PropTypes.shape({
    ID: PropTypes.string,
    ORGANIZATIONNAME: PropTypes.string,
    POSITION: PropTypes.string,
    TYPE: PropTypes.string,
    STARTDATE: PropTypes.string,
    ENDDATE: PropTypes.string,
  }),
  newPositionOnly: PropTypes.bool,
  setNewPositionRowId: PropTypes.func,
};

export default NewGroupForm;
