import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../../Layout/Accordion';
import ThankYouNotes from '../ThankYouNotes';
import SectionHeader from '../SectionHeader';
import FundOriginStory from './FundOriginStory';
import FundDonors from './FundDonors';
import { scholarshipPropType } from './helpers';

const ScholarshipList = ({ aScholarships }) => (
  <>
    <SectionHeader sTitle='My Scholarships' />
    <div className='scholarships__list'>
      {aScholarships.map((oScholarship) => (
        <Accordion
          key={oScholarship.FUNDPURPOSEID}
          mTitle={oScholarship.FUNDNAME}
          sSize='medium'
          sGtmCategory='Scholarship'
        >
          {oScholarship.FUND_ORIGIN && (
            <FundOriginStory sStory={oScholarship.FUND_ORIGIN} />
          )}
          {oScholarship.FUND_DONORS.length > 0 && (
            <FundDonors aDonors={oScholarship.FUND_DONORS} />
          )}
          <ThankYouNotes scholarship={oScholarship} />
        </Accordion>
      ))}
    </div>
  </>
);

ScholarshipList.defaultProps = {
  aScholarships: [],
};

ScholarshipList.propTypes = {
  aScholarships: PropTypes.arrayOf(scholarshipPropType),
};

export default ScholarshipList;
