import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import Createable from 'react-select/creatable';
import AsyncCreateable from 'react-select/async-creatable';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { fnFuzzySearch } from 'utils/common';
import { fnElementRefPropType } from 'utils/customPropTypes';
import useFloatingSelectLabel from './useFloatingSelectLabel';

const sClassNamePrefix = 'reactSelect';

export const Select = ({
  label,
  id,
  name,
  placeholder,
  options,
  valueKey,
  onChange,
  className,
  getOptionLabel,
  getOptionValue,
  allowUserToAddOptions,
  getNewOptionData,
  fieldRef,
  bIsLabelHidden,
  bIsClearable,
  defaultOptions,
  sHelpText,
  bIsMultiSelect,
  sCypressId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);

  // Allows <Select /> to be cleared when overall form is cleared
  const [selectedOption, setSelectedOption] = useState(
    // Initialize selectedOption to the Formik field data if we've got it
    (field.value || {}).ID ||
      (field.value || {}).LOOKUPID ||
      (field.value || {}).NAME
      ? field.value
      : null
  );

  const isRegularSelect = options.length < 1000;

  const {
    sLabelClass,
    sErrorClass,
    fnHandleFocus,
    fnHandleBlur,
    oCustomStyles,
  } = useFloatingSelectLabel(name, selectedOption);

  const handleChange = (option) => {
    setSelectedOption(option);

    // Get string for chosen option to send into Formik
    let newFieldValue = option;
    if (option === null) {
      newFieldValue = null;
    } else if (valueKey) {
      newFieldValue = option[valueKey];
    }
    helpers.setValue(newFieldValue);

    // Call any incoming onChange function
    onChange(option);
  };

  // Keep selected option in sync with Formik value
  useEffect(() => {
    // Clear ReactSelect when overall form gets cleared
    if (
      (field.value || {}).ID === null ||
      (field.value || {}).LOOKUPID === null ||
      (field.value || {}).NAME === ''
    ) {
      setSelectedOption(null);
    } else if (!R.isEmpty(field.value)) {
      let opt = field.value;
      if (valueKey) {
        opt = options.find((option) => option[valueKey] === field.value);
      }
      setSelectedOption(opt);
    }
  }, [field.value, options, valueKey]);

  const getNoOptionsMsg = ({ inputValue }) => {
    let message = `No results found for "${inputValue}"`;

    // Remind user to type a search term if they haven't entered anyting in a regular select
    // or if they've entered less than 4 characters in an async select
    if (
      (isRegularSelect && inputValue === '') ||
      (!isRegularSelect && inputValue.length < 4)
    ) {
      message = 'Type to search';
    }

    return <span className='t-paragraph--small'>{message}</span>;
  };

  // Only used for AsyncSelect
  const loadOptions = async (inputValue) => {
    // Don't search until the user enters at least 2 characters
    if (inputValue.length < 2) {
      return [];
    }

    /* @TODO: Once tested thoroughly, make the seachKey an input prop
     with a default and determine if applying fuzzy to the other
     Select types is required. */
    const oFuzzyResults = fnFuzzySearch(
      options,
      [
        'ATTENDNAME',
        'NAME',
        'LASTNAME',
        'FIRSTNAME',
        'NICKNAME',
        'DESCRIPTION',
        'label',
      ],
      inputValue
    );
    const oFormattedFuzzyResults = oFuzzyResults.map((oResult) => oResult.obj);
    return oFormattedFuzzyResults;
  };

  let theSelect = '';
  switch (true) {
    case isRegularSelect && allowUserToAddOptions:
      theSelect = (
        <Createable
          id={sCypressId}
          inputId={id || name}
          name={name}
          value={selectedOption}
          className={`reactSelect__container ${className}`}
          classNamePrefix={sClassNamePrefix}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          options={options}
          placeholder={placeholder}
          onChange={handleChange}
          isClearable={bIsClearable}
          noOptionsMessage={getNoOptionsMsg}
          getNewOptionData={getNewOptionData}
          ref={fieldRef}
          onFocus={fnHandleFocus}
          onBlur={fnHandleBlur}
          styles={oCustomStyles}
          isMulti={bIsMultiSelect}
        />
      );
      break;
    case !isRegularSelect && allowUserToAddOptions:
      theSelect = (
        <AsyncCreateable
          id={sCypressId}
          inputId={id || name}
          name={name}
          value={selectedOption}
          className={`reactSelect__container ${className}`}
          classNamePrefix={sClassNamePrefix}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          placeholder={placeholder}
          onChange={handleChange}
          isClearable={bIsClearable}
          cacheOptions
          defaultOptions={[]}
          loadOptions={loadOptions}
          noOptionsMessage={getNoOptionsMsg}
          getNewOptionData={getNewOptionData}
          ref={fieldRef}
          onFocus={(e) => {
            // This allows the mousedown event to be captured by other elements
            // like components using closeOnOutsideClick
            const event = new Event('mousedown');
            document.dispatchEvent(event);
            fnHandleFocus();
          }}
          onBlur={fnHandleBlur}
          styles={oCustomStyles}
          isMulti={bIsMultiSelect}
        />
      );
      break;
    case !isRegularSelect && !allowUserToAddOptions:
      theSelect = (
        <AsyncSelect
          id={sCypressId}
          inputId={id || name}
          name={name}
          value={selectedOption}
          className={`reactSelect__container ${className}`}
          classNamePrefix={sClassNamePrefix}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          placeholder={placeholder}
          onChange={handleChange}
          isClearable={bIsClearable}
          cacheOptions
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          noOptionsMessage={getNoOptionsMsg}
          loadingMessage={() => (
            <span className='t-paragraph--small'>Loading...</span>
          )}
          ref={fieldRef}
          styles={oCustomStyles}
          onFocus={fnHandleFocus}
          onBlur={fnHandleBlur}
          isMulti={bIsMultiSelect}
        />
      );
      break;
    default:
      theSelect = (
        <ReactSelect
          id={sCypressId}
          inputId={id || name}
          name={name}
          value={selectedOption}
          className={`reactSelect__container ${className}`}
          classNamePrefix={sClassNamePrefix}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          options={options}
          placeholder={placeholder}
          onChange={handleChange}
          isClearable={bIsClearable}
          noOptionsMessage={getNoOptionsMsg}
          ref={fieldRef}
          onFocus={fnHandleFocus}
          onBlur={fnHandleBlur}
          styles={oCustomStyles}
          isMulti={bIsMultiSelect}
        />
      );
      break;
  }

  return (
    <div className={`boxField boxField--select ${sErrorClass}`}>
      {theSelect}
      {!bIsLabelHidden && (
        <label htmlFor={id || name} className={`select__label ${sLabelClass}`}>
          {label}
        </label>
      )}
      {sHelpText && <p className='field__helpText'>{sHelpText}</p>}
      {meta.error && <p className='field__errorMessage'>{meta.error}</p>}
    </div>
  );
};

Select.defaultProps = {
  label: '',
  id: '',
  name: '',
  placeholder: 'Choose an option...',
  options: [],
  valueKey: '',
  onChange: () => {},
  isLabelHidden: false,
  className: '',
  getOptionLabel: (option) => option.label || option.DESCRIPTION || '',
  getOptionValue: (option) => option.value || option.ID || '',
  allowUserToAddOptions: false,
  getNewOptionData: (option) => ({ label: option, value: option }),
  bIsLabelHidden: false,
  bIsClearable: true,
  fieldRef: null,
  defaultOptions: [],
  sHelpText: '',
  bIsMultiSelect: false,
  sCypressId: '',
};

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  valueKey: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  allowUserToAddOptions: PropTypes.bool,
  getNewOptionData: PropTypes.func,
  bIsLabelHidden: PropTypes.bool,
  bIsClearable: PropTypes.bool,
  fieldRef: fnElementRefPropType,
  defaultOptions: PropTypes.array,
  sHelpText: PropTypes.string,
  bIsMultiSelect: PropTypes.bool,
  sCypressId: PropTypes.string,
};

export default Select;
