import PageLayout from 'components/Layout/PageLayout';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import usePageTitle from 'utils/hooks/usePageTitle';

import ClaimedClassmates from './elements/ClaimedClassmates';
import { MyClassmates } from './elements/MyClassmates';

/**
 * @description - generates the classmates and claimed classmates views
 *
 * @author Tim Holder <holdertm@wfu.edu>
 * @copyright 2021 Wake Forest University, AIT
 */

const Volunteer = () => {
  const [classYear, setClassYear] = useState('');
  usePageTitle('Volunteers');

  return (
    <PageLayout sTitle='Volunteers' bHasBackground={false}>
      <MyClassmates setClassYear={setClassYear} classYear={classYear} />
      <ClaimedClassmates classYear={classYear} />
      {/* This tells <BrowserRouter /> where to render nested routes - here, the public profile modal */}
      <Outlet />
    </PageLayout>
  );
};

export default Volunteer;
