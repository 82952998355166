import React from 'react';
import PropTypes from 'prop-types';

export const formatPhoneNumber = (rawPhoneNumber) => {
  if (rawPhoneNumber.length !== 10) {
    return rawPhoneNumber;
  }
  return rawPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

const PhoneRecord = ({ data }) => (
  <>
    <p className='recordMiniLabel t-label'>{data.TYPE?.DESCRIPTION}</p>
    <div className='record t-paragraph--medium'>
      {data.COUNTRY ? data.COUNTRY.ABBRV : ''} {formatPhoneNumber(data.NUMBER)}
    </div>
  </>
);

PhoneRecord.propTypes = {
  data: PropTypes.shape({
    NUMBER: PropTypes.string.isRequired,
    COUNTRY: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
      ABBRV: PropTypes.string,
      NAME_ABBRV: PropTypes.string,
    }),
    PRIVACY: PropTypes.string.isRequired,
    ID: PropTypes.string.isRequired,
    TYPE: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PhoneRecord;
