import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Collapsible from 'react-collapsible';
import * as R from 'ramda';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import { useActiveFormSection } from '../../../../utils/hooks/useActiveFormSection';
import Name from './Name';
import Phone from './Phone';
import Email from './Email';
import Address from './Address';
import Birthdate from './Birthdate';
import Religion from './Religion';
import NameWithQuickFind from './NameWithQuickFind';
import ProfileSection from '../layout/ProfileSection';

export const PersonalSection = () => {
  const [activeFormSection] = useActiveFormSection();
  const [isMoreExpanded, setIsMoreExpanded] = useState(
    activeFormSection === 'personal'
  );
  const profileData = useSelector((state) => state.Profile);
  const toggleMoreExpanded = () => setIsMoreExpanded(!isMoreExpanded);

  // Only show the more/less button in edit mode or when there's more content to show
  const shouldShowMoreButton =
    activeFormSection === 'personal' ||
    !R.isEmpty(profileData.SPOUSE) ||
    !R.isEmpty(profileData.CHILD) ||
    !R.isEmpty(profileData.BIOGRAPHICAL.RELIGION);

  return (
    <ProfileSection bIsEditable sName='personal' sTitle='Personal'>
      <Name name='name' />
      <Phone />
      <Email />
      <Address />
      <Birthdate />
      {shouldShowMoreButton && (
        <>
          <div
            className={`personal__moreBtnWrapper${
              isMoreExpanded ? '--expanded' : ''
            }`}
          >
            <ButtonWithIcon
              fnHandleClick={toggleMoreExpanded}
              sIcon={isMoreExpanded ? 'collapse' : 'expand'}
              sIconPosition='right'
            >
              {isMoreExpanded ? 'Less' : 'More'}
            </ButtonWithIcon>
          </div>
          <Collapsible open={isMoreExpanded} overflowWhenOpen='visible'>
            <Name
              name='spouse'
              recordPath={['SPOUSE']}
              sTitle='Spouse'
              bIsPrivacyChangeable
            />
            <Name
              name='child'
              recordPath={['CHILD']}
              sTitle='Children'
              bAllowMultiple
            />
            <NameWithQuickFind
              name='parent'
              recordPath={['PARENT']}
              sTitle='Parents'
              bAllowMultiple
              bIsPrivacyChangeable={false}
              codeTablesRelationshipType='Parental'
            />
            <NameWithQuickFind
              name='wfuFamily'
              recordPath={['WFUFAMILY']}
              sTitle='Wake Forest Family'
              label='WFU Family'
              bAllowMultiple
              bIsPrivacyChangeable={false}
              codeTablesRelationshipType='Familial'
            />
            <Religion />
          </Collapsible>
        </>
      )}
    </ProfileSection>
  );
};

export default PersonalSection;
