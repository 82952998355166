export const fnGetIconByType = (sType) => {
  let sIconName = '';
  switch (sType) {
    case 'constituent':
      sIconName = 'user';
      break;
    case 'employer':
      sIconName = 'building';
      break;
    case 'location':
      sIconName = 'mapMarker';
      break;
    default:
      break;
  }
  return sIconName;
};

export const fnGetAllAsteriskIndexes = (sText) => {
  const aAllAsteriskIndexes = sText
    .split('')
    .reduce((aCollector, sCharacter, iIndex) => {
      if (sCharacter === '*') {
        aCollector.push(iIndex);
      }
      return aCollector;
    }, []);
  return aAllAsteriskIndexes;
};

export const fnCombineArrayItemsIntoPairs = (aArray = []) => {
  const bIsEvenNumberOfArrayItems = aArray.length % 2 === 0;
  if (!bIsEvenNumberOfArrayItems) {
    throw new Error("Can't combine an odd number of array items into pairs");
  }
  let iCurrentPairIndex = 0;
  const aPairs = aArray.reduce((aResult, mItem) => {
    if (!aResult[iCurrentPairIndex]) {
      // if there's nothing, create an array with the first item
      aResult[iCurrentPairIndex] = [mItem];
    } else {
      // otherwise, add the new item to the existing array
      aResult[iCurrentPairIndex].push(mItem);
      iCurrentPairIndex++;
    }
    return aResult;
  }, []);
  return aPairs;
};

export const fnHighlightSearchResult = (oSearchHit) => {
  const sFullName = oSearchHit.fields.name[0];
  const sHighlight = oSearchHit.highlights.name;

  const aAllAsteriskIndexes = fnGetAllAsteriskIndexes(sHighlight);
  const aAsteriskPairs = fnCombineArrayItemsIntoPairs(aAllAsteriskIndexes);
  let sHighlightedResult = sFullName;
  aAsteriskPairs.forEach((aPair) => {
    const sTextToHighlight = sHighlight.substring(aPair[0] + 1, aPair[1]);
    sHighlightedResult = sHighlightedResult.replace(
      sTextToHighlight,
      `<strong>${sTextToHighlight}</strong>`
    );
  });
  return sHighlightedResult;
};
