import React from 'react';
import PropTypes from 'prop-types';

import { ChooseType, SelectPrivacy, SelectPrimary } from './DefaultFields';

const OptionsModalDefaultFields = ({
  data,
  privacyLabels,
  name,
  textWhenPrimary,
}) => (
  <>
    {(data || {}).primary !== null && (
      <SelectPrimary
        textWhenPrimary={
          textWhenPrimary ||
          (name ? `This is my primary ${name.toLowerCase()}` : '')
        }
      />
    )}
    {!data?.ID && (
      <ChooseType
        id={data?.ID}
        name={name}
        codeTablesKey={name.toUpperCase()}
      />
    )}
    <SelectPrivacy data={data} privacyLabels={privacyLabels} />
  </>
);

OptionsModalDefaultFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  privacyLabels: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  textWhenPrimary: PropTypes.string,
};

OptionsModalDefaultFields.defaultProps = {
  data: null,
  privacyLabels: ['Public', null, 'Private'],
  name: '',
  textWhenPrimary: '',
};

export default OptionsModalDefaultFields;
