import { object } from 'yup';

import { privacyFieldSchema } from 'utils/schemas/fields';

export const addJobSkillSchema = object({
  skill: object().nullable().required('Please choose a skill.'),
});

export const editJobSkillSchema = object({
  PRIVACY: privacyFieldSchema,
});
