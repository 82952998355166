import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';

const ScrollIndicator = () => {
  const [scrollIndicatorClass, setScrollIndicatorClass] = useState('');

  // Hide scroll indicator once user scrolls at least 100px down the page
  const handleScroll = (event: Event) => {
    if ((event.target as HTMLElement)?.scrollTop > 100) {
      setScrollIndicatorClass('scrollIndicator--byebye');
    } else {
      setScrollIndicatorClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <div className={`scrollIndicator ${scrollIndicatorClass}`}>
      <span className='scrollIndicator__text'>Scroll</span>
      <span className='scrollIndicator__icon'>
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome.expand} />
      </span>
    </div>
  );
};

export default ScrollIndicator;
