import React from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import * as R from 'ramda';

import { CancelButton, SubmitButton } from 'components/Form/Buttons';
import { TextField } from 'components/Form/Fields';
import ProfileRecordLayout from 'components/MyProfile/elements/layout/ProfileRecordLayout';
import JobFieldSet from './JobFieldset';
import { oEditEmployerGroupSchema } from './helpers';
import actions from '../../redux/actions';
import EmployerOptions from './EmployerOptions';

const EditEmployerGroupForm = ({ data, fnCloseForm }) => {
  const fnDispatch = useDispatch();
  const {
    EMPLOYER,
    EMPLOYER_RELATIONSHIP_ID,
    RELATIONSHIP_SET_ID,
    POSITIONS,
    PRIMARY,
    PRIVACY,
  } = data;
  const aPositionsWithoutStatus = POSITIONS.map((oPosition) =>
    R.omit(['STATUS'], oPosition)
  );
  const oInitialValues = {
    EMPLOYER,
    EMPLOYER_RELATIONSHIP_ID,
    RELATIONSHIP_SET_ID,
    POSITIONS: aPositionsWithoutStatus,
    PRIMARY,
    PRIVACY,
  };

  const fnHandleSubmit = async (oFormValues) => {
    await new Promise((resolve, reject) => {
      fnDispatch(actions.update({ data: oFormValues, resolve, reject }));
    });
    fnCloseForm();
  };

  return (
    <Formik
      initialValues={oInitialValues}
      validationSchema={oEditEmployerGroupSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={fnHandleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const bShowOverlappingDatesError = formikProps.errors.POSITIONS?.some(
          (oItem) =>
            oItem.START_DATE === 'Overlapping Dates' ||
            oItem.END_DATE === 'Overlapping Dates'
        );

        return (
          <Form className='editEmployerGroupForm'>
            <ProfileRecordLayout
              left={
                <TextField
                  sId='employer'
                  sName='EMPLOYER'
                  sLabel='Employer'
                  bReadOnly
                />
              }
              right={<EmployerOptions />}
            />
            <FieldArray name='POSITIONS'>
              {(fieldArrayProps) =>
                formikProps.values.POSITIONS.map((oPosition, iIndex) => (
                  <JobFieldSet iIndex={iIndex} key={oPosition.ID} />
                ))
              }
            </FieldArray>
            {bShowOverlappingDatesError && (
              <p className='form__overallError'>
                Employment cannot be saved with overlapping start and end dates.
              </p>
            )}
            <div className='editEmployerGroupForm__controls'>
              <SubmitButton />
              <CancelButton
                fnHandleClick={fnCloseForm}
                bIsDisabled={formikProps.isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditEmployerGroupForm;
