/**
 * Determines if an @alumni.wfu.edu Google username is valid
 * based on Google's requirements (https://support.google.com/a/answer/9193374)
 * and WFU specific requirements.
 */
class WfuGoogleUsernameValidator {
  aRestrictedPrefixes = ['s-', 'is-'];

  aRestrictedUsernames = [
    'abuse',
    'postmaster',
    'root',
    'admin',
    'administrator',
    'nobody',
    'president',
    'security',
    'wfu_ops_gmanager',
  ];

  constructor() {
    this.sUsername = '';
    this.bIsValid = false;
    this.sError = '';
  }

  /**
   * Determines if an @alumni.wfu.edu Google username is valid.
   *
   * @param {string} username - Username to validate.
   * @return {object} Validation results; includes boolean and error message.
   */
  validate(username) {
    this.resetValidator();

    if (!username) {
      this.bIsValid = false;
      this.sError = 'Please enter a username.';
    } else {
      this.sUsername = username.toLowerCase();
      this.runValidationChecks();
    }

    const oResults = {
      bIsValid: this.bIsValid,
      sError: this.sError,
    };
    return oResults;
  }

  resetValidator() {
    this.bIsValid = false;
    this.sError = '';
  }

  /**
   * Runs username through validation checks until one fails or all checks pass.
   */
  runValidationChecks() {
    if (this.isRestrictedUsername()) {
      this.sError = `The following usernames are reserved for WFU use only: ${this.aRestrictedUsernames.join(
        ', '
      )}`;
    } else if (this.containsRestrictedPrefix()) {
      this.sError = `Your username cannot start with any of the following: ${this.aRestrictedPrefixes.join(
        ', '
      )}`;
    } else if (this.sUsername.includes('@alumni.wfu.edu')) {
      this.sError =
        'Please enter your username only. You don\'t need to include "@alumni.wfu.edu."';
    } else if (this.containsInvalidCharacters()) {
      this.sError =
        "Usernames can only contain letters (a–z), numbers (0–9), and the following special characters: dashes (-), underscores (_), apostrophes ('), and periods (.).";
    } else if (this.startsWithPeriod()) {
      this.sError = 'Usernames cannot start with a period (.).';
    } else if (this.containsConsecutivePeriods()) {
      this.sError =
        'Username cannot contain more than one period (.) in a row.';
    } else if (this.sUsername.length < 3) {
      this.sError = 'Usernames must be at least 3 characters long.';
    } else if (this.sUsername.length > 64) {
      this.sError = 'Usernames must be 64 characters or less.';
    } else {
      this.bIsValid = true;
    }
  }

  isRestrictedUsername() {
    const bIsRestrictedUsername =
      this.aRestrictedUsernames.indexOf(this.sUsername) !== -1;
    return bIsRestrictedUsername;
  }

  containsRestrictedPrefix() {
    let bHasRestrictedPrefix = false;
    const sFirstThreeChars = this.sUsername.substr(0, 3);
    bHasRestrictedPrefix = this.aRestrictedPrefixes.some((sPrefix) =>
      sFirstThreeChars.includes(sPrefix)
    );
    return bHasRestrictedPrefix;
  }

  containsInvalidCharacters() {
    const sValidCharsRegEx = /^[a-zA-Z0-9-_'.]+$/;
    const bContainsInvalidChars = !this.sUsername.match(sValidCharsRegEx);
    return bContainsInvalidChars;
  }

  startsWithPeriod() {
    let bStartsWithPeriod = false;
    const sFirstChar = this.sUsername.charAt(0);
    if (sFirstChar === '.') {
      bStartsWithPeriod = true;
    }
    return bStartsWithPeriod;
  }

  containsConsecutivePeriods() {
    let bContainsConsecutivePeriods = false;
    if (this.sUsername.indexOf('..') !== -1) {
      bContainsConsecutivePeriods = true;
    }
    return bContainsConsecutivePeriods;
  }
}

export default WfuGoogleUsernameValidator;
