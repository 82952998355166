import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import SelectContactForm from 'components/Checkout/CheckoutReview/SelectContactForm';
import NewEmailForm from 'components/MyProfile/elements/Personal/Email/NewEmailForm';
import selectEmailOptionsForCheckout from 'components/Checkout/CheckoutReview/CheckoutEmails/selectors';
import EventRegistrationContext from 'components/EventRegistration/EventRegistrationContext';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { selectNextEventRegStep } from 'components/EventRegistration/EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';

const sFormId = 'event-registration-change-email-form';

const ChangeEmailAddress = ({ sEmail }) => {
  const { fnSetCurrentFormId } = useContext(EventRegistrationContext);
  const aEmails = useSelector(selectEmailOptionsForCheckout);
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(state, window.location.pathname)
  );

  useEffect(() => {
    fnSetCurrentFormId(sFormId);
  }, [fnSetCurrentFormId]);

  return (
    <div>
      <h1 className='eventRegistrationContactInfo__instructions t-paragraph--medium'>
        Please select the best email address to reach you.
      </h1>
      <SelectContactForm
        aContactData={aEmails}
        sSelectionLabel='email'
        oInitialValues={{ email: sEmail }}
        handleSubmit={(values) => {
          fnDispatch(
            CheckoutActions.update({
              email: values.email,
            })
          );
          fnNavigate(sNextStepUrl);
        }}
        sFormId={sFormId}
      />
      <NewEmailForm
        handleSubmitSideEffects={(oFormData) => {
          fnDispatch(
            CheckoutActions.update({
              email: oFormData.EMAILADDRESS,
            })
          );
        }}
      />
    </div>
  );
};

ChangeEmailAddress.propTypes = {
  sEmail: PropTypes.string.isRequired,
};

export default ChangeEmailAddress;
