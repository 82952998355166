import React from 'react';
import PropTypes from 'prop-types';

import fnBuildDeceasedText from 'components/MyProfile/elements/Header/fnBuildDeceasedText';
import LinkWithSourcePath from 'utils/LinkWithSourcePath';
import ConstituentAvatar from '../../../ConstituentAvatar';
import MyNetworkStatus from './MyNetworkStatus';
import TotalPrivacy from './TotalPrivacy';
import { fGetPrimaryRecord } from '../../../../utils/common';
import EditableAvatar from './EditableAvatar';

/**
 * Profile header section.
 */
const Header = ({ data, bIsPublicMode }) => {
  const { DECEASEDDATE, LOOKUPID } = data.BIOGRAPHICAL;
  const oPrimaryAddress = fGetPrimaryRecord(data.ADDRESS);
  const oPrimaryEmployment = fGetPrimaryRecord(data.EMPLOYMENT);
  const bIsRetiredFromPrimaryEmployment = oPrimaryEmployment?.POSITIONS.some(
    (oPosition) => oPosition.IS_RETIRED
  );

  return (
    <div className='profileHeading'>
      <div
        className={`profileHeader__avatarWrapper ${
          bIsPublicMode ? 'profileHeader__avatarWrapper--public' : ''
        }`}
      >
        {bIsPublicMode ? (
          <ConstituentAvatar
            lookupId={data.BIOGRAPHICAL.LOOKUPID}
            sFirstName={data.BIOGRAPHICAL.NICKNAME}
            sLastName={data.BIOGRAPHICAL.LASTNAME}
            bHasPhoto={data.BIOGRAPHICAL.HAS_PHOTO}
            sSize='extraLarge'
          />
        ) : (
          <EditableAvatar />
        )}

        {!bIsPublicMode && (
          <LinkWithSourcePath
            className='profileHeader__viewPublicBtn button--tertiary'
            to={`/main/profile/${LOOKUPID}`}
            referringComponent='profile'
          >
            View My Public Profile
          </LinkWithSourcePath>
        )}

        {data.BIOGRAPHICAL.MYNETWORKSTATUS !== 'SELF' && (
          <MyNetworkStatus
            status={data.BIOGRAPHICAL.MYNETWORKSTATUS}
            lookupId={data.BIOGRAPHICAL.LOOKUPID}
            name={data.BIOGRAPHICAL.NAME}
          />
        )}
      </div>
      {!bIsPublicMode && (
        <TotalPrivacy
          lookupId={data.BIOGRAPHICAL.LOOKUPID}
          isPrivate={data.BIOGRAPHICAL.TOTAL_PRIVACY}
        />
      )}
      {DECEASEDDATE && (
        <p className='deceasedLabel t-label'>
          {fnBuildDeceasedText(DECEASEDDATE)}
        </p>
      )}
      <h2 className='profileHeader__name t-paragraph--large'>
        {data.BIOGRAPHICAL.NAME}
      </h2>
      {oPrimaryAddress && (
        <p className='profileHeader__location'>
          {[
            oPrimaryAddress.ADDRESSCITY,
            oPrimaryAddress.ADDRESSSTATEABBRV || oPrimaryAddress.ADDRESSCOUNTRY,
          ]
            .filter(Boolean)
            .join(', ')}
        </p>
      )}
      {oPrimaryEmployment && (
        <p className='profileHeader__employer'>
          {oPrimaryEmployment.EMPLOYER}
          {bIsRetiredFromPrimaryEmployment && ' (Retired)'}
        </p>
      )}
    </div>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    BIOGRAPHICAL: PropTypes.shape({
      LOOKUPID: PropTypes.string,
      DECEASEDDATE: PropTypes.string,
      NAME: PropTypes.string,
      NICKNAME: PropTypes.string,
      LASTNAME: PropTypes.string,
      MYNETWORKSTATUS: PropTypes.string,
      TOTAL_PRIVACY: PropTypes.bool,
      HAS_PHOTO: PropTypes.bool,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    ADDRESS: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    EMPLOYMENT: PropTypes.array,
  }),
  bIsPublicMode: PropTypes.bool,
};

Header.defaultProps = {
  data: null,
  bIsPublicMode: true,
};

export default Header;
