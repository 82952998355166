import React from 'react';

type GiftTagProps = {
  giftType?: string;
};

const GiftTag = ({ giftType = '' }: GiftTagProps) => {
  let label: string = '';
  switch (true) {
    case giftType === 'Matching Gift':
      label = 'Matching Gift';
      break;
    case giftType.includes('Planned Gift'):
      // Grab the planned gift vehicle from the format: `Planned Gift: Vehicle`
      const giftTypeParts: Array<string> = giftType.split(': ');
      label = giftTypeParts.pop() ?? '';
      break;
    default:
      break;
  }
  return label ? <div className='myGiving__giftType'>{label}</div> : null;
};

export default GiftTag;
