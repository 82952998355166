import React from 'react';

import { SkeletonCircle, SkeletonText } from 'components/LoadingZone/Skeleton';

const AdvancedSearchResultsSkeleton = () => (
  <div className='advancedSearchResultsListSkeleton'>
    <div className='advancedSearchResultListItemSkeleton'>
      <SkeletonCircle size='large' />
      <div className='advancedSearchResultListItemSkeleton__details'>
        <SkeletonText size='medium' width='60%' />
        <SkeletonText size='medium' width='40%' />
        <SkeletonText size='medium' width='40%' />
      </div>
    </div>
    <div className='advancedSearchResultListItemSkeleton'>
      <SkeletonCircle size='large' />
      <div className='advancedSearchResultListItemSkeleton__details'>
        <SkeletonText size='medium' width='60%' />
        <SkeletonText size='medium' width='40%' />
        <SkeletonText size='medium' width='40%' />
      </div>
    </div>
    <div className='advancedSearchResultListItemSkeleton'>
      <SkeletonCircle size='large' />
      <div className='advancedSearchResultListItemSkeleton__details'>
        <SkeletonText size='medium' width='60%' />
        <SkeletonText size='medium' width='40%' />
        <SkeletonText size='medium' width='40%' />
      </div>
    </div>
  </div>
);

export default AdvancedSearchResultsSkeleton;
