import * as R from 'ramda';

export default class MyClassmatesAdvSearchOptionsBuilder {
  constructor(classmateList) {
    this.classmateList = classmateList;
  }

  get classmateList() {
    return this.classmates;
  }

  set classmateList(classmateList) {
    this.classmates = classmateList || [];
  }

  getUniqueOptionsFromFlatArray(sKey) {
    const allOptions = this.classmateList.reduce((result, classmate) => {
      if (classmate[sKey]) {
        classmate[sKey].forEach((item) => {
          result.push({ ID: item, DESCRIPTION: item });
        });
      }
      return result;
    }, []);
    const uniqueOptions = R.uniq(allOptions);
    return uniqueOptions;
  }

  getEducProgramOptions() {
    const allEducProgramOptions = this.classmateList.map((option) => ({
      ID: option.EDUCATIONALPROGRAM,
      NAME: option.EDUCATIONALPROGRAM,
    }));
    const uniqueEducProgramOptions = R.uniq(allEducProgramOptions);
    return uniqueEducProgramOptions;
  }

  getEmployerOptions() {
    const allEmployerOptions = this.classmateList.map(
      (option) =>
        option.EMPLOYER && {
          ID: option.EMPLOYER,
          NAME: option.EMPLOYER,
          TYPE: null,
        }
    );
    const uniqueEmployers = R.uniq(allEmployerOptions);
    return uniqueEmployers;
  }

  getRegionOptions() {
    const allStateOptions = this.classmateList.reduce((result, item) => {
      if (item.STATE) {
        result.push({
          ID: item.STATE,
          NAME_ABBRV: item.STATE,
          NAME: item.STATE,
          STATE_ABBRV: item.STATE,
        });
      }
      return result;
    }, []);
    const uniqueStateOptions = R.uniq(allStateOptions);
    return uniqueStateOptions;
  }

  getAdvSearchOptions() {
    let advSearchOptions = {
      AFFINITY_GROUP: [],
      EDUCATIONALDEGREETYPE: { ID: '', DESCRIPTION: '' },
      EDUCATIONALDEPARTMENT: [],
      EDUCATIONALPROGRAM: [],
      EMPLOYERS: [],
      INVOLVEMENT: [],
      REGION: [],
    };

    if (this.classmateList.length > 0) {
      advSearchOptions = {
        AFFINITY_GROUP: this.getUniqueOptionsFromFlatArray('AFFINITYGROUPS'),
        EDUCATIONALDEGREETYPE: { ID: '', DESCRIPTION: '' },
        EDUCATIONALDEPARTMENT:
          this.getUniqueOptionsFromFlatArray('DEPARTMENTS'),
        EDUCATIONALPROGRAM: this.getEducProgramOptions(),
        EMPLOYERS: this.getEmployerOptions(),
        INVOLVEMENT: this.getUniqueOptionsFromFlatArray('INVOLVEMENTS'),
        REGION: this.getRegionOptions(),
      };
    }

    return advSearchOptions;
  }
}
