import React from 'react';
import * as R from 'ramda';

import LongTextWithToggle from 'components/Layout/LongTextWithToggle';
import ConstituentAvatar from '../../ConstituentAvatar';
import LinkWithSourcePath from '../../../utils/LinkWithSourcePath';
import { recipientPropType } from './helpers';

const formatDateToMMDDYYWithSlashes = (sOriginalDate) => {
  const oDate = new Date(sOriginalDate);
  // Month is zero-based...dumb
  let mMonth = oDate.getMonth() + 1;
  // Add leading zero
  mMonth = mMonth < 10 ? `0${mMonth}` : mMonth;

  let mDay = oDate.getDate();
  // Add leading zero
  mDay = mDay < 10 ? `0${mDay}` : mDay;

  const sYear = oDate.getFullYear().toString().substring(2);

  const sFormattedDate = `${mMonth}/${mDay}/${sYear}`;
  return sFormattedDate;
};

const Recipient = ({ data }) => {
  const { NAME, LOOKUPID, BIOGRAPHY, THANKYOUNOTES, HAS_PHOTO } = data;
  return (
    <div className='recipient'>
      <div className='recipient__nameAvatarWrapper'>
        <LinkWithSourcePath
          to={`/philanthropy/profile/${LOOKUPID}`}
          className='recipient__nameAvatarLink'
        >
          <ConstituentAvatar
            lookupId={LOOKUPID}
            bHasPhoto={HAS_PHOTO}
            sFirstName={data.FIRSTNAME}
            sLastName={data.LASTNAME}
          />
          <span className='recipient__name'>{NAME}</span>
        </LinkWithSourcePath>
      </div>
      {BIOGRAPHY && !R.isEmpty(BIOGRAPHY.NOTE) && (
        <div className='recipient__infoSection'>
          <p className='recipient__textLabel t-paragraph--medium'>About</p>
          <LongTextWithToggle
            sText={BIOGRAPHY.NOTE}
            sClassName='recipient__textContent'
          />
        </div>
      )}
      {THANKYOUNOTES.length > 0 && (
        <div className='recipient__infoSection'>
          <p className='recipient__textLabel t-paragraph--medium'>
            Notes of Gratitude
          </p>
          {THANKYOUNOTES.map((thankYou) => {
            const date = formatDateToMMDDYYWithSlashes(thankYou.DATEADDED);
            return (
              <div className='recipient__thankYou' key={date}>
                <p
                  className='recipient__textContent'
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: thankYou.NOTE }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

Recipient.propTypes = {
  data: recipientPropType.isRequired,
};

export default Recipient;
