import React from 'react';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';

const ThumbsUpThumbsDownFeedback = ({
  hasSubmittedGoodBadFeedback,
  setHasSubmittedGoodBadFeedback,
  postFeedback,
}) => {
  const handleSubmitGoodBadFeedback = (type) => {
    setHasSubmittedGoodBadFeedback(true);
    postFeedback(type);
  };

  return hasSubmittedGoodBadFeedback ? (
    <div className='feedback__thanks t-paragraph'>
      Thanks for your feedback!
    </div>
  ) : (
    <div className='feedback__thumbButtons'>
      <IconOnlyButton
        sIcon='thumbsUp'
        sAriaLabel='Good'
        fnHandleClick={() => handleSubmitGoodBadFeedback('Thumbs up')}
        className='feedback__thumbButton--up'
      />
      <IconOnlyButton
        sIcon='thumbsDown'
        sAriaLabel='Bad'
        fnHandleClick={() => handleSubmitGoodBadFeedback('Thumbs down')}
        className='feedback__thumbButton--down'
      />
    </div>
  );
};

ThumbsUpThumbsDownFeedback.propTypes = {
  hasSubmittedGoodBadFeedback: PropTypes.bool.isRequired,
  setHasSubmittedGoodBadFeedback: PropTypes.func.isRequired,
  postFeedback: PropTypes.func.isRequired,
};

export default ThumbsUpThumbsDownFeedback;
