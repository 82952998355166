import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { useActiveFormField } from 'utils/hooks/useActiveFormField';
import { AddItemButton } from 'components/Form';
import useActions from 'utils/hooks/useActions';

import './NewRecordForm.scss';
import { yupSchemaPropType } from 'utils/customPropTypes';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { CancelButton, SubmitButton } from '../Buttons';

const NewRecordForm = ({
  formId,
  addItemLabel,
  actions: actionsProp,
  onShow,
  onHide,
  handleSubmitSideEffects,
  FormBody,
  formBodyProps,
  submitActionName,
  formDataPreprocessor,
  initialValues,
  startsActive,
  validationSchema,
  codeTablesKey,
  ...props
}) => {
  const [activeFormField, setActiveFormField] = useActiveFormField(formId);
  const actions = useActions(actionsProp);
  const aTypeCodes = useCodeTablesSection(codeTablesKey);
  const firstFieldRef = useRef();

  const handleAddItemClick = (event) => {
    setActiveFormField(formId);
    onShow(event);
  };

  const fnCloseForm = (event) => {
    setActiveFormField(null);
    onHide(event);
  };

  useEffect(() => {
    if (startsActive && activeFormField !== formId) {
      setActiveFormField(formId);
    }
  }, [activeFormField, formId, setActiveFormField, startsActive]);

  const handleSubmit = async (formData) => {
    let formDataTypeFix = { ...formData };
    if (Object.keys(formDataTypeFix).includes('TYPE')) {
      // Convert type string into an object with an ID and DESCRIPTION
      const oTypeCode = aTypeCodes.find(
        (type) => type.DESCRIPTION === formData.TYPE
      );
      formDataTypeFix = {
        ...formData,
        TYPE: {
          ID: oTypeCode.ID,
          DESCRIPTION: oTypeCode.DESCRIPTION,
        },
      };
    }

    const processedData = formDataPreprocessor(formDataTypeFix);

    const submitPromise = new Promise((resolve, reject) => {
      actions[submitActionName]({
        data: processedData,
        resolve,
        reject,
      });
    });
    const result = await submitPromise;
    if (result === 'Success') {
      fnCloseForm();
    } else {
      console.error('There was an error saving form data', formData, result);
    }

    handleSubmitSideEffects(formData);
  };

  // Focus the first field when form opens
  useEffect(() => {
    if (firstFieldRef.current) {
      firstFieldRef.current.focus();
    }
  }, [firstFieldRef, activeFormField]);

  return (
    <div className='newRecordForm'>
      {activeFormField === formId ? (
        <Formik
          {...props}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <FormBody
                {...formikProps}
                firstFieldRef={firstFieldRef}
                {...formBodyProps}
              />
              <div className='newRecordForm__controls'>
                <CancelButton
                  fnHandleClick={fnCloseForm}
                  bIsDisabled={formikProps.isSubmitting}
                />
                <SubmitButton />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <AddItemButton
          sLabel={addItemLabel}
          fnHandleClick={handleAddItemClick}
          className='addRecordButton'
        />
      )}
    </div>
  );
};
NewRecordForm.defaultProps = {
  submitActionName: 'create',
  addItemLabel: ({ formId }) => `Add ${formId}`,
  onShow: () => {},
  onHide: () => {},
  handleSubmitSideEffects: () => {},
  formDataPreprocessor: (formData) => formData,
  codeTablesKey: '',
  formBodyProps: null,
  startsActive: false,
};

NewRecordForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  handleSubmitSideEffects: PropTypes.func,
  addItemLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  submitActionName: PropTypes.string,
  formDataPreprocessor: PropTypes.func,
  codeTablesKey: PropTypes.string,
  actions: PropTypes.shape({
    update: PropTypes.func,
    create: PropTypes.func,
  }).isRequired,
  FormBody: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formBodyProps: PropTypes.object,
  startsActive: PropTypes.bool,
  validationSchema: yupSchemaPropType.isRequired,
};

export default NewRecordForm;
