import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';

import { SubmitButton } from 'components/Form/Buttons';
import { fnElementRefPropType } from 'utils/customPropTypes';
import { Radio } from '../../../../../Form/Fields/Radio';
import actions from './redux/actions';
import useActions from '../../../../../../utils/hooks/useActions';

const GDPRConsentModal = ({ onSuccessfulSubmit, modalRef }) => {
  const gdprActions = useActions(actions);

  const validationSchema = object({
    gdprConsent: string()
      .oneOf(['1', '2'])
      .required('Please choose a response.'),
  });

  const handleSubmit = async (values) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const submitPromise = new Promise((resolve, reject) => {
        gdprActions.create({
          data: { CONSENTPREFERENCECODE: values.gdprConsent },
          resolve,
          reject,
        });
      });
      onSuccessfulSubmit(values);
    } catch (err) {
      console.error('Something went wrong saving GDPR consent', err);
    }
  };

  return (
    <div className='gdpr__modal' ref={modalRef}>
      <p className='gdpr__statement'>
        Wake Forest University requests permission to store information about
        your religious affiliation. This data may be used by us to communicate
        with you about opportunities we think may be of interest to you or
        regarding affinity organizations with which you are already affiliated.
        This data may be inferred from other data such as your relationships,
        memberships in affinity groups, or donations to specific funds.
      </p>
      <Formik
        initialValues={{
          gdprConsent: '',
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form className='gdpr__form'>
            <Radio
              sName='gdprConsent'
              sId='gdprConsent-yes'
              sLabel='I consent'
              mValue='2'
              bSilenceErrors
            />
            <Radio
              sName='gdprConsent'
              sId='gdprConsent-no'
              sLabel='I do not consent'
              mValue='1'
              bSilenceErrors
            />
            {errors.gdprConsent && touched.gdprConsent && (
              <p className='field__errorMessage'>{errors.gdprConsent}</p>
            )}
            <SubmitButton>Submit</SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

GDPRConsentModal.propTypes = {
  onSuccessfulSubmit: PropTypes.func,
  modalRef: fnElementRefPropType.isRequired,
};

GDPRConsentModal.defaultProps = {
  onSuccessfulSubmit: () => {},
};

export default GDPRConsentModal;
