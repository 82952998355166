import React from 'react';
import PropTypes from 'prop-types';

const EventRegistrationDetailsCard = ({ sTitle, sCypressId, children }) => (
  <div className='eventRegDetailsCard' data-cy={sCypressId}>
    <h2
      className={`eventRegDetailsCard__title ${
        sTitle === 'Waitlist Events'
          ? 'eventRegDetailsCard__title--waitlist'
          : ''
      }  t-paragraph--medium`}
    >
      {sTitle}
    </h2>
    <div className='eventRegDetailsCard__content'>{children}</div>
  </div>
);

EventRegistrationDetailsCard.propTypes = {
  sTitle: PropTypes.string.isRequired,
  sCypressId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default EventRegistrationDetailsCard;
