import React from 'react';

import { sEmptyLookupId } from 'utils/constants';
import { fnDebouncedCallCloudSearch } from './cloudSearchHelpers';

export const fnLoadOptions = (sUserInput) =>
  new Promise((resolve, reject) => {
    fnDebouncedCallCloudSearch(sUserInput, resolve);
  });

export const fnBuildNoResultsMessage = ({ inputValue }) => (
  <span className='t-paragraph--small'>
    {inputValue ? `No employers found for '${inputValue}'` : 'Type to search'}
  </span>
);

export const fnBuildLoadingMessage = () => (
  <span className='t-paragraph--small'>Loading...</span>
);

export const fnGetOptionLabel = (oOption) => oOption.NAME;

export const fnGetOptionValue = (oOption) => oOption.NAME;

export const fnGetNewOptionData = (option) => ({
  LOOKUPID: sEmptyLookupId,
  NAME: option,
});
