import React from 'react';

import { SkeletonText } from 'components/LoadingZone/Skeleton';

const QuickSearchResultsListSkeleton = () => (
  <div className='quickSearchResultsListSkeleton'>
    <div className='quickSearchResultsListSkeleton__item'>
      <SkeletonText size='medium' width='20px' />
      <SkeletonText size='medium' width='80%' />
    </div>
    <div className='quickSearchResultsListSkeleton__item'>
      <SkeletonText size='medium' width='20px' />
      <SkeletonText size='medium' width='40%' />
    </div>
    <div className='quickSearchResultsListSkeleton__item'>
      <SkeletonText size='medium' width='20px' />
      <SkeletonText size='medium' width='60%' />
    </div>
  </div>
);

export default QuickSearchResultsListSkeleton;
