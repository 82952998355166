import { getWakeNetworkHomeUrl } from '../../config';
import aFormConfig from './formConfig.json';

/**
 * Gets the base path of the checkout (ie 'select' step).
 *
 * @param {object} oLocation - Location object.
 *
 * @returns {string}
 */
export const getCheckoutBasePath = (oLocation) => {
  const sPathName = oLocation.pathname;
  return sPathName
    .replace('/review', '')
    .replace('/payment', '')
    .replace('/confirmation', '');
};

/**
 * Gets the 'select' step link.
 *
 * @param {number} iCurrentStepNumber - Number for the current step.
 * @param {object} oLocation - Location object.
 *
 * @returns {string}
 */
export const getSelectStepLink = (iCurrentStepNumber, oLocation) => {
  const sPathBase = getCheckoutBasePath(oLocation);
  return iCurrentStepNumber === 2 ? sPathBase : '';
};

/**
 * Gets the steps for checkout based on whether there is payment or not.
 *
 * @param {boolean} bHasPayment - Whether there should be a payment step.
 *
 * @returns {object}
 */
export const getCheckoutStepsMinusSelect = (bHasPayment = true) => {
  let aCheckoutSteps = [];
  if (bHasPayment) {
    aCheckoutSteps = [
      {
        slug: 'review',
        label: 'Review',
        number: 2,
      },
      {
        slug: 'payment',
        label: 'Payment',
        number: 3,
      },
      {
        slug: 'confirmation',
        label: 'Confirmation',
        number: 4,
      },
    ];
  } else {
    aCheckoutSteps = [
      {
        slug: 'review',
        label: 'Review',
        number: 2,
      },
      {
        slug: 'confirmation',
        label: 'Confirmation',
        number: 3,
      },
    ];
  }
  return aCheckoutSteps;
};

/**
 * Gets the current step number from the name of the current step.
 *
 * @param {string} sCurrentStep - Gets the number of the current step from the name.
 * @param {boolean} bHasPayment - Whether there should be a payment step.
 *
 * @returns {number}
 */
export const getCurrentStepNumber = (sCurrentStep, bHasPayment = true) => {
  let oStepNamesToNumbers = {};
  if (bHasPayment) {
    oStepNamesToNumbers = { select: 1, review: 2, payment: 3, confirmation: 4 };
  } else {
    oStepNamesToNumbers = { select: 1, review: 2, confirmation: 3 };
  }
  return oStepNamesToNumbers[sCurrentStep];
};

/**
 * Calculates the total of the checkout items.
 *
 * @param {array} aCheckoutItems - Data for review step including items to display in checkout summary table.
 *
 * @example of aCheckoutItems structure
 *  [
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund',
 *      amount: 500
 *    },
 *    {
 *      description; 'ledge #11887782: The Wake Forest Fund - School of Business',
 *      amount: 400
 *    },
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund - School of Law',
 *      amount: 300
 *    }
 *  ]
 *
 * @returns {number}
 */
export const calculateCheckoutTotal = (aCheckoutItems) =>
  aCheckoutItems.reduce(
    (fAccumulator, oCheckoutItem) =>
      fAccumulator + parseFloat(oCheckoutItem.amount),
    0
  );

/**
 * Retrieves the config for the specified form.
 *
 * @param {object} oLocation - from useLocation.
 *
 * @returns {object}
 */
export const retrieveFormConfig = (oLocation) => {
  const sFormBasePath = getCheckoutBasePath(oLocation);
  return aFormConfig.find(
    (oData) => oData.paths.formBasePath === sFormBasePath
  );
};

/**
 * Determines the payment URL using a payment path and app environment.
 *
 * @param {string} sPaymentPath - payment path minus https:// and the environment.
 *
 * @returns {string}
 */
const determinePaymentUrl = (sPaymentPath) => {
  let sPaymentUrl = '';
  const sStage = process.env.REACT_APP_STAGE;
  switch (sStage) {
    case 'prod':
      sPaymentUrl = `https://${sPaymentPath}`;
      break;
    case 'uat':
      sPaymentUrl = `https://uat.${sPaymentPath}`;
      break;
    default:
      sPaymentUrl = `https://dev.${sPaymentPath}`;
      break;
  }
  return sPaymentUrl;
};

/**
 * Retrieves the payment URL for the specified form.
 *
 * @param {object} oLocation - from useLocation.
 *
 * @returns {string}
 */
export const retrieveFormPaymentUrl = (oLocation) => {
  const oFormConfig = retrieveFormConfig(oLocation);
  const sPaymentPath = oFormConfig.paths.paymentPath;
  return determinePaymentUrl(sPaymentPath);
};

/**
 * Trims slash from end of string if there is one at the end of the string.
 *
 * @param {string} sString - String that needs slash trimmed from end.
 *
 * @returns {string}
 */
const trimSlashFromStringEnd = (sString) => {
  let sTrimmedString = '';
  const sLastCharacter = sString.substring(sString.length, sString.length - 1);
  if (sLastCharacter === '/') {
    sTrimmedString = sString.slice(0, -1);
  } else {
    sTrimmedString = sString;
  }
  return sTrimmedString;
};

/**
 * Retrieves the checkout confirmation URL for the specified form.
 *
 * @param {object} oLocation - from useLocation.
 *
 * @returns {string}
 */
export const retrieveCheckoutConfirmationUrl = (oLocation) => {
  let sWakeNetworkBaseUrl = getWakeNetworkHomeUrl();
  sWakeNetworkBaseUrl = trimSlashFromStringEnd(sWakeNetworkBaseUrl);
  const oFormConfig = retrieveFormConfig(oLocation);
  return `${sWakeNetworkBaseUrl}${oFormConfig.paths.formBasePath}/confirmation`;
};
