import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PencilEditButton from 'components/Layout/PencilEditButton';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import { fnChildrenPropType } from 'utils/customPropTypes';
import { useActiveFormField } from 'utils/hooks/useActiveFormField';

/**
 * Displays one profile section, including the header and content.
 */
const ProfileSection = ({ sName, sTitle, children, bIsEditable }) => {
  const [activeSection, setActiveSection] = useActiveFormSection();
  const [bIsEditing, fnSetIsEditing] = useState(activeSection === sName);
  // eslint-disable-next-line no-unused-vars
  const [activeFormField, setActiveFormField] = useActiveFormField();

  const handleEditButtonClick = () => {
    setActiveSection(activeSection === sName ? null : sName);
    fnSetIsEditing(!bIsEditing);
  };

  // Close this section when another section becomes active
  useEffect(() => {
    if (activeSection !== sName) {
      setActiveFormField(null);
      fnSetIsEditing(false);
    }
  }, [activeSection, sName, fnSetIsEditing, setActiveFormField]);

  return (
    <div className='profileSection'>
      <div className='profileSectionHeader'>
        <div className='profileSectionHeader__titleWrapper'>
          <h3 className='profileSectionHeader__title t-label'>{sTitle}</h3>
        </div>
        {bIsEditable && (
          <PencilEditButton
            className='profileSectionHeader__editButton'
            fnHandleClick={handleEditButtonClick}
            bIsEditing={bIsEditing}
          />
        )}
      </div>
      <div className='profileSectionContent'>{children}</div>
    </div>
  );
};

ProfileSection.propTypes = {
  sName: PropTypes.string,
  sTitle: PropTypes.string.isRequired,
  children: fnChildrenPropType.isRequired,
  bIsEditable: PropTypes.bool,
};

ProfileSection.defaultProps = {
  sName: '',
  bIsEditable: false,
};

export default ProfileSection;
