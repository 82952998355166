import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';

export const DeleteButton = ({
  fnHandleClick,
  sLabel,
  bIsLoading,
  sFlavor,
  bIsDisabled,
}) => (
  <Button
    className='deleteButton'
    fnHandleClick={fnHandleClick}
    sFlavor={sFlavor}
    bIsLoading={bIsLoading}
    bIsDisabled={bIsDisabled}
  >
    {sLabel}
  </Button>
);

DeleteButton.propTypes = {
  fnHandleClick: PropTypes.func.isRequired,
  sLabel: PropTypes.string,
  bIsLoading: PropTypes.bool,
  sFlavor: PropTypes.string,
  bIsDisabled: PropTypes.bool,
};

DeleteButton.defaultProps = {
  sLabel: 'Delete',
  bIsLoading: false,
  sFlavor: 'secondary',
  bIsDisabled: false,
};

export default DeleteButton;
