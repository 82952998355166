import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import fnSignIn from 'components/LandingPage/fnSignIn';
import LoadingZone from 'components/LoadingZone';

import wfuLogo from 'images/logos/wfu-single-line-black.png';

const sEnvironment = process.env.REACT_APP_STAGE;
const sUnauthenticatedGivingUrl =
  sEnvironment !== 'prod'
    ? `https://${sEnvironment}.forms.secure.wfu.edu/giving`
    : 'https://forms.secure.wfu.edu/giving';
const aSupportedUnauthenticatedGivingDesignations = [
  'UFU01',
  'UFU03',
  'UFP62',
  'CFU01',
  'GSU01',
  'SBU01',
  'DSU01',
  'LSU01',
  'COS495',
  'UFU04',
  'COL20',
];

/**
 * Informs the user that they can make a gift via Wake Network
 * or as a guest without signing in and provides links to do so.
 */
const GivingTrafficController = ({ oUser }) => {
  const oLocation = useLocation();
  const [oSearchParams, fnSetSearchParams] = useSearchParams();
  const fnNavigate = useNavigate();
  const [bIsLoading, fnSetIsLoading] = useState(true);
  const [sGuestUrl, fnSetGuestUrl] = useState(sUnauthenticatedGivingUrl);

  useEffect(() => {
    const sDesignationQueryParam = oSearchParams.get('designation');
    const sRedirectUrl = sDesignationQueryParam
      ? `/philanthropy/giving?designation=${sDesignationQueryParam}`
      : '/philanthropy/giving';

    // Immediately redirect authenticated WN users to the giving page
    if (oUser) {
      fnNavigate(sRedirectUrl);
    }
    // Set the URLs for the giving pages
    else {
      const oWipSearchParams = {
        redirect: sRedirectUrl,
      };
      let sWipGuestUrl = sUnauthenticatedGivingUrl;

      // Add in the designation query param if it exists
      if (sDesignationQueryParam) {
        oWipSearchParams.designation = sDesignationQueryParam;
        const bIsSupportedUnauthenticatedGivingDesignation =
          aSupportedUnauthenticatedGivingDesignations.includes(
            sDesignationQueryParam
          );
        sWipGuestUrl = bIsSupportedUnauthenticatedGivingDesignation
          ? `${sUnauthenticatedGivingUrl}?designation=${sDesignationQueryParam}`
          : `${sUnauthenticatedGivingUrl}?designation=other&comment=${sDesignationQueryParam}`;
      }

      fnSetSearchParams(oWipSearchParams);
      fnSetGuestUrl(sWipGuestUrl);
    }

    fnSetIsLoading(false);
  }, [oUser, oSearchParams, fnNavigate, fnSetSearchParams]);

  return (
    <LoadingZone isLoading={bIsLoading}>
      <div className='givingTrafficController'>
        <div className='givingTrafficController__header'>
          <div className='givingTrafficController__wfuBranding'>
            <a
              href='https://www.wfu.edu/'
              target='_blank'
              rel='noreferrer'
              className='givingTrafficController__wfuLogoLink'
            >
              <img
                src={wfuLogo}
                alt='Wake Forest University logo'
                className='givingTrafficController__wfuLogo'
              />
            </a>
          </div>
          <h1 className='givingTrafficController__title t-header2'>
            Thank you for your interest in making a gift to Wake Forest.
          </h1>
        </div>
        <div className='givingTrafficController__content'>
          <p className='t-paragraph--largeIntro'>
            We noticed you have a{' '}
            <strong>
              Wake
              <span className='givingTrafficController__goldNetwork'>
                Network
              </span>
            </strong>{' '}
            account. <br />
            Sign in for a better giving experience.
          </p>
          <p className='t-paragraph--largeIntro'>
            Or to give without signing in, click{' '}
            <i>&ldquo;Continue as Guest.&rdquo;</i>
          </p>
          <ButtonWithIcon
            sIcon='google'
            sIconType='wfu'
            fnHandleClick={() => fnSignIn(oLocation)}
            className='landingPage__googleButton'
          >
            Sign in to Wake Network
          </ButtonWithIcon>
          <p className='givingTrafficController__or'>or</p>
          <a href={sGuestUrl} className='givingTrafficController_continueGuest'>
            Continue as Guest
          </a>
        </div>
      </div>
    </LoadingZone>
  );
};

GivingTrafficController.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  oUser: PropTypes.object, // Cognito user object
};

GivingTrafficController.defaultProps = {
  oUser: null,
};

export default GivingTrafficController;
