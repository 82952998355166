import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { CancelButton } from 'components/Form/Buttons';
import Portal from 'utils/Portal';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import AddNote from 'components/Form/OptionsModal/DefaultFields/AddNote';
import oWnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';
import useTrapFocusInsideModal from 'utils/hooks/useTrapFocusInsideModal';
import { fnElementRefPropType } from 'utils/customPropTypes';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import actions from '../redux/actions';
import {
  fnIsRelationshipDeletable,
  oMyNetworkContactPropType,
} from './helpers';

const MyNetworkDeleteModal = ({
  oConnectionToDelete,
  fnSetConnectionToDelete,
  fnSetShowModal,
  oDeleteButtonRefs,
}) => {
  const fnDispatch = useDispatch();
  const [bIsDeleting, fnSetIsDeleting] = useState(false);
  const [bIsContactFormVisible, fnSetIsContactFormVisible] = useState(true);
  const [sContactFormStatus, fnSetContactFormStatus] = useState('');
  const oModalRef = useRef();
  const sUserLookupId = useSelector(currentUserLookupIdSelector);
  const aCodes = useCodeTablesSection('CONSTITUENTNOTETYPE');
  const bIsDeleteAllowed = fnIsRelationshipDeletable(oConnectionToDelete);

  const fnDelete = async () => {
    fnSetIsDeleting(true);
    await new Promise((resolve, reject) => {
      fnDispatch(
        actions.delete({
          data: { ID: oConnectionToDelete.RELATIONSHIP.ID },
          resolve,
          reject,
        })
      );
    });
    fnSetIsDeleting(false);
    fnSetShowModal(false);
    fnSetConnectionToDelete(null);
  };

  const fnCancel = useCallback(() => {
    fnSetShowModal(false);
    fnSetConnectionToDelete(null);
    oDeleteButtonRefs.current[oConnectionToDelete.LOOKUPID].focus();
  }, [
    fnSetShowModal,
    fnSetConnectionToDelete,
    oDeleteButtonRefs,
    oConnectionToDelete,
  ]);

  useCloseOnOutsideClick(oModalRef, fnCancel);

  useTrapFocusInsideModal(oModalRef);

  const fnSubmitContactForm = async ({ data, resolve, reject }) => {
    try {
      // get note type code id
      const sNoteTypeCodeId = aCodes.find(
        (item) => item.DESCRIPTION === 'Wake Network: Other Update'
      )?.ID;
      const sRequestUrl = `constituents/${sUserLookupId}/add_note`;
      const oResponse = await oWnApi.post(sRequestUrl, {
        TYPE: 'Note',
        data: {
          TITLE: 'My Network: Remove Relationship',
          TEXTNOTE: `Remove ${oConnectionToDelete.NAME}, ${oConnectionToDelete.RELATIONSHIP.TYPE.DESCRIPTION}: ${data.TEXTNOTE}`,
          NOTETYPECODEID: sNoteTypeCodeId,
        },
      });
      if (oResponse.status === 200) {
        resolve('Success');
      } else {
        throw oResponse.status;
      }
    } catch (err) {
      console.error('Error saving feedback data', err);
      reject(err);
    }
  };

  // Close the modal after successfully submitting a message
  useEffect(() => {
    if (sContactFormStatus === 'success') {
      // Allow the confirmation message to show for 2 seconds
      setTimeout(() => {
        fnCancel();
      }, 2000);
    }
  }, [sContactFormStatus, fnCancel]);

  return (
    <Portal>
      <div className='modalOverlay'>
        <div
          className='myNetwork__deleteModal'
          role='dialog'
          aria-labelledby='my-network-confirm-delete-title'
          aria-modal='true'
          ref={oModalRef}
        >
          <h3
            className='myNetwork__deleteModalTitle t-paragraph--medium'
            id='my-network-confirm-delete-title'
          >
            Alert
          </h3>
          {bIsDeleteAllowed ? (
            <>
              <p className='myNetwork__deleteModalMessage'>
                Are you sure you would like to remove{' '}
                <strong>{oConnectionToDelete?.NAME}</strong> from your Network?
              </p>
              <div className='myNetwork__deleteModalButtons'>
                <Button fnHandleClick={fnDelete} bIsLoading={bIsDeleting}>
                  Yes
                </Button>
                <CancelButton
                  fnHandleClick={fnCancel}
                  bIsDisabled={bIsDeleting}
                />
              </div>
            </>
          ) : (
            <>
              <p className='myNetwork__deleteModalMessage'>
                Please contact us to remove{' '}
                <strong>{oConnectionToDelete?.NAME}</strong> from your Network.
              </p>
              <AddNote
                visible={bIsContactFormVisible}
                setVisible={fnSetIsContactFormVisible}
                onSubmit={fnSubmitContactForm}
                status={sContactFormStatus}
                setFormStatus={fnSetContactFormStatus}
                successMsg='Thanks for letting us know!'
              />
              {sContactFormStatus !== 'success' && (
                <CancelButton
                  fnHandleClick={fnCancel}
                  bIsDisabled={bIsDeleting}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Portal>
  );
};

MyNetworkDeleteModal.propTypes = {
  oConnectionToDelete: oMyNetworkContactPropType.isRequired,
  fnSetConnectionToDelete: PropTypes.func.isRequired,
  fnSetShowModal: PropTypes.func.isRequired,
  oDeleteButtonRefs: fnElementRefPropType.isRequired,
};

export default MyNetworkDeleteModal;
