import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { fnCalculateTotalCartCost } from './helpers';

export const oInitialState = {
  checkoutSummaryItems: [],
  checkoutTotal: 0,
  fullName: '',
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  userLookupId: '',
  dadTransactionId: '',
  sEncodedEmail: '',
  aCart: [],
};

const reducer = handleActions(
  {
    [actions.update]: (state, action) => ({
      ...state,
      ...action.payload,
      _lastUpdated: Date.now(),
    }),
    [actions.reset]: () => oInitialState,
    [actions.cart.addItem]: (state, action) => {
      const aCartWithNewItem = [...state.aCart, action.payload];
      const nUpdatedTotalCost = fnCalculateTotalCartCost(aCartWithNewItem);
      return {
        ...state,
        checkoutTotal: nUpdatedTotalCost,
        aCart: aCartWithNewItem,
      };
    },
    [actions.cart.updateItem]: (state, action) => {
      const oItemToUpdate = action.payload;
      const aCartWithUpdatedItem = state.aCart.map((oItem) => {
        if (oItem.sId === oItemToUpdate.sId) {
          return oItemToUpdate;
        }
        return oItem;
      });
      const nUpdatedTotalCost = fnCalculateTotalCartCost(aCartWithUpdatedItem);
      return {
        ...state,
        checkoutTotal: nUpdatedTotalCost,
        aCart: aCartWithUpdatedItem,
      };
    },
    [actions.cart.removeItem]: (state, action) => {
      const sIdToRemove = action.payload;
      const aCartWithoutItem = state.aCart.filter(
        (oItem) => oItem.sId !== sIdToRemove
      );
      const nUpdatedTotalCost = fnCalculateTotalCartCost(aCartWithoutItem);
      return {
        ...state,
        checkoutTotal: nUpdatedTotalCost,
        aCart: aCartWithoutItem,
      };
    },
  },
  oInitialState,
  { prefix: 'Checkout' }
);

export default reducer;
