import { fork, all } from 'redux-saga/effects';

import watchProfile from 'components/MyProfile/redux/sagas';
import watchCodeTables from 'components/Main/redux/CodeTables/sagas';
import watchVolunteers from 'components/Volunteer/redux/sagas';
import watchProfileExternalView from 'components/ProfileExternalView/redux/sagas';
import watchScholarships from 'components/Scholarships/redux/sagas';

export default function* root() {
  yield all([
    fork(watchProfile),
    fork(watchProfileExternalView),
    fork(watchCodeTables),
    fork(watchVolunteers),
    fork(watchScholarships),
  ]);
}
