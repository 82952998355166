import { object, array } from 'yup';

import { coerceToArray, coerceToNull } from 'utils/yupHelpers';
import {
  biographicalNormalizer,
  addressNormalizer,
  emailNormalizer,
  phoneNormalizer,
  childNormalizer,
  spouseNormalizer,
  notificationNormalizer,
  myNetworkNormalizer,
  employmentNormalizer,
  degreeNormalizer,
  registeredEventsNormalizer,
} from './helpers';

// Yup schema used to normalize API data for consistency
// and to make it easier to work with.
const userProfileApiDataNormalizer = object({
  BIOGRAPHICAL: biographicalNormalizer,
  EMAIL: array(emailNormalizer).transform(coerceToArray).ensure(),
  PHONE: array(phoneNormalizer).transform(coerceToArray).ensure(),
  ADDRESS: array().of(addressNormalizer).transform(coerceToArray).ensure(),
  EMPLOYMENT: array(employmentNormalizer).transform(coerceToArray).ensure(),
  JOB_SPECIALTY: array().transform(coerceToArray).ensure(),
  INVOLVEMENT: array().transform(coerceToArray).ensure(),
  DEGREE: array().of(degreeNormalizer).transform(coerceToArray).ensure(),
  CHILD: array(childNormalizer).transform(coerceToArray).ensure(),
  SPOUSE: spouseNormalizer.nullable().transform(coerceToNull).default(null),
  PARENT: array().transform(coerceToArray).ensure(),
  WFUFAMILY: array().transform(coerceToArray).ensure(),
  NOTIFICATIONS: array(notificationNormalizer)
    .transform(coerceToArray)
    .ensure(),
  MYNETWORK: array(myNetworkNormalizer).transform(coerceToArray).ensure(),
  EVENT_REGISTRATIONS: array(registeredEventsNormalizer)
    .transform(coerceToArray)
    .ensure(),
});

export default userProfileApiDataNormalizer;
