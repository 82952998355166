import React from 'react';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';
import PhoneRecord from './PhoneRecord';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const PhoneViewMode = ({ aPhones, bIsExpanded }) => {
  const [oFirstPhone, ...aOtherPhones] = aPhones;
  return (
    <>
      {oFirstPhone && (
        <ProfileRecordLayout left={<PhoneRecord data={oFirstPhone} />} />
      )}
      {aOtherPhones.length > 0 && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          {aOtherPhones.map((oPhone) => (
            <ProfileRecordLayout
              key={oPhone.ID}
              left={<PhoneRecord data={oPhone} />}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};

export default PhoneViewMode;

PhoneViewMode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aPhones: PropTypes.arrayOf(PropTypes.object),
  bIsExpanded: PropTypes.bool,
};

PhoneViewMode.defaultProps = {
  aPhones: [],
  bIsExpanded: true,
};
