import { formatDesignations } from 'components/Philanthropy/MyGiving/Payments/helpers/formatTransactionData';

/**
 * Formats pledges data as expected for Lambda.
 *
 * @param {array} aPledges
 *
 * @returns {array}
 */
const formatPledges = (aPledges) =>
  aPledges.map((oPledge) => ({
    ID: oPledge.pledgeGuid,
    AMOUNT: oPledge.amount,
    DESIGNATIONS: formatDesignations(oPledge.designations),
  }));

/**
 * Formats the transaction data so its ready to send to API endpoint.
 *
 * @param {object} oTransactionData
 *
 * @example of oTransactionData structure
 *  {
 *    amount: 1200,
 *    pledges: [
 *      {
 *        pledgeGuid: 'B84D454B-143F-4485-8FB1-2970AEDD9778',
 *        pledgeNumber: '11887783',
 *        amount: 1200,
 *        designations: [
 *          {
 *            CODE: 'UFU01',
 *            NAME: 'The Wake Forest Fund',
 *            AMOUNT: 500,
 *          },
 *          {
 *            CODE: 'SBU01',
 *            NAME: 'The Wake Forest Fund - School of Business',
 *            AMOUNT: 400,
 *          },
 *          {
 *            code: 'LSU01',
 *            NAME: 'The Wake Forest Fund - School of Law',
 *            AMOUNT: 300,
 *          },
 *        ]
 *      }
 *    ]
 *  }
 *
 *  @example of return JSON object
 *  {
 *    "TOTAL_TRANS_AMOUNT": 1200,
 *    "PLEDGES": [
 *      {
 *        "ID": "B84D454B-143F-4485-8FB1-2970AEDD9778",
 *        "AMOUNT": "1200.0000",
 *        "DESIGNATIONS": [
 *          {
 *            "CODE: "UFU01",
 *            "AMOUNT: "500.0000",
 *          },
 *          {
 *            "CODE: "SBU01",
 *            "AMOUNT: "400.0000",
 *          },
 *          {
 *            "CODE: "LSU01",
 *            "AMOUNT: "300.0000",
 *          }
 *        ]
 *      }
 *    ]
 *  }
 *
 * @return {object}
 */
export const formatTransactionData = (oTransactionData) => {
  const oFormattedData = {
    TOTAL_TRANS_AMOUNT: oTransactionData.amount,
    PLEDGES: formatPledges(oTransactionData.pledges),
    ONLINEPLEDGEPAYMENTCOMMENT: oTransactionData.note,
  };
  return oFormattedData;
};

export default formatTransactionData;
