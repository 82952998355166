import React from 'react';
import PropTypes from 'prop-types';

import { fnChildrenPropType } from 'utils/customPropTypes';

const ProfileSubsectionLayout = ({ sTitle, left, right }) => (
  <div className='profileSubsectionLayout'>
    <div className='profileSubsectionLayout__left'>
      <h4 className='recordLabel t-label'>{sTitle}</h4>
      {left}
    </div>
    <div className='profileSubsectionLayout__right'>{right}</div>
  </div>
);

ProfileSubsectionLayout.propTypes = {
  sTitle: PropTypes.string.isRequired,
  left: fnChildrenPropType,
  right: fnChildrenPropType.isRequired,
};

ProfileSubsectionLayout.defaultProps = {
  left: null,
};

export default ProfileSubsectionLayout;
