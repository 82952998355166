import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import RegistrationOptionField from '../RegistrationOptionsForm/RegistrationOptionField';
import EventRegistrantName from '../EventRegistrantName';
import { fnRemoveGuestFromEventInCart } from '../redux/guestHelpers';

const ReviewRegistrantsForEventWithMultipleRegOptions = ({
  sEventLookupId,
  aRegistrationOptions,
}) => {
  const oFormikProps = useFormikContext();
  const aGuests = oFormikProps.values.events.find(
    (event) => event.sEventLookupId === sEventLookupId
  ).guests;
  const iEventFieldIndex = oFormikProps.values.events.findIndex(
    (event) => event.sEventLookupId === sEventLookupId
  );

  return (
    <>
      <h4 className='reviewRegistration__listHeader t-paragraph--medium'>
        Registrant List
      </h4>
      <div className='reviewRegistration__tableWrapper'>
        <table className='reviewRegistration__table' data-cy={`event-${sEventLookupId}-registrants-table`}>
          <thead className='hide_accessibly'>
            <tr>
              <th>Registrant Name</th>
              <th>Selected Option</th>
              <th>Remove Registrant</th>
            </tr>
          </thead>
          <FieldArray name='guests'>
            <tbody className='reviewRegistration__tableBody'>
              {aGuests.map(
                ({ sGuestLookupId, sGuestName }, iGuestFieldIndex) => (
                  <tr key={sGuestLookupId} data-cy={`registrant-${sGuestLookupId}-row`}>
                    <td className='reviewRegistration__nameCell' data-cy='registrant-name'>
                      <EventRegistrantName sName={sGuestName} />
                    </td>
                    <td className='reviewRegistration__optionCell' data-cy='registrant-reg-option'>
                      <RegistrationOptionField
                        sFieldName={`events.${iEventFieldIndex}.guests.${iGuestFieldIndex}.REG_OPTION`}
                        aFieldOptions={aRegistrationOptions}
                        sGuestLookupId={sGuestLookupId}
                        sEventLookupId={sEventLookupId}
                      />
                    </td>
                    <td className='reviewRegistration__removeButtonCell'>
                      <IconOnlyButton
                        sIcon='minus'
                        sAriaLabel='Remove Registrant'
                        sFlavor='secondary'
                        fnHandleClick={() => {
                          fnRemoveGuestFromEventInCart(
                            sGuestLookupId,
                            sEventLookupId
                          );
                        }}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </FieldArray>
        </table>
      </div>
    </>
  );
};

ReviewRegistrantsForEventWithMultipleRegOptions.propTypes = {
  sEventLookupId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  aRegistrationOptions: PropTypes.array.isRequired,
};

export default ReviewRegistrantsForEventWithMultipleRegOptions;
