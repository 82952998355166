import React from 'react';
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
} from 'react-virtualized';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LinkWithSourcePath from 'utils/LinkWithSourcePath';
import oIconLibrary from 'utils/iconLibrary';
import { fnHighlightSearchResult, fnGetIconByType } from './helpers';

const QuickSearchSelectResultsList = ({
  setSelection,
  aResultsList,
  sUserQuery,
}) => {
  /* Show the "no results found" message when:
       -no filtered suggestions are available,
       -and user has typed in the minimum number of characters.
  */
  const bShowNoResultsFoundMsg =
    sUserQuery.length > 0 && aResultsList?.length === 0;

  const iOverallHeight =
    aResultsList?.length > 8 ? 320 : 56 * (aResultsList || []).length + 10;

  const cellHeightCache = new CellMeasurerCache({
    defaultHeight: 24,
    fixedWidth: true,
  });

  const rowRenderer = ({ index, key, style, parent }) => {
    const oItem = aResultsList[index];
    const sType = oItem.fields.type[0];
    const bIsConstituent = sType === 'constituent';
    const onMouseDown = (e) => {
      if (e.button === 0) {
        setSelection({
          NAME: oItem.fields.name[0],
          LOOKUPID: oItem.id,
          TYPE: sType,
        });
      }
    };
    const sIconName = fnGetIconByType(sType);

    const itemMarkup = (
      <>
        <span className='quickSearchResult__icon'>
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome[sIconName]} />
        </span>
        <span
          className='quickSearchResult__name'
          dangerouslySetInnerHTML={{ __html: fnHighlightSearchResult(oItem) }}
        />
      </>
    );

    return (
      <CellMeasurer
        cache={cellHeightCache}
        parent={parent}
        key={key}
        rowIndex={index}
      >
        <div
          key={key}
          style={{ ...style }}
          className='quickSearchResult__wrapper'
        >
          {bIsConstituent ? (
            <LinkWithSourcePath
              className='quickSearchResult'
              to={`/main/profile/${oItem.id}`}
              referringComponent='QuickSearch'
            >
              {itemMarkup}
            </LinkWithSourcePath>
          ) : (
            <button
              type='button'
              className='quickSearchResult'
              onMouseDown={onMouseDown}
            >
              {itemMarkup}
            </button>
          )}
        </div>
      </CellMeasurer>
    );
  };

  return bShowNoResultsFoundMsg ? (
    <p className='quickSearchSelect__noResultsMsg'>{`No results found for "${sUserQuery}"`}</p>
  ) : (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          height={iOverallHeight}
          rowHeight={cellHeightCache.rowHeight}
          rowRenderer={rowRenderer}
          rowCount={aResultsList?.length}
          overscanRowCount={5}
          scrollToIndex={0}
        />
      )}
    </AutoSizer>
  );
};

QuickSearchSelectResultsList.propTypes = {
  aResultsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  setSelection: PropTypes.func.isRequired,
  sUserQuery: PropTypes.string,
};

export default QuickSearchSelectResultsList;
