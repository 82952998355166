import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';

/**
 * Displays the event's location, including the room and street address.
 */
const EventLocation = ({ oLocation }) => {
  if (
    // No location info
    !oLocation.NAME &&
    !oLocation.ROOM &&
    !oLocation.ADDRESSBLOCK &&
    !oLocation.CITY
  ) {
    return null;
  }
  const bIsVirtual = oLocation.NAME === 'Virtual';
  return (
    <div className='eventDetails__location'>
      {bIsVirtual ? (
        <p>
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome.display} />{' '}
          {oLocation.NAME}
        </p>
      ) : (
        <>
          <p>
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome.mapMarker} />{' '}
            {oLocation.NAME}
            {oLocation.ROOM ? `, ${oLocation.ROOM}` : ''}
          </p>
          {oLocation.ADDRESSBLOCK && (
            <p className='t-paragraph--small'>
              {oLocation.ADDRESSBLOCK}
              {oLocation.CITY && (
                <>
                  <br />
                  {oLocation.CITY}, {oLocation.STATE} {oLocation.POSTCODE}
                </>
              )}
            </p>
          )}
        </>
      )}
    </div>
  );
};

EventLocation.propTypes = {
  oLocation: PropTypes.shape({
    NAME: PropTypes.string,
    ROOM: PropTypes.string,
    ADDRESSBLOCK: PropTypes.string,
    CITY: PropTypes.string,
    STATE: PropTypes.string,
    POSTCODE: PropTypes.string,
  }).isRequired,
};

export default EventLocation;
