/* eslint-disable max-len */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import ClaimedClassmatesContext from '../ClaimedClassmatesContext';
import useActions from '../../../../../utils/hooks/useActions';
import * as claimedClassmateActions from '../../../redux/ClaimedClassmates/actions';
import AddNote from '../../../../Form/OptionsModal/DefaultFields/AddNote';
import Portal from '../../../../../utils/Portal';
import useWindowSize from '../../../../../utils/hooks/useWindowSize';

const ClaimedClassmatesEllipsisMenu = ({
  classmateLookupId,
  closeOnOutsideClick,
}) => {
  const [addNoteVisible, setAddNoteVisible] = useState(false);
  const [addNoteFormStatus, setAddNoteFormStatus] = useState(null);
  const [bEllipsisMenuVisible, fnSetEllipsisMenuVisible] = useState(false);
  const actions = useActions(claimedClassmateActions);
  const windowSize = useWindowSize();
  const ellipsisMenuWrapperRef = useRef(null);
  // The Add Note component is shown in a portal on mobile,
  // so it needs its own ref to manage closing it by an outside click.
  const addNoteWrapperRef = useRef(null);
  const [isMobileSize, setIsMobileSize] = useState(windowSize.width < 640);
  const [refToCloseOnOutsideClick, setRefToCloseOnOutsideClick] = useState(
    ellipsisMenuWrapperRef
  );
  const context = useContext(ClaimedClassmatesContext);

  const getAddNoteButtonLabel = useCallback(() => {
    if (!addNoteVisible) {
      return 'Contact Us';
    }
    return addNoteFormStatus === 'success' ? 'Close' : 'Cancel';
  }, [addNoteFormStatus, addNoteVisible]);

  const handleCancel = () => {
    setAddNoteVisible(!addNoteVisible);
    if (isMobileSize) {
      setRefToCloseOnOutsideClick(ellipsisMenuWrapperRef);
    }
    setAddNoteFormStatus(null);
  };

  const handleClose = useCallback(() => {
    setAddNoteVisible(false);
    fnSetEllipsisMenuVisible(false);
    if (isMobileSize) {
      setRefToCloseOnOutsideClick(ellipsisMenuWrapperRef);
    }
  }, [isMobileSize]);

  const showAddNoteForm = () => {
    setAddNoteVisible(true);
    if (isMobileSize) {
      setRefToCloseOnOutsideClick(addNoteWrapperRef);
    }
  };

  const unclaimClassmate = () => {
    handleClose();
    context.setIsLoading(true);
    const promise = new Promise((resolve, reject) => {
      actions.remove({
        classmateLookupId,
        classYear: context.classYear,
        resolve,
        reject,
      });
    });
    promise.then(() => {
      context.setIsLoading(false);
    });
  };

  const handleAddNoteSubmit = (formData) => {
    actions.addNote({
      classmateLookupId,
      resolve: setAddNoteFormStatus,
      noteData: { TEXTNOTE: formData.data.TEXTNOTE },
    });
  };

  useCloseOnOutsideClick(
    refToCloseOnOutsideClick,
    handleClose,
    closeOnOutsideClick
  );

  useEffect(() => {
    setIsMobileSize(windowSize.width < 640);
  }, [windowSize.width]);

  const addNoteMarkup = (
    <div
      className='claimedClassmatesEllipsis__AddNoteWrapper'
      ref={addNoteWrapperRef}
    >
      <ButtonWithIcon
        className='claimedClassmatesEllipsis__cancelButton'
        fnHandleClick={handleCancel}
        sIcon='close'
      >
        {getAddNoteButtonLabel()}
      </ButtonWithIcon>
      <AddNote
        visible={addNoteVisible}
        setVisible={setAddNoteVisible}
        onSubmit={handleAddNoteSubmit}
        status={addNoteFormStatus}
        setFormStatus={setAddNoteFormStatus}
        successMsg='Thank you for the information update.  We will review within the next 1&ndash;2 business days and complete the update or contact you with questions.'
      />
    </div>
  );

  // On mobile devices, show the addNote popup in a Portal
  // so that it can be position fixed to the overall screen
  const addNoteMarkupForScreenSize =
    windowSize.width > 640 ? addNoteMarkup : <Portal>{addNoteMarkup}</Portal>;

  return (
    <div className='claimedClassmatesEllipsis__wrapper'>
      <IconOnlyButton
        fnHandleClick={() => fnSetEllipsisMenuVisible(!bEllipsisMenuVisible)}
        sIcon='ellipsis'
        className='ellipsisButton'
        sAriaLabel='Toggle options'
        sFlavor='tertiary'
      />
      {bEllipsisMenuVisible && (
        <div className='ellipsisPopup' ref={ellipsisMenuWrapperRef}>
          <ButtonWithIcon
            sIcon='unclaimClassmate'
            sIconType='wfu'
            className='claimedClassmatesEllipsis__unclaimButton'
            fnHandleClick={unclaimClassmate}
          >
            Unclaim
          </ButtonWithIcon>

          {addNoteVisible ? (
            addNoteMarkupForScreenSize
          ) : (
            <ButtonWithIcon
              sIcon='edit'
              className='claimedClassmatesEllipsis__updateButton'
              fnHandleClick={showAddNoteForm}
            >
              Update
            </ButtonWithIcon>
          )}
        </div>
      )}
    </div>
  );
};

export default ClaimedClassmatesEllipsisMenu;

ClaimedClassmatesEllipsisMenu.propTypes = {
  classmateLookupId: PropTypes.string.isRequired,
  closeOnOutsideClick: PropTypes.bool,
};

ClaimedClassmatesEllipsisMenu.defaultProps = {
  closeOnOutsideClick: true,
};
