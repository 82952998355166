import React from 'react';
import PropTypes from 'prop-types';
import {
  Area,
  AreaChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

import useWindowSize from 'utils/hooks/useWindowSize';
import { fnFormatChartData } from './helpers';
import FundTooltip from './FundChartTooltip';

const FundFinancialChart = ({ aFinancialData }) => {
  const { sScreenSize } = useWindowSize();
  const aChartData = fnFormatChartData(aFinancialData);

  return aChartData.length > 2 ? (
    <div className='philanthropyReport__chartOuterWrapper philanthropyReport__overviewContainer'>
      <h4 className='philanthropyReport__chartTitle t-label'>
        Fund Value Over Time
      </h4>
      <div className='philanthropyReport__chartXAxisBackdrop' />
      <div className='philanthropyReport__chartWrapper'>
        <ResponsiveContainer>
          <AreaChart data={aChartData} margin={{ top: 16 }}>
            <defs>
              <linearGradient
                id='verticalWhiteFade'
                x1='0'
                y1='0'
                x2='0'
                y2='1'
              >
                <stop offset='0%' stopColor='white' stopOpacity={0.8} />
                <stop offset='100%' stopColor='white' stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey='name'
              className='philanthropyReport__chartXAxis'
              padding={
                sScreenSize === 'small'
                  ? { left: 16, right: 16 }
                  : { left: 56, right: 56 }
              }
              axisLine={false}
              tickLine={false}
              label={
                sScreenSize === 'small'
                  ? `${aChartData[0].name} – ${
                      aChartData[aChartData.length - 1].name
                    }`
                  : null
              } // Replace the ticks with a range for small screens
              tick={sScreenSize !== 'small'}
            />
            <Tooltip content={<FundTooltip />} />
            <Area
              type='monotone'
              dataKey='fundValue'
              fill='url(#verticalWhiteFade)'
              dot
            />
            <Legend
              iconSize={12}
              iconType='circle'
              formatter={(value, entry, index) => 'Fund Value'}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : null;
};

FundFinancialChart.propTypes = {
  aFinancialData: PropTypes.arrayOf(
    PropTypes.shape({
      FISCALYEAR: PropTypes.string,
      FISCAL_YEAR_BEGINNING_MARKET_VALUE: PropTypes.string,
      FISCAL_YEAR_ENDING_MARKET_VALUE: PropTypes.string,
      VALUE_OVER_TIME: PropTypes.arrayOf(
        PropTypes.shape({
          INTERVAL: PropTypes.string,
          ENDING_MARKET_VALUE: PropTypes.string,
        })
      ),
    })
  ),
};

FundFinancialChart.defaultProps = {
  aFinancialData: [],
};

export default FundFinancialChart;
