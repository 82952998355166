/**
 * Formats deceased label for approximate and exact deceased dates.
 *
 * @param {string} sDeceasedDate - Deceased date. Could be "Unknown", year only, or a full date.
 *
 * @returns Formatted deceased date.
 */
const fnBuildDeceasedText = (sDeceasedDate = '') => {
  if (!sDeceasedDate) {
    return '';
  }

  let sFormattedText = '';
  // Date could be unknown or just a year
  const bDsApproximateDeceasedDate =
    sDeceasedDate === 'Unknown' || sDeceasedDate.length === 4;
  if (bDsApproximateDeceasedDate) {
    sFormattedText = `Deceased (c. ${sDeceasedDate})`;
  } else {
    sFormattedText = `Deceased (${sDeceasedDate})`;
  }
  return sFormattedText;
};

export default fnBuildDeceasedText;
