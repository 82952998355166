import React from 'react';

const Elevations = () => (
  <ul className='styleGuide__elevations'>
    <li className='styleGuide__elevation styleGuide__elevation--highlight t-paragraph'>
      Highlight
    </li>
    <li className='styleGuide__elevation styleGuide__elevation--hover t-paragraph'>
      Hover
    </li>
    <li className='styleGuide__elevation styleGuide__elevation--modal t-paragraph'>
      Modals and pop-ups
    </li>
    <li className='styleGuide__elevation styleGuide__elevation--container t-paragraph'>
      Container
    </li>
    <li className='styleGuide__elevation styleGuide__elevation--accordion t-paragraph'>
      Accordion
    </li>
    <li className='styleGuide__elevation styleGuide__elevation--alert t-paragraph'>
      Alert
    </li>
  </ul>
);

export default Elevations;
