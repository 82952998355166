import { object, string, bool } from 'yup';

import { privacyFieldSchema } from 'utils/schemas/fields';

export const addEmailSchema = object({
  TYPE: string().required(),
  EMAILADDRESS: string()
    .email('Please enter a valid email address.')
    .test(
      'is-not-alumni-email',
      'You can create an @alumni.wfu.edu Gmail address from the Settings page or request a change by using the Contact Us form.',
      (email) => {
        const bIsValid = !email.toLocaleLowerCase().includes('@alumni.wfu.edu');
        return bIsValid;
      }
    )
    .required('Please enter your email address.'),
  PRIVACY: privacyFieldSchema,
  PRIMARY: bool().required(),
});

export const editEmailSchema = object({
  PRIVACY: privacyFieldSchema,
  PRIMARY: bool().required(),
});
