import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import IconOnlyButton, {
  iconOnlyButtonPropTypes,
} from 'components/Buttons/IconOnlyButton';

// Displays a button with a pencil icon that changes to an "X" icon when in edit mode.
const PencilEditButton = ({ bIsEditing, ...oProps }) => (
  <IconOnlyButton sIcon={bIsEditing ? 'close' : 'edit'} {...oProps} />
);

// We already know which icons to use, so no need to pass one in
const pencilEditButtonPropTypes = R.omit(['sIcon'], iconOnlyButtonPropTypes);
const pencilEditButtonDefaultProps = R.omit(
  ['sIcon'],
  IconOnlyButton.defaultProps
);

PencilEditButton.propTypes = {
  ...pencilEditButtonPropTypes,
  bIsEditing: PropTypes.bool,
};

PencilEditButton.defaultProps = {
  ...pencilEditButtonDefaultProps,
  bIsEditing: false,
  sAriaLabel: 'Toggle edit mode',
};

export default PencilEditButton;
