import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import sortByPrimaryThenType from 'components/MyProfile/elements/Personal/fnSortByPrimaryThenType';
import PhoneViewMode from 'components/MyProfile/elements/Personal/Phone/viewMode';
import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import PhoneEditMode from 'components/MyProfile/elements/Personal/Phone/editMode';
import { profileExternalViewPhoneActions } from 'components/ProfileExternalView/redux/actions';

const phoneSelector = (state) => (state.ProfileExternalView || {}).PHONE || [];

const getPhoneData = createSelector(phoneSelector, (items) =>
  sortByPrimaryThenType(items)
);

export const PhoneElement = () => {
  const aPhones = useSelector(getPhoneData);
  const [activeFormSection] = useActiveFormSection();
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, setIsEditMode] = useState(false);

  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      setIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      setIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    <ProfileSubsectionLayout
      sTitle='Phone'
      left={
        aPhones.length > 1 && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <PhoneEditMode
            aPhones={aPhones}
            bIsExpanded={bIsExpanded}
            oActions={profileExternalViewPhoneActions}
          />
        ) : (
          <PhoneViewMode aPhones={aPhones} bIsExpanded={bIsExpanded} />
        )
      }
    />
  );
};

export default PhoneElement;
