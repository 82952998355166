import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import { triggerGoogleTagManagerEvent } from 'utils/common';
import Highlight from 'components/Layout/Highlight';
import LineBreaksToParagraphs from 'components/Layout/LineBreaksToParagraphs';
import * as scholarshipsActions from '../redux/actions';
import AddNote from '../../Form/OptionsModal/DefaultFields/AddNote';
import { AddItemButton } from '../../Form';
import { scholarshipPropType } from '../ScholarshipList/helpers';

const ThankYouNotes = ({ scholarship }) => {
  const [isAddNoteActive, setIsAddNoteActive] = useState(false);
  const [addNoteStatus, setAddNoteStatus] = useState('');
  const dispatch = useDispatch();
  const sThankYouNoteText = scholarship.FUNDTHANKYOUNOTES.NOTE;

  const bHasThankYouNote = scholarship.FUNDTHANKYOUNOTES.NOTE;
  const sInitialValue = bHasThankYouNote
    ? scholarship.FUNDTHANKYOUNOTES.NOTE
    : '';

  const oSchema = object({
    TEXTNOTE: string().max(10000).required('Please enter your thank you note.'),
  });

  const handleClickCancel = () => {
    setIsAddNoteActive(false);
  };

  const handleSaveThankYou = async ({ data }) => {
    triggerGoogleTagManagerEvent(
      'Scholarships',
      `selected ${bHasThankYouNote ? 'edit' : 'add'} thank you note`
    );
    try {
      await new Promise((resolve, reject) => {
        if (bHasThankYouNote) {
          dispatch(
            scholarshipsActions.update({
              ID: scholarship.FUNDTHANKYOUNOTES.NOTEID,
              data: {
                TEXTNOTE: data.TEXTNOTE,
              },
              resolve,
              reject,
            })
          );
        } else {
          dispatch(
            scholarshipsActions.addNote({
              TEXTNOTE: data.TEXTNOTE,
              NOTETYPECODEID: '91532261-58C5-45AA-8493-CF7ECF20B45C',
              FUNDPURPOSEID: scholarship.FUNDPURPOSEID,
              resolve,
              reject,
            })
          );
        }
      });
      setIsAddNoteActive(false);
    } catch (err) {
      console.error('Error saving scholarships Thank You Note.', err);
    }
  };

  return (
    <div className='scholarships__thankYouNotes'>
      <h4 className='t-label scholarships__subtitle'>Note of Gratitude</h4>
      {bHasThankYouNote && !isAddNoteActive && (
        <LineBreaksToParagraphs
          sText={sThankYouNoteText}
          sClassName='scholarships__thankYouNoteBody'
        />
      )}
      {scholarship.FUNDTHANKYOUNOTES?.ISREVIEWED ? (
        <Highlight sFlavor='info'>
          Your note was reviewed by the{' '}
          <a
            href='mailto:donorexp@wfu.edu'
            className='scholarships__donorExpEmail t-paragraph--small'
          >
            Donor Experience team
          </a>{' '}
          and cannot be edited.
        </Highlight>
      ) : (
        <AddItemButton
          bIsHidden={isAddNoteActive}
          fnHandleClick={() => setIsAddNoteActive(true)}
          sLabel={
            bHasThankYouNote ? 'Edit thank you note' : 'Add thank you note'
          }
        />
      )}

      {isAddNoteActive && (
        <AddNote
          visible={isAddNoteActive}
          setVisible={setIsAddNoteActive}
          onSubmit={handleSaveThankYou}
          status={addNoteStatus}
          setFormStatus={setAddNoteStatus}
          successMsg='Your thank you note has been saved successfully.'
          formClassName='scholarships__textarea'
          textAreaClassName='scholarships__textarea'
          submitClassName='scholarships__thankYouNote__submitBtn'
          showCancel
          onClickCancel={handleClickCancel}
          submitButtonText='Save'
          initialValue={sInitialValue}
          validationSchema={oSchema}
          sNoteFieldId={`thank-you-note-for-${scholarship.FUNDPURPOSEID}`}
        />
      )}
    </div>
  );
};

ThankYouNotes.propTypes = {
  scholarship: scholarshipPropType,
};

ThankYouNotes.defaultProps = {
  scholarship: null,
};

export default ThankYouNotes;
