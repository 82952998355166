import React from 'react';
import PropTypes from 'prop-types';

import { DollarAmountTextField, Select } from 'components/Form/Fields';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';

const GiftFieldRow = ({ nIndex, oFieldArrayProps, aFunds, aTopFunds }) => (
  <fieldset className='givingCheckoutForm__giftRow' key={nIndex}>
    <legend className='hide_accessibly'>Gift</legend>
    <div className='givingCheckoutForm__giftRowFields'>
      <DollarAmountTextField
        sId={`amount-${nIndex}`}
        sLabel='Amount'
        sName={`gifts.${nIndex}.amount`}
        sClassName='givingCheckoutForm__amount'
      />
      <Select
        id={`fund-${nIndex}`}
        name={`gifts.${nIndex}.fund`}
        label='Fund'
        options={aFunds}
        getOptionLabel={(oOption) => oOption.DESCRIPTION}
        getOptionValue={(oOption) => oOption.DESCRIPTION}
        defaultOptions={aTopFunds}
        sHelpText='Type to search for other funds'
      />
    </div>
    {nIndex > 0 && (
      <IconOnlyButton
        sIcon='minus'
        sAriaLabel='Remove'
        sFlavor='secondary'
        fnHandleClick={() => oFieldArrayProps.remove(nIndex)}
        className='givingCheckoutForm__giftRowRemove'
      />
    )}
  </fieldset>
);

GiftFieldRow.propTypes = {
  nIndex: PropTypes.number.isRequired,
  oFieldArrayProps: PropTypes.shape({
    remove: PropTypes.func,
  }).isRequired,
  aFunds: PropTypes.arrayOf(
    PropTypes.shape({
      DESCRIPTION: PropTypes.string,
    })
  ).isRequired,
  aTopFunds: PropTypes.arrayOf(
    PropTypes.shape({
      DESCRIPTION: PropTypes.string,
    })
  ).isRequired,
};

export default GiftFieldRow;
