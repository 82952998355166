import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DateAndTime from 'date-and-time';

import Button from 'components/Buttons/Button';
import {
  fnAddEventToCart,
  fnAddWaitlistEventToCart,
} from 'components/EventRegistration/redux/eventHelpers';
import { fnAddGuestToEventInCart } from 'components/EventRegistration/redux/guestHelpers';
import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
  selectCurrentUserEmail,
} from 'redux/selectors';
import { selectEventRegNavSteps } from 'components/EventRegistration/EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import {
  fnIsEventAtCapacity,
  fnIsEventRegistrable,
} from 'components/Events/helpers';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import ConfirmRsvpNoModal from '../ConfirmRsvpNoModal';
import RegistrationWindowClosed from '../RegistrationWindowClosed';

const IndividualEventDetailsFooter = ({
  oEvent,
  fnSetShowSignInOrContinueAsGuestModal,
  fnSetChosenEvents,
}) => {
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);
  const oCurrentUserName = useSelector(currentUserFirstLastNameSelector);
  const sCurrentUserEmail = useSelector(selectCurrentUserEmail);
  const [sFirstEventRegStep] = useSelector(selectEventRegNavSteps);
  const [bShowRsvpNoConfirmModal, fnSetShowRsvpNoConfirmModal] =
    useState(false);
  const [bHasRsvpdNo, fnSetHasRsvpdNo] = useState(false);
  const bIsUserAuthenticated = useIsUserAuthenticated();

  const bIsInvited = oEvent?.INVITATION?.IS_INVITED === 1;
  const { RSVP_STATUS, HAS_INVITEES } = oEvent?.INVITATION || '';
  const bIsWaitlistEvent = [true, 1].includes(
    oEvent?.REGISTRATION?.ALLOW_WAITLIST
  );
  const bIsWaitlisted = [true, 1].includes(oEvent?.REGISTRATION?.IS_WAITLISTED);
  const bShowRsvpButtons =
    bIsInvited || (HAS_INVITEES === 1 && !bIsUserAuthenticated); // Show RSVP buttons if the user is invited or if the event has invitees and the user is not authenticated

  const fnSetCheckoutUserInfo = () => {
    fnDispatch(
      CheckoutActions.update({
        fullName: oCurrentUserName.sName,
        firstName: oCurrentUserName.sFirstName,
        lastName: oCurrentUserName.sLastName,
        email: sCurrentUserEmail,
        userLookupId: sCurrentUserLookupId,
      })
    );
  };

  // Add this event to the cart and add the current user as a guest
  const fnBeginRegistration = () => {
    if (!bIsUserAuthenticated) {
      fnSetChosenEvents([oEvent]);
      fnSetShowSignInOrContinueAsGuestModal(true);
      return;
    }

    fnSetCheckoutUserInfo();

    fnAddEventToCart(oEvent);
    fnAddGuestToEventInCart(
      `${oCurrentUserName.sFirstName} ${oCurrentUserName.sLastName}`,
      sCurrentUserLookupId,
      oEvent.LOOKUPID
    );
  };

  const fnJoinWaitlist = () => {
    if (!bIsUserAuthenticated) {
      fnSetChosenEvents([oEvent]);
      fnSetShowSignInOrContinueAsGuestModal(true);
      return;
    }

    fnSetCheckoutUserInfo();

    fnAddWaitlistEventToCart(oEvent);
    fnAddGuestToEventInCart(
      `${oCurrentUserName.sFirstName} ${oCurrentUserName.sLastName}`,
      sCurrentUserLookupId,
      oEvent.LOOKUPID
    );
  };

  // Navigate to the first registration step
  // Doing this in a useEffect instead of in the submit handler allows the
  // `selectEventRegNavSteps` selector to re-calculate against the new event(s) in the cart.
  useEffect(() => {
    if (sFirstEventRegStep) {
      fnNavigate(sFirstEventRegStep);
    }
  }, [sFirstEventRegStep, fnNavigate]);

  const bIsEventRegistrable = fnIsEventRegistrable(oEvent);
  const bIsEventAtCapacity = fnIsEventAtCapacity(oEvent);
  const bIsRegistrationWindowClosed = oEvent.REGISTRATION.CLOSE_DATETIME
    ? DateAndTime.parse(
        oEvent.REGISTRATION.CLOSE_DATETIME,
        'YYYY-MM-DDTHH:mm:ss'
      ) < new Date()
    : false;

  let registrationButtons = '';
  switch (true) {
    // @todo: remove this case after Homecoming moves to WakeNetwork event registration
    case oEvent.MAIN_EVENT?.LOOKUPID === '8-10010554': // Homecoming 2024
      registrationButtons = (
        <a
          href='https://registration.secure.wfu.edu/homecoming/events'
          target='_blank'
          className='button'
          rel='noreferrer'
          style={{ color: 'white', display: 'block' }}
        >
          Register for Homecoming events
        </a>
      );
      break;
    case bIsRegistrationWindowClosed:
      registrationButtons = (
        <RegistrationWindowClosed
          sCloseDateTime={oEvent.REGISTRATION.CLOSE_DATETIME}
          sTimeZone={oEvent.TIME_ZONE}
        />
      );
      break;
    case bIsEventAtCapacity && bIsWaitlistEvent && !bIsWaitlisted:
      registrationButtons = (
        <Button
          fnHandleClick={fnJoinWaitlist}
          sCypressId='waitlist-button'
          className='eventDetailsFooter__registerButton'
        >
          Join the waitlist
        </Button>
      );
      break;
    case !bIsEventRegistrable || (bIsWaitlisted && !bIsEventAtCapacity):
      // Don't show registration buttons when the event is cancelled or the user is already registered
      registrationButtons = null;
      break;
    case RSVP_STATUS === 'Declined':
    case bHasRsvpdNo: // intentional fall-through
      registrationButtons = (
        <p className='t-paragraph--small' data-cy='rsvp-decline-message'>
          You declined this invitation.
        </p>
      );
      break;
    case bShowRsvpButtons:
      registrationButtons = (
        <div className='individualEventDetails__rsvpWrapper'>
          <Button
            sFlavor='underline'
            fnHandleClick={() => fnSetShowRsvpNoConfirmModal(true)}
            sCypressId='rsvp-no-button'
          >
            RSVP No
          </Button>
          <Button
            fnHandleClick={fnBeginRegistration}
            sCypressId='rsvp-yes-button'
          >
            RSVP Yes
          </Button>
        </div>
      );
      break;
    default:
      registrationButtons = (
        <Button
          fnHandleClick={fnBeginRegistration}
          sCypressId='register-button'
          className='eventDetailsFooter__registerButton'
        >
          Register
        </Button>
      );
      break;
  }

  return (
    <div className='eventDetails__actionButtons'>
      {bIsUserAuthenticated && (
        <Button
          sFlavor='secondary'
          fnHandleClick={() => fnNavigate(-1)}
          className='individualEventDetails__back'
          sCypressId='back-button'
        >
          Back
        </Button>
      )}
      <div className='eventDetailsFooter__right'>{registrationButtons}</div>
      {bShowRsvpNoConfirmModal && (
        <ConfirmRsvpNoModal
          sEventTitle={oEvent.TITLE}
          sEventLookupId={oEvent.LOOKUPID}
          fnSetShowRsvpNoConfirmModal={fnSetShowRsvpNoConfirmModal}
          fnSetHasRsvpdNo={fnSetHasRsvpdNo}
          oEventContact={oEvent.CONTACT}
        />
      )}
    </div>
  );
};

IndividualEventDetailsFooter.propTypes = {
  oEvent: PropTypes.shape({
    TITLE: PropTypes.string,
    LOOKUPID: PropTypes.string,
    IS_CANCELLED: PropTypes.bool,
    TIME_ZONE: PropTypes.string,
    CONTACT: PropTypes.shape({
      NAME: PropTypes.string,
      EMAIL: PropTypes.string,
    }),
    INVITATION: PropTypes.shape({
      IS_INVITED: PropTypes.bool,
      INVITEE_ID: PropTypes.string,
    }),
    REGISTRATION: PropTypes.shape({
      MAX_GUESTS: PropTypes.number,
      CLOSE_DATETIME: PropTypes.string,
      ALLOW_WAITLIST: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      IS_WAITLISTED: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    }),
  }).isRequired,
  fnSetShowSignInOrContinueAsGuestModal: PropTypes.func.isRequired,
  fnSetChosenEvents: PropTypes.func.isRequired,
};

export default IndividualEventDetailsFooter;
