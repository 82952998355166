import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import AuthLayoutHeader from './AuthLayoutHeader';
import AuthLayoutProgressBar from './AuthLayoutProgressBar';
import AuthLayoutFooter from './AuthLayoutFooter';

const AuthLayout = ({ sMatchType, children, left, right, continueButton }) => {
  const oLocation = useLocation();

  // Don't show the progress bar on the help page
  const bIsHelpPage = oLocation.pathname.includes('help');

  return (
    <div className='authLayout__wrapper'>
      <AuthLayoutHeader />
      {!bIsHelpPage && <AuthLayoutProgressBar />}
      {children && (
        <div className='authLayout__childrenWrapper'>{children}</div>
      )}
      {left && right && (
        <div className='authLayout__columns'>
          <div className='authLayout__column'>{left}</div>
          <div className='authLayout__column'>{right}</div>
        </div>
      )}
      <AuthLayoutFooter
        sMatchType={sMatchType}
        continueButton={continueButton}
      />
    </div>
  );
};

// AuthLayout requires either children or left and right props
function fnValidateContent(props, propName, componentName) {
  if (!props.children && !props.left && !props.right) {
    return new Error(
      `Either children or left or right must be passed to ${componentName}.`
    );
  }
  return null;
}

AuthLayout.propTypes = {
  sMatchType: PropTypes.string,
  children: fnValidateContent,
  left: fnValidateContent,
  right: fnValidateContent,
  continueButton: PropTypes.element,
};

AuthLayout.defaultProps = {
  sMatchType: '',
  children: null,
  left: null,
  right: null,
  continueButton: null,
};

export default AuthLayout;
