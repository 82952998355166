import React from 'react';
import { useSelector } from 'react-redux';

import Header from './elements/Header';
import Career from './elements/Career';
import Personal from './elements/Personal';
import Education from './elements/Education';
import Groups from './elements/Groups';
import useProfileData from '../../utils/hooks/useProfileData';
import LoadingZone from '../LoadingZone';
import ProfileSkeleton from './elements/ProfileSkeleton';

/**
 * ProfileView
 *
 * @description - The main display component for all Profile sections.
 * @param {Object} props - The props object passed to the component.
 * @param {Object} props.match - The route match passed in by React Router.
 * @param {Object} props.match.params - The params passed to match.
 * @param {Object} props.history - The history object passed by React Router.
 *
 * @author Sean Wcisel swcisel@augustodigital.com
 * @copyright 2019 Wake Forest University, AIT
 */
const MyProfile = () => {
  const profile = useSelector((state) => state.Profile);
  const isProfileDataLoading = useProfileData();

  return (
    <div className='pageLayout__content--background'>
      <LoadingZone
        isLoading={isProfileDataLoading}
        skeleton={<ProfileSkeleton />}
        message='Loading your profile'
      >
        <Header data={profile} bIsPublicMode={false} />
        <Career />
        <Personal />
        {((profile || {}).DEGREE || []).length > 0 && <Education />}
        <Groups />
      </LoadingZone>
    </div>
  );
};

export default MyProfile;
