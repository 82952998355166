import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { Radio } from '../../../Fields/Radio';

export const ChooseType = ({ id, codeTablesKey }) => {
  const aTypeCodes = useCodeTablesSection(codeTablesKey);

  return (
    <fieldset id={id} className='optionsModal__section'>
      <legend className='optionsModal__sectionTitle'>Select Type</legend>
      {aTypeCodes.map(({ ID, DESCRIPTION }) => (
        <Radio
          sName='TYPE'
          sId={`type-select-${ID}`}
          mValue={DESCRIPTION}
          sLabel={DESCRIPTION}
          key={ID}
          sUi='buttons'
        />
      ))}
      <ErrorMessage component='p' className='field__errorMessage' name='TYPE' />
    </fieldset>
  );
};

ChooseType.defaultProps = {
  id: null,
  name: null,
  codeTablesKey: null,
};

ChooseType.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  codeTablesKey: PropTypes.string,
};

export default ChooseType;
