import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Accordion from '../../Layout/Accordion';
import EstateForm from './EstateForm';
import EstateStatus from './EstateStatus';

const Estate = ({ legacySociety }) => {
  // Show the Estate form if the user hasn't set an Estate preference yet.
  const [isEditingPrefs, setIsEditingPrefs] = useState(
    legacySociety.DESCRIPTION === undefined
  );

  return (
    <Accordion
      mTitle='Estate Preferences'
      sGtmCategory='Estate'
      sTitleIcon='estate'
      sTitleIconType='wfu'
    >
      <div className='estate__panelContent'>
        {isEditingPrefs ? (
          <EstateForm legacySocietyStatus={legacySociety.DESCRIPTION} />
        ) : (
          <EstateStatus
            status={legacySociety.DESCRIPTION}
            setIsEditingPrefs={setIsEditingPrefs}
          />
        )}
      </div>
    </Accordion>
  );
};

Estate.propTypes = {
  legacySociety: PropTypes.shape({
    ID: PropTypes.string,
    DESCRIPTION: PropTypes.string,
  }).isRequired,
};

export default Estate;
