import { createActions } from 'redux-actions';

export const profileExternalViewActions = createActions(
  {
    GET: (p) => p,
    SET: (p) => p,
    UPDATE: (p) => p,
  },
  {
    prefix: 'ProfileExternalView',
  }
);

export const profileExternalViewEmailActions = createActions(
  {
    CREATE: (p) => p,
    UPDATE: (p) => p,
    DELETE: (p) => p,
    ADD_NOTE: (p) => p,
  },
  {
    prefix: 'ProfileExternalView/EMAIL',
  }
);

export const profileExternalViewPhoneActions = createActions(
  {
    CREATE: (p) => p,
    UPDATE: (p) => p,
    DELETE: (p) => p,
    ADD_NOTE: (p) => p,
  },
  {
    prefix: 'ProfileExternalView/PHONE',
  }
);

export const profileExternalViewAddressActions = createActions(
  {
    CREATE: (p) => p,
    UPDATE: (p) => p,
    DELETE: (p) => p,
    ADD_NOTE: (p) => p,
  },
  {
    prefix: 'ProfileExternalView/ADDRESS',
  }
);

export const profileExternalViewNameActions = createActions(
  {
    UPDATE: (p) => p,
    ADD_NOTE: (p) => p,
  },
  {
    prefix: 'ProfileExternalView/BIOGRAPHICAL/NAME',
  }
);

export const profileExternalViewAttendNameActions = createActions(
  {
    UPDATE: (p) => p,
    ADD_NOTE: (p) => p,
  },
  {
    prefix: 'ProfileExternalView/BIOGRAPHICAL/ATTEND_NAME',
  }
);

export default profileExternalViewActions;
