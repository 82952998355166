import React from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';

import { NewNameWithQuickFindForm } from './NewNameWithQuickFindForm';
import NameWithQuickFindOptions from './NameWithQuickFindOptions';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

/**
 * Allows user to edit a name on their profile.
 */
const NameWithQuickFindEditMode = ({
  data,
  bAllowMultiple,
  actions,
  name,
  bIsPrivacyChangeable,
  label,
  codeTablesRelationshipType,
}) => (
  <>
    {/* Edit existing names */}
    {data.map((oName) => (
      <ProfileRecordLayout
        key={`${name}-record-${oName.LOOKUPID}-${oName.RELATIONSHIP.ID}`}
        left={<div className='record t-paragraph--medium'>{oName.NAME}</div>}
        right={
          <NameWithQuickFindOptions
            data={oName}
            actions={actions}
            bIsPrivacyChangeable={bIsPrivacyChangeable}
            codeTablesRelationshipType={codeTablesRelationshipType}
          />
        }
      />
    ))}

    {/* Add a new name */}
    {(data.length === 0 || bAllowMultiple) && (
      <NewNameWithQuickFindForm
        actions={actions}
        activateFormLabel={label ? `Add ${label}` : `Add ${capitalCase(name)}`}
        name={name}
        bIsPrivacyChangeable={bIsPrivacyChangeable}
        label={label}
        codeTablesRelationshipType={codeTablesRelationshipType}
      />
    )}
  </>
);

NameWithQuickFindEditMode.defaultProps = {
  data: {},
  label: '',
  codeTablesRelationshipType: '',
};

NameWithQuickFindEditMode.propTypes = {
  bAllowMultiple: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    addNote: PropTypes.func,
    create: PropTypes.func,
    get: PropTypes.func,
    set: PropTypes.func,
    update: PropTypes.func,
  }).isRequired,
  name: PropTypes.string.isRequired,
  bIsPrivacyChangeable: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      CONSTITUENT: PropTypes.shape({
        NAME: PropTypes.string,
        LOOKUPID: PropTypes.string,
      }),
      RELATIONSHIP: PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
    })
  ),
  label: PropTypes.string,
  codeTablesRelationshipType: PropTypes.string,
};

export default NameWithQuickFindEditMode;
