import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';

const MyClassmatesSearchToggle = ({ setShowSearch, showSearch }) => (
  <button
    type='button'
    className='myClassmates__searchHeader'
    onClick={() => setShowSearch(!showSearch)}
  >
    <span className='myClassmates__searchHeaderLeft'>
      <FontAwesomeIcon icon={oIconLibrary.fontAwesome.search} />
      <span className='myClassmates__searchHeaderText'>Search Classmates</span>
    </span>
    <FontAwesomeIcon
      icon={oIconLibrary.fontAwesome[showSearch ? 'collapse' : 'expand']}
    />
  </button>
);

MyClassmatesSearchToggle.propTypes = {
  setShowSearch: PropTypes.func.isRequired,
  showSearch: PropTypes.bool.isRequired,
};

export default MyClassmatesSearchToggle;
