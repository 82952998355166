import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Collapsible from 'react-collapsible';

import VerifyProfileInfoNotification from './VerifyProfileInfoNotification';
import notificationsActions from '../redux/actions';
import useActions from '../../../../utils/hooks/useActions';
import UpdateEmploymentInfoNotification from './UpdateEmploymentInfoNotification';

const NotificationsList = ({ fnSetShowModal }) => {
  const aNotificationsData = useSelector(
    (state) => state.Profile?.NOTIFICATIONS || []
  );
  const actions = useActions(notificationsActions);

  const fnMarkRead = (oNotification) => {
    if (!oNotification.READSTATUS) {
      // eslint-disable-next-line no-unused-vars
      const promise = new Promise((resolve, reject) => {
        actions.update({
          data: {
            // This key is required in order to convert the array JSON to XML correctly.
            NOTIFICATIONS: [
              { NOTIFICATION: { ...oNotification, READSTATUS: '1' } },
            ],
          },
          resolve,
          reject,
        });
      });
    }
  };

  const buildNotificationsMarkup = () => {
    const markup = aNotificationsData.reduce((result, item, index) => {
      let sNotificationTitle = '';
      let notificationMarkup = null;
      switch (item.TYPE.DESCRIPTION) {
        case 'Wake Network Profile Information Last Verified':
          sNotificationTitle = 'Profile Update';
          notificationMarkup = (
            <VerifyProfileInfoNotification
              data={item}
              fnSetShowModal={fnSetShowModal}
            />
          );
          break;
        case 'Employment Last Validated':
          sNotificationTitle = 'Employment Update';
          notificationMarkup = (
            <UpdateEmploymentInfoNotification
              oData={item}
              fnSetShowModal={fnSetShowModal}
            />
          );
          break;
        default:
          break;
      }
      result.push(
        <li
          className='notificationsModal__listItem'
          key={`notification-${item.TYPE.ID}`}
        >
          <Collapsible
            triggerTagName='button'
            triggerClassName={`notification__header${
              item.READSTATUS ? '' : '--unread'
            }`}
            triggerOpenedClassName='notification__header--open'
            trigger={<span className='t-paragraph'>{sNotificationTitle}</span>}
            contentInnerClassName='notification__content'
            onOpen={() => fnMarkRead(item)}
          >
            {notificationMarkup}
          </Collapsible>
        </li>
      );
      return result;
    }, []);
    return markup;
  };
  return (
    <ul className='notificationsModal__list'>{buildNotificationsMarkup()}</ul>
  );
};

NotificationsList.propTypes = {
  fnSetShowModal: PropTypes.func.isRequired,
};

export default NotificationsList;
