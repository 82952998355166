import * as R from 'ramda';
import { calculateMonetarySumByKey } from 'utils/common';

const groupByDesignationCode = R.groupBy(R.prop('hiddenCode'));

/**
 * Combines designations adding all the amounts for each designation.
 *
 * @param {array} aSummaryItems - Data for items to display in summary table.
 *
 * @example of aReviewItems structure.
 *  [
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund',
 *      amount: 500,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 500,
 *    },
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund - School of Business',
 *      amount: 400,
 *      hiddenCode: 'SBU01',
 *      hiddenName: 'The Wake Forest Fund - School of Business',
 *      hiddenAmount: 400,
 *    },
 *    {
 *      description; 'Pledge #11887783: The Wake Forest Fund',
 *      amount: 500,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 500,
 *    }
 *  ]
 *
 * @example of return based on example for aReviewItems.
 *  [
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund',
 *      amount: 1000,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 1000,
 *    },
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund - School of Business',
 *      amount: 400,
 *      hiddenCode: 'SBU01',
 *      hiddenName: 'The Wake Forest Fund - School of Business',
 *      hiddenAmount: 400,
 *    }
 *  ]
 *
 * @returns {object[]}
 */
const combineDesignations = (aSummaryItems) => {
  const oGroupedByDesignationCode = groupByDesignationCode(aSummaryItems);
  const aCombinedDesignations = Object.keys(oGroupedByDesignationCode).map(
    (sDesignationCode) => {
      const aDesignations = oGroupedByDesignationCode[sDesignationCode];
      const oDesignationTotal = calculateMonetarySumByKey(
        aDesignations,
        'amount'
      );
      return {
        ...aDesignations[0],
        amount: oDesignationTotal.value,
        hiddenAmount: oDesignationTotal.value,
      };
    }
  );
  return aCombinedDesignations;
};

/**
 * Builds hidden fields ready data for the designations.
 *
 * Payment step expects these:
 *   id - designation code
 *   name - designation name
 *   quantity - $ amount to the designation
 *
 * @param {array} aSummaryItems - Data for items to display in summary table.
 *
 * @example of aReviewItems structure.
 *  [
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund',
 *      amount: 500,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 500,
 *    },
 *    {
 *      description; 'Pledge #11887782: The Wake Forest Fund - School of Business',
 *      amount: 400,
 *      hiddenCode: 'SBU01',
 *      hiddenName: 'The Wake Forest Fund - School of Business',
 *      hiddenAmount: 400,
 *    },
 *    {
 *      description; 'Pledge #11887783: The Wake Forest Fund',
 *      amount: 500,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 500,
 *    }
 *  ]
 *
 * @example of return based on example for aReviewItems.
 *  [
 *    {
 *      id: 'UFU01',
 *      name: 'The Wake Forest Fund',
 *      quantity: 1000,
 *    },
 *    {
 *      id: 'SBU01',
 *      name: 'The Wake Forest Fund - School of Business',
 *      quantity: 400,
 *    }
 *  ]
 *
 * @returns {object[]}
 */
const buildHiddenFieldsReadyDesignations = (aSummaryItems) => {
  const aCombinedDesignations = combineDesignations(aSummaryItems);
  return aCombinedDesignations.map((oDesignation) => ({
    id: oDesignation.hiddenCode,
    name: oDesignation.hiddenName,
    quantity: oDesignation.hiddenAmount,
  }));
};

/**
 * Builds the data for building the hidden fields for payment submission.
 *
 * @param {object} oParams - Data passed to review step.
 *  Object must have these keys: oFormValues, aSummaryItems, oProfileData.
 *
 * @example of oParams structure.
 *  {
 *    oFormValues: {
 *      amount: 1200,
 *      paymentType: 'current',
 *      otherAmount: 0,
 *      note: '',
 *    },
 *    aSummaryItems: [
 *      {
 *        description; 'Pledge #11887782: The Wake Forest Fund',
 *        amount: 500,
 *        hiddenCode: 'UFU01',
 *        hiddenName: 'The Wake Forest Fund',
 *        hiddenAmount: 500,
 *      },
 *      {
 *        description; 'Pledge #11887782: The Wake Forest Fund - School of Business',
 *        amount: 400,
 *        hiddenCode: 'SBU01',
 *        hiddenName: 'The Wake Forest Fund - School of Business',
 *        hiddenAmount: 400,
 *      },
 *      {
 *        description; 'Pledge #11887783: The Wake Forest Fund',
 *        amount: 500,
 *        hiddenCode: 'UFU01',
 *        hiddenName: 'The Wake Forest Fund',
 *        hiddenAmount: 500,
 *      },
 *    ],
 *    oProfileData: {
 *      LOOKUPID: '08-10108346',
 *      FIRSTNAME: 'Elizabeth',
 *      LASTNAME: 'Hicks',
 *      EMAILADDRESS: 'hicksem@wfu.edu',
 *    }
 *  }
 *
 *  @example of return based on oReviewData example.
 *  Payment step expects the designation form fields to be named liked this:
 *    items[designation][x][id] - designation code
 *    items[designation][x][name] - designation name
 *    items[designation][x][quantity] - $ amount to the designation
 *  Payment step also expects: lookup_id, first_name, last_name, email, donation_amount.
 *    So don't refactor these names.
 *
 *   {
 *      lookup_id: '08-1324123',
 *      contact: {
 *        first_name: 'Elizabeth',
 *        last_name: 'Hicks',
 *        email_address: 'hicksem@wfu.edu',
 *      },
 *      donation_amount: 1300.00,
 *      other_amount_note: 'some note here',
 *      items: {
 *        designation: [
 *          {
 *            id: 'UFU01',
 *            name: 'The Wake Forest Fund',
 *            quantity: 1000,
 *          },
 *          {
 *            id: 'SBU01',
 *            name: 'The Wake Forest Fund - School of Business',
 *            quantity: 400,
 *          }
 *        ]
 *      },
 *    }
 *
 * @return {object}
 */
export const buildHiddenFieldsData = ({
  oFormValues,
  aSummaryItems,
  oProfileData,
}) => {
  const aHiddenFieldReadyDesignations =
    buildHiddenFieldsReadyDesignations(aSummaryItems);

  const sStage = process.env.REACT_APP_STAGE;
  const sEmailAddress =
    sStage === 'prod' ? oProfileData.EMAILADDRESS : 'ait-developers@wfu.edu';

  return {
    lookup_id: oProfileData.LOOKUPID,
    contact: {
      first_name: oProfileData.FIRSTNAME,
      last_name: oProfileData.LASTNAME,
      email_address: sEmailAddress,
    },
    donation_amount: parseFloat(oFormValues.amount),
    other_amount_note: oFormValues.note,
    items: {
      designation: aHiddenFieldReadyDesignations,
    },
  };
};

export default buildHiddenFieldsData;
