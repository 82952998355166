import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';

import ConstituentAvatar from '../../ConstituentAvatar';
import { buildCityStateEmployerString } from '../../../utils/common';
import LinkWithSourcePath from '../../../utils/LinkWithSourcePath';
import RelationshipTypeSwitcher from './RelationshipTypeSwitcher';
import {
  aProtectedRelationshipTypes,
  aProtectedRelationshipCategories,
  oMyNetworkContactPropType,
} from './helpers';
import MyNetworkDeleteModal from './MyNetworkDeleteModal';

const MyNetworkList = ({ aConnections }) => {
  const [bShowConfirmModal, fnSetShowModal] = useState(false);
  const [oConnectionToDelete, fnSetConnectionToDelete] = useState(null);
  // Collect refs for every delete button in order to return focus to them
  // after a keyboard user closes the delete confirmation modal
  const oDeleteButtonRefs = useRef({});

  const fnHandleDeleteClick = (oConstituent) => {
    fnSetShowModal(true);
    fnSetConnectionToDelete(oConstituent);
  };

  return (
    <>
      <ul className='myNetworkList'>
        {aConnections.length > 0 ? (
          aConnections.map((oConstituent) => {
            const { LOOKUPID, FIRSTNAME, LASTNAME, HAS_PHOTO, NAME } =
              oConstituent;
            const detailString = buildCityStateEmployerString(oConstituent);
            const bIsRelationshipChangeable =
              !aProtectedRelationshipTypes.includes(
                oConstituent.RELATIONSHIP.TYPE.DESCRIPTION
              ) &&
              !aProtectedRelationshipCategories.includes(
                oConstituent.RELATIONSHIP.TYPE.CATEGORY
              );
            return (
              <li key={LOOKUPID} className='myNetworkListItem'>
                <div className='myNetworkListItem__avatarName'>
                  <LinkWithSourcePath
                    to={`/main/profile/${LOOKUPID}`}
                    referringComponent='myNetwork'
                  >
                    <ConstituentAvatar
                      lookupId={LOOKUPID}
                      className='myNetworkList__personAvatar'
                      sFirstName={FIRSTNAME}
                      sLastName={LASTNAME}
                      bHasPhoto={HAS_PHOTO}
                    />
                  </LinkWithSourcePath>
                  <div className='myNetworkList__personInfo'>
                    <LinkWithSourcePath
                      to={`/main/profile/${LOOKUPID}`}
                      referringComponent='myNetwork'
                    >
                      {NAME}
                    </LinkWithSourcePath>
                    {detailString && (
                      <p className='myNetworkList__personLocationJob t-paragraph--small'>
                        {detailString}
                      </p>
                    )}
                  </div>
                </div>
                <div className='myNetworkList__relationshipManagement'>
                  {bIsRelationshipChangeable ? (
                    <RelationshipTypeSwitcher oConstituent={oConstituent} />
                  ) : (
                    <p className='t-paragraph--small'>
                      {oConstituent.RELATIONSHIP.TYPE.DESCRIPTION}
                    </p>
                  )}
                  <IconOnlyButton
                    sIcon='close'
                    sFlavor='tertiary'
                    sAriaLabel='Remove from My Network'
                    fnHandleClick={() => fnHandleDeleteClick(oConstituent)}
                    oRef={(oRef) => {
                      oDeleteButtonRefs.current[LOOKUPID] = oRef;
                    }}
                  />
                </div>
              </li>
            );
          })
        ) : (
          <p className='myNetwork__noConnections t-paragraph--small'>
            No connections found.
          </p>
        )}
      </ul>
      {bShowConfirmModal && (
        <MyNetworkDeleteModal
          oConnectionToDelete={oConnectionToDelete}
          fnSetConnectionToDelete={fnSetConnectionToDelete}
          fnSetShowModal={fnSetShowModal}
          oDeleteButtonRefs={oDeleteButtonRefs}
        />
      )}
    </>
  );
};

MyNetworkList.propTypes = {
  aConnections: PropTypes.arrayOf(oMyNetworkContactPropType).isRequired,
};

export default MyNetworkList;
