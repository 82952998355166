import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import AddressRecord from './AddressRecord';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

/**
 * Displays profile address section in view mode.
 */
const AddressViewMode = ({ aAddresses, bIsExpanded, bIsExternalView }) => {
  const [oFirstAddress, ...aOtherAddresses] = aAddresses;

  return (
    <>
      {oFirstAddress && (
        <ProfileRecordLayout
          left={
            <AddressRecord
              oAddress={oFirstAddress}
              bIsExternalView={bIsExternalView}
            />
          }
        />
      )}
      {aOtherAddresses.length > 0 && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          {aOtherAddresses.map((oAddress) => (
            <ProfileRecordLayout
              key={oAddress.ID}
              left={
                <AddressRecord
                  oAddress={oAddress}
                  bIsExternalView={bIsExternalView}
                />
              }
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};

AddressViewMode.defaultProps = {
  aAddresses: [],
  bIsExpanded: false,
  bIsExternalView: false,
};

AddressViewMode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aAddresses: PropTypes.arrayOf(PropTypes.object),
  bIsExpanded: PropTypes.bool,
  bIsExternalView: PropTypes.bool,
};

export default AddressViewMode;
