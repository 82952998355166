import qs from 'qs';

export const fnGetOption = (oOption) => oOption.DESCRIPTION;

// This is a helper function to filter the region options by country
export const fnGetRegionOptionsByCountry = (aRegionOptions, oCountry) => {
  if (!oCountry || !oCountry?.DESCRIPTION) {
    return aRegionOptions;
  }
  const aRegionOptionsFilteredByCountry = aRegionOptions.filter(
    (oState) => oState?.DETAILS?.COUNTRY_DESCRIPTION === oCountry.DESCRIPTION
  );
  return aRegionOptionsFilteredByCountry;
};

// Build a list from 100 years ago to 10 years in the future
export const aClassYearOptions = [];
const oNow = new Date();
const nCurrrentYear = oNow.getFullYear();
const nMaxYear = nCurrrentYear + 10;
const nMinYear = nCurrrentYear - 100;
for (let iYear = nMaxYear; iYear >= nMinYear; iYear -= 1) {
  aClassYearOptions.push({ DESCRIPTION: iYear });
}

export const fnFormatAdvSearchIntoQueryParams = (oAdvSearchFormData) => {
  const oQueryParams = {};
  if (oAdvSearchFormData.firstname) {
    oQueryParams['first-name'] = oAdvSearchFormData.firstname;
  }
  if (oAdvSearchFormData.lastname) {
    oQueryParams['last-name'] = oAdvSearchFormData.lastname;
  }
  if (oAdvSearchFormData.city) {
    oQueryParams.city = oAdvSearchFormData.city;
  }
  if (oAdvSearchFormData.state?.DESCRIPTION) {
    oQueryParams.state = oAdvSearchFormData.state.DESCRIPTION;
  }
  if (oAdvSearchFormData.country?.DESCRIPTION) {
    oQueryParams.country = oAdvSearchFormData.country.DESCRIPTION;
  }
  if (oAdvSearchFormData.classof.DESCRIPTION) {
    oQueryParams['class-of'] = oAdvSearchFormData.classof.DESCRIPTION;
  }
  if (oAdvSearchFormData.employer?.NAME) {
    oQueryParams.employer = oAdvSearchFormData.employer.NAME;
    oQueryParams['include-former-employers'] =
      oAdvSearchFormData.includeformeremployers;
  }
  if (oAdvSearchFormData.educationaldepartment.DESCRIPTION) {
    oQueryParams['area-of-study'] =
      oAdvSearchFormData.educationaldepartment.DESCRIPTION;
  }
  if (oAdvSearchFormData.educationalprogram.DESCRIPTION) {
    oQueryParams['academic-program'] =
      oAdvSearchFormData.educationalprogram.DESCRIPTION;
  }
  if (oAdvSearchFormData.educationalinvolvement.DESCRIPTION) {
    oQueryParams['club-org'] =
      oAdvSearchFormData.educationalinvolvement.DESCRIPTION;
  }
  if (oAdvSearchFormData.affinitygroup.DESCRIPTION) {
    oQueryParams['affinity-group'] =
      oAdvSearchFormData.affinitygroup.DESCRIPTION;
  }
  if (oAdvSearchFormData.industry.DESCRIPTION) {
    oQueryParams['job-skill'] = oAdvSearchFormData.industry.DESCRIPTION;
  }
  const sQueryParams = qs.stringify(oQueryParams);
  return sQueryParams;
};
