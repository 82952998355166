import { object, number, string } from 'yup';

const ssnDigitSchema = number()
  .typeError('The last four digits of your SSN must be numbers between 0 and 9')
  .min(0)
  .max(9)
  .required('Please enter the last four digits of your SSN');

export const lastFourSsnSchema = object().shape({
  ssn1: ssnDigitSchema,
  ssn2: ssnDigitSchema,
  ssn3: ssnDigitSchema,
  ssn4: ssnDigitSchema,
  reCaptchaToken: string().required('Please complete the reCAPTCHA'),
});

export const bannerIdSchema = object().shape({
  bannerid: string()
    .matches(
      /^[A-Za-z0-9-!$@_%^*]*$/,
      'Only upper- and lowercase letters, numbers, and the folowing special characters are valid: -!$@_%^*'
    )
    .min(6, 'Must be at least 6 characters long')
    .max(8, 'Must be no more than 8 characters long')
    .required('Enter your Wake Forest assigned ID or Deacon Token'),
  reCaptchaToken: string().required('Please complete the reCAPTCHA'),
});
