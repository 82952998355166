import { fnGetEventStatus } from '../EventRegistrationContainer/helpers';

/**
 * Get the first event the user is not invited to, if there is one.
 *
 * @param {string} sUserLookupId - The user's lookup ID
 * @param {array} aEventsInCart - The events in the cart
 * @returns {object | null} - The first event the user is not invited to, or null if the user is invited to all events
 */
export const fnGetFirstEventUserIsNotInvitedTo = async (
  sUserLookupId,
  aEventsInCart
) => {
  const aEventStatusPromises = aEventsInCart.map((oEventCartItem) => {
    const sEventSlug = oEventCartItem.oDetails.oEvent.SLUG;
    return fnGetEventStatus(sEventSlug, [{ LOOKUPID: sUserLookupId }], false);
  });
  const aEventStatuses = await Promise.all(aEventStatusPromises);

  for (let i = 0; i < aEventStatuses.length; i++) {
    const oEventStatus = aEventStatuses[i];
    const bUserIsNotInvited = oEventStatus?.UNINVITED_GUESTS?.some(
      ({ LOOKUPID }) => LOOKUPID === sUserLookupId
    );

    if (bUserIsNotInvited) {
      return aEventsInCart[i].oDetails.oEvent;
    }
  }

  return null;
};

export default {};
