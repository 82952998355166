/**
 * Builds summary items for specified pledge data.
 *
 * @param {object} oPledgeData
 *
 * @returns {object}
 */
const buildPledgeSummaryItems = (oPledgeData) => {
  const aPledgeDesignations = oPledgeData.designations || [];
  return aPledgeDesignations.map((oDesignation) => ({
    description: `Pledge #${oPledgeData.pledgeNumber}: ${oDesignation.NAME}`,
    amount: parseFloat(oDesignation.AMOUNT),
    hiddenCode: `${oDesignation.CODE}`,
    hiddenName: `${oDesignation.NAME}`,
    hiddenAmount: oDesignation.AMOUNT,
  }));
};

/**
 * Builds summary items for all pledges.
 *
 * @param {array} aTransactionPledgesData
 *
 * @returns {array}
 */
const buildAllPledgesSummaryItems = (aTransactionPledgesData) => {
  let aAllSummaryItems = [];
  aTransactionPledgesData.forEach((oPledgeData) => {
    const aPledgeSummaryItems = buildPledgeSummaryItems(oPledgeData);
    aAllSummaryItems = [...aAllSummaryItems, ...aPledgeSummaryItems];
  });
  return aAllSummaryItems;
};

/**
 * Builds the data to pass to the review step for checkout summary.
 *
 * Uses the form values and calculated values to build an array of data ready
 * to be passed to the review step.
 *
 * @param {object} oParams - Has keys: aFormValues, oTransactionData
 *    oFormValues - Values from the SelectPaymentForm.
 *    oBatchTransactionData - data build for writing transaction.
 *
 * @example
 * {
 *   oFormValues: {
 *     amount: 1000,
 *     paymentType: 'current',
 *     otherAmount: 0,
 *     note: '',
 *   },
 *   oTransactionData: {
 *     amount: 1200,
 *     pledges: [
 *       {
 *          pledgeGuid: 'B84D454B-143F-4485-8FB1-2970AEDD9778',
 *          pledgeNumber: '11887783',
 *          amount: 1200,
 *          designations: [
 *            {
 *              CODE: 'UFU01',
 *              NAME: 'The Wake Forest Fund',
 *              AMOUNT: 500,
 *            },
 *            {
 *              CODE: 'SBU01',
 *              NAME: 'The Wake Forest Fund - School of Business',
 *              AMOUNT: 400,
 *            },
 *            {
 *              CODE: 'LSU01',
 *              NAME: 'The Wake Forest Fund - School of Law',
 *              AMOUNT: 300,
 *            },
 *          ]
 *        }
 *      ]
 *    }
 *  }
 *
 * @example of return object based on example of aPayablePledges.
 * This assumes one pledge as listed above, and user selected pay current due.
 *  [
 *    {
 *      description: 'Pledge #11887782: The Wake Forest Fund',
 *      amount: 500,
 *      hiddenCode: 'UFU01',
 *      hiddenName: 'The Wake Forest Fund',
 *      hiddenAmount: 500,
 *    },
 *    {
 *      description: 'Pledge #11887782: The Wake Forest Fund - School of Business',
 *      amount: 400,
 *      hiddenCode: 'SBU01',
 *      hiddenName: 'The Wake Forest Fund - School of Business',
 *      hiddenAmount: 400,
 *    },
 *    {
 *      description: 'Pledge #11887782: The Wake Forest Fund - School of Law',
 *      amount: 300,
 *      hiddenCode: 'LSU01',
 *      hiddenName: 'The Wake Forest Fund - School of Law',
 *      hiddenAmount: 300,
 *    }
 *  ]
 *
 * @see buildReviewData.testData.js for more examples of incoming values and
 *  expected return.
 *
 * @returns {array}
 */
export const buildSummaryItemsData = ({ oFormValues, oTransactionData }) => {
  const sPaymentType = oFormValues.paymentType ? oFormValues.paymentType : '';
  let aSummaryItems = [];
  switch (sPaymentType) {
    case 'current':
    case 'total':
      aSummaryItems = buildAllPledgesSummaryItems(
        oTransactionData.pledges || []
      );
      break;
    case 'other':
      aSummaryItems = [
        {
          description: 'Other Amount',
          amount: parseFloat(oFormValues.amount),
          hiddenCode: 'other',
          hiddenName: 'Other',
          hiddenAmount: parseFloat(oFormValues.amount),
        },
      ];
      break;
    default:
      console.error(
        "Expected payment type: 'current', 'total' or 'other'.",
        sPaymentType
      );
      break;
  }
  return aSummaryItems;
};

export default buildSummaryItemsData;
