import PropTypes from 'prop-types';

// Users are not allowed to change or delete these relationships.
export const aProtectedRelationshipTypes = ['Managed Prospect', 'Spouse'];
export const aProtectedRelationshipCategories = ['Parental', 'Children'];

export const fnFilterProtectedRelationshipTypes = (aRelationships) =>
  aRelationships.filter(
    (oRelationship) =>
      !aProtectedRelationshipTypes.includes(oRelationship.DESCRIPTION)
  );

export const fnIsRelationshipDeletable = (oConstituent) => {
  let bIsDeleteAllowed = false;
  if (oConstituent) {
    const sRelationshipType = oConstituent.RELATIONSHIP.TYPE.DESCRIPTION;
    const sRelationshipCategory = oConstituent.RELATIONSHIP.TYPE.CATEGORY;
    if (
      !aProtectedRelationshipTypes.includes(sRelationshipType) &&
      !aProtectedRelationshipCategories.includes(sRelationshipCategory)
    ) {
      bIsDeleteAllowed = true;
    }
  }
  return bIsDeleteAllowed;
};

export const oMyNetworkContactPropType = PropTypes.shape({
  HAS_PHOTO: PropTypes.bool,
  EMPLOYER: PropTypes.string,
  STATE: PropTypes.string,
  CITY: PropTypes.string,
  LOOKUPID: PropTypes.string,
  NAME: PropTypes.string,
  FIRSTNAME: PropTypes.string,
  LASTNAME: PropTypes.string,
  RELATIONSHIPTYPE: PropTypes.string,
  RELATIONSHIP: PropTypes.shape({
    ID: PropTypes.string,
    TYPE: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
      CATEGORY: PropTypes.string,
    }),
  }),
});
