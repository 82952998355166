import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';
import EmployerGroup from 'components/MyProfile/elements/Career/Employment/EmployerGroup';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import {
  fnGetEmploymentByStatus,
  fnHasEmployerWithMultiplePositions,
  fnHasFormerEmployment,
} from 'components/MyProfile/elements/Career/Employment/helpers';

const allEmploymentSelector = (state) =>
  state.ProfileExternalView.EMPLOYMENT || [];
const currentEmploymentSelector = createSelector(
  allEmploymentSelector,
  (aEmployment) => fnGetEmploymentByStatus(['Active', 'Part Time'], aEmployment)
);
const retiredEmploymentSelector = createSelector(
  allEmploymentSelector,
  (aEmployment) => fnGetEmploymentByStatus('Retired', aEmployment)
);

/**
 * Displays employment section on a constituent's public profile.
 */
const Employment = () => {
  const aEmploymentData = useSelector(allEmploymentSelector);
  const aCurrentEmployment = useSelector(currentEmploymentSelector);
  const aRetiredEmployment = useSelector(retiredEmploymentSelector);
  const [bShowFormer, fnSetShowFormer] = useState(false);
  const bShowToggleFormerButton = fnHasFormerEmployment(aEmploymentData);
  const bIsRetired = aRetiredEmployment.length > 0;
  const aAlwaysVisibleEmployment = [
    ...aCurrentEmployment,
    ...aRetiredEmployment,
  ];
  const bHasEmployerWithMultiplePositions =
    fnHasEmployerWithMultiplePositions(aEmploymentData);

  const fnToggleFormer = () => {
    fnSetShowFormer(!bShowFormer);
  };

  return (
    <ProfileSubsectionLayout
      sTitle='Employment'
      left={
        bShowToggleFormerButton && (
          <ViewMoreButton
            fnOnClick={fnToggleFormer}
            bIsShowingMore={bShowFormer}
          />
        )
      }
      right={
        <>
          {!bIsRetired && aCurrentEmployment.length === 0 && (
            <p>No current known employment</p>
          )}
          {(bShowFormer ? aEmploymentData : aAlwaysVisibleEmployment).map(
            (oEmployer) => (
              <EmployerGroup
                data={oEmployer}
                key={oEmployer.EMPLOYER_RELATIONSHIP_ID}
                bIsPublicViewMode
                bHasEmployerWithMultiplePositions={
                  bHasEmployerWithMultiplePositions
                }
              />
            )
          )}
        </>
      }
    />
  );
};

export default Employment;
