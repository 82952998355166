import {
  faChevronDown,
  faMinus,
  faPlus,
  faTimes,
  faChevronUp,
  faDownload,
  faThumbsUp,
  faThumbsDown,
  faChevronRight,
  faBars,
  faHeart,
  faBookBlank,
  faBookOpen,
  faGraduationCap,
  faChevronLeft,
  faSort,
  faCamera,
  faMagnifyingGlass,
  faSparkles,
  faMapLocation,
  faCalendarCirclePlus,
  faClock,
  faDisplay,
  faTriangleExclamation,
  faCalendarStar,
  faPartyHorn,
  faRocketLaunch,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faEllipsis,
  faPencil,
  faEnvelope,
  faSpinner,
  faCircleQuestion,
  faLock,
  faCircleInfo,
  faCircleUser,
  faBell,
  faLocationDot,
  faBuilding,
  faUser,
  faEye,
  faEyeSlash,
  faUpload,
  faCheck,
  faAddressCard,
  faUsers,
  faBackpack,
  faFamilyPants,
  faBuildingColumns,
  faHeart as faHeartSolid,
  faGraduationCap as faGraduationCapSolid,
  faCircleExclamation,
  faCircleDollar,
  faCircleX,
  faUserPlus,
  faTrashCanXmark,
} from '@fortawesome/pro-solid-svg-icons';

import deaconMail from 'images/wfu-email.svg';
import estate from 'images/Icons/EstateIcon.svg';
import claimedClassmate from 'images/Icons/ClaimedClassmatesIcon.svg';
import unclaimClassmate from 'images/Icons/BrokenStar.svg';
import google from 'images/Icons/google-g-logo.svg';
import apple from 'images/Icons/apple-logo.svg';
import facebook from 'images/Icons/facebook-logo.svg';
import amazon from 'images/Icons/amazon-logo.svg';
import { faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons';

const oIconLibrary = {
  fontAwesome: {
    addressCard: faAddressCard,
    addToCalendar: faCalendarCirclePlus,
    arrowLeft: faChevronLeft,
    arrowRight: faChevronRight,
    backpack: faBackpack,
    bell: faBell,
    building: faBuilding,
    buildingColumns: faBuildingColumns,
    bookClosed: faBookBlank,
    bookOpen: faBookOpen,
    calendarStar: faCalendarStar,
    camera: faCamera,
    check: faCheck,
    circleDollar: faCircleDollar,
    clock: faClock,
    close: faTimes,
    closeCircle: faCircleX,
    collapse: faChevronUp,
    display: faDisplay,
    download: faDownload,
    edit: faPencil,
    ellipsis: faEllipsis,
    envelope: faEnvelope,
    exclamationCircle: faCircleExclamation,
    exclamationTriangle: faTriangleExclamation,
    expand: faChevronDown,
    eye: faEye,
    eyeSlash: faEyeSlash,
    family: faFamilyPants,
    graduationCap: faGraduationCap,
    graduationCapSolid: faGraduationCapSolid,
    heart: faHeart,
    heartSolid: faHeartSolid,
    help: faCircleQuestion,
    info: faCircleInfo,
    lock: faLock,
    mapMarker: faLocationDot,
    mapWithMarker: faMapLocation,
    menu: faBars,
    minus: faMinus,
    partyHorn: faPartyHorn,
    plus: faPlus,
    rocket: faRocketLaunch,
    search: faMagnifyingGlass,
    sort: faSort,
    sortDown: faSortDown,
    sortUp: faSortUp,
    sparkles: faSparkles,
    spinner: faSpinner,
    thumbsDown: faThumbsDown,
    thumbsUp: faThumbsUp,
    trash: faTrashCanXmark,
    upload: faUpload,
    user: faUser,
    userAdd: faUserPlus,
    userCircle: faCircleUser,
    users: faUsers,
  },
  wfu: {
    amazon,
    apple,
    claimedClassmate,
    deaconMail,
    estate,
    facebook,
    google,
    unclaimClassmate,
  },
};

export const aIconTypes = Object.keys(oIconLibrary);

export const aFontAwesomeIconNames = Object.keys(oIconLibrary.fontAwesome);
export const aWfuIconNames = Object.keys(oIconLibrary.wfu);

export const aAllIconNames = aIconTypes.reduce(
  (aResult, sIconSet) => [...aResult, ...Object.keys(oIconLibrary[sIconSet])],
  []
);

export default oIconLibrary;
