import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { triggerGoogleTagManagerEvent } from 'utils/common';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import useActions from '../../../../../utils/hooks/useActions';
import MyNetworkActions from '../../../../MyNetwork/redux/actions';
import MyNetworkStatusButton from './MyNetworkStatusButton';

const MyNetworkStatus = ({ status, lookupId, name }) => {
  const actions = useActions(MyNetworkActions);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfullyAdded, setIsSuccessfullyAdded] = useState(false);
  const [isSuccesfullyRemoved, setIsSuccessfullyRemoved] = useState(false);
  const aRelationshipTypes = useCodeTablesSection('RELATIONSHIP');
  const oRelationshipTypeCode = aRelationshipTypes.find(
    (item) => item.DESCRIPTION === 'WakeNetwork Contact'
  );

  const addToMyNetwork = () => {
    triggerGoogleTagManagerEvent('myNetwork', 'added contact');
    setIsLoading(true);
    const promise = new Promise((resolve, reject) => {
      actions.create({
        data: {
          CONSTITUENT: {
            NAME: name,
            LOOKUPID: lookupId,
          },
          RELATIONSHIP: oRelationshipTypeCode,
        },
        resolve,
        reject,
      });
    });
    promise.then(() => {
      setIsLoading(false);
      setIsSuccessfullyAdded(true);
    });
  };

  const removeFromMyNetwork = () => {
    triggerGoogleTagManagerEvent('myNetwork', 'removed contact');
    setIsLoading(true);
    const promise = new Promise((resolve, reject) => {
      actions.delete({
        data: {
          ID: lookupId,
        },
        resolve,
        reject,
      });
    });
    promise.then(() => {
      setIsLoading(false);
      setIsSuccessfullyRemoved(true);
    });
  };

  return status === 'INELIGIBLE' ? null : (
    <div className='myNetworkStatus'>
      {isSuccessfullyAdded || isSuccesfullyRemoved ? (
        <p className='myNetworkStatus__success'>
          {isSuccessfullyAdded && 'Added to My Network'}
          {isSuccesfullyRemoved && 'Removed from My Network'}
        </p>
      ) : (
        <MyNetworkStatusButton
          status={status}
          isLoading={isLoading}
          addToMyNetwork={addToMyNetwork}
          removeFromMyNetwork={removeFromMyNetwork}
        />
      )}
    </div>
  );
};

MyNetworkStatus.propTypes = {
  status: PropTypes.string.isRequired,
  lookupId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default MyNetworkStatus;
