import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';

// Base type for common props
type BaseButtonProps = {
  sFlavor?: 'primary' | 'secondary' | 'tertiary' | 'underline';
  bIsDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
  bIsLoading?: boolean;
  bIsCentered?: boolean;
  oRef?: React.RefObject<HTMLButtonElement> | null;
  sCypressId?: string;
  sFormId?: string;
};

// Type for buttons that require fnHandleClick
type RegularButtonProps = BaseButtonProps & {
  sType?: 'button';
  fnHandleClick: () => void;
};

// Type for buttons that do not require fnHandleClick
type SubmitResetButtonProps = BaseButtonProps & {
  sType?: 'submit' | 'reset';
  fnHandleClick?: () => void;
};

// Union type for all button props
type ButtonProps = RegularButtonProps | SubmitResetButtonProps;

/**
 * Displays a regular, text-only button.
 */
const Button = ({
  sFlavor = 'primary',
  fnHandleClick,
  children,
  bIsDisabled = false,
  className = '',
  bIsLoading = false,
  sType = 'button',
  bIsCentered = false,
  oRef = null,
  sCypressId = '',
  sFormId,
}: ButtonProps) => {
  const sCenteredClass = bIsCentered ? 'button--centered' : '';
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={sType}
      className={`button--${sFlavor} ${sCenteredClass} ${className}`}
      onClick={fnHandleClick}
      disabled={bIsDisabled || bIsLoading}
      ref={oRef}
      data-cy={sCypressId}
      form={sFormId}
    >
      {bIsLoading ? (
        <>
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome.spinner} spin />
          <span className='hide_accessibly'>Loading</span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
