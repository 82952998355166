import { string, object, array, number, bool } from 'yup';

import { coerceToArray, coerceToString, coerceToNull } from 'utils/yupHelpers';

export const classmateDataNormalizer = array(
  object({
    CITY: string().transform(coerceToString),
    STATE: string().transform(coerceToString),
    EMPLOYER: string().transform(coerceToString),
    SCORE: number(),
    SCORE_1TO5: number(),
    DECEASEDDATE: string().transform(coerceToString),
    REUNIONPROSPECTTEAM: object({
      CLAIMEDBY: object().nullable().transform(coerceToNull),
    }),
    MAIDENNAME: string().ensure(),
    HAS_PHOTO: bool().default(false),
  })
).transform(coerceToArray);

export const claimedClassmateDataNormalizer = array(
  object({
    MIDDLENAME: string().transform(coerceToString),
    PHONENUMBER: string().transform(coerceToString),
    MOBILENUMBER: string().transform(coerceToString),
    ADDRESSBLOCK: string().transform(coerceToString),
    CITY: string().transform(coerceToString),
    ZIP: string().transform(coerceToString),
    STATE: string().transform(coerceToString),
    COUNTRY: string().transform(coerceToString),
    REUNIONDONOR: bool(),
    INVOLVEMENTS: string().ensure(),
    HOMECOMINGATTENDANCESTATUS: string().transform(coerceToString),
    HAS_PHOTO: bool().default(false),
  })
).transform(coerceToArray);
