import React from 'react';

import { SkeletonCircle, SkeletonText } from 'components/LoadingZone/Skeleton';

const MyNetworkSkeleton = () => {
  const markup = [];
  for (let i = 0; i < 3; i++) {
    markup.push(
      <div className='myNetworkListSkeleton__row' key={i}>
        <SkeletonCircle size='small' />
        <div className='myNetworkListSkeleton__text'>
          <SkeletonText size='medium' width='50%' />
          <SkeletonText size='small' width='30%' />
        </div>
        <SkeletonText size='small' width='10%' />
      </div>
    );
  }
  return <div className='myNetworkListSkeleton'>{markup}</div>;
};

export default MyNetworkSkeleton;
