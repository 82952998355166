import React from 'react';
import PropTypes from 'prop-types';

import { oCurrencyFormatter } from 'utils/common';
import { installmentPropType } from '../helpers';

/**
 * Displays details for installments with multiple designations and/or partial payments.
 */
const InstallmentDetailsTable = ({ oInstallmentData, bIsPastDue }) => {
  const fAppliedAmount = parseFloat(oInstallmentData.APPLIED);
  const fTotalAmount = parseFloat(oInstallmentData.AMOUNT);
  const bHasPartialPayment =
    fAppliedAmount !== 0 && fAppliedAmount !== fTotalAmount;
  const bHasMultipleDesignations = oInstallmentData.DESIGNATIONS.length > 1;

  return bHasMultipleDesignations || bHasPartialPayment ? (
    <table className='installment__detailsTable'>
      <thead className='hide_accessibly'>
        <tr>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody className='installment__tableBody'>
        {bHasMultipleDesignations &&
          oInstallmentData.DESIGNATIONS.map((oDesignation) => (
            <tr className='installment__row' key={oDesignation.CODE}>
              <td className='installment__multiDesignation'>
                {oDesignation.NAME}
              </td>
              <td className='installment__rowAmount'>
                {oCurrencyFormatter.format(oDesignation.AMOUNT)}
              </td>
            </tr>
          ))}

        {bHasPartialPayment && (
          <>
            <tr className='installment__row'>
              <td className='installment__partialPaymentLabel'>
                Installment amount paid to date
              </td>
              <td className='installment__rowAmount'>
                {oCurrencyFormatter.format(oInstallmentData.APPLIED)}
              </td>
            </tr>
            <tr
              className={`installment__row ${
                bIsPastDue ? 'installment__row--pastDue' : ''
              }`}
            >
              <td className='installment__balanceLabel'>Balance</td>
              <td className='installment__balance'>
                {bIsPastDue && (
                  <span className='installment__pastDue'>
                    {/* VS Code didn't like parentheses as text in JSX. */}
                    &#40;Past Due&#41;
                  </span>
                )}
                <span className='installment__rowAmount'>
                  {oCurrencyFormatter.format(oInstallmentData.BALANCE)}
                </span>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  ) : null;
};

InstallmentDetailsTable.propTypes = {
  oInstallmentData: installmentPropType,
  bIsPastDue: PropTypes.bool.isRequired,
};

InstallmentDetailsTable.defaultProps = {
  oInstallmentData: null,
};

export default InstallmentDetailsTable;
