import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to parse the URL query string.
 * Source: https://v5.reactrouter.com/web/example/query-parameters
 *
 * @example
 *   // www.example.com?foo=bar
 *   const oQueryParams = useQueryParams();
 *   const sFoo = oQueryParams.get('foo');
 *
 * @returns {URLSearchParams}
 */
const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;
