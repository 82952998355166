import React from 'react';
import { Field, useField } from 'formik';
import PropTypes from 'prop-types';

export const CheckBox = ({
  sName,
  sLabel,
  sId,
  bIsDisabled,
  mValue,
  bSilenceErrors,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField({ name: sName, type: 'checkbox' });

  // Formik checkboxes don't work with a blank value prop, so we only pass it if it's not blank
  const oConditionalFieldProps = {};
  if (mValue) {
    oConditionalFieldProps.value = mValue;
  }

  return (
    <div className={`checkbox ${bIsDisabled ? 'checkbox--disabled' : ''}`}>
      <Field
        name={sName}
        className='checkboxInput hide_accessibly'
        type='checkbox'
        id={sId}
        disabled={bIsDisabled}
        {...oConditionalFieldProps}
      />
      <label htmlFor={sId} className='checkboxLabel'>
        {sLabel}
      </label>
      {!bSilenceErrors && meta.touched && meta.error && (
        <p className='field__errorMessage'>{meta.error}</p>
      )}
    </div>
  );
};

CheckBox.defaultProps = {
  bIsDisabled: false,
  bSilenceErrors: false,
  mValue: '',
};

CheckBox.propTypes = {
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  sId: PropTypes.string.isRequired,
  bIsDisabled: PropTypes.bool,
  mValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bSilenceErrors: PropTypes.bool,
};

export default CheckBox;
