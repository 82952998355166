import { object, string, bool } from 'yup';

import { privacyFieldSchema } from 'utils/schemas/fields';

// Phone number format: XXX-XXX-XXXX
const oUsPhoneRegExp = /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/;

export const addPhoneNumberSchema = object({
  TYPE: string().required('Please choose a phone number type.'),
  COUNTRY: object().nullable().required('Please choose a country.'),
  NUMBER: string()
    .trim()
    .when('COUNTRY', ([COUNTRY]) => {
      if (COUNTRY?.DETAILS?.ABBRV === 'US') {
        return string()
          .matches(
            oUsPhoneRegExp,
            'Please enter a valid phone number using the format: XXX-XXX-XXXX'
          )
          .length(
            12,
            'Please enter a valid phone number using the format: XXX-XXX-XXXX'
          )
          .required('Please enter a phone number');
      }
      return string().required('Please enter a phone number');
    }),
  PRIVACY: privacyFieldSchema,
  PRIMARY: bool().required(),
});

export const editPhoneNumberSchema = object({
  PRIMARY: bool().required(),
  PRIVACY: privacyFieldSchema,
});
