import React from 'react';

import Involvements from './Involvements';
import Degrees from './Degrees';
import ProfileSection from '../layout/ProfileSection';

const Education = () => (
  <ProfileSection bIsEditable sTitle='Education' sName='education'>
    <Degrees />
    <Involvements />
  </ProfileSection>
);

export default Education;
