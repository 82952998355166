import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const bIsContactUsPage = location.pathname === '/contact-us';
  const sStage = process.env.REACT_APP_STAGE;
  return (
    <footer className='footer'>
      {!bIsContactUsPage && (
        <Link to='/contact-us' className='footer__link'>
          Contact Us
        </Link>
      )}
      {sStage !== 'prod' && (
        <>
          <Link to='/style-guide' className='footer__link'>
            Style Guide
          </Link>
          <Link to='/component-library' className='footer__link'>
            Component Library
          </Link>
        </>
      )}
    </footer>
  );
};

export default Footer;
