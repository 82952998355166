import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/Buttons/Button';

const EventDetailsPageError = () => {
  const fnNavigate = useNavigate();
  return (
    <div>
      <h2 className='eventDetailsPage__errorMsg t-paragraph--large'>
        Oops! Something went wrong.
      </h2>
      <p>
        We couldn&apos;t find the details about this event. Please try again
        later or <Link to='/contact-us'>contact us</Link> for help.
      </p>
      <Button sFlavor='secondary' fnHandleClick={() => fnNavigate(-1)}>
        Back
      </Button>
    </div>
  );
};

export default EventDetailsPageError;
