import { createActions } from 'redux-actions';

export const { get, set, remove, addNote } = createActions(
  {
    GET: (action) => action,
    SET: (action) => action,
    REMOVE: (action) => action,
    ADD_NOTE: (action) => action,
  },
  { prefix: 'Volunteers/ClaimedClassmates' }
);
