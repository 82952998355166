import * as R from 'ramda';

import store from 'redux/store';
import emailActions from '../../MyProfile/elements/Personal/Email/redux/actions';
import addressActions from '../../MyProfile/elements/Personal/Address/redux/actions';
import { buildAddressValueForReview } from './CheckoutAddresses/helpers';

/**
 * Determines if user provided an email and an address.
 *
 * @param {function} setErrors - useState setter for contact info errors.
 * @returns {boolean}
 */
export const fnValidateContactInfo = (setErrors) => {
  const oReduxStore = store.getState();
  const sEmail = oReduxStore.Checkout.email;
  const sAddress = oReduxStore.Checkout.address;

  let bIsValid = false;
  if (sEmail && sAddress) {
    bIsValid = true;
  }
  if (!sEmail) {
    setErrors((oPreviousState) => ({
      ...oPreviousState,
      email: 'Please enter an email address.',
    }));
  }
  if (!sAddress) {
    setErrors((oPreviousState) => ({
      ...oPreviousState,
      address: 'Please enter an address.',
    }));
  }
  return bIsValid;
};

/**
 * Makes a profile record primary.
 *
 * @param {object} oRecord - Profile record to update.
 * @param {object} oActions - Redux actions to use.
 * @returns {Promise}
 */
const fnMakeProfileRecordPrimary = (oRecord, oActions) => {
  const oRecordClone = R.clone(oRecord);
  oRecordClone.PRIMARY = true;
  const oPromise = new Promise((resolve, reject) => {
    store.dispatch(
      oActions.update({
        data: oRecordClone,
        resolve,
        reject,
      })
    );
  });
  return oPromise;
};

/**
 * Makes chosen email and address primary records if they're not already.
 *
 * @param {string} sEmail - Selected email address.
 * @param {array} aEmails - List of all email addresses for current user.
 * @param {string} sAddress - Selected address.
 * @param {array} aAddresses - List of all addresses for current user.
 */
export const fnMakeContactInfoPrimary = () => {
  const oReduxStore = store.getState();
  const sEmail = oReduxStore.Checkout.email;
  const sAddress = oReduxStore.Checkout.address;
  const aEmails = oReduxStore.Profile?.EMAIL || [];
  const aAddresses = oReduxStore.Profile?.ADDRESS || [];

  const oSelectedEmail = aEmails.find(
    (oEmail) =>
      oEmail.EMAILADDRESS === sEmail &&
      oEmail.TYPE.DESCRIPTION !== 'Wake Network Email Address of Record'
  );
  if (oSelectedEmail && !oSelectedEmail.PRIMARY) {
    fnMakeProfileRecordPrimary(oSelectedEmail, emailActions);
  }

  const oSelectedAddress = aAddresses.find((oAddress) => {
    const sBuiltAddressValue = buildAddressValueForReview(oAddress);
    return sBuiltAddressValue === sAddress;
  });
  if (oSelectedAddress && !oSelectedAddress.PRIMARY) {
    fnMakeProfileRecordPrimary(oSelectedAddress, addressActions);
  }
};

export default fnMakeContactInfoPrimary;
