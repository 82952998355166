import { createActions } from 'redux-actions';

export const attendNameActions = createActions(
  {
    GET: (payload) => payload,
    SET: (payload) => payload,
    CREATE: (payload) => payload,
    UPDATE: (payload) => payload,
    ADD_NOTE: (action) => action,
  },
  {
    prefix: 'Profile/BIOGRAPHICAL/ATTEND_NAME',
  }
);

export default attendNameActions;
