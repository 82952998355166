import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { OptionsModal } from 'components/Form';
import { SelectPrivacy } from 'components/Form/OptionsModal/DefaultFields';
import OptionsModalContactForm from 'components/Form/OptionsModal/OptionsModalContactForm';
import useActions from 'utils/hooks/useActions';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import GroupRecord from './GroupRecord';
import NewGroupForm from './OtherGroups/NewGroupForm';
import oActions from './redux/actions';
import ButtonWithPopupMenu from '../layout/ButtonWithPopupMenu';
import RecordPrivacyIcon from '../RecordPrivacyIcon';
import ProfileRecordLayout from '../layout/ProfileRecordLayout';
import { oGroupPropType } from './helpers';

const EditableGroupRecord = ({
  oData,
  newPositionRowId,
  setNewPositionRowId,
}) => {
  const oGroupActions = useActions(oActions);
  const [editFormInitialValues, setEditFormInitialValues] = useState(oData);
  const bIsAddingNewPositionOnly = oData.ID === newPositionRowId;

  useEffect(() => {
    if (bIsAddingNewPositionOnly) {
      setEditFormInitialValues({
        ID: '',
        ORGANIZATIONNAME: oData.ORGANIZATIONNAME,
        POSITION: '',
        TYPE: oData.TYPE,
        STARTDATE: '',
        ENDDATE: '',
      });
    } else {
      setEditFormInitialValues(oData);
    }
  }, [newPositionRowId, oData, bIsAddingNewPositionOnly]);

  const bIsWfuBoard = oData.ISWFUAFFILIATION;

  return (
    <>
      <ProfileRecordLayout
        left={<GroupRecord oData={oData} />}
        right={
          bIsWfuBoard ? (
            <div className='editableGroupRecord__eyeAndOptionsWrapper'>
              <RecordPrivacyIcon ariaId={`private-eye-${oData.ID}`} />
              <ButtonWithPopupMenu>
                <OptionsModalContactForm
                  sRecordType='group'
                  oRecordData={oData}
                  handleSubmit={oGroupActions.addNote}
                />
              </ButtonWithPopupMenu>
            </div>
          ) : (
            <OptionsModal
              name='group'
              data={oData}
              actions={oActions}
              submitButtonHidden
            >
              {({ setOptionsModalVisible }) => (
                <>
                  <SelectPrivacy data={oData} isPrivacyChangeable={false} />
                  <div className='optionsModal__section'>
                    <ButtonWithIcon
                      sIcon='plus'
                      fnHandleClick={() => {
                        setOptionsModalVisible(false);
                        setNewPositionRowId(oData.ID);
                      }}
                    >
                      Add New Position
                    </ButtonWithIcon>
                  </div>
                </>
              )}
            </OptionsModal>
          )
        }
      />
      {!bIsWfuBoard && (
        <NewGroupForm
          initialValues={editFormInitialValues}
          setNewPositionRowId={setNewPositionRowId}
          newPositionOnly={bIsAddingNewPositionOnly}
        />
      )}
    </>
  );
};

EditableGroupRecord.propTypes = {
  oData: oGroupPropType.isRequired,
  newPositionRowId: PropTypes.string,
  setNewPositionRowId: PropTypes.func,
};

EditableGroupRecord.defaultProps = {
  newPositionRowId: '',
  setNewPositionRowId: null,
};

export default EditableGroupRecord;
