import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCurrentUserEmail } from 'redux/selectors';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import { selectNextEventRegStep } from 'components/EventRegistration/EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import LoadingZone from 'components/LoadingZone';
import useProfileData from 'utils/hooks/useProfileData';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import SocialSignInModal from 'components/LandingPage/SocialSignInModal';
import usePageTitle from 'utils/hooks/usePageTitle';
import ChangeEmailAddress from './ChangeEmailAddress';
import SignInToChangeEmailModal from './SignInToChangeEmailModal';

const EventRegistrationContactInfo = () => {
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();
  const bIsAuthenticated = useIsUserAuthenticated();
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(state, window.location.pathname)
  );
  const sEmail = useSelector(
    (state) => state.Checkout.email || selectCurrentUserEmail(state)
  );
  const sPrimaryRegistrantLookupId = useSelector(
    (state) => state.Checkout.userLookupId
  );
  const [bIsEditingEmail, fnSetIsEditingEmail] = useState(false);
  const [bShowSignInToChangeEmailModal, fnSetShowSignInToChangeEmailModal] =
    useState(false);
  const [bShowSignInModal, fnSetShowSignInModal] = useState(false);
  const bIsLoading = useProfileData(sPrimaryRegistrantLookupId);

  usePageTitle('Event Registration - Contact');

  useEffect(() => {
    fnDispatch(CheckoutActions.update({ email: sEmail }));
  }, [fnDispatch, sEmail]);

  return (
    <LoadingZone isLoading={bIsLoading}>
      <div>
        {!bIsEditingEmail ? (
          <>
            <h1 className='eventRegistrationContactInfo__instructions t-paragraph--medium'>
              {sEmail ? (
                <span>
                  Is <strong data-cy='email-address'>{sEmail}</strong> the best email to reach you?
                </span>
              ) : (
                "Oops! It looks like we don't have your email. Want to proceed with your registration without receiving emails from us?"
              )}
            </h1>
            <div className='eventReg__yesNoButtons'>
              <ButtonWithIcon
                sIcon='check'
                className='eventReg__yesNoButton'
                fnHandleClick={() => {
                  fnDispatch(
                    CheckoutActions.update({
                      email: sEmail,
                    })
                  );
                  fnNavigate(sNextStepUrl);
                }}
                sCypressId='yes-button'
              >
                Yes
              </ButtonWithIcon>
              <ButtonWithIcon
                sIcon='close'
                className='eventReg__yesNoButton'
                fnHandleClick={() => {
                  if (bIsAuthenticated) {
                    fnSetIsEditingEmail(true);
                  } else {
                    fnSetShowSignInToChangeEmailModal(true);
                  }
                }}
                sCypressId='no-button'
              >
                No
              </ButtonWithIcon>
            </div>
          </>
        ) : (
          <ChangeEmailAddress sEmail={sEmail} />
        )}
      </div>
      {bShowSignInToChangeEmailModal && (
        <SignInToChangeEmailModal
          fnSetShowModal={fnSetShowSignInToChangeEmailModal}
          fnSetShowSignInModal={fnSetShowSignInModal}
        />
      )}
      {bShowSignInModal && (
        <SocialSignInModal fnSetShowSignInModal={fnSetShowSignInModal} />
      )}
    </LoadingZone>
  );
};

export default EventRegistrationContactInfo;
