import React from 'react';

import { SelectPrivacy } from 'components/Form/OptionsModal/DefaultFields';
import ButtonWithPopupMenu from 'components/MyProfile/elements/layout/ButtonWithPopupMenu';
import { CheckBox } from 'components/Form/Fields';
import { oInitialValues } from './helpers';

const AddNewEmploymentFormOptions = () => (
  <ButtonWithPopupMenu className='editEmployerGroupForm__employerOptions'>
    <div className='optionsModal__section'>
      <CheckBox sLabel='Set as primary' sName='PRIMARY' sId='primary-record' />
    </div>
    <SelectPrivacy data={oInitialValues} />
    <div className='optionsModal__section'>
      <CheckBox
        sLabel="I'm retired"
        sName='POSITIONS.0.IS_RETIRED'
        sId='retired'
      />
    </div>
  </ButtonWithPopupMenu>
);

export default AddNewEmploymentFormOptions;
