import React from 'react';
import PropTypes from 'prop-types';

import EventDateBlock from 'components/Events/EventDateBlock';
import EventAlert from 'components/Events/EventAlert';

const EventDetailsPageHeader = ({ oEvent }) =>
  oEvent ? (
    <div>
      <EventDateBlock oEvent={oEvent} />
      <div className='eventDetails__titleWrapper' data-cy={`event-${oEvent.LOOKUPID}-details`}>
        <h1 className='eventDetails__eventTitle t-header2'>{oEvent?.TITLE}</h1>
        <EventAlert oEvent={oEvent} />
      </div>
    </div>
  ) : null;

EventDetailsPageHeader.propTypes = {
  oEvent: PropTypes.shape({
    TITLE: PropTypes.string,
  }),
};

EventDetailsPageHeader.defaultProps = {
  oEvent: null,
};

export default EventDetailsPageHeader;
