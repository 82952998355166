import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import oIconLibrary from 'utils/iconLibrary';

const MyNetworkSearch = ({ fnOnChange }) => {
  const [sQuery, fnSetQuery] = useState('');
  const [bShowLabel, fnSetShowLabel] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField('search');

  // Run the search after the user types at least two characters
  useEffect(() => {
    const sTrimmedQuery = field.value.trim();
    // Formik's field.value changes on every render ¯\_(ツ)_/¯,
    // so it's necessary to compare it with the previous query
    // to avoid an infinite loop.
    if (sTrimmedQuery !== sQuery) {
      if (sTrimmedQuery.length >= 2 || sTrimmedQuery.length === 0) {
        fnSetQuery(sTrimmedQuery);
        fnOnChange(sTrimmedQuery);
      }
    }
  }, [field.value, fnOnChange, sQuery]);

  return (
    <div className='myNetworkSearch__wrapper'>
      <div className='boxField'>
        <input
          {...field}
          id='search-my-network'
          type='text'
          name='search'
          className='myNetwork__search'
          onFocus={() => fnSetShowLabel(false)}
          onBlur={() => {
            if (!field.value) {
              fnSetShowLabel(true);
            }
          }}
        />
      </div>
      <FontAwesomeIcon
        icon={oIconLibrary.fontAwesome.search}
        className='myNetworkSearch__magnifyingGlass'
      />
      <label
        htmlFor='search-my-network'
        className={
          bShowLabel
            ? 'myNetworkSearch__fakePlaceholder t-paragraph--medium'
            : 'hide_accessibly'
        }
      >
        Search My Network
      </label>

      {field.value && (
        <IconOnlyButton
          sIcon='close'
          sFlavor='tertiary'
          sAriaLabel='Clear search'
          fnHandleClick={() => {
            helpers.setValue('');
            fnSetShowLabel(true);
          }}
          className='myNetworkSearch__clear'
        />
      )}
    </div>
  );
};

MyNetworkSearch.propTypes = {
  fnOnChange: PropTypes.func.isRequired,
};

export default MyNetworkSearch;
