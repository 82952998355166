import PropTypes from 'prop-types';

export const scholarshipPropType = PropTypes.shape({
  FUNDAWARDS: PropTypes.arrayOf(
    PropTypes.shape({
      AMOUNT: PropTypes.string,
      FISCALYEAR: PropTypes.string,
    })
  ),
  FUNDDETAILS: PropTypes.shape({
    INHONORORMEMORYOF: PropTypes.string,
    PURPOSE: PropTypes.string,
  }),
  FUNDNAME: PropTypes.string,
  FUNDPURPOSEID: PropTypes.string,
  FUNDTHANKYOUNOTES: PropTypes.shape({
    ISREVIEWED: PropTypes.bool,
  }),
});

export default {};
