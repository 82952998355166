import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';
import { fnFormatAddress, fnBuildDateRangeText } from './helpers';

/**
 * Displays an address record.
 */
const AddressRecord = ({ oAddress, bIsExternalView, bIsViewMode }) => {
  const bIsPrivate = oAddress.PRIVACY === '1' || oAddress.PRIVACY === '2';

  const bHasDateRange =
    oAddress.TYPE.DESCRIPTION === 'Seasonal Residence' && oAddress.START_DATE;
  const sDateRange = bHasDateRange
    ? fnBuildDateRangeText(oAddress.START_DATE, oAddress.END_DATE)
    : '';

  return (
    <>
      <span className='recordMiniLabel t-label'>
        {oAddress.TYPE.DESCRIPTION}
        {sDateRange ? `: ${sDateRange}` : ''}
        {bIsPrivate && bIsViewMode && !bIsExternalView && (
          <RecordPrivacyIcon ariaId={`private-eye-${oAddress.ID}`} />
        )}
      </span>
      <span className='record t-paragraph--medium'>
        {fnFormatAddress(oAddress, bIsExternalView)}
      </span>
    </>
  );
};

AddressRecord.propTypes = {
  oAddress: PropTypes.shape({
    PRIVACY: PropTypes.string,
    TYPE: PropTypes.shape({
      DESCRIPTION: PropTypes.string,
    }),
    START_DATE: PropTypes.string,
    END_DATE: PropTypes.string,
    ID: PropTypes.string,
  }).isRequired,
  bIsExternalView: PropTypes.bool,
  bIsViewMode: PropTypes.bool,
};

AddressRecord.defaultProps = {
  bIsExternalView: false,
  bIsViewMode: true,
};

export default AddressRecord;
