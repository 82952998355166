import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import TotalPrivacyDisabledModal from './TotalPrivacyDisabledModal';
import Tooltip from '../../../../Layout/Tooltip';
import { totalPrivacyActions } from './redux/actions';

const TotalPrivacy = ({ lookupId, isPrivate }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [bIsSaving, fnSetIsSaving] = useState(false);

  const handleTotalPrivacy = () => {
    fnSetIsSaving(true);
    const promise = new Promise((resolve, reject) => {
      dispatch(
        totalPrivacyActions.update({
          data: {},
          resolve,
          reject,
        })
      );
    });
    promise.then(() => {
      setShowModal(true);
    });
  };

  return !isPrivate ? null : (
    <div className='totalPrivacy'>
      {showModal && <TotalPrivacyDisabledModal setShowModal={setShowModal} />}
      {isPrivate && (
        <>
          <Button fnHandleClick={handleTotalPrivacy} bIsLoading={bIsSaving}>
            Disable total privacy
          </Button>
          <Tooltip
            ariaId={`total-privacy-form-username-${lookupId}`}
            sTriggerIcon='info'
          >
            Total privacy keeps your public profile undiscoverable. Review your
            information and privacy settings, then disable total privacy to be
            part of the Wake Network!
          </Tooltip>
        </>
      )}
    </div>
  );
};

TotalPrivacy.propTypes = {
  lookupId: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool.isRequired,
};

export default TotalPrivacy;
