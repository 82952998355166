import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from 'components/MyProfile/elements/RecordPrivacyIcon';
import { fnFormatEmploymentDateRange } from '../helpers';

const JobRecord = ({ data, bEmployerIsPrivate }) => {
  let sDateRange = '';
  try {
    sDateRange = fnFormatEmploymentDateRange(
      data.START_DATE,
      data.END_DATE,
      data.STATUS
    );
  } catch (oError) {
    console.error(oError);
  }

  return (
    <li className='jobRecord'>
      <div className='jobRecord__titleWrapper'>
        <p className='jobRecord__title subRecord'>{data.JOB_TITLE}</p>
        {!bEmployerIsPrivate && data.PRIVACY === '2' && (
          <RecordPrivacyIcon ariaId={data.ID} />
        )}
      </div>
      <p className='jobRecord__dates record__dateRange t-dates'>{sDateRange}</p>
    </li>
  );
};

JobRecord.propTypes = {
  data: PropTypes.shape({
    JOB_TITLE: PropTypes.string,
    START_DATE: PropTypes.string,
    END_DATE: PropTypes.string,
    STATUS: PropTypes.string,
  }).isRequired,
  bEmployerIsPrivate: PropTypes.bool.isRequired,
};

export default JobRecord;
