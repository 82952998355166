import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { capitalCase } from 'change-case';

import ProfileRecordLayout from 'components/MyProfile/elements/layout/ProfileRecordLayout';
import { SelectPrivacy } from '../../../../../Form/OptionsModal/DefaultFields';
import { OptionsModal } from '../../../../../Form/OptionsModal';
import { NameOptions } from '../NameOptions';
import { nameOptionsSchema } from '../schemas';
import { NewNameForm } from '../NewNameForm';

/**
 * Allows user to edit a name on their profile.
 */
const EditableNameRecord = ({
  data,
  bIsExpanded,
  name,
  actions,
  attendNameActions,
  bIsPrivacyChangeable,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { NAME, ATTENDNAME, LOOKUPID } = data;

  const handleFormShow = () => {
    setIsFormVisible(true);
  };
  const handleFormHide = () => {
    setIsFormVisible(false);
  };

  return (
    <>
      {!!LOOKUPID && (
        <>
          <ProfileRecordLayout
            left={<div className='record t-paragraph--medium'>{NAME}</div>}
            right={
              !isFormVisible && (
                // Ellipsis menu for existing name.
                // The edit form below has its own ellipsis menu, so
                // don't show this one when the form is open.
                <NameOptions
                  data={data}
                  actions={actions}
                  bIsPrivacyChangeable={bIsPrivacyChangeable}
                  name={name}
                />
              )
            }
          />
          {/* Edit existing name form */}
          <NewNameForm
            initialValues={data}
            activateFormLabel={`Edit ${capitalCase(name)}`}
            bIsPrivacyChangeable={bIsPrivacyChangeable}
            actions={actions}
            onShow={handleFormShow}
            onHide={handleFormHide}
            name={name}
          />
        </>
      )}
      {!!(ATTENDNAME || {}).ID && !isFormVisible && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          <ProfileRecordLayout
            left={
              <>
                <p className='recordMiniLabel t-label'>
                  I attended Wake Forest University as:
                </p>
                <p className='record t-paragraph--medium'>{ATTENDNAME?.NAME}</p>
              </>
            }
            right={
              <OptionsModal
                name={name}
                data={ATTENDNAME}
                actions={attendNameActions}
                validationSchema={nameOptionsSchema}
              >
                <SelectPrivacy data={ATTENDNAME} />
              </OptionsModal>
            }
          />
        </Collapsible>
      )}
    </>
  );
};

EditableNameRecord.propTypes = {
  data: PropTypes.shape({
    NAME: PropTypes.string,
    ATTENDNAME: PropTypes.shape({
      NAME: PropTypes.string,
    }),
    LOOKUPID: PropTypes.string,
  }).isRequired,
  bIsExpanded: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attendNameActions: PropTypes.object.isRequired,
  bIsPrivacyChangeable: PropTypes.bool.isRequired,
};

export default EditableNameRecord;
