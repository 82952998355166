import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import { CheckBox } from '../../../Fields';

/**
 * A field that allows the user to indicate that an item is the
 * "primary" record among its group.
 */
export const SelectPrimary = connect(({ textWhenPrimary, formik }) => {
  const isDisabled =
    !!formik.values.END_DATE ||
    ['Former', 'Retired'].includes(formik.values.STATUS);
  const bIsPrimary = formik.initialValues.PRIMARY;
  const bIsEditingExistingRecord = !!formik.values.ID;

  return (
    <div className='optionsModal__section'>
      {bIsPrimary && bIsEditingExistingRecord ? (
        <p className='selectPrimary__isPrimaryText'>{textWhenPrimary}</p>
      ) : (
        <CheckBox
          sId='primary-record'
          sLabel='Set as primary'
          sName='PRIMARY'
          bIsDisabled={isDisabled}
        />
      )}
    </div>
  );
});

SelectPrimary.defaultProps = {
  textWhenPrimary: 'This is my primary record',
};

SelectPrimary.propTypes = {
  textWhenPrimary: PropTypes.string,
};

export default SelectPrimary;
