import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const LinkWithSourcePath = ({
  to,
  children,
  className,
  referringComponent,
  style,
}) => {
  const oLocation = useLocation();
  let destination = '/';
  switch (typeof to) {
    case 'string':
      destination = to;
      break;
    case 'object':
      destination = to.pathname;
      break;
    case 'function':
    default:
      break;
  }

  return (
    <Link
      to={destination}
      state={{
        ...to.state,
        from: oLocation.pathname,
        referringComponent,
      }}
      className={className}
      style={style}
    >
      {children}
    </Link>
  );
};

LinkWithSourcePath.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  referringComponent: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

LinkWithSourcePath.defaultProps = {
  className: '',
  referringComponent: '',
  style: null,
};

export default LinkWithSourcePath;
