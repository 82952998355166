import { object, string, array, ValidationError, bool } from 'yup';

import { privacyFieldSchema } from 'utils/schemas/fields';
import { sortByKey } from 'utils/common';
import { oEndDateFieldSchema, oStartDateFieldSchema } from '../../helpers';

/**
 * Formats job data for comparison by date.
 *
 * @param {array} aJobs - List of job field values from Formik.
 * @returns {array}
 */
const fnFormatJobsForDateComparison = (aJobs) => {
  const aFormattedJobs = aJobs.map((oPosition, iIndex) => ({
    oStartDate: new Date(oPosition.START_DATE),
    oEndDate: oPosition.END_DATE ? new Date(oPosition.END_DATE) : new Date(),
    // Formik uses the index to identify fields in an array
    iOriginalIndex: iIndex,
  }));
  // Sort jobs in reverse chronological order by start date for consistent comparison.
  sortByKey(aFormattedJobs, 'oStartDate', false);
  return aFormattedJobs;
};

/**
 * Checks if two job date ranges overlap.
 *
 * @param {object} oJob - Job with start and end Date objects.
 * @param {object} oComparisonJob - Job with start and end Date objects.
 * @returns {bool} - true = overlap; false = no overlap
 */
const fnCheckForJobDateOverlap = (oJob, oComparisonJob) =>
  oJob.oStartDate <= oComparisonJob.oEndDate &&
  oJob.oEndDate >= oComparisonJob.oStartDate;

/**
 * Builds list of Formik field names where job start and end dates overlap.
 *
 * @param {array} aJobs - List of job field values from Formik.
 * @returns {array}
 */
export const fnGetOverlappingJobDateErrors = (aJobs) => {
  const aErrors = [];
  const aCheckedJobs = [];
  const aFormattedJobs = fnFormatJobsForDateComparison(aJobs);

  // Compare each job's start/end dates to every other job's start/end dates
  aFormattedJobs.forEach((oJob) => {
    // No need to compare a job's date range with itself or any job we've already compared against
    const aJobsToCompare = aFormattedJobs.filter(
      (oItem) => oItem !== oJob && !aCheckedJobs.includes(oItem)
    );
    aJobsToCompare.forEach((oComparisonJob) => {
      const bDatesOverlap = fnCheckForJobDateOverlap(oJob, oComparisonJob);
      if (bDatesOverlap) {
        aErrors.push(
          `POSITIONS.${oJob.iOriginalIndex}.START_DATE`,
          `POSITIONS.${oComparisonJob.iOriginalIndex}.END_DATE`
        );
      }
    });
    aCheckedJobs.push(oJob);
  });

  return aErrors;
};

const oJobSchema = object({
  JOB_TITLE: string().required('Please add a job title.'),
  START_DATE: oStartDateFieldSchema,
  END_DATE: oEndDateFieldSchema,
  PRIVACY: privacyFieldSchema,
  IS_RETIRED: bool(),
});

export const oEditEmployerGroupSchema = object({
  PRIVACY: privacyFieldSchema,
  POSITIONS: array()
    .of(oJobSchema)
    .required('Must have positions')
    .min(1, 'Must have at least one position')
    .test(
      'no-overlapping-dates',
      'Employment cannot be saved with overlapping start and end dates.',
      (aValue, oContext) => {
        const aErrors = fnGetOverlappingJobDateErrors(aValue);

        if (aErrors.length === 0) {
          return true;
        }

        const aYupErrors = aErrors.map((sErrorFieldName) =>
          oContext.createError({
            path: sErrorFieldName,
            message: 'Overlapping Dates',
          })
        );
        return new ValidationError(aYupErrors);
      }
    ),
});

export default {};
