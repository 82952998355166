import React from 'react';
import PropTypes from 'prop-types';
import DateAndTime from 'date-and-time';
import * as R from 'ramda';

import Accordion from 'components/Layout/Accordion';
import { oCurrencyFormatter, sortByKey } from 'utils/common';
import { installmentPropType } from './helpers';

/**
 * Displays list of paid installments.
 */
const InstallmentsPaid = ({ aData }) => {
  const aCopyData = R.clone(aData);
  const aSortedData = sortByKey(aCopyData, 'DUE_DATE', false);
  return (
    <Accordion
      mTitle='Installments Paid'
      sSize='small'
      className='installmentsPaid'
      sGtmCategory='Installments Paid'
    >
      <div className='installmentsPaid__content'>
        <table className='installmentsPaid__table'>
          <thead className='hide_accessibly'>
            <tr>
              <th>Date</th>
              <th>Amount Paid</th>
            </tr>
          </thead>
          <tbody className='installmentsPaid__tableBody'>
            {aSortedData.map((oInstallment) => {
              const oDueDate = new Date(oInstallment.DUE_DATE);
              const sDate = DateAndTime.format(oDueDate, 'MM.DD.YY');
              const sAmount = oCurrencyFormatter.format(oInstallment.APPLIED);
              return (
                <tr className='installmentsPaid__row' key={oInstallment.ID}>
                  <td className='installmentsPaid__date'>{sDate}</td>
                  <td className='installmentsPaid__amount'>{sAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Accordion>
  );
};

InstallmentsPaid.propTypes = {
  aData: PropTypes.arrayOf(installmentPropType).isRequired,
};

export default InstallmentsPaid;
