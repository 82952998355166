import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import AddNote from './DefaultFields/AddNote';
import { fBuildContactUsNotePrefix } from './helpers';

const OptionsModalContactForm = ({
  oRecordData,
  handleSubmit,
  sRecordType,
}) => {
  const [addNoteVisible, setAddNoteVisible] = useState(false);
  const [addNoteFormStatus, setAddNoteFormStatus] = useState(null);

  const toggleAddNoteVisible = () => {
    if (addNoteVisible && addNoteFormStatus === 'success') {
      setAddNoteFormStatus(null);
    }
    setAddNoteVisible(!addNoteVisible);
  };

  const getAddNoteButtonLabel = useCallback(() => {
    if (!addNoteVisible) {
      return 'Contact Us';
    }

    return addNoteFormStatus === 'success' ? 'Close' : 'Cancel';
  }, [addNoteFormStatus, addNoteVisible]);

  return (
    <>
      <Button
        sFlavor='underline'
        className='optionsModal__contactUs'
        fnHandleClick={toggleAddNoteVisible}
      >
        {getAddNoteButtonLabel(addNoteVisible, addNoteFormStatus)}
      </Button>
      {addNoteVisible && (
        <AddNote
          visible={addNoteVisible}
          setVisible={setAddNoteVisible}
          onSubmit={handleSubmit}
          status={addNoteFormStatus}
          setFormStatus={setAddNoteFormStatus}
          classname='addNoteCallout'
          formClassName='addNote__textArea'
          submitClassName='addNote__submitBtn'
          sNotePrefix={fBuildContactUsNotePrefix(sRecordType, oRecordData)}
        />
      )}
    </>
  );
};

OptionsModalContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  oRecordData: PropTypes.object.isRequired,
  sRecordType: PropTypes.string.isRequired,
};

export default OptionsModalContactForm;
