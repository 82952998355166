import { createPortal } from 'react-dom';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a portal as the last child of the specified dom element.
 *
 * @param {string} sId - Id of the dom element you want to render the portal in.
 * @param {node} children - Children to render in the portal.
 * @returns {node} - Portal.
 */

const Portal = ({ sRootElementId, children }) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    let parentElement = document.getElementById(sRootElementId);
    // If there is no existing DOM element, add a new one.
    if (!parentElement) {
      console.warn(
        `No parent element with id "${sRootElementId}" found. Creating one as a child of "app".`
      );
      parentElement = document.createElement('div');
      parentElement.setAttribute('id', sRootElementId);
      document.getElementById('app').appendChild(parentElement);
    }

    parentElement.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      if (parentElement.childNodes.length === 0) {
        parentElement.remove();
      }
    };
  }, [sRootElementId]);

  if (!rootElemRef.current) {
    rootElemRef.current = document.createElement('div');
  }

  return createPortal(children, rootElemRef.current);
};

Portal.prototype = {
  sRootElementId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Portal.defaultProps = {
  sRootElementId: 'app',
};

export default Portal;
