import * as R from 'ramda';

/**
 * Sorts array of profile data by putting the primary record first,
 * then sorts the rest A–Z by type.
 *
 * @param {array} - Data to sort.
 *
 * @returns {array}
 */
const fnSortByPrimaryThenType = R.sortWith([
  R.descend(R.prop('PRIMARY')),
  R.ascend(R.path(['TYPE', 'DESCRIPTION'])),
]);

export default fnSortByPrimaryThenType;
