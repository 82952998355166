import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import sortByPrimaryThenType from '../fnSortByPrimaryThenType';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import PhoneEditMode from './editMode';
import PhoneViewMode from './viewMode';

const phoneSelector = (state) => (state.Profile || {}).PHONE || [];

const getPhoneData = createSelector(phoneSelector, (items) =>
  sortByPrimaryThenType(items)
);

const Phone = () => {
  const [activeFormSection] = useActiveFormSection();
  const aPhones = useSelector(getPhoneData);
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, setIsEditMode] = useState(false);
  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      setIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      setIsEditMode(false);
    }
  }, [activeFormSection]);
  return (
    <ProfileSubsectionLayout
      sTitle='Phone'
      left={
        aPhones.length > 1 && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <PhoneEditMode aPhones={aPhones} bIsExpanded={bIsExpanded} />
        ) : (
          <PhoneViewMode aPhones={aPhones} bIsExpanded={bIsExpanded} />
        )
      }
    />
  );
};

export default Phone;
