import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { Radio } from 'components/Form/Fields';

const SelectJobPrivacy = ({ sFormikFieldName, sRecordId }) => {
  const [oEmployerPrivacyField] = useField('PRIVACY');
  // eslint-disable-next-line no-unused-vars
  const [oJobPrivacyField, oJobPrivacyMeta, oJobPrivacyHelpers] =
    useField(sFormikFieldName);
  const bEmployerIsPrivate = oEmployerPrivacyField.value === '2';

  // Auto-magically make this job private when the employer is set to private
  useEffect(() => {
    const bJobIsPublic = oJobPrivacyField.value === '0';
    if (bEmployerIsPrivate && bJobIsPublic) {
      oJobPrivacyHelpers.setValue('2');
    }
  }, [oJobPrivacyField, bEmployerIsPrivate, oJobPrivacyHelpers]);

  return (
    <fieldset className='optionsModal__section'>
      <legend className='optionsModal__sectionTitle'>Privacy</legend>
      <Radio
        sName={sFormikFieldName}
        sId={`${sRecordId}-privacy-public`}
        mValue='0'
        sLabel='Public'
        sUi='buttons'
        bIsDisabled={bEmployerIsPrivate}
      />
      <Radio
        sName={sFormikFieldName}
        sId={`${sRecordId}-privacy-private`}
        mValue='2'
        sLabel='Private'
        sUi='buttons'
      />
      {bEmployerIsPrivate && (
        <p className='selectJobPrivacy__message'>
          To mark this job as public, you must make the employer public.
        </p>
      )}
    </fieldset>
  );
};

SelectJobPrivacy.propTypes = {
  sFormikFieldName: PropTypes.string.isRequired,
  sRecordId: PropTypes.string.isRequired,
};

export default SelectJobPrivacy;
