import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import EventDetailsPageHeader from './EventDetailsPageHeader';

const EventDetailsSignInRequired = ({ oEvent, fnSetShowSignInModal }) =>
  oEvent ? (
    <>
      <EventDetailsPageHeader oEvent={oEvent} />
      <p>
        Please{' '}
        <Button
          fnHandleClick={() => fnSetShowSignInModal(true)}
          sFlavor='underline'
          className='eventDetails__regRequiresSignInLink'
        >
          sign in
        </Button>{' '}
        to see details about this event and to register.
      </p>
    </>
  ) : null;

EventDetailsSignInRequired.propTypes = {
  oEvent: PropTypes.shape({
    TITLE: PropTypes.string,
  }),
  fnSetShowSignInModal: PropTypes.func.isRequired,
};

EventDetailsSignInRequired.defaultProps = {
  oEvent: null,
};

export default EventDetailsSignInRequired;
