import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { currentUserLookupIdSelector } from 'redux/selectors';
import oWnApi from 'utils/WnApi';
import LoadingZone from 'components/LoadingZone';
import { SkeletonText } from 'components/LoadingZone/Skeleton';
import oIconLibrary from 'utils/iconLibrary';
import { fnFormatMicroscoreDetails } from './helpers';

import SVGTopHat from '../../../../images/Icons/SVGtophat';

const TopHats = ({ score, lookupId }) => {
  const [sExplanationText, fnSetExplanationText] = useState('');
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);
  const [bShowTooltip, fnSetShowTooltip] = useState(false);
  const [bIsLoading, fnSetIsLoading] = useState(false);

  // Because this component is rendered in a virtualized list,
  // it DOES NOT get unmounted when the user sorts the list.
  // When the score and lookup ID change, we need to "reset" the component.
  useEffect(() => {
    // Close tooltip
    fnSetShowTooltip(false);

    // Update explanation based on score
    if (score === 0) {
      fnSetExplanationText('No similarities');
    } else {
      fnSetExplanationText('');
    }
  }, [score, lookupId]);

  useEffect(() => {
    const fnFetchData = async () => {
      fnSetIsLoading(true);
      const sRequestPath = `constituents/${sCurrentUserLookupId}/microscore_details?comparisonLookupId=${lookupId}`;
      const oResponse = await oWnApi.get(sRequestPath);
      const sDetails = await fnFormatMicroscoreDetails(oResponse.data);
      fnSetExplanationText(sDetails);
      fnSetIsLoading(false);
    };

    // Only call the microscore API when:
    // the user opens the tooltip,
    // the user has a connection with this constituent,
    // and we haven't already fetched the microscore details.
    if (bShowTooltip && score > 0 && !sExplanationText) {
      fnFetchData();
    }
  }, [lookupId, score, sCurrentUserLookupId, bShowTooltip, sExplanationText]);

  return (
    <div className='topHats' aria-label={`${score} top hat icons`}>
      <Rating
        className='topHats__iconWrapper'
        initialRating={score}
        emptySymbol={<SVGTopHat />}
        fullSymbol={<SVGTopHat fill='#000000' />}
        readonly
      />
      <div className='tooltip__wrapper'>
        <button
          type='button'
          className='tooltip__triggerButton button--iconOnly--tertiary'
          onClick={() => fnSetShowTooltip(!bShowTooltip)}
          aria-label='Connection details'
          aria-describedby={`tooltip-details-${lookupId}`}
        >
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome.info} />
        </button>
        <div
          id={`tooltip-details-${lookupId}`}
          className={`topHats__tooltip tooltip tooltip--top ${
            bShowTooltip ? 'topHats__tooltip--show' : ''
          }`}
          role='tooltip'
        >
          <LoadingZone
            isLoading={bIsLoading}
            skeleton={
              <div className='topHats__toolTipSkeleton'>
                <SkeletonText size='small' width='80%' />
                <SkeletonText size='small' width='50%' />
              </div>
            }
          >
            <p className='topHats__tooltipText'>{sExplanationText}</p>
          </LoadingZone>
        </div>
      </div>
    </div>
  );
};

TopHats.propTypes = {
  score: PropTypes.number.isRequired,
  lookupId: PropTypes.string.isRequired,
};

export default TopHats;
