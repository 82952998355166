/**
 * Retrieves list of involvements for the given type.
 *
 * @example - Get Greek life involvments.
 * fnGetInvolvmentsByType('Greek', 'Activities: Photo Club / Greek: Alpha Beta, Omegatron');
 * // 'Alpha Beta, Omegatron'
 *
 * @param {string} sType - Involvement type. One of: 'Activities', 'Athletics', or 'Greek'.
 * @param {string} sInvolvements - Combined involvement list.
 * @returns {string}
 */
const fnGetInvolvementsByType = (sType, sInvolvements) => {
  let sResult = '';
  const aInvolvementsByType = sInvolvements.split('/');
  const sMatch = aInvolvementsByType.find((sItem) => sItem.includes(sType));
  if (sMatch) {
    sResult = sMatch.replace(`${sType}: `, '');
  }
  return sResult;
};

export const fnBuildClaimedClassmatesCsv = (aClaimedClassmates) => {
  const aDataForCsv = aClaimedClassmates.map((item) => {
    const { INVOLVEMENTS } = item;
    const sActivities = fnGetInvolvementsByType('Activities', INVOLVEMENTS);
    const sAthletics = fnGetInvolvementsByType('Athletics', INVOLVEMENTS);
    const sGreek = fnGetInvolvementsByType('Greek', INVOLVEMENTS);
    const sReunionDonor = item.REUNIONDONOR ? 'Yes' : 'No';
    const sRegistered =
      item.HOMECOMINGATTENDANCESTATUS === 'Registered' ? 'Yes' : 'No';

    return {
      CLASSMATE: item.PROSPECTNAME,
      NICKNAME: item.NICKNAME,
      MIDDLENAME: item.MIDDLENAME,
      EMAILADDRESS: item.EMAILADDRESS,
      PRIMARYPHONE: item.PHONENUMBER,
      MOBILE: item.MOBILENUMBER,
      STREETADDRESS: item.ADDRESSBLOCK,
      CITY: item.CITY,
      STATE: item.STATE,
      ZIP: item.ZIP,
      EMPLOYERNAME: item.EMPLOYER,
      COUNTRY: item.COUNTRY,
      ACTIVITIES: sActivities,
      ATHLETICS: sAthletics,
      GREEK: sGreek,
      MAJOR: item.MAJOR,
      REUNIONDONOR: sReunionDonor,
      REGISTERED: sRegistered,
    };
  });
  return aDataForCsv;
};

export default { fnBuildClaimedClassmatesCsv };
