import { useEffect } from 'react';

/**
 * Custom hook that runs a callback function when the user clicks
 * outside of the given ref (or presses the escape key).
 *
 * @param {React.RefObject} oRef - Ref for container HTML element.
 * @param {function} fSetIsModalVisible - Callback for outside clicks.
 * @param {boolean} bShouldCloseOnOutsideClick - Determines if hook should track outside clicks.
 */
function useCloseOnOutsideClick(
  oRef,
  fSetIsModalVisible,
  bShouldCloseOnOutsideClick = true
) {
  if (!oRef || !fSetIsModalVisible) {
    console.warn(
      'The useCloseOnOutsideClick hook requires a ref and a modal visibility function.'
    );
  }

  useEffect(() => {
    // Close the modal if user clicked outside the element with the oRef
    function handleClickOutside(event) {
      if (oRef.current && !oRef.current.contains(event.target)) {
        fSetIsModalVisible(false);
      }
    }

    // Close the modal if the user pressed the Escape key
    function handleEscapeKeyPress(event) {
      if (event.key === 'Escape') {
        fSetIsModalVisible(false);
      }
    }

    // Hooks can't be called conditionally, so this check has to go inside the useEffect.
    if (bShouldCloseOnOutsideClick) {
      // The last parameter, `true`, is necessary to prevent modals containing react-select dropdowns
      // from closing when the user clears the dropdown by clicking the "X" (see AWND-973).
      // Basically, this makes the browser run this event listener code before running react-select's clear code.
      // It's weird. But it works. ¯\_(ツ)_/¯
      document.addEventListener('mousedown', handleClickOutside, true);
      document.addEventListener('keydown', handleEscapeKeyPress);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [oRef, fSetIsModalVisible, bShouldCloseOnOutsideClick]);
}

export default useCloseOnOutsideClick;
