import { object, string } from 'yup';

import { privacyFieldSchema } from 'utils/schemas/fields';

const basicNameSchema = object({
  FIRSTNAME: string().max(50).required('Please enter a first name'),
  NICKNAME: string().max(50).nullable(),
  MIDDLENAME: string().max(50),
  LASTNAME: string().max(50).required('Please enter a last name'),
});

const nameOptionsSchema = object({
  TITLE: object().nullable(),
  PROFESSIONALSUFFIX: object().nullable(),
  PERSONALSUFFIX: object().nullable(),
});

const nameOptionsSchemaWithPrivacy = nameOptionsSchema.concat(
  object({
    PRIVACY: privacyFieldSchema,
  })
);

const nameSchema = basicNameSchema.concat(nameOptionsSchema);

const nameSchemaWithPrivacy = nameSchema.concat(
  object({
    PRIVACY: privacyFieldSchema,
  })
);

export {
  nameSchema,
  nameSchemaWithPrivacy,
  nameOptionsSchema,
  nameOptionsSchemaWithPrivacy,
};
