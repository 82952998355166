import { useEffect, useRef } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { oAdvancedSearchQueryParamSchema } from './helpers';
import QueryParamsToAdvancedSearchTransformer from './QueryParamsToAdvancedSearchTransformer';

const AdvancedSearchDeepLinkHandler = ({ handleSubmitAdvancedSearch }) => {
  const oLocation = useLocation();
  const aRegions = useCodeTablesSection('REGION');
  const aCountries = useCodeTablesSection('COUNTRY');
  const aEducPrograms = useCodeTablesSection('EDUCATIONALPROGRAM');
  const aInvolvements = useCodeTablesSection('INVOLVEMENT');
  const aEducDepts = useCodeTablesSection('EDUCATIONALDEPARTMENT');
  const aAffinityGroups = useCodeTablesSection('AFFINITY_GROUP');
  const aSkills = useCodeTablesSection('JOB_SPECIALTY');
  const bHasProcessedLink = useRef(false);

  // Process a "deep link" containing advanced search criteria
  useEffect(() => {
    // Only process the URL query params once per page load
    if (!bHasProcessedLink.current) {
      const oQueryParams = qs.parse(oLocation.search, {
        ignoreQueryPrefix: true,
      });
      oAdvancedSearchQueryParamSchema
        .validate(oQueryParams)
        .then(async (oValidatedQueryParams) => {
          // transform it for formik's advanced search fields
          const bHasRequiredCodeTablesData =
            aRegions.length > 0 &&
            aCountries.length > 0 &&
            aEducPrograms.length > 0 &&
            aInvolvements.length > 0 &&
            aEducDepts.length > 0 &&
            aAffinityGroups.length > 0 &&
            aSkills.length > 0;
          if (bHasRequiredCodeTablesData) {
            const oTransformer = new QueryParamsToAdvancedSearchTransformer({
              aRegions,
              aCountries,
              aEducPrograms,
              aInvolvements,
              aEducDepts,
              aAffinityGroups,
              aSkills,
            });
            const oFormikReady = await oTransformer.transform(
              oValidatedQueryParams
            );
            handleSubmitAdvancedSearch(oFormikReady);
            bHasProcessedLink.current = true;
          }
        })
        .catch((oError) => {
          console.warn(
            `The query params ${oLocation.search} do not match the format for an advanced search.`
          );
          console.error(oError);
          bHasProcessedLink.current = true;
        });
    }
  }, [
    aEducDepts.length,
    aEducPrograms.length,
    aInvolvements.length,
    aRegions.length,
    aCountries.length,
    aAffinityGroups.length,
    aSkills.length,
    oLocation.search,
    handleSubmitAdvancedSearch,
  ]);

  return null;
};

AdvancedSearchDeepLinkHandler.propTypes = {
  handleSubmitAdvancedSearch: PropTypes.func.isRequired,
};
export default AdvancedSearchDeepLinkHandler;
