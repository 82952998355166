const fnGetFormattedBirthdate = (birthdate) => {
  if (birthdate === '00000000') {
    return '';
  }

  // Gather date parts from original format: 19930605
  const month = birthdate.substring(4, 6);
  const day = birthdate.substring(6);
  const year = birthdate.substring(0, 4);

  // If DAD doesn't have a birthdate year, it sets year to 0000
  return year === '0000' ? `${month}/${day}` : `${month}/${day}/${year}`;
};

export default fnGetFormattedBirthdate;
