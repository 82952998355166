import * as R from 'ramda';
import { createSelector } from 'reselect';

const fnSortByTypeThenDepartment = R.sortWith([
  R.ascend(R.prop('TYPE')),
  R.ascend(R.prop('DEPARTMENT')),
]);

/**
 * Formats degree data into a human-friendly string.
 *
 * @param {object} oDegree - Degree's API data.
 * @returns {string}
 */
export const fnFormatDegreeText = (oDegree) => {
  const sShortYear = oDegree.PREFERREDCLASSYEAR.slice(-2);
  let sFormatted = `'${sShortYear}`;

  const aDetails = oDegree.DETAILS?.DETAIL || [];
  if (aDetails.length > 0) {
    const aSortedDetails = fnSortByTypeThenDepartment(aDetails);
    const aFormattedDetails = aSortedDetails.map(
      ({ DEPARTMENT, TYPE }) => `${DEPARTMENT} (${TYPE})`
    );
    const sCombinedDetails = aFormattedDetails.join(', ');
    sFormatted = `'${sShortYear} ${sCombinedDetails}`;
  }

  return sFormatted;
};

const degreeSelector = (state) => state.Profile.DEGREE;
const fnSortByYearDescending = R.descend(R.prop('PREFERREDCLASSYEAR'));
const fnSortDegreesByYear = (aDegrees) =>
  R.sort(fnSortByYearDescending, aDegrees);

export const degreesByYearSelector = createSelector(
  degreeSelector,
  fnSortDegreesByYear
);

export default { fnFormatDegreeText };
