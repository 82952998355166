import React from 'react';

import { SkeletonText } from 'components/LoadingZone/Skeleton';

const EventCardSkeleton = () => (
  <div>
    <div className='eventCardSkeleton__event'>
      <div className='eventCardSkeleton__eventDate' />
      <div className='eventCardSkeleton__eventDetails'>
        <SkeletonText width='40%' />
        <SkeletonText width='25%' />
      </div>
    </div>
    <div className='eventCardSkeleton__event'>
      <div className='eventCardSkeleton__eventDate' />
      <div className='eventCardSkeleton__eventDetails'>
        <SkeletonText width='40%' />
        <SkeletonText width='25%' />
      </div>
    </div>
  </div>
);

export default EventCardSkeleton;
