import { object, array, string, number, boolean } from 'yup';

import { coerceToString, coerceToArray, coerceToNull } from 'utils/yupHelpers';

const biographicalNormalizer = object({
  TITLE: object().nullable().transform(coerceToNull),
  MIDDLENAME: string().transform(coerceToString),
  PROFESSIONALSUFFIX: object().nullable().transform(coerceToNull),
  PERSONALSUFFIX: object().nullable().transform(coerceToNull),
  ATTENDNAME: object().nullable().transform(coerceToNull),
  RELIGION: object().nullable().transform(coerceToNull).default(null),
  USERNAME: string().ensure(),
  VOLUNTEERTYPES: object({
    TYPE: array()
      .of(
        object({
          COMMITTEES: array().transform(coerceToArray).ensure(),
        })
      )
      .transform(coerceToArray)
      .ensure(),
  }),
  NOTABLEAFFILIATIONS: array(
    object({
      ISFORMER: boolean(),
      ISLIFETIMEMEMBER: boolean(),
      ISWFUAFFILIATION: boolean(),
      STARTDATE: string().transform(coerceToString),
      ENDDATE: string().transform(coerceToString),
    })
  )
    .transform(coerceToArray)
    .ensure(),
  DECEASEDDATE: string().transform(coerceToString),
  ISPHILANTHROPYREPORTRECIPIENT: boolean(),
  TOTAL_PRIVACY: boolean(),
  GOOGLEACCOUNTEXISTS: boolean(),
  ISDONOR: boolean(),
  ISSCHOLARSHIPRECIPIENT: boolean(),
  GDPR: object({
    IMPLICATION: boolean(),
  }),
  HAS_PHOTO: boolean(),
});

const addressNormalizer = object({
  ADDRESSBLOCK: string().transform(coerceToString),
  ADDRESSCITY: string().transform(coerceToString),
  ADDRESSSTATE: string().transform(coerceToString),
  ADDRESSSTATEABBRV: string().transform(coerceToString),
  ADDRESSZIP: string().transform(coerceToString),
  ADDRESSCOUNTRY: string().transform(coerceToString),
  PRIMARY: boolean(),
  START_DATE: string().transform(coerceToString),
  END_DATE: string().transform(coerceToString),
});

const emailNormalizer = object({
  PRIMARY: boolean(),
});

const phoneNormalizer = object({
  PRIMARY: boolean(),
  COUNTRY: object().nullable().transform(coerceToNull).default(null),
});

const childNormalizer = object({
  MIDDLENAME: string().transform(coerceToString),
});

const spouseNormalizer = object({
  MIDDLENAME: string().transform(coerceToString),
  DECEASEDDATE: string().transform(coerceToString),
});

const notificationNormalizer = object({
  READSTATUS: boolean(),
});

const myNetworkNormalizer = object({
  CITY: string().transform(coerceToString),
  STATE: string().transform(coerceToString),
  EMPLOYER: string().transform(coerceToString),
  SCORE: number(),
  SCORE_1TO5: number(),
  HAS_PHOTO: boolean().default(false),
});

const employmentNormalizer = object({
  PRIMARY: boolean(),
  START_DATE: string().transform(coerceToString),
  END_DATE: string().transform(coerceToString),
  POSITIONS: array(
    object({
      JOB_TITLE: string().transform(coerceToString),
      START_DATE: string().transform(coerceToString),
      END_DATE: string().transform(coerceToString),
      IS_RETIRED: boolean(),
    })
  )
    .transform(coerceToArray)
    .ensure(),
});

const degreeNormalizer = object({
  DETAILS: object({
    DETAIL: array().transform(coerceToArray).ensure(),
  }),
});

const registeredEventsNormalizer = object({
  LOCATION: object({
    ROOM: string().transform(coerceToString),
    NAME: string().transform(coerceToString),
  }),
  SUB_EVENTS: array().transform(coerceToArray),
});

export {
  biographicalNormalizer,
  addressNormalizer,
  emailNormalizer,
  phoneNormalizer,
  childNormalizer,
  spouseNormalizer,
  notificationNormalizer,
  myNetworkNormalizer,
  employmentNormalizer,
  degreeNormalizer,
  registeredEventsNormalizer,
};
