import React, { useEffect, useState } from 'react';
import { useFormikContext, Field } from 'formik';
import { useSelector } from 'react-redux';

import { Radio, TextArea, DollarAmountTextField } from 'components/Form/Fields';
import {
  calculateCurrentDuePayablePledges,
  calculateTotalBalancePayablePledges,
  calculatePendingBalance,
} from '../helpers/calculateSelectPaymentTotals';

const SelectPaymentFormBody = () => {
  const oPledgePaymentData = useSelector((state) => state.PledgePayment);
  const [oCurrentDue, setCurrentDue] = useState(null);
  const [oTotalBalance, setTotalBalance] = useState(null);
  const [oPendingAmount, setPendingAmount] = useState(null);
  const oFormikContext = useFormikContext();

  // Determine current, total due, and pending amounts
  useEffect(() => {
    const oCalculatedCurrentDue = calculateCurrentDuePayablePledges(
      oPledgePaymentData.payablePledges
    );
    setCurrentDue(oCalculatedCurrentDue);
    const oCalculatedTotalBalance = calculateTotalBalancePayablePledges(
      oPledgePaymentData.payablePledges
    );
    setTotalBalance(oCalculatedTotalBalance);
    const oCalculatedPendingAmount = calculatePendingBalance(
      oPledgePaymentData.payablePledges
    );
    setPendingAmount(oCalculatedPendingAmount);
  }, [oPledgePaymentData.payablePledges]);

  // Clear other amount and note fields when user chooses current or total amount due
  useEffect(
    () => {
      const bIsCurrentOrTotal =
        oFormikContext.values.paymentType === 'current' ||
        oFormikContext.values.paymentType === 'total';
      const bHasOtherDetails =
        oFormikContext.values.otherAmount || oFormikContext.values.note;

      if (bIsCurrentOrTotal && bHasOtherDetails) {
        oFormikContext.setFieldValue('otherAmount', '');
        oFormikContext.setFieldValue('note', '');
      }
    },
    // oFormikContext changes on EVERY render -__-, so it's useless as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oFormikContext.values.paymentType, oFormikContext.values.otherAmount]
  );

  // Auto-select "Other" if user enters an other amount.
  useEffect(
    () => {
      const { otherAmount, paymentType } = oFormikContext.values;
      if (otherAmount !== '' && paymentType !== 'other') {
        oFormikContext.setFieldValue('paymentType', 'other');
      }
    },
    // oFormikContext changes on EVERY render -__-, so it's useless as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oFormikContext.values.otherAmount, oFormikContext.values.paymentType]
  );

  // Sync hidden amount field with amount user chose
  useEffect(
    () => {
      const { amount } = oFormikContext.values;
      const sOtherAmountSansCommas = oFormikContext.values.otherAmount.replace(
        /,/g,
        ''
      );
      switch (oFormikContext.values.paymentType) {
        case 'current':
          if (oCurrentDue?.value && amount !== oCurrentDue?.value) {
            oFormikContext.setFieldValue('amount', oCurrentDue?.value);
          }
          break;
        case 'total':
          if (oTotalBalance?.value && amount !== oTotalBalance?.value) {
            oFormikContext.setFieldValue('amount', oTotalBalance?.value);
          }
          break;
        case 'other':
          if (amount !== sOtherAmountSansCommas) {
            oFormikContext.setFieldValue('amount', sOtherAmountSansCommas);
          }
          break;
        default:
          break;
      }
    },
    // oFormikContext changes on EVERY render -__-, so it's useless as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      oFormikContext.values.paymentType,
      oFormikContext.values.otherAmount,
      oCurrentDue?.value,
      oTotalBalance?.value,
    ]
  );

  return (
    <>
      <fieldset>
        <legend>Payment Amount</legend>
        <Radio
          sId='amount-current'
          sLabel={`Current Due ${oCurrentDue?.format()}`}
          sName='paymentType'
          mValue='current'
          bIsDisabled={oCurrentDue?.value === 0 || oPendingAmount?.value > 0}
          sWrapperClassName='selectPaymentForm__radio'
          bSilenceErrors
        />
        <Radio
          sId='amount-total'
          sLabel={`Total University Commitments ${oTotalBalance?.format()}`}
          sName='paymentType'
          mValue='total'
          sWrapperClassName='selectPaymentForm__radio'
          bSilenceErrors
          bIsDisabled={oPendingAmount?.value > 0}
        />
        <div className='selectPaymentForm__radioWrapper--other'>
          <Radio
            sId='amount-other'
            sLabel='Other'
            sName='paymentType'
            mValue='other'
            sWrapperClassName='selectPaymentForm__radio'
            bSilenceErrors
          />
          <DollarAmountTextField
            sId='other-dollar-amount'
            sLabel='Other amount'
            sName='otherAmount'
            sClassName='selectPaymentForm__otherAmountField'
            bIsLabelHidden
          />
        </div>
        {oFormikContext.values.paymentType === 'other' && (
          <TextArea
            sId='payment-note'
            sLabel='Note'
            sName='note'
            className='selectPaymentForm__noteField'
            sHelpText='Please share how you would like us to apply your payment.'
          />
        )}
      </fieldset>
      {oFormikContext.touched.paymentType &&
        oFormikContext.errors.paymentType && (
          <p className='field__errorMessage'>
            {oFormikContext.errors.paymentType}
          </p>
        )}
      <Field type='hidden' name='amount' />
    </>
  );
};

export default SelectPaymentFormBody;
