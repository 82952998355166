import React from 'react';
import PropTypes from 'prop-types';

const BetaTag = ({ colorTheme }) => (
  <span className={`betaTag betaTag--${colorTheme}`}>Beta</span>
);

BetaTag.defaultProps = {
  colorTheme: 'gold',
};

BetaTag.propTypes = {
  colorTheme: PropTypes.string,
};

export default BetaTag;
