import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useWindowSize from 'utils/hooks/useWindowSize';

import wnLogoSvg from 'images/logos/wn-logo-gold-gray.svg';
import Button from 'components/Buttons/Button';
import LandingPageSignInModal from '../LandingPage/SocialSignInModal';

const PublicNavBar = () => {
  const [bShowSignInModal, fnSetShowSignInModal] = useState(false);
  const oWindowSize = useWindowSize();
  const oLocation = useLocation();
  // The first-time user process has its own top nav bar, so don't show this one
  const bIsInFirstTimeUserProcess = oLocation.pathname.includes('sign-up');

  return bIsInFirstTimeUserProcess ? null : (
    <header className='publicNavBar'>
      <Link to='/sign-in'>
        <img
          src={wnLogoSvg}
          className='publicNavBar__logo'
          alt='Wake Network logo'
        />
      </Link>
      <div>
        {oWindowSize.width >= 640 && (
          <Link
            to='/sign-up/help/noverify'
            className='landingPage__contactLink'
          >
            Contact Us
          </Link>
        )}
        <Button
          fnHandleClick={() => fnSetShowSignInModal(true)}
          className='publicNavBar__signInButton'
        >
          Sign In
        </Button>
        {bShowSignInModal && (
          <LandingPageSignInModal fnSetShowSignInModal={fnSetShowSignInModal} />
        )}
      </div>
    </header>
  );
};

export default PublicNavBar;
