import React from 'react';

import { SkeletonText } from 'components/LoadingZone/Skeleton';

const GivingSkeleton = () => (
  <div className='givingSkeleton'>
    <div className='givingSkeleton__blackBar' />
    <SkeletonText size='medium' width='50%' />
    <SkeletonText size='medium' width='30%' />
    <SkeletonText size='medium' width='50%' />
    <SkeletonText size='medium' width='30%' />
    <div className='givingSkeleton__blackBar' />
    <SkeletonText size='large' width='80%' />
    <div className='givingSkeleton__blackBar' />
    <SkeletonText size='large' width='80%' />
    <SkeletonText size='large' width='80%' />
    <SkeletonText size='large' width='80%' />
  </div>
);

export default GivingSkeleton;
