import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';

const ViewMoreButton = ({
  fnOnClick,
  bIsShowingMore,
  sViewMoreLabel,
  sViewLessLabel,
}) => (
  <Button sFlavor='underline' className='viewMore' fnHandleClick={fnOnClick}>
    {bIsShowingMore ? sViewLessLabel : sViewMoreLabel}
  </Button>
);

ViewMoreButton.propTypes = {
  fnOnClick: PropTypes.func.isRequired,
  bIsShowingMore: PropTypes.bool.isRequired,
  sViewMoreLabel: PropTypes.string,
  sViewLessLabel: PropTypes.string,
};

ViewMoreButton.defaultProps = {
  sViewMoreLabel: 'View All',
  sViewLessLabel: 'View Less',
};

export default ViewMoreButton;
