import { handleActions } from 'redux-actions';

import { profileExternalViewActions } from './actions';

const initialState = null;
const options = { prefix: 'ProfileExternalView' };

const reducer = handleActions(
  {
    RESET_APP: () => initialState,
    [profileExternalViewActions.set]: {
      next(state, { payload }) {
        return { ...payload, _lastUpdate: Date.now() };
      },
      throw(state) {
        return state;
      },
    },
    [profileExternalViewActions.update]: {
      next(state, { payload }) {
        return { ...state, ...payload, _lastUpdate: Date.now() };
      },
      throw(state) {
        return state;
      },
    },
  },
  initialState,
  options
);

export default reducer;
