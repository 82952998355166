import { createActions } from 'redux-actions';

export default createActions(
  {
    GET: (action) => action,
    SET: (action) => action,
    CREATE: (action) => action,
    UPDATE: (action) => action,
    ADD_NOTE: (action) => action,
    DELETE: (action) => action,
  },
  {
    prefix: 'Profile/EMPLOYMENT',
  }
);
