import React from 'react';

import { Select } from 'components/Form/Fields';

const aRegularSelectOptions = [
  { label: 'Old Gold', value: 'old-gold' },
  { label: 'Black', value: 'black' },
  { label: 'Gray', value: 'gray' },
  { label: 'White', value: 'white' },
];

const aGiantSelectOptions = Array(1001)
  .fill('Dalmation')
  .map((sItem, iIndex) => ({
    label: `${sItem} ${iIndex}`,
    value: `${sItem}${iIndex}`,
  }));

const SelectFields = () => (
  <div>
    <table className='componentLibrary__selectFieldsTable'>
      <caption>Select Fields (single option)</caption>
      <thead>
      <tr>
        <td/>
        <th>&lt; 1,000 options</th>
        <th>&ge; 1,000 options</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>
          Users <strong>can&apos;t</strong> add options
        </th>
        <td>
          <code>&lt;Select : ReactSelect /&gt;</code>
          <Select
            name='selectRegular'
            label='Select'
            options={aRegularSelectOptions}
            placeholder='Choose a color'
            sCypressId='select-regular-single-container'
          />
        </td>
        <td>
          <code>&lt;Select : AsyncSelect /&gt;</code>
          <Select
            name='selectAsync'
            label='Select'
            options={aGiantSelectOptions}
            placeholder='Choose a dalmation'
            sCypressId='select-async-single-container'
          />
        </td>
      </tr>
      <tr>
        <th>
          Users <strong>can</strong> add options
        </th>
        <td>
          <code>&lt;Select : Createable /&gt;</code>
          <Select
            name='selectCreateable'
            label='Select'
            options={aRegularSelectOptions}
            placeholder='Choose a color'
            allowUserToAddOptions
            sCypressId='select-createable-single-container'
          />
        </td>
        <td>
          <code>&lt;Select : AsyncCreateable /&gt;</code>
          <Select
            name='selectAsyncCreateable'
            label='Select'
            options={aGiantSelectOptions}
            placeholder='Choose a dalmation'
            allowUserToAddOptions
            sCypressId='select-async-createable-single-container'
          />
        </td>
      </tr>
      </tbody>
    </table>

    <table className='componentLibrary__selectFieldsTable'>
      <caption>Select Fields (multiple options)</caption>
      <thead>
      <tr>
        <td/>
        <th>&lt; 1,000 options</th>
        <th>&ge; 1,000 options</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>
          Users <strong>can&apos;t</strong> add options
        </th>
        <td>
          <code>&lt;Select : ReactSelect /&gt;</code>
          <Select
            name='selectRegularMulti'
            label='Select'
            options={aRegularSelectOptions}
            placeholder='Choose a color'
            bIsMultiSelect='true'
            sCypressId='select-regular-multi-container'
          />
        </td>
        <td>
          <code>&lt;Select : AsyncSelect /&gt;</code>
          <Select
            name='selectAsyncMulti'
            label='Select'
            options={aGiantSelectOptions}
            placeholder='Choose a dalmation'
            bIsMultiSelect='true'
            sCypressId='select-async-multi-container'
          />
        </td>
      </tr>
      <tr>
        <th>
          Users <strong>can</strong> add options
        </th>
        <td>
          <code>&lt;Select : Createable /&gt;</code>
          <Select
            name='selectCreateableMulti'
            label='Select'
            options={aRegularSelectOptions}
            placeholder='Choose a color'
            allowUserToAddOptions
            bIsMultiSelect='true'
            sCypressId='select-createable-multi-container'
          />
        </td>
        <td>
          <code>&lt;Select : AsyncCreateable /&gt;</code>
          <Select
            name='selectAsyncCreateableMulti'
            label='Select'
            options={aGiantSelectOptions}
            placeholder='Choose a dalmation'
            allowUserToAddOptions
            bIsMultiSelect='true'
            sCypressId='select-async-createable-multi-container'
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
)
;

export default SelectFields;
