/**
 * Builds address value formatted for display on review step.
 *
 * @param {object} oAddress
 *
 * @returns {string}
 */
export const buildAddressValueForReview = (oAddress) => {
  let sAddressValue = '';
  if (oAddress.ADDRESSBLOCK) {
    sAddressValue = `${oAddress.ADDRESSBLOCK} ${oAddress.ADDRESSCITY}, ${oAddress.ADDRESSSTATEABBRV} ${oAddress.ADDRESSZIP} ${oAddress.ADDRESSCOUNTRY}`;
  }
  return sAddressValue;
};

export default buildAddressValueForReview;
