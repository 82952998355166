import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';

import oIconLibrary from 'utils/iconLibrary';
import { fnElementRefPropType } from 'utils/customPropTypes';

// The max Lambda function invocation payload is 6 MB,
// which includes request content and headers,
// so the largest file we can accept is only about 3 MB.
// See https://docs.aws.amazon.com/lambda/latest/dg/gettingstarted-limits.html#function-configuration-deployment-and-execution
const sMaxFileSize = '3 MB';

const FileUpload = ({
  id,
  label,
  name,
  acceptedFileMIMETypes,
  silenceErrors,
  fieldRef,
  fnSetIsLoading,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);
  const [dynamicLabel, setDynamicLabel] = useState(label);
  const sAcceptedFileExtensions = acceptedFileMIMETypes
    .map((sMimeType) => sMimeType.split('/')[1])
    .join(', ');

  const handleChange = async (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      fnSetIsLoading(true);
      helpers.setTouched(true);

      // Change label to name of chosen file
      setDynamicLabel(file.name);

      // Only accept certain file types
      if (!acceptedFileMIMETypes.includes(file.type)) {
        helpers.setError(
          `Please upload one of the following file types: ${sAcceptedFileExtensions}`
        );
        fnSetIsLoading(false);
        return;
      }

      // Only accept files under 3 MB
      if (file.size > 3000000) {
        helpers.setError(
          `Please choose a file that is smaller than ${sMaxFileSize}.`
        );
        fnSetIsLoading(false);
        return;
      }

      const oFileReader = new FileReader();
      oFileReader.onload = () => {
        const sImageDataUrl = oFileReader.result;
        helpers.setValue(sImageDataUrl);
        fnSetIsLoading(false);
      };
      oFileReader.readAsDataURL(file);
    }
  };

  return (
    <div className='fileUpload'>
      <input
        type='file'
        accept={
          acceptedFileMIMETypes.length > 0
            ? acceptedFileMIMETypes.join(', ')
            : '*'
        }
        name={name}
        id={id || name}
        className='fileUpload__input hide_accessibly'
        onChange={handleChange}
        ref={fieldRef}
      />
      <label
        className={`fileUpload__label ${
          meta.error ? 'fileUpload__label--error' : ''
        }`}
        htmlFor={id || name}
      >
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome.upload} />{' '}
        {dynamicLabel}
      </label>
      <p className='field__helpText'>File requirements</p>
      <ul className='t-paragraph'>
        <li>
          File format: <strong>{sAcceptedFileExtensions}</strong>
        </li>
        <li>
          Size: Less than <strong>{sMaxFileSize}</strong>
        </li>
      </ul>
      {!silenceErrors && meta.touched && meta.error ? (
        <p className='field__errorMessage'>{meta.error}</p>
      ) : null}
    </div>
  );
};

FileUpload.defaultProps = {
  id: '',
  label: 'Choose file',
  name: '',
  acceptedFileMIMETypes: [],
  silenceErrors: false,
  fieldRef: null,
  fnSetIsLoading: () => {},
};

FileUpload.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  acceptedFileMIMETypes: PropTypes.arrayOf(PropTypes.string),
  silenceErrors: PropTypes.bool,
  fieldRef: fnElementRefPropType,
  fnSetIsLoading: PropTypes.func,
};

export default FileUpload;
