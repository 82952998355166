import React from 'react';
import PropTypes from 'prop-types';

import LinkWithSourcePath from 'utils/LinkWithSourcePath';
import {
  fnTransformPledgeDataForRender,
  retrievePayablePledges,
  installmentPropType,
} from './helpers';
import {
  calculatePendingBalance,
  calculateTotalBalancePayablePledges,
} from './Payments/PledgePaymentCheckout/helpers/calculateSelectPaymentTotals';
import PledgeList from './PledgeList';

/**
 * Displays list of open pledges.
 */
const Pledges = ({ aPledgeData }) => {
  const aSortedPledgeData = fnTransformPledgeDataForRender(aPledgeData);
  const aPayablePledges = retrievePayablePledges(
    aPledgeData,
    aSortedPledgeData
  );

  const oPendingPledgeBalance = calculatePendingBalance(aPayablePledges);
  const oTotalPledgeBalance =
    calculateTotalBalancePayablePledges(aPayablePledges);
  const bShowPledgePaymentButton =
    aPayablePledges.length > 0 &&
    oPendingPledgeBalance.value !== oTotalPledgeBalance.value;

  return (
    <>
      <div className='myGiving__dividerTitleBlock'>
        <h3 className='myGiving__dividerTitle'>Commitment Installments</h3>
      </div>
      <div className='pledges__contentWrapper'>
        {bShowPledgePaymentButton && (
          <LinkWithSourcePath
            to={{
              pathname: '/philanthropy/commitment-payment',
              state: { aPayablePledges },
            }}
            className='pledges__managePaymentButton button'
          >
            Manage Commitment Payments
          </LinkWithSourcePath>
        )}
        <PledgeList aPledges={aSortedPledgeData} />
      </div>
    </>
  );
};

Pledges.propTypes = {
  aPledgeData: PropTypes.arrayOf(
    PropTypes.shape({
      AMOUNT: PropTypes.string,
      BALANCE: PropTypes.string,
      ID: PropTypes.string,
      INSTALLMENTS: PropTypes.arrayOf(installmentPropType),
      LOOKUPID: PropTypes.string,
      NEXTINSTALLMENTDUEDATE: PropTypes.string,
      PENDING: PropTypes.string,
      PLEDGESUBTYPE: PropTypes.string,
    })
  ).isRequired,
};

export default Pledges;
