import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useActiveFormField from 'utils/hooks/useActiveFormField';
import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { SelectPrivacy } from '../../../../Form/OptionsModal/DefaultFields';
import { OptionsModal } from '../../../../Form';
import actions from './redux/actions';
import AddItemButton from '../../../../Form/AddItemButton';
import useActions from '../../../../../utils/hooks/useActions';
import QuickFindModalForm from '../../../../Form/QuickFindModalForm';
import { addJobSkillSchema, editJobSkillSchema } from './schemas';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const sFormId = 'edit-employment-skills-form';
/**
 * Allows user to add/edit skills.
 */
const SkillsEditMode = ({ aSkills }) => {
  const [bFormIsOpen, fnSetFormIsOpen] = useState(false);
  const [activeFormSection] = useActiveFormSection();
  const bIsEditingCareerSection = activeFormSection === 'career';
  const [initialValues, setInitialValues] = useState({ skill: null });
  const skillActions = useActions(actions);
  const aAllSkills = useCodeTablesSection('JOB_SPECIALTY');
  const [activeFormField, setActiveFormField] = useActiveFormField(sFormId);

  const fnCloseForm = () => {
    setActiveFormField(null);
  };
  const fnOpenForm = () => {
    setActiveFormField(sFormId);
  };

  const handleReset = () => {
    fnSetFormIsOpen(false);
    setInitialValues({ skill: null });
  };

  const handleAddSkillSubmit = async (oFormData) => {
    const oFormattedData = {
      PRIVACY: '0',
      ID: oFormData.skill.ID,
      NAME: oFormData.skill.NAME,
    };
    try {
      const sResult = await new Promise((resolve, reject) =>
        skillActions.create({ data: oFormattedData, resolve, reject })
      );
      if (sResult === 'Success') {
        fnCloseForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (activeFormField === sFormId && bIsEditingCareerSection) {
      fnSetFormIsOpen(true);
    } else {
      fnSetFormIsOpen(false);
    }
  }, [activeFormField, bIsEditingCareerSection, fnSetFormIsOpen]);

  return (
    <>
      {aSkills.map((oSkill) => (
        <ProfileRecordLayout
          left={<div className='record t-paragraph--medium'>{oSkill.NAME}</div>}
          right={
            <OptionsModal
              name='Skills'
              data={oSkill}
              actions={actions}
              validationSchema={editJobSkillSchema}
            >
              <SelectPrivacy data={oSkill} />
            </OptionsModal>
          }
          key={oSkill.ID}
        />
      ))}
      {!bFormIsOpen ? (
        <AddItemButton
          sLabel='Add Skill'
          fnHandleClick={fnOpenForm}
          className='addRecordButton'
        />
      ) : (
        <QuickFindModalForm
          onReset={handleReset}
          onSubmit={handleAddSkillSubmit}
          initialValues={initialValues}
          formikFieldToUpdate='skill'
          options={aAllSkills}
          setIsFormVisible={fnCloseForm}
          schema={addJobSkillSchema}
          sLabel='Skill'
        />
      )}
    </>
  );
};

SkillsEditMode.propTypes = {
  aSkills: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      NAME: PropTypes.string,
      PRIVACY: PropTypes.string,
    })
  ),
};

SkillsEditMode.defaultProps = {
  aSkills: [],
};

export default SkillsEditMode;
