import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import useWindowSize from 'utils/hooks/useWindowSize';
import Accordion from 'components/Layout/Accordion';
import TermsOfUse from './TermsOfUse';
import { CheckBox } from '../../../Form/Fields';
import termsSchema from './schema';

const oInitialValues = {
  terms: false,
};

/**
 * Displays form asking user to accept the terms of service.
 *
 * Note: We are not tracking in DAD if a user accepts the terms of service.
 * Basically, this form is just for show. Confirmed by Wes' decision Jan 2022.
 */
const TermsOfUseAcceptanceForm = ({
  fnHandleSubmit,
  fnHandleSubmitDisable,
}) => {
  const oWindowSize = useWindowSize();
  const bIsMobile = oWindowSize.width < 640;
  return (
    <Formik
      onSubmit={fnHandleSubmit}
      initialValues={oInitialValues}
      validateOnBlur
      validationSchema={termsSchema}
    >
      {(formikProps) => (
        <Form
          className='termsOfUseAcceptancePg__form'
          onChange={fnHandleSubmitDisable(formikProps)}
        >
          <p className='termsOfUseAcceptancePg__formCheckBoxWrapper'>
            <CheckBox
              sId='terms'
              sName='terms'
              sLabel='I agree to the Terms of Service'
            />
          </p>
          {!bIsMobile ? (
            <TermsOfUse />
          ) : (
            <Accordion
              mTitle='View WakeNetwork Acceptance Use Policy'
              sSize='smallUnderline'
            >
              <TermsOfUse />
            </Accordion>
          )}
        </Form>
      )}
    </Formik>
  );
};

TermsOfUseAcceptanceForm.propTypes = {
  fnHandleSubmit: PropTypes.func.isRequired,
  fnHandleSubmitDisable: PropTypes.func.isRequired,
};

export default TermsOfUseAcceptanceForm;
