import React, { useState, useEffect } from 'react';
import DateAndTime from 'date-and-time';

import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import RecordPrivacyIcon from '../RecordPrivacyIcon';
import { oGroupPropType } from './helpers';

const buildDateRangeMarkup = (startDate, endDate) => {
  let dateMarkup = '';
  if (startDate && endDate) {
    const oStartDate = new Date(`${startDate}T00:00`);
    const oEndDate = new Date(`${endDate}T00:00`);
    dateMarkup = (
      <p className='record__dateRange t-dates'>
        {DateAndTime.format(oStartDate, 'MMM YYYY')} &ndash;{' '}
        {DateAndTime.format(oEndDate, 'MMM YYYY')}
      </p>
    );
  } else if (startDate) {
    const oStartDate = new Date(`${startDate}T00:00`);
    dateMarkup = (
      <p className='record__dateRange t-dates'>
        {DateAndTime.format(oStartDate, 'MMM YYYY')} &ndash; Present
      </p>
    );
  }
  return dateMarkup;
};

const GroupRecord = ({ oData }) => {
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);

  useEffect(() => {
    fnSetIsEditMode(activeFormSection === 'notableAffiliations');
  }, [activeFormSection]);

  return (
    <>
      <div className='record t-paragraph--medium'>
        {oData.ORGANIZATIONNAME}
        {!bIsEditMode && (
          <RecordPrivacyIcon ariaId={`private-eye-${oData.ID}`} />
        )}
      </div>
      {oData.POSITION && <p className='subRecord'>{oData.POSITION}</p>}
      {buildDateRangeMarkup(oData.STARTDATE, oData.ENDDATE)}
    </>
  );
};

GroupRecord.propTypes = {
  oData: oGroupPropType.isRequired,
};

export default GroupRecord;
