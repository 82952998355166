import React, { useState } from 'react';
import usePageTitle from 'utils/hooks/usePageTitle';
import { useNavigate } from 'react-router-dom';

import ContinueButton from 'components/Authentication/AuthLayout/ContinueButton';
import AuthLayout from '../../AuthLayout';
import IAmButton from './IAmButton';

/**
 * Sign Up main navigation form.
 * Accepts user input to determine the sign up path to take.
 */
const SignUp = () => {
  const fnNavigate = useNavigate();
  const [sMatchType, fnSetMatchType] = useState('');
  usePageTitle('Sign Up');
  const aMatchTypes = ['alumni', 'student', 'parent', 'friend', 'staff'];

  return (
    <AuthLayout
      sMatchType={sMatchType}
      continueButton={
        <ContinueButton
          fnHandleContinue={() => fnNavigate(`/sign-up/match/${sMatchType}`)}
          bContinueButtonDisabled={!sMatchType}
        />
      }
    >
      <h2>Tell us more about you.</h2>
      <div className='iAm'>
        {aMatchTypes.map((sMatch) => (
          <IAmButton
            sMatchType={sMatch}
            fnHandleClick={() => fnSetMatchType(sMatch)}
            bSelected={sMatchType === sMatch}
            key={sMatch}
          />
        ))}
      </div>
    </AuthLayout>
  );
};

export default SignUp;
