import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { array, object, bool, string } from 'yup';

import { coerceToArray, coerceToString } from 'utils/yupHelpers';
import { currentUserLookupIdSelector } from 'redux/selectors';
import oWnApi from 'utils/WnApi';

const myGivingDataNormalizer = object({
  GIVING: object({
    HISTORY_BY_YEAR: array(
      object({
        IS_CURRENT_FISCAL_YEAR: bool(),
        GIFTS: array(
          object({
            DESIGNATIONS: array().transform(coerceToArray),
          })
        ).transform(coerceToArray),
      })
    ).transform(coerceToArray),
  }),
  PLEDGES: array(
    object({
      INSTALLMENTS: array(
        object({
          DESIGNATIONS: array().transform(coerceToArray),
        })
      ).transform(coerceToArray),
      PLEDGESUBTYPE: string().transform(coerceToString),
    })
  ).transform(coerceToArray),
});

const oNonDonorData = {
  GIVING: {
    SUMMARY: {
      LIFETIME: 0,
      THIS_FISCAL_YEAR: 0,
    },
  },
};

/**
 * Custom hook to retrieve philanthropy data for the current user.
 *
 * @returns {object}
 */
const usePhilanthropyData = () => {
  const [bIsLoading, fnSetIsLoading] = useState(true);
  const [oPhilanthropyData, fnSetPhilanthropyData] = useState(null);
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const bIsDonor = useSelector((state) => state.Profile?.BIOGRAPHICAL?.ISDONOR);

  // Fetch philanthropy data
  useEffect(() => {
    const oAbortController = new AbortController();
    const fetchPhilanthropyData = async () => {
      try {
        const result = await oWnApi.get(
          `constituents/${sLookupId}/philanthropy`,
          true,
          oAbortController.signal
        );
        if (result.status === 200) {
          const normalizedData = await myGivingDataNormalizer.validate(
            result.data.PHILANTHROPY
          );
          fnSetPhilanthropyData(normalizedData);
          fnSetIsLoading(false);
        }
      } catch (err) {
        console.error('Error fetching philanthropy data', err);
      }
    };

    if (bIsDonor) {
      fetchPhilanthropyData();
    } else {
      fnSetPhilanthropyData(oNonDonorData);
      fnSetIsLoading(false);
    }

    return () => {
      oAbortController.abort();
      console.log(
        'Philanthropy API request cancelled. User navigated away from the page.'
      );
    };
  }, [sLookupId, bIsDonor]);

  return { bIsLoading, oPhilanthropyData };
};

export default usePhilanthropyData;
