import React from 'react';
import PropTypes from 'prop-types';

import ConstituentAvatar from '../../../../ConstituentAvatar';
import TopHats from '../../../../Search/elements/TopHats';
import MyClassmatesClaimButton from './MyClassmatesClaimButton';
import { buildCityStateEmployerString } from '../../../../../utils/common';
import LinkWithSourcePath from '../../../../../utils/LinkWithSourcePath';

const MyClassmatesRow = ({ data }) => (
  <>
    <div className='myClassmates__avatar'>
      <LinkWithSourcePath
        className='myClassmates__name'
        to={`volunteers/profile/${data.LOOKUPID}`}
        referringComponent='MyClassmates'
      >
        <ConstituentAvatar
          lookupId={data.LOOKUPID}
          sFirstName={data.NICKNAME}
          sLastName={data.LASTNAME}
          bHasPhoto={data.HAS_PHOTO}
          sSize='large'
        />
      </LinkWithSourcePath>
    </div>

    <div className='myClassmates__userInfo'>
      <LinkWithSourcePath
        className='myClassmates__name'
        to={`/volunteers/profile/${data.LOOKUPID}`}
        referringComponent='MyClassmates'
      >
        {data.NAME}
      </LinkWithSourcePath>
      <div className='t-paragraph--small'>
        {buildCityStateEmployerString(data)}
      </div>
      <div className='myClassmates__topHats'>
        <TopHats score={Number(data.SCORE_1TO5)} lookupId={data.LOOKUPID} />
      </div>
    </div>
    <div className='myClassmates__claimStatusWrapper'>
      <MyClassmatesClaimButton
        claimInfo={data.REUNIONPROSPECTTEAM}
        classmateId={data.LOOKUPID}
      />
    </div>
  </>
);

MyClassmatesRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MyClassmatesRow;
