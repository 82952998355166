import { useEffect } from 'react';

const aClickableTagNames = ['a', 'input', 'textarea', 'button'];

const useTrapFocusInsideModal = (oModalRef) => {
  useEffect(() => {
    let aAllClickableElements = [];
    aClickableTagNames.forEach((sTag) => {
      const aElements = oModalRef.current.getElementsByTagName(sTag);
      aAllClickableElements = [...aAllClickableElements, ...aElements];
    });

    const oFirstClickableElement = aAllClickableElements[0];
    oFirstClickableElement.focus();
    const oLastClickableElement =
      aAllClickableElements[aAllClickableElements.length - 1];

    const fnTrapFocus = (oEvent) => {
      const bIsTabPressed = oEvent.key === 'Tab' || oEvent.keyCode === 9;

      if (!bIsTabPressed) {
        return;
      }

      if (
        oEvent.shiftKey &&
        document.activeElement === oFirstClickableElement
      ) {
        // User pressed shift + tab while focused on the first clickable element
        // Move the focus to the last clickable element
        oLastClickableElement.focus();
        oEvent.preventDefault();
      } else if (
        !oEvent.shiftKey &&
        document.activeElement === oLastClickableElement
      ) {
        // User pressed tab while focused on the last clickable element
        // Move the focus back to the first clickable element
        oFirstClickableElement.focus();
        oEvent.preventDefault();
      }
    };

    document.addEventListener('keydown', fnTrapFocus);

    return () => {
      document.removeEventListener('keydown', fnTrapFocus);
    };
  }, [oModalRef]);
};

export default useTrapFocusInsideModal;
