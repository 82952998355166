import { createSelector } from 'reselect';

import { groupByPrimary } from '../../../../utils/common';
import { buildAddressValueForReview } from './helpers';

const transformAddressDataForRender = (aRawAddressData) => {
  let aAddresses = [];
  if (aRawAddressData.length > 0) {
    const oGroupedByPrimary = groupByPrimary(aRawAddressData);
    if (oGroupedByPrimary.primary) {
      const oPrimaryAddress = oGroupedByPrimary.primary[0];
      const oFormattedPrimaryAddress = {
        id: oPrimaryAddress.ID,
        value: buildAddressValueForReview(oPrimaryAddress),
      };
      aAddresses.push(oFormattedPrimaryAddress);
    }

    let aSecondaryAddresses = [];
    if (oGroupedByPrimary.secondary) {
      aSecondaryAddresses = oGroupedByPrimary.secondary.map((oAddress) => ({
        id: oAddress.ID,
        value: buildAddressValueForReview(oAddress),
      }));
    }
    aAddresses = [...aAddresses, ...aSecondaryAddresses];
  }
  return aAddresses;
};

const rawAddressSelector = (state) => (state.Profile || {}).ADDRESS || [];

export const addressSelector = createSelector(
  rawAddressSelector,
  transformAddressDataForRender
);

export default addressSelector;
