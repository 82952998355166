import React from 'react';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';

import { OptionsModal } from 'components/Form';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';
import profileActions from './redux/actions';
import { editPhoneNumberSchema } from './schemas';
import { NewPhoneNumberForm } from './NewPhoneNumberForm';
import PhoneRecord from './PhoneRecord';

const PhoneEditMode = ({ aPhones, bIsExpanded, oActions }) => {
  const [oFirstPhone, ...aOtherPhones] = aPhones;
  const oTheActions = oActions || profileActions;
  return (
    <>
      {oFirstPhone && (
        <ProfileRecordLayout
          left={<PhoneRecord data={oFirstPhone} />}
          right={
            <OptionsModal
              data={oFirstPhone}
              actions={oTheActions}
              name='Phone'
              validationSchema={editPhoneNumberSchema}
            />
          }
        />
      )}
      {aOtherPhones.length > 0 && (
        <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
          {aOtherPhones.map((oPhone) => (
            <ProfileRecordLayout
              key={oPhone.ID}
              left={<PhoneRecord data={oPhone} />}
              right={
                <OptionsModal
                  data={oPhone}
                  actions={oTheActions}
                  name='Phone'
                  validationSchema={editPhoneNumberSchema}
                />
              }
            />
          ))}
        </Collapsible>
      )}
      <NewPhoneNumberForm oActions={oTheActions} />
    </>
  );
};

export default PhoneEditMode;

PhoneEditMode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aPhones: PropTypes.arrayOf(PropTypes.object),
  bIsExpanded: PropTypes.bool,
};

PhoneEditMode.defaultProps = {
  aPhones: [],
  bIsExpanded: true,
};
