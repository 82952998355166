import React from 'react';
import PropTypes from 'prop-types';

import fnGetFormattedBirthdate from './fnGetFormattedBirthdate';
import RecordPrivacyIcon from '../../RecordPrivacyIcon';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const BirthdateViewMode = ({ sBirthdate }) => (
  <ProfileRecordLayout
    left={
      <span className='record t-paragraph--medium'>
        {fnGetFormattedBirthdate(sBirthdate)}
        <RecordPrivacyIcon ariaId='birthdate-private-eye' />
      </span>
    }
  />
);

BirthdateViewMode.propTypes = {
  sBirthdate: PropTypes.string,
};

BirthdateViewMode.defaultProps = {
  sBirthdate: '',
};

export default BirthdateViewMode;
