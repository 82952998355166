import React from 'react';
import { useSelector } from 'react-redux';

import ClaimedClassmatesRow from './ClaimedClassmatesRow';

const ClaimedClassmatesList = () => {
  const aClaimedClassmates = useSelector(
    (state) => state.Volunteers?.claimedClassmates || []
  );

  return aClaimedClassmates.length > 0 ? (
    <ul className='myClaimedClassmates__list'>
      {aClaimedClassmates.map((oClaimedClassmate) => (
        <li
          className='myClaimedClassmates__listItem'
          key={oClaimedClassmate.MEMBERID}
        >
          <ClaimedClassmatesRow data={oClaimedClassmate} />
        </li>
      ))}
    </ul>
  ) : (
    <p className='claimedClassmates__none'>
      You have not claimed any classmates yet.
    </p>
  );
};

export default ClaimedClassmatesList;
