import React from 'react';
import PropTypes from 'prop-types';

import Pledge from './Pledge';
import { pledgePropType } from './helpers';

// Displays list of pledges.
const PledgeList = ({ aPledges, className }) => (
  <ul className={`pledgeList ${className}`}>
    {aPledges.map((oPledge) => (
      <li className='pledge' key={oPledge.ID}>
        <Pledge oPledgeData={oPledge} />
      </li>
    ))}
  </ul>
);

PledgeList.propTypes = {
  aPledges: PropTypes.arrayOf(pledgePropType).isRequired,
  className: PropTypes.string,
};

PledgeList.defaultProps = {
  className: '',
};

export default PledgeList;
