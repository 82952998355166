import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fnChildrenPropType } from 'utils/customPropTypes';
import oIconLibrary, {
  aAllIconNames,
  aIconTypes,
} from '../../utils/iconLibrary';

/**
 * Displays a button with text and an icon from FontAwesome.
 *
 * @param {function} fnHandleClick - Click handler.
 * @param {boolean} bIsDisabled - If the button is disabled or not.
 * @param {string} sIcon - FontAwesome icon name.
 * @param {string} sIconPosition - Icon can appear to the left or right of button text.
 * @param {string} sIconType - Determines if the icon is from FontAwesome or a custom icon.
 */
const ButtonWithIcon = ({
  fnHandleClick,
  children,
  bIsDisabled,
  sIcon,
  sIconPosition,
  className,
  sIconType,
  bIsLoading,
  sCypressId,
}) => {
  const iconMarkup = (
    <span className='button--iconOnly--secondary'>
      {sIconType === 'fontAwesome' ? (
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome[sIcon]} />
      ) : (
        <img src={oIconLibrary.wfu[sIcon]} alt='' />
      )}
    </span>
  );
  return (
    <button
      type='button'
      className={`${className} button--withIcon`}
      onClick={fnHandleClick}
      disabled={bIsDisabled || bIsLoading}
      data-cy={sCypressId}
    >
      {bIsLoading ? (
        <>
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome.spinner} spin />
          <span className='hide_accessibly'>Loading</span>
        </>
      ) : (
        <>
          {sIconPosition === 'left' && iconMarkup}
          {children}
          {sIconPosition === 'right' && iconMarkup}
        </>
      )}
    </button>
  );
};

ButtonWithIcon.propTypes = {
  fnHandleClick: PropTypes.func.isRequired,
  bIsDisabled: PropTypes.bool,
  sIcon: PropTypes.oneOf(aAllIconNames).isRequired,
  sIconPosition: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  sIconType: PropTypes.oneOf(aIconTypes),
  bIsLoading: PropTypes.bool,
  children: fnChildrenPropType.isRequired,
  sCypressId: PropTypes.string,
};

ButtonWithIcon.defaultProps = {
  bIsDisabled: false,
  sIconPosition: 'left',
  className: '',
  sIconType: 'fontAwesome',
  bIsLoading: false,
  sCypressId: '',
};

export default ButtonWithIcon;
