import React from 'react';

import { fnChildrenPropType } from 'utils/customPropTypes';

/**
 * Error boundary to catch and log JavaScript errors.
 *
 * See https://reactjs.org/docs/error-boundaries.html for details.
 */
class BaseErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { bHasError: false };
  }

  static getDerivedStateFromError(oError) {
    // Update state so the next render will show the fallback UI.
    return { bHasError: true };
  }

  componentDidCatch(oError, oErrorInfo) {
    // @todo - Log the error to CloudWatch
    console.log(oError, oErrorInfo);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  resetError = () => {
    this.setState({ bHasError: false });
  };

  // This method gets overridden with different UI
  // depending on if one section or the whole page crashed.
  render() {
    const { bHasError } = this.state;

    if (bHasError) {
      return <h1>Something went wrong.</h1>;
    }

    const { children } = this.props;

    return children;
  }
}

BaseErrorBoundary.propTypes = {
  children: fnChildrenPropType.isRequired,
};

export default BaseErrorBoundary;
