import { createActions } from 'redux-actions';

export const { update, reset, cart } = createActions(
  {
    UPDATE: (action) => action,
    RESET: (action) => action,
    CART: {
      ADD_ITEM: (action) => action,
      UPDATE_ITEM: (action) => action,
      REMOVE_ITEM: (action) => action,
    },
  },
  {
    prefix: 'Checkout',
  }
);
