import React from 'react';
import PropTypes from 'prop-types';

import { oCurrencyFormatter } from 'utils/common';

const FundChartTooltip = ({ active, payload, label }) => {
  const oDataPoint = payload?.[0]?.payload;
  if (!active || !oDataPoint) {
    return null;
  }

  const [sMonth, sYear] = label.split(' ');
  let sFormattedLabel = '';
  switch (sMonth) {
    case 'Jun':
      sFormattedLabel = `${sMonth} 30, ${sYear} (audited)`;
      break;
    case 'Jul':
      sFormattedLabel = `${sMonth} 1, ${sYear} (audited)`;
      break;
    default:
      sFormattedLabel = `${sMonth} 1, ${sYear} (unaudited)`;
  }

  return (
    active &&
    oDataPoint && (
      <div className='fundChartTooltip'>
        <div className='fundChartTooltip__title t-paragraph--small'>
          {sFormattedLabel}
        </div>
        <div className='fundChartTooltip__item t-paragraph--small'>
          Fund Value:&nbsp;&nbsp;
          <span>{oCurrencyFormatter.format(oDataPoint.fundValue)}</span>
        </div>
      </div>
    )
  );
};

FundChartTooltip.propTypes = {
  active: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  payload: PropTypes.array,
  label: PropTypes.string,
};

FundChartTooltip.defaultProps = {
  active: false,
  payload: [],
  label: '',
};

export default FundChartTooltip;
