import React from 'react';

import { SkeletonText } from 'components/LoadingZone/Skeleton';

const NavBarSkeleton = () => (
  <div className='navBarSkeleton'>
    <SkeletonText size='large' width='25%' />
    <SkeletonText size='large' width='25%' />
    <SkeletonText
      size='large'
      width='5%'
      className='navBarSkeleton__notifications'
    />
  </div>
);

export default NavBarSkeleton;
