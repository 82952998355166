import React, { useState } from 'react';
import * as R from 'ramda';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import ConfirmRsvpNoModal from 'components/EventDetailsPage/ConfirmRsvpNoModal';

const RsvpButtons = ({ oSubEvent, fnSetHasRsvpdNo }) => {
  const [bShowRsvpNoConfirmModal, fnSetShowRsvpNoConfirmModal] =
    useState(false);
  const formikProps = useFormikContext();
  const sFieldValue = JSON.stringify(R.omit(['ATTENDEES'], oSubEvent));
  const bIsSubEventSelected =
    formikProps.values?.subEvents?.includes(sFieldValue);
  return (
    <div className='subEventDetailCard__rsvpWrapper'>
      <Button
        sFlavor='tertiary'
        className='subEventDetailCard__rsvpButton'
        fnHandleClick={() => {
          fnSetShowRsvpNoConfirmModal(true);
          const aCopySubEvents = formikProps.values.subEvents.filter(
            (sSubEvent) => sSubEvent !== sFieldValue
          );
          formikProps.setFieldValue('subEvents', aCopySubEvents);
        }}
        sCypressId='rsvp-no-button'
      >
        RSVP No
      </Button>
      <Button
        sFlavor={bIsSubEventSelected ? 'primary' : 'tertiary'}
        className='subEventDetailCard__rsvpButton'
        fnHandleClick={() => {
          if (!bIsSubEventSelected) {
            const aCopySubEvents = [
              ...formikProps.values.subEvents,
              sFieldValue,
            ];
            formikProps.setFieldValue('subEvents', aCopySubEvents);
          }
        }}
        sCypressId='rsvp-yes-button'
      >
        RSVP Yes
      </Button>
      {bShowRsvpNoConfirmModal && (
        <ConfirmRsvpNoModal
          sEventLookupId={oSubEvent.LOOKUPID}
          sEventTitle={oSubEvent.TITLE}
          fnSetShowRsvpNoConfirmModal={fnSetShowRsvpNoConfirmModal}
          fnSetHasRsvpdNo={fnSetHasRsvpdNo}
          oEventContact={oSubEvent.CONTACT}
        />
      )}
    </div>
  );
};

RsvpButtons.propTypes = {
  oSubEvent: PropTypes.shape({
    ID: PropTypes.string.isRequired,
    TITLE: PropTypes.string.isRequired,
    INVITATION: PropTypes.shape({
      INVITEE_ID: PropTypes.string,
    }),
    LOOKUPID: PropTypes.string.isRequired,
    CONTACT: PropTypes.shape({
      NAME: PropTypes.string,
      EMAIL: PropTypes.string,
    }),
  }).isRequired,
  fnSetHasRsvpdNo: PropTypes.func.isRequired,
};

export default RsvpButtons;
