import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { Select, TextArea } from 'components/Form/Fields';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import EventRegistrantName from '../EventRegistrantName';
import { fnWeightOtherDietaryRestrictionCodeLast } from './selectors';
import {
  fnDebouncedUpdateGuestAdditionalInfoContent,
  fnUpdateGuestDietaryRestrictions,
  fnDebouncedUpdateGuestOtherDietaryRestriction,
} from '../redux/guestHelpers';

const AdditionalInfoFormBody = () => {
  const oFormikProps = useFormikContext();
  const aDietaryRestrictionCodes = useCodeTablesSection(
    'DIETARY_RESTRICTION',
    fnWeightOtherDietaryRestrictionCodeLast
  );
  return (
    <FieldArray name='additionalInfoByGuest'>
      <>
        {oFormikProps.values.additionalInfoByGuest.map(
          ({ sGuestLookupId, sGuestName, dietaryRestrictions }, iIndex) => {
            const bHasChosenOtherDietaryRestriction = dietaryRestrictions?.find(
              (oCode) => oCode?.DESCRIPTION === 'Other'
            );
            return (
              <fieldset
                className='additionalInfoForm__fieldset'
                key={sGuestLookupId}
                data-cy={`additional-info-${sGuestLookupId}-fieldset`}
              >
                <legend>
                  <EventRegistrantName sName={sGuestName} />
                </legend>
                <Select
                  name={`additionalInfoByGuest.${iIndex}.dietaryRestrictions`}
                  id={`additionalInfoByGuest-${sGuestLookupId}-dietaryRestrictions`}
                  label='Dietary Restrictions'
                  options={aDietaryRestrictionCodes}
                  bIsMultiSelect
                  sCypressId='additional-info-dietary-restrictions-select'
                  onChange={(aSelectedCodes) => {
                    fnUpdateGuestDietaryRestrictions(
                      sGuestLookupId,
                      aSelectedCodes
                    );
                  }}
                />
                {bHasChosenOtherDietaryRestriction && (
                  <TextArea
                    sName={`additionalInfoByGuest.${iIndex}.otherDietaryRestriction`}
                    sId={`additionalInfoByGuest-${sGuestLookupId}-otherDietaryRestriction`}
                    sLabel='Other Dietary Restriction'
                    sCypressId='additional-info-other-dietary-restrictions-textarea'
                    fnHandleChange={(sNewValue) => {
                      fnDebouncedUpdateGuestOtherDietaryRestriction(
                        sGuestLookupId,
                        sNewValue
                      );
                    }}
                  />
                )}
                <TextArea
                  sName={`additionalInfoByGuest.${iIndex}.additionalInfo`}
                  sId={`additionalInfoByGuest-${sGuestLookupId}-additionalInfo`}
                  sLabel='Additional Information'
                  sPlaceholder={`Is there any additional information about ${sGuestName} that you would like to share with us?`}
                  sCypressId='additional-info-additional-info-textarea'
                  fnHandleChange={(sNewValue) => {
                    fnDebouncedUpdateGuestAdditionalInfoContent(
                      sGuestLookupId,
                      sNewValue
                    );
                  }}
                />
              </fieldset>
            );
          }
        )}
      </>
    </FieldArray>
  );
};

export default AdditionalInfoFormBody;
