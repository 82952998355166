import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';

import useWindowSize from 'utils/hooks/useWindowSize';
import oIconLibrary from 'utils/iconLibrary';
import Button from 'components/Buttons/Button';
import IdentityProviderAccountManagement from './IdentityProviderAccountManagement';

const AuthLayoutFooter = ({ sMatchType, continueButton }) => {
  const oLocation = useLocation();
  const fnNavigate = useNavigate();
  const oWindowSize = useWindowSize();
  // Don't show the help button on the help page
  const bShouldShowHelpBtn = !oLocation.pathname.includes('help');
  // Don't show the back button on the magic-link fail page (a success automatically redirects to the terms page)
  const bShouldShowBackBtn = sMatchType !== 'magic-link';
  const fnHandleBackBtnClick = () => {
    if (oLocation.pathname === '/sign-up' || sMatchType === 'magic-link') {
      Auth.signOut({ global: true });
    } else {
      fnNavigate(-1);
    }
  };

  return (
    <div className='authLayout__footer'>
      <div className='authLayout__footerButtonContainer'>
        {continueButton}
        {bShouldShowBackBtn && (
          <Button
            sFlavor='secondary'
            className='authLayout__backBtn'
            fnHandleClick={() => fnHandleBackBtnClick()}
          >
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome.arrowLeft} /> Back
          </Button>
        )}
        {bShouldShowHelpBtn && (
          <Link
            to={`/sign-up/help${sMatchType ? `/${sMatchType}` : ''}`}
            className='button--iconOnly--tertiary authLayout__helpBtn'
          >
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome.help} />
            <span className='hide_accessibly'>Help</span>
          </Link>
        )}
      </div>
      {oWindowSize.width < 1024 && <IdentityProviderAccountManagement />}
    </div>
  );
};

AuthLayoutFooter.propTypes = {
  sMatchType: PropTypes.string,
  continueButton: PropTypes.element,
};

AuthLayoutFooter.defaultProps = {
  sMatchType: '',
  continueButton: null,
};

export default AuthLayoutFooter;
