import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const ReligionViewMode = ({ oReligionData }) => (
  <ProfileRecordLayout
    left={
      <>
        <span className='record t-paragraph--medium'>
          {oReligionData.DESCRIPTION}
        </span>
        <RecordPrivacyIcon ariaId={`private-eye-${oReligionData.ID}`} />
      </>
    }
  />
);

ReligionViewMode.propTypes = {
  oReligionData: PropTypes.shape({
    ID: PropTypes.string,
    DESCRIPTION: PropTypes.string,
  }),
};

ReligionViewMode.defaultProps = {
  oReligionData: {},
};

export default ReligionViewMode;
