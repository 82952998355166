import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DateAndTime from 'date-and-time';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/Buttons/Button';
import Modal from 'components/Layout/Modal';
import {
  selectAllEventsInCart,
  selectEventInCartBySlug,
} from '../redux/selectors';
import {
  fnRemoveEventFromCart,
  fnUpdateEventCapacityInCart,
  fnUpdateEventRegistrationTypeToWaitlist,
} from '../redux/eventHelpers';
import EventRegistrationContext from '../EventRegistrationContext';
import { fnRemoveGuestFromEventInCart } from '../redux/guestHelpers';

const EventRegistrationAlert = ({
  oEventRegistrationStatus,
  fnSetNonRegistrableEventStatuses,
}) => {
  const fnNavigate = useNavigate();
  const { fnResetCurrentFormMeta } = useContext(EventRegistrationContext);
  const {
    EVENT_CAPACITY_REMAINING,
    IS_EVENT_CANCELLED,
    IS_EVENT_AT_CAPACITY,
    IS_EVENT_REGISTRATION_CLOSED,
    ALREADY_REGISTERED_GUESTS = [],
    EVENT_SLUG,
  } = oEventRegistrationStatus;
  const oEventInCart = useSelector(selectEventInCartBySlug(EVENT_SLUG));
  const bIsWaitlistEvent = [true, 1].includes(
    oEventInCart.oDetails.oEvent.REGISTRATION?.ALLOW_WAITLIST
  );
  const bIsAlreadyWaitlistEvent = oEventInCart.sType === 'event-waitlist';
  const iNumEventsInCart = useSelector(selectAllEventsInCart).length;
  let sRegistrationCloseDate = '';
  if (oEventInCart.oDetails.oEvent.REGISTRATION.CLOSE_DATETIME) {
    const oRegistrationCloseDate = DateAndTime.parse(
      oEventInCart.oDetails.oEvent.REGISTRATION.CLOSE_DATETIME,
      'YYYY-MM-DDTHH:mm:ss'
    );
    sRegistrationCloseDate = DateAndTime.format(
      oRegistrationCloseDate,
      'h:mm A [on] MMM DD, YYYY'
    );
  }
  const iGuestCount = oEventInCart.oDetails.aGuests.length;
  const sEventTitle = oEventInCart.oDetails.oEvent.TITLE;

  const fnClearEventStatusError = (sEventSlug) => {
    fnSetNonRegistrableEventStatuses((aPreviousValue) =>
      aPreviousValue.filter((oStatus) => oStatus.EVENT_SLUG !== sEventSlug)
    );
  };

  const fnHandleRemoveEvent = () => {
    fnClearEventStatusError(EVENT_SLUG);
    fnRemoveEventFromCart(oEventInCart.sId);
  };
  const fnHandleManageWaitlist = () => {
    fnUpdateEventRegistrationTypeToWaitlist(
      oEventInCart.oDetails.oEvent.LOOKUPID
    );
    fnNavigate(`/events/${EVENT_SLUG}/register/waitlist`);
  };

  const fnHandleManageGuests = () => {
    fnUpdateEventCapacityInCart(
      oEventInCart.oDetails.oEvent.LOOKUPID,
      EVENT_CAPACITY_REMAINING
    );
    fnClearEventStatusError(EVENT_SLUG);
    fnResetCurrentFormMeta();
    fnNavigate(`/events/${EVENT_SLUG}/register/guests`);
  };

  const fnCloseModal = () => {
    fnClearEventStatusError(EVENT_SLUG);
  };

  const fnRemoveAlreadyRegisteredGuests = () => {
    ALREADY_REGISTERED_GUESTS.forEach((oGuest) => {
      fnRemoveGuestFromEventInCart(
        oGuest.LOOKUPID,
        oEventInCart.oDetails.oEvent.LOOKUPID
      );
    });
    fnClearEventStatusError(EVENT_SLUG);
  };

  switch (true) {
    // User is trying to register more guests than the event has capacity for
    case EVENT_CAPACITY_REMAINING > 0 && // 0 capacity means unlimited
      iGuestCount > EVENT_CAPACITY_REMAINING:
      return (
        <Modal
          sModalTitle='Event Nearing Capacity'
          sModalSubtitle={`Only ${EVENT_CAPACITY_REMAINING} spot(s) remaining for ${sEventTitle}`}
          sModalDetail='You can remove some guests or remove the event from your cart.'
          sFlavor='alert'
          fnSetShowModal={fnCloseModal}
          sCypressId='low-capacity-modal'
        >
          <div className='eventRegistrationAlert__buttonWrapper'>
            <Button fnHandleClick={fnHandleManageGuests}>Manage guests</Button>
            <Button fnHandleClick={fnHandleRemoveEvent} sFlavor='secondary'>
              Remove event
            </Button>
          </div>
        </Modal>
      );
    case IS_EVENT_CANCELLED:
      return (
        <Modal
          sModalTitle='Event Cancelled'
          sModalDetail={`Unfortunately, ${sEventTitle} has been cancelled.`}
          sFlavor='alert'
          fnSetShowModal={fnCloseModal}
          sCypressId='event-cancelled-modal'
        >
          {iNumEventsInCart > 1 ? (
            <Button fnHandleClick={fnHandleRemoveEvent}>Remove event</Button>
          ) : (
            <Link to='/main' className='button--primary'>
              Return to home page
            </Link>
          )}
        </Modal>
      );
    case IS_EVENT_AT_CAPACITY:
      return (
        !bIsAlreadyWaitlistEvent && (
          <Modal
            sModalTitle='Event at Capacity'
            sModalDetail={
              bIsWaitlistEvent
                ? `Unfortunately, ${sEventTitle} is at capacity. Would you like to join the waitlist?`
                : `Unfortunately, ${sEventTitle} is at capacity.`
            }
            sFlavor='alert'
            fnSetShowModal={fnCloseModal}
            sCypressId='at-capacity-modal'
          >
            {iNumEventsInCart > 1 ? (
              <div className='eventRegistrationAlert__buttonWrapper'>
                <Button
                  fnHandleClick={fnHandleRemoveEvent}
                  sFlavor='secondary'
                  sCypressId='remove-event-button'
                >
                  Remove event
                </Button>
                {bIsWaitlistEvent && (
                  <Button
                    fnHandleClick={fnHandleManageWaitlist}
                    sCypressId='join-waitlist-button'
                  >
                    Join Waitlist
                  </Button>
                )}
              </div>
            ) : (
              <div className='eventRegistrationAlert__buttonWrapper'>
                <Link to='/main' className='button--primary'>
                  Return to home page
                </Link>
                {bIsWaitlistEvent && (
                  <Button
                    fnHandleClick={fnHandleManageWaitlist}
                    sCypressId='join-waitlist-button'
                  >
                    Join Waitlist
                  </Button>
                )}
              </div>
            )}
          </Modal>
        )
      );
    case IS_EVENT_REGISTRATION_CLOSED:
      return (
        <Modal
          sModalTitle='Event Registration Closed'
          sModalDetail={`Registration for ${sEventTitle} closed at ${sRegistrationCloseDate}.`}
          sFlavor='alert'
          fnSetShowModal={fnCloseModal}
        >
          {iNumEventsInCart > 1 ? (
            <Button fnHandleClick={fnHandleRemoveEvent}>Remove event</Button>
          ) : (
            <Link to='/main' className='button--primary'>
              Return to home page
            </Link>
          )}
        </Modal>
      );
    case ALREADY_REGISTERED_GUESTS.length > 0:
      return (
        <Modal
          sModalTitle='Already Registered for this Event'
          sModalSubtitle={`The following guests are already registered for ${sEventTitle}.`}
          sModalDetail='Please remove them to continue.'
          sFlavor='alert'
          fnSetShowModal={fnCloseModal}
        >
          <ul className='eventRegistrationAlert__alreadyRegisteredList'>
            {ALREADY_REGISTERED_GUESTS.map((oGuest) => (
              <li
                key={oGuest.LOOKUPID}
                className='eventRegistrationAlert__alreadyRegisteredListItem'
              >
                {oGuest.NAME}
              </li>
            ))}
          </ul>
          <div className='eventRegistrationAlert__buttonWrapper'>
            <Button fnHandleClick={fnHandleManageGuests} sFlavor='secondary'>
              Manage guests
            </Button>
            <Button fnHandleClick={fnRemoveAlreadyRegisteredGuests}>
              Remove these guests
            </Button>
          </div>
        </Modal>
      );
    default:
      return null;
  }
};

EventRegistrationAlert.propTypes = {
  oEventRegistrationStatus: PropTypes.shape({
    IS_EVENT_REGISTRABLE: PropTypes.bool.isRequired,
    EVENT_CAPACITY_REMAINING: PropTypes.number.isRequired,
    IS_EVENT_CANCELLED: PropTypes.bool.isRequired,
    IS_EVENT_AT_CAPACITY: PropTypes.bool.isRequired,
    IS_EVENT_REGISTRATION_CLOSED: PropTypes.bool.isRequired,
    ALREADY_REGISTERED_GUESTS: PropTypes.arrayOf(
      PropTypes.shape({
        LOOKUPID: PropTypes.string.isRequired,
      })
    ),
    EVENT_SLUG: PropTypes.string.isRequired,
  }).isRequired,
  fnSetNonRegistrableEventStatuses: PropTypes.func.isRequired,
};

export default EventRegistrationAlert;
