import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

/**
 * A Formik-compatible text field that auto-formats dollar amounts (ex: $1,500.75).
 */
export const DollarAmountTextField = ({
  sId,
  sLabel,
  sName,
  sClassName,
  bIsLabelHidden,
}) => {
  const [field, meta] = useField(sName);
  const bShowErrors = meta.touched && meta.error;
  const sBoxClass = bShowErrors ? 'boxField boxField--error' : 'boxField';
  return (
    <div className={`${sClassName} ${sBoxClass}`}>
      <span className='dollarAmountTextField__dollarSign t-paragraph'>$</span>
      <NumericFormat
        {...field}
        id={sId}
        type='text'
        name={sName}
        className={`formInput--text ${sClassName}`}
        thousandSeparator
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
        defaultValue={0}
        placeholder='0.00'
      />
      <label htmlFor={sId} className={bIsLabelHidden ? 'hide_accessibly' : ''}>
        {sLabel}
      </label>
      {bShowErrors && <p className='field__errorMessage'>{meta.error}</p>}
    </div>
  );
};

DollarAmountTextField.defaultProps = {
  sClassName: '',
  bIsLabelHidden: false,
};

DollarAmountTextField.propTypes = {
  sId: PropTypes.string.isRequired,
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  bIsLabelHidden: PropTypes.bool,
  sClassName: PropTypes.string,
};

export default DollarAmountTextField;
