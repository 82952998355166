const stage = process.env.REACT_APP_STAGE;

export const getWakeNetworkHomeUrl = () => {
  if (window.location.hostname.includes('localhost')) {
    return 'http://localhost:3000/';
  }
  switch (stage) {
    case 'uat':
      return 'https://wakenetwork.uat.wfu.edu/';
    case 'prod':
      return 'https://wakenetwork.wfu.edu/';
    default:
      return 'https://wakenetwork.dev.wfu.edu/';
  }
};

const sRedirectUrl = getWakeNetworkHomeUrl();
export default {
  apiGateway: {
    NAME: stage,
    REGION: 'us-east-1',
    URL: `https://jtazincj81.execute-api.us-east-1.amazonaws.com/${stage}`,
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_qwDqFaPhZ',
    IDENTITY_POOL_ID: 'us-east-1:977cf9c7-d236-44e2-9a79-a776ab03707e',
    APP_CLIENT_ID: '3de7sl8gskrjievcsf5cj0oihi',
    OAUTH: {
      domain: 'wfu-cer-wakenetwork.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // These redirect URLs must match Cognito's `Allowed callback URLs` and `Allowed sign-out URLs` to work,
      // which live in the AWS Console at WN User Pool > App Integration > WN App Client > Hosted UI
      redirectSignIn: `${sRedirectUrl}?redirect=/main`,
      redirectSignOut: `${sRedirectUrl}sign-in`,
      responseType: 'code',
      options: { AdvancedSecurityDataCollectionFlag: false },
    },
  },
};
