import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'components/Form/Fields';
import NewRecordForm from 'components/Form/NewRecordForm';
import { fnElementRefPropType } from 'utils/customPropTypes';
import { NameOptions } from './NameOptions';
import { nameSchema, nameSchemaWithPrivacy } from './schemas';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const FormBody = ({ bIsPrivacyChangeable, firstFieldRef, ...formikProps }) => {
  const { values } = formikProps;

  return (
    <ProfileRecordLayout
      left={
        <>
          <TextField
            sId='first-name'
            sLabel='First Name'
            sName='FIRSTNAME'
            oFieldRef={firstFieldRef}
          />
          <TextField
            sId='nick-name'
            sLabel='Preferred First Name'
            sName='NICKNAME'
          />
          <TextField
            sId='middle-name'
            sLabel='Middle Name'
            sName='MIDDLENAME'
          />
          <TextField sId='last-name' sLabel='Last Name' sName='LASTNAME' />
        </>
      }
      right={
        <NameOptions
          data={values}
          forceExpanded
          bIsPrivacyChangeable={bIsPrivacyChangeable}
          formikProps={formikProps}
        />
      }
    />
  );
};

FormBody.propTypes = {
  firstFieldRef: fnElementRefPropType.isRequired,
  bIsPrivacyChangeable: PropTypes.bool.isRequired,
};

export const NewNameForm = ({
  activateFormLabel,
  actions,
  onShow,
  onHide,
  name,
  initialValues,
  bIsPrivacyChangeable,
}) => (
  <NewRecordForm
    initialValues={initialValues}
    formId={`new_${name}_form${(initialValues || {}).LOOKUPID || ''}`}
    addItemLabel={activateFormLabel}
    submitActionName={(initialValues || {}).LOOKUPID ? 'update' : 'create'}
    validationSchema={name === 'spouse' ? nameSchemaWithPrivacy : nameSchema}
    validateOnBlur={false}
    validateOnChange={false}
    actions={actions}
    FormBody={FormBody}
    formBodyProps={{ bIsPrivacyChangeable }}
    onShow={onShow}
    onHide={onHide}
  />
);

NewNameForm.defaultProps = {
  onShow: () => {},
  onHide: () => {},
  name: 'Name',
  activateFormLabel: 'Edit Name',
  initialValues: {
    FIRSTNAME: '',
    LASTNAME: '',
    MIDDLENAME: '',
    NICKNAME: '',
    ATTENDNAME: undefined,
    TITLE: undefined,
    PROFESSIONALSUFFIX: undefined,
    PERSONALSUFFIX: undefined,
  },
  bIsPrivacyChangeable: true,
};

NewNameForm.propTypes = {
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  name: PropTypes.string,
  activateFormLabel: PropTypes.string,
  initialValues: PropTypes.shape({
    FIRSTNAME: PropTypes.string,
    LASTNAME: PropTypes.string,
    MIDDLENAME: PropTypes.string,
    NICKNAME: PropTypes.string,
    ATTENDNAME: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    TITLE: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
    }),
    PROFESSIONALSUFFIX: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
    }),
    PERSONALSUFFIX: PropTypes.shape({
      ID: PropTypes.string,
      DESCRIPTION: PropTypes.string,
    }),
  }),
  bIsPrivacyChangeable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object.isRequired,
};

export default NewNameForm;
