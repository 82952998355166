import React from 'react';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';

const ConstituentSearchInput = ({ sQuery, fnSetQuery, sLabel }) => (
  <div className='boxField constituentSearchInput__wrapper'>
    <input
      id='constituent-search-input'
      type='text'
      name='constituentSearch'
      onChange={(event) => fnSetQuery(event.target.value)}
      autoComplete='off'
      value={sQuery}
      placeholder=' '
      data-cy='constituent-search-input'
    />
    <label htmlFor='constituent-search-input'>{sLabel}</label>
    {sQuery.length > 0 && (
      <IconOnlyButton
        sIcon='close'
        sAriaLabel='Clear'
        fnHandleClick={() => fnSetQuery('')}
        sFlavor='tertiary'
        className='constituentSearchInput__clearButton'
      />
    )}
  </div>
);

ConstituentSearchInput.propTypes = {
  sQuery: PropTypes.string.isRequired,
  fnSetQuery: PropTypes.func.isRequired,
  sLabel: PropTypes.string.isRequired,
};

export default ConstituentSearchInput;
