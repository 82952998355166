import { object, boolean } from 'yup';

const termsSchema = object({
  terms: boolean().oneOf(
    [true],
    'You must accept the terms of service to continue'
  ),
});

export default termsSchema;
