import { createSelector } from 'reselect';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { fnFuzzySearch } from 'utils/common';
import { fnSelectMyNetworkConnections } from '../helpers';

export const oInitialValues = { search: '', filter: null };

const fnSortByRelationshipDescription = R.sortBy(R.prop('DESCRIPTION'));

/**
 * Filters out any relationship types that are not found
 * within the My Network connections list.
 *
 * @param {array} aRelationshipTypes - All relationship types from Code Tables.
 * @param {array} aMyNetworkConnections - All My Network connections.
 * @returns {array}
 */
export const fnFilterUnusedRelationshipTypes = (aMyNetworkConnections) => {
  let aResults = [];
  if (aMyNetworkConnections.length > 0) {
    const uniqueRelationshipTypesMap = new Map();
    aMyNetworkConnections.forEach((oConnection) => {
      const sRelationshipType = oConnection.RELATIONSHIP.TYPE.DESCRIPTION;
      uniqueRelationshipTypesMap.set(
        sRelationshipType,
        oConnection.RELATIONSHIP.TYPE
      );
    });

    const aUniqueRelationshipTypes = Array.from(
      uniqueRelationshipTypesMap.values()
    );
    aResults = fnSortByRelationshipDescription(aUniqueRelationshipTypes);
  }
  return aResults;
};

// Selects only the relationship types that are currently found in the My Network connections list.
export const fnSelectFilterableRelationshipTypes = createSelector(
  fnSelectMyNetworkConnections,
  fnFilterUnusedRelationshipTypes
);

/**
 * Filters and searches "My Network" connections based on user input.
 *
 * @param {object} oControls - Contains search query and/or filter. { search: 'string', filter: {...} }
 * @param {array} aMyNetworkConnections - List of all My Network connections.
 * @param {function} fnHandleResults - Callback function.
 */
const fnHandleFilterAndSearch = (
  oControls,
  aMyNetworkConnections,
  fnHandleResults
) => {
  let aResults = aMyNetworkConnections;

  if (oControls.filter) {
    aResults = aResults.filter(
      (oConnection) =>
        oConnection.RELATIONSHIPTYPE === oControls.filter.DESCRIPTION
    );
  }

  if (oControls.search) {
    const aFuzzyResults = fnFuzzySearch(aResults, ['NAME'], oControls.search);
    aResults = aFuzzyResults.map((oResult) => oResult.obj);
  }

  fnHandleResults(aResults);
};

export const fnDebouncedFilterAndSearch = debounce(
  (oControls, aMyNetworkConnections, fnHandleResults) => {
    fnHandleFilterAndSearch(oControls, aMyNetworkConnections, fnHandleResults);
  },
  500
);
