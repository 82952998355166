import { object, string } from 'yup';
import DateAndTime from 'date-and-time';

const oToday = new Date();

const birthdateSchema = object({
  BIRTHDATE: string()
    .test('is-valid-birthdate', (sBirthdate, oTestContext) => {
      const bIsValidFormat = DateAndTime.isValid(sBirthdate, 'MM/DD/YYYY');
      if (!bIsValidFormat) {
        return oTestContext.createError({
          message: 'Please enter your birthdate in the format: MM/DD/YYYY.',
        });
      }

      const oBirthdate = new Date(sBirthdate);
      const bIsValidDate = oBirthdate < oToday;
      if (!bIsValidDate) {
        return oTestContext.createError({
          message:
            'Please enter a birthdate before today using the format: MM/DD/YYYY.',
        });
      }

      return bIsValidFormat && bIsValidDate;
    })
    .required('Please enter your birthdate in the format: MM/DD/YYYY.'),
});

export default birthdateSchema;
