import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import PencilEditButton from 'components/Layout/PencilEditButton';
import SelectContactForm from 'components/Checkout/CheckoutReview/SelectContactForm';
import { NewEmailForm } from 'components/MyProfile/elements/Personal/Email/NewEmailForm';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import useProfileData from 'utils/hooks/useProfileData';
import LoadingZone from 'components/LoadingZone';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { selectEmailOptionsForCheckout } from './selectors';
import CheckoutContactInfoSkeleton from '../CheckoutContactInfoSkeleton';

const CheckoutEmails = ({ oErrors, setErrors }) => {
  const [bIsEditingEmail, setIsEditingEmail] = useState(false);
  const submitEmailFormikFormRef = useRef();
  const aEmails = useSelector(selectEmailOptionsForCheckout);
  const oContainerRef = useRef();
  const bIsProfileLoading = useProfileData();
  const sEmail = useSelector((state) => state.Checkout.email);
  const dispatch = useDispatch();

  const fnUpdateEmail = useCallback(
    (sNewEmail) => {
      dispatch(
        CheckoutActions.update({
          email: sNewEmail,
        })
      );
      setErrors((oPreviousState) => ({
        ...oPreviousState,
        email: '',
      }));
    },
    [dispatch, setErrors]
  );

  // Set initial email value
  useEffect(() => {
    if (!sEmail && aEmails.length > 0) {
      fnUpdateEmail(aEmails[0].value);
    }
  }, [sEmail, aEmails, fnUpdateEmail]);

  const fnToggleEmailEditing = () => {
    if (bIsEditingEmail && submitEmailFormikFormRef.current) {
      submitEmailFormikFormRef.current();
    }
    setIsEditingEmail(!bIsEditingEmail);
  };

  useCloseOnOutsideClick(oContainerRef, fnToggleEmailEditing, bIsEditingEmail);

  return (
    <>
      <div
        className='checkoutReview__contact checkout__sectionBox'
        ref={oContainerRef}
      >
        <LoadingZone
          isLoading={bIsProfileLoading}
          skeleton={<CheckoutContactInfoSkeleton />}
        >
          <p className='checkoutReview__contactInfoLabel t-paragraph--medium'>
            Email
          </p>
          {!bIsEditingEmail && (
            <p className='checkoutReview__contactInfo'>
              {sEmail || 'Please add an email address.'}
            </p>
          )}
          <PencilEditButton
            sAriaLabel='Toggle editing email address'
            className='checkoutReview__contactEditButton'
            fnHandleClick={fnToggleEmailEditing}
            bIsEditing={bIsEditingEmail}
          />
          {bIsEditingEmail && (
            <>
              <SelectContactForm
                aContactData={aEmails}
                sSelectionLabel='email'
                oInitialValues={{ email: sEmail }}
                submitFormikFormRef={submitEmailFormikFormRef}
                handleSubmit={(values) => {
                  fnUpdateEmail(values.email);
                }}
              />
              <NewEmailForm
                handleSubmitSideEffects={(oFormData) => {
                  fnUpdateEmail(oFormData.EMAILADDRESS);
                }}
              />
            </>
          )}
        </LoadingZone>
      </div>
      {oErrors.email && <div className='error'>{oErrors.email}</div>}
    </>
  );
};

CheckoutEmails.propTypes = {
  oErrors: PropTypes.shape({
    email: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default CheckoutEmails;
