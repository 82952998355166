import React from 'react';
import { useSelector } from 'react-redux';

import PageLayout from 'components/Layout/PageLayout';
import usePageTitle from 'utils/hooks/usePageTitle';
import { Outlet } from 'react-router-dom';
import MyGiving from './MyGiving';
import Estate from './Estate';
import LoyalDeac from './LoyalDeac';
import PhilanthropyReport from './PhilanthropyReport';
import useProfileData from '../../utils/hooks/useProfileData';
import LoadingZone from '../LoadingZone';

const Philanthropy = () => {
  const isProfileDataLoading = useProfileData();
  const profileBioSection = useSelector(
    (state) => (state.Profile || {}).BIOGRAPHICAL || {}
  );

  const isPhilanthropyReportRecipient =
    profileBioSection.ISPHILANTHROPYREPORTRECIPIENT;
  const userAge = profileBioSection.AGE;
  const primaryConstituency = profileBioSection.PRIMARYCONSTITUENCY;
  const legacySociety = profileBioSection.LEGACYSOCIETY || {};

  // Show Estate section for Legacy Society members,
  // constituents over age 50, and
  // anyone who isn't a current student.
  let showEstate = false;
  if (
    legacySociety.DESCRIPTION ||
    userAge >= 50 ||
    (primaryConstituency && primaryConstituency !== 'Student')
  ) {
    showEstate = true;
  }

  usePageTitle('Philanthropy');

  return (
    <PageLayout sTitle='Philanthropy' bHasBackground={false}>
      <LoadingZone isLoading={isProfileDataLoading}>
        <MyGiving />
        {isPhilanthropyReportRecipient && <PhilanthropyReport />}
        {/* Removed pending further info on the future of the Loyal Deac program */}
        {/* <LoyalDeac /> */}
        {showEstate && <Estate legacySociety={legacySociety} />}
      </LoadingZone>
      {/* This tells <BrowserRouter /> where to render nested routes - here, the public profile modal */}
      <Outlet />
    </PageLayout>
  );
};

export default Philanthropy;
