import { createSelector } from 'reselect';
import { Auth } from 'aws-amplify';

import * as PledgePaymentActions from 'components/Philanthropy/MyGiving/Pledges/Payments/redux/actions';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import store from 'redux/store';

const aIncomingConstituencies = (state) =>
  state.Profile?.BIOGRAPHICAL?.ALL_CONSTITUENCIES || [];

const fnFormatConstituencies = (aConstituencies) =>
  aConstituencies.map((oConstituency) => oConstituency.CONSTITUENCY);

export const userConstituenciesSelector = createSelector(
  aIncomingConstituencies,
  fnFormatConstituencies
);

export const fnSignOut = () => {
  store.dispatch(PledgePaymentActions.reset({}));
  store.dispatch(CheckoutActions.reset({}));
  Auth.signOut({ global: true });
};
