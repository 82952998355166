import React from 'react';
import { useSelector } from 'react-redux';

import CheckoutTracker from 'components/Checkout/CheckoutTracker';
import PageLayout from 'components/Layout/PageLayout';
import { currentUserFirstLastNameSelector } from 'redux/selectors';
import usePageTitle from 'utils/hooks/usePageTitle';
import LoadingZone from 'components/LoadingZone';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import GivingForm from './GivingForm';

const GivingCheckout = () => {
  const { sFirstName } = useSelector(currentUserFirstLastNameSelector);
  const aFunds = useCodeTablesSection('FUND');

  usePageTitle('Giving Checkout');

  return (
    <>
      <CheckoutTracker sFirstStepLabel='Choose Amount' sCurrentStep='select' />
      <PageLayout sTitle='Giving'>
        {sFirstName && <p>Hi {sFirstName},</p>}
        <p>Tell us how much you would like to give today:</p>
        <LoadingZone isLoading={aFunds.length === 0}>
          <GivingForm />
        </LoadingZone>
      </PageLayout>
    </>
  );
};

export default GivingCheckout;
