import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { TextField } from 'components/Form/Fields';

const BestEmailToContactYouField = () => {
  const aEmailData = useSelector((state) => (state.Profile || {}).EMAIL || []);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (aEmailData.length > 0) {
      const oPrimaryEmail = aEmailData.find((oEmail) => oEmail.PRIMARY);
      if (oPrimaryEmail) {
        setFieldValue('email', oPrimaryEmail.EMAILADDRESS);
      }
    }
  }, [aEmailData, setFieldValue]);

  return (
    <TextField
      sLabel='Email'
      sId='email'
      sName='email'
      sPlaceholder='you@example.com'
      sHelpText='Best email to contact you'
    />
  );
};

export default BestEmailToContactYouField;
