import { createActions } from 'redux-actions';

export default createActions(
  {
    GET: (action) => action,
    SET: (action) => action,
    UPDATE: (action) => action,
    ADD_NOTE: (action) => action,
  },
  { prefix: 'Profile/DEGREE' }
);
