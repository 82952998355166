import {
  fnPhoneNumberFormatterE164,
  fnPrettyFormatPhoneNumberForRegion,
} from 'utils/common';

export const oInitialFormValues = {
  username: '',
  password: '',
  recoveryEmail: '',
  recoveryPhoneCountry: {
    DESCRIPTION: 'United States',
    ID: '70FA9361-547E-4C98-A3CF-F655D36F8ECF',
    TYPE: 'COUNTRY',
    DETAILS: {
      ABBRV: 'US',
      NAME_ABBRV: 'US - United States',
    },
  },
  recoveryPhoneNumber: '',
};

export const fnFormatAlumniGoogleAccountData = (oFormValues, oProfile) => {
  const { recoveryPhoneNumber, recoveryPhoneCountry } = oFormValues;
  const { FIRSTNAME, LASTNAME, BANNERID } = oProfile?.BIOGRAPHICAL ?? {};

  const sE164FormattedRecoveryPhoneNumber = fnPhoneNumberFormatterE164(
    recoveryPhoneNumber,
    recoveryPhoneCountry.DETAILS.ABBRV
  );
  const sPrettyRecoveryPhoneNumber = fnPrettyFormatPhoneNumberForRegion(
    recoveryPhoneNumber,
    recoveryPhoneCountry.DETAILS.ABBRV
  );

  const oData = {
    firstname: FIRSTNAME,
    lastname: LASTNAME,
    password: oFormValues.password,
    email: `${oFormValues.username}@alumni.wfu.edu`,
    recoveryEmail: oFormValues.recoveryEmail,
    recoveryPhone: {
      sRecoveryPhoneNumber: sPrettyRecoveryPhoneNumber,
      sE164FormattedRecoveryPhoneNumber,
      sPhoneCountry: oFormValues.recoveryPhoneCountry.DETAILS.ABBRV,
    },
    bannerId: BANNERID,
  };

  return oData;
};

export default {};
