import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a foundation's name linked to its website if available.
 */
const FoundationDonor = ({ oDonor }) => {
  if (oDonor.FOUNDATION_URL) {
    return (
      <a href={oDonor.FOUNDATION_URL} target='_blank' rel='noreferrer'>
        {oDonor.NAME}
      </a>
    );
  }
  return oDonor.NAME;
};

FoundationDonor.propTypes = {
  oDonor: PropTypes.shape({
    FOUNDATION_URL: PropTypes.string,
    NAME: PropTypes.string,
  }).isRequired,
};

export default FoundationDonor;
