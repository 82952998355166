import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import LoadingZone from 'components/LoadingZone';
import { fnSelectMyNetworkConnections } from 'components/MyNetwork/helpers';
import { fnFuzzySearch } from 'utils/common';
import ConstituentSearchInput from './ConstituentSearchInput';
import ConstituentSearchResultsList from './ConstituentSearchResultsList';
import fnDebouncedCallQuickSearch from './fnDebouncedCallQuickSearch';
import fnCombineSearchResults from './fnCombineSearchResults';
import { fnBuildFormikInitialNameValues } from './helpers';

const ConstituentSearch = ({
  fnSetGuestUIPhase,
  fnSetNewGuestInitialValues,
  fnHandleNewConstituent,
  sLabel,
  AlternateFooterComponent,
}) => {
  const [sQuery, fnSetQuery] = useState('');
  const [aSearchResults, fnSetSearchResults] = useState(null);
  const aAbortControllersRef = useRef([]);
  const [bIsLoading, fnSetIsLoading] = useState(false);
  const aMyNetwork = useSelector(fnSelectMyNetworkConnections);

  const fnHandleResults = useCallback(
    (oResults) => {
      fnSetIsLoading(false);
      const aQuickSearchHits = oResults.hits.hit;
      const aMyNetworkSearchResults =
        aMyNetwork.length > 0 ? fnFuzzySearch(aMyNetwork, 'NAME', sQuery) : [];
      const aCombinedResults = fnCombineSearchResults(
        aQuickSearchHits,
        aMyNetworkSearchResults
      );
      fnSetSearchResults(aCombinedResults);
    },
    [aMyNetwork, sQuery]
  );

  const fnResetSearch = () => {
    fnSetQuery('');
    fnSetSearchResults(null);
    fnSetIsLoading(false);
  };

  // Call the quick search API endpoint when the query changes.
  useEffect(() => {
    if (sQuery.trim().length >= 2) {
      fnSetIsLoading(true);

      // @todo Remove this check once all browsers support requestIdleCallback.
      // Looking at you, Safari. https://caniuse.com/requestidlecallback
      if (window.requestIdleCallback) {
        window.requestIdleCallback(() => {
          fnDebouncedCallQuickSearch(
            sQuery,
            aAbortControllersRef.current,
            fnHandleResults
          );
        });
      } else {
        fnDebouncedCallQuickSearch(
          sQuery,
          aAbortControllersRef.current,
          fnHandleResults
        );
      }
    }

    // Reset the search results if the query is empty
    if (sQuery.trim().length === 0) {
      fnSetSearchResults(null);
    }
  }, [sQuery, fnHandleResults]);

  return (
    <div className='constituentSearch'>
      <ConstituentSearchInput
        sQuery={sQuery}
        fnSetQuery={fnSetQuery}
        sLabel={sLabel}
      />
      {sQuery && (
        <div className='constituentSearchResults'>
          <LoadingZone isLoading={bIsLoading}>
            <ConstituentSearchResultsList
              aSearchResults={aSearchResults}
              fnResetSearch={fnResetSearch}
              fnHandleNewConstituent={fnHandleNewConstituent}
            />
          </LoadingZone>
          {AlternateFooterComponent ? (
            <AlternateFooterComponent
              sQuery={sQuery}
              fnSetUIPhase={fnSetGuestUIPhase}
              fnSetNewPersonInitialValues={fnSetNewGuestInitialValues}
            />
          ) : (
            <div className='constituentSearchResults__footer'>
              <p className='constituentSearchResults__footerTitle'>
                Didn&apos;t find who you were looking for?
              </p>
              <div className='constituentSearch__alternatives'>
                <ButtonWithIcon
                  sIcon='plus'
                  fnHandleClick={() => {
                    fnSetNewGuestInitialValues({
                      ...fnBuildFormikInitialNameValues(sQuery),
                      email: '',
                    });
                    fnSetGuestUIPhase('create');
                  }}
                  sCypressId='add-guest-manually-button'
                >
                  Add Your Guest Manually
                </ButtonWithIcon>
                <span className='t-label'>or</span>
                <ButtonWithIcon
                  sIcon='plus'
                  fnHandleClick={() => {
                    fnHandleNewConstituent('', '');
                  }}
                  sCypressId='add-unnamed-guest-button'
                >
                  Add Unnamed Guest
                </ButtonWithIcon>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ConstituentSearch.propTypes = {
  fnSetGuestUIPhase: PropTypes.func.isRequired,
  fnSetNewGuestInitialValues: PropTypes.func.isRequired,
  fnHandleNewConstituent: PropTypes.func.isRequired,
  sLabel: PropTypes.string,
  AlternateFooterComponent: PropTypes.func,
};

ConstituentSearch.defaultProps = {
  sLabel: "Enter your guest's first and last name",
  AlternateFooterComponent: null,
};

export default ConstituentSearch;
