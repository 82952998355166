import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../Layout/Tooltip';

const RecordPrivacyIcon = ({ ariaId }) => (
  <div className='recordPrivacyIcon'>
    <Tooltip sTriggerIcon='lock' ariaId={ariaId}>
      This information is not visible to others.
    </Tooltip>
  </div>
);

RecordPrivacyIcon.propTypes = {
  ariaId: PropTypes.string.isRequired,
};

export default RecordPrivacyIcon;
