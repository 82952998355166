import { createActions } from 'redux-actions';

export const wfuFamilyActions = createActions(
  {
    GET: (payload) => payload,
    SET: (payload) => payload,
    CREATE: (payload) => payload,
    UPDATE: (payload) => payload,
    ADD_NOTE: (action) => action,
    DELETE: (payload) => payload,
  },
  {
    prefix: 'Profile/WFUFAMILY',
  }
);

export default wfuFamilyActions;
