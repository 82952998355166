import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import { oCurrencyFormatter } from 'utils/common';
import EventRegistrantName from '../EventRegistrantName';
import { fnRemoveGuestFromEventInCart } from '../redux/guestHelpers';

const ReviewRegistrantsForEventWithOneRegOption = ({ sEventLookupId }) => {
  const oFormikProps = useFormikContext();
  const aGuests = oFormikProps.values.events.find(
    (event) => event.sEventLookupId === sEventLookupId
  ).guests;
  return (
    <div className='reviewRegistration__form'>
      <h4 className='reviewRegistration__listHeader t-paragraph--medium'>
        Registrant List
      </h4>
      <div className='reviewRegistration__tableWrapper'>
        <table
          className='reviewRegistration__table'
          data-cy={`event-${sEventLookupId}-registrants-table`}
        >
          <thead className='hide_accessibly'>
            <tr>
              <th>Registrant Name</th>
              <th>Cost</th>
              <th>Remove Registrant</th>
            </tr>
          </thead>
          <tbody className='reviewRegistration__tableBody'>
            {aGuests.map(({ sGuestLookupId, sGuestName, REG_OPTION }) => (
              <tr
                key={sGuestLookupId}
                data-cy={`registrant-${sGuestLookupId}-row`}
              >
                <td
                  className='reviewRegistration__nameCell'
                  data-cy='registrant-name'
                >
                  <EventRegistrantName sName={sGuestName} />
                </td>
                <td
                  className='reviewRegistration__optionCell'
                  data-cy='registrant-cost'
                >
                  {oCurrencyFormatter.format(REG_OPTION.COST)}
                </td>
                <td className='reviewRegistration__removeButtonCell'>
                  <IconOnlyButton
                    sIcon='minus'
                    sAriaLabel='Remove Registrant'
                    sFlavor='secondary'
                    fnHandleClick={() => {
                      fnRemoveGuestFromEventInCart(
                        sGuestLookupId,
                        sEventLookupId
                      );
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ReviewRegistrantsForEventWithOneRegOption.propTypes = {
  sEventLookupId: PropTypes.string.isRequired,
};

export default ReviewRegistrantsForEventWithOneRegOption;
