import React from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';

import { NewNameForm } from '../NewNameForm';
import EditableNameRecord from './EditableNameRecord';
import EditSpouseRecord from './EditSpouseRecord';

const oInitialValues = {
  FIRSTNAME: '',
  LASTNAME: '',
  NICKNAME: '',
  ATTENDNAME: null,
  TITLE: null,
  PROFESSIONALSUFFIX: null,
  PERSONALSUFFIX: null,
  PRIVACY: '0',
};

/**
 * Displays profile address section in edit mode.
 */
const NameEditMode = ({
  data,
  bIsExpanded,
  sTitle,
  bAllowMultiple,
  actions,
  name,
  attendNameActions,
  bIsPrivacyChangeable,
}) => (
  <>
    {/* Edit existing names */}
    {data.map((item) =>
      name === 'spouse' ? (
        <EditSpouseRecord data={item} key={`${name}-record-${item.LOOKUPID}`} />
      ) : (
        <EditableNameRecord
          data={item}
          bIsExpanded={bIsExpanded}
          actions={actions}
          sTitle={sTitle}
          attendNameActions={attendNameActions}
          name={name}
          bIsPrivacyChangeable={bIsPrivacyChangeable}
          key={`${name}-record-${item.LOOKUPID}`}
        />
      )
    )}

    {/* Add a new name */}
    {(data.length === 0 || bAllowMultiple) && (
      <NewNameForm
        initialValues={oInitialValues}
        bIsExpanded={bIsExpanded}
        actions={actions}
        sTitle={sTitle}
        attendNameActions={attendNameActions}
        activateFormLabel={`Add ${capitalCase(name)}`}
        name={name}
        bIsPrivacyChangeable={bIsPrivacyChangeable}
      />
    )}
  </>
);

NameEditMode.defaultProps = {
  data: [],
};

NameEditMode.propTypes = {
  bIsExpanded: PropTypes.bool.isRequired,
  sTitle: PropTypes.string.isRequired,
  bAllowMultiple: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    addNote: PropTypes.func,
    create: PropTypes.func,
    get: PropTypes.func,
    set: PropTypes.func,
    update: PropTypes.func,
  }).isRequired,
  name: PropTypes.string.isRequired,
  attendNameActions: PropTypes.shape({
    addNote: PropTypes.func,
    create: PropTypes.func,
    get: PropTypes.func,
    set: PropTypes.func,
    update: PropTypes.func,
  }).isRequired,
  bIsPrivacyChangeable: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      FIRSTNAME: PropTypes.string,
      LASTNAME: PropTypes.string,
      MIDDLENAME: PropTypes.string,
      NICKNAME: PropTypes.string,
      ATTENDNAME: PropTypes.shape({
        ID: PropTypes.string,
        NAME: PropTypes.string,
        PRIVACY: PropTypes.string,
      }),
      TITLE: PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
      PROFESSIONALSUFFIX: PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
      PERSONALSUFFIX: PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
    })
  ),
};

export default NameEditMode;
