import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import aSizes from './sizes';

const oTitleTagBysSize = {
  large: 'h2',
  medium: 'h3',
  small: 'h4',
  smallUnderline: 'h4',
};

const AccordionTrigger = ({
  bIsOpen,
  sSize,
  sTitleIcon,
  sTitleIconType,
  children,
}) => {
  const TitleTag = oTitleTagBysSize[sSize];

  let openIcon = 'plus';
  let closeIcon = 'minus';
  if (['smallUnderline', 'medium'].includes(sSize)) {
    openIcon = 'expand';
    closeIcon = 'collapse';
  }
  return (
    <>
      <TitleTag className={`accordion__title--${sSize}`}>
        {sSize === 'large' && sTitleIconType === 'fontAwesome' && (
          <FontAwesomeIcon icon={oIconLibrary[sTitleIconType][sTitleIcon]} />
        )}
        {sSize === 'large' && sTitleIconType === 'wfu' && (
          <img
            src={oIconLibrary.wfu[sTitleIcon]}
            alt=''
            className='accordion__titleImage'
          />
        )}
        {children}
      </TitleTag>
      <div className={` accordion__headerToggleIconContainer--${sSize}`}>
        <FontAwesomeIcon
          icon={oIconLibrary.fontAwesome[bIsOpen ? closeIcon : openIcon]}
          className='accordion__headerToggleIcon'
        />
      </div>
    </>
  );
};

AccordionTrigger.propTypes = {
  bIsOpen: PropTypes.bool,
  sSize: PropTypes.oneOf(aSizes).isRequired,
  sTitleIcon: PropTypes.string,
  sTitleIconType: PropTypes.oneOf(['fontAwesome', 'wfu']),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

AccordionTrigger.defaultProps = {
  bIsOpen: false,
  sTitleIcon: '',
  sTitleIconType: 'fontAwesome',
};

export default AccordionTrigger;
