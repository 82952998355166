import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import useActions from '../../../utils/hooks/useActions';
import * as ProfileActions from '../../MyProfile/redux/actions';
import AddNote from '../../Form/OptionsModal/DefaultFields/AddNote';

const EstateStatus = ({ status, setIsEditingPrefs }) => {
  const actions = useActions(ProfileActions);
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactFormStatus, setContactFormStatus] = useState('');

  const handleSubmit = async ({
    data,
    resolve: addNoteResolve,
    reject: addNoteReject,
  }) => {
    try {
      const submitPromise = new Promise((resolve, reject) => {
        actions.addNote({
          data: {
            TITLE: 'Estate update',
            TEXTNOTE: data.TEXTNOTE,
            NOTETYPECODEID: 'DB31B27D-288F-4322-8335-C12331C1BC1A',
          },
          resolve,
          reject,
        });
      });
      const result = await submitPromise;

      // Let <AddNote /> know if the API request succeeded or not
      if (result === 'Success') {
        addNoteResolve(result);
      } else {
        addNoteReject();
      }
    } catch (err) {
      console.error('Error sending note about estate preferences', err);
    }
  };

  let contactUsButtonText = 'Contact us';
  if (showContactForm) {
    contactUsButtonText = 'Cancel';
    if (contactFormStatus === 'success') {
      contactUsButtonText = 'Close';
    }
  }

  const contactBtn = (
    <div className='estate__contactWrapper'>
      <Button
        fnHandleClick={() => setShowContactForm(!showContactForm)}
        className='estate__contactBtn'
        sFlavor='underline'
      >
        {contactUsButtonText}
      </Button>
      {showContactForm && (
        <div className='estate__contactFormWrapper'>
          <AddNote
            visible={showContactForm}
            onSubmit={handleSubmit}
            status={contactFormStatus}
            setFormStatus={setContactFormStatus}
          />
        </div>
      )}
    </div>
  );

  const changePrefsBtn = (
    <Button
      fnHandleClick={() => setIsEditingPrefs(true)}
      className='estate__changePrefsBtn'
    >
      Change Preferences
    </Button>
  );

  let statusContent = null;
  switch (status) {
    case 'Yes - Planned Giver':
    case 'Yes':
      statusContent = (
        <>
          <p className='estate__status'>
            I have chosen to include Wake Forest in my will.
          </p>
          {contactBtn}
        </>
      );
      break;
    case 'Interested':
      statusContent = (
        <>
          <p className='estate__status'>I am interested to know more.</p>
          {changePrefsBtn}
          {contactBtn}
        </>
      );
      break;
    case 'No':
      statusContent = (
        <>
          <p className='estate__status'>I am not interested right now.</p>
          {changePrefsBtn}
          {contactBtn}
        </>
      );
      break;
    default:
      break;
  }

  return statusContent;
};

EstateStatus.propTypes = {
  status: PropTypes.string,
  setIsEditingPrefs: PropTypes.func,
};

EstateStatus.defaultProps = {
  status: '',
  setIsEditingPrefs: () => {},
};

export default EstateStatus;
