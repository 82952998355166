import React from 'react';

import useWindowSize from 'utils/hooks/useWindowSize';
import wnLogoSvg from 'images/logos/wn-logo-gold-gray.svg';
import IdentityProviderAccountManagement from './IdentityProviderAccountManagement';

const AuthLayoutHeader = () => {
  const oWindowSize = useWindowSize();
  return (
    <header className='authLayout__header'>
      <img
        src={wnLogoSvg}
        className='authLayout__logo'
        alt='WakeNetwork Logo'
      />
      {oWindowSize.width >= 1024 && <IdentityProviderAccountManagement />}
    </header>
  );
};

export default AuthLayoutHeader;
