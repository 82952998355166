import React from 'react';
import PropTypes from 'prop-types';

export const SkeletonCircle = ({ size, className }) => (
  <div className={`${className} skeletonCircle skeletonCircle--${size}`} />
);

SkeletonCircle.propTypes = {
  size: PropTypes.oneOf(['small', 'large', 'extraLarge']),
  className: PropTypes.string,
};

SkeletonCircle.defaultProps = {
  size: 'large',
  className: '',
};

export default SkeletonCircle;
