import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import { sortByKey } from 'utils/common';
import { createSelector } from 'reselect';
import AddressEditMode from './editMode';
import AddressViewMode from './viewMode';
import ViewMoreButton from '../../../../Layout/ViewMoreButton';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';

const fAddressSelector = (state) => (state.Profile || {}).ADDRESS || [];

const sortAddressesByPrimarySelector = createSelector(
  fAddressSelector,
  (items) => sortByKey(items, 'PRIMARY', false)
);

export const AddressElement = () => {
  const [activeFormSection] = useActiveFormSection();
  const aAddresses = useSelector(sortAddressesByPrimarySelector);
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      fnSetIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      fnSetIsEditMode(false);
    }
  }, [activeFormSection]);
  return (
    <ProfileSubsectionLayout
      sTitle='Address'
      left={
        aAddresses.length > 1 && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <AddressEditMode aAddresses={aAddresses} bIsExpanded={bIsExpanded} />
        ) : (
          <AddressViewMode aAddresses={aAddresses} bIsExpanded={bIsExpanded} />
        )
      }
    />
  );
};

export default AddressElement;
