import React from 'react';

const oToday: Date = new Date();
const iYear: number = oToday.getFullYear();

const LandingPageFooter = () => (
  <footer className='landingPage__footer'>
    <a
      href='https://about.wfu.edu/privacy-policy/'
      className='landingPage__footerLink'
      target='_blank'
      rel='noopener noreferrer'
    >
      Privacy Policy
    </a>
    <a
      href='https://alumni.wfu.edu/stay-connected/wakenetwork/wakenetwork-f-a-q/'
      className='landingPage__footerLink'
      target='_blank'
      rel='noopener noreferrer'
    >
      FAQ
    </a>
    <p className='landingPage__copyright'>
      &copy; Wake Forest University {iYear}
    </p>
  </footer>
);

export default LandingPageFooter;
