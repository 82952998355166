import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import qs from 'qs';

import oWnApi from 'utils/WnApi';
import { SubmitButton } from 'components/Form/Buttons';
import { TextField } from '../../../../Form/Fields';
import { arrayCast } from '../../../../../utils/common';
import matchFormSchema from './schema';

const MatchForm = ({ setMatches, matchType }) => {
  const fnHandleSubmit = async (formData) => {
    const oParams = {
      firstname: formData.firstname || '',
      lastname: formData.lastname || '',
      type: matchType,
    };
    const sQueryParams = qs.stringify(oParams);
    const oResponse = await oWnApi.get(
      `users/identity_match_search?${sQueryParams}`
    );
    if (oResponse.status === 200) {
      let aMatches = [];
      if (oResponse.data.MATCHES !== 'NO RESULTS') {
        aMatches = arrayCast(oResponse.data.MATCHES.DATA);
      }
      setMatches(aMatches);
    }
  };

  return (
    <>
      <h2>Great! Let&apos;s find you in our system.</h2>
      <Formik
        onSubmit={fnHandleSubmit}
        initialValues={{
          firstname: '',
          lastname: '',
        }}
        validateOnBlur
        validationSchema={matchFormSchema}
      >
        {(formikProps) => (
          <Form className='matchForm'>
            <p>Enter your name below:</p>
            <TextField sLabel='First Name' sName='firstname' sId='first-name' />
            <TextField sLabel='Last Name' sName='lastname' sId='last-name' />
            <SubmitButton sCypressId='match_SearchButton'>Search</SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

MatchForm.propTypes = {
  setMatches: PropTypes.func.isRequired,
  matchType: PropTypes.string.isRequired,
};

export default MatchForm;
