import { getWakeNetworkHomeUrl } from 'config';
import DateAndTime from 'date-and-time';
import TimeZonePlugin from 'date-and-time/plugin/timezone';

import { fnConvertTimeZoneAbbrToIana } from 'utils/common';

DateAndTime.plugin(TimeZonePlugin);

/**
 * Formats a start and end date time for Google calendar.
 *
 * @param {string} sStartDateTime - Start date time from DAD
 * @param {string} sEndDateTime - End date time from DAD
 * @param {string} sTimeZoneAbbr - Time zone abbreviation
 */
const fnFormatDateTimeForGoogleCalendar = (
  sStartDateTime,
  sEndDateTime,
  sTimeZoneAbbr = 'ET'
) => {
  const sIanaTimeZone = fnConvertTimeZoneAbbrToIana(sTimeZoneAbbr);
  const oStartDate = DateAndTime.parseTZ(
    sStartDateTime,
    'YYYY-MM-DDTHH:mm:ss',
    sIanaTimeZone
  );
  const sStartDate = DateAndTime.formatTZ(oStartDate, 'YYYYMMDDTHHmmssZ');
  const oEndDate = DateAndTime.parseTZ(
    sEndDateTime,
    'YYYY-MM-DDTHH:mm:ss',
    sIanaTimeZone
  );
  const sEndDate = DateAndTime.formatTZ(oEndDate, 'YYYYMMDDTHHmmssZ');
  return `${sStartDate}/${sEndDate}`;
};

/**
 * Formats an event's location information for Google calendar.
 *
 * @example
 * fnFormatLocationForGoogleCalendar({
 *  NAME: 'Reynolda Hall',
 *  ROOM: 'Magnolia Room',
 *  ADDRESSBLOCK: '1834 Wake Forest Road',
 *  CITY: 'Winston-Salem',
 *  POSTCODE: '12345',
 *  STATE: 'NC',
 * })
 * // 'Reynolda Hall, Magnolia Room, 1834 Wake Forest Road, Winston-Salem, NC 12345'
 *
 * @param {object} oLocation - LOCATION object from the event details API response
 * @returns {string}
 */
export const fnFormatLocationForGoogleCalendar = (oLocation) => {
  if (!oLocation) {
    return '';
  }

  const { NAME, ROOM, ADDRESSBLOCK, CITY, STATE, POSTCODE } = oLocation;
  let sLocation = '';
  const aLocationParts = [NAME, ROOM, ADDRESSBLOCK, CITY, STATE];
  const aDefinedLocationParts = aLocationParts.filter((mItem) => !!mItem);
  sLocation = aDefinedLocationParts.join(', ');
  if (POSTCODE) {
    sLocation += ` ${POSTCODE}`;
  }
  return sLocation;
};

const fnBuildFullEventDetailPageLink = (sEventSlug) => {
  const sBaseUrl = getWakeNetworkHomeUrl();
  return `${sBaseUrl}events/${sEventSlug}`;
};

/**
 * Creates a link that generates a Google calendar event.
 * See https://stackoverflow.com/a/19867654
 *
 * @param {object} oEvent - Event details.
 * @returns {string}
 */
export const fnBuildGoogleCalendarLink = (oEvent) => {
  const {
    TITLE,
    START_DATE_TIME,
    END_DATE_TIME,
    DESCRIPTION,
    LOCATION,
    TIME_ZONE,
    SLUG,
    CONTACT,
  } = oEvent;

  const sTitle = encodeURIComponent(TITLE);

  const sDateTimes = fnFormatDateTimeForGoogleCalendar(
    START_DATE_TIME,
    END_DATE_TIME,
    TIME_ZONE
  );

  const sEventDetailsLink = fnBuildFullEventDetailPageLink(SLUG);
  let sDescription = `${DESCRIPTION || ''}
  <hr />
  \n🎩 Visit <a href="${sEventDetailsLink}" target="_blank">WakeNetwork</a> for the latest event information.
  \n👋 Please get in touch with <a href="mailto:${CONTACT.EMAIL}">${
    CONTACT.NAME
  }</a> with any questions.
  `;
  sDescription = encodeURIComponent(sDescription);

  let sLocation = fnFormatLocationForGoogleCalendar(LOCATION);
  sLocation = encodeURIComponent(sLocation);

  const sGoogleLink = `http://www.google.com/calendar/event?action=TEMPLATE&text=${sTitle}&dates=${sDateTimes}&details=${sDescription}&location=${sLocation}`;
  return sGoogleLink;
};
