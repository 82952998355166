import React from 'react';
import { useSelector } from 'react-redux';

import usePageTitle from 'utils/hooks/usePageTitle';
import AdditionalInfoForm from './AdditionalInfoForm';
import { selectAllNamedEventGuests } from './selectors';

const AdditionalInfo = () => {
  const iNumNamedGuests = useSelector(selectAllNamedEventGuests).length;
  usePageTitle('Event Registration - Additional Information');

  const sWho = iNumNamedGuests > 1 ? 'you and your guests' : 'you';
  return (
    <div>
      <h1 className='additionalInfoForm__instructions t-paragraph--medium'>
        We want to ensure {sWho} have an exceptional experience. Please share
        below anything that may be helpful for us to know.
      </h1>
      <AdditionalInfoForm />
    </div>
  );
};

export default AdditionalInfo;
