import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import useActiveFormField from 'utils/hooks/useActiveFormField';
import GroupRecord from '../GroupRecord';
import EditableGroupRecord from '../EditableGroupRecord';
import NewGroupForm from './NewGroupForm';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';
import { fnGetCurrentAffiliations, fnHasFormerAffiliations } from '../helpers';

const OtherGroups = ({ aGroupData = [] }) => {
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, setIsEditMode] = useState(false);
  const [newPositionRowId, setNewPositionRowId] = useState(null);
  const [bShowFormer, fnSetShowFormer] = useState(false);
  const bShowToggleFormerButton = fnHasFormerAffiliations(aGroupData);

  const aCurrentAffiliations = fnGetCurrentAffiliations(aGroupData);

  useEffect(() => {
    setIsEditMode(activeFormSection === 'notableAffiliations');
  }, [activeFormSection]);

  const fnToggleFormer = () => {
    fnSetShowFormer(!bShowFormer);
  };

  return (
    <ProfileSubsectionLayout
      sTitle='Other Notable Affiliations'
      left={
        bShowToggleFormerButton && (
          <ViewMoreButton
            fnOnClick={fnToggleFormer}
            bIsShowingMore={bShowFormer}
            sViewLessLabel='Hide Former'
            sViewMoreLabel='Show Former'
          />
        )
      }
      right={
        bIsEditMode ? (
          <>
            <NewGroupForm />
            {aGroupData.map((oGroup) => (
              <EditableGroupRecord
                key={oGroup.ID}
                oData={oGroup}
                newPositionRowId={newPositionRowId}
                setNewPositionRowId={setNewPositionRowId}
              />
            ))}
          </>
        ) : (
          (bShowFormer ? aGroupData : aCurrentAffiliations).map((oGroup) => (
            <ProfileRecordLayout
              key={oGroup.ID}
              left={<GroupRecord oData={oGroup} />}
            />
          ))
        )
      }
    />
  );
};

OtherGroups.propTypes = {
  aGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
    })
  ),
};

OtherGroups.defaultProps = {
  aGroupData: [],
};

export default OtherGroups;
