import { createActions } from 'redux-actions';

export const { get, set, update, reset } = createActions(
  {
    GET: (p) => p,
    SET: (p) => p,
    UPDATE: (p) => p,
    RESET: (p) => p,
  },
  {
    prefix: 'CodeTables',
  }
);
