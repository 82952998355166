import React from 'react';
import PropTypes from 'prop-types';

import DateSelectMMDD from './DateSelectMMDD';

const DateRangeMMDD = ({ sGroupLabel }) => (
  <fieldset className='dateRangeMMDD'>
    <legend>{sGroupLabel}</legend>
    <DateSelectMMDD
      groupLabel='Start Date'
      monthFieldOptions={{
        id: 'start-month',
        label: 'Month',
        name: 'START_MONTH',
      }}
      dayFieldOptions={{
        id: 'start-day',
        label: 'Day',
        name: 'START_DAY',
      }}
    />
    <div className='dateRangeMMDD__to'>to</div>
    <DateSelectMMDD
      groupLabel='End Date'
      monthFieldOptions={{
        id: 'end-month',
        label: 'Month',
        name: 'END_MONTH',
      }}
      dayFieldOptions={{
        id: 'end-day',
        label: 'Day',
        name: 'END_DAY',
      }}
    />
  </fieldset>
);

DateRangeMMDD.propTypes = {
  sGroupLabel: PropTypes.string,
};

DateRangeMMDD.defaultProps = {
  sGroupLabel: 'Date Range',
};

export default DateRangeMMDD;
