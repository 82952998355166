import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConstituentAvatar from 'components/ConstituentAvatar';
import oIconLibrary from 'utils/iconLibrary';
import PhotoUploadModal from './PhotoUploadModal';

/**
 * Displays the user's profile photo if they have one;
 * otherwise, it shows their initials.
 * It also allows the user to upload a new photo.
 */
const EditableAvatar = () => {
  const { LOOKUPID, NICKNAME, LASTNAME, HAS_PHOTO } = useSelector(
    (state) => state.Profile.BIOGRAPHICAL
  );
  const [bShowModal, fnSetShowModal] = useState(false);
  const [sUploadedImage, fnSetUploadedImage] = useState('');
  return (
    <>
      <button
        type='button'
        onClick={() => fnSetShowModal(true)}
        aria-label='Change photo'
        className='editableAvatar__button'
      >
        <ConstituentAvatar
          sSize='extraLarge'
          lookupId={LOOKUPID}
          sFirstName={NICKNAME}
          sLastName={LASTNAME}
          bHasPhoto={HAS_PHOTO}
          sUploadedImage={sUploadedImage}
        />
        <span className='editableAvatar__camera button--iconOnly--secondary'>
          <FontAwesomeIcon icon={oIconLibrary.fontAwesome.camera} />
        </span>
      </button>
      {bShowModal && (
        <PhotoUploadModal
          setShowChangePhotoModal={fnSetShowModal}
          fnSetUploadedImage={fnSetUploadedImage}
        />
      )}
    </>
  );
};

export default EditableAvatar;
