import React from 'react';
import PropTypes from 'prop-types';

import { sEmptyGuid } from 'utils/constants';
import { Radio } from '../../../Fields';

export const SelectPrivacy = ({
  data,
  privacyLabels,
  isPrivacyChangeable,
  name,
  formikFieldName,
}) => {
  // Constituent name is always public
  const alwaysPublic = ['name'];
  return (
    <>
      {isPrivacyChangeable && (
        <fieldset className='optionsModal__section'>
          <legend className='optionsModal__sectionTitle'>Select Privacy</legend>
          <Radio
            sName={formikFieldName}
            sId={`${data.ID}-privacy_public`}
            mValue='0'
            sLabel={privacyLabels[0]}
            sUi='buttons'
          />
          {!!privacyLabels[1] && (
            <Radio
              sName={formikFieldName}
              sId={`${data.ID}-privacy_radio_limited`}
              mValue='1'
              sLabel={privacyLabels[1]}
              sUi='buttons'
            />
          )}
          <Radio
            sName={formikFieldName}
            sId={`${data.ID}-privacy_private`}
            mValue='2'
            sLabel={privacyLabels[2]}
            sUi='buttons'
          />
        </fieldset>
      )}

      {/* Show this message when user can't change privacy settings because item is always private */}
      {!isPrivacyChangeable && !alwaysPublic.includes(name) && (
        <div className='optionsModal__section'>
          <div className='optionsModal__alwaysPrivate'>
            This is not visible to others
          </div>
        </div>
      )}
    </>
  );
};

SelectPrivacy.defaultProps = {
  data: { ID: sEmptyGuid },
  privacyLabels: ['Public', null, 'Private'],
  isPrivacyChangeable: true,
  name: '',
  formikFieldName: 'PRIVACY',
};

SelectPrivacy.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
  }),
  privacyLabels: PropTypes.arrayOf(PropTypes.string),
  isPrivacyChangeable: PropTypes.bool,
  name: PropTypes.string,
  formikFieldName: PropTypes.string,
};

export default SelectPrivacy;
