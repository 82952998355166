import React from 'react';
import PropTypes from 'prop-types';

import PencilEditButton from 'components/Layout/PencilEditButton';

/**
 * Section
 * @description - A field that allows the user to indicate that an item is the
 *                'primary' among its group.
 * @memberof Scholarships
 * @param {Object} props - The props passed to this component.
 *
 * @author Tim Holder holdertm@wfu.edu
 * @copyright 2022 Wake Forest University, AIT
 */
const SectionHeader = ({
  sTitle,
  bIsEditing,
  fnHandleEditButtonClick,
  bIsEditable,
}) => (
  <div className='profileSection'>
    <div className='profileSectionHeader'>
      <div className='profileSectionHeader__titleWrapper'>
        <h2 className='profileSectionHeader__title t-label'>{sTitle}</h2>
      </div>
      {bIsEditable && (
        <PencilEditButton
          className='profileSectionHeader__editButton'
          fnHandleClick={fnHandleEditButtonClick}
          bIsEditing={bIsEditing}
        />
      )}
    </div>
  </div>
);

SectionHeader.defaultProps = {
  fnHandleEditButtonClick: () => {},
  bIsEditing: false,
  bIsEditable: false,
};

SectionHeader.propTypes = {
  sTitle: PropTypes.string.isRequired,
  fnHandleEditButtonClick: PropTypes.func,
  bIsEditing: PropTypes.bool,
  bIsEditable: PropTypes.bool,
};

export default SectionHeader;
