import { createActions } from 'redux-actions';

export default createActions(
  {
    GET: (payload) => payload,
    SET: (payload) => payload,
    CREATE: (payload) => payload,
    UPDATE: (payload) => payload,
    ADD_NOTE: (action) => action,
    DELETE: (payload) => payload,
  },
  { prefix: 'Profile/INVOLVEMENT' }
);
