import React, { useMemo } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import * as CheckoutActions from 'components/Checkout/redux/actions';
import Button from 'components/Buttons/Button';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { useNavigate } from 'react-router-dom';
import { oEmptyRow, oSchema, useInitialValues, fnSortByTier } from './helpers';
import GiftFieldRow from './GiftFieldRow';
import GiftTotal from './GiftTotal';
import { selectCartGifts } from '../../selectors';

const GivingForm = () => {
  const aCartGifts = useSelector(selectCartGifts);
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();

  const oInitialValues = useInitialValues(aCartGifts);

  const aFunds = useCodeTablesSection('FUND', fnSortByTier);
  const aTopFunds = useMemo(() => aFunds.slice(0, 18), [aFunds]);

  const fnHandleCancelClick = () => {
    fnDispatch(CheckoutActions.reset({}));
    fnNavigate('/philanthropy');
  };

  const fnHandleSubmit = (oValues) => {
    // Clear any existing gifts in the cart
    if (aCartGifts.length > 0) {
      aCartGifts.forEach((oGift) => {
        fnDispatch(CheckoutActions.cart.removeItem(oGift.sId));
      });
    }
    // Add each new gift to the cart
    oValues.gifts.forEach((oGift) => {
      const { fund, amount } = oGift;
      const oItem = {
        sType: 'donation',
        sId: fund.DETAILS.CODE,
        sDisplayName: fund.DESCRIPTION,
        nPrice: parseFloat(amount.replaceAll(',', '')), // Amounts over $999 will have commas to separate thousands
        oDetails: {
          FUND: fund,
        },
      };
      fnDispatch(CheckoutActions.cart.addItem(oItem));
    });

    fnNavigate('/philanthropy/giving/review');
  };

  return (
    <Formik
      initialValues={oInitialValues}
      validationSchema={oSchema}
      onSubmit={fnHandleSubmit}
      enableReinitialize
      validateOnBlur={false}
    >
      {(formikProps) => {
        const bShowDuplicateFundsError = formikProps.errors.gifts?.some(
          (oGift) => oGift?.fund === 'Duplicate funds'
        );
        return (
          <Form className='givingCheckoutForm'>
            <FieldArray name='gifts'>
              {(oFieldArrayProps) => (
                <>
                  {formikProps.values.gifts.map((oGift, nIndex) => (
                    <GiftFieldRow
                      nIndex={nIndex}
                      oFieldArrayProps={oFieldArrayProps}
                      key={`${oGift.fund?.DETAILS.CODE}`}
                      aFunds={aFunds}
                      aTopFunds={aTopFunds}
                    />
                  ))}
                  <div className='givingCheckoutForm__addRowWrapper'>
                    <ButtonWithIcon
                      sIcon='plus'
                      fnHandleClick={() => oFieldArrayProps.push(oEmptyRow)}
                    >
                      Add
                    </ButtonWithIcon>
                  </div>
                </>
              )}
            </FieldArray>
            {bShowDuplicateFundsError && (
              <p className='form__overallError'>
                Please combine gifts to the same fund into one row.
              </p>
            )}
            <GiftTotal aGifts={formikProps.values.gifts} />
            <div className='checkout__cancelContinue'>
              <Button sType='submit' className='checkout__continue'>
                Continue
              </Button>
              <Button
                sFlavor='secondary'
                fnHandleClick={fnHandleCancelClick}
                className='checkout__cancel'
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GivingForm;
