import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { triggerGoogleTagManagerEvent } from 'utils/common';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import NotificationsModal from './NotificationsModal';

const Notifications = () => {
  const aNotificationsData = useSelector(
    (state) => state.Profile?.NOTIFICATIONS || []
  );

  const [bHasUnreadNotifications, fnSetHasUnreadNotifications] =
    useState(false);
  const [bShowModal, fnSetShowModal] = useState(false);
  const notificationsRef = useRef();

  useEffect(() => {
    const bHasUnread = aNotificationsData.some(
      (item) => item.READSTATUS === false
    );
    fnSetHasUnreadNotifications(bHasUnread);
  }, [aNotificationsData]);

  const handleClick = () => {
    triggerGoogleTagManagerEvent('notifications', 'selected bell', !bShowModal);
    fnSetShowModal(!bShowModal);
  };

  useCloseOnOutsideClick(notificationsRef, fnSetShowModal, bShowModal);

  return (
    <div className='notifications__wrapper' ref={notificationsRef}>
      <IconOnlyButton
        sIcon='bell'
        sFlavor='tertiary'
        className={`notifications__bell${
          bHasUnreadNotifications ? '--unread' : ''
        }`}
        sAriaLabel={`${bShowModal ? 'Hide' : 'Show'} notifications`}
        fnHandleClick={handleClick}
      />
      {bShowModal && <NotificationsModal fnSetShowModal={fnSetShowModal} />}
    </div>
  );
};

export default Notifications;
