import React from 'react';
import PropTypes from 'prop-types';

const SVGtophat = ({
  style = {},
  fill = '#cacaca',
  size = '25px',
  className = '',
  viewBox = '0 0 376.25 333.46',
}) => (
  <svg
    width={size}
    style={style}
    height={size}
    viewBox={viewBox}
    className={className}
    preserveAspectRatio='none'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path
      fill={fill}
      d='M183.45,224.09C158.54,223,128.57,223,99.09,217a54.5,54.5,0,0,1-7.65-2.12c-9.67-3.56-12-6.53-11.71-17,1.07-42.81-6-84.88-11.2-127.12-1.26-10.22-3.32-20.34-4.86-30.52-1.83-12.08.46-15.65,11.86-19,10.87-3.25,22.12-4.73,33.33-5.6,37.81-2.92,75.65-6,113.64-4.56,25.61,1,44.72,3.5,69.66,9.64,17.14,4.22,22.22,6.7,18.68,25.41-5.42,33.7-8.88,64.86-11.83,97.66-1.4,15.59-1.37,31.26-1,46.91.06,2,.15,4,.12,6-.22,11.84-3.92,17-15.49,19.89a192.19,192.19,0,0,1-23.5,4.12C235.69,223.41,212,223.33,183.45,224.09Z'
    />
    <path
      fill={fill}
      d='M191.39,322.81c-39.87.64-73.91-4.08-106.85-15.86-22-7.86-41.47-19.43-57.21-36.91a68,68,0,0,1-14-22.78c-4.36-12.27,1.39-22.58,14.26-24a136.22,136.22,0,0,1,39.78,2c7.37,1.38,10.72,5.57,10,13.55-.63,7.38,2.93,11.05,8.72,13.92,8.44,4.2,17.56,6.15,26.69,7.74a449.11,449.11,0,0,0,100.45,6,271,271,0,0,0,72.29-13.31c12.08-4.05,13.93-6.24,13.22-18.48-.33-5.62,2.28-8.2,7-9.07,13.74-2.52,27.62-4.81,41.59-4,14.69.82,20.28,10.23,15.27,24.22-4.47,12.46-11.86,22.79-21.77,31.7-24.61,22.15-54.53,32.3-86,39.17A280.41,280.41,0,0,1,191.39,322.81Z'
    />
  </svg>
);

export default SVGtophat;

SVGtophat.defaultProps = {
  style: {},
  fill: '#cacaca',
  size: '25px',
  className: '',
  viewBox: '0 0 376.25 333.46',
};

SVGtophat.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  fill: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
};
