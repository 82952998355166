import { handleActions } from 'redux-actions';

import * as actions from './actions';

const initialState = null;

const options = { prefix: 'CodeTables' };

const reducer = handleActions(
  {
    [actions.set]: {
      next(state, { payload }) {
        return { ...payload, _lastUpdated: Date.now() };
      },
      throw(state) {
        return state;
      },
    },
    [actions.update]: {
      next(state, { payload }) {
        return { ...state, ...payload, _lastUpdated: Date.now() };
      },
      throw(state) {
        return state;
      },
    },
    [actions.reset]: {
      next() {
        return initialState;
      },
    },
  },
  initialState,
  options
);

export default reducer;
