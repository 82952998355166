import { useState, useEffect } from 'react';
import { useField } from 'formik';
import * as R from 'ramda';

const oCustomStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    opacity: state.isFocused ? '1' : '0',
  }),
};

const useFloatingSelectLabel = (sFieldName, selectedOption) => {
  const [field, meta] = useField(sFieldName);
  const [sLabelClass, fnSetLabelClass] = useState('');
  const [bHasFocus, fnSetHasFocus] = useState(false);
  const sErrorClass = meta.error ? 'boxField--select--error' : '';

  const fnHandleFocus = () => {
    fnSetLabelClass('select__label--shifted');
    fnSetHasFocus(true);
  };

  const fnHandleBlur = () => {
    if (!selectedOption) {
      fnSetLabelClass('');
    }
    fnSetHasFocus(false);
  };

  // Shift the label up when the field has a value or is in focus
  useEffect(() => {
    const bIsEmpty =
      R.isEmpty(field.value) ||
      R.isNil(field.value) ||
      field.value.ID === null ||
      field.value.LOOKUPID === null;
    if (bIsEmpty && !bHasFocus) {
      fnSetLabelClass('');
    } else {
      fnSetLabelClass('select__label--shifted');
    }
  }, [field.value]);

  return {
    sLabelClass,
    sErrorClass,
    fnHandleFocus,
    fnHandleBlur,
    oCustomStyles,
  };
};

export default useFloatingSelectLabel;
