import React from 'react';
import PropTypes from 'prop-types';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';

const MyNetworkStatusButton = ({
  status,
  isLoading,
  addToMyNetwork,
  removeFromMyNetwork,
}) => {
  switch (status) {
    case 'ELIGIBLE':
      return (
        <ButtonWithIcon
          sIcon='plus'
          fnHandleClick={addToMyNetwork}
          bIsLoading={isLoading}
          className='myNetworkStatus__btn'
        >
          Add to My Network
        </ButtonWithIcon>
      );
    case 'CONNECTED':
      return (
        <ButtonWithIcon
          sIcon='minus'
          fnHandleClick={removeFromMyNetwork}
          bIsLoading={isLoading}
          className='myNetworkStatus__btn'
        >
          Remove from My Network
        </ButtonWithIcon>
      );
    default:
      return null;
  }
};

MyNetworkStatusButton.propTypes = {
  status: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  addToMyNetwork: PropTypes.func.isRequired,
  removeFromMyNetwork: PropTypes.func.isRequired,
};

export default MyNetworkStatusButton;
