import * as Yup from 'yup';

import { fnFormatSubEventDateAndTime } from 'components/EventDetailsPage/helpers';
import { fnBuildGoogleCalendarLink } from 'components/EventDetailsPage/AddToCalendar/helpers';
import { oAdditionalInfoFormSchema } from '../AdditionalInfo/helpers';

export const fnBuildInitialValues = (aEventCartItems, aNamedGuests) => {
  const aEvents = aEventCartItems.map((oEventCartItem) => {
    const { sType } = oEventCartItem;
    const { oEvent, aGuests } = oEventCartItem.oDetails;
    const aGuestsForEvent = aGuests.map((oGuest) => {
      const {
        LOOKUPID,
        NAME,
        REG_OPTION,
        FIRST_NAME,
        LAST_NAME,
        EMAIL_ADDRESS,
      } = oGuest;
      return {
        sGuestLookupId: LOOKUPID,
        sGuestName: NAME,
        REG_OPTION,
        FIRST_NAME,
        LAST_NAME,
        EMAIL_ADDRESS,
      };
    });
    return {
      sEventLookupId: oEvent.LOOKUPID,
      sEventSlug: oEvent.SLUG,
      sRegistrationType: sType,
      TITLE: oEvent.TITLE,
      LOCATION: oEvent.LOCATION,
      START_DATE_TIME: oEvent.START_DATE_TIME,
      END_DATE_TIME: oEvent.END_DATE_TIME,
      TIME_ZONE: oEvent.TIME_ZONE,
      CONTACT: oEvent.CONTACT,
      SLUG: oEvent.SLUG,
      guests: aGuestsForEvent,
      sMainEventTitle: oEvent.MAIN_EVENT?.TITLE ?? '',
      DESCRIPTION: oEvent.DESCRIPTION,
    };
  });

  const aAdditionalInfoByGuest = aNamedGuests.map((oGuest) => {
    const { LOOKUPID, NAME } = oGuest;
    const {
      DIETARY_RESTRICTIONS,
      OTHER_DIETARY_RESTRICTION,
      ADDITIONAL_INFO_CONTENT,
    } = oGuest.ADDITIONAL_INFO || {};
    return {
      sGuestLookupId: LOOKUPID,
      sGuestName: NAME,
      dietaryRestrictions: DIETARY_RESTRICTIONS || [],
      otherDietaryRestriction: OTHER_DIETARY_RESTRICTION || '',
      additionalInfo: ADDITIONAL_INFO_CONTENT || '',
    };
  });

  return {
    events: aEvents,
    additionalInfoByGuest: aAdditionalInfoByGuest,
    isUserAuthenticated: null,
    reCaptchaToken: window.Cypress ? 'cypress' : '',
  };
};

export const oReviewFormSchema = Yup.object()
  .shape({
    events: Yup.array()
      .of(
        Yup.object().shape({
          guests: Yup.array()
            .of(
              Yup.object().shape({
                REG_OPTION: Yup.object().required('Please select an option.'),
              })
            )
            .min(1, 'Please add at least one guest.')
            .required(),
        })
      )
      .min(1, 'Please add at least one event.'),
    reCaptchaToken: Yup.string().when('isUserAuthenticated', {
      is: false,
      then: () => Yup.string().required('Please complete the reCAPTCHA.'),
    }),
  })
  .concat(oAdditionalInfoFormSchema);

export const fnFormatEventRegistrationDataForDad = (oFormValues) => {
  const aEvents = oFormValues.events.map((oEvent) => {
    const {
      sEventSlug,
      sRegistrationType,
      TITLE,
      LOCATION,
      START_DATE_TIME,
      END_DATE_TIME,
      TIME_ZONE,
      guests,
      sMainEventTitle,
    } = oEvent;
    const aRegistrants = guests.map((oGuest) => {
      const {
        sGuestLookupId,
        sGuestName,
        REG_OPTION,
        FIRST_NAME,
        LAST_NAME,
        EMAIL_ADDRESS,
      } = oGuest;
      let oFormattedGuest = {
        LOOKUPID: sGuestLookupId,
        REG_OPTION,
        sGuestName,
      };
      if (EMAIL_ADDRESS) {
        oFormattedGuest = {
          ...oFormattedGuest,
          FIRST_NAME,
          LAST_NAME,
          EMAIL_ADDRESS,
        };
      }
      return oFormattedGuest;
    });
    return {
      EVENT_SLUG: sEventSlug,
      REGISTRATION_TYPE: sRegistrationType,
      TITLE,
      LOCATION,
      sFormattedEventDateAndTime: fnFormatSubEventDateAndTime(
        START_DATE_TIME,
        END_DATE_TIME,
        TIME_ZONE
      ),
      sFormattedCalendarLink: fnBuildGoogleCalendarLink(oEvent),
      sMainEventTitle,
      REGISTRANTS: aRegistrants,
    };
  });
  const aAdditionalInfoByGuest = oFormValues.additionalInfoByGuest.map(
    (oGuest) => {
      const {
        sGuestLookupId,
        sGuestName,
        dietaryRestrictions,
        otherDietaryRestriction,
        additionalInfo,
      } = oGuest;
      return {
        LOOKUPID: sGuestLookupId,
        sGuestName,
        DIETARY_RESTRICTIONS: dietaryRestrictions,
        OTHER_DIETARY_RESTRICTION: otherDietaryRestriction,
        ADDITIONAL_INFO: additionalInfo,
      };
    }
  );
  const oResult = {
    EVENTS: aEvents,
    ADDITIONAL_INFO_BY_CONSTITUENT: aAdditionalInfoByGuest,
  };
  return oResult;
};
