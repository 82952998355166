import PropTypes from 'prop-types';

/**
 * Determines if the fund has enough financial data to show the chart.
 *
 * @param {array} aData - Fund financial history data.
 * @returns {boolean}
 */
export const fnHasFinancialChartData = (aData) => {
  let bHasChartData = false;
  const oFiscalYearData = aData[0];
  const nStartAmount = parseFloat(oFiscalYearData?.FISCAL_YEAR_BEGINNING_VALUE);
  const nEndAmount = parseFloat(oFiscalYearData?.FISCAL_YEAR_ENDING_VALUE);
  if (
    oFiscalYearData?.VALUE_OVER_TIME.length > 1 ||
    (nStartAmount > 0 && nEndAmount > 0)
  ) {
    bHasChartData = true;
  }
  return bHasChartData;
};

export const recipientPropType = PropTypes.shape({
  BIOGRAPHY: PropTypes.shape({
    NOTEID: PropTypes.string,
    NOTE: PropTypes.string,
    DATEADDED: PropTypes.string,
  }),
  FIRSTNAME: PropTypes.string,
  HAS_PHOTO: PropTypes.bool,
  LASTNAME: PropTypes.string,
  LOOKUPID: PropTypes.string,
  NAME: PropTypes.string,
  STATUS: PropTypes.string,
  THANKYOUNOTES: PropTypes.array,
});

export default {};
