import { object, string } from 'yup';
import * as R from 'ramda';

import { fnLowercaseShallowObjectKeys } from 'utils/common';

export const fnHasAtLeastOneStringValue = (oObject) => {
  let bHasAtLeastOne = false;
  const aKeysWithValues = Object.keys(oObject).filter(
    (sKey) => typeof oObject[sKey] === 'string' && !!oObject[sKey]
  );
  if (aKeysWithValues.length > 0) {
    bHasAtLeastOne = true;
  }
  return bHasAtLeastOne;
};

export const oAdvancedSearchQueryParamSchema = object({
  'first-name': string(),
  'last-name': string(),
  city: string(),
  state: string(),
  country: string(),
  'class-of': string(),
  employer: string(),
  'include-former-employers': string(),
  'area-of-study': string(),
  'academic-program': string(),
  'club-org': string(),
  'affinity-group': string(),
  'job-skill': string(),
})
  // Remove any query params not included in the above list
  .transform((value, originalValue, context) => {
    const oLowercasedObj = fnLowercaseShallowObjectKeys(value);
    const aAllowedQueryParams = Object.keys(context.fields);
    const oValueWithAllowedQueryParams = R.pick(
      aAllowedQueryParams,
      oLowercasedObj
    );
    return oValueWithAllowedQueryParams;
  })
  .test(
    'at-least-one',
    'Provide at least one valid query param for advanced search',
    fnHasAtLeastOneStringValue
  );
