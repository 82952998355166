import { createActions } from 'redux-actions';

export default createActions(
  {
    UPDATE: (action) => action,
  },
  {
    prefix: 'Profile/NOTIFICATIONS',
  }
);
