import { object, string } from 'yup';

import { buildMinMaxMoneySchema } from 'utils/yupHelpers';
import {
  calculatePendingBalance,
  calculateTotalBalancePayablePledges,
} from '../helpers/calculateSelectPaymentTotals';

const buildSelectPaymentSchema = (aPayablePledges) => {
  const oTotalBalance = calculateTotalBalancePayablePledges(aPayablePledges);
  const oPendingBalance = calculatePendingBalance(aPayablePledges);
  const oMaxOtherAmount = oTotalBalance.subtract(oPendingBalance);

  const selectPaymentSchema = object().shape({
    paymentType: string().required('Please choose a payment amount.'),
    otherAmount: string().when('paymentType', ([paymentType]) => {
      if (paymentType === 'other') {
        return string()
          .required('Please enter the amount to pay.')
          .concat(buildMinMaxMoneySchema(1, oMaxOtherAmount.value));
      }
      return string();
    }),
    note: string().when('paymentType', ([paymentType]) => {
      if (paymentType === 'other') {
        return string()
          .required('Please enter a note for how to apply the other amount.')
          .max(255);
      }
      return string();
    }),
  });

  return selectPaymentSchema;
};

export default buildSelectPaymentSchema;
