import qs from 'qs';
import { Auth } from 'aws-amplify';

/**
 * Signs in the user using Google as the identity provider
 *
 * @param {object} oLocation - The location object from react-router-dom
 * @param {string} sIdentityProvider - The Cognito federated identity provider (Google, LoginWithAmazon, Facebook, Apple)
 */
const fnSignIn = (oLocation, sIdentityProvider) => {
  const oSignInConfig = {
    provider: sIdentityProvider,
  };

  if (oLocation.search) {
    const oQueryParams = qs.parse(oLocation.search, {
      ignoreQueryPrefix: true,
    });
    const oCustomState = { redirect: oQueryParams.redirect };
    const sCustomState = JSON.stringify(oCustomState);
    oSignInConfig.customState = sCustomState;
  }

  Auth.federatedSignIn(oSignInConfig);
};

export default fnSignIn;
