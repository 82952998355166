import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fnElementRefPropType } from 'utils/customPropTypes';
import oIconLibrary, {
  aIconTypes,
  aAllIconNames,
} from '../../utils/iconLibrary';

/**
 * Displays an icon-only button using a FontAwesome icon.
 *
 * @param {string} sIcon - FontAwesome icon name.
 * @param {function} fnHandleClick - Click handler.
 * @param {string} sAriaLabel - Describes button action for screen readers.
 * @param {string} sIconType - Determines if the icon is from FontAwesome or a custom icon.
 */
const IconOnlyButton = ({
  sIcon,
  fnHandleClick,
  sAriaLabel,
  className,
  sFlavor,
  sIconType,
  oRef,
  sCypressId,
}) => (
  <button
    type='button'
    className={`button--iconOnly--${sFlavor} ${className}`}
    onClick={fnHandleClick}
    aria-label={sAriaLabel}
    ref={oRef}
    data-cy={sCypressId}
  >
    {sIconType === 'fontAwesome' ? (
      <FontAwesomeIcon icon={oIconLibrary.fontAwesome[sIcon]} />
    ) : (
      <img src={oIconLibrary.wfu[sIcon]} alt='' />
    )}
  </button>
);

export const iconOnlyButtonPropTypes = {
  sIcon: PropTypes.oneOf(aAllIconNames).isRequired,
  fnHandleClick: PropTypes.func.isRequired,
  sAriaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  sFlavor: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  sIconType: PropTypes.oneOf(aIconTypes),
  oRef: fnElementRefPropType,
  sCypressId: PropTypes.string,
};

IconOnlyButton.propTypes = iconOnlyButtonPropTypes;

IconOnlyButton.defaultProps = {
  className: '',
  sFlavor: 'primary',
  sIconType: 'fontAwesome',
  oRef: null,
  sCypressId: '',
};

export default IconOnlyButton;
