import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as oCodeTablesActions from 'components/Main/redux/CodeTables/actions';
import { createSelector } from 'reselect';

const aFetchedList = [];

const fnCodeTablesSelectorFactory = (sType, fnTransform) => {
  const fnBaseSelector = (state) => (state.CodeTables || {})[sType] || [];
  return createSelector(fnBaseSelector, fnTransform);
};

/**
 * Retrieves a code tables section from the Redux store.
 * If the codes aren't in the Redux store, it dispatches
 * an action to fetch the codes from the API.
 *
 * @param {string} sType - CodeTables section
 * @param {function} fnTransform - Optional function to alter Redux result data
 * @returns {array}
 */
const useCodeTablesSection = (sType, fnTransform = (a) => a) => {
  const aCodes = useSelector(fnCodeTablesSelectorFactory(sType, fnTransform));
  const fnDispatch = useDispatch();

  useEffect(() => {
    if (sType && aCodes.length === 0 && !aFetchedList.includes(sType)) {
      fnDispatch(oCodeTablesActions.get(sType));
      aFetchedList.push(sType);
    }
  }, [aCodes, sType, fnDispatch]);

  return aCodes;
};

export default useCodeTablesSection;
