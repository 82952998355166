import React from 'react';
import PropTypes from 'prop-types';
import {
  AutoSizer,
  List,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized';

import MyClassmatesRow from './MyClassmatesRow';

const MyClassmatesList = ({ classmates, virtualizedListRef }) => {
  const cellHeightCache = new CellMeasurerCache({
    defaultHeight: 125,
    fixedWidth: true,
  });

  const rowRenderer = ({ index, parent, key, style }) => (
    <CellMeasurer
      key={key}
      cache={cellHeightCache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <div className='myClassmates__row' style={{ ...style }}>
        <MyClassmatesRow data={classmates[index]} />
      </div>
    </CellMeasurer>
  );

  return (
    <div className='classmates__listWrapper'>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={virtualizedListRef}
            width={width}
            height={height}
            rowCount={classmates.length}
            rowHeight={cellHeightCache.rowHeight}
            deferredMeasurementCache={cellHeightCache}
            rowRenderer={rowRenderer}
            overscanRowCount={5}
          />
        )}
      </AutoSizer>
    </div>
  );
};

MyClassmatesList.propTypes = {
  classmates: PropTypes.array,
};

MyClassmatesList.defaultProps = {
  classmates: [],
};

export default MyClassmatesList;
