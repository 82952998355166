import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import useWindowSize from 'utils/hooks/useWindowSize';

const MatchList = ({
  aMatches,
  handleMatchClick,
  sSelectedConstituentLookupId,
  fnHandleClearSearch,
}) => {
  const oWindowSize = useWindowSize();
  return aMatches.length > 0 ? (
    <>
      <h2>Great! Let&apos;s find you in our system.</h2>
      <div className='matchList__wrapper'>
        <p>Select your name below.</p>
        <ul className='matchList__matches'>
          {aMatches.map((item) => (
            <li key={item.LOOKUPID}>
              <Button
                sFlavor='secondary'
                className={`matchList__matchBtn ${
                  sSelectedConstituentLookupId === item.LOOKUPID
                    ? 'matchList__matchBtn--selected'
                    : ''
                }`}
                fnHandleClick={() => handleMatchClick(item)}
              >
                {item.FULL_OUTPUT}
              </Button>
            </li>
          ))}
        </ul>
        <Link className='matchList__notMe' to='/sign-up/help/nomatch'>
          None of these are me
        </Link>
        <Button
          sFlavor='underline'
          className='matchList__searchAgain'
          fnHandleClick={fnHandleClearSearch}
        >
          Search Again
        </Button>
      </div>
    </>
  ) : (
    <>
      <h2>Oh no, we didn&apos;t find you in our system!</h2>
      <Button
        sFlavor={oWindowSize.width < 640 ? 'underline' : 'primary'}
        className='matchList__noMatchesFoundButton'
        fnHandleClick={fnHandleClearSearch}
      >
        Search Again
      </Button>
    </>
  );
};

MatchList.propTypes = {
  aMatches: PropTypes.array.isRequired,
  handleMatchClick: PropTypes.func.isRequired,
  sSelectedConstituentLookupId: PropTypes.string,
  fnHandleClearSearch: PropTypes.func.isRequired,
};

MatchList.defaultProps = {
  sSelectedConstituentLookupId: '',
};

export default MatchList;
