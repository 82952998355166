import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Formik, Form } from 'formik';
import Button from 'components/Buttons/Button';
import { SubmitButton } from 'components/Form/Buttons';
import * as actions from '../../../redux/AllClassmates/actions';

import { ToggleSwitch, Select } from '../../../../Form/Fields';
import useCloseOnOutsideClick from '../../../../../utils/hooks/useCloseOnOutsideClick';
import MyClassmatesAdvSearchOptionsBuilder from './helpers/MyClassmatesAdvSearchOptionsBuilder';
import MyClassmatesSearchEngine from './helpers/MyClassmatesSearchEngine';
import MyClassmatesSearchToggle from './MyClassmatesSearchToggle';
import AdvancedSearchFields from '../../../../Search/elements/AdvancedSearch/AdvancedSearchFields';

const initialValues = {
  firstname: '',
  lastname: '',
  city: '',
  state: { ID: null, NAME: '' },
  classYear: {
    label: null,
    value: null,
    ID: null,
  },
  employer: null,
  educationalprogram: { ID: null, NAME: '' },
  educationalinvolvement: { ID: null, DESCRIPTION: '' },
  educationaldepartment: { ID: null, NAME: '' },
  affinitygroup: { ID: null, DESCRIPTION: '' },
  claimableOnly: false,
};

const advSearchOptionsBuilder = new MyClassmatesAdvSearchOptionsBuilder([]);
const searchEngine = new MyClassmatesSearchEngine();

const MyClassmatesSearch = ({
  handleClassYearChange,
  setClassmatesToShow,
  virtualizedListRef,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const allClassmates = useSelector(
    (state) => (state.AllClassmates || {}).data || []
  );
  const allClassYears = useSelector(
    (state) => (state.AllClassmates || {}).options || []
  );
  const myClassmatesSearchRef = useRef();

  const classYearOptions = (allClassYears || []).map((item) => ({
    label: item,
    value: item,
    ID: item,
  }));

  const dispatch = useDispatch();
  advSearchOptionsBuilder.classmateList = allClassmates;
  const advSearchOptions = advSearchOptionsBuilder.getAdvSearchOptions();

  const handleSearchSubmit = async (searchValues) => {
    dispatch(
      actions.set({
        activeClassmatesFilters: { advSearchCriteria: searchValues },
      })
    );
    const results = await searchEngine.search(searchValues, allClassmates);
    setClassmatesToShow(results);

    // Scroll the results list back to the top
    virtualizedListRef.current.scrollToRow(0);

    // Close search when user clicks submit with new search criteria
    // not when they clear the form
    const isNewSearch = !R.equals(searchValues, initialValues);
    if (isNewSearch) {
      setShowSearch(false);
    }
  };

  const handleReset = () => {
    dispatch(actions.set({ activeClassmatesFilters: null }));
    setClassmatesToShow(allClassmates);
  };

  useCloseOnOutsideClick(myClassmatesSearchRef, setShowSearch);

  return (
    <div
      className={`myClassmatesSearch ${
        showSearch ? 'myClassmatesSearch--open' : ''
      }`}
      ref={myClassmatesSearchRef}
    >
      <MyClassmatesSearchToggle
        setShowSearch={setShowSearch}
        showSearch={showSearch}
      />
      <div className='myClassmates__searchContent'>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSearchSubmit}
          onReset={handleReset}
        >
          {(formikProps) => (
            <Form autoComplete='off' className='myClassmates__searchForm'>
              <div
                className={`myClassmatesSearch__classYearClaimableToggleWrap ${
                  classYearOptions.length > 1
                    ? 'myClassmatesSearch__classYearClaimableToggleWrap--withClassSelector'
                    : ''
                }`}
              >
                {classYearOptions.length > 1 && (
                  <div className='myClassmatesSearch__classYearSelect'>
                    <Select
                      label='Class Year'
                      options={classYearOptions}
                      name='classYear'
                      isLabelHidden
                      onChange={handleClassYearChange}
                    />
                  </div>
                )}
                <div className='myClassmatesSearch_viewClaimableToggle'>
                  <ToggleSwitch
                    sId='classmates-search-claimable'
                    sName='claimableOnly'
                    sLabel='View Claimable'
                  />
                </div>
              </div>
              <AdvancedSearchFields
                advSearchOptions={advSearchOptions}
                bIsClassmatesSearch
              />
              <div className='advancedSearch__formButtonWrapper'>
                <SubmitButton>Search</SubmitButton>
                <Button
                  sType='reset'
                  fnHandleClick={handleReset}
                  sFlavor='secondary'
                >
                  Clear Search
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

MyClassmatesSearch.propTypes = {
  handleClassYearChange: PropTypes.func.isRequired,
  setClassmatesToShow: PropTypes.func.isRequired,
};

export default MyClassmatesSearch;
