import React from 'react';
import PropTypes from 'prop-types';

import { fnElementRefPropType } from 'utils/customPropTypes';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { TextField } from './TextField';
import { Select } from './Select';

export const InternationalPhoneField = ({
  countryFieldOptions,
  phoneFieldOptions,
  groupLabel,
}) => {
  const aCountryOptions = useCodeTablesSection('COUNTRY', (aCodes) => {
    let aSortedCodes = [];
    if (aCodes.length > 0) {
      // Put the United States country code first, since that's where most of our users are located
      const oUsCountryCode = aCodes.find((item) => item.DETAILS.ABBRV === 'US');
      const aOtherCountryCodes = aCodes.filter(
        (item) => item.DETAILS.ABBRV !== 'US'
      );
      aSortedCodes = [oUsCountryCode, ...aOtherCountryCodes];
    }

    return aSortedCodes;
  });

  return (
    <fieldset className='intlPhoneField'>
      <legend className='hide_accessibly'>{groupLabel}</legend>
      <Select
        id={countryFieldOptions.id}
        name={countryFieldOptions.name}
        label={countryFieldOptions.label}
        fieldRef={countryFieldOptions.fieldRef}
        options={aCountryOptions}
        getOptionLabel={(option) => option?.DETAILS?.NAME_ABBRV}
      />
      <TextField
        sId={phoneFieldOptions.id}
        sName={phoneFieldOptions.name}
        sLabel={phoneFieldOptions.label}
        sPlaceholder={phoneFieldOptions.placeholder}
      />
    </fieldset>
  );
};

InternationalPhoneField.propTypes = {
  groupLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  countryFieldOptions: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fieldRef: fnElementRefPropType,
  }).isRequired,
  phoneFieldOptions: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }).isRequired,
};

InternationalPhoneField.defaultProps = {
  groupLabel: 'Phone Number',
};

export default InternationalPhoneField;
