import React from 'react';
import PropTypes from 'prop-types';
import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';
import InvolvementsViewMode from 'components/MyProfile/elements/Education/Involvements/viewMode';

const Involvements = ({ aInvolvements }) => (
  <ProfileSubsectionLayout
    sTitle='Involvements'
    right={<InvolvementsViewMode aInvolvements={aInvolvements} />}
  />
);

export default Involvements;

Involvements.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  aInvolvements: PropTypes.array.isRequired,
};

InvolvementsViewMode.defaultProps = {
  aInvolvements: [],
};
