import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '../../../Form/Fields';

const WfuIdField = ({ matchType, sSelectedValidationType }) => {
  let wfuIdInstructions = 'Enter your Deacon Token (no spaces)';
  let sInputLabel = 'Deacon Token';
  if (sSelectedValidationType !== 'deacontoken') {
    switch (matchType) {
      case 'parent':
        sInputLabel = "Student's ID";
        wfuIdInstructions = "Enter your student's ID";
        break;
      case 'staff':
        sInputLabel = 'Employee ID';
        wfuIdInstructions = 'Enter your employee ID';
        break;
      case 'student':
      case 'alumni':
      default:
        sInputLabel = 'Student ID';
        wfuIdInstructions = 'Enter your student ID';
    }
  }

  return (
    <>
      <h2>
        Welcome back! To keep your info safe, we just need you to verify your
        identity.
      </h2>
      <p>{wfuIdInstructions}:</p>
      <TextField
        sId='bannerid'
        sLabel={sInputLabel}
        sName='bannerid'
        sPlaceholder='#######'
      />
    </>
  );
};

WfuIdField.propTypes = {
  matchType: PropTypes.string.isRequired,
  sSelectedValidationType: PropTypes.string.isRequired,
};

export default WfuIdField;
