import React from 'react';
import PropTypes from 'prop-types';

import { oCurrencyFormatter } from 'utils/common';

const GiftTotal = ({ aGifts }) => {
  const nRawTotal = aGifts.reduce((nTotal, oGift) => {
    if (oGift.amount) {
      // eslint-disable-next-line no-param-reassign
      nTotal += parseFloat(oGift.amount.replaceAll(',', '')); // Amounts over $999 will have commas to separate thousands
    }
    return nTotal;
  }, 0);

  return (
    <p className='givingCheckout__total t-paragraph--large'>
      Gift Total: {oCurrencyFormatter.format(nRawTotal)}
    </p>
  );
};

GiftTotal.propTypes = {
  aGifts: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
    })
  ),
};

GiftTotal.defaultProps = {
  aGifts: [],
};

export default GiftTotal;
