import React, { useState } from 'react';

import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { AddItemButton } from '../../../../Form/AddItemButton';
import useActions from '../../../../../utils/hooks/useActions';
import QuickFindModalForm from '../../../../Form/QuickFindModalForm';
import actions from './redux/actions';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const NewInvolvementForm = () => {
  const [bIsNewItemFormVisible, fnSetNewItemFormVisible] = useState(false);
  const aInvolvementTypes = useCodeTablesSection('INVOLVEMENT');
  const involvementActions = useActions(actions);
  const [initialValues, setInitialValues] = useState({ involvement: null });

  const fnHandleReset = () => {
    fnSetNewItemFormVisible(false);
    setInitialValues({ involvement: null });
  };

  const fnHandleSubmit = async (oFormData) => {
    const oFormattedData = {
      PRIVACY: oFormData.involvement.PRIVACY,
      ID: oFormData.involvement.ID,
    };
    try {
      const sResult = await new Promise((resolve, reject) => {
        involvementActions.create({ data: oFormattedData, resolve, reject });
      });
      if (sResult === 'Success') {
        fnSetNewItemFormVisible(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fnToggleAddInvolvementVisible = () => {
    fnSetNewItemFormVisible(!bIsNewItemFormVisible);
  };
  return (
    <ProfileRecordLayout
      left={
        <>
          <AddItemButton
            sLabel='Add Involvement'
            fnHandleClick={fnToggleAddInvolvementVisible}
            className='addRecordButton'
          />
          {bIsNewItemFormVisible && (
            <QuickFindModalForm
              onReset={fnHandleReset}
              onSubmit={fnHandleSubmit}
              initialValues={initialValues}
              formikFieldToUpdate='involvement'
              options={aInvolvementTypes}
              setIsFormVisible={fnSetNewItemFormVisible}
              placeholder='Involvement'
              sLabel='Involvement'
            />
          )}
        </>
      }
    />
  );
};

export default NewInvolvementForm;
