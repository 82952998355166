import { object, string } from 'yup';

const helpRequestSchema = object({
  firstname: string().max(50).required('Please enter a firstname'),
  lastname: string().max(50).required('Please enter a lastname'),
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  comments: string().required('Please enter some details'),
  reCaptchaToken: string().required('Please complete the reCAPTCHA'),
});

export default helpRequestSchema;
