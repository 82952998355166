import * as R from 'ramda';
import { pascalCase } from 'change-case';
import fuzzysort from 'fuzzysort';
import currency from 'currency.js';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { createRoot } from 'react-dom/client';

import { guid } from './matchers';

const sStage = process.env.REACT_APP_STAGE;

export const arrayCast = (values) =>
  Array.isArray(values) ? values : [values];

/**
 * Retrieves primary record from list.
 *
 * @param {array} aRecords - List of records to search.
 * @returns Primary record or undefined if not found.
 */
export const fGetPrimaryRecord = (aRecords = []) => {
  if (aRecords.length === 0) {
    return null;
  }
  return R.find(R.propEq(true, 'PRIMARY'))(aRecords);
};

export const groupByPrimary = (data) =>
  R.groupBy((item) => (item.PRIMARY ? 'primary' : 'secondary'))(data);

export const findByKey = (key, value, data) =>
  R.find(R.propEq(value, key))(data);

export const isUnique = (key, values, dataArray) =>
  !findByKey(key, values[key], dataArray);

export const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

export const toPascalCase = (string) => pascalCase(string);

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const isEmpty = (obj) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

/**
 * Creates a new object with the own properties of the provided object, but the
 * keys renamed according to the keysMap object as `{oldKey: newKey}`.
 * When some key is not found in the keysMap, then it's passed as-is.
 *
 * Keep in mind that in the case of keys conflict is behaviour undefined and
 * the result may vary between various JS engines!
 *
 * @sig {a: b} -> {a: *} -> {b: *}
 */
export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce(
    (acc, key) => R.assoc(keysMap[key] || key, obj[key], acc),
    {},
    R.keys(obj)
  )
);

/**
 * Sorts a list of objects by a given key in place.
 *
 * @param {array} aDataToSort - List of objects to sort.
 * @param {string} sKey - Key to sort by.
 * @param {boolean} bSortOrder - Sort order; true = ascending A–Z, false = descending Z–A.
 *
 * @returns {array}
 */
export const sortByKey = (aDataToSort, sKey, bSortOrder = true) =>
  aDataToSort.sort((a, b) => {
    const x = a[sKey];
    const y = b[sKey];
    if (bSortOrder) {
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    }
    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  });

// bSortOrder true = ascending, false = descending
export const sortByKeyAsNumber = (array, key, bSortOrder) => {
  const copyArray = [...array];
  copyArray.sort((a, b) => {
    const x = parseInt(a[key]);
    const y = parseInt(b[key]);
    if (bSortOrder) {
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    }
    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  });
  return copyArray;
};

/**
 * Runs a fuzzy search and provides results via a cancellable promise.
 *
 * @param {array} aSuggestions - Items to search.
 * @param {string|array} mSearchKey - Key(s) within item to search against.
 * @param {string} sQuery - Text to search.
 * @param {integer} iMaxResults - Max number of results.
 * @returns {array} - Search results.
 */
export const fnFuzzySearch = (
  aSuggestions = [],
  mSearchKey = '',
  sQuery = '',
  iMaxResults = 25
) => {
  if (aSuggestions.length === 0 || sQuery.length === 0) {
    console.warn(
      'Fuzzy search requires both a list of suggestions to search and a query.'
    );
    return null;
  }
  const oOptions = {
    keys: arrayCast(mSearchKey),
    limit: iMaxResults,
    threshold: 0.25,
  };
  const aResults = fuzzysort.go(sQuery, aSuggestions, oOptions);
  return aResults;
};

export const buildCityStateEmployerString = (data) => {
  const hasCity = !R.isEmpty(data.CITY);
  const hasState = !R.isEmpty(data.STATE);
  let cityStateString = '';
  if (hasCity && hasState) {
    cityStateString = `${data.CITY}, ${data.STATE}`;
  } else if (hasCity || hasState) {
    cityStateString = data.CITY || data.STATE;
  }

  let employerString = '';
  if (!R.isEmpty(data.EMPLOYER) && data.EMPLOYER !== 'Unknown Employer') {
    employerString = data.EMPLOYER;
  }

  let combined = '';
  if (cityStateString && employerString) {
    combined = `${cityStateString} | ${employerString}`;
  } else {
    combined = cityStateString || employerString;
  }
  return combined;
};

/**
 * Triggers a Google Tag Manager event.
 *
 * @param string sCategory - The object that was interacted with. ie: 'philanthropy: myGiving'
 * @param string sAction - The type of interaction. ie: 'accordion expanded'.
 * @param string sLabel - Optional. Useful for categorizing events or adding detail.
 */
export const triggerGoogleTagManagerEvent = (
  sCategory,
  sAction,
  sLabel = ''
) => {
  if (sStage === 'prod') {
    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: `${toPascalCase(sCategory || '')}`,
        action: sAction,
        label: sLabel,
      },
    });
  }
};

export const oCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 * Calculates monetary total from list of objects.
 *
 * @param {array} aItems - List of objects containing money amounts.
 * @param {string} sKey - Object key that holds the money amount.
 * @returns {object}
 */
export const calculateMonetarySumByKey = (aItems = [], sKey = '') => {
  if (!sKey) {
    console.warn('No key provided to sum');
    return currency(0);
  }
  const oTotal = aItems.reduce(
    (oAccumulator, oItem) => oAccumulator.add(currency(oItem[sKey])),
    currency(0)
  );
  return oTotal;
};

/**
 * Converts a phone number to E.164 format
 *
 * @param {string} sPhoneNumber - User provided phone number.
 * @param {string} sISO3166CountryAbbreviation - ISO-3166-1 Country/Region abbreviation.
 * @returns {string}
 */
export const fnPhoneNumberFormatterE164 = (
  sPhoneNumber,
  sISO3166CountryAbbreviation
) => {
  const oParsedNumber = PhoneNumberUtil.getInstance().parse(
    sPhoneNumber,
    sISO3166CountryAbbreviation
  );

  return PhoneNumberUtil.getInstance().format(
    oParsedNumber,
    PhoneNumberFormat.E164
  );
};

/**
 * Determines if a provided phone number is valid for the region.
 *
 * @param {string} sPhoneNumber - User provided phone number.
 * @param {string} sISO3166CountryAbbreviation - ISO-3166-1 Country/Region abbreviation.
 * @returns {boolean}
 */

export const fnIsPhoneNumberValidForRegion = (
  sPhoneNumber,
  sISO3166CountryAbbreviation
) => {
  try {
    const oParsedNumber = PhoneNumberUtil.getInstance().parseAndKeepRawInput(
      sPhoneNumber,
      sISO3166CountryAbbreviation
    );
    const bIsPhoneNumberValidForRegion =
      PhoneNumberUtil.getInstance().isValidNumberForRegion(
        oParsedNumber,
        sISO3166CountryAbbreviation
      );
    return bIsPhoneNumberValidForRegion;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Converts a phone number to the "pretty"/masked format for the region provided.
 *
 * @param {string} sPhoneNumber - User provided phone number.
 * @param {string} sISO3166CountryAbbreviation - ISO-3166-1 Country/Region abbreviation.
 * @example of return - 3365551234 converts to (336) 555-1234
 * @returns {string}
 */
export const fnPrettyFormatPhoneNumberForRegion = (
  sPhoneNumber,
  sISO3166CountryAbbreviation
) => {
  try {
    const oParsedNumber = PhoneNumberUtil.getInstance().parseAndKeepRawInput(
      sPhoneNumber,
      sISO3166CountryAbbreviation
    );
    const sPrettyPhoneNumber = PhoneNumberUtil.getInstance().format(
      oParsedNumber,
      PhoneNumberFormat.NATIONAL
    );
    return sPrettyPhoneNumber;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Measures an element's width and height without rendering it visibly on screen.
 *
 * @param {*} element - Element(s) to measure.
 * @param {*} iMaxWidth - Maximum width in pixels.
 * @returns {Promise}
 */
export const fnMeasureElement = (element, iMaxWidth) => {
  // Creates the hidden div appended to the document body
  const container = document.createElement('div');
  container.style.visibility = 'hidden';
  container.style.zIndex = -1;
  if (iMaxWidth) {
    container.style.maxWidth = `${iMaxWidth}px`;
  }
  const rootDiv = document.getElementById('root');
  rootDiv.appendChild(container);

  // Renders the React element into the hidden div
  const root = createRoot(container);
  root.render(element);

  const oPromise = new Promise((resolve) => {
    // Wait for the element to be rendered
    requestAnimationFrame(() => {
      const iHeight = container.offsetHeight;
      const iWidth = container.offsetWidth;

      // Removes the element and its wrapper from the document
      root.unmount();
      container.parentNode.removeChild(container);

      resolve({ height: iHeight, width: iWidth });
    });
  });

  return oPromise;
};

/**
 * Determines if a given date and time is within a given date and time range.
 *
 * @example
 * const b = fnIsDateWithinRange('2022-01-05T00:00', '2022-01-01T00:00', '2022-01-25T00:00');
 * console.log(b); // true
 *
 * @param {string} sDateTime - Date and time to be checked.
 * @param {*} sStartDateTime - Date range start date and time.
 * @param {*} sEndDateTime - Date range end date and time.
 * @returns {bool}
 */
export const fnIsDateTimeWithinRange = (
  sDateTime,
  sStartDateTime = '',
  sEndDateTime = ''
) => {
  let bResult = false;

  const oDate = new Date(sDateTime);
  const oStart = sStartDateTime ? new Date(sStartDateTime) : null;
  const oEnd = sEndDateTime ? new Date(sEndDateTime) : null;

  if (oStart && oEnd) {
    // Check if the date is between the start and end dates
    if (oDate >= oStart && oDate < oEnd) {
      bResult = true;
    }
  } else if (oStart) {
    // There's only a start date, so see if the date is after it
    if (oDate >= oStart) {
      bResult = true;
    }
  } else if (oEnd) {
    // There's only an end date, so see if the date is before it
    if (oDate < oEnd) {
      bResult = true;
    }
  }

  return bResult;
};

/**
 * Truncates the given text to less than the max number of characters without splitting up words.
 *
 * @param {string} sText - Text to truncate.
 * @param {number} iMaxCharacters - Max number of characters, including spaces.
 * @returns {string}
 */
export const fnTruncateBetweenWords = (sText, iMaxCharacters) => {
  if (sText.length <= iMaxCharacters) {
    return sText;
  }
  let sResult = sText.substring(0, iMaxCharacters);
  const iLastSpaceIndex = sResult.split('').lastIndexOf(' ');
  sResult = sResult.substring(0, iLastSpaceIndex);
  return sResult;
};

/**
 * Lowercases the **first-level** of keys in a given object.
 *
 * @param {object} oObj - Object that needs its first-level keys lowercased
 * @returns {object}
 */
export const fnLowercaseShallowObjectKeys = (oObj) =>
  Object.keys(oObj).reduce((oLowercasedObj, sKey) => {
    // eslint-disable-next-line no-param-reassign
    oLowercasedObj[sKey.toLowerCase()] = oObj[sKey];
    return oLowercasedObj;
  }, {});

/**
 * Finds a given string within an array of objects.
 * Searches for exact matches for lookup IDs or GUIDs;
 * fuzzy searches for descriptions.
 *
 * @param {string} sQuery - ID or description to search for
 * @param {array} aItems - List of objects to search in
 * @returns {Promise}
 */
export const fnFindItemByIdOrDescription = async (sQuery, aItems) => {
  let oResult = null;
  if (sQuery && aItems.length > 0) {
    if (guid.test(sQuery)) {
      oResult = findByKey('ID', sQuery, aItems);
    } else {
      const oSearchResult = fnFuzzySearch(aItems, 'DESCRIPTION', sQuery, 1);
      if (oSearchResult.length > 0) {
        oResult = oSearchResult[0].obj;
      }
    }
  }
  return oResult;
};

/**
 * Converts a time zone abbreviation to an IANA time zone.
 * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * @param {string} sTimeZoneAbbr - Time zone abbreviation
 * @returns {string}
 */
export const fnConvertTimeZoneAbbrToIana = (sTimeZoneAbbr) => {
  if (!sTimeZoneAbbr) {
    console.warn('No time zone abbreviation provided');
    return '';
  }

  const oTimeZoneMap = {
    AKT: 'America/Anchorage', // Alaska Time
    CT: 'America/Chicago', // Central Time
    ET: 'America/New_York', // Eastern Time
    HAT: 'Pacific/Honolulu', // Hawaii-Aleutian Time
    MT: 'America/Denver', // Mountain Time
    PT: 'America/Los_Angeles', // Pacific Time
    SST: 'Pacific/Samoa', // Samoa Standard Time
  };

  if (!oTimeZoneMap[sTimeZoneAbbr]) {
    console.warn(
      `No IANA time zone found for the abbreviation "${sTimeZoneAbbr}"`
    );
    return '';
  }

  return oTimeZoneMap[sTimeZoneAbbr];
};
