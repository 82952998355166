import React from 'react';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import DateAndTime from 'date-and-time';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SubmitButton } from 'components/Form/Buttons';
import Button from 'components/Buttons/Button';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import RegistrationWindowClosed from '../RegistrationWindowClosed';

const MultiLevelEventDetailsFooter = ({
  oEvent,
  fnSetShowSignInOrContinueAsGuestModal,
  fnSetChosenEvents,
}) => {
  const fnNavigate = useNavigate();
  const formikProps = useFormikContext();
  const bIsAuthenticated = useIsUserAuthenticated();

  const bIsRegistrationWindowClosed = oEvent.SUB_EVENTS[0].REGISTRATION
    .CLOSE_DATETIME
    ? DateAndTime.parse(
        oEvent.SUB_EVENTS[0].REGISTRATION.CLOSE_DATETIME,
        'YYYY-MM-DDTHH:mm:ss'
      ) < new Date()
    : false;
  const bHasRegistrableSubEvents = oEvent.SUB_EVENTS.some((oSubEvent) => {
    // This sub event could just be a grouping, so check its sub events
    if (oSubEvent.SUB_EVENTS) {
      return oSubEvent.SUB_EVENTS.some(
        (oSubSubEvent) =>
          oSubSubEvent.REGISTRATION.IS_REGISTERED === 0 &&
          !oSubSubEvent.IS_CANCELLED
      );
    }
    return (
      oSubEvent.REGISTRATION.IS_REGISTERED === 0 && !oSubEvent.IS_CANCELLED
    );
  });

  let continueButtonMarkup = null;
  switch (true) {
    // @todo: remove this case after Homecoming moves to WakeNetwork event registration
    case oEvent.LOOKUPID === '8-10010554': // Homecoming 2024
      continueButtonMarkup = (
        <a
          href='https://registration.secure.wfu.edu/homecoming/events'
          target='_blank'
          className='button'
          rel='noreferrer'
          style={{ color: 'white', display: 'block' }}
        >
          Register for Homecoming events
        </a>
      );
      break;
    case bIsRegistrationWindowClosed:
      continueButtonMarkup = (
        <RegistrationWindowClosed
          sCloseDateTime={oEvent.SUB_EVENTS[0].REGISTRATION.CLOSE_DATETIME}
          sTimeZone={oEvent.TIME_ZONE}
        />
      );
      break;
    case bIsAuthenticated && bHasRegistrableSubEvents:
      continueButtonMarkup = (
        <SubmitButton
          className='multiLevelEventDetails__continue'
          sCypressId='continue-button'
        >
          Continue
        </SubmitButton>
      );
      break;
    case !bIsAuthenticated:
      continueButtonMarkup = (
        <Button
          fnHandleClick={() => {
            // Make sure the user has selected at least one event
            formikProps.validateForm().then((oErrors) => {
              if (R.isEmpty(oErrors)) {
                const aChosenSubEvents = formikProps.values.subEvents.map(
                  (sSubEvent) => JSON.parse(sSubEvent)
                );
                fnSetChosenEvents(aChosenSubEvents);
                fnSetShowSignInOrContinueAsGuestModal(true);
              }
            });
          }}
          className='multiLevelEventDetails__continue'
          sCypressId='continue-button'
        >
          Continue
        </Button>
      );
      break;
    default:
      break;
  }

  return (
    <div className='multiLevelEventDetails__backContinue'>
      {bIsAuthenticated && (
        <Button sFlavor='secondary' fnHandleClick={() => fnNavigate(-1)}>
          Back
        </Button>
      )}
      <div className='multiLevelEventDetailsFooter__right'>
        {continueButtonMarkup}
      </div>
    </div>
  );
};

MultiLevelEventDetailsFooter.propTypes = {
  oEvent: PropTypes.shape({
    TIME_ZONE: PropTypes.string.isRequired,
    SUB_EVENTS: PropTypes.arrayOf(
      PropTypes.shape({
        ID: PropTypes.string.isRequired,
        TITLE: PropTypes.string.isRequired,
        REGISTRATION: PropTypes.shape({
          IS_REGISTERED: PropTypes.number,
          CLOSE_DATETIME: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
  }).isRequired,
  fnSetShowSignInOrContinueAsGuestModal: PropTypes.func.isRequired,
  fnSetChosenEvents: PropTypes.func.isRequired,
};

export default MultiLevelEventDetailsFooter;
