import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import ViewMoreButton from 'components/Layout/ViewMoreButton';
import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';
import NameEditMode from 'components/MyProfile/elements/Personal/Name/NameEditMode';
import {
  profileExternalViewAttendNameActions,
  profileExternalViewNameActions,
} from 'components/ProfileExternalView/redux/actions';
import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import NameViewMode from '../../../MyProfile/elements/Personal/Name/viewMode';
import { arrayCast } from '../../../../utils/common';

const generateSelector = (aRecordPath) => (state) =>
  R.path(aRecordPath, state.ProfileExternalView) || [];

const nameDataSelector = (aRecordPath) =>
  createSelector(generateSelector(aRecordPath), (data) => {
    const aData = arrayCast(data);
    return aData.map((oItem) =>
      R.pick(
        [
          'ATTENDNAME',
          'LASTNAME',
          'FIRSTNAME',
          'MIDDLENAME',
          'TITLE',
          'NICKNAME',
          'PROFESSIONALSUFFIX',
          'PERSONALSUFFIX',
          'LOOKUPID',
          'PRIVACY',
          'NAME',
          'DECEASEDDATE',
        ],
        oItem
      )
    );
  });

export const Name = ({ recordPath, title, allowMultiple, name }) => {
  const aNameData = useSelector(nameDataSelector(recordPath));
  const [activeFormSection] = useActiveFormSection();

  const [bIsExpanded, fnSetIsExpanded] = useState(false);
  const [bIsEditMode, setIsEditMode] = useState(false);

  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      setIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      setIsEditMode(false);
    }
  }, [activeFormSection]);

  const bIsNamePublic = aNameData.some(
    (n) => n.PRIVACY === '0' || n.PRIVACY === undefined
  );

  const bHasAttendName = aNameData.some(
    (nameDataItem) => nameDataItem.ATTENDNAME?.ID
  );

  return (
    bIsNamePublic && (
      <ProfileSubsectionLayout
        sTitle={title}
        left={
          bHasAttendName && (
            <ViewMoreButton
              fnOnClick={fnToggleExpanded}
              bIsShowingMore={bIsExpanded}
            />
          )
        }
        right={
          bIsEditMode ? (
            <NameEditMode
              data={aNameData}
              bIsExpanded={bIsExpanded}
              name={name}
              actions={profileExternalViewNameActions}
              attendNameActions={profileExternalViewAttendNameActions}
              bIsPrivacyChangeable={false}
              bAllowMultiple={false}
              sTitle='Name'
            />
          ) : (
            <NameViewMode
              data={aNameData}
              bIsExpanded={bIsExpanded}
              title={title}
              allowMultiple={allowMultiple}
              name={name}
            />
          )
        }
      />
    )
  );
};

Name.defaultProps = {
  recordPath: ['BIOGRAPHICAL'],
  title: 'Name',
  allowMultiple: false,
};

Name.propTypes = {
  recordPath: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  allowMultiple: PropTypes.bool,
};

export default Name;
