import { Formik, Form } from 'formik';
import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { TextField } from 'components/Form/Fields';
import { SubmitButton } from 'components/Form/Buttons';

const oSchema = yup.object().shape({
  firstName: yup.string().required('Please enter a first name'),
  lastName: yup.string().required('Please enter a last name'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
});

const AddConstituentForm = ({
  oInitialValues,
  fnHandleSubmit,
  sSubmitButtonLabel,
}) => (
  <Formik
    initialValues={oInitialValues}
    validationSchema={oSchema}
    enableReinitialize
    onSubmit={fnHandleSubmit}
  >
    <Form className='addConstituentForm'>
      <TextField sId='firstName' sName='firstName' sLabel='First Name' />
      <TextField sId='lastName' sName='lastName' sLabel='Last Name' />
      <TextField
        sId='email'
        sName='email'
        sLabel='Email'
        sCypressId='add-constituent-form-email'
      />
      <SubmitButton bIsCentered sCypressId='add-constituent-form-submit'>
        {sSubmitButtonLabel}
      </SubmitButton>
    </Form>
  </Formik>
);
AddConstituentForm.propTypes = {
  oInitialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  fnHandleSubmit: PropTypes.func.isRequired,
  sSubmitButtonLabel: PropTypes.string.isRequired,
};

AddConstituentForm.defaultProps = {
  oInitialValues: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

export default AddConstituentForm;
