import React from 'react';
import { useLocation } from 'react-router-dom';

import waitChapelIllustration from './wait-chapel.png';

const Downtime = () => {
  const oLocation = useLocation();
  const { sMessage } = oLocation.state;

  return (
    <div
      className='downtime__wrapper'
      style={{ backgroundImage: `url('${waitChapelIllustration}')` }}
    >
      <div className='downtime__container'>
        <h1 className='downtime__message'>
          WakeNetwork is Down for Maintenance
        </h1>
        {sMessage && <h2 className='downtime__message'>{sMessage}</h2>}
      </div>
    </div>
  );
};

export default Downtime;
