import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Collapsible from 'react-collapsible';

import ProfileSection from 'components/MyProfile/elements/layout/ProfileSection';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import useIsUserViewingSpouseProfile from 'utils/hooks/useIsUserViewingSpouseProfile';
import { Name } from './Name';
import Phone from './Phone';
import Email from './Email';
import Address from './Address';

export const PersonalSection = () => {
  const profileData = useSelector((state) => state.ProfileExternalView);
  const [bIsMoreExpanded, fnSetIsMoreExpanded] = useState(false);
  const fnToggleMoreExpanded = () => fnSetIsMoreExpanded(!bIsMoreExpanded);
  // Only show the more/less button when the constituent has a public spouse
  const bShouldShowMoreButton =
    profileData.SPOUSE && profileData.SPOUSE.PRIVACY === '0';
  const bIsUserViewingSpouseProfile = useIsUserViewingSpouseProfile();

  return (
    <ProfileSection
      sName='personal'
      sTitle='Personal'
      bIsEditable={bIsUserViewingSpouseProfile}
    >
      <Name name='name' />
      {(profileData.PHONE.length > 0 || bIsUserViewingSpouseProfile) && (
        <Phone />
      )}
      {(profileData.EMAIL.length > 0 || bIsUserViewingSpouseProfile) && (
        <Email />
      )}
      {(profileData.ADDRESS.length > 0 || bIsUserViewingSpouseProfile) && (
        <Address />
      )}
      {bShouldShowMoreButton && (
        <>
          <div
            className={`personal__moreBtnWrapper ${
              bIsMoreExpanded ? 'personal__moreBtnWrapper--expanded' : ''
            }`}
          >
            <ButtonWithIcon
              fnHandleClick={fnToggleMoreExpanded}
              sIcon={bIsMoreExpanded ? 'collapse' : 'expand'}
              sIconPosition='right'
            >
              {bIsMoreExpanded ? 'Less' : 'More'}
            </ButtonWithIcon>
          </div>
          <Collapsible open={bIsMoreExpanded} overflowWhenOpen='visible'>
            <Name name='spouse' recordPath={['SPOUSE']} title='Spouse' />
          </Collapsible>
        </>
      )}
    </ProfileSection>
  );
};

export default PersonalSection;
