import React from 'react';

const Typography = () => (
  <>
    <h1>Header 1</h1>
    <h2>Header 2</h2>
    <h3>Header 3</h3>
    <h4>Header 4</h4>
    <h5>Header 5</h5>
    <p className='t-paragraph--large'>
      Large paragraph text. The Wake Forest motto, Pro Humanitate, is regularly
      translated as “For Humanity” and is probably most often understood to mean
      that we do what we do for the sake of humanity, for the people of the
      world. It is often heard as a very specific call to community service.
      This is fine: that is a compelling reading of the motto and we should be
      mindful of our wider obligations to society.
    </p>
    <p className='t-paragraph--bold'>Bold paragraph text.</p>
    <p className='t-paragraph--medium'>
      Medium paragraph text. For many of us coming out of college, we have some
      idea of how we want our futures to play out, whether that be our first job
      or how much money we plan to save in the next amount of years. And by
      doing so, we put ourselves in these parameters. And if anything deviates
      from that plan, we often times fall defeated or we&apos;re unable to
      adapt. In order to create a story that hasn&apos;t been written, we must
      embrace the unknown.
    </p>
    <p>
      Regular paragraph text with some <strong>bold statements</strong>. Class
      of 2021, life is often measured in moments, and today&apos;s a big moment
      for all of you. Enjoy every second of it, and as you depart from this
      beautiful campus, always remember to be grateful for your situation
      because there is a lesson in everything. Everyone&apos;s path is unique so
      embrace every twist and turn on your journey. And most importantly,
      embrace uncertainty. Allow for unlimited opportunities and break those
      parameters so you can create a story that you&apos;ve never read before.
    </p>
    <p className='t-paragraph--small'>
      Small paragraph text. At the time, Wake Forest was the only college in the
      state without a mascot. In 1941 Jack R. Baldwin (&apos;43) took on a
      fraternity brother&apos;s dare and dressed up as he thought an old-time
      Baptist deacon would: top hat, tuxedo, and carrying a black umbrella. The
      football crowd loved it, and a tradition was born. In 1980 the Demon
      Deacon evolved into a complete costume with the large deacon head.
    </p>

    <div className='t-label'>Record label</div>
    <div className='t-dates'>Dates Jan 2020 – Jun 2022</div>

    <a href='foo'>Link</a>
  </>
);

export default Typography;
