import { object, string, array, number, boolean } from 'yup';

import { coerceToArray, coerceToString } from 'utils/yupHelpers';

const advSearchResultsDataNormalizer = array(
  object({
    CITY: string().transform(coerceToString).ensure(),
    EMPLOYER: string().transform(coerceToString).ensure(),
    NAME: string().transform(coerceToString),
    SCORE: number(),
    SCORE_1TO5: number(),
    STATE: string().transform(coerceToString).ensure(),
    HAS_PHOTO: boolean().default(false),
    FIRSTNAME: string().transform(coerceToString),
  })
).transform(coerceToArray);

export default advSearchResultsDataNormalizer;
