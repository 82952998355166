import { put, takeEvery } from 'redux-saga/effects';
import { toPascalCase } from 'utils/common';

import oWnApi from 'utils/WnApi';
import fnParseActionType from 'components/MyProfile/redux/fnParseActionType';
import userProfileApiDataNormalizer from '../../MyProfile/redux/apiDataNormalizers';
import { profileExternalViewActions } from './actions';

function* getProfile({ payload: { lookupId, resolve, reject } }) {
  const oResponse = yield oWnApi.get(`constituents/${lookupId}`);
  if (oResponse.status === 200) {
    const oProfile = yield userProfileApiDataNormalizer.validate(
      oResponse.data.DATA
    );
    yield put(profileExternalViewActions.set(oProfile));
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* createProfileData(oAction) {
  const { data, resolve, reject } = oAction.payload;
  const { sDadDataType, sApiResourceName, sReduxStoreSubKey } =
    fnParseActionType(oAction.type);
  const { ID, ...oDataWithoutId } = data;
  const oRequestPayload = {
    ID: ID || '00000000-0000-0000-0000-000000000000',
    TYPE: sDadDataType,
    data: oDataWithoutId,
  };
  const sTargetLookupId = window.location.pathname.split('/').pop();
  const sRequestPath = `constituents/${sTargetLookupId}/${sApiResourceName}`;
  const oResponse = yield oWnApi.post(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    const oValidatedResponseData =
      yield userProfileApiDataNormalizer.validateAt(
        sDadDataType,
        oResponse.data.DATA
      );
    yield put(
      profileExternalViewActions.update({
        [sReduxStoreSubKey]: oValidatedResponseData,
      })
    );
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* updateProfileData(oAction) {
  const { data, resolve, reject } = oAction.payload;
  const { sDadDataType, sApiResourceName, sReduxStoreSubKey } =
    fnParseActionType(oAction.type);
  const { ID, ...oDataWithoutId } = data;
  const oRequestPayload = {
    ID,
    TYPE: sDadDataType,
    data: oDataWithoutId,
  };
  const sTargetLookupId = window.location.pathname.split('/').pop();
  const sRequestPath = `constituents/${sTargetLookupId}/${sApiResourceName}`;
  const oResponse = yield oWnApi.patch(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    const oValidatedResponseData =
      yield userProfileApiDataNormalizer.validateAt(
        sReduxStoreSubKey,
        oResponse.data.DATA
      );
    yield put(
      profileExternalViewActions.update({
        [sReduxStoreSubKey]: oValidatedResponseData,
      })
    );
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* deleteProfileData(oAction) {
  const { data, resolve, reject } = oAction.payload;
  const { sDadDataType, sApiResourceName, sReduxStoreSubKey } =
    fnParseActionType(oAction.type);
  const sTargetLookupId = window.location.pathname.split('/').pop();
  const sRequestPath = `constituents/${sTargetLookupId}/${sApiResourceName}`;
  const oRequestPayload = {
    ID: data.ID,
    TYPE: sDadDataType,
  };
  const oResponse = yield oWnApi.delete(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    const oValidatedResponseData =
      yield userProfileApiDataNormalizer.validateAt(
        sDadDataType,
        oResponse.data.DATA
      );
    yield put(
      profileExternalViewActions.update({
        [sReduxStoreSubKey]: oValidatedResponseData,
      })
    );
    resolve('Success');
  } else {
    reject('Error');
  }
}

function* createNote(oAction) {
  const { data, resolve, reject } = oAction.payload;
  const sTargetLookupId = window.location.pathname.split('/').pop();

  const sRequestPath = `constituents/${sTargetLookupId}/add_note`;
  const { sDadDataType } = fnParseActionType(oAction.type);
  const sTitle = `${toPascalCase(sDadDataType)} Note`;
  const oRequestPayload = {
    TYPE: 'Note',
    data: {
      TITLE: sTitle,
      ...data,
    },
  };
  const oResponse = yield oWnApi.post(sRequestPath, oRequestPayload);
  if (oResponse.status === 200) {
    resolve('Success');
  } else {
    reject('Error');
  }
}

export default function* watchProfileExternalView() {
  yield takeEvery('ProfileExternalView/GET', getProfile);
  // Matches ProfileExternalView/anything/CREATE
  yield takeEvery(
    (action) => /^ProfileExternalView\/([a-zA-Z_]+)\/CREATE/.test(action.type),
    createProfileData
  );
  // Matches ProfileExternalView/any/thing/UPDATE
  yield takeEvery(
    (action) => /^ProfileExternalView\/([a-zA-Z_/]+)\/UPDATE/.test(action.type),
    updateProfileData
  );
  // Matches ProfileExternalView/anything/DELETE
  yield takeEvery(
    (action) => /^ProfileExternalView\/([a-zA-Z_]+)\/DELETE/.test(action.type),
    deleteProfileData
  );
  // Matches ProfileExternalView/any/thing/ADD_NOTE
  yield takeEvery(
    (action) =>
      /^ProfileExternalView\/([a-zA-Z_/]+)\/ADD_NOTE/.test(action.type),
    createNote
  );
}
