import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';
import Button from 'components/Buttons/Button';

const IAmButton = ({ sMatchType, fnHandleClick, bSelected }) => {
  const fnGetButtonDetails = () => {
    switch (sMatchType) {
      case 'alumni':
        return {
          sIcon: oIconLibrary.fontAwesome.graduationCapSolid,
          sButtonText: 'Alumnus',
          sCypressId: 'iAm__typeButton--alumni',
        };
      case 'student':
        return {
          sIcon: oIconLibrary.fontAwesome.backpack,
          sButtonText: 'Student',
          sCypressId: 'iAm__typeButton--student',
        };
      case 'parent':
        return {
          sIcon: oIconLibrary.fontAwesome.family,
          sButtonText: 'Parent',
          sCypressId: 'iAm__typeButton--parent',
        };
      case 'friend':
        return {
          sIcon: oIconLibrary.fontAwesome.heartSolid,
          sButtonText: 'Friend',
          sCypressId: 'iAm__typeButton--friend',
        };
      case 'staff':
        return {
          sIcon: oIconLibrary.fontAwesome.buildingColumns,
          sButtonText: 'Faculty & Staff',
          sCypressId: 'iAm__typeButton--facultystaff',
        };
      default:
        return {};
    }
  };
  const oButtonDetails = fnGetButtonDetails();
  return (
    <Button
      sFlavor='secondary'
      className={`iAm__typeButton ${
        bSelected ? 'iAm__typeButton--selected' : ''
      }`}
      fnHandleClick={fnHandleClick}
      sCypressId={oButtonDetails.sCypressId}
    >
      <FontAwesomeIcon icon={oButtonDetails.sIcon} />
      {oButtonDetails.sButtonText}
      <div className='iAm__typeButtonSelectedContainer'>
        {bSelected && (
          <FontAwesomeIcon
            icon={oIconLibrary.fontAwesome.check}
            className='iAm__typeButton--selectedCheck'
          />
        )}
      </div>
    </Button>
  );
};

IAmButton.propTypes = {
  sMatchType: PropTypes.string.isRequired,
  fnHandleClick: PropTypes.func.isRequired,
  bSelected: PropTypes.bool,
};

IAmButton.defaultProps = {
  bSelected: false,
};

export default IAmButton;
