import { object, string } from 'yup';

export const NOTE = object({
  TEXTNOTE: string()
    .max(10000)
    .required(
      'Please enter some details about what you would like to have changed.'
    ),
});

export default { NOTE };
