import { createActions } from 'redux-actions';

export default createActions(
  {
    CREATE: (payload) => payload,
  },
  {
    prefix: 'Profile/BIOGRAPHICAL/GDPR',
  }
);
