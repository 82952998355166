import React from 'react';
import PropTypes from 'prop-types';

import { oCurrencyFormatter } from 'utils/common';

const AnnualFundFinances = ({ sBeginningValue, sEndingValue }) => (
  <table className='fundFinancialHistory__table fundFinancialHistory__table--annual'>
    <thead>
      <tr>
        <th>Beginning Balance</th>
        <th>Ending Balance</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <p>
            {sBeginningValue ? oCurrencyFormatter.format(sBeginningValue) : '-'}
          </p>
        </td>
        <td>
          <p>{sEndingValue ? oCurrencyFormatter.format(sEndingValue) : '-'}</p>
        </td>
      </tr>
    </tbody>
  </table>
);

AnnualFundFinances.propTypes = {
  sBeginningValue: PropTypes.string,
  sEndingValue: PropTypes.string,
};

AnnualFundFinances.defaultProps = {
  sBeginningValue: '',
  sEndingValue: '',
};

export default AnnualFundFinances;
