import React from 'react';
import PropTypes from 'prop-types';

/**
 * Converts a string into <p> tags based on line breaks.
 */
const LineBreaksToParagraphs = ({ sText, sClassName }) => {
  if (typeof sText !== 'string') {
    console.error(`Cannot convert ${typeof sText} into paragraphs`);
    return null;
  }
  if (sText.length === 0) {
    return null;
  }
  const aParagraphs = sText.split('\r\n'); // line breaks
  return aParagraphs.map((sParagraph) => (
    <p className={sClassName} key={sParagraph}>
      {sParagraph}
    </p>
  ));
};

LineBreaksToParagraphs.propTypes = {
  sText: PropTypes.string,
  sClassName: PropTypes.string,
};

LineBreaksToParagraphs.defaultProps = {
  sText: '',
  sClassName: '',
};

export default LineBreaksToParagraphs;
