import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import { fnFormatEventCost } from 'components/Events/helpers';

const EventCost = ({ oEvent, sClassName }) => {
  const bHasCost = oEvent.REGISTRATION.HAS_COST === 1;
  return bHasCost ? (
    <p className={sClassName}>
      <FontAwesomeIcon icon={oIconLibrary.fontAwesome.circleDollar} />{' '}
      {fnFormatEventCost(oEvent)} per person
    </p>
  ) : null;
};

EventCost.propTypes = {
  oEvent: PropTypes.shape({
    REGISTRATION: PropTypes.shape({
      HAS_COST: PropTypes.number,
    }),
  }).isRequired,
  sClassName: PropTypes.string,
};

EventCost.defaultProps = {
  sClassName: '',
};

export default EventCost;
