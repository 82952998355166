import React from 'react';
import PropTypes from 'prop-types';

import { DateField } from './DateField';

export const DateRange = ({ oStartDateProps, oEndDateProps }) => (
  <fieldset className='dateRange'>
    <legend className='dateRange__legend'>Date Range</legend>
    <div className='dateRange__startDate'>
      <DateField
        sName={oStartDateProps.sName}
        sLabel={oStartDateProps.sLabel}
        sId={oStartDateProps.sId}
      />
    </div>
    <div className='dateRange__endDate'>
      <DateField
        sName={oEndDateProps.sName}
        sLabel={oEndDateProps.sLabel}
        sId={oEndDateProps.sId}
      />
    </div>
  </fieldset>
);

DateRange.propTypes = {
  oStartDateProps: PropTypes.shape({
    sName: PropTypes.string.isRequired,
    sLabel: PropTypes.string.isRequired,
    sId: PropTypes.string.isRequired,
  }).isRequired,
  oEndDateProps: PropTypes.shape({
    sName: PropTypes.string.isRequired,
    sLabel: PropTypes.string.isRequired,
    sId: PropTypes.string.isRequired,
  }).isRequired,
};

export default DateRange;
