import React from 'react';
import PropTypes from 'prop-types';

import { OptionsModal } from 'components/Form';
import { TextField } from 'components/Form/Fields';
import NewRecordForm from 'components/Form/NewRecordForm';
import { fnElementRefPropType } from 'utils/customPropTypes';
import useIsUserViewingSpouseProfile from 'utils/hooks/useIsUserViewingSpouseProfile';
import { profileExternalViewEmailActions } from 'components/ProfileExternalView/redux/actions';
import { addEmailSchema } from './schemas';
import profileEmailActions from './redux/actions';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const NEW_EMAIL_FIELD_ID = 'new-email-field';

const FormBody = ({ firstFieldRef, ...formikProps }) => (
  <ProfileRecordLayout
    left={
      <TextField
        sId='email'
        sLabel='Email Address*'
        sName='EMAILADDRESS'
        sPlaceholder='youremail@domain.com'
        oFieldRef={firstFieldRef}
      />
    }
    right={
      <OptionsModal
        data={formikProps.values}
        name='Email'
        forceExpanded
        formikProps={formikProps}
      />
    }
  />
);

FormBody.propTypes = {
  firstFieldRef: fnElementRefPropType.isRequired,
};

export const NewEmailForm = ({
  onShow,
  onHide,
  FormInactiveComponent,
  initialValues,
  handleSubmitSideEffects,
}) => {
  const bIsUserViewingSpouseProfile = useIsUserViewingSpouseProfile();
  return (
    <NewRecordForm
      initialValues={initialValues}
      addItemLabel='Add Email'
      formId={NEW_EMAIL_FIELD_ID}
      onShow={onShow}
      onHide={onHide}
      validateOnBlur={false}
      validateOnChange={false}
      actions={
        bIsUserViewingSpouseProfile
          ? profileExternalViewEmailActions
          : profileEmailActions
      }
      FormBody={FormBody}
      FormInactiveComponent={FormInactiveComponent}
      validationSchema={addEmailSchema}
      codeTablesKey='EMAIL'
      handleSubmitSideEffects={handleSubmitSideEffects}
    />
  );
};

NewEmailForm.defaultProps = {
  onShow: () => {},
  onHide: () => {},
  handleSubmitSideEffects: () => {},
  initialValues: {
    EMAILADDRESS: '',
    TYPE: 'Personal Email Address',
    PRIVACY: '0',
    PRIMARY: true,
  },
  FormInactiveComponent: null,
};

NewEmailForm.propTypes = {
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  handleSubmitSideEffects: PropTypes.func,
  initialValues: PropTypes.shape({
    EMAILADDRESS: PropTypes.string,
    TYPE: PropTypes.string,
    PRIVACY: PropTypes.string,
    PRIMARY: PropTypes.bool,
  }),
  FormInactiveComponent: PropTypes.element,
};

export default NewEmailForm;
