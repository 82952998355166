import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

/**
 * Determines if the current user is fully authenticated:
 *   * Signed in with an IdP
 *   * Matched with a DAD constituent record (NOT a first-time user)
 * @returns {boolean} bIsAuthenticated - Whether the user is authenticated
 */
const useIsUserAuthenticated = () => {
  const [bIsAuthenticated, fnSetIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkIsUserAuthenticated = async () => {
      try {
        const oCognitoUser = await Auth.currentAuthenticatedUser();
        const bIsFirstTimeUser =
          oCognitoUser.signInUserSession.idToken.payload.isFtu === 'true';

        if (!bIsFirstTimeUser) {
          fnSetIsAuthenticated(true);
        }
      } catch (oError) {
        if (oError === 'The user is not authenticated') {
          fnSetIsAuthenticated(false);
        } else {
          console.error('Error checking if user is authenticated', oError);
        }
      }
    };

    checkIsUserAuthenticated();
  }, []);

  return bIsAuthenticated;
};

export default useIsUserAuthenticated;
