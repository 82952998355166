import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary, { aIconTypes } from 'utils/iconLibrary';

const IconList = ({ sType }) => (
  <ul className='styleGuide__icons'>
    {Object.keys(oIconLibrary[sType]).map((sIcon) => (
      <li className='styleGuide__icon' key={sIcon}>
        {sType === 'fontAwesome' ? (
          <span className='t-paragraph--large'>
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome[sIcon]} />
          </span>
        ) : (
          <img
            src={oIconLibrary.wfu[sIcon]}
            alt=''
            className='styleGuide__iconImage'
          />
        )}
        <span className='styleGuide__iconName t-paragraph--small'>{sIcon}</span>
      </li>
    ))}
  </ul>
);

IconList.propTypes = {
  sType: PropTypes.oneOf(aIconTypes).isRequired,
};

export default IconList;
