import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import expireReducer from 'redux-persist-expire';

import Profile from 'components/MyProfile/redux/reducer';
import ProfileExternalView from 'components/ProfileExternalView/redux/reducer';
import CodeTables from 'components/Main/redux/CodeTables/reducer';
import Checkout from 'components/Checkout/redux/reducer';
import PledgePayment from 'components/Philanthropy/MyGiving/Pledges/Payments/redux/reducer';
import Meta from './meta/reducer';
import Volunteers from '../components/Volunteer/redux/ClaimedClassmates/reducer';
import AllClassmates from '../components/Volunteer/redux/AllClassmates/reducer';
import Scholarships from '../components/Scholarships/redux/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  transforms: [
    expireReducer('CodeTables', {
      expireSeconds: 86400, // one day
      persistedAtKey: '_lastUpdated',
    }),
  ],
  whitelist: ['CodeTables'],
};

const pledgePaymentPersistConfig = {
  key: 'pledgepayment',
  storage: storageSession,
};

const checkoutPersistConfig = {
  key: 'checkout',
  storage: storageSession,
};

const rootReducer = combineReducers({
  Profile,
  ProfileExternalView,
  CodeTables,
  Meta,
  Volunteers,
  AllClassmates,
  Scholarships,
  Checkout: persistReducer(checkoutPersistConfig, Checkout),
  PledgePayment: persistReducer(pledgePaymentPersistConfig, PledgePayment),
});

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedRootReducer;
