import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CheckoutConfirmation from 'components/Checkout/CheckoutConfirmation';
import CheckoutSummaryTable from 'components/Checkout/CheckoutSummaryTable';
import usePageTitle from 'utils/hooks/usePageTitle';
import * as PledgePaymentActions from './redux/actions';
import { buildConfirmationData } from './PledgePaymentReview/helpers';

/**
 * Responsible for pledge payment confirmation page.
 */
const PledgePaymentConfirmation = () => {
  const fnNavigate = useNavigate();
  const oLocation = useLocation();
  const dispatch = useDispatch();
  const oPledgePaymentData = useSelector((state) => state.PledgePayment);
  const [aSummaryItems, setSummaryItems] = useState([]);

  usePageTitle('Commitment Payment Confirmation');

  // Clear saved pledge payment data
  useEffect(() => {
    if (
      oPledgePaymentData.payablePledges.length > 0 &&
      oPledgePaymentData.selectPaymentFormValues
    ) {
      const oConfirmationData = buildConfirmationData({
        oFormValues: oPledgePaymentData.selectPaymentFormValues,
        aPayablePledges: oPledgePaymentData.payablePledges,
      });
      setSummaryItems(oConfirmationData.aSummaryItems);
      dispatch(PledgePaymentActions.reset({}));
    }
  }, [
    dispatch,
    oPledgePaymentData.selectPaymentFormValues,
    oPledgePaymentData.payablePledges,
  ]);

  let bUserRefreshedPage = false;
  // performance is an experimental feature
  // See https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
  if (performance?.getEntriesByType('navigation')?.length > 0) {
    bUserRefreshedPage =
      performance.getEntriesByType('navigation')[0].type === 'reload';
  }
  const bShouldRedirect = !oLocation.search || bUserRefreshedPage;
  if (bShouldRedirect) {
    fnNavigate('/philanthropy');
  }

  return (
    <CheckoutConfirmation
      sTitle='Commitment Payment'
      sFirstStepLabel='Select Payment'
      oTableMarkup={<CheckoutSummaryTable aCheckoutItems={aSummaryItems} />}
      bHasPayment
    />
  );
};

export default PledgePaymentConfirmation;
