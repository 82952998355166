import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { CancelButton, SubmitButton } from 'components/Form/Buttons';
import { TextField, DateRange } from 'components/Form/Fields';
import { AddItemButton } from 'components/Form';
import useActiveFormField from 'utils/hooks/useActiveFormField';
import useActiveFormSection from 'utils/hooks/useActiveFormSection';
import ProfileRecordLayout from 'components/MyProfile/elements/layout/ProfileRecordLayout';
import SelectEmployerField from 'components/Form/Fields/Select/SelectEmployerField';
import actions from '../redux/actions';
import { oInitialValues, fnBuildAddNewEmploymentSchema } from './helpers';
import AddNewEmploymentFormOptions from './AddNewEmploymentFormOptions';

/**
 * Displays add new employment form.
 */
const AddNewEmploymentForm = () => {
  const sFormId = 'add-employment-form';
  const [activeFormField, setActiveFormField] = useActiveFormField(sFormId);
  const [activeFormSection] = useActiveFormSection();
  const bIsEditingCareerSection = activeFormSection === 'career';
  const [bIsFormOpen, fnSetIsFormOpen] = useState(false);
  const aExistingEmployment = useSelector(
    (state) => state.Profile.EMPLOYMENT || []
  );
  const fnDispatch = useDispatch();
  const oFirstFieldRef = useRef();

  // Auto-focus the first field when the form opens
  useEffect(() => {
    if (bIsFormOpen && oFirstFieldRef.current) {
      oFirstFieldRef.current.focus();
    }
  }, [oFirstFieldRef, bIsFormOpen, activeFormField]);

  const fnCloseForm = () => {
    setActiveFormField(null);
  };

  const fnOpenForm = () => {
    setActiveFormField(sFormId);
  };

  const fnHandleSubmit = (oFormValues) => {
    const oPromise = new Promise((resolve, reject) => {
      fnDispatch(actions.create({ data: oFormValues, resolve, reject }));
    });
    oPromise.then(() => {
      fnSetIsFormOpen(false);
    });
  };

  useEffect(() => {
    if (activeFormField === sFormId && bIsEditingCareerSection) {
      fnSetIsFormOpen(true);
    } else {
      fnSetIsFormOpen(false);
    }
  }, [activeFormField, bIsEditingCareerSection, sFormId, fnSetIsFormOpen]);

  const oSchema = fnBuildAddNewEmploymentSchema(aExistingEmployment);

  return bIsFormOpen ? (
    <Formik
      initialValues={oInitialValues}
      validationSchema={oSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={fnHandleSubmit}
    >
      {(formikProps) => {
        const bShowOverlappingDatesError = formikProps.errors.POSITIONS?.some(
          (oItem) => oItem.START_DATE === 'Overlapping Dates'
        );
        return (
          <Form className='editEmployerGroupForm'>
            <ProfileRecordLayout
              left={
                <>
                  <SelectEmployerField
                    oFieldRef={oFirstFieldRef}
                    className='editEmployerGroupForm__employer'
                    sInputId='new-employer'
                    bIsUserAllowedToAddOptions
                  />
                  <FieldArray name='POSITIONS'>
                    {(fieldArrayProps) =>
                      formikProps.values.POSITIONS.map((oPosition, iIndex) => (
                        <div className='jobFieldSet__fields' key={oPosition.ID}>
                          <TextField
                            sId={`job-title-${iIndex}`}
                            sName={`POSITIONS.${iIndex}.JOB_TITLE`}
                            sLabel='Job Title'
                          />
                          <DateRange
                            oStartDateProps={{
                              sName: `POSITIONS.${iIndex}.START_DATE`,
                              sLabel: 'Start Date',
                              sId: 'start-date',
                            }}
                            oEndDateProps={{
                              sName: `POSITIONS.${iIndex}.END_DATE`,
                              sLabel: 'End Date',
                              sId: 'end-date',
                            }}
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                </>
              }
              right={<AddNewEmploymentFormOptions />}
            />

            {bShowOverlappingDatesError && (
              <p className='form__overallError'>
                Employment cannot be saved with overlapping start and end dates.
              </p>
            )}
            <div className='editEmployerGroupForm__controls'>
              <SubmitButton />
              <CancelButton
                fnHandleClick={fnCloseForm}
                bIsDisabled={formikProps.isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <AddItemButton sLabel='Add Employment' fnHandleClick={fnOpenForm} />
  );
};

export default AddNewEmploymentForm;
