import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { object } from 'yup';

import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import actions from './redux/actions';
import useActions from '../../../../../utils/hooks/useActions';
import { AddItemButton } from '../../../../Form/AddItemButton';
import GDPRConsentModal from './GDPRConsentModal';
import QuickFindModalForm from '../../../../Form/QuickFindModalForm';
import useCloseOnOutsideClick from '../../../../../utils/hooks/useCloseOnOutsideClick';

const AddNewReligion = ({ data }) => {
  const aReligionOptions = useCodeTablesSection('RELIGION');
  const isGDPRImplicated = useSelector(
    (state) => state.Profile.BIOGRAPHICAL.GDPR.IMPLICATION
  );
  // GDPR Consent status options
  // 0 - no response
  // 1 - opt out
  // 2 - opt in
  const isGDPROptIn = useSelector(
    (state) => (state.Profile.BIOGRAPHICAL.GDPR.CONSENT || {}).STATUS === '2'
  );
  const [showEditForm, setShowEditForm] = useState(false);
  const [showGDPRModal, setShowGDPRModal] = useState(false);
  const religionActions = useActions(actions);
  const gdprModalRef = useRef();
  const initialValues = {
    RELIGION: {
      ID: data ? data.ID : '',
      DESCRIPTION: data ? data.DESCRIPTION : '',
    },
  };
  const religionSchema = object({
    RELIGION: object().nullable().required('Please choose a religion.'),
  });

  const handleAddReligionClick = () => {
    // Ask for consent from GDPR users who haven't opted-in yet
    // before letting them add a religion
    if (isGDPRImplicated && !isGDPROptIn) {
      setShowGDPRModal(true);
    } else {
      setShowEditForm(true);
    }
  };

  // Allow GDPR users to add religion after consenting to GDPR statement
  const handleSuccessfulGDPRSubmit = (values) => {
    setShowGDPRModal(false);
    // GDPR Consent status options
    // 0 - no response
    // 1 - opt out
    // 2 - opt in
    if (values.gdprConsent === '2') {
      setShowEditForm(true);
    }
  };

  const handleAddReligionSubmit = async (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      religionActions.create({ data: formData.RELIGION, resolve, reject });
    });
    try {
      const result = await submitPromise;
      if (result === 'Success') {
        setShowEditForm(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useCloseOnOutsideClick(gdprModalRef, setShowGDPRModal);

  return (
    <>
      <AddItemButton
        bIsHidden={showEditForm || showGDPRModal}
        sLabel={data ? 'Edit religion' : 'Add Religion'}
        fnHandleClick={handleAddReligionClick}
        className='addRecordButton'
      />
      {showEditForm && (
        <QuickFindModalForm
          onSubmit={handleAddReligionSubmit}
          onReset={() => setShowEditForm(false)}
          initialValues={initialValues}
          schema={religionSchema}
          formikFieldToUpdate='RELIGION'
          options={aReligionOptions}
          setIsFormVisible={setShowEditForm}
          sLabel='Religion'
        />
      )}
      {showGDPRModal && (
        <GDPRConsentModal
          onSuccessfulSubmit={handleSuccessfulGDPRSubmit}
          modalRef={gdprModalRef}
        />
      )}
    </>
  );
};

AddNewReligion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

AddNewReligion.defaultProps = {
  data: null,
};

export default AddNewReligion;
