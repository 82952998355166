import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import oIconLibrary from 'utils/iconLibrary';
import useActions from '../../../../utils/hooks/useActions';
import notificationsActions from '../redux/actions';

const VerifyProfileInfoNotification = ({ data, fnSetShowModal }) => {
  const [bShowSuccess, fnSetShowSuccess] = useState(false);
  const [bIsLoading, fnSetIsLoading] = useState(false);
  const actions = useActions(notificationsActions);

  const fnVerifyProfileInfo = async (bInfoIsUpToDate) => {
    if (bInfoIsUpToDate) {
      fnSetIsLoading(true);
      // eslint-disable-next-line no-unused-vars
      const oResult = await new Promise((resolve, reject) => {
        actions.update({
          data: {
            // This key is required in order to convert the array JSON to XML correctly.
            NOTIFICATIONS: [
              {
                NOTIFICATION: {
                  ID: data.ID,
                  TYPE: data.TYPE,
                  DISMISSEDSTATUS: '1',
                },
              },
            ],
          },
          resolve,
          reject,
        });
      });
      if (oResult === 'Success') {
        fnSetIsLoading(false);
        fnSetShowSuccess(true);
      }
    }
    fnSetShowModal(false);
  };

  return bShowSuccess ? (
    <div className='notification__success'>
      <p>
        Great!
        <br />
        Thanks for confirming!
      </p>
      <FontAwesomeIcon
        icon={oIconLibrary.fontAwesome.sparkles}
        className='notificationsModal__successIcon'
      />
    </div>
  ) : (
    <>
      <p className='verifyProfileInfoNotification__text t-paragraph--small'>
        Is your information up to date?
      </p>
      <Button
        className='notification__answerBtn'
        fnHandleClick={() => fnVerifyProfileInfo(false)}
        sFlavor='tertiary'
        bIsLoading={bIsLoading}
        bIsCentered
      >
        No, I need to make updates
      </Button>
      <Button
        className='notification__answerBtn'
        fnHandleClick={() => fnVerifyProfileInfo(true)}
        sFlavor='underline'
        bIsDisabled={bIsLoading}
        bIsCentered
      >
        Yes, my information is current
      </Button>
    </>
  );
};

VerifyProfileInfoNotification.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    TYPE: PropTypes.string,
  }).isRequired,
  fnSetShowModal: PropTypes.func.isRequired,
};

export default VerifyProfileInfoNotification;
