import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { sortByKey } from 'utils/common';
import EmailViewMode from 'components/MyProfile/elements/Personal/Email/viewMode';
import EmailEditMode from 'components/MyProfile/elements/Personal/Email/editMode';
import ViewMoreButton from 'components/Layout/ViewMoreButton';
import ProfileSubsectionLayout from 'components/MyProfile/elements/layout/ProfileSubsectionLayout';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import { profileExternalViewEmailActions } from 'components/ProfileExternalView/redux/actions';

const emailSelector = (state) => (state.ProfileExternalView || {}).EMAIL || [];

const sortByPrimary = createSelector(emailSelector, (items) =>
  sortByKey(items, 'PRIMARY', false)
);

export const EmailElement = () => {
  const aEmails = useSelector(sortByPrimary);
  const [activeFormSection] = useActiveFormSection();
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, setIsEditMode] = useState(false);

  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      setIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      setIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    <ProfileSubsectionLayout
      sTitle='Email'
      left={
        aEmails.length > 1 && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <EmailEditMode
            aEmails={aEmails}
            bIsExpanded={bIsExpanded}
            oActions={profileExternalViewEmailActions}
          />
        ) : (
          <EmailViewMode aEmails={aEmails} bIsExpanded={bIsExpanded} />
        )
      }
    />
  );
};

export default EmailElement;
