import React from 'react';
import PropTypes from 'prop-types';

import { fnChildrenPropType } from '../../../utils/customPropTypes';

/**
 * Responsible for basic page layout with title and content area.
 */
const PageLayout = ({ mTitle, children, bHasBackground, className }) => (
  <div className={`pageLayout ${className}`}>
    {typeof mTitle === 'string' ? (
      <h1 className='pageLayout__title'>{mTitle}</h1>
    ) : (
      <div className='pageLayout__title'>{mTitle}</div>
    )}
    <div
      className={`pageLayout__content${bHasBackground ? '--background' : ''}`}
    >
      {children}
    </div>
  </div>
);

PageLayout.propTypes = {
  mTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: fnChildrenPropType.isRequired,
  bHasBackground: PropTypes.bool,
  className: PropTypes.string,
};

PageLayout.defaultProps = {
  bHasBackground: true,
  className: '',
};

export default PageLayout;
