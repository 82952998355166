import { object, string, bool, mixed } from 'yup';

import { privacyFieldSchema } from 'utils/schemas/fields';

const requiredForSeasonalOnlySchemaFactory =
  (errorMessage) =>
  ([TYPE]) => {
    if (TYPE === 'Seasonal Residence') {
      return string().required(errorMessage);
    }
    return string();
  };

export const addAddressSchema = object({
  ADDRESSBLOCK: string()
    .max(250)
    .required('Please enter a valid street address'),
  ADDRESSCITY: string().max(100).required('Please enter your city'),
  ADDRESSZIP: string().when('ADDRESSCOUNTRY', (ADDRESSCOUNTRY) => {
    if (ADDRESSCOUNTRY?.DETAILS?.NAME_ABBRV === 'US') {
      return string().max(5).required('Please enter a 5 digit zip code');
    }
    return string();
  }),
  START_MONTH: string().when(
    'TYPE',
    requiredForSeasonalOnlySchemaFactory(
      'Please enter a starting month for your seasonal address'
    )
  ),
  START_DAY: string().when(
    'TYPE',
    requiredForSeasonalOnlySchemaFactory(
      'Please enter a starting day for your seasonal address'
    )
  ),
  END_MONTH: string().when(
    'TYPE',
    requiredForSeasonalOnlySchemaFactory(
      'Please enter an end month for your seasonal address'
    )
  ),
  END_DAY: string().when(
    'TYPE',
    requiredForSeasonalOnlySchemaFactory(
      'Please enter an end day for your seasonal address'
    )
  ),
  PRIMARY: bool().required(),
  TYPE: string().required(),
  PRIVACY: privacyFieldSchema,
  ADDRESSCOUNTRY: object().nullable().required('Please enter your country'),
  ADDRESSSTATE: mixed().when('ADDRESSCOUNTRY', (ADDRESSCOUNTRY) => {
    if (ADDRESSCOUNTRY?.DETAILS?.NAME_ABBRV === 'US') {
      return object().nullable().required('Please enter your state');
    }
    return object().nullable();
  }),
});

export const editAddressSchema = object({
  PRIMARY: bool().required(),
  PRIVACY: privacyFieldSchema,
});
