import React from 'react';
import PropTypes from 'prop-types';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';

export const AddItemButton = ({
  fnHandleClick,
  sLabel,
  bIsHidden,
  className,
}) => {
  const sAction = sLabel.toLowerCase().includes('edit') ? 'edit' : 'add';

  return bIsHidden ? null : (
    <ButtonWithIcon
      className={`addRecordButton ${className}`}
      fnHandleClick={fnHandleClick}
      sIcon={sAction === 'add' ? 'plus' : 'edit'}
    >
      {sLabel}
    </ButtonWithIcon>
  );
};

AddItemButton.propTypes = {
  fnHandleClick: PropTypes.func.isRequired,
  sLabel: PropTypes.string.isRequired,
  bIsHidden: PropTypes.bool,
  className: PropTypes.string,
};

AddItemButton.defaultProps = {
  bIsHidden: false,
  className: '',
};

export default AddItemButton;
