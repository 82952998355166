import React from 'react';
import PropTypes from 'prop-types';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';
import LinkWithSourcePath from '../../../../../utils/LinkWithSourcePath';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

const NameWithQuickFindViewMode = ({ data }) =>
  data.map((item) => (
    <ProfileRecordLayout
      key={`name-element-${item.LOOKUPID}-${item.RELATIONSHIP.ID}`}
      left={
        <div className='record'>
          <LinkWithSourcePath
            to={`/main/profile/${item.LOOKUPID}`}
            referringComponent='NameWithQuickFind'
          >
            {item.NAME}
          </LinkWithSourcePath>
          <RecordPrivacyIcon ariaId={`private-eye-${item.LOOKUPID}`} />
        </div>
      }
    />
  ));

NameWithQuickFindViewMode.defaultProps = {
  data: {},
};

NameWithQuickFindViewMode.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      NAME: PropTypes.string,
      LOOKUPID: PropTypes.string,
      RELATIONSHIP: PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
    })
  ),
};

export default NameWithQuickFindViewMode;
