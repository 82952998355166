import Accordion from 'components/Layout/Accordion';
import React, { useContext, useEffect, useState } from 'react';

import oWnApi from 'utils/WnApi';
import BetaTag from 'components/Layout/BetaTag';
import LoadingZone from 'components/LoadingZone';
import PreviousLocationContext from 'utils/PreviousLocationContext';
import MultiLevelEventCard from '../MultiLevelEventCard';
import UpcomingEventCard from './UpcomingEventCard';
import EventCardSkeleton from '../EventCardSkeleton';

const UpcomingEvents = () => {
  const [bIsLoading, fnSetIsLoading] = useState(true);
  const [aEvents, fnSetEvents] = useState([]);
  const { oPreviousLocation } = useContext(PreviousLocationContext);

  useEffect(() => {
    const fnGetEvents = async () => {
      const oResponse = await oWnApi.get('events');
      if (oResponse.status === 200) {
        fnSetEvents(oResponse.data);
      }
      fnSetIsLoading(false);
    };
    fnGetEvents();
  }, []);

  return (
    <Accordion
      mTitle={
        <>
          <span>Upcoming Events</span>
          {!bIsLoading && <span>{aEvents.length}</span>}
          <BetaTag />
        </>
      }
      sTitleIcon='addToCalendar'
      sCypressId='upcoming-events-accordion'
      bStartOpen={oPreviousLocation?.pathname.includes('events')}
    >
      <div className='upcomingEvents__content' data-cy='upcoming-events-content'>
        <LoadingZone isLoading={bIsLoading} skeleton={<EventCardSkeleton />}>
          {aEvents.length > 0 ? (
            <ul className='upcomingEvents__list' data-cy='upcoming-events-list'>
              {aEvents.map((oEvent) =>
                oEvent.SUB_EVENTS ? (
                  <MultiLevelEventCard
                    oEvent={oEvent}
                    SubEventComponent={UpcomingEventCard}
                    key={oEvent.EVENT_LOOKUPID}
                  />
                ) : (
                  <UpcomingEventCard
                    oEvent={oEvent}
                    key={oEvent.EVENT_LOOKUPID}
                  />
                )
              )}
            </ul>
          ) : (
            <p>There are no upcoming events. Check back soon!</p>
          )}
        </LoadingZone>
      </div>
    </Accordion>
  );
};

export default UpcomingEvents;
