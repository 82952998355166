import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import PencilEditButton from 'components/Layout/PencilEditButton';
import SelectContactForm from 'components/Checkout/CheckoutReview/SelectContactForm';
import { NewAddressForm } from 'components/MyProfile/elements/Personal/Address/NewAddressForm';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import useProfileData from 'utils/hooks/useProfileData';
import LoadingZone from 'components/LoadingZone';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { addressSelector } from './selectors';
import CheckoutContactInfoSkeleton from '../CheckoutContactInfoSkeleton';
import { buildAddressValueForReview } from './helpers';

const CheckoutAddresses = ({ oErrors, setErrors }) => {
  const dispatch = useDispatch();
  const [bIsEditingAddress, setIsEditingAddress] = useState(false);
  const submitAddressFormikFormRef = useRef();
  const aAddresses = useSelector(addressSelector);
  const oContainerRef = useRef();
  const aStates = useCodeTablesSection('REGION');
  const bIsProfileLoading = useProfileData();
  const sAddress = useSelector((state) => state.Checkout.address);

  const fnUpdateAddress = useCallback(
    (sNewAddress) => {
      dispatch(
        CheckoutActions.update({
          address: sNewAddress,
        })
      );
      setErrors((oPreviousState) => ({
        ...oPreviousState,
        address: '',
      }));
    },
    [dispatch, setErrors]
  );

  // Set initial address
  useEffect(() => {
    if (!sAddress && aAddresses.length > 0) {
      fnUpdateAddress(aAddresses[0].value);
    }
  }, [aAddresses, sAddress, fnUpdateAddress]);

  const fnToggleAddressEditing = () => {
    if (bIsEditingAddress && submitAddressFormikFormRef.current) {
      submitAddressFormikFormRef.current();
    }
    setIsEditingAddress(!bIsEditingAddress);
  };

  useCloseOnOutsideClick(
    oContainerRef,
    fnToggleAddressEditing,
    bIsEditingAddress
  );

  const buildNewAddressValue = (oFormData) => {
    const { ADDRESSBLOCK, ADDRESSCITY, ADDRESSZIP } = oFormData;
    const oStateCode = aStates.find(
      (oState) => oState.ID === oFormData.ADDRESSSTATE
    );
    const oAddress = {
      ADDRESSBLOCK,
      ADDRESSCITY,
      ADDRESSZIP,
      ADDRESSSTATEABBRV: oStateCode.DETAILS.STATE_ABBRV,
      ADDRESSCOUNTRY: 'US',
    };
    const sNewAddress = buildAddressValueForReview(oAddress);
    fnUpdateAddress(sNewAddress);
  };

  return (
    <>
      <div
        className='checkoutReview__contact checkout__sectionBox'
        ref={oContainerRef}
      >
        <LoadingZone
          isLoading={bIsProfileLoading}
          skeleton={<CheckoutContactInfoSkeleton />}
        >
          <p className='checkoutReview__contactInfoLabel t-paragraph--medium'>
            Address
          </p>
          {!bIsEditingAddress && (
            <p className='checkoutReview__contactInfo'>
              {sAddress || 'Please add an address.'}
            </p>
          )}
          <PencilEditButton
            sAriaLabel='Toggle editing mailing address'
            className='checkoutReview__contactEditButton'
            fnHandleClick={fnToggleAddressEditing}
            bIsEditing={bIsEditingAddress}
          />
          {bIsEditingAddress && (
            <>
              <SelectContactForm
                aContactData={aAddresses}
                sSelectionLabel='address'
                oInitialValues={{ address: sAddress }}
                submitFormikFormRef={submitAddressFormikFormRef}
                handleSubmit={(values) => {
                  fnUpdateAddress(values.address);
                }}
              />
              <NewAddressForm handleSubmitSideEffects={buildNewAddressValue} />
            </>
          )}
        </LoadingZone>
      </div>
      {oErrors.address && <div className='error'>{oErrors.address}</div>}
    </>
  );
};

CheckoutAddresses.propTypes = {
  oErrors: PropTypes.shape({
    email: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default CheckoutAddresses;
