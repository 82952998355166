import React, { useCallback, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import oIconLibrary from 'utils/iconLibrary';
import AdvancedSearch from './elements/AdvancedSearch';
import { QuickSearchSelect } from './elements/QuickSearch';
import AdvancedSearchDeepLinkHandler from './elements/AdvancedSearch/AdvancedSearchDeepLinkHandler';

/**
 * Toggles between QuickSearch and AdvancedSearch.
 */
const DirectorySearch = () => {
  const [bIsAdvancedSearchVisible, fnSetIsAdvancedSearchVisible] =
    useState(false);
  const [advSearchCriteria, setAdvSearchCriteria] = useState(undefined);
  const [bForceSubmit, fSetForceSubmit] = useState(false);
  const oLocation = useLocation();
  const bIsAdvSearchDeepLink =
    oLocation.pathname.includes('/search') && !!oLocation.search;

  const handleSubmitAdvancedSearch = useCallback((data) => {
    fSetForceSubmit(true);
    setAdvSearchCriteria(data);
    fnSetIsAdvancedSearchVisible(true);
  }, []);

  return (
    <div className='searchContainer'>
      <div className='search__magnifyIcon'>
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome.search} />
      </div>
      {!bIsAdvancedSearchVisible && (
        <QuickSearchSelect
          handleSubmitAdvancedSearch={handleSubmitAdvancedSearch}
        />
      )}
      {bIsAdvSearchDeepLink && (
        <AdvancedSearchDeepLinkHandler
          handleSubmitAdvancedSearch={handleSubmitAdvancedSearch}
        />
      )}
      <AdvancedSearch
        bIsAdvancedSearchVisible={bIsAdvancedSearchVisible}
        fnSetIsAdvancedSearchVisible={fnSetIsAdvancedSearchVisible}
        bForceSubmit={bForceSubmit}
        initialSearchCriteria={advSearchCriteria}
      />
    </div>
  );
};
export default DirectorySearch;
