import { createActions } from 'redux-actions';

export default createActions(
  {
    CREATE: (action) => action,
    UPDATE: (action) => action,
    DELETE: (action) => action,
  },
  {
    prefix: 'Profile/MYNETWORK',
  }
);
