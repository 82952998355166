import React from 'react';
import { useField, Field } from 'formik';
import PropTypes from 'prop-types';

import { capitalize } from '../../../utils/common';

export const Radio = ({
  sId,
  sLabel,
  sName,
  bSilenceErrors,
  mValue,
  bIsDisabled,
  sUi,
  sWrapperClassName,
  sSize,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField({
    name: sName,
    type: 'radio',
  });
  const bShowErrors = meta.touched && meta.error && !bSilenceErrors;

  return (
    <div
      className={`${sWrapperClassName} radio ${
        bIsDisabled ? 'radio--disabled' : ''
      } radio--ui${capitalize(sUi)}`}
    >
      <Field
        name={sName}
        type='radio'
        value={mValue}
        className='radioInput hide_accessibly'
        id={sId}
        disabled={bIsDisabled}
      />
      <label
        htmlFor={sId}
        className={`radioLabel ${
          sSize ? `radioLabel--${sSize}` : ''
        } t-paragraph`}
      >
        {sLabel}
      </label>
      {bShowErrors && <p className='field__errorMessage'>{meta.error}</p>}
    </div>
  );
};

Radio.defaultProps = {
  mValue: '',
  bSilenceErrors: false,
  bIsDisabled: false,
  sUi: 'default',
  sWrapperClassName: '',
  sSize: '',
};

Radio.propTypes = {
  sId: PropTypes.string.isRequired,
  sName: PropTypes.string.isRequired,
  sLabel: PropTypes.string.isRequired,
  mValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  bSilenceErrors: PropTypes.bool,
  bIsDisabled: PropTypes.bool,
  sUi: PropTypes.oneOf(['default', 'buttons']),
  sWrapperClassName: PropTypes.string,
  sSize: PropTypes.string,
};

export default Radio;
