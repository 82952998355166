import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, Select, CheckBox } from 'components/Form/Fields';
import Button from 'components/Buttons/Button';
import SelectEmployerField from 'components/Form/Fields/Select/SelectEmployerField';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';
import { useFormikContext } from 'formik';
import {
  fnGetOption,
  fnGetRegionOptionsByCountry,
  aClassYearOptions,
} from './helpers';

const AdvancedSearchFields = ({ bIsClassmatesSearch, bForceExpandMore }) => {
  const { errors, setFieldError, values } = useFormikContext();
  const [oCountry, setCountry] = useState(null);
  const [oRegion, setRegion] = useState(null);
  const aAllRegionOptions = useCodeTablesSection('REGION', (aCodes) => {
    const aFilteredStateOptions = fnGetRegionOptionsByCountry(aCodes, oCountry);
    return aFilteredStateOptions;
  });
  const aCountryOptions = useCodeTablesSection('COUNTRY');
  const aEducDeptOptions = useCodeTablesSection('EDUCATIONALDEPARTMENT');
  const aEducProgramOptions = useCodeTablesSection('EDUCATIONALPROGRAM');
  const aInvolvementOptions = useCodeTablesSection('INVOLVEMENT');
  const aSkillOptions = useCodeTablesSection('JOB_SPECIALTY');
  const aAffinityGroupOptions = useCodeTablesSection('AFFINITY_GROUP');
  const [more, setMore] = useState(bForceExpandMore);
  const aRegionOptionsFilteredByCountry = fnGetRegionOptionsByCountry(
    aAllRegionOptions,
    oCountry
  );

  useEffect(() => {
    const sErrorMessage = `${oRegion?.DESCRIPTION} is not in ${oCountry?.DESCRIPTION}`;
    const bIsRegionNotInSelectedCountry =
      oCountry?.ID &&
      oRegion?.ID &&
      !(oRegion?.DETAILS?.COUNTRY_ABBRV === oCountry?.DETAILS?.ABBRV);
    if (
      bIsRegionNotInSelectedCountry &&
      errors?.state !== sErrorMessage &&
      errors?.country !== sErrorMessage
    ) {
      setFieldError('state', sErrorMessage);
      setFieldError('country', sErrorMessage);
    }
  }, [oCountry, errors, setFieldError, oRegion]);

  return (
    <>
      <div className='advancedSearch__basicFieldsWrap'>
        <TextField sId='first-name' sName='firstname' sLabel='First Name' />
        <TextField sId='last-name' sName='lastname' sLabel='Last Name' />
        <TextField sId='city' sName='city' sLabel='City' />
        <Select
          label={oCountry?.DESCRIPTION !== 'United States' ? 'Region' : 'State'}
          options={aRegionOptionsFilteredByCountry}
          name='state'
          getOptionLabel={(option) =>
            option.DETAILS?.NAME_ABBRV || option.DESCRIPTION
          }
          getOptionValue={(option) =>
            option.DETAILS?.NAME_ABBRV || option.DESCRIPTION
          }
          onChange={setRegion}
        />
        <Select
          label='Country'
          options={aCountryOptions}
          name='country'
          getOptionLabel={(option) =>
            option.DETAILS?.NAME_ABBRV || option.DESCRIPTION
          }
          getOptionValue={(option) =>
            option.DETAILS?.NAME_ABBRV || option.DESCRIPTION
          }
          onChange={setCountry}
        />
        {!bIsClassmatesSearch && (
          <Select
            label='Class Year'
            options={aClassYearOptions}
            name='classof'
            getOptionLabel={fnGetOption}
            getOptionValue={fnGetOption}
          />
        )}
        <SelectEmployerField
          sInputId='advanced-search-employer'
          className={
            bIsClassmatesSearch ? '' : 'advancedSearch__employerField--expanded'
          }
          sFieldName='employer'
        />
        {values.employer?.NAME && (
          <CheckBox
            sName='includeformeremployers'
            sLabel='Include former employers'
            sId='adv-search-include-former-employers'
          />
        )}
      </div>
      <Button
        sFlavor='underline'
        className={`advancedSearch__moreButton${more ? '--expanded' : ''}`}
        fnHandleClick={() => setMore(!more)}
      >
        {more ? 'Less' : 'More'}
      </Button>
      {more && (
        <div className='advancedSearch__advFieldsWrap'>
          <Select
            label='Area of Study (Department)'
            options={aEducDeptOptions}
            name='educationaldepartment'
            getOptionLabel={fnGetOption}
            getOptionValue={fnGetOption}
          />
          <Select
            label='Academic Program'
            options={aEducProgramOptions}
            name='educationalprogram'
            getOptionLabel={fnGetOption}
            getOptionValue={fnGetOption}
          />
          <Select
            label='Clubs & Organizations'
            options={aInvolvementOptions}
            name='educationalinvolvement'
            getOptionLabel={fnGetOption}
            getOptionValue={fnGetOption}
          />
          {!bIsClassmatesSearch && (
            <Select
              label='Job Skills'
              options={aSkillOptions}
              name='industry'
              getOptionLabel={fnGetOption}
              getOptionValue={fnGetOption}
            />
          )}
          <Select
            label='Affinity Groups'
            options={aAffinityGroupOptions}
            name='affinitygroup'
            getOptionLabel={fnGetOption}
            getOptionValue={fnGetOption}
          />
        </div>
      )}
    </>
  );
};

AdvancedSearchFields.propTypes = {
  bIsClassmatesSearch: PropTypes.bool,
  bForceExpandMore: PropTypes.bool,
};

AdvancedSearchFields.defaultProps = {
  bIsClassmatesSearch: false,
  bForceExpandMore: false,
};

export default AdvancedSearchFields;
