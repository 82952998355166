import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { fnIsDateTimeWithinRange } from 'utils/common';
import fnFormatDate from './helpers';

/**
 * Displays a banner alerting users about upcoming maintenance downtimes
 * that occur within the next 2 days.
 */
const UpcomingDowntimeBanner = ({ aMaintenanceAlerts }) => {
  const { pathname } = useLocation();

  // Finds downtime scheduled within the next 2 days
  const oUpcomingMaintenanceAlert = aMaintenanceAlerts.find((oAlert) => {
    const sStart = oAlert.M.start.S;
    const oTwoDaysBeforeStart = new Date(sStart);
    oTwoDaysBeforeStart.setDate(oTwoDaysBeforeStart.getDate() - 2);
    const sTwoDaysBeforeStart = oTwoDaysBeforeStart.toDateString();
    return fnIsDateTimeWithinRange(Date.now(), sTwoDaysBeforeStart, sStart);
  });

  const sFormattedStart = fnFormatDate(oUpcomingMaintenanceAlert?.M?.start?.S);
  const sFormattedEnd = fnFormatDate(oUpcomingMaintenanceAlert?.M?.end?.S);

  return oUpcomingMaintenanceAlert ? (
    <div
      className={`upcomingDowntimeBanner ${
        pathname.includes('sign-in')
          ? 'upcomingDowntimeBanner--landingPage'
          : ''
      }`}
    >
      <p>
        WakeNetwork will be down for maintenance from{' '}
        <strong>{sFormattedStart}</strong> to <strong>{sFormattedEnd}</strong>.
      </p>
    </div>
  ) : null;
};

UpcomingDowntimeBanner.propTypes = {
  aMaintenanceAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      M: PropTypes.shape({
        start: PropTypes.shape({ S: PropTypes.string }),
        end: PropTypes.shape({ S: PropTypes.string }),
      }),
    })
  ),
};

UpcomingDowntimeBanner.defaultProps = {
  aMaintenanceAlerts: [],
};

export default UpcomingDowntimeBanner;
