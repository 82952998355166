import { useState, useEffect } from 'react';

const listeners = new Map([]);
let state = null;

const setState = (newState) => {
  state = newState;
  // Call setState() for each listener in the array
  listeners.forEach((listener) => {
    listener(state);
  });
};

export const useActiveFormField = (id) => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.set(id, newListener);
    return () => {
      listeners.delete(id);
    };
  }, [id, newListener]);
  return [state, setState];
};

export default useActiveFormField;
