import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  fnFormatEventTime,
  fnFormatEventDate,
  fnFormatEventCost,
  fnIsEventRegistrable,
} from '../helpers';
import EventAlert from '../EventAlert';
import EventDateBlock from '../EventDateBlock';

const UpcomingEventCard = ({ oEvent }) => {
  const { START_DATE_TIME, END_DATE_TIME, TIME_ZONE, SLUG } = oEvent;
  const bIsSingleLevelEvent = !oEvent.MAIN_EVENT_ID;

  return (
    <li className='eventCard'
      data-cy={`event-${oEvent.EVENT_LOOKUPID}-card`}
      >
      {bIsSingleLevelEvent && (
        <div className='eventCard__dateBlockWrapper'>
          <EventDateBlock oEvent={oEvent} />
        </div>
      )}
      <div className='eventCard__separatorWrapper'>
        <div className='upcomingEventCard__content'>
          <div className='upcomingEventCard__details'>
            <Link
              to={`/events/${SLUG || oEvent.EVENT_LOOKUPID}`}
              className='upcomingEventCard__title t-paragraph--bold'
              data-cy='event-details-link'
            >
              {oEvent.TITLE}
            </Link>
            {oEvent.LOCATION && (
              <p className='upcomingEventCard__location t-paragraph--small'>
                {oEvent.LOCATION.NAME}
              </p>
            )}
            <p className='upcomingEventCard__time t-paragraph--small'>
              {!bIsSingleLevelEvent &&
                `${fnFormatEventDate(START_DATE_TIME, END_DATE_TIME)} at `}
              {fnFormatEventTime(START_DATE_TIME, END_DATE_TIME, TIME_ZONE)}
            </p>
            {oEvent.REGISTRATION.HAS_COST === 1 && (
              <p className='upcomingEventCard__cost t-paragraph--small'>
                {fnFormatEventCost(oEvent)} per person
              </p>
            )}
          </div>
          <EventAlert oEvent={oEvent} />
          {fnIsEventRegistrable(oEvent) && bIsSingleLevelEvent && (
            <Link
              to={`/events/${SLUG || oEvent.EVENT_LOOKUPID}`}
              className='upcomingEventCard__register button button--tertiary'
              data-cy='register-button'
            >
              {oEvent.IS_INVITED === 1 ? 'RSVP' : 'Register'}
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

UpcomingEventCard.propTypes = {
  oEvent: PropTypes.shape({
    EVENT_ID: PropTypes.string.isRequired,
    EVENT_LOOKUPID: PropTypes.string.isRequired,
    TITLE: PropTypes.string.isRequired,
    START_DATE_TIME: PropTypes.string.isRequired,
    END_DATE_TIME: PropTypes.string.isRequired,
    TIME_ZONE: PropTypes.string,
    IS_CANCELLED: PropTypes.number.isRequired,
    IS_INVITED: PropTypes.number,
    MAIN_EVENT_ID: PropTypes.string,
    SLUG: PropTypes.string,
    REGISTRATION: PropTypes.shape({
      HAS_COST: PropTypes.number.isRequired,
      COST_RANGE: PropTypes.shape({
        MIN: PropTypes.number.isRequired,
        MAX: PropTypes.number.isRequired,
      }),
      TOTAL_CAPACITY: PropTypes.number.isRequired,
      TOTAL_REGISTRATIONS: PropTypes.number.isRequired,
      IS_REGISTERED: PropTypes.number.isRequired,
    }).isRequired,
    LOCATION: PropTypes.shape({
      NAME: PropTypes.string,
    }),
  }).isRequired,
};

export default UpcomingEventCard;
