import * as R from 'ramda';
import { string } from 'yup';

import { arrayCast, oCurrencyFormatter } from 'utils/common';
import WfuGoogleUsernameValidator from './WfuGoogleUsernameValidator';

export const wfuGoogleUsernameValidator = new WfuGoogleUsernameValidator();

/**
 * Yup transformation to coerce value into an array.
 * An empty object becomes an empty array.
 * A non-empty object gets wrapped in an array.
 *
 * @example
 * yup.array().transform(coerceToArray)
 *
 * @param {*} value - Current value in Yup validation chain.
 * @param {*} originalValue - Original input value.
 * @return array
 */
export const coerceToArray = (value, originalValue) => {
  const isEmptyObject =
    typeof originalValue === 'object' && R.isEmpty(originalValue);
  const transformedValue = isEmptyObject ? [] : arrayCast(originalValue);
  return transformedValue;
};

/**
 * Yup transformation to coerce value into a string.
 * An empty object becomes an empty string.
 *
 * @example
 * yup.string().transform(coerceToString)
 *
 * @param {*} value - Current value in Yup validation chain.
 * @param {*} originalValue - Original input value.
 * @return string
 */
export const coerceToString = (value, originalValue) => {
  const isEmptyObject =
    typeof originalValue === 'object' && R.isEmpty(originalValue);
  const transformedValue = isEmptyObject ? '' : originalValue;
  return transformedValue;
};

/**
 * Yup transformation to coerce empty value into null.
 *
 * @example
 * yup.object().nullable().transform(coerceToNull)
 *
 * @param {*} value - Current value in Yup validation chain.
 * @param {*} originalValue - Original input value.
 * @return string
 */
export const coerceToNull = (value, originalValue) => {
  const isEmptyObject =
    typeof originalValue === 'object' && R.isEmpty(originalValue);
  const transformedValue = isEmptyObject ? null : originalValue;
  return transformedValue;
};

/**
 * Creates a Yup schema to enforce minimums and maximums
 * on human-friendly money amounts like $1,000,000.00.
 *
 * @example Accept amounts between 5.00 and 10.00, inclusive, on a required field.
 *  amount: string()
 *    .required('Please enter an amount.')
 *    .concat(buildMinMaxMoneySchema(5, 10))
 *
 * @param {number} fMin - Minimum amount of money to accept.
 * @param {number} fMax - Maximum amount of money to accept.
 *
 * @returns {object}
 */
export const buildMinMaxMoneySchema = (fMin, fMax) => {
  const oMinSchema = string().test(
    'minimum',
    `Please enter an amount of at least ${oCurrencyFormatter.format(fMin)}`,
    (value) => {
      const fValueSansCommas = value?.replaceAll(',', '');
      return fValueSansCommas >= fMin;
    }
  );
  let oSchema = oMinSchema;

  if (fMax) {
    const oMaxSchema = string().test(
      'maximum',
      `Please enter an amount less than or equal to ${oCurrencyFormatter.format(
        fMax
      )}`,
      (value) => {
        const fValueSansCommas = value?.replaceAll(',', '');
        return fValueSansCommas <= fMax;
      }
    );
    oSchema = oSchema.concat(oMaxSchema);
  }
  return oSchema;
};
