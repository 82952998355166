import { object, array, string, bool } from 'yup';

import { sEmptyGuid } from 'utils/constants';
import { privacyFieldSchema } from 'utils/schemas/fields';
import { oEndDateFieldSchema, oStartDateFieldSchema } from '../helpers';

export const oInitialValues = {
  ID: sEmptyGuid,
  EMPLOYER: null,
  PRIMARY: true,
  PRIVACY: '0',
  POSITIONS: [
    {
      ID: sEmptyGuid,
      JOB_TITLE: '',
      START_DATE: '',
      END_DATE: '',
      IS_RETIRED: false,
    },
  ],
};

const oJobSchema = object({
  JOB_TITLE: string().required('Please add a job title.'),
  START_DATE: oStartDateFieldSchema,
  END_DATE: oEndDateFieldSchema,
  PRIVACY: privacyFieldSchema,
});

/**
 * Builds an array of jobs with start/end date strings converted to Date objects.
 *
 * @param {array} aEmployers - Employment data.
 * @returns {array}
 */
const fnFormatJobsForDateComparison = (aEmployers) => {
  const aAllFormattedJobs = aEmployers.reduce((aResult, oEmployer) => {
    const aFormattedJobs = oEmployer.POSITIONS.map((oPosition) => ({
      oStartDate: new Date(oPosition.START_DATE),
      oEndDate: oPosition.END_DATE ? new Date(oPosition.END_DATE) : '',
    }));
    return [...aResult, ...aFormattedJobs];
  }, []);
  return aAllFormattedJobs;
};

/**
 * Checks if the new job's date range overlaps with an existing job's date range.
 *
 * @param {object} oNewJob - Job with start and end Dates.
 * @param {object} oExistingJob - Job with start and end Dates.
 * @returns
 */
const fnCheckForNewJobDateOverlap = (oNewJob, oExistingJob) => {
  let bDatesOverlap = false;
  if (oNewJob.oEndDate && oExistingJob.oEndDate) {
    bDatesOverlap =
      oNewJob.oStartDate <= oExistingJob.oEndDate &&
      oNewJob.oEndDate >= oExistingJob.oStartDate;
  } else if (oExistingJob.oEndDate) {
    const sNewJobAssumedEndDate = new Date();
    bDatesOverlap =
      oNewJob.oStartDate <= oExistingJob.oEndDate &&
      sNewJobAssumedEndDate >= oExistingJob.oStartDate;
  } else {
    // When neither job has an end date, only compare start dates
    bDatesOverlap = oNewJob.oStartDate <= oExistingJob.oStartDate;
  }
  return bDatesOverlap;
};

/**
 * Determines if the new job the user is trying to add
 * overlaps any existing employment with that same employer.
 *
 * @param {string} sNewEmployer - New employer name.
 * @param {object} oNewJob - New position details.
 * @param {array} aExistingEmployment - List of user's existing employment records.
 * @returns {bool} - true = valid, no overlap; false = not valid, overlaps
 */
export const fnValidateNewJobForOverlapWithExisting = (
  sNewEmployer,
  oNewJob,
  aExistingEmployment
) => {
  let bIsValid = false;
  const aExistingEmploymentWithSameEmployer = aExistingEmployment.filter(
    (oEmployer) => oEmployer.EMPLOYER === sNewEmployer
  );
  if (aExistingEmploymentWithSameEmployer.length === 0) {
    bIsValid = true;
    return bIsValid;
  }

  // Make a ready-to-compare array of all existing jobs with the same employer
  const aExistingJobs = fnFormatJobsForDateComparison(
    aExistingEmploymentWithSameEmployer
  );

  const oFormattedNewJob = {
    oStartDate: new Date(oNewJob.START_DATE),
    oEndDate: oNewJob.END_DATE ? new Date(oNewJob.END_DATE) : '',
  };

  // Compare new job to existing jobs for any overlaps
  bIsValid = aExistingJobs.every((oExistingJob) => {
    const bDatesOverlap = fnCheckForNewJobDateOverlap(
      oFormattedNewJob,
      oExistingJob
    );
    return !bDatesOverlap;
  });

  return bIsValid;
};

export const fnBuildAddNewEmploymentSchema = (aExistingEmployment) =>
  object({
    EMPLOYER: object()
      .nullable()
      .required('Please add an employer before saving.'),
    PRIVACY: privacyFieldSchema,
    PRIMARY: bool().required(),
    POSITIONS: array()
      .of(oJobSchema)
      .required('Must have positions')
      .test(
        'no-overlapping-dates',
        'Employment cannot be saved with overlapping start and end dates.',
        (aValue, oContext) => {
          const sNewEmployer = oContext.parent.EMPLOYER?.NAME;
          const bIsValid = fnValidateNewJobForOverlapWithExisting(
            sNewEmployer,
            aValue[0],
            aExistingEmployment
          );

          if (bIsValid) {
            return bIsValid;
          }
          return oContext.createError({
            path: 'POSITIONS.0.START_DATE',
            message: 'Overlapping Dates',
          });
        }
      ),
  });
