import { object, array, boolean } from 'yup';

import { coerceToArray } from 'utils/yupHelpers';

const recipientDataNormalizer = object({
  BIOGRAPHY: object().nullable().default(null),
  THANKYOUNOTES: array().transform(coerceToArray).ensure(),
  HAS_PHOTO: boolean().default(false),
});

const philanthropyReportDataNormalizer = object({
  STEWARDSHIPDATA: object({
    SCHOLARSHIPS: array()
      .of(
        object({
          CONTACTS: array().transform(coerceToArray),
          FINANCIALS: array()
            .of(
              object({
                VALUE_OVER_TIME: array().transform(coerceToArray).ensure(),
              })
            )
            .transform(coerceToArray)
            .ensure(),
          RECIPIENTS: array()
            .of(recipientDataNormalizer)
            .transform(coerceToArray)
            .ensure(),
        })
      )
      .transform(coerceToArray),
  }),
});

export default philanthropyReportDataNormalizer;
