import React from 'react';

import { oCurrencyFormatter } from 'utils/common';
import useWindowSize from 'utils/hooks/useWindowSize';

const oQuartersToShortMonths = {
  Q1: 'Jul–Sept',
  Q2: 'Oct–Dec',
  Q3: 'Jan–Mar',
  Q4: 'Apr–Jun',
};

const oQuartersToFullMonths = {
  Q1: 'July–September',
  Q2: 'October–December',
  Q3: 'January–March',
  Q4: 'April–June',
};

const FundFinancesByQuarter = ({ aFinances }) => {
  const oWindowSize = useWindowSize();
  return (
    <div className='fundFinancesByQuarter'>
      {aFinances.map(
        ({
          INTERVAL,
          BEGINNING_VALUE,
          ADDITIONALGIFTS,
          REINVESTMENTS,
          TRANSFERS,
          DISTRIBUTIONS,
          INVESTINGGAINORLOSS,
          ENDING_VALUE,
        }) => (
          <div key={INTERVAL}>
            <div className='fundFinancialHistory__quarter'>
              <p className='t-paragraph--medium'>{INTERVAL}</p>
              <p className='t-paragraph--medium'>
                {oWindowSize.width < 640
                  ? oQuartersToShortMonths[INTERVAL]
                  : oQuartersToFullMonths[INTERVAL]}
              </p>
            </div>
            <div className='fundFinancialHistory__tableWrapper'>
              <table className='fundFinancialHistory__table'>
                <thead className='hide_accessibly'>
                  <tr>
                    <th>Label</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className='t-paragraph--medium'>Beginning Value</p>
                    </td>
                    <td>
                      <p className='t-paragraph--medium'>
                        {BEGINNING_VALUE
                          ? oCurrencyFormatter.format(BEGINNING_VALUE)
                          : '-'}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Additional Gifts</p>
                    </td>
                    <td>
                      <p>{oCurrencyFormatter.format(ADDITIONALGIFTS)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Reinvestments</p>
                    </td>
                    <td>
                      <p>{oCurrencyFormatter.format(REINVESTMENTS)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Transfers</p>
                    </td>
                    <td>
                      <p>{oCurrencyFormatter.format(TRANSFERS)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Distributions</p>
                    </td>
                    <td>
                      <p>{oCurrencyFormatter.format(DISTRIBUTIONS)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Investing Gain / Loss</p>
                    </td>
                    <td>
                      <p>{oCurrencyFormatter.format(INVESTINGGAINORLOSS)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className='t-paragraph--medium'>Ending Value</p>
                    </td>
                    <td>
                      <p className='t-paragraph--medium'>
                        {ENDING_VALUE
                          ? oCurrencyFormatter.format(ENDING_VALUE)
                          : '-'}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FundFinancesByQuarter;
