import React from 'react';
import PropTypes from 'prop-types';

const SVGShinyHeartIcon = ({ fillColor, strokeColor }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='56'
    height='50'
    fill='none'
    viewBox='0 0 56 50'
  >
    <path
      fill={fillColor}
      d='M54.794 18.997c-1.462 8.111-7.153 14.619-12.896 20.32a56.185 56.185 0 01-2.956 2.742c-.797.686-1.764 1.267-2.51 1.996l-.017.014c-3.018 2.418-6.02 4.303-8.728 5.53-2.71-1.227-5.709-3.113-8.726-5.53l-.017-.014c-.746-.729-1.714-1.31-2.512-1.996a56.23 56.23 0 01-2.955-2.742C7.736 33.62 2.043 27.112.58 19 .284 17.35.28 15.769.4 14.106.944 6.481 7.457.466 15.41.466c2.39-.002 4.745.551 6.87 1.615a14.934 14.934 0 015.355 4.5.933.933 0 01.052-.069.78.78 0 01.053.07 15.023 15.023 0 017.262-5.299 15.405 15.405 0 019.062-.266A15.083 15.083 0 0151.64 5.88a14.488 14.488 0 013.337 8.226c.12 1.66.116 3.24-.182 4.89z'
    />
    <path
      fill='#fff'
      d='M54.794 18.997c-1.462 8.111-7.153 14.619-12.895 20.32a56.27 56.27 0 01-2.956 2.742c-.798.686-1.765 1.267-2.511 1.996l-.017.014c-3.017 2.418-6.02 4.303-8.727 5.53-2.712-1.227-5.71-3.113-8.727-5.53l-.017-.014c-.746-.729-1.714-1.31-2.511-1.996a56.231 56.231 0 01-2.956-2.742c-.666-.66-1.334-1.335-1.993-2.023l39.345-32.31c2.711 2.5 3.815 4.478 4.147 9.119.12 1.662.116 3.243-.182 4.894z'
      opacity='0.42'
    />
    <path
      fill={strokeColor}
      d='M35.833 25.203l-.352.545c.074-.177.143-.354.21-.534l.142-.01z'
    />
  </svg>
);

SVGShinyHeartIcon.defaultProps = {
  fillColor: '#F5D00C',
  strokeColor: 'none',
};

SVGShinyHeartIcon.propTypes = {
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default SVGShinyHeartIcon;
