import React from 'react';
import { fnChildrenPropType } from 'utils/customPropTypes';

const ProfileRecordLayout = ({ left, right }) => (
  <div className='profileRecord'>
    <div className='profileRecord__content'>{left}</div>
    {right && <div className='profileRecord__options'>{right}</div>}
  </div>
);

ProfileRecordLayout.propTypes = {
  left: fnChildrenPropType.isRequired,
  right: fnChildrenPropType,
};

ProfileRecordLayout.defaultProps = {
  right: null,
};

export default ProfileRecordLayout;
