import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Conditionally renders a NavLink or a span
const ConditionalNavLink = ({ children, sTo, bCondition, className }) =>
  bCondition ? (
    <NavLink to={sTo} className={className}>
      {children}
    </NavLink>
  ) : (
    <span className={className}>{children}</span>
  );

ConditionalNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  sTo: PropTypes.string.isRequired,
  bCondition: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

ConditionalNavLink.defaultProps = {
  className: '',
};

export default ConditionalNavLink;
