import React from 'react';
import PropTypes from 'prop-types';
import Installment from './Installment';
import { installmentPropType } from '../helpers';

/**
 * Displays list of upcoming pledge installments.
 */
const InstallmentSchedule = ({ aData }) => {
  const bHasMultipleDesignations = aData[0].DESIGNATIONS.length > 1;
  return (
    <div className='installmentSchedule'>
      <p className='installmentSchedule__title'>Installment Schedule</p>
      <ul className='installmentSchedule__installments'>
        {aData.map((oInstallment) => (
          <li
            className={`installmentSchedule__installment ${
              bHasMultipleDesignations
                ? 'installmentSchedule__installment--multiDesignation'
                : ''
            }`}
            key={oInstallment.ID}
          >
            <Installment oData={oInstallment} />
          </li>
        ))}
      </ul>
    </div>
  );
};

InstallmentSchedule.propTypes = {
  aData: PropTypes.arrayOf(installmentPropType).isRequired,
};

export default InstallmentSchedule;
