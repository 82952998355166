import { createActions } from 'redux-actions';

export const parentActions = createActions(
  {
    GET: (payload) => payload,
    SET: (payload) => payload,
    CREATE: (payload) => payload,
    UPDATE: (payload) => payload,
    ADD_NOTE: (action) => action,
  },
  {
    prefix: 'Profile/PARENT',
  }
);

export default parentActions;
