import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import oWnApi from 'utils/WnApi';
import * as CheckoutActions from 'components/Checkout/redux/actions';

/**
 * Custom hook to fetch the event details.
 *
 * @param {string} sEventSlug - The event slug
 * @param {bool} bIsAuthenticated - Whether the user is authenticated or not
 * @returns {object} - { oEvent, sError, bIsEventDetailsLoading }
 */
const useEventDetails = (sEventSlug, bIsAuthenticated) => {
  const fnDispatch = useDispatch();
  const [oEvent, fnSetEvent] = useState(null);
  const [sError, fnSetError] = useState('');
  const [bIsLoading, fnSetIsLoading] = useState(true);

  // When the event slug in the URL changes, reset and fetch the event details
  useEffect(() => {
    // Clear the cart
    fnDispatch(CheckoutActions.reset({}));

    // Reset state variables
    fnSetIsLoading(true);
    fnSetEvent(null);
    fnSetError(null);

    const fnGetEventDetails = async () => {
      const oResponse = await oWnApi.get(
        `events/${sEventSlug}`,
        bIsAuthenticated
      );
      if (oResponse.status === 200) {
        fnSetEvent(oResponse.data);
      } else {
        console.error(oResponse.data);
        fnSetError('Something went wrong. Please try again later.');
      }
      fnSetIsLoading(false);
    };

    fnGetEventDetails();
  }, [sEventSlug, fnDispatch, bIsAuthenticated]);

  return { oEvent, sError, bIsEventDetailsLoading: bIsLoading };
};

export default useEventDetails;
