import { oMinimumDateSchema } from 'utils/schemas/fields';
import { object, string, date } from 'yup';

export const addEditGroupSchema = object({
  ORGANIZATIONNAME: string().required('Please enter a group name.'),
  POSITION: string().required('Please enter a position.'),
  TYPE: string().required('Please select the group type.'),
  STARTDATE: oMinimumDateSchema.required('Please enter a start date.'),
  ENDDATE: date().when('STARTDATE', ([STARTDATE]) => {
    if (STARTDATE) {
      return oMinimumDateSchema.min(
        STARTDATE,
        'Please add a start date before the end date.'
      );
    }
    return oMinimumDateSchema;
  }),
});

export default addEditGroupSchema;
