/**
 * A set of regular expressions for string matching
 * @module matchers
 */

/**
 * guid
 * @memberof matchers
 * @example f5e08ec7-8a56-e311-8719-0025906126df
 */
export const guid =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export const lookupIdMatcher = /[0-9]+-[0-9]+/;

export const phone =
  /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;

export default { guid, phone };
