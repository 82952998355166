import React from 'react';

import { Radio } from 'components/Form/Fields/Radio';

const Radios = () => (
  <div className='componentLibrary__radioButtons'>
    <fieldset>
      <legend>Radios</legend>
      <Radio
        sId='radio-selected'
        sName='radio'
        mValue='selected'
        sLabel='Selected'
      />
      <Radio
        sId='radio-default'
        sName='radio'
        mValue='default'
        sLabel='Default'
      />
      <Radio
        sId='radio-disabled'
        sName='radio'
        mValue='disabled'
        sLabel='Disabled'
        bIsDisabled
      />
    </fieldset>
    <fieldset>
      <legend>Radios as Buttons</legend>
      <Radio
        sId='radio-button-selected'
        sName='radioButton'
        mValue='selected'
        sLabel='Selected'
        sUi='buttons'
      />
      <Radio
        sId='radio-button-default'
        sName='radioButton'
        mValue='default'
        sLabel='Default'
        sUi='buttons'
      />
      <Radio
        sId='radio-button-disabled'
        sName='radioButton'
        mValue='disabled'
        sLabel='Disabled'
        sUi='buttons'
        bIsDisabled
      />
    </fieldset>
  </div>
);

export default Radios;
