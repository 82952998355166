import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import monthsData from './monthsData.json';
import { Select } from '../Select';

const convertNumberToStringWithLeadingZeroes = (number, minNumDigits = 2) => {
  let result = number.toString();
  // Prepend 0(s)
  while (result.length < minNumDigits) {
    result = `0${result}`;
  }
  return result;
};

const buildDaysData = (numDays) => {
  const days = [];
  for (let i = 1; i <= numDays; i += 1) {
    days.push({
      day: convertNumberToStringWithLeadingZeroes(i),
    });
  }
  return days;
};

const DateSelectMMDD = ({ groupLabel, monthFieldOptions, dayFieldOptions }) => {
  // eslint-disable-next-line no-unused-vars
  const [monthField, monthMeta, monthHelpers] = useField(
    monthFieldOptions.name
  );
  // eslint-disable-next-line no-unused-vars
  const [dayField, dayMeta, dayHelpers] = useField(dayFieldOptions.name);
  const [numDays, setNumDays] = useState(31);
  const days = buildDaysData(numDays);

  useEffect(() => {
    if (monthField.value) {
      // Update available days based on month chosen
      const monthData = monthsData.find(
        (month) => month.number === monthField.value
      );
      const maxDays = monthData.numDays;
      setNumDays(maxDays);

      // Prevent a Feb 31 situation when the month changes
      if (parseInt(dayField.value) > maxDays) {
        dayHelpers.setValue(convertNumberToStringWithLeadingZeroes(maxDays));
      }
    }
  }, [monthField.value, dayField.value, dayHelpers]);

  return (
    <fieldset className='dateSelectMMDD'>
      <legend className='dateSelectMMDD__legend'>{groupLabel}</legend>
      <div className='dateSelectMMDD__fields'>
        <Select
          id={monthFieldOptions.id}
          name={monthFieldOptions.name}
          label={monthFieldOptions.label}
          options={monthsData}
          valueKey='number'
          getOptionLabel={(option) => option.name}
          placeholder='MM'
        />
        <div className='dateSelectMMDD__slash'>/</div>
        <Select
          id={dayFieldOptions.id}
          name={dayFieldOptions.name}
          label={dayFieldOptions.label}
          options={days}
          valueKey='day'
          getOptionLabel={(option) => option.day}
          placeholder='DD'
        />
      </div>
    </fieldset>
  );
};

DateSelectMMDD.propTypes = {
  groupLabel: PropTypes.string.isRequired,
  monthFieldOptions: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  dayFieldOptions: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default DateSelectMMDD;
