import * as yup from 'yup';
import { useState, useEffect } from 'react';
import * as R from 'ramda';

import useQueryParams from 'utils/hooks/useQueryParams';
import useCodeTablesSection from 'utils/hooks/useCodeTablesSection';

export const oEmptyRow = {
  amount: '',
  fund: {
    DESCRIPTION: 'The Wake Forest Fund',
    DETAILS: {
      SCHOOL: 'University Wide',
      CODE: 'UFU01',
      CATEGORY: 'Unrestricted Support',
      SORT_TIER: '1',
    },
    ID: 'c6c03907-c14b-4ef4-b9e6-7945bbe8d8f7',
    TYPE: 'FUND',
  },
};

export const useInitialValues = (aCartGifts) => {
  const aFunds = useCodeTablesSection('FUND');
  const [oInitialValues, fnSetInitialValues] = useState({
    gifts: [oEmptyRow],
  });
  const oQueryParams = useQueryParams();

  // Initialize the form
  useEffect(() => {
    const sUrlDesignation = oQueryParams.get('designation');
    // If the user already started a gift,
    // re-initialize the form with the items in the cart.
    if (aCartGifts.length > 0) {
      const aFormatted = aCartGifts.map((oGift) => ({
        amount: oGift.nPrice.toString(),
        fund: oGift.oDetails.FUND,
      }));
      fnSetInitialValues({ gifts: aFormatted });
    } else if (sUrlDesignation) {
      // Use the designation query param to set the default fund
      const oDefaultFund = aFunds.find(
        (oFund) => oFund.DETAILS.CODE === sUrlDesignation
      );
      fnSetInitialValues({ gifts: [{ amount: '', fund: oDefaultFund }] });
    }
  }, [aCartGifts, oQueryParams, aFunds]);

  return oInitialValues;
};

const oGiftSchema = yup.object().shape({
  amount: yup
    .string()
    .required('Please enter an amount.')
    .test(
      'minimum-amount',
      'Amount must be at least $1',
      // eslint-disable-next-line consistent-return
      (sAmount, oContext) => {
        if (sAmount) {
          const nAmount = parseFloat(sAmount.replaceAll(',', '')); // Amounts over $999 will have commas to separate thousands
          if (nAmount < 1) {
            const oError = oContext.createError({
              message: 'Please enter an amount of at least $1.00.',
            });
            return new yup.ValidationError(oError);
          }
          return true;
        }
      }
    ),
  fund: yup.object().required('Please choose a fund.').nullable(),
});

export const oSchema = yup.object().shape({
  gifts: yup
    .array()
    .of(oGiftSchema)
    .required()
    .min(1)
    .test('no-duplicates', 'No repeated funds', (aGifts, oContext) => {
      const aErrors = [];
      aGifts.forEach((oGift, nGiftIndex) => {
        const aOtherGifts = aGifts.filter((oOtherGift) => oOtherGift !== oGift);
        aOtherGifts.forEach((oComparisonGift) => {
          const bIsDuplicateFund =
            oGift.fund?.DETAILS.CODE === oComparisonGift.fund?.DETAILS.CODE;
          if (bIsDuplicateFund) {
            const oError = oContext.createError({
              path: `gifts.${nGiftIndex}.fund`,
              message: 'Duplicate funds',
            });
            aErrors.push(oError);
          }
        });
      });

      if (aErrors.length === 0) {
        return true;
      }

      return new yup.ValidationError(aErrors);
    }),
});

export const fnSortByTier = R.sortBy(R.path(['DETAILS', 'SORT_TIER']));
