import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import RecordPrivacyIcon from '../../RecordPrivacyIcon';
import LinkWithSourcePath from '../../../../../utils/LinkWithSourcePath';
import ProfileRecordLayout from '../../layout/ProfileRecordLayout';

/**
 * NameViewMode
 *
 * @description - The component to render when the Name form is in `view` mode.
 *
 * @memberof Name
 *
 * @param {Object} props - The props passed to this component.
 * @param {array} props.data - The name related fields passed from the BIOGRAPHICAL block of Profile data.
 *
 * @author Sean Wcisel swcisel@augustodigital.com
 * @copyright 2022 Wake Forest University, AIT
 */
const NameViewMode = ({ data, bIsExpanded, name }) =>
  data.map(({ NAME, ATTENDNAME, LOOKUPID, PRIVACY, DECEASEDDATE }) => {
    // Children records are always private
    const privacyVal = name === 'child' ? '2' : PRIVACY;
    const isPrivate = privacyVal === '1' || privacyVal === '2';
    const bIsDeceased = !!DECEASEDDATE;
    const bHasAttendName = ATTENDNAME?.ID;

    return (
      <React.Fragment key={`name-${NAME}`}>
        <ProfileRecordLayout
          left={
            <div className='record'>
              <LinkWithSourcePath
                to={`/main/profile/${LOOKUPID}`}
                referringComponent='NameViewMode'
              >
                {NAME}
              </LinkWithSourcePath>
              {bIsDeceased && (
                <p className='deceasedLabel'>Deceased ({DECEASEDDATE})</p>
              )}
              {isPrivate && (
                <RecordPrivacyIcon ariaId={`private-eye-${LOOKUPID}`} />
              )}
            </div>
          }
        />
        {bHasAttendName && (
          <Collapsible open={bIsExpanded} overflowWhenOpen='visible'>
            <ProfileRecordLayout
              left={
                <>
                  <p className='recordMiniLabel t-label'>
                    I attended Wake Forest University as:
                  </p>
                  <div className='record t-paragraph--medium'>
                    {`${ATTENDNAME.NAME}`}
                  </div>
                </>
              }
            />
          </Collapsible>
        )}
      </React.Fragment>
    );
  });

NameViewMode.defaultProps = {
  data: {
    FIRSTNAME: undefined,
    LASTNAME: undefined,
    MIDDLENAME: undefined,
    NICKNAME: undefined,
    ATTENDNAME: undefined,
    TITLE: undefined,
    ID: undefined,
  },
  bIsExpanded: false,
  name: '',
};

NameViewMode.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      FIRSTNAME: PropTypes.string,
      LASTNAME: PropTypes.string,
      MIDDLENAME: PropTypes.string,
      NICKNAME: PropTypes.string,
      ATTENDNAME: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      ID: PropTypes.string,
      TITLE: PropTypes.shape({
        ID: PropTypes.string,
        DESCRIPTION: PropTypes.string,
      }),
    })
  ),
  bIsExpanded: PropTypes.bool,
  name: PropTypes.string,
};

export default NameViewMode;
