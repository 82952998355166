import formatSummaryItems from 'components/Philanthropy/MyGiving/Payments/helpers/formatSummaryItems';
import { buildBatchTransactionData } from './helpers/buildBatchTransactionData';
import { buildSummaryItemsData } from './helpers/buildSummaryItemsData';
import { buildHiddenFieldsData } from './helpers/buildHiddenFieldsData';
import { formatTransactionData } from './helpers/formatTransactionData';

export const buildReviewData = ({
  oFormValues,
  aPayablePledges,
  oProfileData,
}) => {
  const oTransactionData = buildBatchTransactionData({
    oFormValues,
    aPayablePledges,
  });
  const aSummaryItems = buildSummaryItemsData({
    oFormValues,
    oTransactionData,
  });
  const oHiddenFieldsData = buildHiddenFieldsData({
    oFormValues,
    aSummaryItems,
    oProfileData,
  });
  return {
    oTransactionData: formatTransactionData(oTransactionData),
    aSummaryItems: formatSummaryItems(aSummaryItems),
    oHiddenFieldsData,
  };
};

export const buildConfirmationData = ({ oFormValues, aPayablePledges }) => {
  const oTransactionData = buildBatchTransactionData({
    oFormValues,
    aPayablePledges,
  });
  const aSummaryItems = buildSummaryItemsData({
    oFormValues,
    oTransactionData,
  });
  return {
    aSummaryItems: formatSummaryItems(aSummaryItems),
  };
};
