import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import * as R from 'ramda';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import oWnApi from 'utils/WnApi';
import Modal from 'components/Layout/Modal';
import Button from 'components/Buttons/Button';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import usePageTitle from 'utils/hooks/usePageTitle';
import GoogleReCaptcha from 'components/Form/Fields/GoogleReCaptcha';
import EventRegistrationDetailsCard from '../EventRegistrationDetailsCard';
import ReviewRegistrationEvent from './ReviewRegistrationEvent';
import AdditionalInfoFormBody from '../AdditionalInfo/AdditionalInfoFormBody';
import { selectAllNamedEventGuests } from '../AdditionalInfo/selectors';
import {
  fnBuildInitialValues,
  fnFormatEventRegistrationDataForDad,
  oReviewFormSchema,
} from './helpers';
import EventRegistrationContext from '../EventRegistrationContext';
import { selectNextEventRegStep } from '../EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import PaymentHiddenForm from './PaymentHiddenForm';
import { selectAllEventsInCart } from '../redux/selectors';
import { fnFormatEventRegConfirmationEmailData } from '../helpers';

const sReviewRegistrationFormId = 'event-registration-review-form';

const ReviewRegistration = () => {
  const fnNavigate = useNavigate();
  const fnDispatch = useDispatch();
  const aEventCartItems = useSelector(selectAllEventsInCart);
  const bHasWaitlistEvents = aEventCartItems.some(
    (oEvent) => oEvent.sType === 'event-waitlist'
  );
  const bHasNonWaitlistEvents = aEventCartItems.some(
    (oEvent) => oEvent.sType === 'event'
  );
  const { dadTransactionId } = useSelector((state) => state.Checkout);
  const oCheckout = useSelector((state) => state.Checkout);
  const aNamedGuests = useSelector(selectAllNamedEventGuests);
  const {
    fnSetCurrentFormId,
    fnSetIsSubmittingCurrentForm,
    bIsSubmittingCurrentForm,
  } = useContext(EventRegistrationContext);
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(state, window.location.pathname)
  );
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const [bShowRegistrationError, fnSetShowRegistrationError] = useState(false);
  const [sRegistrationErrorMsg, fnSetRegistrationErrorMsg] = useState('');
  const oHiddenFieldFormRef = useRef();
  const oReCaptchaRef = useRef();
  const oFormikRef = useRef();

  const oInitialValues = useMemo(
    () => fnBuildInitialValues(aEventCartItems, aNamedGuests),
    [aEventCartItems, aNamedGuests]
  );

  // Pass `bIsUserAuthenticated` into Formik so that
  // it will only require the reCAPTCHA field for unauth users
  useEffect(() => {
    oFormikRef.current.setFieldValue(
      'isUserAuthenticated',
      bIsUserAuthenticated,
      false // Skip validation to give the user a chance to do the reCAPTCHA first
    );
  }, [bIsUserAuthenticated]);

  useEffect(() => {
    fnSetCurrentFormId(sReviewRegistrationFormId);

    return () => {
      fnSetCurrentFormId('');
    };
  }, [fnSetCurrentFormId]);

  usePageTitle('Event Registration - Review');

  // Reset dadTransactionId when the component mounts
  useEffect(() => {
    fnDispatch(CheckoutActions.update({ dadTransactionId: '' }));
  }, [fnDispatch]);

  const fnHandleSubmit = async (oFormValues) => {
    fnSetIsSubmittingCurrentForm(true);
    const { reCaptchaToken } = oFormValues;

    const oRegistrationData = fnFormatEventRegistrationDataForDad(oFormValues);
    const oConfirmationEmailData = fnFormatEventRegConfirmationEmailData(
      oCheckout,
      aEventCartItems
    );
    const oRegistrationDataWithExtraSeasoning = {
      ...oRegistrationData,
      ...oConfirmationEmailData,
      reCaptchaToken,
    };
    const oResponse = await oWnApi.post(
      'events/registrations',
      oRegistrationDataWithExtraSeasoning,
      bIsUserAuthenticated
    );
    if (oResponse.status === 200) {
      const sDadTransactionId = oResponse.data.NEW_RECORD_ID;
      if (sDadTransactionId) {
        fnDispatch(
          CheckoutActions.update({
            dadTransactionId: sDadTransactionId,
          })
        );
      } else {
        fnNavigate(sNextStepUrl);
      }
      fnSetCurrentFormId('');
    } else if (
      oResponse.status === 500 &&
      oResponse.data.includes('not registrable')
    ) {
      console.error('Failed to save registration.', oResponse);
      let sErr = oResponse.data;
      const iEventIndex = sErr.indexOf('Unfortunately');
      if (iEventIndex !== -1) {
        sErr = sErr.substring(iEventIndex);
      }
      fnSetRegistrationErrorMsg(sErr);
      fnSetShowRegistrationError(true);
    } else if (oResponse.data.includes('reCAPTCHA')) {
      console.error('reCAPTCHA verification failed');
      oFormikRef.current.setFieldValue('reCaptchaToken', '');
      if (oReCaptchaRef.current) {
        oReCaptchaRef.current.reset();
      }
      fnSetRegistrationErrorMsg(
        'Please verify that you are not a robot by checking the reCAPTCHA box.'
      );
      fnSetShowRegistrationError(true);
    } else {
      console.error('Failed to save registration.', oResponse);
      fnSetRegistrationErrorMsg(
        'There was an error saving your registration. Please try again.'
      );
      fnSetShowRegistrationError(true);
    }

    fnSetIsSubmittingCurrentForm(false);
  };

  // Submit the hidden form to Elizabeth's payment gateway when dadTransactionId is updated
  useEffect(() => {
    const sHiddenFieldDadTransactionId =
      oHiddenFieldFormRef.current?.elements?.dad_transaction_id?.value;
    if (
      bIsSubmittingCurrentForm &&
      dadTransactionId &&
      sHiddenFieldDadTransactionId === dadTransactionId
    ) {
      oHiddenFieldFormRef.current.submit();
    }
  }, [dadTransactionId, bIsSubmittingCurrentForm, oHiddenFieldFormRef]);

  return (
    <div>
      <h1 className='reviewRegistration__instructions t-paragraph--medium'>
        Please review your pending registration details below.
      </h1>
      <Formik
        enableReinitialize
        initialValues={oInitialValues}
        validationSchema={oReviewFormSchema}
        onSubmit={fnHandleSubmit}
        innerRef={oFormikRef}
      >
        {(oFormikProps) => (
          <Form
            className='reviewRegistration__form'
            id={sReviewRegistrationFormId}
            onKeyDown={(event) => {
              // Prevent form submission when the user presses Enter while searching for a guest
              if (event.key === 'Enter' && event.target.tagName === 'INPUT') {
                event.preventDefault();
              }
            }}
          >
            {bHasNonWaitlistEvents && (
              <EventRegistrationDetailsCard
                sTitle='Events'
                sCypressId='review-events'
              >
                <FieldArray name='events'>
                  <>
                    {oFormikProps.values.events
                      .filter((event) => event.sRegistrationType === 'event')
                      .map(({ sEventLookupId }) => (
                        <ReviewRegistrationEvent
                          sEventLookupId={sEventLookupId}
                          key={sEventLookupId}
                        />
                      ))}
                  </>
                </FieldArray>
              </EventRegistrationDetailsCard>
            )}
            {bHasWaitlistEvents && (
              <EventRegistrationDetailsCard
                sTitle='Waitlist Events'
                sCypressId='review-waitlist-events'
              >
                <FieldArray name='waitlist-events'>
                  <>
                    {oFormikProps.values.events
                      .filter(
                        (event) => event.sRegistrationType === 'event-waitlist'
                      )
                      .map(({ sEventLookupId }) => (
                        <ReviewRegistrationEvent
                          sEventLookupId={sEventLookupId}
                          key={sEventLookupId}
                        />
                      ))}
                  </>
                </FieldArray>
              </EventRegistrationDetailsCard>
            )}
            <EventRegistrationDetailsCard
              sTitle='Additional Information for All Events'
              sCypressId='review-additional-info'
            >
              <div className='reviewRegistration__additionalInfoFormWrapper'>
                <AdditionalInfoFormBody />
              </div>
            </EventRegistrationDetailsCard>
            {bIsUserAuthenticated === false && (
              <GoogleReCaptcha
                oReCaptchaRef={oReCaptchaRef}
                className='reviewRegistration__reCaptcha'
              />
            )}
            {!R.isEmpty(oFormikProps.errors) && (
              <p className='reviewRegistration__generalError form__overallError'>
                Please correct the errors above and try again.
              </p>
            )}
          </Form>
        )}
      </Formik>
      {bShowRegistrationError && (
        <Modal
          sModalTitle='Something went wrong'
          sModalDetail={sRegistrationErrorMsg}
          fnSetShowModal={() => fnSetShowRegistrationError(false)}
          sFlavor='alert'
        >
          <Button fnHandleClick={() => fnSetShowRegistrationError(false)}>
            Close
          </Button>
        </Modal>
      )}
      <PaymentHiddenForm oHiddenFieldFormRef={oHiddenFieldFormRef} />
    </div>
  );
};

export default ReviewRegistration;
