import React from 'react';

import { SkeletonText, SkeletonCircle } from 'components/LoadingZone/Skeleton';

const ProfileSkeleton = () => (
  <div className='profileSkeleton'>
    <SkeletonCircle size='extraLarge' className='profileSkeleton__avatar' />
    <div className='profileSkeleton__textGroup'>
      <SkeletonText size='medium' width='50%' />
      <SkeletonText size='medium' width='30%' />
      <SkeletonText size='medium' width='40%' />
    </div>
    <SkeletonText
      size='large'
      width='100%'
      className='profileSkeleton__sectionHeader'
    />
    <div className='profileSkeleton__sectionContent'>
      <SkeletonText size='medium' width='30%' />
      <div className='profileSkeleton__sectionContentRight'>
        <SkeletonText size='medium' width='50%' />
        <SkeletonText size='medium' width='70%' />
      </div>
    </div>
  </div>
);

export default ProfileSkeleton;
