import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import { useActiveFormSection } from '../../../../../utils/hooks/useActiveFormSection';
import { arrayCast } from '../../../../../utils/common';
import NameEditMode from './NameEditMode';
import NameViewMode from './viewMode';
import * as actions from './redux/actions';
import ViewMoreButton from '../../../../Layout/ViewMoreButton';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';

const generateSelector = (aRecordPath) => (state) =>
  R.path(aRecordPath, state.Profile) || [];

const nameDataSelector = (aRecordPath) =>
  createSelector(generateSelector(aRecordPath), (mData) => {
    const aData = arrayCast(mData);
    return aData.map((oItem) =>
      R.pick(
        [
          'NAME',
          'ATTENDNAME',
          'LASTNAME',
          'FIRSTNAME',
          'MIDDLENAME',
          'TITLE',
          'NICKNAME',
          'PROFESSIONALSUFFIX',
          'PERSONALSUFFIX',
          'LOOKUPID',
          'ID',
          'PRIVACY',
          'RELATIONSHIPID',
          'DECEASEDDATE',
        ],
        oItem
      )
    );
  });

export const NameElement = ({
  recordPath,
  sTitle,
  bAllowMultiple,
  name,
  bIsPrivacyChangeable,
}) => {
  const [activeFormSection] = useActiveFormSection();
  const [bIsExpanded, fnSetIsExpanded] = useState(
    activeFormSection === 'personal'
  );
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  const fnToggleExpanded = () => fnSetIsExpanded(!bIsExpanded);
  const nameData = useSelector(nameDataSelector(recordPath));

  useEffect(() => {
    if (activeFormSection === 'personal') {
      fnSetIsExpanded(true);
      fnSetIsEditMode(true);
    } else {
      fnSetIsExpanded(false);
      fnSetIsEditMode(false);
    }
  }, [activeFormSection]);

  const bHasAttendName = nameData.some(
    (nameDataItem) => nameDataItem.ATTENDNAME?.ID
  );

  return (
    <ProfileSubsectionLayout
      sTitle={sTitle}
      left={
        bHasAttendName && (
          <ViewMoreButton
            fnOnClick={fnToggleExpanded}
            bIsShowingMore={bIsExpanded}
          />
        )
      }
      right={
        bIsEditMode ? (
          <NameEditMode
            data={nameData}
            bIsExpanded={bIsExpanded}
            sTitle={sTitle}
            bAllowMultiple={bAllowMultiple}
            actions={actions[name]}
            attendNameActions={actions.attendName}
            name={name}
            bIsPrivacyChangeable={bIsPrivacyChangeable}
          />
        ) : (
          <NameViewMode data={nameData} bIsExpanded={bIsExpanded} name={name} />
        )
      }
    />
  );
};

NameElement.defaultProps = {
  recordPath: ['BIOGRAPHICAL'],
  sTitle: 'Name',
  bAllowMultiple: false,
  bIsPrivacyChangeable: false,
};

NameElement.propTypes = {
  recordPath: PropTypes.arrayOf(PropTypes.string),
  sTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  bAllowMultiple: PropTypes.bool,
  bIsPrivacyChangeable: PropTypes.bool,
};

export default NameElement;
