import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import { useField } from 'formik';
import ReviewRegistrantsForEventWithMultipleRegOptions from './ReviewRegistrantsForEventWithMultipleRegOptions';
import ReviewRegistrantsForEventWithOneRegOption from './ReviewRegistrantsForEventWithOneRegOption';
import ReviewRegistrantsForEventWaitlist from './ReviewRegistrantsForEventWaitlist';
import AddGuestFromReviewStep from './AddGuestFromReviewStep';
import RemoveEventModal from './RemoveEventModal';
import {
  selectEventInCartByLookupId,
  selectAllEventsInCart,
} from '../redux/selectors';

const ReviewRegistrationEvent = ({ sEventLookupId }) => {
  const [bShowRemoveEventModal, fnSetShowRemoveEventModal] = useState(false);
  const oEventCartItem = useSelector(
    selectEventInCartByLookupId(sEventLookupId)
  );
  const bIsWaitlistEvent = oEventCartItem.sType === 'event-waitlist';
  const aEventsInCart = useSelector(selectAllEventsInCart);
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField(
    `events.find(event => event.sEventLookupId === '${sEventLookupId}').guests`
  );

  // Sometimes Formik has stale data, so don't render anything if the event is not in the cart
  if (!oEventCartItem) {
    return null;
  }

  const { TITLE, LOOKUPID, REGISTRATION } = oEventCartItem.oDetails.oEvent;
  const bHasMultipleRegOptions = REGISTRATION.REGISTRATION_OPTIONS.length > 1;

  // Only show "Please add at least one guest" error, not registration option field errors
  // See https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas
  const bShowError = typeof meta.error === 'string';

  return (
    <div
      className='reviewRegistration__event'
      data-cy={`event-${LOOKUPID}-details`}
    >
      <div className='reviewRegistration__eventTitleWrapper'>
        <h3 className='reviewRegistration__eventTitle t-paragraph--large'>
          {TITLE}
        </h3>
        {aEventsInCart.length > 1 && (
          <IconOnlyButton
            sIcon='trash'
            sAriaLabel='Remove Event'
            fnHandleClick={() => {
              fnSetShowRemoveEventModal(true);
            }}
          />
        )}
      </div>
      {!bIsWaitlistEvent &&
        (bHasMultipleRegOptions ? (
          <ReviewRegistrantsForEventWithMultipleRegOptions
            sEventLookupId={sEventLookupId}
            aRegistrationOptions={REGISTRATION?.REGISTRATION_OPTIONS || []}
          />
        ) : (
          <ReviewRegistrantsForEventWithOneRegOption
            sEventLookupId={sEventLookupId}
          />
        ))}
      {bIsWaitlistEvent && (
        <ReviewRegistrantsForEventWaitlist sEventLookupId={sEventLookupId} />
      )}
      {bShowError && <p className='field__errorMessage'>{meta.error}</p>}
      <div className='reviewRegistration__addGuestWrapper'>
        <AddGuestFromReviewStep oEventCartItem={oEventCartItem} />
      </div>
      {bShowRemoveEventModal && (
        <RemoveEventModal
          sEventTitle={TITLE}
          sEventLookupId={LOOKUPID}
          fnCloseModal={() => {
            fnSetShowRemoveEventModal(false);
          }}
        />
      )}
    </div>
  );
};

ReviewRegistrationEvent.propTypes = {
  sEventLookupId: PropTypes.string.isRequired,
};

export default ReviewRegistrationEvent;
