export const fnGetOtherValidationOptionText = (sValidationType, sMatchType) => {
  const validationTypeText = {
    ssn: {
      parent: "Enter the last 4 of my student's SSN",
      default: 'Enter the last 4 of my SSN',
    },
    deacontoken: {
      default: 'Enter your Deacon Token',
    },
    bannerid: {
      student: 'Enter your student ID',
      parent: "Enter your student's ID",
      staff: 'Enter your employee ID',
      default: 'Enter your Wake Forest assigned ID',
    },
    'magic-link': {
      default: 'Get a magic link',
    },
  };

  const matchTypeObject = validationTypeText[sValidationType];

  let buttonText = '';

  if (matchTypeObject) {
    buttonText = matchTypeObject[sMatchType] || matchTypeObject.default;
  }

  return buttonText;
};

export default { fnGetOtherValidationOptionText };
