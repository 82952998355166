import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import Button from 'components/Buttons/Button';
import LoadingZone from '../LoadingZone';
import useProfileData from '../../utils/hooks/useProfileData';
import Notifications from './Notifications';
import useWindowSize from '../../utils/hooks/useWindowSize';
import logoHeart from '../../images/logos/wn-logo-heart-gold.svg';
import logoWord from '../../images/logos/wn-logo-text-white-gold.svg';
import NavBarSkeleton from './NavBarSkeleton';
import { userConstituenciesSelector, fnSignOut } from './helpers';

const NavBar = () => {
  const isProfileDataLoading = useProfileData();
  const windowSize = useWindowSize();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const volunteerTypes = useSelector(
    (state) => state?.Profile?.BIOGRAPHICAL?.VOLUNTEERTYPES?.TYPE || []
  );

  const isReunionVolunteer =
    volunteerTypes.filter((type) => type.NAME === 'Reunion Volunteer').length >
    0;

  const aUserConstituencies = useSelector(userConstituenciesSelector);

  const isScholarshipRecipient = useSelector((state) =>
    isProfileDataLoading
      ? false
      : state.Profile.BIOGRAPHICAL.ISSCHOLARSHIPRECIPIENT
  );

  const aConstituenciesAllowedToMakeAlumGmail = [
    'Alumnus',
    'Non-degreed Alumna/us',
    'Student',
    'Withdrawn Student',
  ];

  const bIsAllowedToMakeAlumGmail = aUserConstituencies.some((sConstituency) =>
    aConstituenciesAllowedToMakeAlumGmail.includes(sConstituency)
  );

  const handleMobileNavToggle = () => setIsMobileNavOpen(!isMobileNavOpen);
  const handleNavLinkClick = () => setIsMobileNavOpen(false);

  return (
    <header className='navBar'>
      <div className='navBar__mobileHeader'>
        <div className='navBar__mobileLogo'>
          <Link to='/main' onClick={handleNavLinkClick}>
            <img
              className='navBar__mobileLogoHeart'
              src={logoHeart}
              alt='Heart with a top hat inside'
            />
            <img
              className='navBar__mobileLogoWord'
              src={logoWord}
              alt='Wake Network'
            />
          </Link>
        </div>
        <div className='navBar__mobileNotLogoWrapper'>
          {windowSize.width < 1366 && !isProfileDataLoading && (
            <Notifications />
          )}
          <IconOnlyButton
            sIcon='menu'
            fnHandleClick={handleMobileNavToggle}
            className='navBar__toggle'
            sAriaLabel='Open main menu'
            sFlavor='tertiary'
          />
        </div>
      </div>
      <nav
        className={`navBar__menu ${
          isMobileNavOpen ? 'navBar__menu--open' : ''
        }`}
        aria-label='Main menu'
      >
        <IconOnlyButton
          sIcon='close'
          fnHandleClick={handleMobileNavToggle}
          className='navBar__toggle navBar__toggle--close'
          sAriaLabel='Close main menu'
          sFlavor='tertiary'
        />
        <ul className='navBar__list'>
          <li className='navBar__listItem navBar__listItem--logo'>
            <Link to='/main' onClick={handleNavLinkClick}>
              <img
                className='navBar__logoHeart'
                src={logoHeart}
                alt='Heart with a top hat inside'
              />
              <img
                className='navBar__logoWord'
                src={logoWord}
                alt='Wake Network'
              />
            </Link>
          </li>
          <LoadingZone
            isLoading={isProfileDataLoading}
            skeleton={<NavBarSkeleton />}
          >
            <li className='navBar__listItem'>
              <Link
                to='/main'
                className='navBar__link'
                onClick={handleNavLinkClick}
              >
                Home
              </Link>
            </li>
            <li className='navBar__listItem'>
              <Link
                to='/philanthropy'
                className='navBar__link'
                onClick={handleNavLinkClick}
              >
                Philanthropy
              </Link>
            </li>
            {isScholarshipRecipient && (
              <li className='navBar__listItem'>
                <Link
                  to='/scholarships'
                  className='navBar__link'
                  onClick={handleNavLinkClick}
                >
                  Scholarships
                </Link>
              </li>
            )}
            {isReunionVolunteer && (
              <li className='navBar__listItem'>
                <Link
                  to='/volunteers'
                  className='navBar__link'
                  onClick={handleNavLinkClick}
                >
                  Volunteers
                </Link>
              </li>
            )}
            {bIsAllowedToMakeAlumGmail && (
              <li className='navBar__listItem'>
                <Link
                  to='/settings'
                  className='navBar__link'
                  onClick={handleNavLinkClick}
                >
                  Settings
                </Link>
              </li>
            )}
            <li className='navBar__listItem'>
              <Button fnHandleClick={fnSignOut} className='navBar__link'>
                Sign Out
              </Button>
            </li>
            {windowSize.width >= 1366 && (
              <li className='navBar__listItem'>
                <Notifications />
              </li>
            )}
          </LoadingZone>
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;
