import PageLayout from 'components/Layout/PageLayout';
import React from 'react';
import usePageTitle from 'utils/hooks/usePageTitle';

import useProfileData from '../../utils/hooks/useProfileData';
import LoadingZone from '../LoadingZone';
import AlumniGoogleAccount from './AlumniGoogleAccount';

const SettingsView = () => {
  const isProfileLoading = useProfileData();
  usePageTitle('Settings');

  return (
    <PageLayout sTitle='Settings'>
      <LoadingZone isLoading={isProfileLoading}>
        <AlumniGoogleAccount />
      </LoadingZone>
    </PageLayout>
  );
};

export default SettingsView;
